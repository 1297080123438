import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, map, tap, of, catchError, BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { WebSocketService } from './web-socket.service';
import { AppHelper } from '../utilities/app.helper';
import { AppConstant } from '../utilities/app.constant';
import { ProjectHomePageMask } from '../models/rig_journal/rig_journal.model';
import { IHomeFilterPayLoad } from '../interface/common';

@Injectable({
  providedIn: 'root',
})
export class HomeService extends BaseService {
  constructor(httpClient: HttpClient, private _io: WebSocketService) {
    super(httpClient);
  }

  pageSize: number = 100000;  
  pageIndex: number = 1;


  private payloadFilter = new BehaviorSubject<any>(AppConstant.DEFAULT_HOME_FILTER);

  setPayloadFilter(nextvalue: any) {
    this.payloadFilter.next(nextvalue);
  }

  getPayloadFilter() {
    return this.payloadFilter.getValue();
  }

  setPayloadFilterDefault() {
    this.payloadFilter.next({
      code: 'active',
      searchString: '',
    });
    this.setchipsFilter([]);
    this.setchipFiltersKey([]);
  }

  // NEW KEEP Filter Chip Home Filter
  private chipsFilter = new BehaviorSubject<string[]>([]);
  private chipFiltersKey = new BehaviorSubject<string[]>([]);

  setchipsFilter(nextvalue: string[]) {
    this.chipsFilter.next(nextvalue);
  }

  getchipsFilter() {
    return this.chipsFilter.getValue();
  }

  setchipFiltersKey(nextvalue: string[]) {
    this.chipFiltersKey.next(nextvalue);
  }

  getchipFiltersKey() {
    return this.chipFiltersKey.getValue();
  }


  public subjectRefreshItemRestore = new Subject<any>();

  private _refreshRequired = new Subject<void>();

  get RefreshRequired() {
    return this._refreshRequired;
  }

  public getIntervals(rigId?: string): Observable<any> {
    const uri = `rig-journal/interval-list?rigId=${rigId}`;
    return this.get<any>(uri);
  }

  public getWellbores(rigId?: string, projectId?: string): Observable<any> {
    const uri = `rig-journal/wellbore-list?rigId=${rigId}&projectId=${projectId}`;
    return this.get<any>(uri);
  }

  getRuns(rigId?: string): Observable<any> {
    const uri = `rig-journal/run-list?rigId=${rigId}`;
    return this.get<any>(uri);
  }

  public getSources(): Observable<any> {
    return of({
      data: AppConstant.DEFAULT_DATA_SOURCES_UPDATE,
      message: 'Get source list successfully!',
    });
  }

  public getCompaniesById(sourceKey: string): Observable<any[]> {
    const sourceKeyEnCode = `edm_source = '${sourceKey?.toLocaleLowerCase()}'`;
    const uri = `policy?query=${btoa(sourceKeyEnCode)}`;
    return this.get<any[]>(uri).pipe();
  }

  public getProjectsByIdCompany(company_id: string): Observable<any[]> {
    const uri = `policy/get-project-list/${company_id}`;
    return this.get<any[]>(uri).pipe();
  }

  public getSitesByIdProject(project_id: string): Observable<any[]> {
    const uri = `project/get-site-list/${project_id}`;
    return this.get<any[]>(uri).pipe();
  }

  public getWellbByIdSite(site_id: string): Observable<any[]> {
    const uri = `site/get-well-list/${site_id}`;
    return this.get<any[]>(uri).pipe();
  }

  public getWellborebByIdWell(well_id: string): Observable<any[]> {
    const uri = `well/get-wellbore-list/${well_id}`;
    return this.get<any[]>(uri).pipe();
  }

  public getConstructors(): Observable<any[]> {
    const uri = `contractor`;
    return this.get<any[]>(uri).pipe();
  }

  public getRigs(): Observable<any[]> {
    const uri = `rig`;
    return this.get<any[]>(uri).pipe();
  }

  public getRigJournalbyId(rigJournalId: string): Observable<any[]> {
    const uri = `rig-journal/project-list/${rigJournalId}`;
    return this.get<any[]>(uri).pipe();
  }

  public getListIntervalByJournalId(rigJournalId: string): Observable<any[]> {
    const uri = `interval/list-interval/${rigJournalId}`;
    return this.get<any[]>(uri).pipe();
  }

  public getListRunByJournalId(rigJournalId: string): Observable<any[]> {
    const uri = `run/list-run/${rigJournalId}`;
    return this.get<any[]>(uri).pipe();
  }
  
  getProjectsFilter(payload: IHomeFilterPayLoad, isFirstCall: boolean): Observable<any> {
    const filterPayload: any = Object.assign({}, payload);
    if ((payload.code && filterPayload.code === 'active') || (filterPayload.code && filterPayload.code === 'All' && filterPayload.status))
      payload.isActive = true
    
    let uri = `rig-journal/project-list/v2`
    return this.get<any[]>(uri,{
      params: {
        ...(payload || {}),
        pageSize: isFirstCall ? 1 : 1000000,
        pageIndex: 1,
      }
    })
      .pipe(
        map((item: any) => {
          return item?.data?.items;
        }),
        map((list: any) => {
          return (list || []).map((i: any) => {
            return {
              ...i,
              project: {
                ...i.project,
                projectName:
                  i.project.projectCurrentName || i.project.projectOriginalName,
              },
              wellbore: {
                ...i.wellbore,
                wellboreName:
                  i.wellbore.wellboreCurrentName ||
                  i.wellbore.wellboreOriginalName,
              },
              well: {
                wellName: i.well.wellCurrentName || i.well.wellOriginalName,
              },
            };
          });
        }),
        tap(() => this.RefreshRequired.next())
      )
      .pipe(catchError(() => of([])));
  }
  
  public getSearchRigByStringEDM(
    projectId: string,
    rigId?: string,
    searchString?: string
  ): Observable<any[]> {
    const uri = `rig-journal/rig-list?projectId=${projectId}${
      rigId ? '&rigId=' + rigId : ''
    }`;

    return this.get<any>(uri).pipe(
      map((item) => {
        return item.data;
      }),
      map((item) => {
        searchString = searchString ? searchString.trim() : '';

        if (!searchString) {
          return item;
        }
        // Filter by String.
        const regex = new RegExp(searchString, 'i');

        return item.filter((i: any) => {
          return regex.test(i.rigName);
        });
      }), // add projectName because cant use directly original or current
      map((item) => {
        return item.map((i: any) => {
          return {
            ...i,
            projectName: i.projectCurrentName || i.projectOriginalName,
          };
        });
      })
    );
  }

  public addFileRigJournal(
    rigJournalId: string,
    rigDataRouting: any,
    rigCommunicateProtocal: any,
    language: string
  ): Observable<any[]> {
    const uri = `rig-journal/edit/${rigJournalId}`;
    const formData = new FormData();
    formData.append('language', language);
    if (!rigDataRouting) {
      formData.append('isRmRouting', 'hasValue');
    } else if (typeof rigDataRouting === 'object') {
      const fileName = AppHelper.UtileFunctions.encodeFileName(rigDataRouting.name);
      formData.append('rigDataRouting', rigDataRouting, fileName);
    }
    if (!rigCommunicateProtocal) {
      formData.append('isRmProtocol', 'hasValue');
    } else if (typeof rigCommunicateProtocal === 'object') {
      const fileName = AppHelper.UtileFunctions.encodeFileName(rigCommunicateProtocal.name);
      formData.append('rigCommunicateProtocal', rigCommunicateProtocal, fileName);
    }
    return this.put<any, any>(uri, formData).pipe();
  }


  public addScopeRigJournal(
    scopePayload: any,
    rigJournalId: string
  ): Observable<any[]> {
    const uri = `rig-journal/edit-scope/${rigJournalId}`;
    return this.put<any, any>(uri, scopePayload).pipe();
  }

  public addDistributionList(
    XLSX_FILE: File,
    rigJournalId: string,
    language: string
  ): Observable<{ message: string; data: ProjectHomePageMask | null }> {
    const uri = `rig-journal/edit/${rigJournalId}`;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append(
      'Access-Control-Allow-Methods',
      'DELETE, POST, GET, OPTIONS'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'Content-Type, Authorization, X-Requested-With'
    );

    const formData = new FormData();
    const fileName = AppHelper.UtileFunctions.encodeFileName(XLSX_FILE.name);
    formData.append('rigDistributionList', XLSX_FILE, fileName);
    formData.append('language', language);

    return this.put<any, any>(uri, formData, { headers: headers }).pipe();
  }

  public removeDistributionList(rigJournalId: string): Observable<any> {
    const uri = `rig-journal/remove-distribution-list/${rigJournalId}`;
    return this.put<any, any>(uri).pipe();
  }

  public addContractor(contractorName: string): Observable<any> {
    const uri = 'contractor/create';
    const data = {
      contractorName: AppHelper.UtileFunctions.encodeBase64(contractorName),
    };
    return this.post<any, any>(uri, data);
  }

  public addRig(payload: any): Observable<any[]> {
    const uri = 'rig/create';
    AppHelper.UtileFunctions.encode64Payload(payload, ['rigName']);

    const formData = new FormData();
    formData.append('contractorId', payload.contractorId);
    formData.append('rigName', payload.rigName);
    formData.append('rigImage', payload.rigImage);
    formData.append('rigSpec', payload.rigSpec);

    return this.post<any, any>(uri, formData);
  }

  public addWellbore(payload: any): Observable<any[]> {
    const uri = 'rig-journal/create';

    return this.post<any, any>(uri, payload);
  }

  public updateWellbore(payload: any, wellboreId: string): Observable<any[]> {
    const uri = `wellbore/${wellboreId}/edit`;
    AppHelper.UtileFunctions.encode64Payload(payload, [
      'guid',
      'uwi',
      'wellboreName',
      'wellName',
      'siteName',
    ]);

    return this.put<any, any>(uri, payload);
  }

  public updateWellboreStatus(
    rigId: string,
    wellboreId: string
  ): Observable<any[]> {
    const uri = `rig-journal/change-status?rigId=${rigId}&wellboreId=${wellboreId}`;

    return this.put<any, any>(uri);
  }

  public addInterval(payload: any): Observable<any[]> {
    const uri = 'interval/create';
    AppHelper.UtileFunctions.encode64Payload(payload, ['intervalName']);

    if (payload.isNaCaseHoleLastShoe) {
      payload.casedHoleId = null;
      payload.lastShoeMeasuredDepth = null;
    }

    if (payload.isNaOpenHole) {
      payload.openHoleDiameter = null;
    }

    const formData = new FormData();
    formData.append('wellboreId', payload.wellboreId);
    formData.append('rigId', payload.rigId);
    formData.append('intervalName', payload.intervalName);
    formData.append('operation', payload.operation);
    formData.append('casedHoleId', payload.casedHoleId);
    formData.append('openHoleDiameter', payload.openHoleDiameter);
    formData.append('lastShoeMeasuredDepth', payload.lastShoeMeasuredDepth);
      
    if (payload.intervalDesignOfService?.name) {
      const fileName = AppHelper.UtileFunctions.encodeFileName(payload.intervalDesignOfService.name);
      formData.append('intervalDesignOfService', payload.intervalDesignOfService, fileName);
    } else {
      formData.append('intervalDesignOfService', payload.intervalDesignOfService);
    }

    formData.append('isNaCaseHoleLastShoe', payload.isNaCaseHoleLastShoe);
    formData.append('isNaOpenHole', payload.isNaOpenHole);

    if (payload.unit) {
      formData.append('unit', payload.unit);
    }

    return this.post<any, any>(uri, formData);
  }

  public updateInterval(payload: any, intervalId: string): Observable<any[]> {
    const uri = `interval/edit/${intervalId}`;
    AppHelper.UtileFunctions.encode64Payload(payload, ['intervalName']);

    if (payload.isNaCaseHoleLastShoe) {
      payload.casedHoleId = null;
      payload.lastShoeMeasuredDepth = null;
    }

    if (payload.isNaOpenHole) {
      payload.openHoleDiameter = null;
    }

    const formData = new FormData();
    formData.append('intervalName', payload.intervalName);
    formData.append('operation', payload.operation);
    formData.append('casedHoleId', payload.casedHoleId);
    formData.append('openHoleDiameter', payload.openHoleDiameter);
    formData.append('lastShoeMeasuredDepth', payload.lastShoeMeasuredDepth);

    if (payload.intervalDesignOfService) {
      if(typeof(payload.intervalDesignOfService) !=='string'){
        const fileName = payload.intervalDesignOfService?.name
          ? AppHelper.UtileFunctions.encodeFileName(payload.intervalDesignOfService.name)
          : payload.intervalDesignOfService;
        formData.append(
          'intervalDesignOfService',
          payload.intervalDesignOfService,
          fileName
        );
        
      }
    } else {
      formData.append('isRmDesign', 'hasValue');
    }

    formData.append('isNaCaseHoleLastShoe', payload.isNaCaseHoleLastShoe);
    formData.append('isNaOpenHole', payload.isNaOpenHole);

    if (payload.unit) {
      formData.append('unit', payload.unit);
    }

    return this.put<any, any>(uri, formData);
  }

  public addRun(payload: any): Observable<any[]> {
    const uri = 'run/create';
    AppHelper.UtileFunctions.encode64Payload(payload, ['runDescription']);

    const formData = new FormData();
    formData.append('intervalId', payload.intervalId);
    formData.append('description', payload.runDescription);
    formData.append('type', payload.type);
    formData.append('runNo', payload.runNumber);
    formData.append('rigBhaNo', payload.rigBHANumber);
    formData.append('bitSize', payload.bitSize);
    formData.append('reamerSize', payload.reamerSize);
    formData.append('casingSize', payload.casingStringSize);
    formData.append('startMeasuredDepth', payload.startMeasuredDepth);
    formData.append('tdMeasuredDepth', payload.TDMeasuredDepth);
    formData.append('belowRotaryTimestamp', payload.belowRotaryTimestamp);
    formData.append('runTdTimestamp', payload.runTDTimestamp);
    formData.append('aboveRotaryTimestamp', payload.runTDAboveRotaryTimestamp);

    if (payload.tally?.name) {
      const fileName = AppHelper.UtileFunctions.encodeFileName(payload.tally.name);
      formData.append('tally', payload.tally, fileName);
    } else {
      formData.append('tally', payload.tally);
    }

    if (payload.unit) {
      formData.append('unit', payload.unit);
    }

    return this.post<any, any>(uri, formData);
  }

  public updateRun(payload: any, runId: string): Observable<any[]> {
    const uri = `run/edit/${runId}`;
    AppHelper.UtileFunctions.encode64Payload(payload, ['runDescription']);

    const formData = new FormData();
    formData.append('description', payload.runDescription);
    formData.append('runNo', payload.runNumber);
    formData.append('rigBhaNo', payload.rigBHANumber);
    formData.append('type', payload.type);
    formData.append('bitSize', payload.bitSize);
    formData.append('reamerSize', payload.reamerSize);
    formData.append('casingSize', payload.casingStringSize);
    formData.append('belowRotaryTimestamp', payload.belowRotaryTimestamp);
    formData.append('startMeasuredDepth', payload.startMeasuredDepth);
    formData.append('runTdTimestamp', payload.runTDTimestamp);
    formData.append('tdMeasuredDepth', payload.TDMeasuredDepth);
    formData.append('aboveRotaryTimestamp', payload.runTDAboveRotaryTimestamp);

    if (payload.tally) {
      if (payload.tally?.name) {
        const fileName = AppHelper.UtileFunctions.encodeFileName(payload.tally.name);
        formData.append('tally', payload.tally, fileName);
      } else {
        formData.append('tally', payload.tally);
      }
    } else {
      formData.append('isRmTally', 'hasValue');
    }

    if (payload.unit) {
      formData.append('unit', payload.unit);
    }
    return this.put<any, any>(uri, formData);
  }

  public editRig(payload: any, id: string): Observable<any[]> {
    const uri = `rig/edit/${id}`;

    AppHelper.UtileFunctions.encode64Payload(payload, ['rigName']);
    const formData = new FormData();
    formData.append('contractorId', payload.contractorId);
    formData.append('rigName', payload.rigName);
    formData.append('rigType', payload.rigType);
    formData.append('rigImage', payload.rigImage);
    formData.append('rigSpec', payload.rigSpec);

    return this.put<any, any>(uri, formData);
  }

  public editProject(payload: any, projectId: string): Observable<any[]> {
    const uri = `project/${projectId}/edit`;
    AppHelper.UtileFunctions.encode64Payload(payload, [
      'projectName',
      'policyName',
      'lease',
      'field',
      'block',
    ]);

    return this.put<any, any>(uri, payload);
  }

  public getPrimaryContact(rigJournalId: string): Observable<any> {
    const uri = `rig-journal/project-list/${rigJournalId}`;

    return this.get<any>(uri).pipe(
      map((item) => {
        return item.distributionList.find((contact: any) => {
          if (contact.sheetName === 'PRIMARY POINTS OF CONTACT') {
            return contact;
          }
        });
      }),
      map((item) => {
        return item?.data ? item.data : [];
      })
    );
  }

  public deleteRun(runId: string): any {
    const uri = `run/${runId}`;
    return this.delete<any>(uri).pipe();
  }

  public deleteInterval(intervalId: string): any {
    const uri = `interval/${intervalId}`;
    return this.delete<any>(uri).pipe();
  }

  public deleteWellbore(rigJournalId: string): any {
    const uri = `rig-journal/${rigJournalId}`;
    return this.delete<any>(uri).pipe(
      tap((res) => {
        this._io.emit('delete-wellbore', res);
        this.RefreshRequired.next();
      })
    );
  }

  public deleteRig(rigId: string): any {
    const uri = `rig-journal/${rigId}`;
    return this.delete<any>(uri).pipe();
  }

}
