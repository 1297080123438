import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, finalize, map, Observable, tap } from 'rxjs';
import { Subject } from 'rxjs';
import { AppHelper } from '../utilities/app.helper';
import { PaginationData } from '../interface/pagination-data.interface';

// Constant Value

@Injectable()
export class RigService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getConstractors(): Observable<any[]> {
    const me = this;
    const uri = `contractor`;
    return me.get<any[]>(uri).pipe();
  }

  public getRigs(): Observable<any[]> {
    const me = this;
    const uri = `rig`;
    return me.get<any[]>(uri).pipe();
  }

  public addContractor(contractorName: string): Observable<any> {
    const me = this;
    const uri = 'contractor/create';

    let data = {
      contractorName: AppHelper.UtileFunctions.encodeBase64(contractorName),
    };
    return me.post<any, any>(uri, data);
  }

  public addRig(payload: any): Observable<any[]> {
    const me = this;
    const uri = 'rig/create';
    AppHelper.UtileFunctions.encode64Payload(payload, ['rigName']);

    // Encode object for Rig Type
    let rigTypeData = btoa(JSON.stringify(payload.rigType));

    const formData = new FormData();
    formData.append('contractorId', payload.contractorId);
    formData.append('rigName', payload.rigName);
    formData.append('rigType', rigTypeData);

    if (payload?.rigImage) {
      const fileNameRigImage = AppHelper.UtileFunctions.encodeFileName(payload.rigImage.name);
      formData.append('rigImage', payload.rigImage,fileNameRigImage);
    }
  
    if (payload?.rigSpec) {
      const fileNameRigSpec = AppHelper.UtileFunctions.encodeFileName(payload.rigSpec.name);
      formData.append('rigSpec', payload.rigSpec, fileNameRigSpec);
    }

    return me.post<any, any>(uri, formData);
  }

  // Need more rig to check replace document or not?
  public editRig(payload: any, id: string, rig: any): Observable<any[]> {
    const me = this;
    const uri = `rig/edit/${id}`;
    AppHelper.UtileFunctions.encode64Payload(payload, ['rigName']);

    // Encode object for Rig Type
    let rigTypeData = btoa(JSON.stringify(payload.rigType));

    const formData = new FormData();
    formData.append('contractorId', payload.contractorId);
    formData.append('rigName', payload.rigName);
    formData.append('rigType', rigTypeData);

    if (!payload?.rigImage) {
      formData.append('isRmImage', 'hasValue');
    } else if (typeof payload?.rigImage === 'object') {
      const fileName = AppHelper.UtileFunctions.encodeFileName(payload.rigImage.name);
      formData.append('rigImage', payload.rigImage, fileName);
    }

    if (!payload?.rigSpec) {
      formData.append('isRmSpec', 'hasValue');
    } else if (typeof payload?.rigSpec === 'object') {
      const fileName = AppHelper.UtileFunctions.encodeFileName(payload.rigSpec.name);
      formData.append('rigSpec', payload.rigSpec, fileName);
    }
    // undefine mean delete in Database
    return me.put<any, any>(uri, formData);
  }

  public editContractor(
    contractorId: string,
    contractorName: string
  ): Observable<any> {
    const me = this;
    const uri = `contractor/edit/${contractorId}`;
    let data = {
      contractorName: AppHelper.UtileFunctions.encodeBase64(contractorName),
    };
    return me.put<any, any>(uri, data);
  }

  public editImageRig(
    rigImage: File,
    id: string,
    rigName: string
  ): Observable<any[]> {
    const me = this;
    const uri = `rig/edit/${id}`;

    const formData = new FormData();
    const fileName = AppHelper.UtileFunctions.encodeFileName(rigImage.name);
    formData.append('rigImage', rigImage, fileName);

    return me.put<any, any>(uri, formData);
  }

  public editSpecsRig(
    rigSpec: File,
    id: string,
    rigName: string
  ): Observable<any[]> {
    const me = this;
    const uri = `rig/edit/${id}`;

    const formData = new FormData();
    const fileName = AppHelper.UtileFunctions.encodeFileName(rigSpec.name);
    formData.append('rigSpec', rigSpec, fileName);

    return me.put<any, any>(uri, formData);
  }

  public deleteRig(rigId: string): any {
    const me = this;
    let uri = `rig/delete/${rigId}`;
    return me.delete<any>(uri).pipe();
  }

  // contractorString: string
  public getSearchRigByStringEDM(
    projectId: string,
    searchString: string,
    rigTypeHierarchy: string,
    contractorString: string,
    timeDebounce: number
  ): Observable<any[]> {
    const me = this;
    const uri = `rig`;

    searchString = searchString && searchString.trim();

    return me.get<any>(uri).pipe(
      debounceTime(timeDebounce),
      map((item) => {
        return item.data;
      }),
      map((item) => {
        return this.loadHierarchy(item);
      }),
      map((item) => {
        if (!rigTypeHierarchy || rigTypeHierarchy?.length === 0) {
          return item;
        }
        return item.filter((rig: any) => {
          return rig.rigTypeName.rigTypeHierarchy.includes(rigTypeHierarchy);
        });
      }),
      map((item) => {
        if (!contractorString || contractorString?.length === 0) {
          return item;
        }
        let resultCon: any[] = [];
        const conditions = [...contractorString];
        conditions.forEach((condition: any) => {
          const resultCondition = item.filter(
            (x: any) => x.contractorId === condition
          );
          resultCon = [...resultCon, ...resultCondition];
        });

        return resultCon;
      }),
      map((item) => {
        if (!searchString) {
          return item;
        }
        // Filter by String.
        const escapedSearchString = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedSearchString, 'i');

        return item.filter((i: any) => {
          return regex.test(i.rigName);
        });
      })
    );
  }

  public getSearchRigByStringEDMV2(
    searchString: string,
    rigTypeHierarchy: string,
    contractorString: string,
    timeDebounce: number,
    pageSize?: number,
    pageIndex?: number
  ): Observable<PaginationData<any>> {
    const me = this;
    let params: any = {
      pageSize: pageSize || 1000000,
      pageIndex: pageIndex || 1,
    };
    searchString && (params['searchKeyword'] = searchString.trim());
    rigTypeHierarchy && (params['rigType'] = rigTypeHierarchy);
    contractorString && (params['contractorIds'] = contractorString);
    return me.get<any>('rig/v2', {params}).pipe(
      debounceTime(timeDebounce),
      map((res) => {
        const data = res.data || {};
        return {
          ...data,
          items: this.loadHierarchy(data?.items || []),
        }
      })
    );
  }

  loadHierarchy(arr: any) {
    return (arr = arr.map((rig: any) => {
      if (!rig.rigTypeName) {
        return rig;
      }
      let rigTypeName: any;
      try {
        rigTypeName = JSON.parse(atob(rig.rigTypeName));
        return {
          ...rig,
          rigTypeName: rigTypeName,
        };
      } catch (e) {
        return {
          ...rig,
          rigTypeName: 'N/A',
        };
      }
    }));
  }
}
