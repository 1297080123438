<p-card header="" class="video-card">
  <div class="relative video-thumbnail">
    <img
      [src]="video.imageUrl"
      class="thumbnail-image"
    />
    <div class="video-thumbnail-overlay">
      <fa-icon [icon]="playIcon" class="play-button" (click)="openVideo()"></fa-icon>
    </div>
    <div class="absolute top-0 right-0 option-video" *ngIf="isAdmin || isViewer">
      <button
        type="button"
        pButton
        icon="pi pi-ellipsis-v"
        [ngClass]="{'active': isMenuShown}"
        (click)="menu.toggle($event)"
      ></button>
    </div>
  </div>
  <div class="video-card-content">
    <div class="flex flex-row title-video-name">
      <div class="header-video" [title]="video.title">
        <span class="cursor-pointer" (click)="openVideo()">{{ video.title }}</span>
      </div>
      <!-- <i class="pi pi-share-alt share-video-icon cursor-pointer"></i> -->
    </div>
    <p class="description-video">
      {{ video.description }}
    </p>
    <div class="grid time-and-date-video">
      <div class="col-7 flex flex-row justify-content-between">
        <span class="time-and-date-video-font">{{ video.views || 0 }} view{{ video.views > 1 ? 's' : ''}}</span>
        <span class="time-and-date-video-font">{{ video.createdAtFormat }}</span>
      </div>
      <div class="col-5 flex justify-content-end">
        <span>
          <mat-icon>comment</mat-icon>
        </span>
      </div>
    </div>
  </div>
</p-card>
<p-menu #menu [popup]="true" [model]="items" (onShow)="onMenuShow()" (onHide)="onMenuHide()"></p-menu>
