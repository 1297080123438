import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { MailBoxService } from 'src/app/shared/services/mailbox.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { MailboxAdvancedFilterComponent } from '../mailbox-advanced-filter/mailbox-advanced-filter.component';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { IConfirmDialog, TableListColumn } from 'src/app/shared/interface/common';
import { MailBox } from 'src/app/shared/interface/mailbox/mailbox.interface';
import { Menu } from 'primeng/menu';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';

@Component({
  selector: 'app-mailbox-management',
  templateUrl: './mailbox-management.component.html',
  styleUrls: ['./mailbox-management.component.scss'],
})
export class MailboxManagementComponent extends BaseComponent {
  isLoading: boolean = false;

  isViewer: boolean = false;

  mailBoxEdit: MailBox = {
    disciplineList: [],
    isActive: true,
    mailboxId: '',
    mailboxName: '',
    remoteCenter: {
      remoteCenterId: '',
      remoteCenterName: '',
    },
    remoteCenterId: '',
  };

  mailBoxDelete: MailBox = {
    disciplineList: [],
    isActive: true,
    mailboxId: '',
    mailboxName: '',
    remoteCenter: {
      remoteCenterId: '',
      remoteCenterName: '',
    },
    remoteCenterId: '',
  };

  // Variables for Filter Chips
  chipsFilter: String[] = [];
  chipFiltersKey: string[] = [];

  AppHelper = AppHelper;

  mailBoxListColumn: TableListColumn[] = [
    { field: 'mailboxName', header: 'Functional Mailbox' },
    { field: 'displayDiscipline', header: 'Discipline' },
    { field: 'remoteCenter.remoteCenterName', header: 'Remote Center' },
    { field: 'isActive', header: 'Status' },
    { field: 'features', header: 'x' },
  ];

  msgFromApis = 'No result found';

  mailBoxListData: MailBox[] = []; //MailBox
  mailBoxListDataTemp: MailBox[] = [];

  restoreMailBoxListData: MailBox[] = [];
  cacheMailBoxListData: MailBox[] = [];

  payloadFilter?: any;

  // Variable of Form
  @ViewChild('searchForm') searchForm!: NgForm;
  @ViewChild('advancedFilter') advancedFilter:
    | MailboxAdvancedFilterComponent
    | undefined;

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  previewSearch: string = '';
  subjectSearchString = new Subject<string>();

  displayAddFormMailBoxDialog: boolean = false;
  displayEditFormMailBoxDialog: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;
  
  constructor(
    private _mailBoxService: MailBoxService,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    this.isLoading = true;

    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
        }
        this.loadInitialData();
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData() {
    // Obsver Filter
    this.subjectSearchString
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((searchTextValue) => {
        // this.previewSearch = searchTextValue;
        this.filterMailBoxManagement(1000);
      });
    //
  }

  ngAfterViewInit(): void {
    this.filterMailBoxManagement(1000);
  }

  filterMailBoxManagement(timeDebounce: number) {
    let formValue = this.searchForm.value;

    if (this.searchForm.value) {
      this.isLoading = true;
      this._mailBoxService
        .getMailBoxsByStringNonAssign(formValue.searchString, timeDebounce)
        .pipe(
          takeUntil(this.destroy$),
          take(1),
          finalize(() => (this.isLoading = false)),
          switchMap((res: any) => {
            this.mailBoxListDataTemp = res;
            this.mailBoxListData = res;
            this.mailBoxListData = AppHelper.UtileFunctions.sortArrayBykey(
              this.mailBoxListData,
              'mailboxName'
            );

            this.mailBoxListData = [...this.mailBoxListDataTemp];

            this.loadDisciplineName();

            this.restoreMailBoxListData = JSON.parse(
              JSON.stringify(this.mailBoxListData)
            );
            this.cacheMailBoxListData = JSON.parse(
              JSON.stringify(this.mailBoxListData)
            );
            this.loadPayloadFilter(this.payloadFilter);
            return of([res]);
          })
        )
        .subscribe({});
    }
  }
  loadDisciplineName() {
    this.mailBoxListData = this.mailBoxListData.map((mailbox: any) => {
      if (mailbox.disciplineList.length) {
        let displayDiscipline = mailbox.disciplineList
          .map((discipline: any) => discipline.disciplineName)
          .join(', ');
        return {
          ...mailbox,
          displayDiscipline: displayDiscipline,
        };
      } else {
        return {
          ...mailbox,
          displayDiscipline: 'N/A',
        };
      }
    });
  }

  loadChipFilter(itemFilter: any) {
    let items = Object.assign({}, itemFilter);
    this.chipFiltersKey = Object.keys(items);
    this.chipsFilter = [];

    Object.keys(items).forEach((item: any) => {
      switch (items[item][0]) {
        default:
          this.chipsFilter.push(items[item][0]);
          break;
      }
    });
  }

  loadPayloadFilter(payloadFilter: any) {
    this.payloadFilter = payloadFilter;

    this.mailBoxListData = JSON.parse(
      JSON.stringify(this.restoreMailBoxListData)
    );

    if (
      this.payloadFilter === undefined ||
      (this.payloadFilter.discipline.length === 0 &&
        this.payloadFilter.remoteCenter.length === 0)
    ) {
      this.mailBoxListData = JSON.parse(
        JSON.stringify(this.restoreMailBoxListData)
      );
    } else {
      this.mailBoxListData = this.filterPayloadData(
        this.mailBoxListData,
        this.payloadFilter
      );
    }

    this.cacheMailBoxListData = JSON.parse(
      JSON.stringify(this.mailBoxListData)
    );

    this.keyupSearch();
  }

  filterPayloadData = (originalData: any[], condition: any): any[] => {
    return originalData.filter((item) => {
      const matchingDisciplines = condition.discipline
        ? condition.discipline.some((disciplineId: any) =>
            item.disciplineList.some(
              (discipline: any) => discipline.disciplineId === disciplineId
            )
          )
        : true;

      const matchingRemoteCenters = condition.remoteCenter
        ? condition.remoteCenter.length === 0 ||
          (item.remoteCenterId &&
            condition.remoteCenter.includes(item.remoteCenterId))
        : true;

      if (
        condition.discipline.length > 0 &&
        condition.remoteCenter.length > 0
      ) {
        return matchingDisciplines && matchingRemoteCenters;
      } else if (
        condition.discipline.length > 0 &&
        condition.remoteCenter.length === 0
      ) {
        return matchingDisciplines;
      } else if (
        condition.discipline.length === 0 &&
        condition.remoteCenter.length > 0
      ) {
        return matchingRemoteCenters;
      }
    });
  };

  removeItemChip(item: any, i: number) {
    let keyItemRemove: string = this.chipFiltersKey[i];
    this.chipsFilter.splice(i, 1);
    this.chipFiltersKey.splice(i, 1);

    // If clear all filter chip that mean reset all data
    this.advancedFilter?.onResetFilter(this.chipsFilter.length !== 0 ? keyItemRemove : 'all'); 

    this.payloadFilter[keyItemRemove] = [];
    this.loadPayloadFilter(this.payloadFilter);
  }
  clearFilter(): void {
    this.advancedFilter?.onResetFilter('all');

    this.searchForm.controls['searchString'].setValue('');

    this.chipsFilter = [];

    this.payloadFilter = {
      discipline: [],
      remoteCenter: [],
    };

    this.filterMailBoxManagement(0);
  }
  keyupSearch(e?: any) {
    let searchTextValue = this.searchForm.controls['searchString'].value;
    this.mailBoxListData = JSON.parse(
      JSON.stringify(this.cacheMailBoxListData)
    );

    if (searchTextValue) {
      let regex = new RegExp(searchTextValue, 'i');
      this.mailBoxListData = this.mailBoxListData.filter((mailbox: any) => {
        return regex.test(mailbox.mailboxName);
      });
    } else {
      this.mailBoxListData = JSON.parse(
        JSON.stringify(this.cacheMailBoxListData)
      );
    }
  }

  showContextMenu(event: any, inputData: MailBox) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(mailBox: MailBox): any[] {
    return [
      {
        label: 'Edit',
        icon: 'c-icons edit-icon',
        command: () => {
          this.mailBoxEdit = mailBox;
          this.displayEditFormMailBoxDialog = true;
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        disabled: this.isViewer,
        command: () => {
          this.mailBoxDelete = mailBox;
          
          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this functional mailbox (${mailBox.mailboxName}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete it.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  hideFormMailBoxDialog(value: boolean) {
    this.displayAddFormMailBoxDialog = value;
    this.displayEditFormMailBoxDialog = value;
  }

  reloadMailBoxManagement(event: any) {
    this.filterMailBoxManagement(0);
  }

  handleChangeStatus(mailBoxEdit: any) {
    this.updateMailBoxStatus(mailBoxEdit);
  }

  updateMailBoxStatus(mailBoxEdit: any) {
    this.isLoading = true;
    this._mailBoxService
      .updateMailboxStatus(
        mailBoxEdit.mailboxId,
        mailBoxEdit.isActive ? !mailBoxEdit.isActive : true
      )
      .pipe(catchError(AppHelper.UtileFunctions.handleError))
      .subscribe({
        next: (response: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Update Mailbox Status',
            content: `${mailBoxEdit?.mailboxName} was updated successfully!`,
          });
          this.filterMailBoxManagement(0);
        },
        error: (error: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Update Mailbox Status',
            content: `Your request to update status ${mailBoxEdit?.mailboxName} was denied!! ${error?.message}`,
          });
          this.filterMailBoxManagement(0);
        },
      });
  }

  deleteMailBox(mailBoxDelete: any) {
    this.isLoading = true;
    this._mailBoxService
      .deleteMailbox(mailBoxDelete.mailboxId)
      .pipe(catchError(AppHelper.UtileFunctions.handleError))
      .subscribe({
        next: (response: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Delete Mailbox',
            content: `${mailBoxDelete?.mailboxName} was deleted successfully!`,
          });
          this._confirmService.clearDialog();
          this.filterMailBoxManagement(0);
        },
        error: (error: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Delete Mailbox',
            content: `Your request to delete ${mailBoxDelete?.mailboxName} was denied!! ${error?.message}`,
          });

          // Close Confirm Delete Dialog and Loading
          this._confirmService.clearDialog();
          this.isLoading = false;
        },
      });
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Delete':
            this.deleteMailBox(this.mailBoxDelete);
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }
  onDestroy(): void {}
}
