import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  EditProject,
  RigMark,
} from '../../../shared/interface/project.interface';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { HomeDistributionListComponent } from './../home-distribution-list/home-distribution-list.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RigLevelDataToAddWellbore } from 'src/app/shared/models/rig.model';
import { Router } from '@angular/router';
import { passingExtendInfoService } from 'src/app/shared/services/passingExtendInfo.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { BehaviorSubject, finalize, of, switchMap, take, tap } from 'rxjs';
import { BackPortalService } from 'src/app/shared/services/back-portal.service';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog, IDataSelection, IDropdownSelection } from 'src/app/shared/interface/common';
import { OptionButtonAndIdType, OptionButtonType } from 'src/app/shared/type';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { PassingMailboxService } from 'src/app/shared/services/passingMailbox.service';
import { DistributionService } from 'src/app/shared/services/distribution.service';
import { Menu } from 'primeng/menu';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';
import { DialogService } from 'primeng/dynamicdialog';
import { ExportSettingComponent } from 'src/app/shared/components/export-setting/export-setting.component';
import { HomeFilterService } from 'src/app/shared/services/home-filter.service';

@Component({
  selector: 'app-home-project-item',
  templateUrl: './home-project-item.component.html',
  styleUrls: ['./home-project-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeProjectItemComponent implements OnInit, OnChanges {
  @Input() project: RigMark[] = [];
  @Input() userRole: string = '';

  @Output() showRequestReloadHomePage = new EventEmitter<string>();
  @Output() requestLoadingTheme = new EventEmitter<boolean>();

  // activeRidId
  @Input() activeRigCard: { rigJournalId: string, rigName: string } = {
    rigJournalId: '',
    rigName: ''
  };

  @Output() passActiveRigCard = new EventEmitter<{ rigJournalId: string, rigName: string }>();

  @Input() isSkeleton: boolean = true;

  // Variable decide show/hide sidebar import distribution list
  displaySidebarImportDistribution: boolean = false;
  rigNameImportDistribution: string = '';
  rigProjectImportDistribution: string = '';
  rigOperatorImportDistribution: string = '';
  rigJournalId: string = '';
  rigId: string = '';
  distributionUrl: string = '';
  // Variable for percent import distribution
  percentImport: number = 0;

  // Status decide template of sidebar import distribution list
  uploadStatus: string = '';
  reuploadStatus!: boolean | number;

  @ViewChild('distributionList')
  distributionList!: HomeDistributionListComponent;

  @ViewChild('menuAddDistribution') menuAddDistribution: any;

  @ViewChild('menu') menu: any;

  // Setting for popup edit project via rig card
  displayEditProject: boolean = false;
  editProject!: EditProject;

  // Setting for popup add wellbore in Rig Card Level
  display_menuAddWellbore: boolean = false;
  dataMenuAddWellboreRigCardLevel!: RigLevelDataToAddWellbore;

  displayFormRig: boolean = false;
  rigEditId: string = '';
  rigJournalEditId: string = '';
  projectNameEdit: string = '';
  rigStatistics: any[] = [];
  rig: any;
  AppHelper = AppHelper;
  hasMsg: boolean = false;

  // tracking intervence button
  createInterveneConditon = {
    isValid: false,
    isEditProject: false,
    isEditRig: false,
    hasWellActive: false,
    hasDistributionList: false,
  };

  isAdmin: boolean = false;
  isViewer: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  selectedMailBox: any = [];

  canEditProject: boolean = false;

  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  remoteCenterHeader: string[] = [];
  remoteCenterString: string = '';

  constructor(
    private _notificationService: NotificationService,
    private _router: Router,
    private _blobService: AzureBlobService,
    private _passingDataService: passingExtendInfoService,
    private _homeService: HomeService,
    private _interventionService: InterventionService,
    private _backPortalService: BackPortalService,
    private _confirmService: ConfirmDialogService,
    private _userInfoService: UserInfoService,
    private _passingMailboxService: PassingMailboxService,
    private _distributionService: DistributionService,
    private _dialogService: DialogService,
    private _homeFilterService: HomeFilterService
  ) { }

  ngOnInit() {
    this.canEditProject = this._userInfoService.hasPermission(MODULE_NAME.PROJECT, USER_PERMISSION.EDIT);

    this.isAdmin = this.userRole === AppConstant.ROLES.ADMIN.label;
    this.isViewer = this.userRole === AppConstant.ROLES.VIEWER.label;

    // this.project.interventionFlag
    this.project.forEach((element) => {
      // Sort Flag Statistic
      element.flagStatistic = AppHelper.UtileFunctions.sortPrimaryFlag(
        element.interventionFlag
      ).slice(0, 4);

      if (!element.rig.imageUrl) {
        element.rig.rigImageBlob = '';
      } else {
        element.rig.rigImageBlob = this._blobService.getRigImage(
          decodeURIComponent(
            AppHelper.StringFunctions.getFileName(element.rig.imageUrl)
          )
        );
      }

      // Encode for url default image
      if (element.rig.rigTypeName) {
        const decodeRigType = JSON.parse(
          AppHelper.UtileFunctions.decodeIfIsBase64(element.rig.rigTypeName)
        );
        element.rig.rigTypeDecoded = decodeRigType;
        element.rig.rigDefaultImage = `../../../../assets/images/default-rigs/${encodeURIComponent(
          element.rig.rigTypeDecoded.rigDefaultImage
        )}`;
      }

      if (element.remoteCenterList.length) {
        element.remoteCenterList.forEach(remote => {
          this.remoteCenterHeader.push(remote.remoteCenterName);
        })
      }

      // Custom Data Source Name
      element.policy.dataSource = this.getDataSourceObject(
        element.policy.edmSource || ''
      );
    });

    this.remoteCenterHeader = [...new Set(this.remoteCenterHeader)];
    this.remoteCenterString = this.remoteCenterHeader.join(', ');

    this.project = AppHelper.UtileFunctions.sortByKeyNestObject(
      this.project,
      'rig',
      'rigName'
    );

    this.updateButtonCondition();

    this._distributionService.rigcardUpdate$.subscribe((updateRigCard: any) => {
      if (updateRigCard.rigJournalId) {
        if (updateRigCard.updateType === 'Distribution') {
          let isFound = false;
          this.project = this.project.map((rigCard: RigMark) => {
            if (rigCard.rigJournalId === updateRigCard.rigJournalId) {
              isFound = true;
              return {
                ...rigCard,
                distributionListUrl: updateRigCard.distributionListUrl,
              };
            } else {
              return rigCard;
            }
          });
          if (isFound)
            this.updateButtonCondition();
        }

        if (updateRigCard.updateType === 'RigTile') {
          let isFound = false;
          this.project = this.project.map((rigCard: RigMark) => {
            if (rigCard.rigJournalId === updateRigCard.rigJournalId) {
              isFound = true;
              return {
                ...rigCard,
                distributionListUrl: updateRigCard.rigFile.distributionListUrl,
                communicateProtocolUrl: updateRigCard.rigFile.communicateProtocolUrl,
                dataRoutingUrl: updateRigCard.rigFile.dataRoutingUrl,
                remoteCenterListName: updateRigCard.rigRemoteListName,
                mailboxList: updateRigCard.mailboxList,
                language: updateRigCard?.language
              };
            } else {
              return rigCard;
            }
          });
          if (isFound)
            this.updateButtonCondition();
        }

        setTimeout(() => {
          if (updateRigCard.updateType === 'StatusActive') {
            this.activeRigCard = {
              rigJournalId: updateRigCard.rigJournalId,
              rigName: updateRigCard.rig.rigName
            };
            this.passActiveRigCard.emit(this.activeRigCard);
          }
        }, 300);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Case back from another Component
    changes?.project?.currentValue?.[0]?.rigJournalId

    if (changes?.activeRigCard?.currentValue?.rigJournalId && 
      changes?.activeRigCard?.previousValue === undefined && 
      changes?.activeRigCard?.firstChange &&
      changes?.project?.currentValue?.[0]?.rigJournalId === changes?.activeRigCard?.currentValue?.rigJournalId
    ) {
      console.log('Changes: Case Back From Another Component then keep filter');
      const { rigJournalId, rigName } = changes?.activeRigCard?.currentValue;
      this.executeFilter(rigJournalId, rigName);
    }
  }

  updateButtonCondition() {
    this.project = this.project.map((rig: any) => {
      if (!rig.project.curUnit) {
        return {
          ...rig,
          rigCondition: `The project's attributes must be updated`,
        };
      } else if (!rig.mailboxList.length || !rig.remoteCenterList.length) {
        return {
          ...rig,
          rigCondition: `The rig's attributes must be updated`,
        };
      } else if (!rig.distributionListUrl) {
        return {
          ...rig,
          rigCondition: `Distribution lists must be added to the rig tile`,
        };
      } else if (rig.isComplete) {
        return {
          ...rig,
          rigCondition: `A wellbore must be set to active`,
        };
      } else {
        return {
          ...rig,
          rigCondition: ``,
        };
      }
    });
  }

  ngAfterViewInit() { }
  getDataSourceObject(dataSourceName: string) {
    const defaultValue = {
      sourceId: 'idOfHPM',
      sourceName: 'HPM',
      displaySourceName: 'Global HPM',
      databaseSource: 'EDM',
    };
    return AppConstant.DATA_SOURCES[`${dataSourceName}`] || defaultValue
  }

  showContextMenu(event: any, inputData: RigMark) {
    this.getMenuItemsForItem$.next(this.getMenuItemsForItem(inputData))
    this.menu.toggle(event);
  }

  getMenuItemsForItem(rigTile: RigMark): any {
    const canAdd = this._userInfoService.hasPermission(MODULE_NAME.WELLBORE, USER_PERMISSION.ADD);

    return [
      {
        label: 'Distribution Lists',
        icon: 'c-icons file-icon',
        command: () => {
          this.requestLoadingTheme.emit(true);
          this._homeService
            .getRigJournalbyId(rigTile.rigJournalId)
            .pipe(
              finalize(() => {
                this.requestLoadingTheme.emit(false);
              })
            )
            .subscribe({
              next: (res: any) => {
                const newRig: RigMark = res.data
                rigTile.mailboxList = newRig.mailboxList
                rigTile.remoteCenterList = newRig.remoteCenterList
                rigTile.distributionListUrl = newRig.distributionListUrl
                rigTile.isComplete = newRig.isComplete
                rigTile.dataRoutingUrl = newRig.dataRoutingUrl
                rigTile.communicateProtocolUrl = newRig.communicateProtocolUrl

                this.distributionUrl = rigTile.distributionListUrl || '';
                this.uploadStatus = rigTile.distributionListUrl
                  ? 'uploading'
                  : 'uploadYet';
                this.displaySidebarImportDistribution = true;
                this.rigNameImportDistribution = rigTile.rig.rigName;
                this.rigProjectImportDistribution = rigTile.project.projectName;
                this.rigOperatorImportDistribution =
                  rigTile.policy.policyCurrentName ||
                  rigTile.policy.policyOriginalName;
                this.rigJournalId = rigTile.rigJournalId;
                this.rigId = rigTile.rig.rigId;

                // UPDATE =================================================================
                let payloadFilterLog = this._homeService.getPayloadFilter();

                // Temporarily with rigJournalId
                payloadFilterLog = {
                  ...payloadFilterLog,
                  rigJournalId: [rigTile.rigJournalId]
                };

                // Call API get Alert Log with payload filter
                this.filterAlertLog(payloadFilterLog, rigTile.rig.rigName);

                this._distributionService.setDisplay({
                  render: {
                    isShow: true,
                    template: this.uploadStatus,
                    reuploadStatus: false,
                  },
                });
                this._distributionService.setDisData({
                  data: {
                    rigId: this.rigId,
                    rigJournalId: this.rigJournalId,
                    rigOperator: this.rigOperatorImportDistribution,
                    rigProject: this.rigProjectImportDistribution,
                    rigName: this.rigNameImportDistribution,
                    language: rigTile.language
                  },
                });
                // get new
                this._distributionService.setDisURL({
                  link: {
                    distributionUrl: this.distributionUrl,
                  },
                });
              },
              error: (error) => {
                console.log(error);
              },
            });
        }
      },
      {
        label: 'Edit Rig Tile',
        visible: this.isAdmin || this.isViewer,
        icon: 'c-icons edit-icon',
        command: () => {
          this.displayFormRig = true;
          this.rigEditId = rigTile.rig.rigId;
          this.rigJournalEditId = rigTile.rigJournalId;
          this.projectNameEdit = rigTile.project.projectName;

          // Set data and log of Recent Alert Logs
          this.executeFilter(rigTile.rigJournalId, rigTile.rig.rigName);

          // Keep focus for rig card
          this.activeRigCard = {
            rigJournalId: rigTile.rigJournalId,
            rigName: rigTile.rig.rigName
          }
          this.passActiveRigCard.emit(this.activeRigCard);
        },
      },
      {
        label: 'Add Wellbore',
        icon: 'c-icons addwell-icon',
        visible: canAdd || this.isViewer,
        command: () => {
          // Set data and log of Recent Alert Logs
          this.executeFilter(rigTile.rigJournalId, rigTile.rig.rigName);

          // Keep focus for rig card
          this.activeRigCard = {
            rigJournalId: rigTile.rigJournalId,
            rigName: rigTile.rig.rigName
          }
          this.passActiveRigCard.emit(this.activeRigCard);

          this.display_menuAddWellbore = true;
          this.dataMenuAddWellboreRigCardLevel = {
            source: {
              sourceId: rigTile.policy.edmSource || '',
              sourceName: rigTile.policy.edmSource || '',
              displaySourceName: AppHelper.StringFunctions.sourceName(
                rigTile.policy.edmSource || ''
              ),
            },
            company: {
              policyId: rigTile.policy.policyId,
              policyName: rigTile.policy.policyOriginalName,
            },
            project: {
              projectId: rigTile.project.projectId,
              projectName: rigTile.project.projectOriginalName,
            },
            contractor: {
              contractorId: rigTile.rig.contractorId,
              contractorName: rigTile.rig.contractorName || '',
            },
            assignRig: {
              rigId: rigTile.rig.rigId,
              rigName: rigTile.rig.rigName,
            },
          };
        },
      },
      {
        label: 'Wellbores',
        icon: 'c-icons wellbore-icon',
        command: () => {
          // Set TagName/Filter Log of Recent Alert Logs
          this._interventionService.setLogFilter({
            rigJournalId: rigTile.rigJournalId,
            rigName: rigTile.rig.rigName
          });

          this._router.navigate(
            [
              `home/rig-journal/${rigTile.rig.rigId}/project/${rigTile.project.projectId || ''
              }`,
            ],
            {
              queryParams: { tab: 'wellbore' },
            }
          );
        },
      },
      {
        label: 'Intervals',
        icon: 'c-icons interval-icon',
        command: () => {
          // Set TagName/Filter Log of Recent Alert Logs
          this._interventionService.setLogFilter({
            rigJournalId: rigTile.rigJournalId,
            rigName: rigTile.rig.rigName
          });

          this._router.navigate(
            [
              `home/rig-journal/${rigTile.rig.rigId}/project/${rigTile.project.projectId || ''
              }`,
            ],
            {
              queryParams: { tab: 'interval' },
            }
          );
        },
      },
      {
        label: 'Runs',
        icon: 'c-icons run-icon',
        command: () => {
          // Set TagName/Filter Log of Recent Alert Logs
          this._interventionService.setLogFilter({
            rigJournalId: rigTile.rigJournalId,
            rigName: rigTile.rig.rigName
          });

          this._router.navigate(
            [
              `home/rig-journal/${rigTile.rig.rigId}/project/${rigTile.project.projectId || ''
              }`,
            ],
            {
              queryParams: { tab: 'run' },
            }
          );
        },
      },
      {
        label: 'Export',
        icon: 'c-icons export-icon',
        command: () => {
          // Set data and log of Recent Alert Logs
          this.executeFilter(rigTile.rigJournalId, rigTile.rig.rigName);

          // Keep focus for rig card
          this.activeRigCard = {
            rigJournalId: rigTile.rigJournalId,
            rigName: rigTile.rig.rigName
          }
          this.passActiveRigCard.emit(this.activeRigCard);

          this.openExportDialog(rigTile);
        },
      },
    ];
  }

  hideAddWellboreDialog(value: boolean) {
    //
    this.display_menuAddWellbore = value;
    // this.showRequestReloadHomePage.emit('Reload Table Page');
  }

  showIntervene(rig: any): void {
    // Filter theo Rig Card
    this.executeFilter(rig.rigJournalId, rig.rig.rigName);


    this.requestLoadingTheme.emit(true);
    this._homeService
      .getRigJournalbyId(rig.rigJournalId)
      .pipe(
        finalize(() => {
          this.hasMsg = false;
          this.requestLoadingTheme.emit(false);
        })
      )
      .subscribe({
        next: (res: any) => {
          const newRig: RigMark = res.data
          rig.mailboxList = newRig.mailboxList
          rig.remoteCenterList = newRig.remoteCenterList
          rig.distributionListUrl = newRig.distributionListUrl
          rig.isComplete = newRig.isComplete



          // #1: If the Project settings have not be updated.
          if (this.checkFieldRig(rig.project)) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `A Manager or Admin must update the project's attributes using the Edit Project option from the rig tile menu.`,
            });
          }
          // #2: If no Remote Center and at least one Functional Mailbox has been assigned to the Rig Tile
          else if (
            (!rig.mailboxList.length ||
              !rig.remoteCenterList.length) &&
            !this.hasMsg
          ) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `An Admin or Manager, or Engineer with elevated permissions, must assign the rig tile to at least one Remote Center and Functional Mailbox using the Edit Rig option in the rig tile menu.`,
            });
          }
          // #3: If no Distribution Lists file is uploaded
          else if (!rig.distributionListUrl && !this.hasMsg) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `A Distribution Lists file must be uploaded to the rig tile before creating an intervention.`,
            });
          }
          // #4: If no Wellbore is active
          else if (rig.isComplete && !this.hasMsg) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `The rig tile contains no active wellbore. A Manager, or Engineer with elevated permissions, must either change the status of an existing wellbore or add a wellbore using the Wellbores option in the rig tile menu.`,
            });
          } else {
            this.rig = rig
            this.showSelectionMailBox();
          }

        },
        error: (error) => {
          console.log(error);
        },
      });
  }
  showSelectionMailBox() {
    const rigMailBox = this.rig.mailboxList;
    const userMailBox =
      this._userInfoService.userSubject.getValue().mailboxList;

    if (!userMailBox) {
      console.log('Can not get user mailbox');
    }

    if (!rigMailBox) {
      console.log('Can not get rig mailbox');
    }

    let dataSelection = [];

    if (rigMailBox.length >= 1 && userMailBox.length >= 1) {
      dataSelection = rigMailBox.filter((rigMail: any) =>
        userMailBox.some(
          (userMail: any) => rigMail.mailboxId === userMail.mailboxId
        )
      );
    }

    this.confirmDialog.dataSelection = dataSelection.map((mailbox: any, index: number) => {
      let nameEmail = mailbox.mailboxName.split('@').shift();
      return {
        isSelected: index == 0 ? true : false,
        mailboxId: mailbox.mailboxId,
        mailboxName: mailbox.mailboxName,
        mailboxLocalPart: nameEmail ? nameEmail : mailbox.mailboxName,
      };
    });

    if (this.confirmDialog.dataSelection.length == 0) {
      this.confirmDialog.dataSelection = [{
        isSelected: true,
        mailboxName: 'N/A',
      }];
    }
    this.confirmDialog.dataDropdownTemplate = JSON.parse(JSON.stringify(AppConstant.ALERT_TEMPLATE));
    this.confirmDialog.dataDropdownLanguage = JSON.parse(JSON.stringify(AppConstant.LANGUAGE_TEMPLATE));

    this.confirmDialog.dataDropdownLanguage.forEach((item: any) => {
      if (item.id === this.rig?.language) {
        item.isSelected = true;
        this.confirmDialog.defaultLanguage = item
      } else {
        item.isSelected = false;
      }
    })

    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header: 'Mailbox Selection',
      haveDialogMessage: true,
      dialogMessage: 'Select the functional mailbox that will be used to send the alert.',
      dialogMessageTemplate: 'Select the template of the report:',
      dialogMessageLanguage: 'Select the language of the report:',
      isDisableDropdown: false,
      havePrimaryButton: true,
      primaryButtonLabel: 'Continue',
      isValidPrimaryButton: true,
      haveBoxSelection: true,
      alignCenterButton: true,
      buttonEvent: (event: OptionButtonAndIdType) =>
        this.onButtonClickDialog(event),
    });
  }

  checkFieldRig(rigProject: any) {
    let notEmpty = false;
    for (const prop in rigProject) {
      if (
        !rigProject[prop] &&
        prop !== 'projectCurrentName' &&
        prop !== 'preUnit'
      ) {
        notEmpty = true;
        break;
      }
    }
    return notEmpty;
  }

  // Hide sidebar from child component value
  hideSideBar(value: boolean) {
    //
    this.displaySidebarImportDistribution = value;
  }

  switchTemplate(value: string) {
    //
    if (value === 'uploadYet-reload') {
      // this.uploadStatus = 'uploadYet';
      this.reuploadStatus = AppHelper.MathFunctions.getRandomNumber();
      this.distributionUrl = '';
    } else {
      this.reuploadStatus = false;
      this.uploadStatus = value;
    }
  }

  requestReloadHomePage(value: string) {
    this.showRequestReloadHomePage.emit(value);
  }

  hideEditProject(isReloadPage: boolean) {
    if (isReloadPage) {
      this.displayEditProject = !isReloadPage;
      this.showRequestReloadHomePage.emit('Reload Table Page');
    } else {
      this.displayEditProject = isReloadPage;
    }
  }

  hideEditRig(value: boolean) {
    this.displayFormRig = !value;
    // this.showRequestReloadHomePage.emit('Reload Table Page');
  }

  readPDFdocument(
    event: any,
    PDFLink: string,
    containerName: string,
    currentRigCard: any
  ) {
    if (PDFLink) {
      //Send signal loading to parent
      this.requestLoadingTheme.emit(true);
      this._blobService
        .downloadPDF(
          decodeURIComponent(AppHelper.StringFunctions.getFileName(PDFLink)),
          containerName
        )
        .then((res) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          this.requestLoadingTheme.emit(false);
        })
        .catch((error) => {
          console.error('Caught error:', error); // The error will be caught here
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading PDF Document',
            content: 'This document is no longer available, please try again',
          });

          // disable hyperlink of no longer available document
          switch (containerName) {
            case 'rigcommunicateprotocal':
              currentRigCard.communicateProtocolUrl = null;
              break;
            case 'rigdatarouting':
              currentRigCard.dataRoutingUrl = null;
              break;
            case 'intervaldesignofservice':
              currentRigCard.interval.designOfServiceUrl = null;
              break;
          }
          this.requestLoadingTheme.emit(false);
        });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Loading PDF Document',
        content: 'This document is no longer available, please try again',
      });
      this.requestLoadingTheme.emit(false);
    }
  }

  getMouseLocation(event: any, rigId: string) {
    this.requestLoadingTheme.emit(true);
    event.stopPropagation();

    this._passingDataService.updateApprovalMessage({
      clientX: event.clientX,
      clientY: event.clientY,
      primaryContact: 'Loading',
    });
    this._homeService.getPrimaryContact(rigId).subscribe({
      next: (contact: any[]) => {
        if (!contact.length) {
          this.project[this.project.findIndex((item: RigMark) => item.rigJournalId === rigId)].distributionListUrl = '';
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: `Distribution list not exist anymore!`,
          });
        }
        else {
          this._passingDataService.updateApprovalMessage({
            clientX: event.clientX,
            clientY: event.clientY,
            primaryContact: contact,
          });
        }
      },
      error: (error: any) => {
        console.error(error);
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Loading contact information',
          content: error?.message,
        });
      },
    });
    this.requestLoadingTheme.emit(false);

  }

  executeFilter(rigJournalId: string, rigName: string) {

    // [IMPORTANT] - purpose to loading more Alerts
    this._interventionService.setTriggerRigCard(true);

    this._interventionService.setInterventionLogs([]);
    this._interventionService.setInterventionLogsTotal(0);

    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };

    // Store rigJournalId Focusing
    this._homeFilterService.setFocusRigCard(rigJournalId);

    this._homeService.setPayloadFilter(payloadFilterLog);

    // Call API get Alert Log with payload filter
    this.filterAlertLog(payloadFilterLog, rigName);
  }

  filterAlertLog(filterPayload: any, rigName: string) {
    of(null).pipe(
      tap(() => {
        // Turn on Loading Alert Log
        this._interventionService.setLoading(true);

        // Set Filter Chip Logs
        // HightLight Rig Card - CSS 
        if (filterPayload?.rigJournalId && rigName) {
          this.passActiveRigCard.emit({ rigJournalId: filterPayload?.rigJournalId[0], rigName: rigName });
          // Set TagName/Filter Log of Recent Alert Logs
          this._interventionService.setLogFilter({
            rigJournalId: filterPayload?.rigJournalId[0],
            rigName: rigName
          });
        } else {
          this.passActiveRigCard.emit({ rigJournalId: '', rigName: '' });
          this._interventionService.setLogFilterDefault();
        }
      }),
      switchMap(() => {
        return this._interventionService.getInterventionLogsFilter(filterPayload, 0, AppConstant.ITEMS_PER_PAGE)
        .pipe(
          finalize(() => this._interventionService.setLoading(false))
        )
      })
    ).subscribe((alert: any) => {
      console.log(1);
      this.showDataAlertLogs(alert);
    });
  }

  showDataAlertLogs(alertResponse: { items: any, total: number }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    setTimeout(() => {
      this._interventionService.setTriggerRigCard(false);
    }, 300);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    // Check if the click is outside the component
    if (
      !targetElement.closest('.project-item__header-wrapper') &&
      !targetElement.closest('.p-sidebar-distribution') &&
      !targetElement.closest('.style-class-dialog.p-dialog') &&
      !targetElement.closest('.p-toast-message-content') &&
      !targetElement.closest('.notification__item') &&
      !targetElement.closest('.notification__item__feature-right') &&
      !targetElement.closest('.description-icon') &&
      !targetElement.closest('.header-content__feature') &&
      !targetElement.closest('.header-content__title') &&
      !targetElement.closest('.project-edit-icon') &&
      !targetElement.closest('.p-dialog-mask') &&
      !targetElement.closest('.p-overlaypanel-content') &&
      !targetElement.closest('.p-component-overlay') &&
      !targetElement.closest('.header') &&
      !targetElement.closest('.VRO-footer') &&
      // !targetElement.closest('.p-input-icon-left') && (Behavior click Input Clear Focus Rig Card)
      !targetElement.closest('.p-menu-overlay') &&
      !targetElement.closest('.more-option') &&
      !targetElement.closest('.filter-wapper') &&
      this.activeRigCard.rigJournalId &&
      this.activeRigCard.rigName
    ) {
      // Clear HightLight Rig Card - CSS
      this.passActiveRigCard.emit({ rigJournalId: '', rigName: '' });

      // Clear Filter Chip Logs -- Implement in filterAlertLog Function
      // ====

      // Clear HightLight Alert Log - CSS
      this._interventionService.setInterventionLogsActive('');

      // Set Alert Logs by Current Filter - Non Focusing
      let payloadFilterLog = this._homeService.getPayloadFilter();
      // Temporarily with rigJournalId
      payloadFilterLog = { ...payloadFilterLog };
      delete payloadFilterLog.rigJournalId;
      this._homeService.setPayloadFilter(payloadFilterLog);

      // Clear rigJournalId Focusing
      this._homeFilterService.setFocusRigCard('');

      // Call API get Alert Log with payload filter
      this.filterAlertLog(payloadFilterLog, ''); // Empty Filter Chip Log
    }
  }

  redirectTo(rigJournalId: string, rigName: string) {
    this._router.navigate([`/home/alert-rig/${rigJournalId}`]);
  }

  openEditProject() {
    let data = this.project[0];
    let editProject = {
      projectId: data.project.projectId || '',
      projectName: data.project.projectName || '',
      projectOriginalName: data.project.projectOriginalName || '',
      projectCurrentName: data.project.projectCurrentName || '',
      company: data.policy.policyCurrentName || data.policy.policyOriginalName,
      policyCurrentName: data.policy.projectCurrentName || '',
      policyOriginalName: data.policy.policyOriginalName || '',
      country: data.project.country || '',
      region: data.project.region || '',
      block: data.project.block || '',
      lease: data.project.lease || '',
      field: data.project.field || '',
      timezone: data.project.timezone || '',
      preUnit: data.project.preUnit || '',
      curUnit: data.project.curUnit || '',
    };
    this.displayEditProject = !this.displayEditProject;
    this.editProject = editProject;
  }

  onButtonClickDialog(option: OptionButtonType | OptionButtonAndIdType): void {
    // Selector Dialog
    if (typeof option === 'object' && 'dataSelected' in option) {
      switch (option.buttonType) {
        case AppConstant.OPTION_BUTTON.YES:
          this.handleSelected(option.dataSelected);
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.NO:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
          break;
        default:
          break;
      }
    }
    // Normal Dialog
    else if (typeof option === 'string') {
      switch (option) {
        case AppConstant.OPTION_BUTTON.YES:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.NO:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
          break;
        default:
          break;
      }
    }
  }

  handleSelected(dataSelected: IDataSelection) {
    // In this case uniqe infor is NAME
    if (this.confirmDialog.dataSelection) {
      // Mark this selected is true before pass this to new page/state
      this.confirmDialog.dataSelection = this.confirmDialog.dataSelection.map((mail: IDataSelection) => {
        if (mail.mailboxName === dataSelected.mailboxName) {
          return {
            ...mail,
            isSelected: true,
          }
        } else {
          return {
            ...mail,
            isSelected: false,
          }
        }
      })

      const isSelected = this.confirmDialog.dataSelection.find(
        (mail: any) => mail.isSelected
      );

      const mailboxSelect = isSelected?.mailboxName === 'N/A' ? null : isSelected;
      const templateSelected = this.confirmDialog.dataDropdownTemplate.find(
        (template: IDropdownSelection) => template.isSelected
      );

      const languageSelected = this.confirmDialog.dataDropdownLanguage.find(
        (language: IDropdownSelection) => language.isSelected
      );
      this._passingMailboxService.updatePassingMailbox({
        mailboxs: this.confirmDialog.dataSelection,
        selectedMailbox: mailboxSelect,
        template: templateSelected,
        language: languageSelected,
      });

      this._backPortalService.setCollapseLog(false);
      this._router.navigate([`home/rig/${this.rig.rigJournalId}`]);
    }
  }

  openExportDialog({ rig, rigJournalId }: any): void {
    const ref = this._dialogService.open(ExportSettingComponent, {
      header: `Export Alerts: ${rig.rigName}`,
      data: {
        rigJournalId: rigJournalId
      },
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose
      .subscribe({
        next: (res: any) => { },
        error: (error) => {
          console.log(error);
        },
      });
  }

}
