import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import {
  Subject,
  of,
  switchMap,
  takeUntil,
  forkJoin,
  map,
  finalize,
  BehaviorSubject,
} from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoaderLayOut } from 'src/app/shared/services/loaderLayOut.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { passingExtendInfoService } from 'src/app/shared/services/passingExtendInfo.service';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { DistributionService } from 'src/app/shared/services/distribution.service';
import { DistributionTemplate, MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';
import { Menu } from 'primeng/menu';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { ExportSettingComponent } from 'src/app/shared/components/export-setting/export-setting.component';
import { DialogService } from 'primeng/dynamicdialog';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-alert-detail',
  templateUrl: './alert-detail.component.html',
  styleUrls: ['./alert-detail.component.scss'],
})
export class AlertDetailComponent extends BaseComponent {
  isLoading: boolean = false;

  alertData: any;
  alertListData: any;

  formLoaded?: Promise<boolean>;

  msgFromApis = 'No result found';

  AppHelper = AppHelper;

  flowInitialData$ = new Subject();
  currentUser: any;
  currentUnit: string = '';

  rigImageBlob: any;

  popupProjectInformation: any = {
    isDisplay: false,
    project: null,
  };

  popupRigInformation: any = {
    isDisplay: false,
    rig: null,
  };

  popupWellboreInformation: any = {
    isDisplay: false,
    dataMenuAddWellboreRigCardLevel: null,
  };

  hasMsg: boolean = false;

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  public fieldNameLeft: string[] = [
    'Operator',
    'Region',
    'Country',
    'Field',
    'Block',
    'Site',
    'Map Zone',
  ];
  public fieldNameRight: string[] = [
    'Well (Active)',
    'Well Surf Lat/Long',
    'well UID',
    'Wellbore (Active)',
    'Interval (Active)',
    'Run (Active)',
    'Unit System',
  ];

  alertListColumn: any[] = [
    { field: 'eventType', header: 'Alert Type' },
    { field: 'initiated', header: 'Initiated' },
    { field: 'stateCode', header: 'Status' },
    { field: 'event', header: 'Event' },
    { field: 'eventDescription', header: 'Description' },
    { field: 'resolution', header: 'Resolution' },
    { field: 'depthConverted', header: 'Depth' },
    { field: 'runNumber', header: 'Run' },
    { field: 'diameterConverted', header: 'Interval' },
  ];

  constructor(
    private _loaderLayOut: LoaderLayOut,
    private _alertService: AlertService,
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _activedRoute: ActivatedRoute,
    private _blobService: AzureBlobService,
    private _notificationService: NotificationService,
    private _homeService: HomeService,
    private _passingDataService: passingExtendInfoService,
    private _interventionService: InterventionService,
    private _distributionService: DistributionService,
    private _collapseLayoutService: CollapseLayoutService,
    private _dialogService: DialogService
  ) {
    super();
  }
  onInit() {
    this.isLoading = true;
    this.loadInitialData();
  }
  loadInitialData() {
    this._userInfoService.userSubject$.subscribe((userResponse: any) => {
      const user = userResponse || null;
      this.currentUser = user;

      if (user && user.id && user.role) {
        this.flowInitialData$
          .pipe(
            takeUntil(this.destroy$),
            switchMap(() => {
              return this._activedRoute.paramMap;
            }),
            map((params) => params.get('rigJournalId') || ''),
            switchMap((rigJournalId: string) => {
              if (rigJournalId) {

                let payloadFilterLog = this.addFilterRigJournalId(rigJournalId);
                return forkJoin([
                  this._alertService.getAlertInfoById(rigJournalId),
                  this._alertService.getAlertTableById(rigJournalId),
                  this._interventionService.getInterventionLogsFilter(payloadFilterLog, 0, AppConstant.ITEMS_PER_PAGE),
                ]);
              }
              return of([]);
            }),
            switchMap(([alertData, alertTableData, alertLogs]: any) =>
              forkJoin([
                of(alertData),
                of(alertTableData),
                of(alertLogs),
                this._userInfoService.checkPermissionWithRig(
                  alertData?.message,
                  alertData?.data
                ),
              ])
            )
          )
          .subscribe({
            next: ([alertData, alertTableData, alertLogs, permission]: any) => {
              if (!permission) {
                this._router.navigate(['home']);
                return;
              }

              this.alertData = alertData?.data;
              this.alertListData = alertTableData;
              this.currentUnit = this.alertData.project.curUnit;
              
              // Set Intervention Log Filter
              this.showDataAlertLogs(alertLogs);
              
              // Set TagName/Filter Log of Recent Alert Logs
              this._interventionService.setLogFilter({
                rigJournalId: this.alertData.rigJournalId,
                rigName: this.alertData.rig.rigName
              });

              this.clarifyData();
            },
            error: (error) => {
              console.error(error);
              this.isLoading = false;
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Load initialization data',
                content: error?.message || error,
              });
              if (error && error === 'This item not active!') {
                this._router.navigate(['home']);
              }
              return;
            },
          });
        this.flowInitialData$.next(null);
      }
    });
  }
  
  showDataAlertLogs(alertResponse: { items: any, total: number  }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
  }

  clarifyData(): void {
    this.alertListData = AppHelper.UtileFunctions.sortArrayExceptNABykey(
      this.alertListData,
      'initiated',
      'ascending',
      false
    );

    this.alertData.flagStatistic = AppHelper.UtileFunctions.sortPrimaryFlag(
      this.alertData.interventionFlag
    ).slice(0, 4);

    // Check if Rig Card have updated project yet?
    if (this.alertData.project.country) {
      const countryObj = AppConstant.COUNTRIES.find((country) => {
        return country.code === this.alertData.project.country.toUpperCase();
      });
      this.alertData.project.countryName = countryObj?.name || null;
      this.alertData.project.countryInfo = `${countryObj?.name} (${countryObj?.code})`;
      const regionObj = AppConstant.REGIONS.find((region) => {
        return region.code === this.alertData.project.region.toUpperCase();
      });
      this.alertData.project.regionInfo = regionObj?.name || null;
    }

    // Encode for url default image
    if (this.alertData.rig.rigTypeName) {
      const decodeRigType = JSON.parse(
        AppHelper.UtileFunctions.decodeIfIsBase64(
          this.alertData.rig.rigTypeName
        )
      );
      this.alertData.rig.rigTypeDecoded = decodeRigType;
    }

    if (!this.alertData.rig.imageUrl) {
      this.rigImageBlob = '';
    } else {
      this.alertData.rig.rigImageBlob = this._blobService.getRigImage(
        decodeURIComponent(
          AppHelper.StringFunctions.getFileName(this.alertData.rig.imageUrl)
        )
      );
    }

    // Table of Contents

    this.alertListData = this.alertListData.map((item: any) => {
      return {
        ...item,
        classColor: AppHelper.MathFunctions.getColorOfInterventionStatus(
          item.stateCode,
          item.timeExpire
        ),
        stateMessage: AppHelper.MathFunctions.getActionOfInterventionStatus(
          item.stateCode,
          item.timeExpire
        ),
        depthString:
          AppHelper.MathFunctions.transferData(
            item.bitMD,
            UNIT_SYSTEM,
            this.currentUnit,
            item.unit ? item.unit : '',
            'depth',
            0,
            0,
            0,
            0
          ) +
          ' (bit) / ' +
          AppHelper.MathFunctions.transferData(
            item.holeMD,
            UNIT_SYSTEM,
            this.currentUnit,
            item.unit ? item.unit : '',
            'depth',
            0,
            0,
            0,
            0
          ) +
          ' (hole)',
        depthConverted: AppHelper.MathFunctions.transferData(
          item.bitMD,
          UNIT_SYSTEM,
          this.currentUnit,
          item.unit ? item.unit : '',
          'depth',
          0,
          0,
          0,
          0,
          false
        ),
        intervalString:  item?.interval?.intervalName,
        diameterConverted: item.run
        ? `${item.run.description} (${item.run.runNo})`
        : 'N/A',
      };
    });

    this._loaderLayOut.setLoadingAlertsDetailDone(true);

    this.isLoading = false;
    this.formLoaded = Promise.resolve(true);
  }

  ngAfterViewInit(): void {
    // Uppdate of table
    this._distributionService.rigcardUpdate$.subscribe((updateRigRow: any) => {
      if (updateRigRow.rigJournalId) {          
          this._activedRoute.paramMap
            .pipe(
              takeUntil(this.destroy$),
              map((params) => {
                return { rigJournalId: params.get('rigJournalId')};
              }),
              switchMap(({ rigJournalId }) => {
                if (rigJournalId) { 
                  return forkJoin([
                    this._alertService.getAlertInfoById(rigJournalId || '')
                  ]);
                } else {
                  return of();
                }
              })
            ).subscribe({
              next: ([alertData]: any) => {  
                if (alertData) {
                  this.alertData = alertData?.data;
                  
                  // Encode for url default image
                  if (this.alertData.rig.rigTypeName) {
                    const decodeRigType = JSON.parse(
                      AppHelper.UtileFunctions.decodeIfIsBase64(
                        this.alertData.rig.rigTypeName
                      )
                    );
                    this.alertData.rig.rigTypeDecoded = decodeRigType;
                  }

                  if (!this.alertData.rig.imageUrl) {
                    this.rigImageBlob = '';
                  } else {
                    this.alertData.rig.rigImageBlob = this._blobService.getRigImage(
                      decodeURIComponent(
                        AppHelper.StringFunctions.getFileName(this.alertData.rig.imageUrl)
                      )
                    );
                  }
                }
              }
            })
      }
    });
  }

  showContextMenu(event: any, inputData: any) {
    this.getMenuItemsForItem$.next(this.getMenuItemsForItem(inputData));
    this.menu.toggle(event);
  }

  // MenuItem[]
  getMenuItemsForItem(alert: any): any {
    const canAdd = this._userInfoService.hasPermission(
      MODULE_NAME.WELLBORE,
      USER_PERMISSION.ADD
    );
    const canEditProject = this._userInfoService.hasPermission(
      MODULE_NAME.PROJECT,
      USER_PERMISSION.EDIT
    );

    const data = alert;
    if (this.currentUser) {
      const isAdmin =
        this._userInfoService.userSubject.getValue().role ===
        AppConstant.ROLES.ADMIN.label;
      const isViewer =
        this._userInfoService.userSubject.getValue().role ===
        AppConstant.ROLES.VIEWER.label;
      return [
        {
          label: 'Distribution Lists',
          icon: 'c-icons file-icon',
          command: () => {
            this._distributionService.setDisplay({
              render: {
                isShow: true,
                template: data.distributionListUrl
                  ? DistributionTemplate.uploading
                  : DistributionTemplate.uploadYet,
                reuploadStatus: false,
              },
            });

            this._distributionService.setDisData({
              data: {
                rigId: data.rig.rigId,
                rigJournalId: data.rigJournalId,
                rigOperator:
                  data.policy.policyCurrentName ||
                  data.policy.policyOriginalName ||
                  'Company Name',
                rigProject:
                  data.projectCurrentName ||
                  data.projectOriginalName ||
                  'Project Name',
                rigName: data.rig.rigName || 'Rig Name',
                language: data.language
              },
            });

            this._distributionService.setDisURL({
              link: {
                distributionUrl: data.distributionListUrl,
              },
            });
          },
        },
        {
          label: 'Edit Project',
          visible: isViewer || canEditProject,
          icon: 'c-icons setting-icon',
          command: () => {
            this.popupProjectInformation = {
              isDisplay: true,
              project: {
                projectId: data.project.projectId || '',
                projectName:
                  data.project.projectName ||
                  data.project.projectCurrentName ||
                  data.project.projectOriginalName ||
                  '',
                projectOriginalName: data.project.projectOriginalName || '',
                projectCurrentName: data.project.projectCurrentName || '',
                company:
                  data.policy.policyCurrentName ||
                  data.policy.policyOriginalName,
                policyCurrentName: data.policy.projectCurrentName || '',
                policyOriginalName: data.policy.policyOriginalName || '',
                country: data.project.country || '',
                region: data.project.region || '',
                block: data.project.block || '',
                lease: data.project.lease || '',
                field: data.project.field || '',
                timezone: data.project.timezone || '',
                preUnit: data.project.preUnit || '',
                curUnit: data.project.curUnit || '',
              },
            };
          },
        },
        {
          label: 'Edit Rig Tile',
          visible: isAdmin || isViewer,
          icon: 'c-icons edit-icon',
          command: () => {
            this.popupRigInformation = {
              isDisplay: true,
              rigJournal: {
                rigJournalId: data.rigJournalId,
                rigId: data.rig.rigId,
                projectName:
                  data.project.projectName ||
                  data.project.projectCurrentName ||
                  data.project.projectOriginalName ||
                  '',
              },
            };
          },
        },
        {
          label: 'Add Wellbore',
          icon: 'c-icons addwell-icon',
          visible: canAdd,
          command: () => {
            this.popupWellboreInformation = {
              isDisplay: true,
              dataMenuAddWellboreRigCardLevel: {
                source: {
                  sourceId: data.policy.edmSource || '',
                  sourceName: data.policy.edmSource || '',
                  displaySourceName: AppHelper.StringFunctions.sourceName(
                    data.policy.edmSource || ''
                  ),
                },
                company: {
                  policyId: data.policy.policyId,
                  policyName: data.policy.policyOriginalName,
                },
                project: {
                  projectId: data.project.projectId,
                  projectName: data.project.projectOriginalName,
                },
                contractor: {
                  contractorId: data.rig.contractorId,
                  contractorName: data.rig.contractorName || '',
                },
                assignRig: {
                  rigId: data.rig.rigId,
                  rigName: data.rig.rigName,
                },
              },
            };
          },
        },
        {
          label: 'Wellbores',
          icon: 'c-icons wellbore-icon',
          command: () => {
            this._router.navigate(
              [
                `home/rig-journal/${data.rig.rigId}/project/${
                  data.project.projectId || ''
                }`,
              ],
              {
                queryParams: { tab: 'wellbore' },
              }
            );
          },
        },
        {
          label: 'Intervals',
          icon: 'c-icons interval-icon',
          command: () => {
            this._router.navigate(
              [
                `home/rig-journal/${data.rig.rigId}/project/${
                  data.project.projectId || ''
                }`,
              ],
              {
                queryParams: { tab: 'interval' },
              }
            );
          },
        },
        {
          label: 'Runs',
          icon: 'c-icons run-icon',
          command: () => {
            this._router.navigate(
              [
                `home/rig-journal/${data.rig.rigId}/project/${
                  data.project.projectId || ''
                }`,
              ],
              {
                queryParams: { tab: 'run' },
              }
            );
          },
        },
        {
          label: 'Export',
          icon: 'c-icons export-icon',
          command: () => {
            this.openExportDialog(data);
          },
        },
      ];
    }
  }

  hidePopUpProject(isShow: boolean) {
    this.popupProjectInformation.isDisplay = false;
  }

  hidePopUpRig(isReloadPage: boolean) {
    if (isReloadPage) {
      this.popupRigInformation.isDisplay = !isReloadPage;
    }
  }

  hideAddWellboreDialog(isShow: boolean) {
    this.popupWellboreInformation.isDisplay = isShow;
  }

  showIntervene(alertData: any): void {
    this.isLoading = true;
    this._homeService
      .getRigJournalbyId(alertData.rigJournalId)
      .pipe(
        finalize(() => {
          // Turn off Loading from child item when finish call API
          this.hasMsg = false;
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (res: any) => {
          // #1: If the Project settings have not be updated.
          if (this.checkFieldRig(alertData.project)) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `A Manager or Admin must update the project's attributes using the Edit Project option from the rig tile menu.`,
            });
          }
          // #2: If no Remote Center and at least one Functional Mailbox has been assigned to the Rig Tile
          else if (
            (!res.data.mailboxList.length ||
              !res.data.remoteCenterList.length) &&
            !this.hasMsg
          ) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `An Admin or Manager, or Engineer with elevated permissions, must assign the rig tile to at least one Remote Center and Functional Mailbox using the Edit Rig option in the rig tile menu.`,
            });
          }
          // #3: If no Distribution Lists file is uploaded
          else if (!alertData.distributionListUrl && !this.hasMsg) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `A Distribution Lists file must be uploaded to the rig tile before creating an intervention.`,
            });
          }
          // #4: If no Wellbore is active
          else if (alertData.isComplete && !this.hasMsg) {
            this.hasMsg = true;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: `The rig tile contains no active wellbore. A Manager, or Engineer with elevated permissions, must either change the status of an existing wellbore or add a wellbore using the Wellbores option in the rig tile menu.`,
            });
          } else this._router.navigate([`home/rig/${alertData.rigJournalId}`]);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  checkFieldRig(rigProject: any) {
    let notEmpty = false;
    for (const prop in rigProject) {
      if (
        !rigProject[prop] &&
        prop !== 'projectCurrentName' &&
        prop !== 'preUnit'
      ) {
        notEmpty = true;
        break;
      }
    }
    return notEmpty;
  }

  readPDFdocument(event: any, PDFLink: string, containerName: string) {
    if (PDFLink) {
      this.isLoading = true;
      this._blobService
        .downloadPDF(
          decodeURIComponent(AppHelper.StringFunctions.getFileName(PDFLink)),
          containerName
        )
        .then((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Caught error:', error); // The error will be caught here
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading PDF Document',
            content: 'This document is no longer available, please try again',
          });
          // disable hyperlink of no longer available document
          switch (containerName) {
            case 'rigcommunicateprotocal':
              this.alertData.communicateProtocolUrl = null;
              break;
            case 'rigdatarouting':
              this.alertData.dataRoutingUrl = null;
              break;
            case 'intervaldesignofservice':
              this.alertData.interval.designOfServiceUrl = null;
              break;
          }
          this.isLoading = false;
        });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Loading PDF Document',
        content: 'This document is no longer available, please try again',
      });
    }
  }

  getMouseLocation(event: any, rigId: string) {
    this.isLoading = true;

    this._passingDataService.updateApprovalMessage({
      clientX: event.clientX - 300,
      clientY: event.clientY,
      primaryContact: 'Loading',
    });

    this._homeService.getPrimaryContact(rigId).subscribe({
      next: (contact: any) => {
        this._passingDataService.updateApprovalMessage({
          clientX: event.clientX - 300,
          clientY: event.clientY,
          primaryContact: contact,
        });
        this.isLoading = false;
      },
      error: (error: any) => {
        console.error(error);
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.INFO,
          header: 'Error: Loading contact information',
          content: error?.message,
        });
      },
    });
  }

  redirectTo(path: string) {
    if (path) {
      this._router.navigate([`${path}`]);
      this._collapseLayoutService.updateCollapseLayout(true);
    }
  }

  openExportDialog({rig, rigJournalId}: any): void {
    const ref = this._dialogService.open(ExportSettingComponent, {
      header: `Export Alerts: ${rig.rigName}`,
      data: {
        rigJournalId: rigJournalId
      },
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose
      .subscribe({
        next: (res: any) => {},
        error: (error) => {
          console.log(error);
        },
      });
  }

  addFilterRigJournalId(rigJournalId: string) {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    return this._homeService.getPayloadFilter();
  }

  onDestroy() {
    this.flowInitialData$.complete();
  }
}
