import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss'],
  providers: [MessageService],
})
export class EditVideoComponent implements OnInit {
  @ViewChild('videoUploader') videoUploader!: FileUpload;
  @ViewChild('thumbnailUploader') thumbnailUploader!: FileUpload;

  isViewer: boolean = false;
  categoryOptions: { name: string; id: string }[] = [];
  formGroupEditVideo: FormGroup = this.fb.group({
    category: ['', [Validators.required]],
    video: [null, [Validators.required]],
    thumbnail: [null, [Validators.required]],
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });
  isDataChanged: boolean = false;

  fileVideoLabel: string = 'Browse file';
  fileThumbnailLabel: string = 'Browse file';

  fileVideoLabelName: string = 'Browse file';
  fileThumbnailLabelName: string = 'Browse file';

  showCancelButtonVideo: boolean = false;
  showCancelButtonThumbnail: boolean = false;

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    if (this.config.data.isViewer) {
      this.isViewer = this.config.data.isViewer;
    }

    this.categoryOptions = this.config.data.categories.map((category: any) => {
      return {
        name: category.categoryName,
        id: category.categoryId,
      };
    });

    const itemSelected = this.categoryOptions.find(
      (category) =>
        (category.name as string) === this.config.data.itemSelected.categoryName
    );

    if (itemSelected)
      this.formGroupEditVideo.patchValue({
        category: itemSelected,
      });

    if (this.config.data.video) {
      const video = this.config.data.video;
      this.formGroupEditVideo.get('title')?.setValue(video.title);
      this.formGroupEditVideo.get('description')?.setValue(video.description);
    }

    this.preventWhiteSpace('title');
    this.preventWhiteSpace('description');
    this.formGroupEditVideo.valueChanges.subscribe(
      () => (this.isDataChanged = this.checkDataChanged())
    );

    // Set name of uploaded Video and Thumnail
    this.loadNameOfFiles(
      this.config.data.video.url,
      this.config.data.video.thumbnail
    );
  }

  loadNameOfFiles(linkVideo?: string, linkThumbnail?: string) {
    if (!linkVideo) {
      this.fileVideoLabel = 'Browse file';
      this.fileVideoLabelName = 'Browse file';
    } else {
      const nameFileImage = AppHelper.UtileFunctions.getFileNameFromUrl(linkVideo);
      const nameFileImageShort = AppHelper.StringFunctions.shortenFileName(nameFileImage, 12, 6);

      this.fileVideoLabelName = nameFileImage;

      this.fileVideoLabel = nameFileImageShort;
      this.showCancelButtonVideo = true;

      this.formGroupEditVideo.get('video')?.setValue('hasValue');
    }

    if (!linkThumbnail) {
      this.fileThumbnailLabel = 'Browse file';
      this.fileThumbnailLabelName = 'Browse file';
    } else {
      const nameFileThumb = AppHelper.UtileFunctions.getFileNameFromUrl(linkThumbnail);
      const nameFileThumbShort = AppHelper.StringFunctions.shortenFileName(nameFileThumb, 12, 6);

      this.fileThumbnailLabelName = nameFileThumb;
      this.fileThumbnailLabel = nameFileThumbShort;
      this.showCancelButtonThumbnail = true;

      this.formGroupEditVideo.get('thumbnail')?.setValue('hasValue');
    }
  }

  private buildPayload(): FormData {
    const payload: FormData = new FormData();

    let fileNameVideo, fileNameThumbnail;
    if (this.formGroupEditVideo.get('video')?.value != 'hasValue') {
      fileNameVideo = AppHelper.UtileFunctions.encodeFileName(this.formGroupEditVideo.value.video.currentFiles[0].name);
      fileNameThumbnail = AppHelper.UtileFunctions.encodeFileName(this.formGroupEditVideo.value.thumbnail.currentFiles[0].name);
    }

    if (
      this.formGroupEditVideo.get('category')?.value.name !=
      this.config.data.currentCategory
    )
      payload.append(
        'categoryId',
        this.formGroupEditVideo.get('category')?.value.id
      );
    if (
      this.formGroupEditVideo.get('title')?.value !=
      this.config.data.video.title
    )
      payload.append(
        'title',
        AppHelper.UtileFunctions.encodeBase64(
          this.formGroupEditVideo.get('title')?.value
        )
      );
    if (
      this.formGroupEditVideo.get('description')?.value !=
      this.config.data.video.description
    )
      payload.append(
        'description',
        AppHelper.UtileFunctions.encodeBase64(
          this.formGroupEditVideo.get('description')?.value
        )
      );

    if (this.formGroupEditVideo.get('video')?.value != 'hasValue') {
      payload.append(
        'video',
        this.formGroupEditVideo.get('video')?.value.currentFiles[0],
        fileNameVideo
      );
    }

    if (this.formGroupEditVideo.get('thumbnail')?.value != 'hasValue') {
      payload.append(
        'thumbnail',
        this.formGroupEditVideo.get('thumbnail')?.value.currentFiles[0],
        fileNameThumbnail
      );
    }
    return payload;
  }

  public onCancel(): void {
    this.ref.close();
  }

  public onEditVideo(): void {
    this.ref.close({
      payload: this.buildPayload(),
      videoId: this.config.data.video.videoId,
    });
  }

  public onUploaderSelect(uploader: string, event: any): void {
    if (this.validateFile(uploader, event.files[0])) {
      switch (uploader) {
        case 'video':
          this.fileVideoLabelName = event.files[0].name;
          break;

        case 'thumbnail':
          this.fileThumbnailLabelName = event.files[0].name;
          break;
      }

      this.formGroupEditVideo.get(uploader)?.setValue(event);
      this.formGroupEditVideo.get(uploader)?.markAsTouched();
    } else {
      this.clearUploader(uploader);
    }
  }

  public onUploaderClear(uploader: string): void {
    switch (uploader) {
      case 'video':
        this.fileVideoLabelName = 'Browse file';
        break;

      case 'thumbnail':
        this.fileThumbnailLabelName = 'Browse file';
        break;
    }

    this.formGroupEditVideo.get(uploader)?.setValue(null);
    this.formGroupEditVideo.get(uploader)?.markAsUntouched();
  }

  private clearUploader(uploader: string): void {
    if (uploader === 'video') this.videoUploader.clear();
    if (uploader === 'thumbnail') this.thumbnailUploader.clear();
  }

  private preventWhiteSpace(formControl: string): void {
    this.formGroupEditVideo
      .get(formControl)
      ?.valueChanges.subscribe((value) => {
        if (value === ' ')
          this.formGroupEditVideo.get(formControl)?.patchValue('');
      });
  }

  private validateFile(uploader: string, file: File): boolean {
    // Verify file type
    if (
      !(
        (uploader == 'video' && file.type == 'video/mp4') ||
        (uploader == 'thumbnail' &&
          (file.type == 'image/png' ||
            file.type == 'image/jpeg' ||
            file.type == 'image/jpg'))
      )
    ) {
      let fileType: string;
      if (uploader == 'video') fileType = '.mp4';
      if (uploader == 'thumbnail') fileType = '.png, .jpg, .jpeg';
      if (fileType!) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid File Type`,
          content: `${file.name}: Invalid file type`,
        });
      }
      return false;
    }

    // Verify file size
    let maxFileSize: number;
    if (uploader == 'video') maxFileSize = 256000000;
    if (uploader == 'thumbnail') maxFileSize = 10000000;
    if (file.size > maxFileSize!) {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Allowed file size: ${maxFileSize! / 1000000}MB`,
        content: `${file.name}: Invalid file size`,
      });
      return false;
    }

    // Return true if file is valid
    return true;
  }

  public checkDataChanged(): boolean {
    if (
      this.formGroupEditVideo.get('category')?.value.name !=
        this.config.data.currentCategory ||
      this.formGroupEditVideo.get('title')?.value !=
        this.config.data.video.title ||
      this.formGroupEditVideo.get('description')?.value !=
        this.config.data.video.description
    ) {
      return true;
    }
    return false;
  }
}
