import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, debounceTime, map } from 'rxjs';

// Constant Value

@Injectable()
export class MailBoxService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getMailBoxsByStringNonAssign(
    searchStringMailBox: string,
    timeDebounce: number
  ): Observable<any[]> {
    const uri = `mailbox`;

    searchStringMailBox = searchStringMailBox && searchStringMailBox.trim();

    return this.get<any>(uri).pipe(
      debounceTime(timeDebounce),
      map((item: any) => {
        return item.data;
      }),
      map((item: any) => {
        if (item.length === 0) return item;
        return item.filter((mailbox: any) => {
          return mailbox.remoteCenter !== null;
        });
      }),
      map((item) => {
        if (!searchStringMailBox) {
          return item;
        }
        // Filter by String.
        searchStringMailBox = searchStringMailBox.replace(
          /([!@#$%^&*()+=\[\]\\';,./{}|":<>?~_-])/g,
          '\\$1'
        );

        const regex = new RegExp(searchStringMailBox, 'i');

        return item.filter((i: any) => {
          return regex.test(i.mailboxName);
        });
      })
    );
  }

  public getMailBoxs(): Observable<any[]> {
    const uri = `mailbox`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        return item.data;
      })
    );
  }

  public getMailBoxsNonAssign(): Observable<any[]> {
    const uri = `mailbox`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        if (item?.data.length === 0) return item.data;
        return item.data.filter((item: any) => {
          return item.remoteCenter === null;
        });
      })
    );
  }

  public getRemoveCenter(): Observable<any[]> {
    const uri = `remote-center`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        return item.data;
      })
    );
  }

  public getDiscipline(): Observable<any[]> {
    const uri = `discipline`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        return item.data;
      })
    );
  }

  getDisciplineByRemoteCenterId(remoteCenterId: string): Observable<any[]> {
    const uri = `remote-center/${remoteCenterId}`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        return item.data.disciplineList;
      })
    );
  }

  public addMailbox(mailboxId: string, payload: any): Observable<any> {
    const uri = `mailbox/${mailboxId}`;
    const data = {
      remoteCenterId: payload.remoteCenter.remoteCenterId,
      discipline: payload.discipline.map((discipline: any) => {
        return {
          disciplineId: discipline.disciplineId,
        };
      }),
    };

    return this.put<any, any>(uri, data);
  }

  public updateMailbox(mailboxId: string, payload: any): Observable<any> {
    const uri = `mailbox/${mailboxId}`;
    const data = {
      mailboxName: payload.functionalMailbox.mailboxName,
      remoteCenterId: payload.remoteCenter? payload.remoteCenter.remoteCenterId : undefined,
      discipline: payload.discipline 
      ? payload.discipline.map((discipline: any) => {
        return {
          disciplineId: discipline.disciplineId,
        };
      })
      : undefined,
    };

    return this.put<any, any>(uri, data);
  }

  public updateMailboxStatus(
    mailboxId: string,
    nextStatus: any
  ): Observable<any> {
    const uri = `mailbox/${mailboxId}`;
    const data = {
      isActive: nextStatus,
    };

    return this.put<any, any>(uri, data);
  }

  public deleteMailbox(mailboxId: string): Observable<any> {
    const uri = `mailbox/${mailboxId}`;
    return this.delete<any>(uri);
  }
}
