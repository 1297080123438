import { AppHelper } from 'src/app/shared/utilities/app.helper';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import {
  ALERT_ADVANCED_FILTER,
} from 'src/app/shared/metadata/alert-advanced-filter.metadata';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AlertFilterOptionsResponse, EngineerFilterOption } from 'src/app/shared/type';
import { isEmpty } from 'lodash';
import { IAlertCacheFilter } from './alert-filter.model';

@Component({
  selector: 'app-alert-advanced-filter',
  templateUrl: './alert-advanced-filter.component.html',
  styleUrls: ['./alert-advanced-filter.component.scss'],
})
export class AlertAdvancedFilterComponent extends BaseComponent {
  @Input() alertListData: any[] = [];

  @Output() chipFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('treeRiskEvent') treeRiskEvent: any;
  
  METADATA: any = ALERT_ADVANCED_FILTER;

  statusesOption: any = [];
  eventsOption: any = [];
  selectedEventString: string = this.METADATA.OPTION.PLACE_HOLDER;
  actionTakensOption: any = [];
  risksOption: any = [];
  prepareRisksObjectOption: any = [];
  prepareRisksStringOption: any = [];
  engineersOption: any = [];
  prepareEngineerGroupOption: any = [];
  alertType: any = [];

  showSlider: boolean = false;
  remoteCentersOption: any = [];

  restoreStatusOption: any = [];
  rigsOption: any = [];
  projectsOption: any = [];
  wellsOption: any = [];

  selectedTimeRange: any;
  selectedStatus: any;
  selectedEvent: any;
  countSelectedEvent: number = 0;

  selectedActionTaken: any;
  selectedTimeSaved: any[] = [null, null];
  selectedRisk: any;
  countSelectedRisk: number = 0;
  selectedRiskString: string = this.METADATA.OPTION.PLACE_HOLDER;

  selectedEngineer: any;
  countSelectedEngineer: number = 0;
  selectedEngineerString: string = this.METADATA.OPTION.PLACE_HOLDER;

  selectedRemoteCenter: any;
  selectedRig: any;
  selectedProject: any;
  selectedWell: any;
  selectedAlertType: any;

  cacheSlectedOptionApplied: IAlertCacheFilter = {
    actionTaken: null,
    engineer: null,
    engineerString: this.METADATA.OPTION.PLACE_HOLDER,
    event: null,
    eventString: this.METADATA.OPTION.PLACE_HOLDER,
    eventType: null,
    project: null,
    remoteCenter: null,
    rig: null,
    risk: null,
    riskString: this.METADATA.OPTION.PLACE_HOLDER,
    status: null,
    timeRange: null,
    timeSaved: null,
    well: null,
  };

  minDateValue: Date = new Date(-8640000000000000);
  maxDateValue: Date = new Date(+8640000000000000);
  defaultDateValue: Date = new Date();

  selectableDate: string = 'Selectable value is in the range: All';

  minTimeSaved: number = 0;
  maxTimeSaved: number = 100;

  isWarnField: boolean = false;
  selectableTimeSave: string = 'Selectable value is in the range: All';

  constructor(private _alertService: AlertService) {
    super();
  }

  onInit(): void {
    this.loadInitialData(this.alertListData);
  }

  makeEngineerOption(array: EngineerFilterOption[]) {
    const newTree: any[] = [];

    array.forEach((engineer: EngineerFilterOption, index: number) => {
      const dataEngineer = {...engineer, ...{       
        label: engineer.name, 
        leaf: false,
        styleClass: 'lowestChild',
        selectable: true,
      }};

      const disciplineEngineer = engineer.disciplineName;
      const idx = newTree.findIndex((node: any) => node?.label === disciplineEngineer);

      if (idx >= 0) {
        newTree[idx].children.push(dataEngineer);
      } else {
        const objNew = {
          label: disciplineEngineer,
          id: index,
          styleClass: 'parent',
          selectable: false,
          children: [dataEngineer]
        }
        newTree.push(objNew);
      }
    });

    newTree.push({
      label: 'N/A',
      id: 'N/A',
      data: 'N/A',
      styleClass: 'single-choose',
      selectable: true,
      children: [],
    });
    
    return newTree;
  }

  makeRisksOption(arrayRisksId: string[], node: any) {
    if (!node) return null;

    if (node?.risk_id) {
      if (arrayRisksId.includes(node.risk_id || '')) {
        return node;
      }
      return null;
    }

    const nodeClonde: any = {
      id: node.id,
      data: node.data, 
      label: node.label,
      children: [],
      styleClass: node?.styleClass,
      selectable: false,
    }

    for (let index = 0; index < node?.children?.length; index++) {
      const data = this.makeRisksOption(arrayRisksId, node.children[index]);
      if (data) {
        nodeClonde.children.push(data);
      }
    }

    if (nodeClonde.children.length > 0)
      return nodeClonde;
    else return null;
  }

  makeEventOption(arrayRisksId: string[], node: any) {
    if (!node) return null;

    if (node?.event_id) {
      if (arrayRisksId.includes(node.event_id || '')) {
        return node;
      }
      return null;
    }

    const nodeClonde: any = {
      data: node.data, 
      label: node.label,
      children: [],
      event_id: node?.event_id,
      eventType: node?.eventType,
      leaf: node?.leaf,
      styleClass: node?.styleClass,
    }

    for (let index = 0; index < node.children.length; index++) {
      const data = this.makeEventOption(arrayRisksId, node.children[index]);
      if (data) {
        nodeClonde.children.push(data);
      }
    }

    if (nodeClonde.children.length > 0)
      return nodeClonde;
    else return null;
  }

  loadInitialData(rawData: any) {      
    // this.METADATA = ;
    this.alertType = AppConstant.ALERT_TYPE;

    this._alertService.getAlertFilterOptions().subscribe((res: AlertFilterOptionsResponse) => {
      const { status, events, actionTakens, risks, engineers, remoteCenters, rigs, projects, wells, timeSaved, timeRange } = res;

      // prepare Status options
      this.statusesOption = (status || []).map((item: any) => ({
        stateCode: item.id,
        statusName: item.name
      }));

      this.actionTakensOption = (actionTakens || []).map(item => ({
        actionTokenId: item.actionTakenId,
        actionTakenName: item.actionTakenName ? item.actionTakenName.charAt(0).toUpperCase() + item.actionTakenName.slice(1) : 'N/A'
      }));

      this.eventsOption = events || [];

      // Prepare Event option
      const listEventDataId: string[] = [];
      this.eventsOption = [];
      events.forEach((event: any) => {
        if (event?.event_id)
          listEventDataId.push(event.event_id);
      });
      AppConstant.EVENTS.map((nodeEvent: any) => {
        const dataTree = this.makeEventOption(listEventDataId, nodeEvent);        
        if (dataTree)
          this.eventsOption.push(dataTree);
      });

      
      // prepare Risk options      
      const listRiskDataId: string[] = [];
      const listRiskCustom: any[] = [];
      this.risksOption = [];

      risks.forEach((risk: any) => {
        if (risk?.risk_id) {
          listRiskDataId.push(risk.risk_id);
        } else {
          listRiskCustom.push(risk);
        }
      });
      AppConstant.RISKS.map((nodeRisk: any) => {
        const dataTree = this.makeRisksOption(listRiskDataId, nodeRisk);        
        if (dataTree)
          this.risksOption.push(dataTree);
      });

      // Merge RiskCustom
      if (listRiskCustom.length > 0) {
        const customOptions: any = {
          id: 'CUSTOM',
          label: 'Custom Risk',
          data: 'Custom Risk',
          risk_id: 'custom-risk',
          styleClass: 'parent_',
          selectable: true,
          children: [],
        };
        customOptions.children.concat(listRiskCustom);
        this.risksOption.push(customOptions)
      }      

      // prepare Engineer options
      this.engineersOption = this.makeEngineerOption(engineers) || [];

      // prepare Remote Centers options
      this.remoteCentersOption = (remoteCenters || []).map((item: any) => ({
        ...item,
        remoteCenterName: item.name
      }));

      // prepare Rig options
      this.rigsOption = rigs || [];

      // prepare Project options
      this.projectsOption = (projects || []).map(item => ({
        projectId: item.projectId,
        projectName: `${item.projectName} (${item?.edmSource})`, //item.projectCurrentName || item.projectOriginalName,
        policyId: item.policyId,
      }));

      // prepare Well options
      this.wellsOption = (wells || []).map(item => ({
        ...item,
        label: item.wellCurrentName || item.wellOriginalName || 'N/A',
      }));

      this.wellsOption = this.projectsOption.map((project: any) => {
        let childrendWell = this.wellsOption.filter(
          (well: any) => well.projectId === project.projectId
        );
        // prepare children for node
        childrendWell = childrendWell.map((child: any) => {
          return {
            ...child,
            id: child.wellId,
            data: child.wellId,
            label: child.label,
            selectable: true,
          };
        });
        // return node parent
        if (childrendWell.length > 0) {
          return {
            label: project.projectName,
            data: project.projectId,
            styleClass: 'parent',
            selectable: false,
            children: childrendWell,
          };
        } else {
          return {
            label: project.projectName,
            data: project.projectId,
            styleClass: 'nonChild',
            leaf: false,
            selectable: false,
            children: childrendWell,
          };
        }
      });

      // prepare Status options

      // Setting for Time Saved Dropdowns
      this.minTimeSaved = timeSaved?.[0] || this.minTimeSaved;
      this.maxTimeSaved = timeSaved?.[1] || this.maxTimeSaved;
      this.selectedTimeSaved = [this.minTimeSaved, this.maxTimeSaved];
      this.selectableTimeSave = `Selectable value is in the range: ${this.minTimeSaved} to ${this.maxTimeSaved}`;


      // Setting for Time Range Dropdowns
      const [smallest, largest] = timeRange || [];
      if (smallest && largest) {
        this.minDateValue = new Date(smallest);
        this.maxDateValue = new Date(largest);
        this.selectableDate = `Selectable value is in the range: ${AppHelper.DateFunctions.formatDateTime(
          new Date(smallest),
          false
        )} to ${AppHelper.DateFunctions.formatDateTime(
          new Date(largest),
          false
        )}`;
      }
    });


    let conditionEventId: any[] = [];

    conditionEventId =
      AppHelper.UtileFunctions.removeDuplicatesFromArray(conditionEventId);

    if (
      this.cacheSlectedOptionApplied.timeSaved &&
      this.cacheSlectedOptionApplied.timeSaved[0] &&
      this.cacheSlectedOptionApplied.timeSaved[1]
    ) {
      this.selectedTimeSaved = [
        this.cacheSlectedOptionApplied.timeSaved[0],
        this.cacheSlectedOptionApplied.timeSaved[1],
      ];
    } else {
      this.selectedTimeSaved = [this.minTimeSaved, this.maxTimeSaved];
    }

    // clear duplicate user
    this.engineersOption.forEach((engineer: any) => {
      if (engineer.children) {
        engineer.children = AppHelper.UtileFunctions.filterUniqueAndSortByKey(
          engineer.children,
          'label'
        );
      }
    });

    // Setting for Remote Center Dropdowns
    this.remoteCentersOption =
      AppHelper.UtileFunctions.filterUniqueAndSortByKey(
        this.remoteCentersOption,
        'remoteCenterName'
      );
    this.remoteCentersOption = AppHelper.UtileFunctions.sortArrayExceptNABykey(
      this.remoteCentersOption,
      'remoteCenterName'
    );

    // Setting for Rig Dropdowns
    this.rigsOption = AppHelper.UtileFunctions.filterUniqueAndSortByKey(
      this.rigsOption,
      'rigName'
    );

    // Setting for Project Dropdowns
    this.projectsOption = AppHelper.UtileFunctions.filterUniqueAndSortByKey(
      this.projectsOption,
      'projectName'
    );

    // Setting for Well Dropdowns
    this.wellsOption = AppHelper.UtileFunctions.filterUniqueAndSortByKey(
      this.wellsOption,
      'wellName'
    );
  }

  executeFilter() {
    let payloadChip: any = {};
    let payloadChipTimeRanges: any = [];
    let payloadChipEventTypes: any = [];
    let payloadChipStatuses: any = [];
    let payloadChipEvents: any = [];
    let payloadChipActionTakens: any = [];
    let payloadChipTimeSaved: any = [];
    let payloadChipRisks: any = [];
    let payloadChipEngineers: any = [];
    let payloadChipRemoteCenters: any = [];
    let payloadChipRigs: any = [];
    let payloadChipProjects: any = [];
    let payloadChipWells: any = [];

    // Time saved always multiple with start-end value
    let timeRangeChip = '';

    if (this.selectedTimeRange) {
      if (this.selectedTimeRange[0] && this.selectedTimeRange[1]) {
        timeRangeChip = `Time Range: ${AppHelper.DateFunctions.formatDateTime(
          new Date(this.selectedTimeRange[0]),
          false
        )} - ${AppHelper.DateFunctions.formatDateTime(
          new Date(this.selectedTimeRange[1]),
          false
        )}`;
      } else {
        timeRangeChip = `Time Range: ${AppHelper.DateFunctions.formatDateTime(
          new Date(this.selectedTimeRange[0]),
          false
        )}`;
      }
    }

    payloadChipTimeRanges.push(timeRangeChip);

    (this.selectedStatus || []).length > 1
      ? payloadChipStatuses.push(`Multiple status`)
      : (this.selectedStatus || []).length == 1
        && payloadChipStatuses.push(`Status: ${this.selectedStatus[0].statusName}`);

    const selectedEvent = this.selectedEvent?.filter((node: any) => node?.event_id);
    (selectedEvent || []).length > 1
      ? payloadChipEvents.push(`Multiple event`)
      : (selectedEvent || []).length == 1
        && payloadChipEvents.push(`Event: ${selectedEvent[0].data}`);

    (this.selectedAlertType || []).length > 1
      ? payloadChipEventTypes.push(`Multiple type`)
      : (this.selectedAlertType || []).length == 1
        && payloadChipEventTypes.push(`Alert type: ${this.selectedAlertType[0].name}`);

    (this.selectedActionTaken || []).length > 1
      ? payloadChipActionTakens.push(`Multiple Action Taken`)
      : (this.selectedActionTaken || []).length == 1
        && payloadChipActionTakens.push(
          `Action: ${this.selectedActionTaken[0].actionTakenName}`
        );

    // Time saved always multiple with start-end value
    if (!isEmpty(this.selectedTimeSaved) && (this.selectedTimeSaved[0] !== this.minTimeSaved || this.selectedTimeSaved[1] !== this.maxTimeSaved)) {
      payloadChipTimeSaved.push(`Time Saved: ${this.selectedTimeSaved[0]} - ${this.selectedTimeSaved[1]}`);
    }

    const selectedRisk = this.selectedRisk?.filter((node: any) => node?.risk_id);
    (selectedRisk || []).length > 1
      ? payloadChipRisks.push(`Multiple risk`)
      : (selectedRisk || []).length == 1
        && payloadChipRisks.push(`Risk: ${selectedRisk[0].data}`);

    const selectedEngineer = this.selectedEngineer?.filter((node: any) => node?.selectable);
    (selectedEngineer || []).length > 1
      ? payloadChipEngineers.push(`Multiple engineer`)
      : (selectedEngineer || []).length == 1
        && payloadChipEngineers.push(`Engineer: ${selectedEngineer[0].label}`);

    (this.selectedRemoteCenter || []).length > 1
      ? payloadChipRemoteCenters.push(`Multiple remote center`)
      : (this.selectedRemoteCenter || []).length == 1
        && payloadChipRemoteCenters.push(
          `Remote Center: ${this.selectedRemoteCenter[0].remoteCenterName}`
        );

    (this.selectedRig || []).length > 1
      ? payloadChipRigs.push(`Multiple rig`)
      : (this.selectedRig || []).length == 1
        && payloadChipRigs.push(`Rig: ${this.selectedRig[0].rigName}`);

    (this.selectedProject || []).length > 1
      ? payloadChipProjects.push(`Multiple project`)
      : (this.selectedProject || []).length == 1
        && payloadChipProjects.push(`Project: ${this.selectedProject[0].projectName}`);

    (this.selectedWell || []).length > 1
      ? payloadChipWells.push(`Multiple well`)
      : (this.selectedWell || []).length == 1
        && payloadChipWells.push(`Well: ${this.selectedWell[0].wellName || this.selectedWell[0].label}`);

    payloadChip = {
      timeRange: payloadChipTimeRanges,
      eventType: payloadChipEventTypes,
      status: payloadChipStatuses,
      event: payloadChipEvents,
      actionTaken: payloadChipActionTakens,
      timeSaved: payloadChipTimeSaved,
      risk: payloadChipRisks,
      engineer: payloadChipEngineers,
      remoteCenter: payloadChipRemoteCenters,
      rig: payloadChipRigs,
      project: payloadChipProjects,
      well: payloadChipWells,
    };

    if (!(this.selectedTimeRange || []).length) {
      delete payloadChip.timeRange;
    }

    if (!(this.selectedAlertType || []).length) {
      delete payloadChip.eventType;
    }

    if (!(this.selectedStatus || []).length) {
      delete payloadChip.status;
    }

    if (!(selectedEvent || []).length) {
      delete payloadChip.event;
    }

    if (!(this.selectedActionTaken || []).length) {
      delete payloadChip.actionTaken;
    }

    if (
      this.selectedTimeSaved[0] === this.minTimeSaved &&
      this.selectedTimeSaved[1] === this.maxTimeSaved
    ) {
      delete payloadChip.timeSaved;
    }

    if (!(selectedRisk || []).length) {
      delete payloadChip.risk;
    }

    if (!(selectedEngineer || []).length) {
      delete payloadChip.engineer;
    }

    if (!(this.selectedRemoteCenter || []).length) {
      delete payloadChip.remoteCenter;
    }

    if (!(this.selectedRig || []).length) {
      delete payloadChip.rig;
    }

    if (!(this.selectedProject || []).length) {
      delete payloadChip.project;
    }

    if (!(this.selectedWell || []).length) {
      delete payloadChip.well;
    }

    // payload cal API
    let payload: any = {};
    let payloadTimeRanges: any = [];
    let payloadEventTypes: any = [];
    let payloadStatuses: any = [];
    let payloadEvents: any = [];
    let payloadActionTakens: any = [];
    let payloadTimesaved: any = [];
    let payloadRisks: any = [];
    let payloadEngineers: any = [];
    let payloadRemoteCenter: any = [];
    let payloadRigs: any = [];
    let payloadProjects: any = [];
    let payloadWells: any = [];

    (this.selectedTimeRange || []).forEach((item: any) => {
      const timeRangePoint = new Date(item).getTime();
      payloadTimeRanges.push(timeRangePoint || payloadTimeRanges[0]);
    });

    (this.selectedAlertType || []).forEach((item: any) => {
      payloadEventTypes.push(item.id);
    });

    (this.selectedStatus || []).forEach((item: any) => {
      payloadStatuses.push(item.stateCode);
    });

    (selectedEvent || []).forEach((item: any) => {
      payloadEvents.push(item.event_id);
    });

    (this.selectedActionTaken || []).forEach((item: any) => {
      payloadActionTakens.push(item.actionTakenName);
    });
  
    if (!isEmpty(this.selectedTimeSaved) && (this.selectedTimeSaved[0] !== this.minTimeSaved || this.selectedTimeSaved[1] !== this.maxTimeSaved)) {
      this.selectedTimeSaved.forEach((item: any) => {
        payloadTimesaved.push(item);
      });
    }

    (selectedRisk || []).forEach((item: any) => {
      if (selectedRisk[0]?.risk_id)
        payloadRisks.push(item.risk_id);
    });

    (selectedEngineer || []).forEach((item: any) => {
      payloadEngineers.push(item.id);
    });

    (this.selectedRemoteCenter || []).forEach((item: any) => {
      payloadRemoteCenter.push(item.id);
    });

    (this.selectedRig || []).forEach((item: any) => {
      payloadRigs.push(item.rigId);
    });

    (this.selectedProject || []).forEach((item: any) => {
      payloadProjects.push(item.projectId);
    });

    (this.selectedWell || []).forEach((item: any) => {
      payloadWells.push(item.wellId);
    });

    payload = {
      timeRange: payloadTimeRanges,
      eventType: payloadEventTypes,
      status: payloadStatuses,
      event: payloadEvents,
      actionTaken: payloadActionTakens,
      timeSaved: payloadTimesaved,
      risk: payloadRisks,
      engineer: payloadEngineers,
      remoteCenter: payloadRemoteCenter,
      rig: payloadRigs,
      project: payloadProjects,
      well: payloadWells,
    };
  
    this.chipFilter.emit(Object.assign({}, payloadChip));
    this.filter.emit(Object.assign({}, payload));

    this.cacheSlectedOptionApplied.timeRange = this.selectedTimeRange;
    this.cacheSlectedOptionApplied.status = this.selectedStatus;
    this.cacheSlectedOptionApplied.event = this.selectedEvent;
    this.cacheSlectedOptionApplied.eventString = this.selectedEventString;
    this.cacheSlectedOptionApplied.actionTaken = this.selectedActionTaken;
    this.cacheSlectedOptionApplied.timeSaved = [...this.selectedTimeSaved];
    this.cacheSlectedOptionApplied.risk = this.selectedRisk;
    this.cacheSlectedOptionApplied.riskString = this.selectedRiskString;
    this.cacheSlectedOptionApplied.engineer = this.selectedEngineer;
    this.cacheSlectedOptionApplied.engineerString = this.selectedEngineerString;
    this.cacheSlectedOptionApplied.remoteCenter = this.selectedRemoteCenter;
    this.cacheSlectedOptionApplied.rig = this.selectedRig;
    this.cacheSlectedOptionApplied.project = this.selectedProject;
    this.cacheSlectedOptionApplied.well = this.selectedWell;
    this.cacheSlectedOptionApplied.eventType = this.selectedAlertType;
  }

  onHide(event: any) {
    this.selectedTimeRange = this.cacheSlectedOptionApplied?.timeRange || []; 
    this.selectedStatus = this.cacheSlectedOptionApplied?.status || []; 
    this.selectedEvent = this.cacheSlectedOptionApplied?.event || [];
    this.onEventOptions(null, "|");

    this.selectedEventString = this.cacheSlectedOptionApplied?.eventString || this.METADATA.OPTION.PLACE_HOLDER; 
    this.selectedActionTaken = this.cacheSlectedOptionApplied?.actionTaken || []; 
    this.selectedTimeSaved = [...this.cacheSlectedOptionApplied?.timeSaved || [this.minTimeSaved, this.maxTimeSaved]]; 
    this.selectedRisk = this.cacheSlectedOptionApplied?.risk || [];
    this.onSelectRisk();
    this.selectedRiskString = this.cacheSlectedOptionApplied?.riskString || this.METADATA.OPTION.PLACE_HOLDER; 

    this.selectedEngineer = this.cacheSlectedOptionApplied?.engineer || [];
    this.onSelectEngineer();
    this.selectedEngineerString = this.cacheSlectedOptionApplied?.engineerString || this.METADATA.OPTION.PLACE_HOLDER; 

 
    this.selectedRemoteCenter = this.cacheSlectedOptionApplied?.remoteCenter || []; 
    this.selectedRig = this.cacheSlectedOptionApplied?.rig || []; 
    this.selectedProject = this.cacheSlectedOptionApplied?.project || []; 
    this.selectedWell = this.cacheSlectedOptionApplied?.well || []; 
    this.selectedAlertType = this.cacheSlectedOptionApplied?.eventType || [];      

    if (this.selectedTimeSaved[0] <= this.selectedTimeSaved[1])
      this.isWarnField = false;
  }

  onResetFilter(type: string) {
    switch (type) {
    case 'all':
      // for Event dropdown
      this.onClearEvent();

      this.selectedTimeRange = null;
      this.selectedStatus = [];
      this.selectedActionTaken = [];
      this.selectedTimeSaved = [this.minTimeSaved, this.maxTimeSaved];
      this.onClearRisk();
      this.onClearEngineer();

      this.selectedRemoteCenter = [];
      this.selectedRig = [];
      this.selectedProject = [];
      this.selectedWell = [];
      this.selectedAlertType = [];      

      this.cacheSlectedOptionApplied = {
        actionTaken: null,
        engineer: null,
        engineerString: this.METADATA.OPTION.PLACE_HOLDER,
        event: null,
        eventString: this.METADATA.OPTION.PLACE_HOLDER,
        project: null,
        remoteCenter: null,
        rig: null,
        risk: null,
        riskString: this.METADATA.OPTION.PLACE_HOLDER,
        status: null,
        timeRange: null,
        timeSaved: [this.minTimeSaved, this.maxTimeSaved],
        well: null,
        eventType: null,
      };

      break;
    case 'actionTaken':
      this.selectedActionTaken = [];
      this.cacheSlectedOptionApplied.actionTaken = [];
      break;
    case 'engineer':
      this.onClearEngineer();
      this.cacheSlectedOptionApplied.engineer = [];
      this.selectedEngineerString = this.METADATA.OPTION.PLACE_HOLDER;
      break;
    case 'event':
      this.onClearEvent();
      this.cacheSlectedOptionApplied.event = [];
      this.cacheSlectedOptionApplied.eventString = 'All';
      this.selectedEventString = this.METADATA.OPTION.PLACE_HOLDER;
      break;
    case 'project':
      this.selectedProject = [];
      this.cacheSlectedOptionApplied.project = [];
      break;
    case 'remoteCenter':
      this.selectedRemoteCenter = [];
      this.cacheSlectedOptionApplied.remoteCenter = [];
      break;
    case 'rig':
      this.selectedRig = [];
      this.cacheSlectedOptionApplied.rig = [];
      break;
    case 'risk':
      this.onClearRisk();
      this.cacheSlectedOptionApplied.risk = [];
      this.selectedRiskString = this.METADATA.OPTION.PLACE_HOLDER;
      break;
    case 'status':
      this.selectedStatus = [];
      this.cacheSlectedOptionApplied.status = [];
      break;
    case 'timeRange':
      this.selectedTimeRange = null;
      this.cacheSlectedOptionApplied.timeRange = null;
      break;
    case 'timeSaved':
      this.selectedTimeSaved = [this.minTimeSaved, this.maxTimeSaved];
      this.cacheSlectedOptionApplied.timeSaved = [this.minTimeSaved, this.maxTimeSaved];
      break;
    case 'well':
      this.selectedWell = [];
      this.cacheSlectedOptionApplied.well = [];
      break;
    case 'eventType': 
      this.selectedAlertType = [];
      this.cacheSlectedOptionApplied.eventType = [];
      break;
    default:
      break;
    }
  }

  onResetInitial() {
    this.loadInitialData(this.alertListData);
  }

  selectedOptionChange(event: any, selectedItem: any, typeItem: any) {}

  selectedOptionClear(typeItem: any) {}

  previewDiscipline: any[] = [];
  isCallDataRemoteCenter: boolean = false;

  previewRemoteCenter: any[] = [];
  isCallDataDiscipline: boolean = false;

  onPanelHideRemoteCenterDropdown() {}

  onPanelHideDisciplineDropdown() {}

  loadDataDiscipline() {}
  loadDataRemoteCenter() {}

  findNodeWithChildLabel(node: any, childLabel: string) {
    if (node.label === childLabel) {
      return node;
    } 
    for (const child of node.children) {
      const found: any = this.findNodeWithChildLabel(child, childLabel);
      if (found) {
        const nodeClonde: any = {
          event_id: node.event_id,
          data: node.data, 
          label: node.label,
          children: found,
          styleClass: node?.styleClass,
          selectable: false,
        }
        return nodeClonde;
      }
    }
    return null;
  }

  onEventOptions(event: any = null, seperateMark: string) {
    const arrString: string[] = [];

    this.selectedEvent?.filter((node: any) => node?.event_id).forEach((node: any) => {
      for (let events of AppConstant.EVENTS) {        
        const result = this.findNodeWithChildLabel(events, node.label);
        if (result) {
          const arrLabel = [result.label];
          let cloneChildren = result?.children || null;

          while (cloneChildren) {
            cloneChildren?.label && arrLabel.push(cloneChildren.label);
            cloneChildren = cloneChildren?.children || null;
          }

          const str = arrLabel.join(` ${seperateMark} `);
          arrString.push(str)
          break;
        }
      }
    })

    this.selectedEventString = arrString.join(", ") || 'All';
    this.countSelectedEvent = arrString?.length;
  }
  
  onEventUncheck(event: any) {
    const arrString = this.selectedEventString
                        .split(", ")
                        .filter((label: string) => !label.includes(event.node.label));
                              
    this.selectedEventString = arrString.join(", ") || 'All';
    this.countSelectedEvent = arrString?.length;
  }

  onNodeExpand(event: any) {
    if (event.node.children === undefined) event.node.expanded = false;
  }

  onSelectRisk() {
    let arrString: any[] = [];
    this.countSelectedRisk = 0;
    this?.selectedRisk?.forEach((nodeSelect: any) => {
      if (nodeSelect.risk_id) {
        this.countSelectedRisk++;
        arrString.push(nodeSelect.label);
      }
    });
    this.selectedRiskString = arrString.join(", ") || 'All';
  }

  onSelectEngineer() {
    let arrString: any[] = [];
    this.countSelectedEngineer = 0;
    this?.selectedEngineer?.forEach((nodeSelect: any) => {
      if (nodeSelect.selectable) {
        this.countSelectedEngineer++;
        arrString.push(nodeSelect.label);
      }
    })    
    this.selectedEngineerString = arrString.join(", ") || 'All';
  }

  onClearEvent(event: any = null) {
    this.selectedEvent = [];
    this.countSelectedEvent = 0;
    this.selectedEventString = this.METADATA.OPTION.PLACE_HOLDER;
    this.resetPartialSelected(this.eventsOption);
  }

  resetPartialSelected(option: any) {
    if (!option) return;

    for (let node of option) {
      node.partialSelected = false;
      if (node.partialSelected && node.children?.length > 0) 
        this.resetPartialSelected(node.children)
    }
  }

  onClearRisk(event: any = null) {
    this.selectedRisk = [];
    this.countSelectedRisk = 0;
    this.selectedRiskString = ALERT_ADVANCED_FILTER.OPTION.PLACE_HOLDER;
  }
  onClearEngineer(event: any = null) {
    this.selectedEngineer = [];
    this.countSelectedEngineer = 0;
    this.selectedEngineerString = ALERT_ADVANCED_FILTER.OPTION.PLACE_HOLDER;
  }
  onClearStatus(event: any) {
    this.selectedStatus = [];
  }

  onClearAlertType(event: any) {
    this.selectedAlertType = [];
  }

  togglePanel(event: Event) {
    event.stopPropagation();
    this.showSlider = !this.showSlider;
  }

  onClickOutside(event: Event) {
    this.showSlider = false;
  }

  filterComplexDataByRiskId(data: any[], conditionData: string[]): any[] {
    return data.reduce((acc: any[], item: any) => {
      if (item.risk_id && conditionData.includes(item.risk_id)) {
        // If the current item has a valid risk_id and is present in conditionData, keep it
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        // If the current item has children, recursively call the function on its children
        const filteredChildren = this.filterComplexDataByRiskId(
          item.children,
          conditionData
        );
        if (filteredChildren.length > 0) {
          // If there are filtered children, keep the current item and replace its children with the filtered ones
          acc.push({ ...item, children: filteredChildren });
        }
      }
      return acc;
    }, []);
  }

  filterComplexDataByEventId(data: any[], conditionData: string[]): any[] {
    return data.reduce((acc: any[], item: any) => {
      if (item.event_id && conditionData.includes(item.event_id)) {
        // If the current item has a valid event_id and is present in conditionData, keep it
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        // If the current item has children, recursively call the function on its children
        const filteredChildren = this.filterComplexDataByEventId(
          item.children,
          conditionData
        );
        if (filteredChildren.length > 0) {
          // If there are filtered children, keep the current item and replace its children with the filtered ones
          acc.push({ ...item, children: filteredChildren });
        }
      }
      return acc;
    }, []);
  }

  findOverallLargestAndSmallestNumbers(
    arr: any[],
    key1: string,
    key2: string
  ): { smallest: number; largest: number } {
    let smallest: number | null = null;
    let largest: number | null = null;
    for (const obj of arr) {
      const dateNumberStart = obj[key1];
      const dateNumberEnd = obj[key2];
      if (
        typeof dateNumberStart === 'number' &&
        (smallest === null || dateNumberStart < smallest)
      ) {
        smallest = dateNumberStart;
      }
      if (
        typeof dateNumberEnd === 'number' &&
        (largest === null || dateNumberEnd > largest)
      ) {
        largest = dateNumberEnd;
      }
    }
    if (smallest === null) {
      smallest = 0; // Set a default value if there are no valid dateNumberStart values.
    }
    if (largest === null) {
      largest = 0; // Set a default value if there are no valid dateNumberEnd values.
    }
    return { smallest, largest };
  }

  pickAllTimeSaved() {
    if (
      this.selectedTimeSaved[0] !== this.minTimeSaved ||
      this.selectedTimeSaved[1] !== this.maxTimeSaved
    ) {
      this.selectedTimeSaved = [this.minTimeSaved, this.maxTimeSaved];
    }
  }

  onOptionsUpdateWell(event: any) {
    if (this.selectedWell) {
      this.selectedWell = this.selectedWell.filter((well: any) => well.wellId);

      const selectedMappingProject = this.projectsOption.filter(
        (project: any) => {
          return this.selectedWell.some((well: any) => {
            return well.projectId === project.projectId;
          });
        }
      );
      if (!this.selectedProject) this.selectedProject = [];
      this.selectedProject.push(...selectedMappingProject);
      this.selectedProject = AppHelper.UtileFunctions.filterUniqueAndSortByKey(
        this.selectedProject,
        'projectId',
        false
      );
    }
  }

  onOptionsUpdateProject(event: any) {
    if (this.selectedProject) {
      let justPickId = event.itemValue.projectId;
      let findInProject = this.selectedProject.find(
        (project: any) => project.projectId === justPickId
      );

      if (findInProject === undefined) {
        // remove this
        this.selectedWell = this.selectedWell.filter(
          (well: any) => well.parent.data !== justPickId
        );
      }
    } else {
      this.selectedWell = null;
    }
  }
  onClearProject(event: any) {
    this.selectedWell = null;
  }

  // Prevent '-', '+', 'e' in Input type="number"
  public inputNumber_Clear_e(event: any, index: number) {
    let invalidChars = ['-', '+', 'e', 'E'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    } else {
      if (index === 0) {
        if (+event.target.value > this.selectedTimeSaved[0]) {
          this.selectedTimeSaved = this.selectedTimeSaved;
        } else {
          this.selectedTimeSaved = [
            +event.target.value,
            this.selectedTimeSaved[1],
          ];
        }
      } else {
        this.selectedTimeSaved = [
          this.selectedTimeSaved[0],
          +event.target.value,
        ];
      }
    }
  }

  handleTime(event: any, index: number) {
    if (index === 0) {
      this.selectedTimeSaved = [+event.target.value, this.selectedTimeSaved[1]];
    } else {
      this.selectedTimeSaved = [this.selectedTimeSaved[0], +event.target.value];
    }

    if (
      this.selectedTimeSaved[0] !== null &&
      this.selectedTimeSaved[1] !== null
    ) {
      if (this.selectedTimeSaved[0] > this.selectedTimeSaved[1]) {
        this.isWarnField = true;
      } else {
        this.isWarnField = false;
      }
    }
  }

  onDestroy(): void {}
}
