import { Component, ViewChild } from '@angular/core';
import {
  takeUntil,
  catchError,
  of,
  finalize,
  BehaviorSubject,
  filter,
  take,
} from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { UserAdvancedFilterComponent } from '../user-advanced-filter/user-advanced-filter.component';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { Menu } from 'primeng/menu';
import { UserADGroup } from 'src/app/shared/interface/userADG.interface';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';

@Component({
  selector: 'app-user-team-management',
  templateUrl: './user-team-management.component.html',
  styleUrls: ['./user-team-management.component.scss'],
})
export class UserTeamManagementComponent extends BaseComponent {
  isLoading: boolean = false;

  isViewer: boolean = false;
  isManager: boolean = false;

  userListColumn: any[] = [
    { field: 'displayName', header: 'User' },
    { field: 'roleDisplay', header: 'Role' },
    { field: 'halId', header: 'Halliburton ID' },
    { field: 'psl', header: 'PSL' },
    { field: 'countryDisplay.name', header: 'Country' },
    { field: 'remoteCenterDisplay', header: 'Remote Center' },
    { field: 'timezone', header: 'Time Zone' },
    { field: 'mail', header: 'Email' },
    { field: 'phone', header: 'Phone' },
    { field: 'mailBoxDisplay', header: 'Functional Mailbox' },
    { field: 'features', header: '' },
  ];

  userListDataTemp: any[] = [];
  userListData: any[] = [];
  restoreUserListData: any[] = [];
  cacheUserListData: any[] = [];

  payloadFilter: any;

  PSLs: any[] = [];
  Countries: any[] = [];

  counterResult: number = 0;

  // Variable of Form
  @ViewChild('searchForm') searchForm!: NgForm;
  @ViewChild('advancedFilter') advancedFilter:
    | UserAdvancedFilterComponent
    | undefined;

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  msgFromApis: string = 'No result found';
  previewSearch: string = '';

  displayEditUser = false;
  displayAddUser = false;

  editUser: any;
  currentUser: any;

  // Variables for Filter Chips
  chipsFilter: String[] = [];
  chipFiltersKey: string[] = [];

  AppHelper = AppHelper;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;
  
  constructor(
    private _location: Location,
    private _router: Router,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService,
    public _blobService: AzureBlobService,
  ) {
    super();
  }

  onInit(): void {
    this.isLoading = true;

    this.PSLs = AppConstant.HALLIBURTON_PSLs;
    this.Countries = AppConstant.COUNTRIES;


    this._userInfoService.userSubject$.pipe(
      filter((userObject: any) => !!userObject),
      take(1),
    ).subscribe((response) => {
      if (response) {
        this.currentUser = response;
        this.isViewer =
          this.currentUser.role === AppConstant.ROLES.VIEWER.label;
        this.isManager =
          this.currentUser.role === AppConstant.ROLES.MANAGER.label;

        this.filterUserManagement(0);
      }
    });
  }

  onDestroy(): void {}

  filterUserManagement(timeDebounce: number) {
    if (this.searchForm?.value) {
      this.isLoading = true;
      const formValue = this.searchForm?.value;

      this._userInfoService
        .getUsersByStringEDM(formValue.searchString, timeDebounce)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: (res) => {
            this.counterResult = res.length;
            this.userListDataTemp = res;
            this.userListData = res;

            if (res.length === 0) {
              this.msgFromApis = 'No result found';
              return of(null);
            } else {
              if (this.userListDataTemp.length === res.length) {
                this.userListDataTemp = AppHelper.UtileFunctions.sortArrayBykey(
                  this.userListDataTemp,
                  'displayName'
                );
                // // Convert Mailbox to String --> Render

                this.userListData = [...this.userListDataTemp];

                this.userListData = this.formatUserListData(this.userListData);
                this.restoreUserListData = JSON.parse(
                  JSON.stringify(this.userListData)
                );
                this.cacheUserListData = JSON.parse(
                  JSON.stringify(this.userListData)
                );
                this.loadPayloadFilter(this.payloadFilter);
              }
            }
          },
          error: (error) => {
            // this._notificationService.setMessage({
            //   type: AppConstant.MESSAGE_TYPE.WARNING,
            //   header: 'Loading image',
            //   content: error?.message || error,
            // });
          },
        });
    }
  }

  formatUserListData(userListData: any[]): any[] {
    userListData = userListData.map((user: any) => {
      return this.formatUser(user);
    });
    return userListData;
  }

  formatUser(user:any) {
    const mailBoxDisplay = user.mailboxList.map((mailbox: any) => {
      return mailbox.mailboxName;
    });

    const remoteCenterDisplay = user.remoteCenterList.map((remoteCenter: any) => {
      return remoteCenter.remoteCenterName;
    });

    const pslDisplay = this.PSLs.find((psl: any) => {
      return psl.code === user.psl;
    });

    const countryDisplay = this.Countries.find((country: any) => {
      return country.code === user.country;
    });

    if (!user.remoteCenterList.length) {
      user.remoteCenterList = [
        { remoteCenterId: null, remoteCenterName: 'N/A' },
      ];
    }
    if (!user.mailboxList.length) {
      user.mailboxList = [
        { mailboxId: null, mailboxName: 'N/A' },
      ];
    }

    if (user?.avatar) {
      const url = this._blobService.getUserImage(
        decodeURIComponent(
          AppHelper.StringFunctions.getFileName(user.avatar)
        )
      );
      user = {...user, avatarBlob: url};
    }

    return {
      ...user,
      roleDisplay:
        user.role === 'Engineer' && user.discipline?.disciplineName
          ? user.role + ' (' + user.discipline?.disciplineName + ') '
          : user.role,
      mailBoxDisplay: mailBoxDisplay.join(', '),
      remoteCenterDisplay: remoteCenterDisplay.join(', '),
      pslDisplay: pslDisplay,
      countryDisplay: countryDisplay,
    };
  }

  ngAfterViewInit(): void {
    //
    this.filterUserManagement(700);

    this._userInfoService.reloadUserManagement$.subscribe(
      (isReload: boolean) => {
        if (isReload) {
          this.filterUserManagement(0);
        }
      }
    );
  }

  showContextMenu(event: any, inputData: UserADGroup) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(userADGroup: UserADGroup): any {
    return [
      {
        label: 'Permissions',
        icon: 'c-icons setting-icon',
        command: () => {
          this._router.navigate([`user/team-management/${userADGroup.id}`], {});
        },
      },
      {
        label: 'Edit User',
        icon: 'c-icons edit-icon',
        command: () => {
          this.displayEditUser = true;
          this.editUser = this.userListData.find(
            (user) => user.id === userADGroup.id
          );
        },
      },
      {
        label: 'Delete',
        visible: this.currentUser?.id !== userADGroup.id,
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        disabled: this.isViewer,
        command: () => {
          this.editUser = this.userListData.find(
            (user) => user.id === userADGroup.id
          );

          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this user (${this.editUser.halId}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete this user.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  removeUser(editUser: any): void {
    this.isLoading = true;
    this._userInfoService
      .removeUser(editUser.id)
      .pipe(
        catchError(AppHelper.UtileFunctions.handleError),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Remove User',
            content: `Remove User: ${editUser.halId} - ${editUser.firstName} ${editUser.lastName} successfully!`,
          });
          this.isLoading = true;
        },
        error: (error: any) => {
          error &&
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Remove User',
              content: error?.message || error,
            });
          this.isLoading = true;
        },
        complete: () => {
          this.updateUser(editUser.id, {}, 'DELETE');
        },
      });
  }
  redirectToRollAndPermission(userId?: any): any {
    this._router.navigate([`user/team-management/${userId}`], {});
  }

  getFirstLetter(userName: string | undefined): string {
    if (!userName) return 'Unknown User Name';

    let arrySplitName = userName.trim().split(' ');
    let userNameLength = arrySplitName.length;

    let firstLetter = arrySplitName[0].charAt(0);
    let secondLetter = arrySplitName[userNameLength - 1].charAt(0);

    return (firstLetter + secondLetter).toLocaleUpperCase() || userName;
  }

  keyupSearch(e?: any) {
    let searchTextValue = this.searchForm.controls['searchString'].value;
    this.userListData = JSON.parse(JSON.stringify(this.cacheUserListData));

    if (searchTextValue) {
      let regex = new RegExp(searchTextValue, 'i');
      this.userListData = this.userListData.filter((user: any) => {
        if (user.customMail) {
          return (
            regex.test(user.firstName + ' ' + user.lastName) ||
            regex.test(user.customMail)
          );
        } else {
          return (
            regex.test(user.firstName + ' ' + user.lastName) ||
            regex.test(user.mail)
          );
        }
      });
      this.counterResult = this.userListData.length;
    } else {
      this.userListData = JSON.parse(JSON.stringify(this.cacheUserListData));
      this.counterResult = this.userListData.length;
    }
  }

  loadChipFilter(itemFilter: any) {
    let items = Object.assign({}, itemFilter);
    this.chipFiltersKey = Object.keys(items);
    this.chipsFilter = [];

    Object.keys(items).forEach((item: any) => {
      switch (items[item][0]) {
        case 'US':
          this.chipsFilter.push('USA');
          break;
        case 'AE':
          this.chipsFilter.push('UAE');
          break;
        case 'N/A':
          if (item === 'remoteCenter') {
            this.chipsFilter.push('No Remote Center');
          }
          if (item === 'mailbox') {
            this.chipsFilter.push('No Functional Mailbox');
          }
          break;
        default:
          this.chipsFilter.push(items[item][0]);
          break;
      }
    });
  }

  loadPayloadFilter(payloadFilter: any) {
    this.payloadFilter = payloadFilter;

    this.userListData = JSON.parse(JSON.stringify(this.restoreUserListData));

    if (this.payloadFilter) {
      this.userListData = this.filterPayloadData(
        this.userListData,
        this.payloadFilter
      );
      this.counterResult = this.userListData.length;
    } else {
      this.userListData = JSON.parse(JSON.stringify(this.restoreUserListData));
      this.counterResult = this.userListData.length;
    }

    this.cacheUserListData = JSON.parse(JSON.stringify(this.userListData));

    this.keyupSearch();
  }

  filterPayloadData(data: any[], condition: any) {
    return data.filter((item) => {
      const flagRemoteCenter = !!item?.remoteCenterList?.some((remoteCenter: any) => {
        if (condition.remoteCenter.includes(remoteCenter?.remoteCenterId))
          return true;
      });
      
      const includeNA = (condition.remoteCenter.includes(null));
      let userNotHaveRemoteCenter = (includeNA && item?.remoteCenterList?.length == 0) || false;

      return (
        (!condition.remoteCenter?.length || flagRemoteCenter || userNotHaveRemoteCenter) &&
        (!condition.role?.length ||
          condition.role.includes(item.role) ||
          condition.role.includes(item?.discipline?.disciplineId)) &&
        (!condition.mailbox?.length ||
          item.mailboxList.some((mail: any) =>
            condition.mailbox.includes(mail.mailboxId)
          )) &&
        (!condition.psl?.length || condition.psl.includes(item.psl)) &&
        (!condition.country?.length || condition.country.includes(item.country))
      );
    });
  }

  removeItemChip(item: any, i: number) {
    let keyItemRemove: any = this.chipFiltersKey[i];

    this.chipsFilter.splice(i, 1);
    this.chipFiltersKey.splice(i, 1);

    this.advancedFilter?.onResetFilter(keyItemRemove);

    this.payloadFilter[keyItemRemove] = [];

    this.loadPayloadFilter(this.payloadFilter);
  }
  clearFilter(): void {
    this.advancedFilter?.onResetFilter('all');

    this.searchForm.controls['searchString'].setValue('');

    this.chipsFilter = [];

    this.payloadFilter = {
      remoteCenter: [],
      role: [],
      mailbox: [],
      psl: [],
      country: [],
    };

    this.filterUserManagement(0);
  }
  // copy text
  copyText(copyText: string) {
    navigator.clipboard.writeText(copyText);
    this._notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.SUCCESS,
      header: 'Copy clipboard !',
      content: `Copied: ${copyText} successfully`,
    });
  }
  backHistory() {
    this._location.back();
  }

  // Reload after Add/Edit User
  requestReloadPage(event: any) {
    const {flag, userId, data, action} = event;

    this.displayAddUser = !flag;
    this.displayEditUser = !flag;
    this.updateUser(userId, data, action);
  }

  updateUser(userId: string, data: any = {}, action: string) {
    let newUserInfo = null;
    if (data && action !== "DELETE")
      newUserInfo = this.formatUser(data);
    
    switch (action) {
      case 'CREATE':        
        this.restoreUserListData.push(newUserInfo);        
        break;
      case 'EDIT':        
        let idx = this.restoreUserListData?.findIndex((user: any) => user.id === userId);
        if (idx) {
          this.restoreUserListData[idx] = newUserInfo;
        }
        break;
      case 'DELETE':
        this.restoreUserListData = this.restoreUserListData?.filter((user: any) => user.id !== userId);
        break;
      default:
        break;
    }
    this.loadPayloadFilter(this.payloadFilter);
    this.isLoading = false;
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Delete':
            this._confirmService.clearDialog();
            this.removeUser(this.editUser);
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  replaceCanadaDialCode(canadaPhoneNumber: string): string {
    if (canadaPhoneNumber && canadaPhoneNumber.slice(0, 3) === '+11') {
      return (canadaPhoneNumber =
        canadaPhoneNumber.slice(0, 1) + canadaPhoneNumber.slice(2));
    }
    return canadaPhoneNumber;
  }
}
