import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable} from 'rxjs';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { log } from 'fabric/fabric-impl';

@Injectable()
export class HomeFilterService extends BaseService {
  constructor(httpClient: HttpClient) { 
    super(httpClient); 
  }

  focusRigCard = new BehaviorSubject<string>('');
  searchRigCard = new BehaviorSubject<string[]>([]);


  // == focusRigCard Area ===
  setFocusRigCard(rigJournalId: string) {
    this.focusRigCard.next(rigJournalId);
  }

  getFocusRigCard(): string {
    return this.focusRigCard.getValue();
  }
  // == focusRigCard Area ===


  // == searchRigCard Area ===
  setSearchRigCard(searchRigCardList: string[]) {
    this.searchRigCard.next(searchRigCardList);
  }

  getSearchRigCard(): string[] {
    return this.searchRigCard.getValue();
  }
  // == searchRigCard Area ===
  
  // == Get Home Filter Options Function 
  public getHomeFilterOptions(payload: any): Observable<any> {
    const filterPayload: any = Object.assign({}, payload);
    if ((payload.code && filterPayload.code === 'active') || (filterPayload.code && filterPayload.code === 'All' && filterPayload.status))
      payload.isActive = true
    
    const uri = 'rig-journal/filter-options';    
    return this.get<any[]>(uri, {
      params: {
        ...(payload || {}),
        pageSize: 1000000,
        pageIndex: 1,
      }
    }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  
}
