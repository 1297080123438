import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import html2canvas from 'html2canvas';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { ALERT_TYPE } from 'src/app/shared/enum';
import { TRANSLATION_SCOPES, TranslationOptions, TranslationService } from 'src/app/shared/services/translation.service';
import { fabric } from 'fabric';
import { FileUpload } from 'primeng/fileupload';
import { Object as ObjectCanvas } from 'fabric/fabric-impl';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { OptionButtonType } from 'src/app/shared/type';
import { IConfirmDialog } from 'src/app/shared/interface/common';

interface imageProperty {
  top: number,
  left: number,
  width: number,
  height: number,
  scale: number,
  actualWidth: number,
  actualHeight: number
}

@Component({
  selector: 'app-alert-preview-pdf',
  templateUrl: './intervention-preview-pdf.component.html',
  styleUrls: ['./intervention-preview-pdf.component.scss'],
})
export class AlertPreviewPdfComponent
  extends BaseComponent
  implements AfterViewInit
{
  isLoading: boolean = false;
  isEditMode: boolean = false;

  @Input()
  showPDF: boolean = false;

  @Input()
  pdfSrc: any;

  @Input()
  fileName = 'New Intervention.pdf';

  @Input()
  previewPDFPayload: any;

  @Input()
  alertId = '';

  @Input()
  isCreateResolve: boolean = false;

  @Input()
  isResolvedOnTime: boolean = false;

  @Input()
  mailboxId: string = ''; // for selecton mailbox

  @Input()
  disableSendfPdf: boolean = true;

  @Output()
  reportPDF: EventEmitter<any> = new EventEmitter<any>();

  
  @Output()
  saveEditorPDF: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  hidePreviewPdf: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  triggerSaveAlert: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pdfViewerRender') pdfViewerRender!: PdfJsViewerComponent;
  @ViewChild('uploadedFiles') uploadedFiles!: FileUpload;
  @ViewChild('canvasContainer') canvasContainer: ElementRef;
  @ViewChild('canvasThumbnail') canvasContainerThumbnail: ElementRef;
  @ViewChild('roadmapClone') roadmapClone!: ElementRef;

  // UPDATE for keep Intervention Log Filter
  @Input()
  rigJournalId: string = '';

  @Input()
  rigName: string = '';

  
  isViewer: boolean = false;
  isEngineer: boolean = false
  
  @Input()
  currentUser: any;
  
  @Input()
  fileAlertDataEditor: File | null = null;

  @Input()
  isUseEditor: boolean = false;

  @Output()
  updateFlagUseEditor: EventEmitter<boolean> = new EventEmitter<boolean>();

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;


  event: string[] = [];
  language: string = 'en';
  translatedData: any = {};  

  canvas: fabric.Canvas;
  canvasThumbnail: fabric.Canvas;

  undoStack: any[] = [];
  redoStack: any[] = [];

  dataObjActive: any = {};

  allowedArea = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  }

  // landscape case. portrait is opposite
  A4_SIZE = {
    width: 595,
    height: 842
  }
  // 119
  // 168

  // measure is point (pt)
  marginallowedArea = 10;
  heightForPageNumber = 20;

  listImage: File[] = [];
  listImageThumbnail: any[] = [];
  indexImageSelected: number = -1;

  coordMouse: {top: number, left: number} = {top: 0, left: 0};

  listCanvas: any[] = [];
  listCanvasThumbnail: any[] = [];
  indexCanvasSelected: number = 0;

  templateForDesign: HTMLCanvasElement | any;
  templateRoot: HTMLCanvasElement | any;

  headerInfo: HTMLCanvasElement | any;
  isRoadmap: boolean = false;


  constructor(
    private _notificationService: NotificationService,
    private _translationService: TranslationService,
    private _cd: ChangeDetectorRef,
    private _confirmService: ConfirmDialogService,
    private renderer: Renderer2,
  ) {
    super();
  }

  onInit(): void{    
    this.event = this.previewPDFPayload?.alertEvent?.split(' | ');
    this.language = this.previewPDFPayload?.language || this.language;
    this.translatedData = {
      dataFields: {
        virtualRemoteOperations: this.translateDataField("VIRTUAL REMOTE OPERATIONS"),
        region: this.translateDataField("Region"),
        rig: this.translateDataField("Rig"),
        country: this.translateDataField("Country"),
        spudDate: this.translateDataField("Spud Date"),
        operator: this.translateDataField("Operator"),
        well: this.translateDataField("Well"),
        project: this.translateDataField("Project"),
        wellbore: this.translateDataField("Wellbore"),
        block: this.translateDataField("Block"),
        uwi: this.translateDataField("UWI"),
        lease: this.translateDataField("Lease"),
        remoteCenter: this.translateDataField("Remote Center"),
        field: this.translateDataField("Field"),      
        resolution: this.translateDataField("RESOLUTION"),
        intervention: this.translateDataField("INTERVENTION"),
        report: this.translateDataField("REPORT"),
        timeSaved: this.translateDataField("Time Saved"),
        contactMethod: this.translateDataField("Contact Method"),
        recommendationFollowed: this.translateDataField("Recommendation Followed"),
        resolutionDetail: this.translateDataField("Details"),
        currentOperation: this.translateDataField("Current Operation"),
        interval: this.translateDataField("Interval"),
        run: this.translateDataField("Run"),
        depth: this.translateDataField("Depth"),
        risk: this.translateDataField("Risk"),
        description: this.translateDataField("Description"),
        recommendation: this.translateDataField("Recommendation"),
        timestamp: this.translateDataField('TIMESTAMP'),
        engineerName: this.translateDataField("ENGINEER'S NAME"),
        engineerDiscipline: this.translateDataField("ENGINEER'S DISCIPLINE"),
        runNo: this.translateDataField("RUN NO."),
        risks: this.translateDataField("RISKS"),
        recommendations: this.translateDataField("RECOMMENDATIONS"),
        event: this.translateDataField("EVENT"),
      },
      data: {
        risk: this.previewPDFPayload.risk,
        currentOperation: this.translateDataField(this.previewPDFPayload.currentOperation, { scope: TRANSLATION_SCOPES.CURRENT_OPERATION }),
        event: this.previewPDFPayload?.alertEvent?.split(' | ')?.map((e: string) => this.translateDataField(e?.trim(), { scope : TRANSLATION_SCOPES.EVENTS })),
        depth: this.previewPDFPayload?.depth
          ?.replace('string', this._translationService.translate('string', this.language, { scope: TRANSLATION_SCOPES.DATA_FIELDS }))
          ?.replace('hole', this._translationService.translate('hole', this.language, { scope: TRANSLATION_SCOPES.DATA_FIELDS }))
      }
    }
    
    switch (this.currentUser.role) {
      case AppConstant.ROLES.ADMIN.label:
      case AppConstant.ROLES.MANAGER.label:
        this.isViewer = false;
        this.isEngineer = false;
        break;
      case AppConstant.ROLES.VIEWER.label:
        this.isViewer = true;
        this.isEngineer = false;
        break;
      default:
        this.isViewer = false;
        this.isEngineer = true;
        break;
    }
    
    if (this.previewPDFPayload?.template === ALERT_TYPE.ROADMAP) {
      this.isRoadmap = true;
      this.A4_SIZE = {
        width: 842,
        height: 595,
      }
    } else {
      this.isRoadmap = false;
      this.A4_SIZE = {
        width: 595,
        height: 842,
      }
    }
    this._cd.detectChanges();
    const template_roadmap = document.getElementById('make-pdf-roadmap');
    if (this.isRoadmap && template_roadmap) {
      const childNodes = template_roadmap.childNodes[0];
      const totalHeight = (childNodes.childNodes[0] as HTMLElement).clientHeight + 
                (childNodes.childNodes[1] as HTMLElement).clientHeight + 
                (childNodes.childNodes[3] as HTMLElement).clientHeight + 
                (childNodes.childNodes[4] as HTMLElement).clientHeight;
      // Calculate height for evidence section (ES). 1195px is wrapper, 20px for margin ES
      const res = 1195 - (totalHeight + 20);
      // maxHeight 475px is value default of ES, if res is lower => set new data
      if (res < 475)
        childNodes.childNodes[2].childNodes.forEach((child: any) => {
          if (child?.tagName == 'IMG')
            child.style.maxHeight = `${res}px`;
        })

      const cloneNode = template_roadmap?.cloneNode(true);
      this.renderer.appendChild(this.roadmapClone.nativeElement, cloneNode);
    }
  }

  ngAfterViewInit(): void {
    this.generatePDF(this.isCreateResolve);
  }
  
  translateDataField(text: string, opts: TranslationOptions = { scope: TRANSLATION_SCOPES.DATA_FIELDS }) {
    return this._translationService.translate(text, this.language, opts)
  }

  // translateDataField(text: string) {    
  //   return this._translationService.translate(text, this.language, { scope: TRANSLATION_SCOPES.DATA_FIELDS })    
  // }

  ngOnChanges(changes: SimpleChanges) {    
    if (changes.pdfSrc && !changes.pdfSrc.firstChange) {
      this.pdfViewerRender.pdfSrc = changes.pdfSrc.currentValue;
      this.pdfViewerRender.downloadFileName = this.fileName;
      this.pdfViewerRender.refresh();
    }
  }


  // ================================================================================================
  // ================================================================================================
  // ================================================================================================

  
  async initCanvas() {
    this.canvas = new fabric.Canvas(this.canvasContainer.nativeElement);
    this.canvas.setWidth(this.A4_SIZE.width);
    this.canvas.setHeight(this.A4_SIZE.height);

    this.prepareBackground();
    this.saveState();

    this.canvas.on("object:moving", (options: fabric.IEvent<MouseEvent>) => {
      if (options.target)
        this.checkObjMoving(options.target);
    });

    this.canvas.on("object:scaling", (options: fabric.IEvent<MouseEvent>) => {
      if (options.target)
        this.checkObjMoving(options.target);
    });

    this.canvas.on("object:modified", () => {
      this.saveState();
    });

    this.canvas.on("drop", (e: any) => {
      this.coordMouse = {
        top: e.e.offsetY,
        left: e.e.offsetX,
      };
      this.selectImage(this.indexImageSelected, true);
    });

    this.canvas.on("selection:created", (event: fabric.IEvent<MouseEvent>) => {
      let selectedObjects = event?.selected as fabric.Object[] | undefined;      

      if (selectedObjects?.[0]) {
        this.canvas.setActiveObject(selectedObjects[0]);
        this.dataObjActive = {
          name: selectedObjects[0]?.name,
          top: selectedObjects[0].top,
          left: selectedObjects[0].left,
          scale: selectedObjects[0].scaleX,
        };
      }
    });

    this.canvas.on("mouse:up", () => {
      this.saveCurentCanvas();
    });

    this.saveCurentCanvas();
    this._cd.detectChanges();
  }
  
  @HostListener('document:keydown', ['$event']) onKeyDown(event: any) {
    // const value = e.target.value;
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the component
    if (event.keyCode == 46) {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && !targetElement.closest('#canvas-editor')) {
        this.canvas.remove(activeObject);
      }
    }
  }

  prepareBackground() {
    if (this.indexCanvasSelected == 0) {
      var dataUrlHeader = this.templateForDesign.toDataURL();
    } else {
      var dataUrlHeader = this.headerInfo.toDataURL();
    }
    
    fabric.Image.fromURL(dataUrlHeader, (img: fabric.Image | any) => {
      const ratio = this.A4_SIZE.width / (img.width || this.A4_SIZE.width);
      const headerHeight = img.height * ratio;

      if (this.isRoadmap) {
        this.allowedArea = {
          top: 160,
          left: 10,
          width: this.A4_SIZE.width - 20,
          height: this.allowedArea.height,
        }
      } else {
        this.allowedArea = {
          top: headerHeight + this.marginallowedArea,
          left: this.marginallowedArea,
          width: this.A4_SIZE.width - this.marginallowedArea * 2,
          height: this.A4_SIZE.height - (headerHeight + this.marginallowedArea * 2) - this.heightForPageNumber,
        }
      }
  
      img.set({
        left: 0,
        top: 0,
        scaleX: ratio,
        scaleY: ratio,
        selectable: false,
        hoverCursor: 'default',
        moveCursor: 'default'
      });
      const boundaryRect = new fabric.Rect({
        top: this.allowedArea.top,
        left: this.allowedArea.left,
        width: this.allowedArea.width,
        height: this.allowedArea.height,
        fill: 'transparent',
        stroke: 'blue',
        strokeWidth: 2,
        strokeDashArray: [5, 3],
        strokeLineCap: 'square',
        selectable: false,
        hoverCursor: 'default',
        moveCursor: 'default'
      });
      boundaryRect.set('name', 'base');
      this.canvas.add(boundaryRect);
      boundaryRect.sendToBack();

      // Add image to Fabric.js canvas
      img.set('name', 'base');
      this.canvas.add(img);
      img.sendToBack();

      this.canvas.setBackgroundColor('#FFFFFF', () => {});
      this.canvas.renderAll();

      this.saveCurentCanvas();
    });
  }
  
  checkObjMoving(object: fabric.Object | any) {
    let scale: number = object.scaleX;
    const actualWidth = object?.width * scale;
    const actualHeight = object?.height * scale;

    if (object?.left < this.allowedArea.left || 
        object?.top < this.allowedArea.top || 
        object?.left + actualWidth > this.allowedArea.left + this.allowedArea.width || 
        object?.top  + actualHeight > this.allowedArea.top + this.allowedArea.height ) {

        if (object?.name == this.dataObjActive?.name) {
          this.setPosition(object, this.dataObjActive);
        } else {
          const newPosition: imageProperty = this.calcPositionCenterWhiteSpace(object);
          this.setPosition(object, newPosition);
        }

    } else {
      this.dataObjActive = {
        name: object?.name,
        top: object.top,
        left: object.left,
        scale: object.scaleX,
      };
    }
  }

  /**
   * Calculate Position for image into Center WhiteSpace area
   * @param object 
   * @returns 
   */
  calcPositionCenterWhiteSpace(object: fabric.Object | any): imageProperty {
    const RATIO = 0.5;
    let scale: number = object.scaleX;
    let actualWidth = object?.width * scale;
    let actualHeight = object?.height * scale;

    // calculate scale number for image
    if (actualHeight >= this.allowedArea.height || actualWidth >= this.allowedArea.width) {
      const scaleX = (actualWidth / this.allowedArea.width);
      const scaleY = (actualHeight / this.allowedArea.height);
      if (scaleX >= 1 && scaleX > scaleY )
        scale = (1 / scaleX) * RATIO;
      
      if (scaleY >= 1 && scaleY > scaleX )
        scale = (1 / scaleY) * RATIO;
    }

    actualWidth = object?.width * scale;
    actualHeight = object?.height * scale;
    
    const newPosition = {
      top: this.allowedArea.top + (this.allowedArea.height - actualHeight) / 2,
      left: (this.allowedArea.width - actualWidth) / 2,
      width: object?.width,
      height: object?.height,
      scale: scale,
      actualWidth,
      actualHeight
    };

    return newPosition;
  }

  setPosition(object: fabric.Object, newPosition: {top: number, left: number, scale: number}): fabric.Object {    
    object.set('top', newPosition.top);
    object.set('left', newPosition.left);
    object.set('scaleX', newPosition.scale);
    object.set('scaleY', newPosition.scale);

    return object
  }

  addPage() {
    if (this.isRoadmap) return;
    if (this.listCanvas.length >= this.listImage.length + 1) return;

    this.saveCurentCanvas();
    this.indexCanvasSelected = this.listCanvas.length;
    this.canvas.clear();
    this.resetState();
    this.prepareBackground();
  }

  deletePage() {
    if (this.listCanvas.length >= 2) {
      this.listCanvas.splice(this.indexCanvasSelected, 1);
      this.listCanvasThumbnail.splice(this.indexCanvasSelected, 1);

      if (this.indexCanvasSelected > this.listCanvas.length - 1) {
        this.indexCanvasSelected -= 1;
      }
      this.resetState();
      this.loadCanvas(this.listCanvas[this.indexCanvasSelected]);
    }
  }

  selectPage(index: number) {
    this.saveCurentCanvas();
    this.indexCanvasSelected = index;
    this.canvas.clear();
    this.resetState();
    this.loadCanvas(this.listCanvas[index]);
  }

  saveCurentCanvas() {
    this.canvas.renderAll();
    const canvasData = this.saveDataCanvas();
    const thumbnail = this.canvasToThumbnail();
    this.listCanvas[this.indexCanvasSelected] = canvasData;
    this.listCanvasThumbnail[ this.indexCanvasSelected]= thumbnail;
  }

  loadCanvas(jsonData: any) {
    this.canvas.loadFromJSON(jsonData, () => {
      this.loadObject();
      this.canvas.renderAll();
    })
  }
  
  resetState() {
    this.redoStack = [];
    this.undoStack = [];
  }

  refreshCanvas() {
    this.resetState();
    this.canvas.getObjects().forEach((obj: fabric.Object) => {
      if (obj?.name != 'base')
        this.canvas.remove(obj);
    });
  }

  deleteObjActive() {
    const obj = this.canvas.getActiveObject();
    if (obj)
      this.canvas.remove(obj);
  }

  bringForward() {
    const obj = this.canvas.getActiveObject();
    if (obj)
      this.canvas.bringForward(obj);
  }

  sendToBack() {
    const obj = this.canvas.getActiveObject();
    
    if (obj) {
      this.canvas.sendBackwards(obj);
      const listObj = this.canvas.getObjects();
      const idx = listObj.findIndex((o: any) => o.name == obj?.name);

      if (idx > -1 && idx < 2) {
        for (let i = 0; i < 2-idx; i++)
          this.canvas.bringForward(obj);
      }
    }
  }
  
  saveState() {
    let state = this.saveDataCanvas();

    if (this.undoStack.length > 50)
      this.undoStack = this.undoStack.slice(1);

    this.undoStack.push(state);
    this.redoStack = []; // Clear redo stack whenever a new state is saved
  }

  saveDataCanvas() {
    const propertiesToInclude = ['name', 'selectable', '_controlsVisibility', 'lockRotation', 'hasRotatingPoint', 'cornerSize', 'cornerStyle'];
    const data = this.canvas.toJSON(propertiesToInclude);

    return data;
  }

  
  createThumbnail(sourceCanvas: HTMLCanvasElement | HTMLImageElement, width = 118, height = 168) {
    const newCanvas = document.createElement('canvas');
    newCanvas.width = width;
    newCanvas.height = height;
    
    // Get the 2D context of canvas
    const thumbnailCtx = newCanvas.getContext('2d');
    thumbnailCtx?.drawImage(sourceCanvas, 0, 0, width, height);
    const thumbnailDataURL = newCanvas.toDataURL('image/png');

    newCanvas.parentNode?.removeChild(newCanvas);
    return thumbnailDataURL;
  }

  canvasToThumbnail(): any {
    const canvas = this.canvasContainer.nativeElement;

    if (canvas) {
      const dataUrl = this.createThumbnail(canvas);
      return dataUrl;
    }
    return null;
  }

  imageToThumbnail(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const image = new Image();
      image.onload = () => {
        const width = image.height;
        const ratio = 200/width;
        const dataUrl = this.createThumbnail(image, image.width * ratio, image.height * ratio);
        resolve(dataUrl);

      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = event?.target?.result as string;
    };
    reader.readAsDataURL(file);
    });
  }

  // Function to undo the last action
  undo() {
    if (this.undoStack.length > 1) { // Ensure there's at least one state to undo
      this.redoStack.push(this.undoStack.pop()); // Move the current state to redo stack
      var prevState = this.undoStack[this.undoStack.length - 1];
      this.loadCanvas(prevState);
    }
  }

  // Function to redo the last undone action
  redo() {
    if (this.redoStack.length > 0) {
      var nextState = this.redoStack.pop();
      this.undoStack.push(nextState);
      this.loadCanvas(nextState);
    }
  }

  loadObject() {
    this.canvas.getObjects().forEach((obj: fabric.Object) => {
      if (obj?.name == 'base')
        this.canvas.remove(obj);
    });
    this.prepareBackground();
  }

  mouseDownImage(index: number) {
    this.indexImageSelected = index;
  }

  async selectImage(index: number, isDrop: boolean = false) {
    // todo
    if (this.allowedArea.height < 250) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Alert Editor',
        content: 'Space is too small, please import into another page',
      });
      return;
    }

    const file = this.listImage[index] as File;
    if (!file) return;
    this.indexImageSelected = -1;
    const base64String = await this.fileToBase64(file);

    fabric.Image.fromURL(base64String, (image: fabric.Image | any) => {
      image.set({
        left: 50,
        top: 50,
        scaleX: 1,
        scaleY: 1,
        cornerSize: 10,
        cornerStyle: 'circle',
        lockRotation: true,
        hasRotatingPoint: false,
        hasBorders: true
      });
      image.set('name', file.name);

      // get properties for Image.
      let newPosition: imageProperty = this.calcPositionCenterWhiteSpace(image);

      if (isDrop)
        newPosition = this.calculateImageAtDropArea(newPosition);
      this.setPosition(image, newPosition);

      this.disableControlVisibility(image);
      this.canvas.add(image);
      this.canvas.setActiveObject(image);
      this.saveCurentCanvas();
    });
  }

  calculateImageAtDropArea(imageProperty: imageProperty): imageProperty {
    const {top, left} = this.coordMouse;
    const {actualHeight, actualWidth, scale, height, width } = imageProperty;
    const root = {
      top: top - actualHeight/2 ,
      left: left - actualWidth/2,
      width: actualWidth,
      height: actualHeight
    };

    // check Top Left corner of image
    if (root.left < this.allowedArea.left) {
      root.left = this.allowedArea.left;
    } 
    if (root.top < this.allowedArea.top) {
      root.top = this.allowedArea.top;
    }
    // check Top Right corner of image
    if (root.left + actualWidth > this.allowedArea.left + this.allowedArea.width) {
      root.left = this.allowedArea.width - actualWidth;
    } 
    if (root.top  + actualHeight > this.allowedArea.top + this.allowedArea.height) {
      root.top = this.allowedArea.top + this.allowedArea.height - actualHeight;
    }

    return {
      top: root.top,
      left: root.left,
      actualHeight,
      actualWidth,
      width,
      height,
      scale
    }
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  }

  disableControlVisibility(obj: ObjectCanvas) {
    obj.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      mtr: false
    });
    obj.lockRotation = false;
  }

  exportToFile() {
    this.saveCurentCanvas();
    const dataExport = {
      data: this.listCanvas.map((canvas: any) => {
              return { objects: canvas.objects }
            }),
      thumbnails: this.listCanvasThumbnail
    }

    const myBlob = new Blob([JSON.stringify(dataExport)], {
      type: 'application/json',
    });
    const file = new File([myBlob], 'description.json', { type: 'application/json' });

    return file;
  }

  onSelectFile(event: any) {
    const file: File = event.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent: any = JSON.parse(e.target.result);
      const dataCanvas = this.removeImgNotExist(fileContent.data);
      
      this.listCanvasThumbnail = fileContent.thumbnails;
      this.listCanvas = this.preCalculate(dataCanvas, this.listCanvasThumbnail, this.isRoadmap);
      this.indexCanvasSelected = 0;

      this.canvas.loadFromJSON(this.listCanvas[0], () => {
        this.loadObject();
        this.saveEditorPDF.emit({
          template: this.templateForDesign,
          headerInfo:this.headerInfo,
          listCanvas: this.listCanvas,
        });

        this.undoStack = [];
        this.redoStack = [];
      });
    }
    reader.readAsText(file);
    this.uploadedFiles?.clear();

  }

  makePageThumbailOnLoad(listCanvas: any[], index: number) {
    const data = this.headerInfo.toDataURL();
    listCanvas[index].objects[0].src = data;

    this.canvasThumbnail = new fabric.Canvas(this.canvasContainerThumbnail.nativeElement);
    this.canvasThumbnail.setWidth(this.A4_SIZE.width);
    this.canvasThumbnail.setHeight(this.A4_SIZE.height);

    this.canvasThumbnail.loadFromJSON(listCanvas[index], () => {
      this.canvasThumbnail.setBackgroundColor('#FFFFFF', () => {});
      this.canvasThumbnail.renderAll();

      const canvas = this.canvasContainerThumbnail.nativeElement;
      if (canvas) {
        const dataUrl = this.createThumbnail(canvas);
        this.listCanvasThumbnail[index] = dataUrl;
      }
      this.canvasThumbnail.dispose();
    })
  }

  removeImgNotExist(fileContent: any[]): any[] {
    return fileContent.map((canvas:any) => {
      for (let i = 0; i < canvas?.objects?.length; i++) {
        const object = canvas?.objects[i];
        const condition = (img: any) => img.name == object?.name;
        const result = this.listImage.findIndex(condition) != -1 || object.name == 'base';
        if (!result) {
          canvas.objects.splice(i-- , 1);
        }
      }

      return canvas;
    })
  }

  preCalculate(dataCanvas: any[], listCanvasThumbnail: any[], isRoadmap: boolean): any[] {
    if (isRoadmap) return dataCanvas;

    const firstCanvas = dataCanvas[0].objects;
    const ratio = this.A4_SIZE.width / (this.templateRoot.width || this.A4_SIZE.width);
    const headerHeight = this.templateRoot.height * ratio;

    const newBounding = {
      top: headerHeight + this.marginallowedArea,
      left: this.marginallowedArea,
      width: this.A4_SIZE.width - this.marginallowedArea * 2,
      height: this.A4_SIZE.height - (headerHeight + this.marginallowedArea * 2) - this.heightForPageNumber,
    }
    const oldBounding = firstCanvas.find((obj:any) => obj.type == 'rect');
    const isHaveImg = (firstCanvas || []).reduce(
      (acc: number, cur: any) => {
        if (cur.type == 'image' && cur.name != 'base')
          acc++;
        return acc;
      }, 0) > 0;

    if (!oldBounding || !isHaveImg) return dataCanvas;
      
    if (newBounding.height < 250) {
      // clone all image page 1st to next page
      dataCanvas.splice(1, 0, {objects: firstCanvas});
      listCanvasThumbnail.splice(1, 0, listCanvasThumbnail[0]);
      // remove all image page 1st
      dataCanvas[0].objects = [];
      
      const headerInfoHeight = this.headerInfo.height * ratio;
      dataCanvas[1].objects.forEach((obj: any) => {
        if (obj.type == 'image' && obj.name != 'base') {
          obj.top = this.marginallowedArea * 2 + headerInfoHeight + (obj.top - oldBounding.top);
        }
      })
      this.makePageThumbailOnLoad(dataCanvas, 1);
    } else {
      if (newBounding.height < oldBounding.height) {
        // scale old image
        const newRatioImg = newBounding.height / oldBounding.height;
        firstCanvas.forEach((obj: any) => {
          if (obj.type == 'image' && obj.name != 'base') {
            obj.scaleX = obj.scaleX * newRatioImg;
            obj.scaleY = obj.scaleY * newRatioImg;
            obj.top = newBounding.top + ((obj.top - oldBounding.top) * newRatioImg);
          }
        })
      };
    }

    return dataCanvas;
  }

  saveEditor() {
    this.saveCurentCanvas();
    this.switchMode(); 
    this.saveEditorPDF.emit({
      template: this.templateForDesign,
      headerInfo:this.headerInfo,
      listCanvas: this.listCanvas,
    });
    this.updateFlagUseEditor.emit(true);
  }

  resetDefault() {
    this.reportPDF.emit([[this.templateRoot, this.headerInfo || null], this.isCreateResolve]);
    this.updateFlagUseEditor.emit(false);
  }



  // ================================================================================================
  // ================================================================================================
  // ================================================================================================






  async getTemplate(): Promise<any> {
    const template_default = document.getElementById('make-pdf-default');
    const template_roadmap = document.getElementById('make-pdf-roadmap');
    const template_roadmap2 = document.getElementById('make-pdf-roadmap-clone');

    const headerInfo = document.getElementById('alert-info');
    const options = { useCORS: true };

    if (this.isRoadmap && template_roadmap && template_roadmap2) {      
        // Scale the template to be compatible with an A4 landscape model
        // 595 / 1240 = 0.4798387096774194 
        const RATIO = 0.48;
        const sectionImage = template_roadmap2.getElementsByClassName('wrapper-evidence');
        for (let i = 0; i < sectionImage.length; i++) {
          let element = sectionImage[i];
          element.childNodes.forEach((child: any) => {
            if (child?.tagName == 'IMG')
              child.setAttribute('class', 'image-evidence visibility-hidden');
          })
          const bounding = element.getBoundingClientRect();
          this.allowedArea.height = bounding.height * RATIO;
        }
        const [template, templateForDesign] = await Promise.all([
          html2canvas(template_roadmap, options),
          html2canvas(template_roadmap2.firstChild as HTMLElement, options),
        ])

        this.templateRoot = template;
        this.templateForDesign = templateForDesign;

        this.initCanvas();
        return [template, null];
    } else if (template_default && headerInfo) {
      const [template, header] = await Promise.all([
        html2canvas(template_default, options),
        html2canvas(headerInfo, options)
      ])

      this.templateRoot = template;
      this.templateForDesign = template;
      this.headerInfo = header;

      this.initCanvas();
      return [template, header];
    }
    return [];
  }

  async generatePDF(isCreateResolve: boolean) {
    // Make image thumbnails
    for (const file of this.previewPDFPayload?.imageEvidences) {
      const thumbnail = await this.imageToThumbnail(file);
      this.listImage = this.listImage.concat(file);
      this.listImageThumbnail = this.listImageThumbnail.concat(thumbnail);
    }

    const reportPDFPromise = await this.getTemplate();

    if (this.isUseEditor && this.fileAlertDataEditor) {
      this.onSelectFile({files: [this.fileAlertDataEditor]});
    } else {
      this.reportPDF.emit([reportPDFPromise, isCreateResolve]);
    }
  }

  removeTheDraft() {
    this.hidePreviewPdf.emit(false);

    if (!this.fileAlertDataEditor)
      this.updateFlagUseEditor.emit(false);
  }

  switchMode() {
    this.isEditMode = !this.isEditMode;
  }

  SaveAlert() {
    if (this.isViewer) return;

    this.fileAlertDataEditor = this.exportToFile();
    this.triggerSaveAlert.emit({
      isSend: false,
      alertEditorData: this.isUseEditor ? this.fileAlertDataEditor : null
    });
  }

  SaveSendAlert() {
    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header: 'Save Alert',
      haveDialogMessage: true,
      dialogMessage: 'Do you want to Save & Send this alert?',
      havePrimaryButton: true,
      primaryButtonLabel: 'Save & Send',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: true,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event),
    });
  }
  
  onButtonClickDialog(option: OptionButtonType ) {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        this.fileAlertDataEditor = this.exportToFile();
        this.triggerSaveAlert.emit({
          isSend: true,
          alertEditorData: this.isUseEditor ? this.fileAlertDataEditor : null
        });

        this._confirmService.setDialog({
          ...this.confirmDialog,
          isVisible: false,
        });
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
      default:
        this._confirmService.setDialog({
          ...this.confirmDialog,
          isVisible: false,
        });
        break;
    }
  }

  onDestroy(): void {}
}
