<div *ngIf="filtersLoaded | async">
  <p-dialog
    [(visible)]="displayFormMailBoxDialog"
    [modal]="true"
    [style]="{ width: '480px', 'min-height': '320px' }"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="false"
    maskStyleClass="maskStyleDialog"
    styleClass="style-class-dialog"
    [closable]="false"
  >
    <ng-template pTemplate="header">
      <div
        style="
          margin-left: 7px;
          margin-top: 3px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        "
        class="cursor-pointer rigName"
      >
        <span [pTooltip]="formMailBoxTitle || ''" tooltipPosition="bottom">
          {{ formMailBoxTitle || "" }}
        </span>
      </div>
      <div class="custom-close-icon cursor-pointer" (click)="removeTheDraft()">
        <i class="pi pi-times"></i>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div
        class="container edit-form add-input"
        *ngIf="!!formMailBoxGroup"
        [formGroup]="formMailBoxGroup"
      >
        <div class="row">
          <div class="col-12">
            <div
              class="add-input-title"
              [ngClass]="
                formMailBoxGroup.get('functionalMailbox')!.disabled
                  ? 'label-disabled'
                  : ''
              "
            >
              Functional Mailbox
              <span
                [ngClass]="
                  AppHelper.UtileFunctions.isRequired(
                    formMailBoxGroup,
                    'functionalMailbox'
                  )
                    ? 'p-error'
                    : 'd-none'
                "
                >*</span
              >
            </div>

            <div class="w-100 add-input p-inputgroup non-icon">
              <p-dropdown
                [options]="functionalMailboxes"
                [filter]="true"
                optionLabel="mailboxName"
                [showClear]="true"
                placeholder="Select Functional Mailbox"
                class="custom-p-drop"
                scrollHeight="160px"
                [formControlName]="fieldNameKeys.functionalMailbox"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="col-12">
            <div
              class="add-input-title"
              [ngClass]="
                formMailBoxGroup.get('remoteCenter')!.disabled
                  ? 'label-disabled'
                  : ''
              "
            >
              Remote Center
              <span
                [ngClass]="
                  AppHelper.UtileFunctions.isRequired(
                    formMailBoxGroup,
                    'remoteCenter'
                  )
                    ? 'p-error'
                    : 'd-none'
                "
                >*</span
              >
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-dropdown
                [options]="remoteCenters"
                [filter]="true"
                optionLabel="remoteCenterName"
                [showClear]="true"
                placeholder="Select Remote Center"
                class="custom-p-drop"
                scrollHeight="160px"
                [formControlName]="fieldNameKeys.remoteCenter"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
                (onChange)="selectItem($event, 'remoteCenter')"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="col-12">
            <div
              class="add-input-title"
              [ngClass]="
                formMailBoxGroup.get('discipline')!.disabled
                  ? 'label-disabled'
                  : ''
              "
            >
              Discipline
              <span
                [ngClass]="
                  AppHelper.UtileFunctions.isRequired(
                    formMailBoxGroup,
                    'discipline'
                  )
                    ? 'p-error'
                    : 'd-none'
                "
                >*</span
              >
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-multiSelect
                [options]="disciplines"
                [formControlName]="fieldNameKeys.discipline"
                [showHeader]="false"
                [showClear]="true"
                [filter]="true"
                optionLabel="disciplineName"
                placeholder="Select Discipline(s)"
                class="custom-p-multiselect"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
                [disabled]="formMailBoxGroup.get('discipline')!.disabled"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div *ngIf="!!formMailBoxGroup" class="d-flex justify-content-end gap-2">
        <p-button
          [attr.data-cy]="'cancel-button'"
          label="Cancel"
          (onClick)="removeTheDraft()"
          class="custom-p-button custom-p-button--primary"
        ></p-button>
        <p-button
          [attr.data-cy]="!mailBoxEdit ? 'add-button' : 'save-button'"
          [label]="!mailBoxEdit ? 'Add' : 'Save'"
          (onClick)="submitMailBox()"
          class="custom-p-button custom-p-button--secondary"
          [ngClass]="
            formMailBoxGroup.status === 'INVALID' || isViewer || formMailBoxGroup.pristine
              ? 'custom-p-button custom-p-button--disable'
              : ''
          "
          [disabled]="formMailBoxGroup.status === 'INVALID' || isViewer || formMailBoxGroup.pristine"
        ></p-button>
      </div>
    </ng-template>
    <!-- Loading -->
    <app-loading [isLoading]="isLoading"></app-loading>
  </p-dialog>
</div>
