<div class="project">
  <div class="header-content">
    <div class="header-content__title d-flex">
      <span class="me-1">My Projects</span>
      <ng-container *ngIf="!hideItemFeatures">
        <div class="more-option-title">
          <svg-icon class="header-content__title--feature" key="dot3hoz" size="md"
            (click)="menuAdding.toggle($event)"></svg-icon>
        </div>

        <p-menu 
          #menuAdding [popup]="true" 
          (onHide)="AppHelper.UtileFunctions.hideOptionMenu(menuAdding)"
          (onShow)="AppHelper.UtileFunctions.showOptionMenu(menuAdding)" 
          [model]="itemFeatures"
          >
        </p-menu>
      </ng-container>
    </div>
  </div>


  <div class="project__filter">
    <div class="project__filter--input">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input 
          #searchInput 
          type="text" 
          pInputText
          placeholder="Search by project, rig, well" 
          class="search-box"
          maxlength="50" 
          name="searchString" 
          (keyup)="onSearchChange($event)" />
      </span>
    </div>

    <div class="project__filter-view">
      <p-button (click)="rigCardMode('card')" class="project__filter-view--left"
        [ngClass]="showMode === 'card' ? 'active-filter' : ''" icon="pi pi-th-large" iconPos="left"></p-button>
      <p-button (click)="tableMode('table')" class="project__filter-view--right"
        [ngClass]="showMode === 'table' ? 'active-filter' : ''" icon="pi pi-align-justify" iconPos="left"></p-button>
    </div>

    <div class="project__filter-button project__filter-button--dark">
      <div class="p-d-flex p-d-flex-column">
        <app-home-advanced-filter 
          #advancedFilter 
          [haveFilter]="!!chipsFilter.length"
          [isShowActive]="isShowActive"
          (chipFilter)="loadChipFilter($event)" 
          (payloadFilter)="loadPayloadFilter($event)">
        </app-home-advanced-filter>
      </div>
    </div>
  </div>
  <div class="fitler__option-list">
    <div class="fitler__option-item p-d-flex">
      <div>
        <p>Total: {{ counterResult }}</p>
      </div>

      <div class="chip-wrapper">
        <ng-container class="p-d-flex" *ngFor="let item of chipsFilter; index as i">
          <p-chip label="{{ item }}" styleClass="chip-item" removeIcon="pi pi-times" [removable]="true"
            (onRemove)="removeItemChip(item, i)"></p-chip>
        </ng-container>
      </div>
      <div class="button-highlight">
        <p (click)="clearAllFilter()">Clear all</p>
      </div>
    </div>
    <div class="fitler__option-counter"></div>
  </div>

  <!-- Decide showing Mode -->
  <div class="project__result-wrap">
    <div class="project__result" *ngIf="searchResults$.length">
      <ng-container *ngIf="showMode === 'card'; then showCard; else showTable">
      </ng-container>
      <ng-template #showCard>
        <div class="project__result-list" *ngFor="let project of searchResults$; let i = index">
          <app-home-project-item 
            [userRole]="userRole" 
            [project]="project[1]" 
            [isSkeleton]="(isSkeleton$ | async)!" 
            [activeRigCard]="activeRigCard"
            (showRequestReloadHomePage)="showRequestReloadHomePage($event)"
            (requestLoadingTheme)="showLoadingTheme($event)"
            (passActiveRigCard)="activeRigCard = $event">
          </app-home-project-item>
        </div>
      </ng-template>

      <ng-template #showTable>
        <app-home-project-item-table 
          [projectListData]="searchResultsTable$"
          (showRequestReloadHomePage)="showRequestReloadHomePage($event)">
        </app-home-project-item-table>
      </ng-template>
    </div>
    <div class="project__result" *ngIf="!searchResults$.length">
      {{ msgFromApis }}
    </div>
  </div>
  <!-- Loading -->
  <app-loading *ngIf="isLoading" [isLoading]="isLoading" [isIconShow]="true"></app-loading>
</div>

<!-- Add Wellbore Dialog Component -->
<ng-container *ngIf="showAddWellbore">
  <app-home-add-wellbore 
    [displayAddWellboreDialog]="showAddWellbore"
    (hideAddWellboreDialog)="hideAddWellboreDialog($event)"
    (hideAddWellboreDialogAndReload)="hideAddWellboreDialogAndReload($event)">
  </app-home-add-wellbore>
</ng-container>