<!-- <div *ngIf="filtersLoaded | async"> -->
<p-dialog
  [(visible)]="displayFormRigDialog"
  [modal]="true"
  [style]="{ 'max-width': '480px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <div class="header-dialog">
      <span [pTooltip]="formRigTitle || ''" tooltipPosition="bottom">
        {{ formRigTitle || "" }}
      </span>
    </div>
    <div class="custom-close-icon cursor-pointer" (click)="removeTheDraft()">
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <div class="container edit-form add-input" [formGroup]="formRigGroup">
      <div class="row">
        <div class="col-12 mb-4">
          <div class="add-input-title">
            Rig Name <span style="color: #e5181b">*</span>
          </div>
          <div>
            <p-autoComplete
              placeholder="Enter name"
              [showClear]="true"
              [suggestions]="filteredRigName"
              (completeMethod)="filterRigName($event)"
              (onClear)="clearRigName($event)"
              [showEmptyMessage]="false"
              field="rigName"
              [minLength]="1"
              styleClass="smart-text-input"
              scrollHeight="160px"
              [autoHighlight]="true"
              [maxlength]="50"
              [formControlName]="fieldNameKeys.rigName"
              [innerMsg]="messageErrors"
            >
              <ng-template let-value pTemplate="item">
                <div class="d-flex justify-content-between">
                  <div class="result-text">
                    {{ value.rigName }}
                  </div>
                  <div *ngIf="value.flag === 'new'" class="add-new-text">
                    + {{ rigEdit ? "Edit" : "Add" }} rig name
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <div class="col-12 mb-4">
          <app-multi-level-dropdown
            [sourceData]="rigTypes"
            placeholder="Select type"
            [showClear]="true"
            scrollHeight="200px"
            [selectAllWhenChooseParent]="true"
            [isCustomTemplate]="true"
            [customLabelString]="customLabelString"
            seperateMark="|"
            [isCounterSelected]="false"
            label="Rig Type"
            [isRequired]="true"
            [multiType]="MULTI_LEVEL.SINGLE_SELECT.LOWEST_LEVEL.LABEL"
            [formGroup]="formRigGroup"
            [formControlNamePass]="fieldNameKeys.rigType"
            [messageErrors]="messageErrors"
          >
          </app-multi-level-dropdown>
        </div>

        <div class="col-12 mb-4">
          <div class="add-input-title">
            Rig Contractor <span style="color: #e5181b">*</span>
          </div>
          <div>
            <p-autoComplete
              placeholder="Enter contractor"
              [showClear]="true"
              [suggestions]="filteredDrillingContractor"
              (completeMethod)="filterDrillingContractor($event)"
              (onClear)="clearDrillingContractor($event)"
              [showEmptyMessage]="false"
              field="contractorName"
              [minLength]="1"
              styleClass="smart-text-input"
              scrollHeight="160px"
              [autoHighlight]="true"
              [maxlength]="50"
              [formControlName]="fieldNameKeys.rigConstractor"
              [innerMsg]="messageErrors">
              <ng-template let-value pTemplate="item">
                <div class="d-flex justify-content-between">
                  <div class="result-text">
                    {{ value.contractorName }}
                  </div>
                  <div *ngIf="value.flag === 'new'" class="add-new-text">
                    + {{ rigEdit ? "Edit" : "Add new" }} contractor
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <div class="col-12 mb-4">
          <div class="add-input-title">Rig Image</div>
          <div>
            <div class="custom-single-upload-btn">
              <div class="col-4 d-flex align-items-center">
                <div class="add-input-des">
                  .png, .jpeg, .jpg, aspect ratio 160x104, max 1 MB
                </div>
              </div>
              <div class="col-3 me-1 d-flex align-items-center">
                <div #progressUploadImage style="display: none; width: 100%">
                  <div style="width: 100%">
                    <p-progressBar
                      [value]="valueProgress"
                      [showValue]="false"
                      styleClass="progressUpload"
                    ></p-progressBar>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                [pTooltip]="fileImageLabel"
                tooltipPosition="bottom"
              >
                <p-fileUpload
                  #uploadImage
                  name="rigImage[]"
                  url="./upload.php"
                  [multiple]="false"
                  accept=".png, .jpeg, .jpg"
                  (onSelect)="showCropper($event)"
                  (onClear)="onClear_image($event)"
                  [chooseLabel]="
                    fileImageLabel === 'Browse file'
                      ? fileImageLabel
                      : (fileImageLabel | shortFileName : 6)
                  "
                  chooseIcon="pi pi-upload"
                  cancelLabel=" "
                  cancelStyleClass="cancelStyle"
                  chooseStyleClass="chooseStyle"
                  styleClass="hidePreview"
                  [showUploadButton]="false"
                  [showCancelButton]="showCancelButtonImage"
                ></p-fileUpload>
                <!-- [disabled]="formRigGroup.get('rigImage')?.disabled || false" -->
              </div>
            </div>
          </div>
          <div class="col-12">
            <input
              type="hidden"
              pInputText
              name="rigImage"
              [formControlName]="fieldNameKeys.rigImage"
            />
          </div>
          <div *ngIf="isInvalidControl(fieldNameKeys.rigImage)">
            <ng-container
              *ngFor="let msg of getErrorByField(fieldNameKeys.rigImage)"
            >
              <div class="inner-msg-error">
                <span>{{ msg }}</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div class="add-input-title">Rig Specifications</div>
          <div>
            <div class="custom-single-upload-btn">
              <div class="col-4 d-flex align-items-center">
                <div class="add-input-des">Only .pdf, max 5 MB</div>
              </div>
              <div class="col-3 me-1 d-flex align-items-center">
                <div #progressUploadSpecs style="display: none; width: 100%">
                  <div style="width: 100%">
                    <p-progressBar
                      [value]="valueProgress"
                      [showValue]="false"
                      styleClass="progressUpload"
                    ></p-progressBar>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                [pTooltip]="fileSpecsLabel"
                tooltipPosition="bottom"
              >
                <p-fileUpload
                  #uploadSpecs
                  name="rigSpec[]"
                  url="./upload.php"
                  [multiple]="false"
                  accept=".pdf"
                  (onSelect)="onSelect_specs($event)"
                  (onClear)="onClear_specs($event)"
                  [chooseLabel]="
                    fileSpecsLabel === 'Browse file'
                      ? fileSpecsLabel
                      : (fileSpecsLabel | shortFileName : 6)
                  "
                  chooseIcon="pi pi-upload"
                  cancelLabel=" "
                  cancelStyleClass="cancelStyle"
                  chooseStyleClass="chooseStyle"
                  styleClass="hidePreview"
                  [showUploadButton]="false"
                  [showCancelButton]="showCancelButtonSpecs"
                ></p-fileUpload>
                <!-- [disabled]="formRigGroup.get('rigSpecs')?.disabled || false" -->
              </div>
            </div>
          </div>
          <div class="col-12">
            <input
              type="hidden"
              pInputText
              name="rigSpecs"
              [formControlName]="fieldNameKeys.rigSpecs"
            />
          </div>
          <div *ngIf="isInvalidControl(fieldNameKeys.rigSpecs)">
            <ng-container
              *ngFor="let msg of getErrorByField(fieldNameKeys.rigSpecs)"
            >
              <div class="inner-msg-error">
                <span>{{ msg }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="removeTheDraft()"
        class="custom-p-button custom-p-button--primary"
      ></p-button>
      <p-button
        [attr.data-cy]="!rigEdit ? 'add-button' : 'save-button'"
        [label]="!rigEdit ? 'Add' : 'Save'"
        class="custom-p-button custom-p-button--secondary"
        (onClick)="submitRig()"
        [ngClass]="
          formRigGroup.status === 'INVALID' || isViewer
            ? 'custom-p-button custom-p-button--disable'
            : ''
        "
        [disabled]="formRigGroup.status === 'INVALID' || isViewer"
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>
