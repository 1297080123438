<div class="confirm-dialog">
  <form (ngSubmit)="onSubmit()">
    <p-dialog
      [header]="informDisplay.header"
      [(visible)]="visible"
      [modal]="true"
    >
      <div>
        <div class="d-flex align-items-center mb-2">
          <p-checkbox
            [(ngModel)]="checkedConfirm"
            [binary]="true"
            inputId="binary"
            name="temp"
          ></p-checkbox>
          <label
            for="binary"
            [ngClass]="
              checkedConfirm ? 'enable-checked-label' : 'disable-checked-label'
            "
            >{{ informDisplay.titleCheckbox }}</label
          >
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
          <app-customizable-button 
            label="Cancel" 
            [style]="'dark'"
            [isDisabled]="false" 
            [elementId]="'cancelButton'" 
            (eventButton)="onDestroy.emit()"
          ></app-customizable-button>
          
          <!-- // Style Disable com with style propertirs -->
          <app-customizable-button 
            label="Delete" 
            [style]="'light'"
            [attr.data-cy]="'delete-button'"
            [isDisabled]="!checkedConfirm"
            [elementId]="'confirmButton'" 
            type="submit"
            (eventButton)="onSubmit()"            
          ></app-customizable-button>
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>

<div class="warning-dialog">
  <p-dialog
    [header]="
      informDisplay.type === 'DELETE_CATEGORY_GUIDE'
        ? 'Delete Chapter'
        : 'Delete Channel'
    "
    [(visible)]="visibleWarningdialog"
    [modal]="true"
    (onHide)="onDestroy.emit()"
  >
    <div>
      <p class="selected-chapter">
        {{
          informDisplay.type === "DELETE_CATEGORY_GUIDE"
            ? "You cannot delete a chapter when it contains subchapter."
            : "You cannot delete a channel when it contains video."
        }}
      </p>
    </div>
    <ng-template pTemplate="footer">
      <div class="button-footer">
        <p-button
        type="button"
          [attr.data-cy]="'ok-button'"
          label="Ok"
          class="custom-p-button custom-p-button--secondary"
          (onClick)="visibleWarningdialog = false"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>
</div>
