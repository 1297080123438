<div *ngIf="filtersLoaded | async">
  <p-dialog
    [(visible)]="displayFormUser"
    [modal]="true"
    [style]="{ width: '512px', height: 'auto' }"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="false"
    maskStyleClass="maskStyleDialog"
    styleClass="style-class-dialog"
    [closable]="false"
    [baseZIndex]="0"
  >
    <ng-template pTemplate="header">
      <div *ngIf="userEdit">Edit User: {{ userEdit.displayName }}</div>
      <div *ngIf="!userEdit">Add User</div>

      <div class="custom-close-icon" (click)="closeEditUserDialog()">
        <i class="pi pi-times"></i>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <div
        class="container edit-form add-input"
        *ngIf="!!formUserGroup"
        [formGroup]="formUserGroup"
      >
        <div class="row">
          <div class="col-12 mb-2">
            <div class="add-input-title">
              Halliburton ID<span
                class="p-error"
                *ngIf="!formUserGroup.get('halId')!.disabled"
              >
                *</span
              >
            </div>
            <div>
              <p-autoComplete
                placeholder="Enter Halliburton ID"
                [showClear]="true"
                [showEmptyMessage]="true"
                emptyMessage="Not found user in group"
                field="halId"
                [suggestions]="filteredHallUser"
                (completeMethod)="filterHallUser($event)"
                (onClear)="clearFilteredHallUser($event)"
                [minLength]="1"
                styleClass="smart-text-input"
                scrollHeight="160px"
                [autoHighlight]="true"
                [maxlength]="50"
                [formControlName]="fieldNameKeys.halId"
                [innerMsg]="messageErrors"
              >
                <ng-template let-value pTemplate="item">
                  <div class="d-flex justify-content-between">
                    <div class="result-text">
                      <span class="HalId">
                        {{ value.halId }}
                      </span>
                      <span class="HalName">
                        &nbsp; &nbsp; {{ value.displayName }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>

          <div class="col-6 mb-2" style="padding-right: 12px">
            <div class="add-input-title">
              First Name
              <span
                class="p-error"
                *ngIf="!formUserGroup.get('firstName')!.disabled"
              >
                *</span
              >
            </div>
            <div>
              <div class="add-input p-inputgroup p-input-icon-right">
                <i
                  class="icon pi pi-ellipsis-v cursor-pointer"
                  (click)="menuItems_1.toggle($event)"
                >
                </i>
                <input
                  type="text"
                  pInputText
                  class="custom-p-input"
                  placeholder="Enter First Name"
                  [formControlName]="fieldNameKeys.firstName"
                  [innerMsg]="messageErrors"
                  TextInputValidate
                  typeCheck="short"
                />
                <p-menu
                  #menuItems_1
                  [popup]="true"
                  [model]="menuItems"
                  (onShow)="onMenuShow('firstName')"
                  class="menu-table-item"
                ></p-menu>
              </div>
            </div>
          </div>

          <div class="col-6 mb-2" style="padding-left: 12px">
            <div class="add-input-title">
              Last name
              <span
                class="p-error"
                *ngIf="!formUserGroup.get('lastName')!.disabled"
              >
                *</span
              >
            </div>
            <div>
              <div class="add-input p-inputgroup p-input-icon-right">
                <i
                  class="icon pi pi-ellipsis-v cursor-pointer"
                  (click)="menuItems_2.toggle($event)"
                ></i>
                <input
                  type="text"
                  pInputText
                  class="custom-p-input"
                  placeholder="Enter Last Name"
                  [formControlName]="fieldNameKeys.lastName"
                  [innerMsg]="messageErrors"
                  TextInputValidate
                  typeCheck="short"
                />
                <p-menu
                  #menuItems_2
                  [popup]="true"
                  [model]="menuItems"
                  (onShow)="onMenuShow('lastName')"
                  class="menu-table-item"
                ></p-menu>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="add-input-title d-flex justify-content-between">
              <div
                [ngClass]="
                  formUserGroup.get('role')!.disabled ? 'label-disabled' : ''
                "
              >
                <span>
                  Role
                  <span
                    class="p-error"
                    *ngIf="!formUserGroup.get('role')!.disabled"
                  >
                    *</span
                  >
                </span>
              </div>
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-treeSelect
                [options]="roles"
                class="custom-p-tree"
                scrollHeight="200px"
                placeholder="Select Role"
                [showClear]="true"
                (onClear)="onClearRole($event)"
                (onNodeExpand)="onNodeExpand($event)"
                (onNodeSelect)="onOptionsSelected($event, selectedNode)"
                [formControlName]="fieldNameKeys.role"
                [propagateSelectionDown]="false"
              >
                <ng-template pTemplate="value">
                  {{ selectedUserString || "Select Role" }}
                </ng-template>
              </p-treeSelect>
            </div>

            <div>
              <div *ngIf="isInvalidControl(fieldNameKeys.role)">
                <ng-container
                  *ngFor="let msg of getErrorByField(fieldNameKeys.role)"
                >
                  <div class="inner-msg-error">
                    <span>{{ msg }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="add-input-title">
              PSL
              <span class="p-error" *ngIf="!formUserGroup.get('psl')!.disabled">
                *</span
              >
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-dropdown
                [options]="PSLs"
                [filter]="true"
                optionLabel="name"
                [showClear]="true"
                placeholder="Select PSL"
                class="custom-p-drop"
                scrollHeight="160px"
                [formControlName]="fieldNameKeys.psl"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="add-input-title">
              Country
              <span
                class="p-error"
                *ngIf="!formUserGroup.get('country')!.disabled"
              >
                *</span
              >
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-dropdown
                [options]="countries"
                [filter]="true"
                optionLabel="name"
                [showClear]="true"
                placeholder="Select Country"
                class="custom-p-drop"
                scrollHeight="160px"
                [formControlName]="fieldNameKeys.country"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div
              class="add-input-title d-flex justify-content-between"
              [ngClass]="
                formUserGroup.get('remoteCenter')!.disabled
                  ? 'label-disabled'
                  : ''
              "
            >
              <span>
                Remote Center
                <span
                class="p-error"
                *ngIf="!formUserGroup.get('remoteCenter')!.disabled && !isSelectViewerRole"
                >
                *</span
                >
              </span>

              <span>
                ({{ counterRemoteCenter }}

                {{
                  counterRemoteCenter === 0 || counterRemoteCenter === 1
                    ? "option"
                    : "options"
                }}
                selected)
              </span>
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-multiSelect
                [options]="remoteCenters"
                [formControlName]="fieldNameKeys.remoteCenter"
                [filter]="true"
                optionLabel="remoteCenterName"
                [showClear]="true"
                [showHeader]="false"
                placeholder="Select Remote Center"
                class="custom-p-multiselect"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
                (onChange)="countSelectedValue($event, 'remoteCenter')"
                (onClear)="counterRemoteCenter = 0"
              >
              </p-multiSelect>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="add-input-title">
              Time Zone
              <span
                class="p-error"
                *ngIf="!formUserGroup.get('timezone')!.disabled"
              >
                *</span
              >
            </div>
            <div class="w-100 add-input p-inputgroup non-icon">
              <p-dropdown
                [options]="timezones"
                [filter]="true"
                optionLabel="name"
                [showClear]="true"
                placeholder="Select Time Zone"
                class="custom-p-drop"
                scrollHeight="160px"
                [formControlName]="fieldNameKeys.timezone"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="col-6 mb-2">
            <div class="add-input-title">
              Phone
              <span
                class="p-error"
                *ngIf="!formUserGroup.get('phone')!.disabled"
              >
                *</span
              >
            </div>
            <div>
              <div class="add-input p-inputgroup">
                <div
                  class="form-group form-flag"
                  [ngClass]="
                    isInvalidControl(fieldNameKeys.phone) ? 'border-error' : ''
                  "
                >
                  <p-dropdown
                    [options]="countries"
                    optionLabel="name"
                    class="custom-p-drop"
                    scrollHeight="105px"
                    [filter]="true"
                    filterBy="combine_name_dial"
                    (onFilter)="customFilterChanged($event)"
                    (onChange)="addPrefixNumber($event)"
                    [formControlName]="fieldNameKeys.flag"
                    [innerMsg]="messageErrors"
                    [resetFilterOnHide]="true"
                  >
                    <ng-template let-country pTemplate="selectedItem">
                      <div
                        class="country-item country-item-value"
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #c9cfd4;
                        "
                      >
                        <i
                          class="flag flag-{{
                            formUserGroup
                              .get('flag')
                              ?.value?.code?.toLowerCase() || 'us'
                          }}"
                        ></i>
                      </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                      <div class="country-item">
                        <div>
                          <i
                            class="flag flag-{{
                              country.code.toLowerCase() || 'us'
                            }}"
                          ></i>
                          {{ country.name }}
                          <span style="color: #3b4957">
                            {{ country.replace_dial_code || country.dial_code }}
                          </span>
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <div class="input-box">
                    <input
                      type="hidden"
                      [formControlName]="fieldNameKeys.dialCode"
                    />
                    <span
                      class="prefix"
                      [ngClass]="
                        formUserGroup.get('phone')!.disabled ? 'disabled' : ''
                      "
                      >{{ formUserGroup.get("dialCode")?.value }}</span
                    >
                    <input
                      type="text"
                      NumbersOnly
                      [maxLength]="18"
                      [maxDecimalDigit]="0"
                      [allowDecimals]="false"
                      [allowSign]="false"
                      [isBlurActive]="false"
                      pInputText
                      class="custom-p-input"
                      placeholder="Enter Phone"
                      [formControlName]="fieldNameKeys.phone"
                      (paste)="handlePasteInput('phone', $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div *ngIf="isInvalidControl(fieldNameKeys.phone)">
                <ng-container
                  *ngFor="let msg of getErrorByField(fieldNameKeys.phone)"
                >
                  <div class="inner-msg-error">
                    <span>{{ msg }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="col-6 mb-2" style="padding-left: 12px">
            <div class="add-input-title">
              Email
              <span
                class="p-error"
                *ngIf="!formUserGroup.get('mail')!.disabled"
              >
                *</span
              >
            </div>
            <div>
              <div class="add-input p-inputgroup non-icon">
                <input
                  type="text"
                  pInputText
                  class="custom-p-input"
                  placeholder="Enter Email"
                  (focusout)="onBlurMethod('mail', formUserGroup)"
                  [formControlName]="fieldNameKeys.mail"
                  [innerMsg]="messageErrors"
                />
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div
              class="add-input-title d-flex justify-content-between"
              [ngClass]="
                formUserGroup.get('funtionalMailbox')!.disabled
                  ? 'label-disabled'
                  : ''
              "
            >
              <span>
                Functional Mailbox
                <span
                  class="p-error"
                  *ngIf="!formUserGroup.get('funtionalMailbox')!.disabled"
                >
                  *</span
                >
              </span>

              <span>
                ({{ counterSelectedMailBox }}

                {{
                  counterSelectedMailBox === 0 || counterSelectedMailBox === 1
                    ? "option"
                    : "options"
                }}
                selected)
              </span>
            </div>
            <div>
              <p-multiSelect
                [options]="funtionalMailboxes"
                [formControlName]="fieldNameKeys.funtionalMailbox"
                [showHeader]="false"
                [showClear]="true"
                [filter]="true"
                optionLabel="mailboxName"
                placeholder="Select Functional Mailbox"
                class="custom-p-multiselect"
                (onChange)="countSelectedValue($event, 'funtionalMailbox')"
                (onClear)="counterSelectedMailBox = 0"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 651px" *ngIf="!formUserGroup"></div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div *ngIf="!!formUserGroup" class="d-flex justify-content-end gap-2">
        <p-button
          [attr.data-cy]="'cancel-button'"
          label="Cancel"
          (click)="closeEditUserDialog()"
          class="custom-p-button custom-p-button--primary"
        ></p-button>
        <p-button
          [attr.data-cy]="userEdit ? 'save-button' : 'add-button'"
          [label]="userEdit ? 'Save' : 'Add'"
          (onClick)="submitUser($event)"
          class="custom-p-button custom-p-button--secondary"
          [disabled]="formUserGroup.status === 'INVALID' || isViewer"
          [ngClass]="
            formUserGroup.status === 'INVALID' || isViewer
              ? 'custom-p-button custom-p-button--disable'
              : ''
          "
        ></p-button>
      </div>
    </ng-template>

    <!-- Loading -->
    <app-loading [isLoading]="isLoading"></app-loading>
  </p-dialog>
</div>
