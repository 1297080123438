import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'riskString',
})
export class StringRiskPipe implements PipeTransform {
  /**
   * Transforms the input value into a formatted string.
   * If the input value is empty or undefined, it returns 'N/A'.
   * If the input value can be parsed as JSON and is an array, it extracts the 'label' property from each object and returns them joined by commas.
   * Example:
   * [{"risk_id":"ETSE01","data":"BOP/BOP systems test failure(Rented)","label":"BOP/BOP systems test failure(Rented)"}] will return
   * ===> BOP/BOP systems test failure(Rented)
   * Otherwise, it returns the original value. (Input value can not parsed as JSON, it is a string then returns this string value.)
   *
   * @param value - The input value to be transformed.
   * @returns The transformed string.
   */

  transform(value: any): any {
    if (!value) {
      return 'N/A';
    } else {
      try {
        if (isArray(value)) {
          return value.map((risk: any) => risk.label).join(', ');
        }
        let data = JSON.parse(value);
        if (data.length) {
          let labelValues = data.map((risk: any) => risk.label);
          return labelValues.join(', ');
        } else {
          return value;
        }
      } catch (error) {
        return value;
      }
    }
  }
}
