<p-dialog 
  [(visible)]="showPDF" 
  [modal]="true" 
  [style]="{
    height: '100%',
    width: isEditMode ?  'auto' : '50%',
    'min-width': '75px'
  }" 
  [draggable]="false" 
  [resizable]="false" 
  [closeOnEscape]="false" 
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog" 
  [closable]="false"
>
  <!-- HEADER -->
  <ng-template pTemplate="header">
    <div [ngClass]="{ hidden: isEditMode }" class="d-flex header-preview-pdf">
      <div class="pdf-title">{{ fileName }}</div>
    </div>

    <div class="w-100 d-flex flex-column justify-content-center" [ngClass]="{ hidden: !isEditMode }">
      <h5 class="pdf-title" style="position: fixed">Alert Editor</h5>
      <div class="w-100 d-flex flex-row justify-content-center">
          <div class="header-preview-pdf d-flex gap-3 m-sm-1">
            <svg-icon class="pi cursor-pointer" key="addPage" tooltipPosition="bottom" pTooltip="Add page" (click)='addPage()' size="xl"></svg-icon>
            <svg-icon class="pi cursor-pointer" key="removePage" tooltipPosition="bottom" pTooltip="Delete page" (click)='deletePage()' size="xl"></svg-icon>
            <svg-icon class="pi cursor-pointer pi-trash" key="pi-trash" tooltipPosition="bottom" pTooltip="Delete active object" (click)="deleteObjActive()" size="xl" style="color: #e8eced"></svg-icon>
            <svg-icon class="pi cursor-pointer" key="restore" tooltipPosition="bottom" pTooltip="Restore" (click)='refreshCanvas()' size="xl"></svg-icon>
            <svg-icon class="pi cursor-pointer" key="undo" tooltipPosition="bottom" pTooltip="Undo" (click)="undo()" size="xl"></svg-icon>
            <svg-icon class="pi cursor-pointer" key="redo" tooltipPosition="bottom" pTooltip="Redo" (click)="redo()" size="xl"></svg-icon>
            
            <svg-icon class="pi cursor-pointer" key="seprerate" size="xl"></svg-icon>
            <div class="d-flex flex-row pageNumber">
              <div class="spec">{{ indexCanvasSelected + 1 }}</div>
              &nbsp;{{ "/" }}
              <div class="">{{ listCanvas.length || 0 }}</div>
            </div>

            <svg-icon class="pi cursor-pointer" key="seprerate" size="xl"></svg-icon>
            <svg-icon class="pi cursor-pointer pi-download" key="pi-download" tooltipPosition="bottom" pTooltip="Back forward" (click)="sendToBack()" size="xl" style="color: #e8eced"></svg-icon>
            <svg-icon class="pi cursor-pointer pi-upload" key="pi-upload" tooltipPosition="bottom" pTooltip="Bring forward" (click)="bringForward()" size="xl" style="color: #e8eced"></svg-icon>
          </div>
      </div> 
    
    </div> 

    <div class="custom-close-icon" (click)="removeTheDraft()">
      <svg-icon class="pi cursor-pointer pi-times" key="pi-times" (click)='removeTheDraft()' size="x" style="color: #e8eced"></svg-icon>
    </div>
  </ng-template>


  <!-- BODY -->
  <ng-template pTemplate="content" style="overflow: auto;">
    <div [ngStyle]="{ display: isEditMode ? 'none' : 'block', height: '100%' }">
      <div style="height: 100%">
        <ng2-pdfjs-viewer #pdfViewerRender [zoom]="isRoadmap ? '75' : '100'" viewerId="MyID" [openFile]="false">
        </ng2-pdfjs-viewer>
      </div>

    </div>

    <!-- EDITOR -->
    <div [ngStyle]="{ display: isEditMode ? 'block' : 'none' }">
      <div class="d-flex flex-row gap-1 w-100">
        <!-- page thumbnail -->
        <div class="col-section border-right d-flex flex-column">
          <span class="title">Pages</span>
          <div *ngFor="let thumbnailCanvas of listCanvasThumbnail; let i = index"
            class="imageContainer"
          >
            <div class="canvasWrapper" (click)="selectPage(i)"
            >
              <img
                [id]="'thumbnailCanvas' + i "
                [src]="thumbnailCanvas"
                [ngClass]="{canvasSelected: indexCanvasSelected === i}"
              >
              <span>{{i + 1}}</span>
            </div>
          </div>
        </div>

        <!-- image thumbnail -->
        <div class="col-section d-flex flex-column">
          <span class="title">Evidence</span>
          <span class="detail">Drag and drop images into the alert</span>
          <div *ngFor="let thumbnail of listImageThumbnail; let i = index"
            (dblclick)="selectImage(i)"
            class="imageContainer"
          >
            <div class="imageWrapper">
              <img [id]="'thumbnailImage' + i" [src]="thumbnail" (mousedown)="mouseDownImage(i)">
              <span>{{listImage[i].name}}</span>
            </div>
          </div>
        </div>
  
        <!-- Canvas -->
        <div class="col-section">
          <canvas #canvasContainer id="canvas-editor"></canvas>
          <canvas #canvasThumbnail id="canvas-thumbnail" style="display: none;"></canvas>
        </div>
      </div>
    </div>


    <!-- DEFAULT -->
    <div *ngIf="!isRoadmap" id="make-pdf-default" class="" style="width: 1344px; position: absolute; top: 10000px;">
      <div class="container-fluid">
        <div class="">
          <table id="alert-info" class="table-header table table-bordered table-report mb-0"
            style="border: 1px solid #818f99">
            <tbody>
              <tr class="row-header">
                <td colspan="2" class="img-fit header" style="width: 24%; background-color: #cc0000">
                  <img srcset="../../../../assets/images/logo/logo.png" alt="" />
                </td>
                <td colspan="2" class="cell-center title-alert" style="font-size: 18px">
                  {{ translatedData.dataFields?.virtualRemoteOperations }}
                </td>
              </tr>
              <tr class="row-header">
                <td colspan="2" rowspan="7" class="" [ngStyle]="{
                    background:
                      'no-repeat fixed center/100% 280px url(' +
                      previewPDFPayload.rigImageUrl +
                      ')',
                    position: 'relative',
                    'z-index': 1
                  }"></td>
                <td class="cell-center" style="width: 38%">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.region}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.region || "Asia Pacific (AP)" }}
                    </div>
                  </div>
                </td>
                <td class="cell-center">
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.rig}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.rig || "Sapura Esperanza" }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.country}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.country || "Malaysia" }}
                    </div>
                  </div>
                </td>
                <td class="cell-center">
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.spudDate}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.spudDate }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.operator}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.operator }}
                    </div>
                  </div>
                </td>
                <td class="cell-center">
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.well}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.well }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.project}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.project }}
                    </div>
                  </div>
                </td>
                <td class="cell-center">
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.wellbore}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.wellbore }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.block}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.block }}
                    </div>
                  </div>
                </td>
                <td class="cell-center">
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.uwi}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.uwi }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.lease}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.lease }}
                    </div>
                  </div>
                </td>
                <td class="cell-center">
                  <div class="d-flex justify-content-between">
                    <div class="align-middle" style="text-align: left">{{ translatedData.dataFields?.remoteCenter}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.remoteCenter }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center">
                  <!-- align-items-center -->
                  <div class="d-flex justify-content-between">
                    <div class="align-middle">{{ translatedData.dataFields?.field}}:</div>
                    <div class="dark-color align-right-form">
                      {{ previewPDFPayload.field }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered table-report mb-0" [ngClass]="isCreateResolve ? '' : 'd-none'">
            <tbody>
              <tr *ngIf="this.isCreateResolve" class="">
                <td colspan="4" class="cell-center title-flag dark-color" [ngClass]="previewPDFPayload.alertFlag">
                  <span *ngFor="let item of translatedData.data?.event; let last = last" [ngClass]="{'text-bold': last, 'text-normal': !last}">
                    {{ item }}{{ !last ? " | " : ""}}
                  </span>
                </td>
              </tr>
              <tr class="">
                <td colspan="4" class="cell-center title-1 dark-color">
                  {{ translatedData.dataFields?.resolution }}
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center" style="width: 22%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">
                      <mat-icon fontIcon="access_time" class="size-sm"></mat-icon>
                    </div>
                    <div class="dark-color">
                      {{ previewPDFPayload.rigTimestampsSave }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 18%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">
                      <mat-icon fontIcon="person_outline" class="size-sm"></mat-icon>
                    </div>
                    <div class="dark-color">
                      {{ previewPDFPayload.userNameSave }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 30%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">
                      <mat-icon fontIcon="list_alt" class="size-sm"></mat-icon>
                    </div>
                    <div class="dark-color">
                      {{ previewPDFPayload.userDisciplineSave }}
                    </div>
                  </div>
                </td>

                <td class="cell-center" style="width: 30%">
                  <div class="d-flex">
                    <div>{{ translatedData.dataFields?.timeSaved }}:</div>
                    <div class="dark-color">
                      {{ previewPDFPayload.timeSaved }} hrs
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td style="" [colSpan]="3">
                  <div class="d-flex">
                    <div>{{ translatedData.dataFields?.contactMethod }}:</div>
                    <div class="dark-color">
                      {{ previewPDFPayload.contactMethod }}
                    </div>
                  </div>
                </td>
                <td style="width: 30%">
                  <div class="d-flex">
                    <div>{{ translatedData.dataFields?.recommendationFollowed }}:</div>
                    <div class="dark-color">
                      {{ previewPDFPayload.recommendationFollowed }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td colspan="4" class="" style="height: 56px">
                  <div class="d-flex">
                    <div class="me-3">{{ translatedData.dataFields?.resolutionDetail }}:</div>
                    <div class="dark-color wrap-text">
                      {{ previewPDFPayload.resolutionDetail }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered table-report mb-0">
            <tbody>
              <tr *ngIf="!this.isCreateResolve" class="">
                <td colspan="4" class="cell-center title-flag dark-color" [ngClass]="previewPDFPayload.alertFlag">
                  <span *ngFor="let item of translatedData.data?.event; let last = last" [ngClass]="{'text-bold': last, 'text-normal': !last}">
                    {{ item }}{{ !last ? " | " : ""}}
                  </span>
                </td>
              </tr>
              <tr class="">
                <td colspan="4" class="cell-center title-1 dark-color"
                  style="font-size: 16px; text-transform: uppercase">
                  {{ previewPDFPayload.eventType === "Report" ? translatedData.dataFields?.report : translatedData.dataFields?.intervention }}
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center" style="width: 22%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">
                      <mat-icon fontIcon="access_time" class="size-sm"></mat-icon>
                    </div>
                    <div class="dark-color">
                      {{ previewPDFPayload?.rigTimestampsBefore || previewPDFPayload?.rigTimestampsSave }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 18%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">
                      <mat-icon fontIcon="person_outline" class="size-sm"></mat-icon>
                    </div>
                    <div class="dark-color">
                      {{ previewPDFPayload?.userNameBefore || previewPDFPayload?.userNameSave }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 30%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">
                      <mat-icon fontIcon="list_alt" class="size-sm"></mat-icon>
                    </div>
                    <div class="dark-color">
                      {{ previewPDFPayload?.userDisciplineBefore || previewPDFPayload?.userDisciplineSave }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 30%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">{{ translatedData.dataFields?.currentOperation }}:</div>
                    <div class="dark-color">
                      {{ translatedData.data?.currentOperation }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="row-header">
                <td class="cell-center" style="width: 22%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">{{ translatedData.dataFields?.interval }}:</div>
                    <div class="dark-color">
                      {{ previewPDFPayload.interval }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 18%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">{{ translatedData.dataFields?.run }}:</div>
                    <div class="dark-color">
                      {{ previewPDFPayload.run }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 30%">
                  <div class="row-detail-intervention d-flex align-items-center">
                    <div class="me-1">{{ translatedData.dataFields?.depth }}:</div>
                    <div class="dark-color">
                      {{ translatedData.data?.depth || previewPDFPayload.depth }}
                    </div>
                  </div>
                </td>
                <td class="cell-center" style="width: 30%">
                  <div class="row-detail-intervention d-flex align-items-center" style="height: 100%">
                    <div class="me-1">{{ translatedData.dataFields?.risk }}:</div>
                    <div style="text-align: left" class="dark-color">
                      {{ translatedData.data?.risk }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td colspan="4" class="">
                  <!-- align-items-center -->
                  <div class="d-flex">
                    <div class="me-3">{{ translatedData.dataFields?.description }}:</div>
                    <div class="dark-color wrap-text">
                      {{ previewPDFPayload.eventDescription }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td colspan="4" class="">
                  <!-- align-items-center -->
                  <div class="d-flex" style="height: 100%">
                    <div class="me-3">{{ translatedData.dataFields?.recommendation }}:</div>
                    <div id="last-content" class="dark-color wrap-text">
                      {{ previewPDFPayload.recommendation }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- ROADMAP -->
    <div *ngIf="isRoadmap" id="make-pdf-roadmap" class="title-flag dark-color road-map-container" [ngClass]="previewPDFPayload.alertFlag">
      <div class="d-flex flex-column h-full">
        <!-- HEADER -->
        <div class="row-data-new d-flex flex-row w-100 justify-content-center">
          <table id="alert-info" class="table table-report-new mb-0"
            style="width: 232px; height: 210px; margin-right: 10px;">
            <tbody>
              <tr class="row-new">
                <td class="img-fit header" style="height: 40px; background-color: #000; padding: 1px;">
                  <img src="..\..\..\..\assets\images\logo\new-logo.svg" width="100%" height="100%" />
                </td>
              </tr>
              <tr class="row-new">
                <td [ngStyle]="{
                    background:
                      'no-repeat fixed center/100% 280px url(' + previewPDFPayload.rigImageUrl + ')',
                    position: 'relative',
                    'z-index': 1
                  }" style="height: 100%;"></td>
              </tr>
            </tbody>
          </table>

          <table id="alert-info" class="table table-report-new mb-0">
            <tbody>
              <tr class="row-new">
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.region | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.region || "Asia Pacific (AP)" }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.project | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.project }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.field | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.field }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.well | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.well }}
                  </div>
                </td>
              </tr>

              <tr class="row-new">
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.country | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.country || "Malaysia" }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.block | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.block }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.rig | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.rig || "Sapura Esperanza" }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.wellbore | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.wellbore }}
                  </div>
                </td>
              </tr>

              <tr class="row-new">
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.operator | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.operator }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.lease | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.lease }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.remoteCenter | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.remoteCenter }}
                  </div>
                </td>
                <td>
                  <span class="title-new">
                    {{ translatedData.dataFields?.uwi | uppercase }}
                  </span>
                  <div class="content-new with-header">
                    {{ previewPDFPayload.uwi }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- EVENT -->
        <div class="event-new d-flex flex-column">
          <span class="title-event">
            {{ translatedData.dataFields?.event || 'EVENT' | uppercase }}
          </span>
          <div class="content-event">
            <span *ngFor="let item of translatedData.data?.event; let last = last" [ngClass]="{'text-bold': last, 'text-normal': !last}">
              {{ item }}{{ !last ? " | " : ""}}
            </span>
          </div>
        </div>

        <!-- EVIDENCE -->
        <div class="wrapper-evidence" [style]="{'grid-template-columns':
              previewPDFPayload.evidences.length === 1 ? 'auto' : 'auto auto'}">
          <img *ngFor="let image of previewPDFPayload.evidences; let i = index" class="image-evidence"
            [src]="image | safe : 'resourceUrl'">

        </div>

        <!-- BOTTOM 1 -->
        <div class="row-data-new d-flex flex-row w-100 justify-content-center">
          <div class="grid-bottom1">
            <span class="title-new">
              {{ translatedData.dataFields?.timestamp | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.engineerName | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.engineerDiscipline | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.currentOperation | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.interval | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.runNo | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.depth | uppercase }}
            </span>

            <div class="content-new small-bottom">
              {{ previewPDFPayload?.rigTimestampsSave }}
            </div>
            <div class="content-new small-bottom">
              {{ previewPDFPayload?.userNameSave }}
            </div>
            <div class="content-new small-bottom">
              {{ previewPDFPayload?.userDisciplineSave }}
            </div>
            <div class="content-new small-bottom">
              {{ translatedData.data?.currentOperation }}
            </div>
            <div class="content-new small-bottom">
              {{ previewPDFPayload.interval }}
            </div>
            <div class="content-new small-bottom">
              {{ previewPDFPayload.run }}
            </div>
            <div class="content-new small-bottom">
              {{ translatedData.data?.depth || previewPDFPayload.depth }}
            </div>
          </div>
        </div>

        <!-- BOTTOM 2 -->
        <div class="row-data-new d-flex flex-row w-100 justify-content-center">
          <div class="grid-bottom2">
            <span class="title-new">
              {{ translatedData.dataFields?.description | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.risks | uppercase }}
            </span>
            <span class="title-new">
              {{ translatedData.dataFields?.recommendations | uppercase }}
            </span>

            <div class="content-new large-bottom">
              {{ previewPDFPayload.eventDescription }}
            </div>
            <div class="content-new large-bottom">
              {{ translatedData.data?.risk }}
            </div>
            <div class="content-new large-bottom">
              {{ previewPDFPayload.recommendation }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #roadmapClone *ngIf="isRoadmap" id="make-pdf-roadmap-clone">
    </div>
  </ng-template>


  <ng-template pTemplate="footer">
    <!-- Preview mode -->
    <div *ngIf="!isEditMode" class="w-100">
      <div class="d-flex justify-content-center gap-2 w-100">
        <p-button
          [attr.data-cy]="'close-button'"
          label="Back" 
          (onClick)="removeTheDraft()" 
          class="custom-p-button custom-p-button--secondary"></p-button>
        <p-button
          [attr.data-cy]="'close-button'"
          label="Edit"
          [disabled]="isViewer"
          (onClick)="switchMode()" 
          class="custom-p-button custom-p-button--secondary">
        </p-button>
        <p-button 
          label="Save"
          class="custom-p-button custom-p-button--primary"
          [attr.data-cy]="'close-button'"
          [disabled]="isViewer || disableSendfPdf"
          (click)="SaveAlert()">
        </p-button>
        <p-button
          *ngIf="isEngineer"
          class="custom-p-button custom-p-button--primary"
          label="Send"
          [attr.data-cy]="'close-button'"
          [disabled]="disableSendfPdf"
          (click)="SaveSendAlert()">
        </p-button>
      </div>

      <div
        class="cursor-pointer reset-section d-flex justify-content-center flex-row"
        (click)="resetDefault()"
      >
        <svg-icon class="pi" key="reset" size="xl"></svg-icon>
        <span class="ms-2">Reset</span>
      </div>
    </div>
    
    <!-- Editor mode -->
    <div *ngIf="isEditMode" class="d-flex justify-content-center gap-2">
      <p-button
        [attr.data-cy]="'close-button'"
        label="Cancel" 
        (onClick)="switchMode()" 
        class="custom-p-button custom-p-button--secondary">
      </p-button>
      
      <!-- <p-button 
        [attr.data-cy]="'close-button'"
        label="Export" 
        (click)="export()"
        class="custom-p-button custom-p-button--primary">
      </p-button> -->

      <p-button 
        [attr.data-cy]="'close-button'"
        label="Save" 
        (click)="saveEditor()"
        class="custom-p-button custom-p-button--primary">
      </p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>



