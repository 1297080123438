import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { AppHelper } from '../../../shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RigLevelDataToAddWellbore } from 'src/app/shared/models/rig.model';
import { DataSource } from 'src/app/shared/interface/rig.interface';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { MULTI_LEVEL } from 'src/app/shared/metadata/multi-level-dropdown-type.metadata';

@Component({
  selector: 'app-home-add-wellbore',
  templateUrl: './home-add-wellbore.component.html',
  styleUrls: ['./home-add-wellbore.component.scss'],
})
export class HomeAddWellboreComponent extends BaseComponent {
  isLoading: boolean = false;

  _displayAddWellboreDialog!: boolean;

  get displayAddWellboreDialog(): boolean {
    return this._displayAddWellboreDialog;
  }
  @Input() set displayAddWellboreDialog(value: boolean) {
    this._displayAddWellboreDialog = value;

    // force refresh
  }

  @Input()
  addwellboreLevelRigCard!: RigLevelDataToAddWellbore;

  @Output()
  hideAddWellboreDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  hideAddWellboreDialogAndReload: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  sources: DataSource[] = [];
  sourcesUpdate: any[] = [];
  customString: string = '';

  companies: any[] = [];
  projects: any[] = [];
  sites: any[] = [];
  wells: any[] = [];
  wellbores: any[] = [];
  contractors: any[] = [];
  assignRigs: any[] = [];
  restoreAssignRigs: any[] = [];

  settingDataADdWellbore$ = new Subject();

  isViewer: boolean = false;

  // // Variable of Form
  public formAddWellboreGroup!: FormGroup;

  public fieldNameKeys = {
    source: 'source',
    company: 'company',
    project: 'project',
    site: 'site',
    well: 'well',
    wellbore: 'wellbore',
    contractor: 'contractor',
    assignRig: 'assignRig',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
  };

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  MULTI_LEVEL = MULTI_LEVEL;

  constructor(
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    this.isLoading = true;

    // Since this is a popup, there is definitely User data. So we can get form data with .getValue()
    this.isViewer =
      this._userInfoService.userSubject.getValue().role ===
      AppConstant.ROLES.VIEWER.label;

    this.sourcesUpdate = AppConstant.DEFAULT_DATA_SOURCES_UPDATE;

    if (this.addwellboreLevelRigCard === undefined) {
      this.settingDataADdWellbore$
        .pipe(
          takeUntil(this.destroy$),
          switchMap(() =>
            forkJoin([
              this._homeService.getSources(),
              this._homeService.getConstructors(),
              this._homeService.getRigs(),
            ])
          )
        )
        .subscribe(([sources, contractors, rigs]: any) => {
          if (sources.data && contractors.data && rigs.data) {
            // source
            this.sourcesUpdate = sources.data;
            // contractors
            this.contractors = contractors.data;
            this.contractors = AppHelper.UtileFunctions.sortArrayBykey(
              this.contractors,
              'contractorName'
            );

            // rigs
            this.assignRigs = AppHelper.UtileFunctions.sortArrayBykey(
              Object.values(rigs.data),
              'rigName'
            );

            this.restoreAssignRigs = [...this.assignRigs];
            this.isLoading = false;
          }
        });
      this.buildForm();
    } else {
      
      
      this.sources = [...this.companies, this.addwellboreLevelRigCard.source];
      this.companies = [
        ...this.companies,
        this.addwellboreLevelRigCard.company,
      ];
      this.projects = [...this.projects, this.addwellboreLevelRigCard.project];
      this.contractors = [
        ...this.contractors,
        this.addwellboreLevelRigCard.contractor,
      ];
      this.assignRigs = [
        ...this.assignRigs,
        this.addwellboreLevelRigCard.assignRig,
      ];
      if (
        this.addwellboreLevelRigCard.site &&
        this.addwellboreLevelRigCard.well
      ) {
        this.sites = [...this.sites, this.addwellboreLevelRigCard.site];
        this.wells = [...this.wells, this.addwellboreLevelRigCard.well];
        let wellId = this.addwellboreLevelRigCard.well.wellId;
        this.settingDataADdWellbore$
          .pipe(
            takeUntil(this.destroy$),
            concatMap(() => this._homeService.getWellborebByIdWell(wellId))
          )
          .subscribe((wellbores: any) => {
            this.wellbores = wellbores.data;
            this.wellbores = this.wellbores.map((wellbore: any) => {
              return {
                ...wellbore,
                wellboreName:
                  // wellbore.wellboreCurrentName || wellbore.wellboreOriginalName,
                  wellbore.wellboreOriginalName,
              };
            });
            this.wellbores = AppHelper.UtileFunctions.sortArrayBykey(
              this.wellbores,
              'wellboreName'
            );
            this.isLoading = false;
          });
      } else {
        this.settingDataADdWellbore$
          .pipe(
            takeUntil(this.destroy$),
            concatMap(() =>
              this._homeService.getSitesByIdProject(
                this.addwellboreLevelRigCard.project.projectId
              )
            )
          )
          .subscribe((sites: any) => {
            this.sites = sites.data;
            this.sites = this.sites.map((site: any) => {
              return {
                ...site,
                // siteName: site.siteCurrentName || site.siteOriginalName,
                siteName: site.siteOriginalName,
              };
            });
            this.sites = AppHelper.UtileFunctions.sortArrayBykey(
              this.sites,
              'siteName'
            );
            this.isLoading = false;
          });
      }
      this.buildFormRigCardLevel(this.addwellboreLevelRigCard);
    }
    this.settingDataADdWellbore$.next(null);
  }

  buildForm() {
    this.formAddWellboreGroup = this._builder.group({
      [this.fieldNameKeys.source]: ['', [Validators.required]],
      [this.fieldNameKeys.company]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.project]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.site]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.well]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.wellbore]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.contractor]: ['', [Validators.required]],
      [this.fieldNameKeys.assignRig]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
    });
  }

 
  buildFormRigCardLevel(
    dataAddwellboreRigcardLevel: RigLevelDataToAddWellbore
  ) {
    let edmValue: any;
    for (let index = 0; index < AppConstant.DEFAULT_DATA_SOURCES_UPDATE.length; index++) {
      const element = AppConstant.DEFAULT_DATA_SOURCES_UPDATE[index];
      if (element.children?.length) {
        edmValue = element?.children.find((data: any) => {
          return data.data === dataAddwellboreRigcardLevel.source.sourceId
        })
      }
      else {
        if (element.data === dataAddwellboreRigcardLevel.source.sourceId) {
          edmValue = element
          break;
        }
      }
    }

    this.customString = AppConstant.DATA_SOURCES[`${edmValue?.data}`]?.displaySourceDropdown;
    if (this.assignRigs) {
      this.formAddWellboreGroup = this._builder.group({
        [this.fieldNameKeys.source]: [
          {
            value: edmValue,
            disabled: true
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.company]: [
          {
            value: {
              ...dataAddwellboreRigcardLevel.company,
            },
            disabled: true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.project]: [
          {
            value: {
              ...dataAddwellboreRigcardLevel.project,
            },
            disabled: true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.site]: [
          {
            value:
              dataAddwellboreRigcardLevel.site === undefined
                ? ''
                : { ...dataAddwellboreRigcardLevel.site },
            disabled:
              dataAddwellboreRigcardLevel.site === undefined ? false : true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.well]: [
          {
            value:
              dataAddwellboreRigcardLevel.well === undefined
                ? ''
                : { ...dataAddwellboreRigcardLevel.well },
            disabled: true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.wellbore]: [
          {
            value: '',
            disabled:
              dataAddwellboreRigcardLevel.well === undefined ? true : false,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.contractor]: [
          { value: dataAddwellboreRigcardLevel.contractor, disabled: true },
          [Validators.required],
        ],
        [this.fieldNameKeys.assignRig]: [
          {
            value: dataAddwellboreRigcardLevel.assignRig,
            disabled: true,
          },
          [Validators.required],
        ],
      });
    }
  }

  ngAfterViewInit(): void {
    const $formValue = this.formAddWellboreGroup.valueChanges;
    const company = this.formAddWellboreGroup.get('company')?.value;

    const checkIndex = [
      'source',
      'company',
      'project',
      'site',
      'well',
      'wellbore',
      'contractor',
      // 'rig',
    ];

    let nextFieldIndex = 0;

    $formValue!
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(400),
        distinctUntilChanged(),
        tap((formValue) => {
          this.isLoading = true;
          // field '' tức là cần được call để lấy data
          // field null là field vừa clear thì phải giữ data lại
        }),
        switchMap((formValue) => {
          let x = this.formAddWellboreGroup;

          let arrayKey = Object.getOwnPropertyNames(formValue);
          // this.addwellboreLevelRigCard === undefined && arrayKey.pop();

          if (arrayKey.length > 2) {
            arrayKey = arrayKey.filter(
              (item) => item !== 'contractor' && item !== 'assignRig'
            );
          }

          let copiedFormValue = Object.assign({}, formValue);
          delete copiedFormValue.assignRig;

          let currentObj = arrayKey[arrayKey.length - 2];
          let currentObjValue = copiedFormValue[arrayKey[arrayKey.length - 2]]; //formValue
          let nextIndex = checkIndex.findIndex((item) => item === currentObj);

          if (company?.length === 0 && copiedFormValue.company === null) {
            copiedFormValue.company = '';
          }

          let isClear =
            Object.values(copiedFormValue).find((x) => x === null) === null; //formValue

          let nextObj = checkIndex[nextIndex + 1];
          nextFieldIndex = nextIndex + 1;

          if (isClear === true) {
            nextObj = 'clear';
          }

          switch (nextObj) {
            case 'company':
              return this._homeService.getCompaniesById(
                currentObjValue.data
              );
            case 'project':
              return this._homeService.getProjectsByIdCompany(
                currentObjValue.policyId
              );
            case 'site':
              return this._homeService.getSitesByIdProject(
                currentObjValue.projectId
              );
            case 'well':
              return this._homeService.getWellbByIdSite(currentObjValue.siteId);
            case 'wellbore':
              return this._homeService.getWellborebByIdWell(
                currentObjValue.wellId
              );
            case 'clear':
              return of('');
          }
          return of('');
        })
      )
      .subscribe((nextFieldData: any) => {
        if (!nextFieldData.data) {
          this.isLoading = false;
          return;
        }

        let fillData = checkIndex[nextFieldIndex];

        switch (fillData) {
          case 'company':
            this.companies = nextFieldData.data.map((company: any) => {
              return {
                ...company,
                policyName: company.policyOriginalName,
              };
            });

            // remote invalid data policyName
            this.companies = AppHelper.UtileFunctions.filterArrayNullData(
              this.companies,
              'policyName'
            );
            // sort results by policyName
            this.companies = AppHelper.UtileFunctions.sortArrayBykey(
              this.companies,
              'policyName'
            );
            // if (this.companies.length === 0) {
            //   const company = this.formAddWellboreGroup.get('company');
            //   company?.setValue('')
            // }
            break;
          case 'project':
            // sort results by project name
            this.projects = nextFieldData.data.map((project: any) => {
              return {
                ...project,
                projectName:
                  // project.projectCurrentName || project.projectOriginalName,
                  project.projectOriginalName,
              };
            });

            // remote invalid data projectName
            this.projects = AppHelper.UtileFunctions.filterArrayNullData(
              this.projects,
              'projectName'
            );
            // sort results by projectName
            this.projects = AppHelper.UtileFunctions.sortArrayBykey(
              this.projects,
              'projectName'
            );

            // this.projects = Object.values(nextFieldData)[0];
            break;
          case 'site':
            this.sites = nextFieldData.data.map((site: any) => {
              return {
                ...site,
                // siteName: site.siteCurrentName || site.siteOriginalName,
                siteName: site.siteOriginalName,
              };
            });

            // remote invalid data site name
            this.sites = AppHelper.UtileFunctions.filterArrayNullData(
              this.sites,
              'siteName'
            );
            // sort results by site name
            this.sites = AppHelper.UtileFunctions.sortArrayBykey(
              this.sites,
              'siteName'
            );
            // this.sites = Object.values(nextFieldData)[0];
            break;
          case 'well':
            this.wells = nextFieldData.data.map((well: any) => {
              return {
                ...well,
                // wellName: well.wellCurrentName || well.wellOriginalName,
                wellName: well.wellOriginalName,
              };
            });

            // remote invalid data wellName
            this.wells = AppHelper.UtileFunctions.filterArrayNullData(
              this.wells,
              'wellName'
            );
            // sort results by wellName
            this.wells = AppHelper.UtileFunctions.sortArrayBykey(
              this.wells,
              'wellName'
            );
            // this.wells = Object.values(nextFieldData)[0];

            break;
          case 'wellbore':
            this.wellbores = nextFieldData.data.map((wellbore: any) => {
              return {
                ...wellbore,
                wellboreName:
                  // wellbore.wellboreCurrentName || wellbore.wellboreOriginalName,
                  wellbore.wellboreOriginalName,
              };
            });

            this.wellbores = AppHelper.UtileFunctions.sortArrayBykey(
              this.wellbores,
              'wellboreName'
            );

            // sort results by wellborebore name
            this.wellbores = AppHelper.UtileFunctions.sortArrayBykey(
              this.wellbores,
              'wellboreName'
            );
            // this.wellbores = Object.values(nextFieldData)[0];
            break;
        }

        this.isLoading = false;
      });
  }

  addNewWellbore(e: any) {
    if (!this.formAddWellboreGroup.valid) {
      return;
    } else {
      let payload = {
        projectId: this.formAddWellboreGroup.value.project
          ? this.formAddWellboreGroup.value.project.projectId
          : this.addwellboreLevelRigCard?.project?.projectId,
        rigId: this.formAddWellboreGroup.value.assignRig
          ? this.formAddWellboreGroup.value.assignRig.rigId
          : this.addwellboreLevelRigCard?.assignRig?.rigId,
        wellboreId: this.formAddWellboreGroup.value.wellbore.wellboreId,
      };

      this.isLoading = true;

      this._homeService
        .addWellbore(payload)
        .pipe(catchError(AppHelper.UtileFunctions.handleError))
        .subscribe({
          next: (response: any) => {
            this.displayAddWellboreDialog = !this.displayAddWellboreDialog;
            this.hideAddWellboreDialogAndReload.emit(
              this.displayAddWellboreDialog
            );
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Add Wellbore',
              content: 'Wellbore was added successfully!',
            });

            this.isLoading = false;
            this.reset();
          },
          error: (error: any) => {
            error &&
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Add Wellbore',
                content: error?.message || error,
              });
            this.isLoading = false;
          },
        });
    }
  }

  removeTheDraft() {
    if (
      this.formAddWellboreGroup.pristine &&
      !this.formAddWellboreGroup.touched
    ) {
      this.displayAddWellboreDialog = !this.displayAddWellboreDialog;
      this.hideAddWellboreDialog.emit(this.displayAddWellboreDialog);
    } else {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    }
  }

  closeDialogForm() {
    this.reset();
    this.displayAddWellboreDialog = !this.displayAddWellboreDialog;
    this.hideAddWellboreDialogAndReload.emit(this.displayAddWellboreDialog);
  }

  reset() {
    const company = this.formAddWellboreGroup.get('company');
    const project = this.formAddWellboreGroup.get('project');
    const site = this.formAddWellboreGroup.get('site');
    const well = this.formAddWellboreGroup.get('well');
    const wellbore = this.formAddWellboreGroup.get('wellbore');
    const assignRig = this.formAddWellboreGroup.get('assignRig');

    company?.setValue('');

    project?.disable();
    project?.setValue('');

    site?.disable();
    site?.setValue('');

    well?.disable();
    well?.setValue('');

    wellbore?.disable();
    wellbore?.setValue('');

    // assignRig?.disable();
    assignRig?.setValue('');

    // this.formAddWellboreGroup.markAsUntouched();
    // this.formAddWellboreGroup.markAsPristine();

    // this.formAddWellboreGroup.reset();
  }

  selectItem(event: any, feild: string) {
    const company = this.formAddWellboreGroup.get('company');
    const project = this.formAddWellboreGroup.get('project');
    const site = this.formAddWellboreGroup.get('site');
    const well = this.formAddWellboreGroup.get('well');
    const wellbore = this.formAddWellboreGroup.get('wellbore');
    const contractor = this.formAddWellboreGroup.get('contractor');
    const assignRig = this.formAddWellboreGroup.get('assignRig');

    switch (feild) {
      case 'source':
        // xu li source
        if (event.node.data) {
          company?.markAsUntouched();
          company?.markAsPristine();

          company?.enable();
          company?.setValue('');

          project?.disable();
          project?.setValue('');

          site?.disable();
          site?.setValue('');

          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');
        } else {
          company?.disable();
          company?.setValue('');

          project?.disable();
          project?.setValue('');

          site?.disable();
          site?.setValue('');

          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        }
        break;
      case 'company':
        // xu li company
        if (event.value) {
          project?.markAsUntouched();
          project?.markAsPristine();

          project?.enable();
          project?.setValue('');

          site?.disable();
          site?.setValue('');

          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        } else {
          project?.disable();
          project?.setValue('');

          site?.disable();
          site?.setValue('');

          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        }
        break;
      case 'project':
        // xu li project
        if (event.value) {
          site?.markAsUntouched();
          site?.markAsPristine();

          site?.enable();
          site?.setValue('');

          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        } else {
          site?.disable();
          site?.setValue('');

          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        }
        break;
      case 'site':
        // xu li site
        if (event.value) {
          well?.markAsUntouched();
          well?.markAsPristine();

          well?.enable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        } else {
          well?.disable();
          well?.setValue('');

          wellbore?.disable();
          wellbore?.setValue('');

          // assignRig?.disable();
          // assignRig?.setValue('');
        }
        break;
      case 'well':
        // xu li well
        if (event.value) {
          wellbore?.markAsUntouched();
          wellbore?.markAsPristine();

          wellbore?.enable();
          wellbore?.setValue('');
        } else {
          wellbore?.disable();
          wellbore?.setValue('');
        }
        break;
      case 'wellbore':
        if (event.value) {
          // assignRig?.enable();
        } else {
          // assignRig?.disable();
          // assignRig?.setValue('');
        }
        break;
      case 'contractor':
        if (event.value) {
          assignRig?.markAsUntouched();
          assignRig?.markAsPristine();
          assignRig?.setValue('');

          this.assignRigs = [...this.restoreAssignRigs];
          this.assignRigs = this.assignRigs.filter((rig: any) => {
            return rig.contractorId === event.value.contractorId;
          });
          assignRig?.enable();
        } else {
          contractor?.setValue('');

          this.assignRigs = [...this.restoreAssignRigs];
          assignRig?.disable();
          assignRig?.setValue('');
        }
        break;
      default:
        break;
    }
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  onDestroy(): void {}

  getCustomLabelMultiDropdown(currentObjLevel: any): string {
    let labelDisplay = '';
    if (currentObjLevel.parent) {
      labelDisplay = `${currentObjLevel.parent.label} (${labelDisplay})`;
    } else {
      labelDisplay = `${currentObjLevel.label}`;
    }
    return labelDisplay;
  }
}
