import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { PermissionGroup } from 'src/app/shared/models/permissionGroup.model';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { RemoteCenter } from 'src/app/shared/models/rig.model';

@Component({
  selector: 'app-user-form-user',
  templateUrl: './user-form-user.component.html',
  styleUrls: ['./user-form-user.component.scss'],
})
export class UserFormUserComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  displayFormUser: boolean = false;

  @Input()
  userEdit: any;

  @Input()
  userLogin: any;

  @Output()
  hideFormUser: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  requestReloadPage: EventEmitter<any> = new EventEmitter<any>();

  selectedNode: any;
  selectedUserString: string = '';

  ADMIN_DEFAULT_PERMISSION!: PermissionGroup;
  MANAGER_DEFAULT_PERMISSION!: PermissionGroup;
  ENGINEER_DEFAULT_PERMISSION!: PermissionGroup;
  VIEWER_DEFAULT_PERMISSION!: PermissionGroup;

  // Variable of Form
  public formUserGroup!: FormGroup;

  public fieldNameKeys = {
    firstName: 'firstName',
    lastName: 'lastName',
    role: 'role',
    halId: 'halId',
    psl: 'psl',
    country: 'country',
    remoteCenter: 'remoteCenter',
    timezone: 'timezone',
    flag: 'flag',
    phone: 'phone',
    dialCode: 'dialCode',
    mail: 'mail',
    funtionalMailbox: 'funtionalMailbox',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    mailValidator: 'Please fill in the correct email syntax.',
    whitespace: 'Please fill in the information without white space.',

    userNonExist: "This user's ID does not exist",
    minlength: 'Please enter phone more 6 numbers',
    maxlength: 'Please enter phone less 12 numbers',

    firstNameValidator: 'Please enter between 2 and 30 characters',
    lastNameValidator: 'Please enter between 2 and 30 characters',
  };

  private nonSpaceStartEndRegex = `^[^\s]+(\s+[^\s]+)*$`;
  // private emailRegex1: string = `^(?!\.*?[\._-]{2})([^-_\.]\.*[^-_\.])[a-zA-Z0-9_\.-]{1,}@[A-Za-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$`;
  private emailRegex: string = `^(?!\.*?[\._-]{2})([^-_\.]\.*[^-_\.])[a-zA-Z0-9_\.-]{1,}@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\\.[A-Za-z0-9]{2,}){1,2}$`;
  // private limitCharacterRegex_1: string = `^[0-9A-Za-z!@\. ;:'"?-]{2,30}$`;
  private limitCharacterRegex_1: string = `^[0-9A-Za-z!@\. ;:'"?_-]{2,30}$`;

  roles: any[] = [];
  PSLs: any[] = [];
  countries: any[] = [];
  remoteCenters: any[] = [];
  timezones: any[] = [];
  funtionalMailboxes: any[] = [];

  counterSelectedRole = 0;
  counterSelectedMailBox = 0;
  counterRemoteCenter = 0;

  filteredHallUser: any[] = [];

  displayNameUser: string = '';

  settingDataFormUser$ = new Subject();

  filtersLoaded?: Promise<boolean>;
  isDataReady: boolean = false;

  menuItems: any = [];

  isViewer: boolean = false;
  isSelectViewerRole: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _builder: FormBuilder,
    private _userInfoService: UserInfoService,
    private _notificationService: NotificationService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    this.filtersLoaded = Promise.resolve(true);
    this.isLoading = true;

    this.PSLs = AppConstant.HALLIBURTON_PSLs;
    this.countries = AppConstant.PHONE_COUNTRIES;
    this.timezones = AppConstant.TIME_ZONES;
    this.roles = JSON.parse(JSON.stringify(AppConstant.WELLCARE_ROLES_TEAM));
    // [...AppConstant.WELLCARE_ROLES_TEAM];

    let roleData: any;
    let remoteCenterData:  any[] = [];;
    let mailBoxData: any[] = [];

    let userInfor = this._userInfoService.userSubject.getValue();

    if (userInfor)
      this.isViewer = userInfor.role === AppConstant.ROLES.VIEWER.label;

    const remoteCenterList: any[] = this.userEdit?.remoteCenterList?.map((item:RemoteCenter) => item?.remoteCenterId)

    let sources: Observable<any>[] = [
      this._userInfoService.getDiscipline(),
      this._userInfoService.getDisciplineById(
        this.userEdit?.discipline?.disciplineId || this.userEdit?.role || null
      ),
      this._userInfoService.getMailBoxByQuery(
        this.userEdit?.discipline?.disciplineId,
        remoteCenterList
      ),
    ];
    this.settingDataFormUser$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(id => forkJoin(sources))
      )
      .subscribe(([discipline, remote, mailbox]: any) => {
        if (discipline && remote && mailbox) {
          if (discipline?.data?.length) {
            //
            this.roles[0].children = [];
            discipline.data.forEach((discipline: any) => {
              this.roles[0].children.push({
                label: discipline.disciplineName,
                data: discipline.disciplineId,
                styleClass: 'lowestChild',
                leaf: false,
                parent: 'Engineer',
              });
            });
            //

            //
            if (userInfor.role === AppConstant.ROLES.MANAGER.label) {
              this.roles.push(AppConstant.ROLES.MANAGER.value);
            } else {
              this.roles.push(AppConstant.ROLES.VIEWER.value);
              this.roles.push(AppConstant.ROLES.MANAGER.value);
              this.roles.push(AppConstant.ROLES.ADMIN.value);
            }

            // index - 1
            if (this.userEdit) {
              // need set label of dropdown multi level and Item chooose - Role (ENGINEER role)
              if (this.userEdit.role === AppConstant.ROLES.ADMIN.label) {
                roleData = this.roles[3];
                this.selectedUserString = AppConstant.ROLES.ADMIN.label;
              } else if (
                this.userEdit.role === AppConstant.ROLES.VIEWER.label
              ) {
                roleData = this.roles[1];
                this.selectedUserString = AppConstant.ROLES.VIEWER.label;
              } else if (
                this.userEdit.role === AppConstant.ROLES.MANAGER.label
              ) {
                // Drop down role have filter by userInfor.role so need change order
                roleData =
                  this.roles[
                    userInfor.role === AppConstant.ROLES.MANAGER.label ? 1 : 2
                  ];
                this.selectedUserString = AppConstant.ROLES.MANAGER.label;
              } else {
                let dataDisciplineId = this.userEdit.discipline.disciplineId;
                let found,
                  index_lv_1 = 0,
                  index_lv_2 = 0;
                found = this.roles[0]!.children!.find(
                  (item: any, i_Lv2: number) => {
                    if (item.data === dataDisciplineId) {
                      index_lv_2 = i_Lv2;
                      return item.data === dataDisciplineId;
                    }
                  }
                );
                if (found) {
                  roleData = this.roles[0]!.children![index_lv_2];
                  this.selectedUserString = `Engineer (${roleData.label})`;
                }
              }
            }
          } else {
            this.roles[0].styleClass = 'lowestChild';
            this.roles[0].leaf = false;
          }

          // Setting Remote Center List -- EDIT CASE -- Check Engineer or not
          // Setting Mail Box List -- EDIT CASE
          if (this.userEdit) {
            // Edit Engineer
            if (this.userEdit.role === AppConstant.ROLES.ENGINEER.label) {
              this.remoteCenters = remote?.data?.remoteCenterList?.map((remote: any) => {
                return {
                  remoteCenterName: remote.remoteCenterName,
                  remoteCenterId: remote.remoteCenterId,
                };
              });

              for (
                let i_Lv1 = 0;
                i_Lv1 < this.userEdit.remoteCenterList.length;
                i_Lv1++
              ) {
                let found = this.remoteCenters.find((item, i_Lv2) => {
                  return (
                    item.remoteCenterId ===
                    this.userEdit.remoteCenterList[i_Lv1].remoteCenterId
                  );
                });
                //

                if (found) {
                  remoteCenterData.push(found);
                }
              }
              this.counterRemoteCenter = remoteCenterData.length;

              ////////////////////////////////////////
              this.funtionalMailboxes = mailbox.data.map((mailBox: any) => {
                return {
                  mailboxName: mailBox.mailboxName,
                  mailboxId: mailBox.mailboxId,
                };
              });
              //
              for (
                let i_Lv1 = 0;
                i_Lv1 < this.userEdit.mailboxList.length;
                i_Lv1++
              ) {
                let found = this.funtionalMailboxes.find((item, i_Lv2) => {
                  return (
                    item.mailboxId ===
                    this.userEdit.mailboxList[i_Lv1].mailboxId
                  );
                });
                //

                if (found) {
                  mailBoxData.push(found);
                }
              }
              this.counterSelectedMailBox = mailBoxData.length;
              //
            }

            // Edit Engineer Manager
            if (
              [
                AppConstant.ROLES.MANAGER.label,
                AppConstant.ROLES.VIEWER.label,
              ].includes(this.userEdit.role)
            ) {
              this.remoteCenters = remote?.data?.map((remote: any) => {
                return {
                  remoteCenterName: remote.remoteCenterName,
                  remoteCenterId: remote.remoteCenterId,
                };
              });

              for (
                let i_Lv1 = 0;
                i_Lv1 < this.userEdit.remoteCenterList.length;
                i_Lv1++
              ) {
                let found = this.remoteCenters.find((item, i_Lv2) => {
                  return (
                    item.remoteCenterId ===
                    this.userEdit.remoteCenterList[i_Lv1].remoteCenterId
                  );
                });
                //

                if (found) {
                  remoteCenterData.push(found);
                }
              }
              this.counterRemoteCenter = remoteCenterData.length;
            }
          }

          this.countries = this.countries.map(country => {
            return {
              ...country,
              combine_name_dial: `${country.name}  ${
                country.replace_dial_code || country.dial_code
              }`,
            };
          });
          // Setting Data - End

          // Call To Build Template
          if (this.userEdit) {
            // Set reset Name Value
            this.displayNameUser = this.userEdit.displayName;
            // Build Form Edit
            this.buildFormEdit(
              this.userEdit,
              roleData,
              remoteCenterData,
              mailBoxData,
              this.userLogin
            );
            this.isLoading = false;
          } else {
            // Build Form Add
            this.buildFormAdd();
            this.isLoading = false;
          }
          this.searchHalId();
          this.changeRole();
          this.changeRemoteCenter();
          // Call To Build Template
        }
      });

    this.settingDataFormUser$.next(null);

    // Setting data for Default Permissions
    let sourcePermissions: Observable<any>[] = [
      this._userInfoService.getDefaultPermission(AppConstant.ROLES.ADMIN.label),
      this._userInfoService.getDefaultPermission(
        AppConstant.ROLES.MANAGER.label
      ),
      this._userInfoService.getDefaultPermission(
        AppConstant.ROLES.ENGINEER.label
      ),
      this._userInfoService.getDefaultPermission(
        AppConstant.ROLES.VIEWER.label
      ),
    ];

    forkJoin(sourcePermissions).subscribe(
      ([
        adminPermission,
        managerPermission,
        engineerPermission,
        viewerPermission,
      ]: any) => {
        this.ADMIN_DEFAULT_PERMISSION = {
          groupPermission: adminPermission.data,
        };

        this.MANAGER_DEFAULT_PERMISSION = {
          groupPermission: managerPermission.data,
        };

        this.ENGINEER_DEFAULT_PERMISSION = {
          groupPermission: engineerPermission.data,
        };

        this.VIEWER_DEFAULT_PERMISSION = {
          groupPermission: viewerPermission.data,
        };
      }
    );
  }

  buildFormAdd() {
    this.formUserGroup = this._builder.group({
      [this.fieldNameKeys.halId]: [
        '',
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.nonSpaceStartEndRegex,
            'halId',
            'whitespace'
          ),
          this.userNonExist,
        ],
      ],
      [this.fieldNameKeys.firstName]: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitCharacterRegex_1,
            'firstName',
            'firstNameValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.lastName]: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitCharacterRegex_1,
            'lastName',
            'lastNameValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.role]: [null, Validators.required],
      [this.fieldNameKeys.psl]: [null, Validators.required],
      [this.fieldNameKeys.country]: [null, Validators.required],
      [this.fieldNameKeys.remoteCenter]: [{ value: null, disabled: true }],
      [this.fieldNameKeys.timezone]: [null, Validators.required],
      [this.fieldNameKeys.flag]: [null],
      [this.fieldNameKeys.dialCode]: ['+1'],
      [this.fieldNameKeys.phone]: [
        null,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
        ],
      ],
      [this.fieldNameKeys.mail]: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.customCorrectField(
            this.fieldNameKeys,
            this.emailRegex,
            'mail',
            'mailValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.funtionalMailbox]: [
        { value: null, disabled: true },
        Validators.required,
      ],
    });
  }

  buildFormEdit(
    user: any,
    roleData: any,
    remoteCenterData: any,
    mailBoxData: any,
    userLogin: any
  ) {
    this.isSelectViewerRole =
      this.userEdit.role === AppConstant.ROLES.VIEWER.label;
    user.replaceCountry = user.country.slice(0, 2);

    let psl = this.PSLs.find(psl => psl.code === user.psl);

    let country = this.countries.find(
      country =>
        country.code === user.country || country.code === user.replaceCountry
    );

    let timezone = this.timezones.find(
      timezone => timezone.code === user.timezone
    );

    let userDailCode = user.phone.split(' ')[0];
    // Don need compare with user?.country ||
    let valueFlag = this.countries.find(
      flag => flag.dial_code === userDailCode
    );

    let valueDial = this.countries.find(dial => {
      if (dial.dial_code === userDailCode) {
        return dial;
      }
    });

    let valuePhone = '';
    if (valueDial && user.phone) {
      valuePhone = user.phone.replace(valueDial.dial_code, '');
      valuePhone = AppHelper.UtileFunctions.replaceAllCharacter(
        valuePhone,
        ' ',
        ''
      );
    }

    this.formUserGroup = this._builder.group({
      [this.fieldNameKeys.halId]: [
        { value: { halId: user.halId, userId: user.id }, disabled: true },
      ],
      [this.fieldNameKeys.firstName]: [
        user.firstName,
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitCharacterRegex_1,
            'firstName',
            'firstNameValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.lastName]: [
        user.lastName,
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitCharacterRegex_1,
            'lastName',
            'lastNameValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.role]: [
        {
          value: roleData,
          disabled: user.id === userLogin.id ? true : false,
        },
        Validators.required,
      ],
      [this.fieldNameKeys.psl]: [psl, Validators.required],
      [this.fieldNameKeys.country]: [country, Validators.required],
      [this.fieldNameKeys.remoteCenter]: [
        {
          value: remoteCenterData,
          disabled:
            user.id === userLogin.id ||
            [AppConstant.ROLES.ADMIN.label].includes(user.role)
              ? true
              : false,
              
        },
        [AppConstant.ROLES.ENGINEER.label, AppConstant.ROLES.MANAGER.label].includes(user.role)
          ? [Validators.required]
          : [],
      ],
      [this.fieldNameKeys.timezone]: [timezone, Validators.required],
      [this.fieldNameKeys.flag]: [valueFlag, Validators.required],
      [this.fieldNameKeys.dialCode]: [
        valueDial.replace_dial_code || valueDial.dial_code,
      ],
      [this.fieldNameKeys.phone]: [
        Number(valuePhone) !== 0 ? Number(valuePhone) : '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
        ],
      ],
      [this.fieldNameKeys.mail]: [
        user.customMail || user.mail,
        [AppConstant.ROLES.ADMIN.label].includes(user.role)
          ? []
          : [
              Validators.required,
              this.noWhitespaceValidator,
              this.customCorrectField(
                this.fieldNameKeys,
                this.emailRegex,
                'mail',
                'mailValidator'
              ),
            ],
      ],
      [this.fieldNameKeys.funtionalMailbox]: [
        {
          value: mailBoxData,
          disabled:
            user.id === userLogin.id ||
            ![AppConstant.ROLES.ENGINEER.label].includes(user.role)
              ? true
              : false,
        },
        user.role === AppConstant.ROLES.ENGINEER.label
          ? [Validators.required]
          : [],
      ],
    });
  }

  ngAfterViewInit(): void {}

  searchHalId() {
    const formValueHaId = this.formUserGroup.get(
      this.fieldNameKeys.halId
    )?.valueChanges;
    formValueHaId!
      .pipe(
        catchError(AppHelper.UtileFunctions.handleError),
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        tap(user => {
          this.isLoading = true;
          // Setting Infro here
          if (typeof user !== 'string') {
            //

            // Set First Name Field
            if (user.firstName) {
              this.formUserGroup
                .get(this.fieldNameKeys.firstName)
                ?.setValue(user.firstName);
            } else {
              this.formUserGroup
                .get(this.fieldNameKeys.firstName)
                ?.setValue(user.displayName.split(' ')[0] || '');
            }

            // Set Last Name Field
            if (user.lastName) {
              this.formUserGroup
                .get(this.fieldNameKeys.lastName)
                ?.setValue(user.lastName);
            } else {
              let resetValue = '';
              let arrName = user.displayName.split(' ');
              arrName.shift();
              resetValue = arrName.join('') || '';

              this.formUserGroup
                .get(this.fieldNameKeys.lastName)
                ?.setValue(resetValue);
            }

            // Set Country Field
            if (user.country) {
              let country = this.countries.find(country => {
                if (user.country === 'U.A.E.') {
                  return country.code === 'AE';
                } else {
                  return (
                    country.code === user.country.slice(0, 2) ||
                    country.name.toLowerCase() === user.country.toLowerCase()
                  );
                }
              });

              //

              this.formUserGroup
                .get(this.fieldNameKeys.country)
                ?.setValue(country);

              this.formUserGroup
                .get(this.fieldNameKeys.dialCode)
                ?.setValue(country?.dial_code);

              this.formUserGroup
                .get(this.fieldNameKeys.flag)
                ?.setValue(country);

              this.displayNameUser = user.displayName;
            }

            // Set Email Field
            if (user.mail) {
              this.formUserGroup
                .get(this.fieldNameKeys.mail)
                ?.setValue(user.customMail || user.mail);
            }

            // Set Phone Field
          }
        }),
        switchMap(data => this._userInfoService.getUsersByHalID(data))
      )
      .subscribe((users: any) => {
        if (users?.data?.length) {
          this.filteredHallUser = users.data.filter((user: any) => !user.role);
        } else {
          this.filteredHallUser = [];
        }
        this.isLoading = false;
        //
      });
  }

  changeRole() {
    let roleSelected = '';
    const formValueRole = this.formUserGroup.get(
      this.fieldNameKeys.role
    )?.valueChanges;
    formValueRole!
      .pipe(
        catchError(AppHelper.UtileFunctions.handleError),
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        map(role => {
          return role?.data ? role.data : role;
        }),
        tap(disciplineId => {
          this.isLoading = true;
          // Setting role here
          roleSelected = disciplineId;
          //

          // Reset number select of MailBox
          this.counterSelectedMailBox = 0;
        }),
        switchMap(disciplineId =>
          this._userInfoService.getDisciplineById(disciplineId)
        )
      )
      .subscribe((response: any) => {
        let remoteCenterControl = this.formUserGroup.get(
          this.fieldNameKeys.remoteCenter
        );
        let funtionalMailboxControl = this.formUserGroup.get(
          this.fieldNameKeys.funtionalMailbox
        );

        let mailBoxControl = this.formUserGroup.get(
          this.fieldNameKeys.funtionalMailbox
        );

        // Reset Value of control when Role change
        remoteCenterControl?.setValue(null);
        mailBoxControl?.setValue(null);
        this.isSelectViewerRole = false;

        switch (roleSelected) {
          case null:
            this.remoteCenters = response;
            break;
          case AppConstant.ROLES.VIEWER.label:
            this.isSelectViewerRole = true;
            this.remoteCenters = response.data;
            this.remoteCenters.forEach(function (remoteCenter) {
              delete remoteCenter.disciplineList;
            });
            remoteCenterControl?.setValidators([]);
            break;
          case AppConstant.ROLES.ADMIN.label:
            this.remoteCenters = response;
            remoteCenterControl?.setValidators([]);
            break;
          case AppConstant.ROLES.MANAGER.label:
            this.remoteCenters = response.data;
            this.remoteCenters.forEach(function (remoteCenter) {
              delete remoteCenter.disciplineList;
            });

            remoteCenterControl?.setValidators([Validators.required]);
            break;
          default:
            let remoteCenterList = response.data.remoteCenterList;
            this.remoteCenters = remoteCenterList;
            remoteCenterControl?.setValidators([Validators.required]);
            break;
        }

        //

        if (
          !roleSelected ||
          [AppConstant.ROLES.ADMIN.label].includes(roleSelected)
        ) {
          remoteCenterControl?.disable();
          funtionalMailboxControl?.disable();
        } else {
          remoteCenterControl?.enable();
          funtionalMailboxControl?.disable();
        }

        this.isLoading = false;
      });
  }

  changeRemoteCenter() {
    let remoteCenterSelected = '';
    const formValueRemoteCenter = this.formUserGroup.get(
      this.fieldNameKeys.remoteCenter
    )?.valueChanges;
    formValueRemoteCenter!
      .pipe(
        catchError(AppHelper.UtileFunctions.handleError),
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        tap(remoteCenter => {
          if (!!remoteCenter) {
            this.isLoading = true;
          } else {
            this.formUserGroup
              .get(this.fieldNameKeys.funtionalMailbox)
              ?.setValue(null);
          }
          // Setting role here
          remoteCenterSelected = remoteCenter;
          //

          // Reset number select of MailBox
          this.counterSelectedMailBox = 0;
        }),
        switchMap((remoteCenter: RemoteCenter[]) => {
          let discipline = this.formUserGroup.get(
            this.fieldNameKeys.role
          )?.value;

          if (
            remoteCenter?.length ||
            [
              AppConstant.ROLES.MANAGER.label,
              AppConstant.ROLES.VIEWER.label,
            ].includes(discipline?.data)
          ) {
            this.formUserGroup
              .get(this.fieldNameKeys.funtionalMailbox)
              ?.disable();
            }
            const remoteCenterList: any[] = remoteCenter?.map((item:RemoteCenter) => item?.remoteCenterId)

            return this._userInfoService.getMailBoxByQuery(
              discipline?.data || '',
              remoteCenterList
            );

        })
      )
      .subscribe((response: any) => {
        let mailBoxList = response?.data;
        if (mailBoxList) {
          // Set mailBoxControl Enable
          let mailBoxControl = this.formUserGroup.get(
            this.fieldNameKeys.funtionalMailbox
          );
          mailBoxControl?.enable();
          mailBoxControl?.setValidators([Validators.required]);
          mailBoxControl?.setValue(null);

          // Set mailBoxControl Value
          if (mailBoxList.length === 0) {
            mailBoxControl?.setValue(null);
            this.funtionalMailboxes = [];
          } else {
            this.funtionalMailboxes = mailBoxList.map((mailBox: any) => {
              return {
                mailboxName: mailBox.mailboxName,
                mailboxId: mailBox.mailboxId,
              };
            });
          }
        }
        this.isLoading = false;
      });
  }

  filterHallUser(event: any) {}

  clearFilteredHallUser(event: any) {
    this.filteredHallUser = [];
    this.formUserGroup.get(this.fieldNameKeys.halId)?.setValue('');
  }

  onMenuShow(formKey: 'firstName' | 'lastName') {
    this.menuItems = [
      {
        label: 'Reset',
        icon: 'c-icons restore-icon',
        command: () => {
          if (formKey === 'firstName') {
            let resetValue = this.displayNameUser.split(' ')[0] || '';

            //
            this.formUserGroup.get(formKey)?.setValue(resetValue);
          } else if (formKey === 'lastName') {
            let resetValue = '';
            let arrName = this.displayNameUser.split(' ');
            arrName.shift();
            resetValue = arrName.join('') || '';

            //
            this.formUserGroup.get(formKey)?.setValue(resetValue);
          }
        },
      },
    ];
  }

  onOptionsSelected(event: any, selectedNode: any) {
    if (event.node.hasOwnProperty('children')) {
      selectedNode = selectedNode === undefined ? null : selectedNode;
      this.formUserGroup.get('role')?.setValue(selectedNode);

      if (!selectedNode) {
        const control = this.formUserGroup.get(this.fieldNameKeys.role);
        control?.setErrors(null);
      }

      return;
    }

    this.selectedNode = event.node;
    if (event.node.parent === undefined) {
      this.selectedUserString = `${event.node.label}`;
    } else {
      this.selectedUserString = `${event.node.parent.label} (${event.node.label})`;
    }
    this.formUserGroup.get('role')?.setValue(this.selectedNode);
  }

  onClearRole(event: any) {
    this.selectedNode = undefined;
    this.selectedUserString = '';
  }

  countSelectedValue(e: any, controlName: string) {
    let controlValue = this.formUserGroup.controls[controlName].value;
    if (controlValue) {
      switch (controlName) {
        case 'remoteCenter':
          this.counterRemoteCenter = controlValue.length;
          break;

        case 'funtionalMailbox':
          this.counterSelectedMailBox = controlValue.length;
          break;

        default:
          break;
      }
    }
  }

  public handlePasteInput(controlName: string, input: any) {
    let control = this.formUserGroup.get(controlName);

    // get paste data
    let textPaste = input.clipboardData.getData('text/plain');

    // remove AZaz -+eE from paste data
    let finalPaste = textPaste.replace(/[^0-9]+/g, '');

    control?.setValue(finalPaste);
    this.getRefresh('phone');

    input.preventDefault();
  }

  submitUser(value: any): void {
    //
    this.isLoading = true;

    let formData = this.formUserGroup.value;

    if (this.userEdit && this.userEdit.id && this.userEdit.halId) {
      this.formUserGroup.value.halId = {
        halId: this.userEdit.halId,
        id: this.userEdit.id,
      };
    }

    let formDataMailBox: any[] = [];
    let formDataRemoteCenter: any[] = [];

    if (formData.funtionalMailbox) {
      formDataMailBox = formData.funtionalMailbox;
      formDataMailBox.forEach(function (mailbox: any) {
        delete mailbox.mailboxName;
      });
    }

    if (formData.remoteCenter) {
      formDataRemoteCenter = formData.remoteCenter;
      formDataRemoteCenter.forEach(function (remoteCenter: any) {
        delete remoteCenter.remoteCenterName;
      });
    }

    if (formData.halId) {
      let roleSelected: any;
      if (this.userEdit) {
        if (formData.role === undefined) {
          roleSelected = this.userEdit.role;
        } else {
          roleSelected = formData.role.parent
            ? AppConstant.ROLES.ENGINEER.label
            : formData.role.data;
        }
      } else {
        roleSelected = formData.role.parent
          ? formData.role.parent.data
          : formData.role.data;
      }

      let disciplineIdSelected =
        roleSelected === AppConstant.ROLES.ENGINEER.label
          ? formData.role.data
          : '';

      let payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        role: roleSelected,
        psl: formData.psl.code,
        country: formData.country.code === 'US' ? 'USA' : formData.country.code,
        remoteCenterList: formDataRemoteCenter,
        timezone: formData.timezone.code,
        phone: `${formData?.flag?.dial_code || formData.dialCode} ${
          formData.phone
        }`,
        mail: formData.mail,
        disciplineId: disciplineIdSelected, //styleClass === 'lowestChild' ? formData.role.data : '',
        mailboxList: formDataMailBox,
      };

      if (this.userEdit) {
        //Edit user
        let permissionPayload!: PermissionGroup;
        switch (payload.role) {
          case AppConstant.ROLES.ADMIN.label:
            permissionPayload = this.ADMIN_DEFAULT_PERMISSION;
            break;
          case AppConstant.ROLES.MANAGER.label:
            permissionPayload = this.MANAGER_DEFAULT_PERMISSION;
            break;
          case AppConstant.ROLES.ENGINEER.label:
            permissionPayload = this.ENGINEER_DEFAULT_PERMISSION;
            break;
          case AppConstant.ROLES.VIEWER.label:
            permissionPayload = this.VIEWER_DEFAULT_PERMISSION;
            break;
        }

        let sources: Observable<any>[] = [
          this._userInfoService.editUser(formData.halId.id, payload),
        ];

        if (this.userEdit.role !== payload.role) {
          sources.push(
            this._userInfoService.updatePermission(
              formData.halId.id,
              permissionPayload
            )
          );
        } else if (
          payload.role === AppConstant.ROLES.MANAGER.label &&
          !AppHelper.UtileFunctions.areArraysEqual(
            payload.remoteCenterList,
            this.userEdit.remoteCenterList.map((remoteCenter: any) => {
              return { remoteCenterId: remoteCenter.remoteCenterId };
            })
          )
        ) {
          sources.push(
            this._userInfoService.updatePermission(
              formData.halId.id,
              permissionPayload
            )
          );
        } else if (
          payload.role === AppConstant.ROLES.ENGINEER.label &&
          !AppHelper.UtileFunctions.areArraysEqual(
            payload.mailboxList,
            this.userEdit.mailboxList.map((mail: any) => {
              return { mailboxId: mail.mailboxId };
            })
          )
        ) {
          sources.push(
            this._userInfoService.updatePermission(
              formData.halId.id,
              permissionPayload
            )
          );
        }

        forkJoin(sources).subscribe({
          next: ([updateUser, addPermission]) => {
            if (updateUser || addPermission) {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Update User',
                content: 'User was updated successfully!',
              });
              this.requestReloadPage.emit({
                flag:true,
                userId: this.userEdit.id,
                data: updateUser.data,
                action: "EDIT"
              });
            }
          },
          error: (error: any) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Update User',
              content: error?.message || error,
            });
            this.isLoading = false;
          },
        });
      } else {
        let permissionPayload!: PermissionGroup;
        switch (payload.role) {
          case AppConstant.ROLES.ADMIN.label:
            permissionPayload = this.ADMIN_DEFAULT_PERMISSION;
            break;
          case AppConstant.ROLES.MANAGER.label:
            permissionPayload = this.MANAGER_DEFAULT_PERMISSION;
            break;
          case AppConstant.ROLES.ENGINEER.label:
            permissionPayload = this.ENGINEER_DEFAULT_PERMISSION;
            break;
          case AppConstant.ROLES.VIEWER.label:
            permissionPayload = this.VIEWER_DEFAULT_PERMISSION;
            break;
        }

        this._userInfoService
          .addUser(formData.halId.id, payload)
          .pipe(
            catchError((error: any) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Add User',
                content: error?.message || error,
              });
              this.isLoading = false;
              return error;
            }),
            switchMap((res: any) => {
              if (res) {
                return forkJoin([
                          of(res),
                          this._userInfoService
                            .updatePermission(formData.halId.id, permissionPayload)
                            .pipe(
                              catchError((error: any) => {
                                this._notificationService.setMessage({
                                  type: AppConstant.MESSAGE_TYPE.WARNING,
                                  header: 'Update perrmission user',
                                  content: error?.message || error,
                                });
                                this.isLoading = false;
                                return error;
                              })
                        )]);
              }
              return of(null);
            })
          )
          .subscribe({
            next: (res: any) => {
              const [userInfo, permission] = res;
              if (userInfo && permission) {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.SUCCESS,
                  header: 'Add User',
                  content: 'User was added successfully!',
                });
                this.requestReloadPage.emit({
                  flag: true,
                  userId: "",
                  data: userInfo?.data,
                  action: "CREATE"
                });
              }
            },
          });
      }
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'User Not Found',
        content:
          "Can't find the right user in AD Group Please contact the Director Or re-enter to search for Halliburton ID",
      });
      this.isLoading = false;
    }
  }

  getRefresh(controlName: string) {
    const control = this.formUserGroup.get(controlName);
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  closeEditUserDialog() {
    if (this.formUserGroup.touched || !this.formUserGroup.pristine) {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    } else {
      this.hideFormUser.emit(!this.displayFormUser);
    }
  }

  closeDialogForm() {
    this._confirmService.clearDialog();
    this.hideFormUser.emit(!this.displayFormUser);
  }

  customFilterChanged(e: any) {
    //
    //
  }
  addPrefixNumber(e: any) {
    this.formUserGroup
      .get('dialCode')
      ?.setValue(e.value.replace_dial_code || e.value.dial_code);
  }

  // This function using for custom validators
  private customCorrectField(
    fieldNameKeys: any,
    regex: string,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;

        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        const reg = new RegExp(regex);

        //

        let currentValue = currentControl?.value
          ? currentControl?.value.toString()
          : '';

        if (reg.test(currentValue ? currentValue.trim() : '')) {
          return null;
        }
        return { [keyValidator]: true };
      }
      return null;
    };
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formUserGroup.get(controlName);

    //

    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const controlValue = this.formUserGroup.get(controlName)?.value;

    const errorObj = this.formUserGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  private userNonExist(control: FormControl) {
    const userInfor = control.value;
    //
    return userInfor?.id ? null : { userNonExist: true };
  }

  // Because when dont have any chareacter another error message of required will show
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    //
    formGroupName.controls[controlName].patchValue(control?.value.trim());
  }

  onNodeExpand(event: any) {
    if (event.node.children === undefined) event.node.expanded = false;
  }

  onButtonClickDialog(
    option: OptionButtonType,
    dialogType: DialogAction
  ): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }
  onDestroy(): void {}
}
