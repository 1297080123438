import { Pipe, PipeTransform } from '@angular/core';
import { isObject } from 'lodash';

@Pipe({
  name: 'eventHierarchyString',
})
export class StringEventHierarchyPipe implements PipeTransform {
  /**
   * This
   * Transforms the input value into a formatted string.
   * If the input value is empty or undefined, it returns 'N/A'.
   * If the input value can be parsed as JSON and is an array, it extracts the 'label' property from each object and returns them joined by commas.
   * Example:
   *  {
        "event_id": "EV72",
        "data": "Limited Data Exchange",
        "label": "Limited Data Exchange",
        "hierarchy": "Data Acquisition/Limited Data Exchange"
      } 
   * will return ===> Data Acquisition/Limited Data Exchange
   * Otherwise, it returns the original value. (Input value can not parsed as JSON, it is a string then returns this string value.)
   *
   * @param value - The input value to be transformed.
   * @returns The transformed string.
   */

  transform(value: any): any {
    if (!value) {
      return 'N/A';
    } else {
      if (value?.hierarchy) {
        return value?.hierarchy;
      }

      try {
        const data = JSON.parse(value);
        if (data.hasOwnProperty('hierarchy')) {
          return data.hierarchy;
        } else {
          return 'N/A';
        }
      } catch (error) {
        return value;
      }
    }
  }
}
