import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpCenterService } from './help-center.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { TabView } from 'primeng/tabview';
import { Location } from '@angular/common';
@Component({
  selector: 'app-help-video',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit, OnDestroy {
  TOOLTIP_HELP_CENTER = AppConstant.TOOLTIP_HELP_CENTER;
  roleView: string = '';
  user: any = {};
  visibleReportIssue: boolean = false;
  countVideos: number = 0;
  indexTabview: number = 0;
  tabView = ['video', 'guide', 'tutorial', 'release note', 'term'];
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('tabViewForm') tabViewForm!: TabView;

  constructor(
    private _router: Router,
    private location: Location,
    private _userInfoService: UserInfoService,
    private _helpCenterService: HelpCenterService,
    private _notificationService: NotificationService,
    private _dialogService: DialogService,
    private _route: ActivatedRoute,
    private _location: Location,
  ) {
    this.indexTabview =
      this._router.getCurrentNavigation()?.extras.state?.index || 0;

    this._route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.indexTabview = this.tabView.findIndex((tab: string) => tab === params.tab)
      } else {
        this.indexTabview = 0;
      }
    });
  }

  ngOnInit(): void {
    
    this._route.queryParams.subscribe((params) => {
      const current = params.tab ? params.tab : this.tabView[0];
      const index = this.tabView.findIndex((element) => element === current);
      this.indexTabview = index === -1 ? 0 : index;
    });

    this._helpCenterService.subjectRedirectToTermsOfUse
      .pipe(takeUntil(this.destroy$))
      .subscribe((index: number) => {
        this.indexTabview = index;
      });

    this._userInfoService.userSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        if (user) {
          this.user = user;
          this.roleView = user.role;
        }
      },
    });
  }

  public closeHelpCenter(): void {
    this._router.navigate(['/home']);
  }

  confirm(type: string) {
    switch (type) {
      case 'report-issue': {
        this.visibleReportIssue = true;
        break;
      }
    }
  }

  sendReportIssue() {}

  showReportIssueDialog(): void {
    const ref = this._dialogService.open(ReportIssueComponent, {
      header: 'Report An Issue',
      width: '30.25rem',
      data: {},
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose.subscribe((payload: FormData) => {
      if (payload) {
        this._helpCenterService.sendReportIssue(payload).subscribe({
          next: (res) => {
            if (res)
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Report Issue',
                content: `Your issue report was sent successfully!`,
              });
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  updateVideoCount(event: number): void {
    this.countVideos = event;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  tabviewChangeIndex(e: any) {
    this.indexTabview = e;
    const url = this._router.createUrlTree([], 
      {
        relativeTo: this._route,
        queryParams: { tab: this.tabView[this.indexTabview] }
      }).toString()
    this.location.go(url);
  }
}
