import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, catchError, finalize, forkJoin, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { MailBox } from 'src/app/shared/interface/mailbox/mailbox.interface';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { MailBoxService } from 'src/app/shared/services/mailbox.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Component({
  selector: 'app-mailbox-form',
  templateUrl: './mailbox-form.component.html',
  styleUrls: ['./mailbox-form.component.scss'],
})
export class MailboxFormComponent extends BaseComponent {
  @Input()
  displayFormMailBoxDialog: boolean = false;

  @Output()
  hideFormMailBoxDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  reloadMailBoxManagement: EventEmitter<boolean> = new EventEmitter<boolean>(); // emit both true/false will reload table but true will reload contractor dropdown

  @Input() mailBoxEdit: any;

  formMailBoxTitle: string = 'Add Mailbox';

  AppHelper = AppHelper;

  public formMailBoxGroup!: FormGroup;

  public fieldNameKeys = {
    functionalMailbox: 'functionalMailbox',
    remoteCenter: 'remoteCenter',
    discipline: 'discipline',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
  };

  functionalMailboxes: MailBox[] = [];
  remoteCenters: any[] = [];
  disciplines: any[] = [];

  filtersLoaded?: Promise<boolean>;

  isViewer: boolean = false;
  isLoading: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _mailBoxService: MailBoxService,
    private _notificationService: NotificationService,
    private _builder: FormBuilder,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    this.isLoading = true;

    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
        }
        this.loadInitialData();
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData() {
    this.filtersLoaded = Promise.resolve(true);
    const sourceObs: Observable<any>[] = [
      this._mailBoxService.getMailBoxs(),
      this._mailBoxService.getRemoveCenter(),
      // this._mailBoxService.getDiscipline()
    ];

    if (this.mailBoxEdit)
      sourceObs.push(
        this._mailBoxService.getDisciplineByRemoteCenterId(
          this.mailBoxEdit.remoteCenterId
        )
      );
    forkJoin(sourceObs)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: ([mailbox, remote, discipline]) => {
          this.functionalMailboxes = mailbox;

          this.remoteCenters = remote;

          if (discipline) {
            this.disciplines = discipline;
          }

          if (!this.mailBoxEdit) {
            this.functionalMailboxes = this.functionalMailboxes.filter(
              (mailbox: any) => {
                return mailbox.remoteCenter === null;
              }
            );

            this.buildFormAddMailBox();
          } else {
            this.formMailBoxTitle = `Edit MailBox: ${this.mailBoxEdit.mailboxName}`;
            this.functionalMailboxes = this.functionalMailboxes.filter(
              (mailbox: any) => {
                return (
                  mailbox.remoteCenter === null ||
                  mailbox.mailboxName === this.mailBoxEdit.mailboxName
                );
              }
            );

            this.buildFormEditMailBox(this.mailBoxEdit);
          }
        },
      });
  }

  buildFormAddMailBox() {
    this.formMailBoxGroup = this._builder.group({
      [this.fieldNameKeys.functionalMailbox]: [null, [Validators.required]],
      [this.fieldNameKeys.remoteCenter]: [null, [Validators.required]],
      [this.fieldNameKeys.discipline]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
    });
  }

  buildFormEditMailBox(mailBoxEdit: MailBox) {
    const functionalMailbox = this.functionalMailboxes.find((mailbox: any) => {
      return mailbox.mailboxName === mailBoxEdit.mailboxName;
    });

    const remoteCenter = this.remoteCenters.find((mailbox: any) => {
      return mailbox.remoteCenterId === mailBoxEdit.remoteCenter.remoteCenterId;
    });

    const discipline = this.disciplines.filter((dis: any) =>
      mailBoxEdit.disciplineList
        .map((item) => item.disciplineId)
        .includes(dis.disciplineId)
    );

    this.formMailBoxGroup = this._builder.group({
      [this.fieldNameKeys.functionalMailbox]: [
        {
          value: functionalMailbox,
          disabled: true,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.remoteCenter]: [remoteCenter, [Validators.required]],
      [this.fieldNameKeys.discipline]: [discipline, [Validators.required]],
    });
  }

  selectItem(event: any, controlName: string) {
    const remoteCenter = this.formMailBoxGroup.get('remoteCenter');
    const discipline = this.formMailBoxGroup.get('discipline');

    switch (controlName) {
      case 'remoteCenter':
        if (event.value) {
          discipline?.markAsUntouched();
          discipline?.markAsPristine();
          discipline?.enable();
          this.loadNewDataDiscipline(true, event.value.remoteCenterId);
        } else {
          discipline?.disable();
          discipline?.setValue('');
          this.loadNewDataDiscipline(false);
        }
        break;
      default:
        break;
    }
  }
  loadNewDataDiscipline(isCallNewData: boolean, remoteCenterId?: string) {
    if (isCallNewData && remoteCenterId) {
      this.isLoading = true;

      this._mailBoxService
        .getDisciplineByRemoteCenterId(remoteCenterId)
        .pipe(
          takeUntil(this.destroy$),
          catchError(AppHelper.UtileFunctions.handleError),
          finalize(() => (this.isLoading = false))
        )
        .subscribe({
          next: (res: any) => {
            this.disciplines = res;
            this.formMailBoxGroup
              .get(this.fieldNameKeys.discipline)
              ?.setValue(null);
          },
          error: (error) => {
            console.error(error);
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Loading Discipline Data',
              content: error?.message || error,
            });
          },
        });
    } else {
      this.disciplines = [];
    }
  }

  submitMailBox() {
    const payload = this.formMailBoxGroup.getRawValue();

    if (!this.mailBoxEdit) {
      this._mailBoxService
        .addMailbox(payload.functionalMailbox.mailboxId, payload)
        .pipe(
          catchError(AppHelper.UtileFunctions.handleError),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (res: any) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Add Mailbox',
              content: 'Mailbox was added successfully!',
            });
            this.requestReloadManlBoxManagement();
          },
          error: (error) => {
            console.error(error);
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Added Mailbox',
              content: error?.message || error,
            });
          },
        });
    } else {
      const isChangedRemoteCenter = this.mailBoxEdit.remoteCenter.remoteCenterId === payload.remoteCenter.remoteCenterId
      if (isChangedRemoteCenter) {
        delete payload.remoteCenter;
      }
      const isChangedDiscipline = this.compareDisciplines(this.mailBoxEdit.disciplineList, payload.discipline)
      if (isChangedDiscipline) {
        delete payload.discipline;
      }


      this._mailBoxService
        .updateMailbox(payload.functionalMailbox.mailboxId, payload)
        .pipe(
          catchError(AppHelper.UtileFunctions.handleError),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (res: any) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Update Mailbox',
              content: 'Mailbox was updated successfully!',
            });
            this.requestReloadManlBoxManagement();
          },
          error: (error) => {
            console.error(error);
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Update Mailbox',
              content: error?.message || error,
            });
          },
        });
    }
  }
  requestReloadManlBoxManagement() {
    this.displayFormMailBoxDialog = false;
    this.hideFormMailBoxDialog.emit(this.displayFormMailBoxDialog);
    this.reloadMailBoxManagement.emit(true);
  }

  removeTheDraft() {
    if (this.formMailBoxGroup.pristine && !this.formMailBoxGroup.touched) {
      this.displayFormMailBoxDialog = !this.displayFormMailBoxDialog;
      this.hideFormMailBoxDialog.emit(this.displayFormMailBoxDialog);
    } else {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    }
  }

  closeDialogForm() {
    this.displayFormMailBoxDialog = !this.displayFormMailBoxDialog;
    this.hideFormMailBoxDialog.emit(this.displayFormMailBoxDialog);
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  onDestroy(): void {}

  compareDisciplines = (arr1: any[], arr2: any[]): boolean =>{
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    const temp1 = arr1.sort((a, b) => { return a.disciplineId.localeCompare(b.disciplineId)})
    const temp2 = arr2.sort((a, b) => { return a.disciplineId.localeCompare(b.disciplineId)})
  
    // Iterate through the arrays and compare each element
    for (let i = 0; i < temp1.length; i++) {
      if (temp1[i].disciplineId !== temp2[i].disciplineId) {
        return false;
      }
    }
    // If all checks pass, the arrays are equal
    return true;
  }
}
