import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PassingMailboxService {
  private passingMailbox = new BehaviorSubject<any>(null);

  constructor() {}

  updatePassingMailbox(dataMailbox: any) {
    this.passingMailbox.next(dataMailbox);
  }
  
  getPassingMailbox() {
    return this.passingMailbox.getValue();
  }
}
