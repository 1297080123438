import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkRiskLength',
})
export class StringCheckRiskLength implements PipeTransform {
  transform(value: any): any {
    if (value === 'Risk Event') return value;

    let arrSplit = value.split(' | ');
    let storeChar = 0;
    let isOverLimitCharacter = false;
    let limitCharacter = 32;
    let checkPoint = 0;

    for (let i = 0; i < arrSplit.length; i++) {
      //loop but remove last element
      storeChar = storeChar + arrSplit[i].length;

      if (storeChar <= limitCharacter) {
        checkPoint = i;
      } else {
        isOverLimitCharacter = true;
        break;
      }
    }

    let tempArr = [];
    if (isOverLimitCharacter && !checkPoint) {
      // Text overflow at the beginning of the element
      if (arrSplit.length <= 1) {
        return arrSplit[0].slice(0, 32) + '...';
      } else {
        return arrSplit[0].slice(0, 32) + '... +' + (arrSplit.length - 1);
      }
    } else {
      // Text will not overflowing
      tempArr = arrSplit.slice(0, checkPoint + 1);
      if (storeChar >= limitCharacter) {
        return tempArr.join(', ') + '... +' + (arrSplit.length - checkPoint - 1);
      } else {
        return tempArr.join(', ');
      }
    }
  }
}
