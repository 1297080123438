import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-form-release-note',
  templateUrl: './form-release-note.component.html',
  styleUrls: ['./form-release-note.component.scss']
})
export class FormReleaseNoteComponent implements OnInit {
  WELLCARE_VERSION = AppConstant.WELLCARE_VERSION;
  regex: RegExp = /^\d+(\.\d+){2}$/;
  oldValue = '';
  createReleaseForm: FormGroup  = this.fb.group({
    version: ['', []],
  });

  @Input() isAdmin: boolean = false;
  @Input() dataEdit: any = null;

  @Output() action:EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('newRelease') newRelease!: ElementRef;  
  
  public messageErrors: any = {
    format: 'Use the following format when defining the release version: <major>.<minor>.<patch>',
  };
  constructor(
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createReleaseForm = this.fb.group({
      version: ['', [this.customCorrectField(
        this.regex,
        'version',
        'format'
      ),]],

    });
    
    if (this.dataEdit?.categoryName) {
      this.oldValue = this.dataEdit?.categoryName;
      this.createReleaseForm.get('version')?.setValue(this.dataEdit?.categoryName);
    }
    
    setTimeout(() => {
      this.newRelease?.nativeElement.focus();
    }, 0)
  }

  cancelSaveRelease() {
    this.action.emit({
      action: 'CANCEL',
    });
    this.createReleaseForm.reset();
  }

  saveRelease() {
    if (!this.createReleaseForm.valid) return;

    const value = this.createReleaseForm.get('version')?.value.trim();
    if (this.oldValue === value) {
      this.cancelSaveRelease()
    }
    
    if (this.dataEdit) {
      this.dataEdit.categoryName = value;
      this.action.emit({
        action: 'EDIT',
        data: this.dataEdit
      })
    } else {
      this.action.emit({
        action: 'NEW',
        data: {
          categoryName: value}
      })
    }
  }

  private customCorrectField(
    regex: RegExp,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;
        const currentControl = _formGroup.get(controlName);

        let currentValue = currentControl?.value
          ? currentControl?.value.toString()
          : '';

        if (regex.test(currentValue ? currentValue.trim() : '')) {
          return null;
        }
        return {[messageErrorKey]: true };
      }
      return null;
    };
  }

  public isInvalidControl(controlName: string): boolean {
    const control = this.createReleaseForm.get(controlName);
    if (!control) {
      return false;
    }
    
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const errorObj = this.createReleaseForm.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  onKeyDown(event: any) {
    if (event.target.value) {
      const regex: RegExp = /[0-9.]+/g;
      const versionControl = this.createReleaseForm.get('version')
      const str: string = event.target.value;

      const matches = str.match(regex);
      const fixedStr = matches ? matches.join('') : '';

      versionControl?.setValue(fixedStr);
    }
  }
}
