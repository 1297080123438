import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Optional,
  Renderer2,
  forwardRef,
} from '@angular/core';
import {
  COMPOSITION_BUFFER_MODE,
  DefaultValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { AppHelper } from '../../utilities/app.helper';

@Directive({
  selector: '[appUnitInput]',
  providers: [],
})
export class UnitInputDirective extends DefaultValueAccessor {
  @Input() unitSystem: any;
  @Input() unitClass: string = 'mm';
  @Input() referenceUnit: string = 'API'; // Current Project Unit;
  @Input() currentUnit: string | undefined = undefined; // Existing object needs to reference and convert Unitsytems: Interval || Run || Intervention;;
  @Input() previousUnitSystem: string = '';

  @Input() unitType: string = 'diameter';

  @Input() factorDiameterNumber: number = 0;
  @Input() factorDepthNumber: number = 0;

  @Input() digitDiameterNumber: number = 0;
  @Input() digitDepthNumber: number = 0;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private _inputEl: ElementRef,
    private _ngControl: NgControl
  ) {
    super(renderer, elementRef, true);
  }

  ngOnInit() {
    let formUnitOld: any;
    let formUnitNew: any;
    let unitConverterDiameter = 0;
    let unitConverterDepth = 0;
    let formUnit: any;
    let digitDiameter = 0;
    let digitDepth = 0;

    if (this.currentUnit) {
      if (this.currentUnit && this.currentUnit !== this.referenceUnit) {
        formUnitOld = AppHelper.MathFunctions.findUnit(
          this.currentUnit,
          this.unitSystem.unit
        );

        formUnitNew = AppHelper.MathFunctions.findUnit(
          this.referenceUnit,
          this.unitSystem.unit
        );

        for (const key in this.unitSystem.converter) {
          let combineConvertersDiameterKey =
            formUnitOld.Diameter + '2' + formUnitNew.Diameter;
          let combineDepthDiameterKey =
            formUnitOld.Depth + '2' + formUnitNew.Depth;

          if (key === combineConvertersDiameterKey.toLowerCase()) {
            unitConverterDiameter = this.unitSystem.converter[key];
          }

          if (key === combineDepthDiameterKey.toLowerCase()) {
            unitConverterDepth = this.unitSystem.converter[key];
          }
        }
      }
    }
    formUnit = AppHelper.MathFunctions.findUnit(
      this.referenceUnit,
      this.unitSystem.unit
    );
    digitDiameter = AppHelper.MathFunctions.getDigitUnitSystems(
      formUnit.Diameter
    );
    digitDepth = AppHelper.MathFunctions.getDigitUnitSystems(formUnit.Depth);

    this.factorDiameterNumber = unitConverterDiameter;
    this.factorDepthNumber = unitConverterDepth;

    this.digitDiameterNumber = digitDiameter;
    this.digitDepthNumber = digitDepth;

    //
    const control = this._ngControl.control;
    if (control) {
      const inputValue = control.value;
      const parsedValue = parseFloat(
        inputValue?.toString().replace(/,/g, '') || ''
      );

      if (!isNaN(parsedValue)) {
        let result = 0;
        if (this.unitType === 'diameter') {
          result = AppHelper.MathFunctions.financial(
            this.factorDiameterNumber === 0
              ? parsedValue * 1
              : parsedValue * this.factorDiameterNumber,
            this.digitDiameterNumber
          );
        } else if (this.unitType === 'depth') {
          result = AppHelper.MathFunctions.financial(
            this.factorDepthNumber === 0
              ? parsedValue * 1
              : parsedValue * this.factorDepthNumber,
            this.digitDepthNumber
          );
        }
        control.setValue(
          result.toLocaleString('en-US', {
            useGrouping: true,
          })
        );
      } else {
        control.setValue(null);
        // control.markAsDirty();
      }
    }
  }
}
