import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
  forkJoin,
  BehaviorSubject,
  finalize,
} from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {
  ProjectData,
  RigInterval,
  RigWellbore,
  WellList,
} from 'src/app/shared/interface/rig.interface';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Menu } from 'primeng/menu';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';


@Component({
  selector: 'app-home-rig-item-interval',
  templateUrl: './home-rig-item-interval.component.html',
  styleUrls: ['./home-rig-item-interval.component.scss'],
})
export class HomeRigItemIntervalComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  projectData!: ProjectData;

  @Input()
  restoreWellList: WellList[] = [];

  @Input()
  restoreRigListDataWellbore: RigWellbore[] = [];
  rigListDataWellbore: RigWellbore[] = [];

  @Input()
  restoreRigListDataInterval: RigInterval[] = [];
  rigListDataInterval: any[] = [];

  @Input() rigId: string = '';

  @Input() isViewer: boolean = false;
  
  @Input() isEngineer: boolean = false;
  
  UNIT_SYSTEM: any = UNIT_SYSTEM;

  rigListIntervalColumn: any[] = [
    { field: 'intervalName', header: 'Interval' },
    { field: 'openHoleDiameter', header: 'Hole Size' },
    { field: 'features', header: 'x' },
  ];


  emptyMsg = '';

  counterResult: number = 0;

  displayFormInterval: boolean = false;

  // Variable pass to edit popup
  editInterval: RigInterval | null = null;
  // Variable of Form
  @ViewChild('searchForm') searchForm!: NgForm;
  @ViewChild('menu') menu: any;

  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  // Unit chacracter
  // Variable of Form
  public formIntervalItem!: FormGroup;

  public fieldNameKeys = {
    project: 'project',
    well: 'well',
    wellbore: 'wellbore',
  };

  AppHelper = AppHelper;

  intervalDelete: any;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _activedRoute: ActivatedRoute,
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    if (this.restoreRigListDataInterval) {
      this.rigListDataInterval = [...this.restoreRigListDataInterval ];
    }
    
    this.buildForm();
  }

  ngAfterViewInit(): void {}

  bindingWellbore() {
    const selectedWellbore = this.formIntervalItem.get('wellbore')?.value;
    if (!selectedWellbore) return;

    const wellboreSelect = this.restoreRigListDataWellbore.find(
      (wellbore: RigWellbore) => wellbore.wellboreId === selectedWellbore.wellboreId);

    if (wellboreSelect) {
      // auto select well by wellbore
      const wellId = wellboreSelect.wellId;
      const filterWell = this.restoreWellList.find(
        (well: any) => well.id === wellId
      );      
      this.formIntervalItem.get('well')?.patchValue(filterWell);

      this.formIntervalItem.get('wellbore')?.patchValue(wellboreSelect);
      this.rigListDataWellbore = this.restoreRigListDataWellbore.filter(
        (wellbore: any) => wellbore.wellId === wellboreSelect?.wellId
      )

      this.filterIntervalList();
    } else {
      this.getRefresh('wellbore');
      this.rigListDataInterval = [];
    }
  }

  bindingWell(event?: any) {
    if (this.formIntervalItem.get('well')?.value) {
      const wellId = this.formIntervalItem.get('well')?.value.id;

      this.rigListDataWellbore = this.restoreRigListDataWellbore.filter(
        (wellbore: any) => wellbore.wellId === wellId
      );;
      this.rigListDataInterval = [];
      this.getRefresh('wellbore');
    }
  }

  onClearWell() {
    this.getRefresh('well');
    this.rigListDataWellbore = [];

    this.onClearWellbore();
  }

  onClearWellbore() {
    this.getRefresh('wellbore');

    this.rigListDataInterval = [];
    this.emptyMsg = `Please choose wellbore  value`;
  }

  buildForm(wellIdDropDownId?: string, wellboreIdDropDownId?: string) {

    if (wellIdDropDownId && wellboreIdDropDownId) {
      this.formIntervalItem = this._builder.group({
        [this.fieldNameKeys.project]: [
          {
            value: this.projectData.currentName || this.projectData.originalName,
            disabled: true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.well]: [
          this.restoreWellList.find((well: any) => well.id === wellIdDropDownId),
          [Validators.required],
        ],
        [this.fieldNameKeys.wellbore]: [
          this.rigListDataWellbore.find(
            (wellbore: any) => wellbore.wellboreId === wellboreIdDropDownId
          ),
          [Validators.required],
        ],
      });

      if (wellboreIdDropDownId) {
        this.rigListDataInterval = [...this.restoreRigListDataInterval];
        this.rigListDataInterval = this.rigListDataInterval.filter(
          (interval: any) => {
            return interval.wellboreId === wellboreIdDropDownId;
          }
        );

        this.counterResult = this.rigListDataInterval.length;
        this.emptyMsg =
          this.counterResult === 0 ? `There is no interval created!` : '';

        const formValue = this.formIntervalItem.valueChanges;
        formValue!
          .pipe(
            takeUntil(this.destroy$),
            debounceTime(300),
            distinctUntilChanged(),
            tap((filter) => {
              if (filter.well && filter.wellbore) {
                this.rigListDataInterval = this.restoreRigListDataInterval.filter(
                  (interval: any) => {
                    return interval.wellboreId === filter.wellbore.id;
                  }
                );

                this.counterResult = this.rigListDataInterval.length;
                this.emptyMsg =
                  this.counterResult === 0 ? `There is no interval created!` : '';
              }
            })
          )
          .subscribe((res) => {});
      }
    } else {
      // check lại khi có chức năng all false và recently
      let wellboreActive_wellboreRecently = this.restoreRigListDataWellbore.find(
        (welbore: any) => {
          return welbore.wellboreIsActive;
        }
      );

      // Nếu có 1 wellbore đang actice thì sẽ autofil 2 chú bé này vào dropdown và filter table
      if (wellboreActive_wellboreRecently) {
        this.formIntervalItem = this._builder.group({
          [this.fieldNameKeys.project]: [
            {
              value: this.projectData.currentName || this.projectData.originalName,
              disabled: true,
            },
            [Validators.required],
          ],
          [this.fieldNameKeys.well]: [
            this.restoreWellList.find(
              (well: any) => well.id === wellboreActive_wellboreRecently?.wellId
            ),
            [Validators.required],
          ],
          [this.fieldNameKeys.wellbore]: [
            this.restoreRigListDataWellbore.find(
              (wellbore: any) =>
                wellbore.wellboreId === wellboreActive_wellboreRecently?.wellboreId
            ),
            [Validators.required],
          ],
        });

        this.rigListDataWellbore = this.restoreRigListDataWellbore.filter(
          (wellbore: any) => {
            return (
              wellbore.wellId ===
              wellboreActive_wellboreRecently?.wellId
            );
          }
        );
      } else {
        this.formIntervalItem = this._builder.group({
          [this.fieldNameKeys.project]: [
            {
              value: this.projectData.currentName || this.projectData.originalName,
              disabled: true,
            },
            [Validators.required],
          ],
          [this.fieldNameKeys.well]: [null, [Validators.required]],
          [this.fieldNameKeys.wellbore]: [null, [Validators.required]],
        });

        this.rigListDataInterval = [];
        this.counterResult = this.rigListDataInterval.length;
        this.emptyMsg =
          this.counterResult === 0
            ? `No results found, please filter by well and wellbore`
            : '';
      }

      this.filterIntervalList();
    }
  }

  filterIntervalList() {
    const formValue = this.formIntervalItem.value;

    if (formValue.well && formValue.wellbore) {
      this.rigListDataInterval = this.restoreRigListDataInterval.filter(
        (interval: any) => {
          return interval.wellboreId === formValue.wellbore.wellboreId;
        }
      );
    } else if (!formValue.well || !formValue.wellbore) {
      // If 2 dropdown filter dont have value --> restore data --> show empty
      this.rigListDataInterval = [];
    }

    // Set counter item
    this.counterResult = this.rigListDataInterval.length;

    // Alert if dont have data
    this.emptyMsg =
      this.counterResult === 0 ? `There is no interval created!` : '';
  }

  showContextMenu(event: any, inputData: RigInterval) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(intervalRow: RigInterval): any {
    const canDelete = this._userInfoService.hasPermission(MODULE_NAME.INTERVAL, USER_PERMISSION.DELETE);

    return [
      {
        label: 'Edit',
        icon: 'c-icons edit-icon',
        command: () => {
          this.displayFormInterval = true;
          this.editInterval = this.rigListDataInterval.find(
            (interval: any) => interval.intervalId === intervalRow.intervalId
          );
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        visible: canDelete,
        disabled: this.isViewer,
        command: () => {
          this.intervalDelete = intervalRow;

          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this interval (${intervalRow.intervalName}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete it.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  addInverval() {
    const me = this;
    if (!this.projectData.curUnit) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Add Interval',
        content: 'You need to add Unit System for Project',
      });
      return;
    }
    if (
      !this.formIntervalItem.get('well')?.value ||
      !this.formIntervalItem.get('wellbore')?.value
    ) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Add Interval',
        content:
          'You need to select the value of well and wellbore before creating a new interval',
      });
    } else {
      this.displayFormInterval = true;
      this.editInterval = null;
    }
  }

  hideEditInterval(event: any) {
    this.displayFormInterval = false;
  }

  getRefresh(controlName: string) {
    const control = this.formIntervalItem.get(controlName);
    control?.setValue('');
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Delete':
            const deleteId = this.intervalDelete.intervalId || '';
            if (!deleteId) {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Information',
                content: 'The ID of the Interval to be deleted could not be found',
              });
              break;
            }
            this.deleteInterval(deleteId);
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  reloadEditInterval(arrayWellId_WellboreId: any) {
    this.rigListDataInterval = [];

    this._activedRoute.paramMap
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.isLoading = true),
        map((params) => params.get('id')),
        switchMap((id) => {
          this.rigId = id || '';
          return this._homeService.getIntervals(id || '').pipe(
            finalize(() => this.isLoading = false)
          )
        })
      )
      .subscribe((dataList: any) => {
        if (dataList && dataList.data) {
          if (dataList.data.length > 0) {
            this.restoreRigListDataInterval = [...dataList.data];
            this.bindingWellbore();
          }
        }
      });
  }

  deleteInterval(intervalId: string) {
    this.isLoading = true;
    this._homeService
      .deleteInterval(intervalId)
      .pipe(
        tap(() => this.isLoading = false),
      )
      .subscribe({
        next: (res: any) => {
          this.restoreRigListDataInterval = this.restoreRigListDataInterval.filter(
            (interval: RigInterval) => interval.intervalId !== intervalId
          );
          this.filterIntervalList();

          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Delete interval',
            content: `${this.intervalDelete.intervalName} was deleted successfully!`,
          });
        },
        error: (err: any) => {
          err &&
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Delete interval',
              content: err,
            });
          this.isLoading = false;
        },
      });
  }

  onDestroy(): void {}
}
