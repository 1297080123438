<div class="rig-list">
  <app-module-header
    [backPortal]="false"
    backPortalLabel="Nothing Render Because backPortal === false"
    headerTitle="Rig Management"
    [buttonFearture]="true"
    buttonFeartureLabel="Add Rig"
    [bottomMargin]="true"
    (onClickButton)="displayAddFormRigDialog = true"
  ></app-module-header>

  <form #searchRigForm="ngForm" style="max-width: 1380px">
    <div class="project__filter rig-list__filter">
      <div class="d-flex" style="width: 1260px">
        <div class="rig-list__filter--input" style="width: 320px">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              type="text"
              pInputText
              placeholder="Search by rig"
              class="search-box"
              maxlength="50"
              name="searchString"
              (keyup)="keyupSearch($event)"
              ngModel
            />
          </span>
        </div>

        <div class="rig-list__filter--input" style="min-width: 290px">
          <p-treeSelect
            #treeEvent
            [options]="rigTypes"
            placeholder="Select type"
            [showClear]="true"
            scrollHeight="200px"
            [propagateSelectionDown]="true"
            class="custome-p-tree any-level"
            (onNodeSelect)="onOptionsSelected($event, '|')"
            (onNodeExpand)="onNodeExpand($event)"
            (onClear)="onClear($event)"
            name="rigType"
            ngModel
          >
            <ng-template pTemplate="value">
              {{ customLabel }}
            </ng-template>
          </p-treeSelect>
        </div>

        <div class="rig-list__filter--input" style="width: 100%; max-width: 578px;">
          <p-multiSelect
            #contractorDropdown
            [options]="contractorList"
            [showClear]="true"
            [resetFilterOnHide]="true"
            [filter]="true"
            [showToggleAll]="false"
            optionLabel="contractorName"
            optionValue="contractorId"
            placeholder="Select rig contractor"
            class="custom-p-multiselect custom-p-multiselect-rig-list"
            name="contractorString"
            scrollHeight="168px"
            (onClear)="onClearContractorDropdown()"
            (onPanelHide)="onPanelHideContractorDropdown()"
            ngModel
          >
          </p-multiSelect>
          <!-- (onChange)="countItemSelected($event, 'remoteCenter')"
          (onClear)="clearItemSelected()" -->
        </div>
      </div>
      <div class="rig-list__filter--counter">
        <span>Total: {{ rigListData.length || 0 }}</span>
      </div>
      <div
        class="rig-list__filter--text button-highlight cursor-pointer"
        (click)="clearFilter()"
      >
        <span>Clear all</span>
      </div>
    </div>

    <p-table
      [value]="rigListData"
      [columns]="rigListColumn"
      [scrollable]="true"
      [resizableColumns]="true"
      styleClass="p-datatable-striped custom-table"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 40px">
          <th
            *ngFor="let col of columns; last as isLast; let i = index"
            [pSortableColumn]="col.field"
            pResizableColumn
            [ngClass]="{
              'th-last': isLast,
              'th-document': i === 3 || i === 4,
              'th-document-long': i === 1,
              'th-default': i !== 1 && i !== 3 && i !== 4 && !isLast
            }"
          >
            <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
            </ng-container>

            <ng-template #showThNotLast>
              <div
                [pTooltip]="col.header"
                tooltipPosition="bottom"
                class="th-content"
              >
                {{ col.header }}
              </div>
              <div *ngIf="i !== 3 && i !== 4" class="header-sort-icon">
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                ></i>

                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                ></i>
              </div>
            </ng-template>
            <ng-template #showThLast></ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr>
          <td
            class="td-default"
            [pTooltip]="rowData.rigName"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.rigName }}
            </span>
          </td>
          <td class="document-cell-long">
            <span
              [pTooltip]="rowData?.rigTypeName?.rigTypeHierarchy"
              tooltipPosition="bottom"
            >
              {{ rowData?.rigTypeName?.rigTypeHierarchy || "N/A" }}
            </span>
          </td>
          <td
            class="td-default"
            [pTooltip]="rowData.contractorName"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.contractorName }}
            </span>
          </td>
          <td
            class="document"
            style="min-width: 100px; max-width: 140px"
            [pTooltip]="
              isViewer
                ? ''
                : rowData?.imageUrl
                ? decodeFileName(rowData.imageUrl)
                : 'Drag image to upload'
            "
            tooltipPosition="bottom"
          >
            <!-- Drag document to upload -->
            <!-- class them chu no image -->
            <div class="text-center">
              <div
                class="document-cell"
                [ngClass]="{
                  'non-file image': !rowData.imageUrl
                }"
              >
                <ng-container *ngIf="false">
                  <ng-container *ngIf="rowData.imageUrl">
                    <ng-container *ngIf="!rowData.imageBlob">
                      <div style="margin-top: -4px">
                        <div
                          class="content-progress-bar"
                          style="
                            height: inherit;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <span class="loader scale-down"></span>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="rowData.imageBlob">
                      <div style="margin-top: -4px">
                        <img
                          [src]="rowData.imageBlob | safe : 'url'"
                          alt="Cant Load Image"
                          class="rig-item__image-display"
                        />
                      </div>
                    </ng-container>
                  </ng-container>

                  <div *ngIf="!rowData.imageUrl" style="margin-top: -4px">
                    <img
                      [src]="
                        '../../../../assets/images/default-rigs/' +
                          rowData.rigTypeName.rigDefaultImage | safe : 'url'
                      "
                      alt="Cant Load Image"
                      class="rig-item__image-display"
                    />
                  </div>
                </ng-container>

                <ng-container *ngIf="rowData.imageUrl">
                  <ng-container *ngIf="!rowData.imageBlob">
                    <div style="margin-top: -4px">
                      <div
                        class="content-progress-bar"
                        style="
                          height: inherit;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <span class="loader scale-down"></span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="rowData.imageBlob">
                    <div style="margin-top: -4px">
                      <img
                        [src]="rowData.imageBlob | safe : 'url'"
                        alt="Rig Image"
                        class="rig-item__image-display"
                      />
                    </div>
                  </ng-container>
                </ng-container>
                <div
                  *ngIf="!rowData.imageUrl"
                  class="upload-document image"
                  [ngClass]="{ 'form-disabled': isViewer }"
                  appDrag
                  [isViewer]="isViewer"
                  (files)="filesDropped($event, 'image', rowData)"
                ></div>
              </div>
            </div>
          </td>
          <td
            class="document"
            style="min-width: 100px; max-width: 140px"
            [pTooltip]="
              isViewer
                ? ''
                : rowData?.specUrl
                ? decodeFileName(rowData?.specUrl)
                : 'Drag document to upload'
            "
            tooltipPosition="bottom"
          >
            <div
              class="document-cell"
              [ngClass]="{
                'non-file pdf': !rowData.specUrl
              }"
            >
              <div
                *ngIf="rowData.specUrl"
                style="margin-top: -4px"
                (click)="readPDFdocument(rowData.specUrl, 'rigspec', rowData)"
              >
                <svg-icon key="pdf" size="lg"></svg-icon>
              </div>

              <div
                *ngIf="!rowData.specUrl"
                class="upload-document pdf"
                [ngClass]="{ 'form-disabled': isViewer }"
                appDrag
                [isViewer]="isViewer"
                (files)="filesDropped($event, 'pdf', rowData)"
              ></div>
            </div>
          </td>
          <td style="min-width: 50px; max-width: 50px">
            <div class="more-option-table">
              <svg-icon
                class="header-content__title--feature"
                key="dot3hoz"
                size="md"
                (click)="showContextMenu($event, rowData)"
              >
              </svg-icon>
            </div>
          </td>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [appendTo]="'body'"
            [styleClass]="'menu-table-item'"
          ></app-menu>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">{{ msgFromApis }}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator 
      [rows]="pageSize" 
      [totalRecords]="totalRecords" 
      [rowsPerPageOptions]="[10,20,30,{showAll:'All'}]" 
      [first]="(pageIndex - 1) * pageSize"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entities"
      [showCurrentPageReport]="true"
      [templateLeft]="paginatorLeft"
      (onPageChange)="onPageChange($event)">
      <ng-template #paginatorLeft>
        <div class="paginator--totalLeft">Total<span>{{ totalRecords }}</span></div>
      </ng-template>
    </p-paginator>
  </form>
</div>

<!-- Form Add Rig Dialog Component -->
<ng-container *ngIf="displayAddFormRigDialog">
  <app-rig-form
    [displayFormRigDialog]="displayAddFormRigDialog"
    (hideFormRigDialog)="hideFormRigDialog($event)"
    (reloadRigManagement)="reloadRigManagement($event)"
  ></app-rig-form>
</ng-container>

<!-- Form Edit Rig Dialog Component -->
<ng-container *ngIf="displayEditFormRigDialog">
  <app-rig-form
    [displayFormRigDialog]="displayEditFormRigDialog"
    [rigEdit]="rigEdit"
    (hideFormRigDialog)="hideFormRigDialog($event)"
    (reloadRigManagement)="reloadRigManagement($event)"
  ></app-rig-form>
</ng-container>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
