<div class="tabview-video">
  <div class="search-tabview-video">
    <!-- <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        type="text"
        pInputText
        placeholder="Search by flow, role, etc"
        [(ngModel)]="searchValue"
      />
    </span> -->
  </div>
  <!--  -->
  <!--  -->
  <!--  -->
  <!-- View Admin -->

  <ng-template #admin>
    <div class="tabview-video-admin flex flex-row">
      <div class="navigate-list-video mr-3">
        <div class="flex flex-row justify-content-between">
          <p class="title-navigate-list">Channels</p>
          <i
            class="pi pi-plus m-1 cursor-pointer hightlight-hover"
            (click)="openCreateCategoryForm()"
          ></i>
        </div>
        <div *ngIf="categories.length">
          <div
            cdkDropList
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListData]="categories"
          >
            <div
              class="example-box"
              *ngFor="let category of categories; index as i"
              cdkDrag
              pTooltip="Drag & Drop to change position"
              tooltipPosition="bottom"
              tooltipStyleClass="tooltip-text-align-left"
            >
              <div
                class="category-item-text w-full item-chapter-hover flex flex-row justify-content-between pt-2 pb-2 align-items-center chapter-item"
                (click)="selectCategory(category, i)"
                [class]="
                  itemSelected?.categoryId === category.categoryId
                    ? 'item-selected'
                    : ''
                "
              >
                <p class="mb-0 name-chanel w-full">
                  {{ category.categoryName }} ({{ category.videoList.length }})
                </p>
                <i
                  class="pi pi-ellipsis-v display-when-hover"
                  (click)="menuCategory.toggle($event)"
                >
                </i>
              </div>
            </div>
          </div>
        </div>
        <form *ngIf="!!createCategoryForm" [formGroup]="createCategoryForm">
          <div
            [hidden]="!createCategoryEnable"
            class="mb-2 flex flex-row gap-1 relative add-new-channel"
          >
            <input
              #nameCategory
              formControlName="name"
              type="text"
              pInputText
              class="input-text-new-chapter mt-3"
              TextInputValidate
              typeCheck="short"
            />
            <div class="absolute button-confirm-add-chapter">
              <i
                class="pi pi-times mr-2 hightlight-hover"
                (click)="cancelAddNewCategory()"
              ></i>
              <i
                class="pi pi-check mr-2 hightlight-hover"
                [ngClass]="{'label-disabled': isViewer}"
                (click)="addNewCategory()"
                type="submit"
              ></i>
            </div>
          </div>
        </form>
        <ng-container *ngIf="createCategoryForm?.status === 'INVALID'">
          <small
            class="p-error block"
            *ngIf="
              (createCategoryForm.get('name')?.errors?.required &&
                createCategoryForm.get('name')?.dirty) ||
              (createCategoryForm.get('name')?.errors?.required &&
                createCategoryForm.get('name')?.errors?.blank &&
                createCategoryForm.get('name')?.dirty)
            "
          >
            {{ "Category" + " is required" }}
          </small>
          <small
            class="p-error block"
            *ngIf="
              createCategoryForm.get('name')?.errors?.blank &&
              !createCategoryForm.get('name')?.errors?.required &&
              createCategoryForm.get('name')?.dirty
            "
          >
            {{ "Category" + " is required" }}
          </small>
          <small
            class="p-error block"
            *ngIf="
              !createCategoryForm.get('name')?.errors?.blank &&
              !createCategoryForm.get('name')?.errors?.required &&
              createCategoryForm.get('name')?.errors?.duplicated &&
              createCategoryForm.get('name')?.dirty
            "
          >
            {{ "Category" + " is duplicated" }}
          </small>
          <small
            class="p-error block"
            *ngIf="
              !createCategoryForm.get('name')?.errors?.duplicated &&
              createCategoryForm.get('name')?.errors?.maxlength &&
              createCategoryForm.get('name')?.dirty
            "
          >
            {{ "Value must be no more than " + 50 + " characters" }}
          </small>
        </ng-container>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!-- content Videos  -->
      <div class="cards-help-video-admin">
        <p-card class="add-video-button" (click)="showAddVideo()">
          <div
            class="relative flex flex-column justify-content-center align-items-center h-100"
          >
            <i class="pi pi-plus icon-add-video"></i>
            <p class="add-video-button-text">Add Video</p>
          </div>
        </p-card>
        <ng-container *ngIf="itemSelected">
          <ng-container
            *ngFor="let i of itemSelected ? itemSelected.videoList : []"
          >
            <div class="video-card-container" style="height: 15.25rem">
              <app-video-card
                [video]="i"
                [isAdmin]="roleView === 'Admin'"
                [isViewer]="roleView === 'Viewer'"
                (confirmEvent)="excuseOptionVideo($event, i)"
                (videoClicked)="openVideo($event, i)"
                ></app-video-card>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <!--  -->
  <!--  -->
  <!--  -->
  <!-- View Engineer -->
  <!-- || roleView === 'Viewer'; -->

  <ng-container
    *ngIf="
      roleView === 'Engineer' ||
        roleView === 'Manager' 
      else admin
    "
  >
    <ng-container *ngIf="categories.length">
      <div
        class="tabview-video-engineer"
        *ngFor="let category of categories ? categories : []"
      >
        <ng-container *ngIf="category.videoList.length">
          <p class="category-title">
            {{ category.categoryName }} ({{ category.videoList.length }})
          </p>
          <div class="cards-help-video">
            <ng-container *ngFor="let i of category ? category.videoList : []">
              <app-video-card
                [video]="i"
                [isAdmin]="roleView === 'Admin'"
                [isViewer]="roleView === 'Viewer'"
                (confirmEvent)="excuseOptionVideo($event, i)"
                (videoClicked)="openVideo($event, i)"
                ></app-video-card>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<template #alertContainer></template>

<div class="confirm-dialog-add-video">
  <p-dialog
    [header]="dataConfirmDeleteVideo.header"
    [(visible)]="displayCheckedConfirm"
    [modal]="true"
    [draggable]="false"
  >
    <div>
      <div class="field-checkbox">
        <p-checkbox
          [(ngModel)]="checkedConfirm"
          [binary]="true"
          inputId="binary"
        ></p-checkbox>
        <label
          for="binary"
          [ngClass]="
            checkedConfirm ? 'enable-checked-label' : 'disable-checked-label'
          "
          >{{ dataConfirmDeleteVideo.titleCheckbox }}</label
        >
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="justify-content-end d-flex gap-2">
        <app-customizable-button 
          label="Cancel" 
          [style]="'dark'"
          [isDisabled]="false" 
          [elementId]="'cancelButton'" 
          (eventButton)="cancelConfirmVideo()">
        </app-customizable-button>
        <app-customizable-button 
          [label]="dataConfirmDeleteVideo.type" 
          [style]="'light'"
          [isDisabled]="!checkedConfirm"
          [elementId]="'confirmButton'" 
          (eventButton)="excuseConfirmVideo(dataConfirmDeleteVideo)">
        </app-customizable-button>
      </div>
    </ng-template>
  </p-dialog>
</div>

<!--  -->
<p-menu
  #menuCategory
  [model]="categoryMenu"
  [popup]="true"
  styleClass="menucusCategory"
></p-menu>

<template #deleteContainer></template>
<p-toast [breakpoints]="{ top: '40px' }">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <div class="flex flex-row gap-2 header-message">
          <fa-icon [icon]="faCircleInfo"></fa-icon>
          <p>{{ message.data.header }}</p>
        </div>
        <p class="detail-message">{{ message.detail }}</p>
        <p class="time-message">
          {{ "Today, " + message.data.hours + " : " + message.data.minutes }}
        </p>
      </div>
    </div>
  </ng-template>
</p-toast>

<app-loading [isLoading]="isLoading"></app-loading>
