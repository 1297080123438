<div class="tabview-release">
  <div class="search-tabview-release"></div>

    <ng-template #admin>
      <div class="tabview-release-admin flex flex-row">
        <div class="navigate-list-release mr-3">
          <div class="flex flex-row justify-content-between">
            <p class="title-navigate-list">Release</p>
            <i class="pi pi-plus m-1 cursor-pointer hightlight-hover" (click)="openCreateReleaseForm()" ></i>
          </div>

          <app-form-release-note
            *ngIf="createReleaseEnable"
            [isAdmin]="isAdmin"
            (action)="actionFormRelease($event)"
          ></app-form-release-note>
          <div *ngIf="releaseNotes.length">
            <div cdkDropList class="example-list" >
              <div
                class="example-box"
                *ngFor="let releaseNote of releaseNotes; index as i"
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-text-align-left"
              >
                <div
                  *ngIf="!(isRenameRealseNote && itemRename?.categoryId === releaseNote?.categoryId)"
                  class="category-item-text w-full item-release-hover flex flex-row justify-content-between pt-2 pb-2 align-items-center chapter-item"
                  [class]="
                    itemSelected?.categoryId === releaseNote?.categoryId
                      ? 'item-selected'
                      : ''
                  "
                >
                  <p class="mb-0 name-chanel w-full"  (click)="onSelectCategory(releaseNote)">
                    {{WELLCARE_VERSION + " " + releaseNote.categoryName }}
                  </p>
                  <i 
                    [ngClass]="!releaseNote.isPublish ? 'pi pi-send rotate' : 'pi pi-check-circle'"
                    (click)="!releaseNote.isPublish && onPublishReleaseNote(releaseNote)"
                  ></i>
                  <i
                    class="pi pi-ellipsis-v menu-icon"
                    (click)="showContextMenu($event, releaseNote)"
                    [ngClass]="releaseNote.isPublish ? 'hidden-icon' : 'display-when-hover'"
                    ></i>
                </div>

                
                <app-form-release-note
                  *ngIf="isRenameRealseNote && itemRename?.categoryId === releaseNote?.categoryId"
                  [isAdmin]="isAdmin"
                  [dataEdit]="itemRename"
                  (action)="actionFormRelease($event)"
                ></app-form-release-note>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
        <!-- content Videos  -->
        <div *ngIf="itemSelected" class="flex-grow-1 flex-shrink-0 content-guide-editor">
          <div class="flex flex-row justify-content-between tittle-icon-editor">
            <p class="description-editor">{{ "Description" }}<span class="required-asterisk">*</span></p>
            <i
              class="pi pi-pencil icon-editor"
              [ngClass]="{ 'icon-editor-disabled': !isAdmin }"
              *ngIf="!editorEnable"
              (click)="onShowEditor()"
            ></i>
            <div class="flex flex-row" *ngIf="editorEnable">
              <i class="pi pi-times icon-editor mr-2 hightlight" (click)="onHideEditor()"></i>
              <i class="pi pi-check icon-editor hightlight" (click)="validateDescriptionItem() && updateDescriptionItem()"></i>
            </div>
          </div>
          <div
            class="flex flex-row justify-content-start tittle-icon-editor" 
            (click)="openAttachment(itemSelected.attachmentUrl)"
          >
            <p class="description-editor" [ngClass]="{'have-attachment': itemSelected?.attachmentUrl}">
              {{ WELLCARE_VERSION + " " + itemSelected.categoryName + " "}}
              <i *ngIf="itemSelected?.attachmentUrl" class="pi pi-window-maximize icon-editor hightlight"></i>
            </p>
          </div>
          <ng-container>
            <p-editor
              [(ngModel)]="tempEditor"
              [style]="{ width: '100%' }"
              [readonly]="!editorEnable"
              [styleClass]="editorEnable ? '' : 'disable-editor'"
              (onTextChange)="onTextChange()"
            ></p-editor>
            <div *ngIf="editorEnable" style="font-size: 12px; padding-bottom: 15px;"> {{ countSizeContent()}}/{{maxSizeContent}} characters</div>
          </ng-container>
          <ng-container *ngIf="editorEnable">
            <div class="flex flex-row justify-content-start tittle-icon-editor">
              <p class="description-editor">{{ "PDF Attachment" }} <span class="required-asterisk">*</span></p>
            </div>
            <div class="flex flex-row justify-content-between tittle-icon-editor">
              <p class="upload-title" (click)="openAttachment(itemSelected?.attachmentUrl)">{{ pdfAttachmentName || "" }} </p>
              <div class="upload-input">
                <p-fileUpload
                  #uploadedFiles
                  mode="basic"
                  [multiple]="false"
                  accept=".pdf"
                  [maxFileSize]="10485760"
                  [auto]="false"
                  chooseLabel="Browse file"
                  uploadLabel="pi pi-upload"
                  chooseIcon="pi pi-upload"
                  chooseStyleClass="button-choose-upload"
                  (onClear)="onUploaderClear()"
                  (onSelect)="onSelectFile($event)"
                  ></p-fileUpload>
              </div>
            </div>
          </ng-container>
          
        </div>
      </div>
    </ng-template>
  
    <!--  -->
    <!--  -->
    <!--  -->
    <!-- View Engineer -->
    <ng-container *ngIf="!isAdmin else admin" >
      <div *ngIf="releaseNotes?.length" class="d-flex flex-sm-column-reverse">
        <div *ngFor="let releaseNote of releaseNotes" class="flex-grow-1 flex-shrink-0 tabview-release-engineer">
          <div class="flex flex-row justify-content-start tittle-icon-editor">
            <p
              class="description-editor"
              [ngClass]="{'have-attachment': releaseNote?.attachmentUrl}"
              (click)="openAttachment(releaseNote?.attachmentUrl)"
            >
              {{ WELLCARE_VERSION + " " + releaseNote.categoryName + " "}}
              <i *ngIf="releaseNote?.attachmentUrl" class="pi pi-window-maximize icon-editor hightlight"></i>
            </p>
          </div>
          <ng-container *ngIf="releaseNote?.content else loading" >
            <p-editor
              [ngModel]="releaseNote.content"
              [style]="{ width: '100%' }"
              [readonly]="true"
              [styleClass]="'disable-editor'"
              styleClass="disable-editor"
            ></p-editor>
          </ng-container>
          <ng-template #loading>
            <p-skeleton width="280px" height="286px"></p-skeleton>
          </ng-template>
          
        </div>
      </div>
    </ng-container>
    <app-loading [isLoading]="isLoading"></app-loading>
</div>

<p-menu
  #menuCategory
  [model]="categoryMenu"
  [popup]="true"
  styleClass="menucusCategory"
></p-menu>
  