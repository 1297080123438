import '../../../../assets/js/custom.js';
import * as moment from 'moment';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { Subject, forkJoin, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
import {
  RigData,
  RigInterval,
  RigRun,
} from 'src/app/shared/interface/rig.interface.js';

import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from './../../../shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoaderLayOut } from 'src/app/shared/services/loaderLayOut.service';
import { IMessage } from 'src/app/shared/interface/common.js';
import { ALERT_TYPE, EVIDENCE_TYPE_PROCESS } from 'src/app/shared/enum';
import { ActivatedRoute } from '@angular/router';
import { AlertTemplate } from 'src/app/shared/type/index.js';
import { IAlertEvent } from 'src/app/shared/interface/alert.interface';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PDFDocument } from 'pdf-lib';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';
import deepCompare from 'src/app/shared/utilities/deep-compare';
import { pick, capitalize } from 'lodash';

const MAXIMUN_NUMBER_FILE_UPLOAD = 5;

@Component({
  selector: 'app-alert-form-initiate',
  templateUrl: './intervention-form-initiate.component.html',
  styleUrls: ['./intervention-form-initiate.component.scss'],
})
export class AlertFormInitiateComponent
  extends BaseComponent
  implements AfterViewInit
{
  // Variable using in template
  // rigData: any;

  @Input() rigData!: RigData;

  @Input() rigInterval!: RigInterval;

  @Input() rigRun!: RigRun;

  @Input() localTimestamps: any;

  @Input() rigTimestamps: any;

  @Input() devident: number = 0; // work but not progess

  @Input() userTimeZone: string = '';
  @Input() rigTimeZone: string = '';

  // @Input() flagColor: any[] = [];

  private _flagColor: any[];
  @Input()
  set flagColor(value: any[]) {
    const data = this.interventionDataEdit;
    this._flagColor = value;

    let flag = this._flagColor.find((flag) => {
      this.selectedFlagPurpose = flag.code === data?.flag ? flag.purpose : '';
      return flag.code === data?.flag;
    });
    
    // Reassign to get latest value // Swap Edit Alert Case
    this.flags = this._flagColor;
    if (!flag && !this.flags.length) {
      this.isMissingDistribution = true;
      flag = {
        code: data.flag,
        name: capitalize(data.flag),
        purpose: capitalize(data.flag)
      }
      this.flags = [flag];
    } else {
      this.isMissingDistribution = false;
    }
    this.formInitiateGroup.get('flag')?.setValue(flag);
  }

  get flagColor(): any[] {
    return this._flagColor;
  }

  UNIT_SYSTEM: any = UNIT_SYSTEM;

  //For edit mode
  @Input() alertIdEdit: any;
  @Input() interventionDataEdit: any;

  // ReadOnly Mode
  @Input() readOnly: boolean = false;

  @Input() selectedLanguage!: string;
  @Input() selectedTemplate!: AlertTemplate | any;

  // Variable using for option of save, send, preview
  items!: MenuItem[];

  // Variable using in flags
  flags: any[] = [];
  selectedFlag?: string;
  isMissingDistribution: boolean = false;

  // check
  value: boolean = false;

  // event
  events: IAlertEvent[] = [];

  // risks
  risks: any[] = [];

  defaultNA = {
    id: 'na',
    key: 'na',
    name: 'N/A',
  }

  // interval optpion
  intervalOptions: any[] = [];
  // run option
  runOptions: any[] = [];
  isDisableRunOption: boolean = true;
  backupOption: any = {
    intervalOptions: [],
    runOptions: []
  };

  selectedNodeOperation: any;
  selectedNodeOperationString: string = '';

  // new operation
  operations: any[] = [];
  // selectEvent
  selectedEvent: any;

  selectedNode: any;
  selectedNodeString: string = '';

  selectedNodeRisk: any[] = [];
  selectedNodeStringRisk: string = '';
  selectionRiskLimit = 4;

  // max size of file PDF can upload;
  // maxFileSize_PDF: number = 7000000;
  // maxFileSize_image: number = 3000000;
  maxFileSizeUpload: number = 10485760;

  
  // Variable used for upload picture
  uploadedFiles: any[] = [];
  isLoadingFileEvidence: boolean = false;

  // viewChild for counter amount upload
  @ViewChild('fileUploader') fileUploader: any;
  // counterUpload = new BehaviorSubject<number>(0);

  // treeEvent
  @ViewChild('treeEvent') treeEvent: any;
  @ViewChild('treeRiskEvent') treeRiskEvent: any;

  // Title for Upload Component
  UploadTitle = {
    choose: 'Add files',
    upload: 'Paste from Clipboard',
    cancel: 'Clear All',
  };


  @Output() onChange = new EventEmitter<{originalValues: any, currentValues: any, hasChange: boolean}>();
  originalFormValues: any = {};

  // Key of form
  public fieldNameKeys = {
    autoLocalTimestamp: 'autoLocalTimestamp',
    autoRigTimestamp: 'autoRigTimestamp',
    localTimestamps: 'localTimestamps',
    rigTimestamps: 'rigTimestamps',
    isOverrideTime: 'isOverrideTime',
    holeDiameter: 'holeDiameter',
    interval: 'interval',
    run: 'run',
    effectiveDiameter: 'effectiveDiameter',
    runNumber: 'runNumber',
    customRunNumber: 'customRunNumber',
    isOverrideRunNumber: 'isOverrideRunNumber',
    bitMD: 'bitMD',
    holeMD: 'holeMD',
    selectedOperation: 'selectedOperation',
    operation: 'operation',
    anotherOperation: 'anotherOperation',
    event: 'event',
    eventDescription: 'eventDescription',
    flag: 'flag',
    recommendation: 'recommendation',
    selectedRisk: 'selectedRisk',
    risk: 'risk',
    anotherRisk: 'anotherRisk',
    evidence: 'evidence',
    validationDetailCheck: 'validationDetailCheck',
    validationDetail: 'validationDetail',
    furtherAction: 'furtherAction',
  };

  // Variable of Form
  public formInitiateGroup: FormGroup = this._builder.group({
    [this.fieldNameKeys.autoLocalTimestamp]: [''],
    [this.fieldNameKeys.autoRigTimestamp]: [''],
    [this.fieldNameKeys.localTimestamps]: [''],
    [this.fieldNameKeys.rigTimestamps]: [''],
    [this.fieldNameKeys.isOverrideTime]: [''],
    [this.fieldNameKeys.holeDiameter]: [''],
    [this.fieldNameKeys.interval]: [''],
    [this.fieldNameKeys.run]: [''],
    [this.fieldNameKeys.effectiveDiameter]: [''],
    [this.fieldNameKeys.runNumber]: [''],
    [this.fieldNameKeys.customRunNumber]: [''],
    [this.fieldNameKeys.isOverrideRunNumber]: [''],
    [this.fieldNameKeys.bitMD]: [''],
    [this.fieldNameKeys.holeMD]: [''],
    [this.fieldNameKeys.selectedOperation]: [''],
    [this.fieldNameKeys.operation]: [''],
    [this.fieldNameKeys.anotherOperation]: [''],
    [this.fieldNameKeys.event]: [''],
    [this.fieldNameKeys.eventDescription]: [''],
    [this.fieldNameKeys.flag]: [''],
    [this.fieldNameKeys.recommendation]: [''],
    [this.fieldNameKeys.selectedRisk]: [''],
    [this.fieldNameKeys.risk]: [''],
    [this.fieldNameKeys.anotherRisk]: [''],
    [this.fieldNameKeys.evidence]: [''],
    [this.fieldNameKeys.validationDetailCheck]: [''],
    [this.fieldNameKeys.validationDetail]: [''],
    [this.fieldNameKeys.furtherAction]: [''],
  });;

  // Message for Error
  public messageErrors: any = {
    required: 'Please fill in the information.',
    minlength: 'Please enter more characters',
    maxlength: 'Please enter no more than 2000 characters',
    bitMDValidator:
      'Please enter bitMD decimal (2 digits) and no more than 15 characters',
    holeMDValidator:
      'Please enter holeMD decimal (2 digits) and no more than 15 characters',
    holeDiameterValidator:
      'Please enter Hole Diameter decimal (3 digits) and no more than 15 characters',
    effectiveDiameterValidator:
      'Please enter Effective Diameter decimal (3 digits) and no more than 15 characters',
    customRunNumberValidator:
      'Please enter Custom RunNumber decimal (3 digits) and no more than 15 characters',
    anotherOperationValidator: 'Please enter no more than 100 characters',
    anotherRiskValidator: 'Please enter no more than 100 characters',
    whitespace: 'Please fill in the information without white space.',
    mustLowest: 'Please select the lowest level of the root category',
    selectionLimit: `You only can select up to ${this.selectionRiskLimit} items.`,
    orderBitMDAndHoleMd: 'Bit MD must be equal to or less than Hole MD!',
    orderHoleMDAndBitMd: 'Hole MD must be equal to or greater than Bit MD!',
    limitLine: 'Please enter no more than 15 lines.',
  };

  // counter fisrt time for remove error
  counterRequired = 0;
  isOnDrag = false;
  // regex for custom validate
  private twoDigitRegex: string = `^(?=[0-9.]{0,17}$)[0-9]+(?:\.[0-9]{0,2})?$`;
  private threeDigitRegex: string = `^(?=[0-9.]{0,18}$)[0-9]+(?:\.[0-9]{0,3})?$`;
  private limitCharacterRegex_1: string = `^[0-9A-Za-z\u00C0-\u1EF9!@\. ;:'"?-]{0,100}$`;
  private limitCharacterRegex_2: string = `^[0-9A-Za-z!@\. ;:'"?-]{0,1000}$`;
  private limitLenght100: string = `^.{0,100}$`;

  // check style for field when click override
  isTimeOverrided: boolean = false;
  isRunNumberOverrided: boolean = false;
  selectedFlagPurpose: string = '';

  @Input()
  projectUnit: string = '';

  unitConverterDiameter: number = 0;
  unitConverterDepth: number = 0;
  digitDiameter: number = 0;
  digitDepth: number = 0;

  formUnit: any;

  AppHelper = AppHelper;

  formLoaded?: Promise<boolean>;
  
  getOption$ = new Subject();

  constructor(
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _blobService: AzureBlobService,
    private _homeService: HomeService,
    private _loaderLayOut: LoaderLayOut,
    private _activedRoute: ActivatedRoute,
    @Optional() private _config: DynamicDialogConfig
  ) {
    super();
  }

  onInit(): void {
    const newTreeEvent: any[] = [];
    if (this.selectedTemplate) {
        AppConstant.EVENTS.forEach((event: IAlertEvent) => {
          const res = this.getEventDropdown(this.selectedTemplate, event);
          if (res)
            newTreeEvent.push(res);
        });
    }

    this.events = newTreeEvent;

    this.flags = this.flagColor;
    this.risks = AppConstant.RISKS;
    this.operations = AppConstant.OPERATIONS;
    this.projectUnit = this.rigData.project.curUnit || '';
    this.intervalOptions = [this.defaultNA];
    this.runOptions = [this.defaultNA];

    this.formUnit = AppHelper.MathFunctions.findUnit(
      this.projectUnit,
      this.UNIT_SYSTEM.unit
    );
    this.buildForm();

    this.getOption$.pipe(
      switchMap(() => {
        return this._activedRoute.paramMap;
      }),
      take(1),
      map((params) => {
        return params.get('id') || this._config?.data.rigJournalIdParam}
        ),
      switchMap((rigJournalId) => {
        if (rigJournalId)
          return forkJoin([
            this._homeService.getListIntervalByJournalId(rigJournalId),
            this._homeService.getListRunByJournalId(rigJournalId),
          ]);
        return of([]);
      })
    ).subscribe({
      next: ([listInterVal, listRun]: any) => {
        this.isDisableRunOption = true;
        this.isLoadingFileEvidence = false;

        if (listInterVal && listInterVal?.data?.length > 0) {
          const newOptions = listInterVal.data.map((interval: any) => {
            return {
              id: interval.intervalId,
              key: interval.intervalId,
              name: interval.intervalName,
            }
          });

          this.backupOption.intervalOptions = [...newOptions];
          newOptions.push(this.defaultNA);
          this.intervalOptions = newOptions;
        }

        
        if (listRun && listRun?.data?.length > 0) {
          const newOptions = listRun.data.map((run: any) => {
            return {
              id: run.runId,
              key: run.runId,
              name: `${run.description} (${run.runNo})`,
              runNo: run.runNo,
              description: run.description,
              intervalId: run.intervalId
            }
          });

          this.backupOption.runOptions = [...newOptions];
          this.runOptions = [];
        }
        
        this.generateForm();
      },
      error: () => {
        this.intervalOptions = [this.defaultNA];
        this.runOptions = [this.defaultNA];
        this.generateForm();
      },
      complete: () => {}
    })
    this.getOption$.next(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTemplate']?.currentValue != changes['selectedTemplate']?.previousValue) {
      const newTree: any[] = [];
      AppConstant.EVENTS.forEach((event: IAlertEvent) => {
        const res = this.getEventDropdown(changes['selectedTemplate']?.currentValue, event);
        if (res)
          newTree.push(res);
      });
      this.events = newTree;
      const controlEvent = this.formInitiateGroup?.get(this.fieldNameKeys.furtherAction);

      const eventData = this.getEventInList(controlEvent?.value , this.events);
      controlEvent?.setValue(eventData);

      const controlFurther = this.formInitiateGroup?.get(this.fieldNameKeys.furtherAction);
      if (changes['selectedTemplate']?.currentValue === ALERT_TYPE.ROADMAP) {
        controlFurther?.setValue(['no']);
        controlFurther?.disable();
      } else {
        controlFurther?.enable();
      }
    }

    if (changes['interventionDataEdit']?.currentValue != changes['interventionDataEdit']?.previousValue) {
      this.generateForm();
    }
  }

  getEventInList(eventDataObject: any, list: IAlertEvent[]) {
    if (!eventDataObject) {
      this.selectedNodeString = '';
      return null;
    }
    let eventData: any = null;

    let found,
      index_lv_1 = -1,
      index_lv_2 = -1,
      index_Lv3 = -1;
    for (let i_Lv1 = 0; i_Lv1 < list.length; i_Lv1++) {
      found = list[i_Lv1]?.children?.find((item: IAlertEvent, i_Lv2) => {
        if (item.hasOwnProperty('children') && item?.children?.length > 0) {
          item?.children.find((finalItem, i_Lv3) => {
            if (finalItem.event_id === eventDataObject.event_id) {
              index_lv_1 = i_Lv1;
              index_lv_2 = i_Lv2;
              index_Lv3 = i_Lv3;
              //
              return finalItem;
            }
          });
        } else if (item.event_id === eventDataObject.event_id) {
          index_lv_1 = i_Lv1;
          index_lv_2 = i_Lv2;
          index_Lv3 = -1;
          index_lv_1 = i_Lv1;
          return item;
        }
      });
      if (found) {
        break;
      }
    }
    if (index_Lv3 >= 0) {
      eventData =
        list[index_lv_1]?.children[index_lv_2]?.children[
          index_Lv3
        ];

      this.selectedNodeString = `${list[index_lv_1].label}/${
        list[index_lv_1]?.children[index_lv_2].label
      }/${
        list[index_lv_1]?.children[index_lv_2]?.children[
          index_Lv3
        ].label
      }`;
      
      return eventData;
    } else if (index_lv_2 >= 0) {
      eventData = list[index_lv_1]?.children[index_lv_2];
      this.selectedNodeString = `${list[index_lv_1].label}/${list[index_lv_1]?.children[index_lv_2].label}`;
      return eventData;
    }

    this.selectedNodeString = '';
    return null;
  }


  override ngOnDestroy(): void {
    this.getOption$.complete();
  }

  private getEventDropdown(templ: AlertTemplate, node: IAlertEvent): any {
    if (!node) return null;

    if (node?.styleClass === 'lowestChild' && node?.template) {
      const find = node?.template.includes(templ);
      if (find)
        return node;
      else return null;
    }

    const nodeClonde: IAlertEvent = {
      data: node.data, 
      label: node.label,
      children: [],
      template: node?.template,
      event_id: node?.event_id,
      eventType: node?.eventType,
      leaf: node?.leaf,
      styleClass: node?.styleClass,
    }

    for (let index = 0; index < node.children.length; index++) {
      const data = this.getEventDropdown(templ, node.children[index]);
      if (data) {
        nodeClonde.children.push(data);
      }
    }

    if (nodeClonde.children.length > 0)
      return nodeClonde;
    else return null;
  }

  generateForm() {
    if (this.alertIdEdit && this.interventionDataEdit && this.flagColor) {
      this.buildFormEdit(this.interventionDataEdit, this.readOnly);
    } else {
      // Collapse All Event Dropdowns when create new Intervention
      if (this.events) {
        this.events.forEach((node) => {
          this.expandRecursive(node, false);
        });
      }
    }

    this.formLoaded = Promise.resolve(true);

    // Send Signal to layout turn off loading
    this._loaderLayOut.setLoadingInterventionDone(true);

    const holeMDControl = this.formInitiateGroup.get('holeMD');
    const bitMDControl = this.formInitiateGroup.get('bitMD');

    bitMDControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const holeMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          holeMDControl?.value,
          ',',
          ''
        );
        const bitMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          value,
          ',',
          ''
        );

        if (holeMDValue && bitMDValue > holeMDValue) {
          bitMDControl?.setErrors({
            orderBitMDAndHoleMd: this.messageErrors.orderBitMDAndHoleMd,
          });
        }
      });

    holeMDControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const holeMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          value,
          ',',
          ''
        );
        const bitMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          bitMDControl?.value,
          ',',
          ''
        );
        if (bitMDValue && holeMDValue < bitMDValue) {
          holeMDControl?.setErrors({
            orderHoleMDAndBitMd: this.messageErrors.orderHoleMDAndBitMd,
          });
        }
      });

    // xu li override Timestamp
    this.formInitiateGroup
      .get('isOverrideTime')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          const localTimestamp =
            this.formInitiateGroup.get('localTimestamps');
          const rigTimestamp = this.formInitiateGroup.get('rigTimestamps');

          // change color of lable input
          this.isTimeOverrided = addCtrlValue;

          if (addCtrlValue) {
            localTimestamp?.enable();
            localTimestamp?.setValidators([Validators.required]);

            rigTimestamp?.enable();
            rigTimestamp?.setValidators([Validators.required]);
          } else {
            localTimestamp?.disable();
            localTimestamp?.setValidators([]);

            rigTimestamp?.disable();
            rigTimestamp?.setValidators([]);
          }
        })
      )
      .subscribe();

    // xu li override run number
    this.formInitiateGroup
      .get('isOverrideRunNumber')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          const customRunNumber =
            this.formInitiateGroup.get('customRunNumber');
          // change color of lable input
          this.isRunNumberOverrided = addCtrlValue;

          if (addCtrlValue) {
            customRunNumber?.enable();
            customRunNumber?.setValidators([Validators.required]);
          } else {
            customRunNumber?.disable();
            customRunNumber?.setValidators([]);
          }
        })
      )
      .subscribe();

    // xu li select operation
    this.formInitiateGroup
      .get('selectedOperation')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          const operation = this.formInitiateGroup.get('operation');
          const customOperation =
            this.formInitiateGroup.get('anotherOperation');

          //

          if (addCtrlValue[0] === undefined) {
            customOperation?.disable();
            operation?.disable();
            addCtrlValue.pop();
          } else {
            addCtrlValue[0] === 'B'
              ? (customOperation?.enable(), operation?.disable())
              : (customOperation?.disable(), operation?.enable());
          }
        })
      )
      .subscribe();

    // xu li select risk
    this.formInitiateGroup
      .get('selectedRisk')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          //

          const customRisk = this.formInitiateGroup.get('anotherRisk');
          const dropdownRisk = this.formInitiateGroup.get('risk');
          //

          if (addCtrlValue[0] === undefined) {
            customRisk?.disable();
            dropdownRisk?.disable();
            addCtrlValue.pop();
          } else {
            addCtrlValue[0] === 'B'
              ? (customRisk?.enable(), dropdownRisk?.disable())
              : (customRisk?.disable(), dropdownRisk?.enable());
          }
        })
      )
      .subscribe();

    //xu li add validator for Rig-based Team Validation Details after click yes/no in rig-base
    this.formInitiateGroup
      .get('validationDetailCheck')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          //

          const validationDetail =
            this.formInitiateGroup.get('validationDetail');

          if (addCtrlValue[0] === undefined) {
            addCtrlValue.pop();
          }

          addCtrlValue[0] === 'yes'
            ? validationDetail?.setValidators([
                Validators.required,
                Validators.maxLength(2000),
                this.noWhitespaceValidator,
              ])
            : validationDetail?.setValidators([
                Validators.maxLength(2000),
                this.noWhitespaceValidator
              ]);
          this.getRefresh('validationDetail');
        })
      )
      .subscribe();

    //xu li furtherAction
    this.formInitiateGroup
      .get('furtherAction')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          //

          // if (addCtrlValue[0] === undefined) {
          //   addCtrlValue.pop();
          // }
        })
      )
      .subscribe();

    this.formInitiateGroup.valueChanges.subscribe(() => {
      this.emitChange(this.originalFormValues, this.formInitiateGroup.getRawValue());    
    })
  }

  emitChange(originalValues: any, currentValues: any) {
    const fields = Object.keys(this.fieldNameKeys);
    this.onChange.emit({originalValues, currentValues, hasChange: !deepCompare(pick(originalValues, fields), pick(currentValues, fields))})
  }

  ngAfterViewInit() {}

  buildForm() {
    let holeDiameter = Number(
      AppHelper.UtileFunctions.replaceAllCharacter(
        this.rigInterval?.openHoleDiameter || '0',
        ',',
        ''
      )
    );

    let isFurtherAction = ['yes'];
    const isRoadmap = this.selectedTemplate === ALERT_TYPE.ROADMAP;
    if (isRoadmap)
      isFurtherAction = ['no'];

    this.formInitiateGroup = this._builder.group({
      [this.fieldNameKeys.autoLocalTimestamp]: [
        { value: this.localTimestamps, disabled: true },
      ],
      [this.fieldNameKeys.isOverrideTime]: [false],
      [this.fieldNameKeys.localTimestamps]: [{ value: null, disabled: true }],
      [this.fieldNameKeys.autoRigTimestamp]: [
        { value: this.rigTimestamps, disabled: true },
      ],
      [this.fieldNameKeys.rigTimestamps]: [{ value: null, disabled: true }],
      [this.fieldNameKeys.holeDiameter]: [
        {
          value: holeDiameter,
          disabled: true,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.effectiveDiameter]: [''],
      [this.fieldNameKeys.runNumber]: [
        {
          value: Number(
            AppHelper.UtileFunctions.replaceAllCharacter(
              this.rigRun?.runNo || '0',
              ',',
              ''
            )
          ),
          disabled: true,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.isOverrideRunNumber]: [false],
      [this.fieldNameKeys.customRunNumber]: [
        { value: '', disabled: true },
        [this.fieldNameKeys.isOverrideRunNumber ? Validators.required : false],
      ],
      [this.fieldNameKeys.interval]: [
        { value: null, disabled: false },
        [Validators.required],
      ],
      [this.fieldNameKeys.run]: [
        { value: null, disabled: false },
        [Validators.required],
      ],
      [this.fieldNameKeys.bitMD]: ['', [Validators.required]],
      [this.fieldNameKeys.holeMD]: ['', [Validators.required]],
      [this.fieldNameKeys.selectedOperation]: [
        this.selectedOperation,
        [Validators.required],
      ],
      [this.fieldNameKeys.operation]: [
        { value: this.selectedNodeOperation, disabled: true },
        [Validators.required, this.chooseLowestLevelCategoryValidator],
      ],
      [this.fieldNameKeys.anotherOperation]: [
        { value: '', disabled: true },
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitLenght100,
            'anotherOperation',
            'anotherOperationValidator'
          ),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.event]: [
        this.selectedNode,
        [Validators.required, this.chooseLowestLevelCategoryValidator],
      ],
      [this.fieldNameKeys.eventDescription]: [
        '',
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.lineValidator(15),
        ],
      ],

      [this.fieldNameKeys.flag]: ['', [Validators.required]],
      [this.fieldNameKeys.recommendation]: [
        '',
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.lineValidator(15),
        ],
      ],
      // this.risksway[0]
      [this.fieldNameKeys.selectedRisk]: [this.selectedRisk, [Validators.required]],
      [this.fieldNameKeys.risk]: [
        { value: this.selectedNodeRisk, disabled: true },
        Validators.compose([
          Validators.required,
          this.selectionLimitValidator(this.selectionRiskLimit),
        ]),
      ],
      [this.fieldNameKeys.anotherRisk]: [
        { value: '', disabled: true },
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitLenght100,
            'anotherRisk',
            'anotherRiskValidator'
          ),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.evidence]: [null],
      [this.fieldNameKeys.validationDetailCheck]: [
        this.validationDetailCheck,
        [Validators.required],
      ],
      [this.fieldNameKeys.validationDetail]: [
        '',
        this.validationDetailCheck ? [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
        ] : [
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.furtherAction]: [
        {
          value: isFurtherAction,
          disabled: isRoadmap,
        },
        [Validators.required],
      ],
    });
  }

  async buildFormEdit(interventionDataEdit: any, isReadOnlyMode: boolean) {
    const data = interventionDataEdit;

    // need set label of dropdown multi level and Item chooose - OPERATION
    let operationData: any;
    if (typeof data.currentOperation !== "string") {
      this.selectedNodeOperationString = data.currentOperation.label;
      let found,
        index_lv_1 = 0,
        index_lv_2 = 0;
      for (let i_Lv1 = 0; i_Lv1 < AppConstant.OPERATIONS.length; i_Lv1++) {
        found = AppConstant.OPERATIONS[i_Lv1].children.find((item, i_Lv2) => {
          if (item.operation_id == data.currentOperation.operation_id) {
            index_lv_2 = i_Lv2;
            return item.operation_id == data.currentOperation.operation_id;
          }
        });
        if (found) {
          index_lv_1 = i_Lv1;
          break;
        }
      }

      operationData = AppConstant.OPERATIONS[index_lv_1].children[index_lv_2];
    } else {
      operationData = data.currentOperation;
    }

    // need set label of dropdown multi level and Item chooose - EVENT
    let eventData: any;
    if (data.event) {
      const eventDataObject = data.event;
      eventData = this.getEventInList(eventDataObject, this.events);
    }

    // need set label of dropdown multi level and Item chooose - RISK
    let riskData: any[] | string = [];
    if (typeof data.risk !== "string") {
      let found,
        index_lv_1 = 0,
        index_lv_2 = 0;
      data.isCustomRisk = false;
      for (
        let index_RiskData = 0;
        index_RiskData < data.risk.length;
        index_RiskData++
      ) {
        //
        for (let i_Lv1 = 0; i_Lv1 < AppConstant.RISKS.length; i_Lv1++) {
          found = AppConstant.RISKS[i_Lv1].children.find((item, i_Lv2) => {
            if (item.risk_id == data.risk?.[index_RiskData]?.risk_id) {
              index_lv_1 = i_Lv1;
              index_lv_2 = i_Lv2;
              return item.risk_id == data.risk?.[index_RiskData]?.risk_id;
            }
          });
          if (found) {
            riskData.push(AppConstant.RISKS[index_lv_1].children[index_lv_2]);
            break;
          }
        }
      }
      this.selectedNodeStringRisk = riskData.map((risk) => {return risk?.label}).join(' | ');
    } else {
      data.isCustomRisk = true;
    }

    // FurtherAction
    let isFurtherAction = ['no'];
    if (data.isRequiredFutherAction) {
      isFurtherAction[0] = data.isRequiredFutherAction ? 'yes' : 'no';
    }
    
    const isRoadmap = this.selectedTemplate === ALERT_TYPE.ROADMAP;
    if (isRoadmap)
      isFurtherAction = ['no'];

    let isRequiredRigBasedValidation = ['no'];
    if (data.isRequiredRigBasedValidation) {
      isRequiredRigBasedValidation[0] = data.isRequiredRigBasedValidation
        ? 'yes'
        : 'no';
    }

    let holeDiameter = data.holeDiameter;
    let effectiveDiameter =
      data.effectiveDiameter !== 'NaN' ? data.effectiveDiameter : null;
    let holeMD = data.holeMD !== 'NaN' ? data.holeMD : null;
    let bitMD = data.bitMD !== 'NaN' ? data.bitMD : null;

    // Update flag
    let flag = this.flagColor.find((flag) => {
      this.selectedFlagPurpose = flag.code === data.flag ? flag.purpose : '';
      return flag.code === data.flag;
    });
    
    // Reassign to get latest value // Swap Edit Alert Case
    this.flags = this.flagColor;
    if (!flag && !this.flags.length) {
      this.isMissingDistribution = true;
      flag = {
        code: data.flag,
        name: capitalize(data.flag),
        purpose: capitalize(data.flag)
      }
      this.flags = [flag];
    } else {
      this.isMissingDistribution = false;
    }

    let interval = this.intervalOptions.find(option => option.id === data?.interval?.intervalId);
    let run = null;
    if (interval) {
      // Interval have data ==> set value for Run
      this.isDisableRunOption = false;
      this.runOptions = this.backupOption.runOptions.filter((run: any) => run.intervalId === interval.id);
      this.runOptions.push(this.defaultNA);

      const runSelect = this.runOptions.find(option => option.id === data?.run?.runId) || null;
      run = runSelect? runSelect : this.defaultNA;
    } else {
      // Interval doesn't have data ==> disable Run
      this.isDisableRunOption = true;
      this.runOptions = [];
      interval = this.defaultNA;
    }

    this.formInitiateGroup = this._builder.group({
      [this.fieldNameKeys.autoLocalTimestamp]: [
        {
          value: data.isOverrideTime
            ? AppHelper.DateFunctions.formatDateTime(new Date(), true)
            : data.localTimestamps
            ? AppHelper.DateFunctions.formatDateTime(
                new Date(Number(data.localTimestamps)),
                true
              )
            : AppHelper.DateFunctions.formatDateTime(new Date(), true),
          disabled: true,
        },
      ],
      [this.fieldNameKeys.isOverrideTime]: [
        { value: data.isOverrideTime, disabled: isReadOnlyMode },
      ],
      [this.fieldNameKeys.localTimestamps]: [
        {
          value: data.isOverrideTime
            ? data.localTimestamps
              ? AppHelper.DateFunctions.formatDateTime(
                  new Date(Number(data.localTimestamps)),
                  true
                )
              : AppHelper.DateFunctions.formatDateTime(new Date(), true)
            : null,
          disabled: isReadOnlyMode || !data.isOverrideTime,
        },
      ],
      [this.fieldNameKeys.autoRigTimestamp]: [
        {
          value: data.isOverrideTime
            ? AppHelper.DateFunctions.formatDateTime(new Date(), true)
            : data.rigTimestamps
            ? AppHelper.DateFunctions.formatDateTime(
                new Date(Number(data.rigTimestamps)),
                true
              )
            : AppHelper.DateFunctions.formatDateTime(new Date(), true),
          disabled: true,
        },
      ],
      [this.fieldNameKeys.rigTimestamps]: [
        {
          value: data.isOverrideTime
            ? data.rigTimestamps
              ? AppHelper.DateFunctions.formatDateTime(
                  new Date(Number(data.rigTimestamps)),
                  true
                )
              : AppHelper.DateFunctions.formatDateTime(new Date(), true)
            : null,
          disabled: isReadOnlyMode || !data.isOverrideTime,
        },
      ],
      [this.fieldNameKeys.holeDiameter]: [
        { value: holeDiameter, disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.effectiveDiameter]: [
        { value: data.effectiveDiameter, disabled: isReadOnlyMode },
      ],
      [this.fieldNameKeys.runNumber]: [
        { value: !data.isOverrideNumber ? data.runNumber : 0, disabled: true },
        [Validators.required],
      ],
      [this.fieldNameKeys.isOverrideRunNumber]: [
        { value: data.isOverrideNumber, disabled: isReadOnlyMode },
      ],
      [this.fieldNameKeys.customRunNumber]: [
        {
          value: data.runNumber,
          disabled: isReadOnlyMode || !data.isOverrideNumber,
        },
      ],
      [this.fieldNameKeys.interval]: [
        { value: interval, disabled: isReadOnlyMode },
        [Validators.required],
      ],
      [this.fieldNameKeys.run]: [
        { value: run, disabled: isReadOnlyMode },
        [run ? Validators.required : Validators.nullValidator],
      ],
      [this.fieldNameKeys.bitMD]: [
        { value: bitMD, disabled: isReadOnlyMode },
        [Validators.required],
      ],
      [this.fieldNameKeys.holeMD]: [
        { value: holeMD, disabled: isReadOnlyMode },
        [Validators.required],
      ],
      [this.fieldNameKeys.selectedOperation]: [
        {
          value: (this.selectedOperation[0] = data.isCustomOperation ? 'B' : 'A'),
          disabled: isReadOnlyMode,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.operation]: [
        {
          value: !data.isCustomOperation ? operationData : '',
          disabled: isReadOnlyMode || data.isCustomOperation,
        },
        [Validators.required, this.chooseLowestLevelCategoryValidator],
      ],
      [this.fieldNameKeys.anotherOperation]: [
        {
          value: data.isCustomOperation ? operationData : '',
          disabled: isReadOnlyMode || !data.isCustomOperation,
        },
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitLenght100,
            'anotherOperation',
            'anotherOperationValidator'
          ),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.event]: [
        {
          value: eventData,
          disabled: isReadOnlyMode,
        },
        [Validators.required, this.chooseLowestLevelCategoryValidator],
      ],
      [this.fieldNameKeys.eventDescription]: [
        {
          value: data.eventDescription,
          disabled: isReadOnlyMode,
        },
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.lineValidator(15)
        ],
      ],

      [this.fieldNameKeys.flag]: [
        {
          value: flag,
          disabled: isReadOnlyMode,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.recommendation]: [
        {
          value: data.recommendation,
          disabled: isReadOnlyMode,
        },
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.lineValidator(15)
        ],
      ],
      [this.fieldNameKeys.selectedRisk]: [
        {
          value: (data.isCustomRisk ? 'B' : 'A'),
          disabled: isReadOnlyMode,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.risk]: [
        {
          value: data.isCustomRisk ? this.selectedNodeRisk : riskData,
          disabled: isReadOnlyMode || data.isCustomRisk,
        },
        Validators.compose([
          Validators.required,
          this.selectionLimitValidator(this.selectionRiskLimit),
        ]),
      ],
      [this.fieldNameKeys.anotherRisk]: [
        {
          value: data.isCustomRisk ? data.risk : "",
          disabled: isReadOnlyMode || !data.isCustomRisk,
        },
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.limitLenght100,
            'anotherRisk',
            'anotherRiskValidator'
          ),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.evidence]: [
        {
          value: this.uploadedFiles,
          disabled: isReadOnlyMode,
        },
      ],
      [this.fieldNameKeys.validationDetailCheck]: [
        {
          value: isRequiredRigBasedValidation,
          disabled: isReadOnlyMode,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.validationDetail]: [
        {
          value: data.rigBasedValidationDetail,
          disabled: isReadOnlyMode,
        },
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.furtherAction]: [
        {
          value: isFurtherAction,
          disabled: isReadOnlyMode || isRoadmap,
        },
        [Validators.required],
      ],
    });

    this.originalFormValues = this.formInitiateGroup.getRawValue();

    // Fill data for evidence
    if (data?.evidences?.length > 0) {
      const listEvidence = AppHelper.UtileFunctions.filterEvidenceByType(data.evidences, EVIDENCE_TYPE_PROCESS.INITIATE);
      
      const promiseAll: Promise<Blob>[] = [];
      for (const evidence of listEvidence) {
        // Evidence have process is resolve, will exists
        if (evidence.typeProcess === EVIDENCE_TYPE_PROCESS.RESOLVE) return;
        
        this.isLoadingFileEvidence = true;
        const blod = this._blobService.downloadImageEvidence(
          decodeURIComponent(
            AppHelper.StringFunctions.getFileName(evidence.evidenceUrl),
          ));
        promiseAll.push(blod);
      }

      Promise.all(promiseAll).then((values: Blob[]) => {        
        if (values.length === listEvidence.length) {
          const fileDownload : File[] = [];
          values.forEach((blob: Blob, index: number) => {
            const fullFileName = decodeURIComponent(listEvidence[index].evidenceName) 
              || AppHelper.StringFunctions.getFileName(listEvidence[index].evidenceUrl).slice(14);
            fileDownload.push(
              new File([blob], fullFileName, {
                type:
                  AppHelper.StringFunctions.getExtension(fullFileName) !== 'pdf'
                    ? `image/${AppHelper.StringFunctions.getExtension(
                        fullFileName
                      )}`
                    : 'application/pdf',
              })
            );
          })
          this.uploadedFiles = [...fileDownload];
          this.isLoadingFileEvidence = false;
        } else {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Download file have problem',
            content: 'Please refresh page.',
          });
        }
      }).catch(() => {
        this.isLoadingFileEvidence = false;
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Download file have problem',
          content: 'Please input again.',
        });
      });;
    }
  }

  operationsWay: any[] = [
    { name: 'selectBox', key: 'A' },
    { name: 'AnotherOperation', key: 'B' },
  ];

  selectedOperation: string[] = [];

  selectedRisk: string[] = [];
  validationDetailCheck: string[] = ['yes'];
  furtherAction: string[] = ['yes'];

  risksway: any[] = [
    { name: 'selectBox', key: 'A' },
    { name: 'typing', key: 'B' },
  ];

  yesNoData: any[] = [
    { name: 'Yes', key: 'yes' },
    { name: 'No', key: 'no' },
  ];

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  calculateAllottedSizeFile(): boolean | number {
    let currentTotalFileSizeUpload = 0;

    let counterOverLoad = 0;
    let errorFile;
    this.fileUploader!._files.forEach((file: any) => {
      if (currentTotalFileSizeUpload + file.size < this.maxFileSizeUpload) {
        counterOverLoad++;
        currentTotalFileSizeUpload += file.size;
      } else {
        errorFile = true;
      }
    });
    if (errorFile) return false;
    else return counterOverLoad;
  }

  // Upload Picture and validate when select wrong type upload
  async onSelect(event: any) {
    const filesList: Array<File> = [...event.files];
    const MAXIMIN_TOTAL_FILES_SIZE = 10485760; // 10MB = 10485760bytes
    const listErr: IMessage[] = [];

    // Calculate total file size is uploaded
    let currentTotalFileSize =
      this.uploadedFiles?.reduce(
        (acc: number, file: File) => acc + file.size,
        0
      ) || 0;

    for (let i = 0; i < filesList.length; i++) {
      let file = filesList[i];

      // Verify duplicate
      const isDuplicated = this.uploadedFiles?.some((f: File) => {
        return f.name === file.name && f.size == file.size;
      });
      if (isDuplicated) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Duplicated file',
          content: 'Your uploading file is duplicated.',
        });
        // Remove file dublicate from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file type
      const mimeTypeAllow = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
      ];
      if (!mimeTypeAllow.includes(file.type.toLowerCase())) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid File Type`,
          content:
            'Invalid file type. Allowed file types: .pdf,.jpg, .jpeg, .png',
        });
        // Remove Invalid file type from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file name
      let formatSpecialChar = /[!()\[\]{}\\|<>\/?'"]+/;
      if (
        formatSpecialChar.test(
          AppHelper.StringFunctions.extractFileName(file.name)
        )
      ) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Detected: Invalid file name',
          content:
            "A file name can't contain any of the following characters: ! ' \" { } [ ] ( ) \\ / : ? < > | ",
        });
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 0 byte
      if (file.size  == 0) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Empty file cannot be uploaded.',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 10000000
      if (file.size > MAXIMIN_TOTAL_FILES_SIZE!) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Allowed maximum file size: 10MB',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      if (currentTotalFileSize + file.size > MAXIMIN_TOTAL_FILES_SIZE) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid total file size',
          content:
            'File make exceeded total allowed file size. Should be removed',
        });

        filesList.splice(i--, 1);
        continue;
      } else {
        currentTotalFileSize += file.size;
      }
      
      if (file.type.includes("image")) {
        try {
          let pdfDoc = await PDFDocument.create();
          const imgBuffer = await AppHelper.UtileFunctions.fileToArrayBuffer(file);
    
          let img = null;
          file.type === 'image/png'
            ? (img = await pdfDoc.embedPng(imgBuffer))
            : (img = await pdfDoc.embedJpg(imgBuffer));
        } catch {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Invalid file type',
            content: `File ${file.name} cannot be uploaded because of a mismatch between the file type and file name.`,
          });
          filesList.splice(i--, 1);
          continue;
        }
      }
    }

    // Verify file number
    if (
      this.uploadedFiles?.length + filesList?.length >
      MAXIMUN_NUMBER_FILE_UPLOAD
    ) {
      const numberFilesCanAdd =
        MAXIMUN_NUMBER_FILE_UPLOAD - this.uploadedFiles?.length;
      const numberFileRemoved = filesList.length - numberFilesCanAdd;

      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Exceeded allowed number of files`,
        content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
      });
      filesList.splice(numberFilesCanAdd, numberFileRemoved);
    }

    // Remove notification duplicate
    for (let i = 0; i < listErr.length; i++) {
      this._notificationService.setMessage(listErr[i]);
    }

    this.uploadedFiles = [...this.uploadedFiles, ...filesList] || [];
    this.formInitiateGroup.get('evidence')?.setValue(this.uploadedFiles);
  }

  // custom remove item picture
  removeItemUpload(index: number) {
    if (!this.readOnly) {
      this.fileUploader!._files.splice(index, 1);
      this.uploadedFiles = [...this.fileUploader!._files] || [];
      this.formInitiateGroup.get('evidence')?.setValue(this.uploadedFiles);
    }
  }
  // drag
  dragTime(e: any) {
    this.isOnDrag = true;
    if (this.isOnDrag) {
      this.uploadedFiles = [...this.fileUploader!._files] || [];
      this.formInitiateGroup.get('evidence')?.setValue(this.uploadedFiles);
    }
  }

  // Click text when emtpy upload
  openSelectFileUpload() {
    if (!this.readOnly) {
      const uploader = document.querySelector(
        '.fileupload-initiate'
      ) as HTMLElement;
      const chooseBtn = uploader.querySelector(
        '.p-fileupload-choose'
      ) as HTMLElement;
      chooseBtn.click();
    }
  }
  // Remove All Picture
  onClearFiles($event: any) {
    if (!this.fileUploader?.el?.nativeElement) return;

    const clipBoardButton = this.fileUploader.el.nativeElement.querySelector(
      'div.p-fileupload-buttonbar > button:nth-child(2)'
    );
    
    if (clipBoardButton) {
      clipBoardButton.disabled = false;
      clipBoardButton.classList.remove('p-disabled');
    }

    this.uploadedFiles = [];
    this.fileUploader!._files = [];
    this.formInitiateGroup.get('evidence')?.setValue([]);
  }

  // error all
  onError($event: any) {}

  async onPasteClipBoardFile(): Promise<void> {
    try {
      const clipboardItems: ClipboardItem[] = await navigator.clipboard.read();
      const blobOutput: Blob = await clipboardItems[0]?.getType('image/png');

      if (blobOutput) {
        const fileName = this.prepareFileName(this.uploadedFiles);

        const file: File = new File([blobOutput], fileName, {
          type: 'image/png',
        });
        if (file.size <= this.maxFileSizeUpload/2) {
          if (
            this.uploadedFiles?.length  >=  MAXIMUN_NUMBER_FILE_UPLOAD
          ) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: `Exceeded allowed number of files`,
              content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
            });
          } else {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Clipboard',
              content: 'Pasted screenshot accepted.',
            });
            this.onSelect({files: [file]});
          };        
        } else {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Clipboard',
            content: 'Pasted screenshot is larger than 5MB.',
          });
        }
      }

    } catch (e) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Clipboard',
        content: 'Pasted screenshot is invalid.',
      });
    }
  }

  private prepareFileName(listFileExists: any[]): string {
    let number: number = 1;
    let nameString: string = '';
    let findName: boolean = false;
    while (!findName) {
      nameString = `pasted-screenshot-${number}.png`;
      const result = listFileExists.some((f: any) => f.name === nameString);
      if (result) {
        number++;
      } else {
        findName = !findName;
      }
    }

    return nameString;
  }

  onSubmit() {
    if (this.isLoadingFileEvidence) {
      return { error: { value: true, type: 'WAITING_LOAD_DATA' } };
    }

    if (this.isMissingDistribution) {
      return { error: { value: true, type: 'MISSING_DISTRIBUTION_DATA' } };
    }

    this.formInitiateGroup.markAllAsTouched();
    this.formInitiateGroup.markAsDirty();
    this.formInitiateGroup.updateValueAndValidity();

    Object.keys(this.formInitiateGroup.controls).map((controlNathis) => {
      const control = this.formInitiateGroup.get(controlNathis);
      control?.markAsDirty();
      control?.markAllAsTouched();
      control?.updateValueAndValidity();
    });

    const valueFormRaw = this.formInitiateGroup.getRawValue();

    if (valueFormRaw.interval?.id === 'na') {
      valueFormRaw.run = this.defaultNA;
    }
    
    valueFormRaw.evidence = [...this.uploadedFiles || []];
    valueFormRaw.eventString = this.selectedNodeString;

    // Add Unit System to show in report
    valueFormRaw.unit = this.formUnit;

    this.originalFormValues = this.formInitiateGroup.getRawValue();    
    this.emitChange(this.originalFormValues, this.originalFormValues);
    return this.formInitiateGroup.valid
      ? valueFormRaw
      : { error: { value: true, type: 'INVALID' } };
  }

  getRefresh(controlName: string) {
    const control = this.formInitiateGroup.get(controlName);
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }
  // Theo chiờu true thi sẽ là từ local tìm rig
  // Theo chiờu false thi sẽ là từ rig tìm local
  onSetTimeTest(e: Date, devident: number, direction: boolean) {
    let date1 = new Date(e);

    let tz1 = this.userTimeZone.slice(0, 3);
    let tz2 = this.rigTimeZone.slice(0, 3);

    if (direction) {
      //
      let a = this.getTimeConvert(date1, devident, tz1, tz2);
      this.formInitiateGroup.controls['rigTimestamps'].setValue(
        this.getTimeConvert(date1, devident, tz1, tz2)
      );
    } else {
      //
      this.formInitiateGroup.controls['localTimestamps'].setValue(
        this.getTimeConvert(date1, devident, tz2, tz1)
      );
    }
  }

  getTimeConvert = (time: Date, devident: number, tz1: string, tz2: string) => {
    let timeUserInput = moment(time);
    if (Number(tz1) < Number(tz2)) {
      return timeUserInput
        .add(Math.abs(devident), 'hours')
        .format('DD-MMM-YYYY, HH:mm');
    } else {
      return timeUserInput
        .subtract(Math.abs(devident), 'hours')
        .format('DD-MMM-YYYY, HH:mm');
    }
  };

  // Function focus in fisrt input having error
  private focusElementInvalid() {
    const inputToFocus = document.querySelectorAll('input.ng-invalid');
    for (let i = 0; i < inputToFocus.length; i++) {
      const input = inputToFocus[i] as HTMLElement;
      input.focus();
      return;
    }
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formInitiateGroup.get(controlName);
    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const errorObj = this.formInitiateGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  // function help clear inline errorsMessage in p-upload component
  clearInlineErrorUpload() {
    var nodes = document.getElementsByTagName('p-messages');
    for (var i = 0, len = nodes.length; i != len; ++i) {
      nodes[0].parentNode?.removeChild(nodes[0]);
    }
  }
  // common funciona for single choose checkbox
  singleSelectCheckBox(controlName: string, formGroupName: FormGroup) {
    let getValue = formGroupName.get(controlName)?.value;
    let lastestValue = getValue[getValue.length - 1];
    //

    formGroupName.get(controlName)?.setValue([]);
    formGroupName.get(controlName)?.setValue([lastestValue]);
  }

  // This function using for custom validators
  private customCorrectField(
    fieldNameKeys: any,
    regex: string,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;
        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        const reg = new RegExp(regex);

        //

        let currentValue = currentControl?.value
          ? currentControl?.value.toString()
          : '';

        if (reg.test(currentValue ? currentValue.trim() : '')) {
          return null;
        }
        return { [keyValidator]: true };
      }
      return null;
    };
  }

  selectionLimitValidator(limit: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedItems = control.value;

      if (selectedItems && selectedItems.length > limit) {
        return { selectionLimit: { limit } };
      }
      return null;
    };
  }

  // This function using for check space validators
  // if have more than 1 character and it all space, it will show error message
  // Because when dont have any chareacter another error message of required will show
  public noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const moreThanOneCharecter = control.value.length === 0;
      const isValid = !isWhitespace || moreThanOneCharecter;
      return isValid ? null : { whitespace: true };
    }
  }

  public lineValidator(maxLine: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let value = (control.value || '');
      
      const arrSplit = value.split('\n');
      if (arrSplit.length <= maxLine)
        return null;

      return { limitLine: true };
    }
  }

  // This function using for check Multi-level selector, User required choose the lowest level of the root category
  public chooseLowestLevelCategoryValidator(control: FormControl) {
    const value = control.value;
    //

    let hasChildren = true;
    if (!!value) {
      // check select dont have children -> lowset level
      hasChildren = value?.children?.length > 0;
    }
    return !hasChildren ? null : { mustLowest: true };
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    formGroupName.controls[controlName].patchValue(
      AppHelper.StringFunctions.textTrimmingBeauty(control?.value)
    );
  }

  public inputNumber_Clear_e(event: any) {
    let invalidChars = ['-', '+', 'e', 'E'];

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  onClearOperation(event: any) {
    this.selectedNodeOperation = undefined;
    this.selectedNodeOperationString = '';
  }
  onClearEvent(event: any) {
    this.selectedNode = undefined;
    this.selectedNodeString = '';
  }
  onClearRisk(event: any) {
    this.selectedNodeRisk = [];
    this.selectedNodeStringRisk = '';
  }

  onClearInterval() {
    this.formInitiateGroup.get(this.fieldNameKeys.interval)?.setValue(null);
    this.formInitiateGroup.get(this.fieldNameKeys.run)?.setValue(null);
    this.isDisableRunOption = true;
  }

  onClearRun() {
    this.formInitiateGroup.get(this.fieldNameKeys.run)?.setValue(null);
  }

  onNodeExpand(event: any) {
    if (event.node.children === undefined) event.node.expanded = false;
  }

  onOptionsSelected(event: any) {
    //
    this.treeEvent.overlayVisible = false;
    if (event.node?.children?.length > 0) {
      if (
        this.selectedNode !== undefined &&
        !this.selectedNode.hasOwnProperty('children')
      ) {
        this.formInitiateGroup.get('event')?.setValue(this.selectedNode);
        return;
      }
      this.selectedNode = event.node;
      this.formInitiateGroup.get('event')?.setValue(this.selectedNode);
      this.selectedNodeString = '';
      return;
    } else {
      //
      this.selectedNode = event.node;
      let finalAncestor = false;
      let labelDisplay = event.node.label;
      let currentObjLevel = event.node;
      while (!finalAncestor) {
        if (
          currentObjLevel.hasOwnProperty('parent') &&
          currentObjLevel.parent !== undefined
        ) {
          //
          currentObjLevel = currentObjLevel.parent;
          labelDisplay = `${currentObjLevel.label} / ${labelDisplay}`;
        } else {
          finalAncestor = true;
        }
      }
      this.selectedNodeString = labelDisplay;
      //
    }
  }

  onOptionsSelectedRiskEvent(event: any) {
    if (event.node.hasOwnProperty('children')) {
      if (
        this.selectedNodeRisk !== undefined &&
        this.selectedNodeRisk.hasOwnProperty('children')
      ) {
        this.formInitiateGroup.get('risk')?.setValue(this.selectedNodeRisk);
        return;
      }
      this.selectedNodeRisk = this.treeRiskEvent.value;
      this.formInitiateGroup.get('risk')?.setValue(this.selectedNodeRisk);
      this.selectedNodeStringRisk = '';
      return;
    } else {
      this.selectedNodeRisk = this.treeRiskEvent.value;
      this.selectedNodeStringRisk = this.selectedNodeRisk.map((risk) => {return risk?.label}).join(' | ');
    }
  }

  onOptionsUnSelectedRiskEvent(event: any) {
    this.selectedNodeRisk = this.treeRiskEvent.value;

    if (this.selectedNodeRisk.length > 0) {
      this.selectedNodeRisk = this.treeRiskEvent.value;
      this.selectedNodeStringRisk = this.selectedNodeRisk.map((risk) => {return risk?.label}).join(' | ');
    } else {
      this.selectedNodeRisk = [];
      this.selectedNodeStringRisk = '';
    }

    const index = this.selectedNodeRisk.indexOf(event.node);
    if (index >= 0) {
      this.selectedNodeRisk.splice(index, 1);
    }
  }

  onOptionsSelectedInterval(event: any) {
    this.formInitiateGroup.get(this.fieldNameKeys.interval)?.setValue(event.value);
    if (!event.value) return;

    if (event.value.id !== 'na') {
      const runControl = this.formInitiateGroup.get(this.fieldNameKeys.run);
      runControl?.setValue(null);
      runControl?.enable();
      runControl?.setValidators([Validators.required]);

      const newRunOption = this.backupOption.runOptions.filter((run: any) => run.intervalId === event.value.id);
      newRunOption.push(this.defaultNA);
      
      this.isDisableRunOption = false;
      this.runOptions = newRunOption;
    } else {
      const runControl = this.formInitiateGroup.get(this.fieldNameKeys.run);
      runControl?.setValue(null);
      runControl?.disable();
      runControl?.setValidators([]);
      runControl?.setErrors(null)

      this.isDisableRunOption = true;
      this.runOptions = [];
    }
  }

  onOptionsSelectedRun(event: any) {
    this.formInitiateGroup.get(this.fieldNameKeys.run)?.setValue(event.value);
  }

  onOptionsSelectedOperation(event: any) {
    //
    this.treeEvent.overlayVisible = false;
    if (event.node.hasOwnProperty('children')) {
      if (
        this.selectedNodeOperation !== undefined &&
        this.selectedNodeOperation.hasOwnProperty('children')
      ) {
        this.formInitiateGroup
          .get('operation')
          ?.setValue(this.selectedNodeOperation);
        return;
      }
      this.selectedNodeOperation = event.node;
      this.formInitiateGroup
        .get('operation')
        ?.setValue(this.selectedNodeOperation);
      this.selectedNodeOperationString = '';
      return;
    } else {
      //
      this.selectedNodeOperation = event.node;
      let finalAncestor = false;
      let labelDisplay = event.node.label;
      let currentObjLevel = event.node;
      //

      // while (finalAncestor) {
      //   if (
      //     currentObjLevel.hasOwnProperty('parent') &&
      //     currentObjLevel.parent !== undefined
      //   ) {
      //     currentObjLevel = currentObjLevel.parent;
      //
      //     labelDisplay = `${currentObjLevel.label} / ${labelDisplay}`;
      //   } else {
      //     finalAncestor = true;
      //   }
      // }
      this.selectedNodeOperationString = labelDisplay;
    }
  }
  onDestroy(): void {}

  @ViewChild('imgRenderer') imgRenderer?: ElementRef;

  onPaste(event: any) {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;

    let blob: any = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
      }
    }

    // const returnedTarget = Object.assign({}, blob);
    //

    // load image if there is a pasted image
    if (blob) {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        this.imgRenderer!.nativeElement.src = evt.target.result;
      };
      reader.readAsDataURL(blob);
    }
  }

  formatDate(dateString: any) {
    let time = new Date(Number(dateString)).toLocaleTimeString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    let date = new Date(Number(dateString)).toLocaleDateString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return date;
  }
  slectedFlag(event: any) {
    this.selectedFlagPurpose = event?.value?.purpose || '';
  }

  isDynamicRequired(controlName: string, formGroupName: FormGroup): boolean {
    let getValue = formGroupName.get(controlName)?.value;

    if (getValue && getValue[0]) {
      return getValue[0] === 'yes' ? true : false;
    }
    return getValue ? true : false;
  }

  removeItemUploadWithName(name: string) {
    if (!name) return;

    const idx = this.uploadedFiles.findIndex((f: any) => {
      f.name === name;
    });
    idx && this.removeItemUpload(idx);
  }

  onCustomClick() {

  }
}
