import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { catchError, concatMap, finalize, forkJoin, of, switchMap, takeUntil, tap, throwError } from 'rxjs';

import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { FileUpload } from 'primeng/fileupload';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RigInterval, RigRun, RigWellbore } from 'src/app/shared/interface/rig.interface';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-home-form-run',
  templateUrl: './home-form-run.component.html',
  styleUrls: ['./home-form-run.component.scss'],
})
export class HomeFormRunComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  displayFormRun: boolean = false;

  @Output()
  hide_formRun: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  hide_formRunEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  reload_formRun: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  request_changeTab: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  projectId: string = '';

  @Input()
  projectUnit: string = '';

  @Input()
  wellboreList: any;

  @Input()
  wellbore!: RigWellbore;

  @Input()
  interval!: RigInterval;

  @Input()
  intervalList: any;

  @Input()
  rigListDataInterval: any;

  @Input()
  run: any;

  @Input()
  fromChangeStatus: boolean = false;

  @Input()
  returnRigId: string = '';

  @Input()
  returnWellboreId: string = '';

  @Input()
  rigId: string = '';

  @Input()
  rigListDataRun!: RigRun[];

  @Input()
  wellboreNameOverride: string = 'Loading ...';

  @Input() isViewer: boolean = false;

  titlePopup = 'Add Run';

  items?: FormArray;

  runTypeList: any[] = [];

  informGroup: [] = [];

  formUnit: any;

  // loading Browse file
  value: number = 0;
  @ViewChild('uploadTally') uploadTally?: FileUpload;
  @ViewChild('progressUploadTally') progressUploadTally?: ElementRef;

  // Max File Size Upload
  maxFileSizeUpload: number = 10000000;
  currentDesignSizeUpload: number = 0;

  // type description
  typeDescription: string = 'Select type of run';
  drillingText: string =
    'When Rotaty Drilling is selected, casing will be disabled';
  nonDrillingText: string =
    'When Rotaty Non-Drilling is selected, Bit and Reamer Sizes will be disabled';

  fileLabel = 'Browse file';

  public fieldNameKeys = {
    runId: 'runId',

    wellboreId: 'wellboreId',
    wellboreName: 'wellboreName',
    intervalId: 'intervalId',
    intervalName: 'intervalName',

    runDescription: 'runDescription',
    runNumber: 'runNumber',
    rigBHANumber: 'rigBHANumber',

    type: 'type',

    bitSize: 'bitSize',
    reamerSize: 'reamerSize',
    casingStringSize: 'casingStringSize',

    belowRotaryTimestamp: 'belowRotaryTimestamp',
    startMeasuredDepth: 'startMeasuredDepth',

    runTDTimestamp: 'runTDTimestamp',
    TDMeasuredDepth: 'TDMeasuredDepth',

    runTDAboveRotaryTimestamp: 'runTDAboveRotaryTimestamp',

    hiddenTextTally: 'hiddenTextTally',
  };

  // Variable of Form
  public formRunGroup: FormGroup = this._builder.group({
    inform: this._builder.group({
      [this.fieldNameKeys.wellboreId]: [null],
      [this.fieldNameKeys.wellboreName]: [null],

      [this.fieldNameKeys.intervalId]: [null],
      [this.fieldNameKeys.intervalName]: [null],

      [this.fieldNameKeys.runDescription]: [null],
      [this.fieldNameKeys.runNumber]: [null],
      [this.fieldNameKeys.rigBHANumber]: [null],

      [this.fieldNameKeys.type]: [null],

      [this.fieldNameKeys.bitSize]: [null],
      [this.fieldNameKeys.reamerSize]: [null],
      [this.fieldNameKeys.casingStringSize]: [null],
      [this.fieldNameKeys.hiddenTextTally]: [null],
    }),
    timestamp: this._builder.group({
      [this.fieldNameKeys.runTDAboveRotaryTimestamp]: [null],
      [this.fieldNameKeys.runTDTimestamp]: [null],
      [this.fieldNameKeys.belowRotaryTimestamp]: [null],
      [this.fieldNameKeys.startMeasuredDepth]: [null],
      [this.fieldNameKeys.TDMeasuredDepth]: [null],
    }),
  });

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    maxlength: 'Please enter no more than 50 characters',
    order_1:
      'Run Below Rotary Timestamp should be less than Run TD and Run Above Rotary Timestamps',
    order_2:
      'Run TD should be greater than Run Below Rotary Timestamp and less than Run Above Rotary Timestamps',
    order_3:
      'Run Above Rotary Timestamps should be greater than Run Below Rotary Timestamp and Run TD',

    orderStartMDAndTDMD:
      'Start Measured Depth should be less than or equal to TD Measured Depth',
    orderTDMDAndStartMD:
      'TD Measured Depth must be greater than or equal to Start Measured Depth',
  };
  
  UNIT_SYSTEM: any = UNIT_SYSTEM;

  showCancelButtonUpLoad: boolean = false;
  lastPDFLink: string = '';

  AppHelper = AppHelper;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _interventionService: InterventionService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }
  onInit(): void {
    this.isLoading = true;

    this.runTypeList = AppHelper.UtileFunctions.sortArrayBykey(
      AppConstant.TYPES,
      'data'
    );

    this.formUnit = AppHelper.MathFunctions.findUnit(
      this.projectUnit,
      this.UNIT_SYSTEM.unit
    );

    this.generateForm();
  }

  ngAfterViewInit() {
    if (this.run) {
      this.loadNameOfFiles(this.run.tallyUrl);
    }
  }

  generateForm() {
    if (this.run) {
      this.buildFormEdit(this.run);
      this.titlePopup = `Edit Run Number: ${this.run.runNo}`;
      this.setBehaviour();


      // markAsPristine to check close when dont active form.
      if (this.run && this.formRunGroup.pristine === false) {
        this.formRunGroup.markAsPristine();
      }

      this.isLoading = false;
    } else {
      this.buildForm();
      this.setBehaviour();
      this.isLoading = false;
    }
  }
  setBehaviour() {
    const startMeasuredDepthControl = this.formRunGroup
      ?.get('timestamp')
      ?.get('startMeasuredDepth');
    const TDMeasuredDepthControl = this.formRunGroup
      ?.get('timestamp')
      ?.get('TDMeasuredDepth');

    startMeasuredDepthControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const startMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          value,
          ',',
          ''
        );
        const TDMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          TDMeasuredDepthControl?.value,
          ',',
          ''
        );

        if (TDMDValue && startMDValue > TDMDValue)
          startMeasuredDepthControl?.setErrors({
            orderStartMDAndTDMD: this.messageErrors.orderStartMDAndTDMD,
          });
        TDMeasuredDepthControl?.setErrors(null);
      });

    TDMeasuredDepthControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const TDMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          value,
          ',',
          ''
        );
        const startMDValue = +AppHelper.UtileFunctions.replaceAllCharacter(
          startMeasuredDepthControl?.value,
          ',',
          ''
        );
        if (startMDValue && TDMDValue < startMDValue)
          TDMeasuredDepthControl?.setErrors({
            orderTDMDAndStartMD: this.messageErrors.orderTDMDAndStartMD,
          });
        startMeasuredDepthControl?.setErrors(null);
      });

    this.informG
      .get('type')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((type) => {
          const bitSize = this.informG.get('bitSize');
          const reamerSize = this.informG.get('reamerSize');
          const casingStringSize = this.informG.get('casingStringSize');

          if (type) {
            if (type.type === 'Non-Drilling') {
              this.typeDescription = this.nonDrillingText;

              bitSize?.disable();
              bitSize?.setValidators([]);

              reamerSize?.disable();
              reamerSize?.setValidators([]);

              casingStringSize?.enable();
              casingStringSize?.setValidators([Validators.required]);
              casingStringSize?.value
                ? casingStringSize?.setValue(casingStringSize?.value)
                : casingStringSize?.setValue(null);
            } else {
              this.typeDescription = this.drillingText;

              bitSize?.setValidators([Validators.required]);
              bitSize?.enable();
              bitSize?.value
                ? bitSize?.setValue(bitSize?.value)
                : bitSize?.setValue(null);

              reamerSize?.enable();
              // reamerSize?.setValidators([Validators.required]);

              casingStringSize?.disable();
              casingStringSize?.setValidators([]);
            }
          } else {
            this.typeDescription = 'Select type of run';

            bitSize?.disable();
            bitSize?.setValidators([]);

            reamerSize?.disable();
            reamerSize?.setValidators([]);

            casingStringSize?.disable();
            casingStringSize?.setValidators([]);
          }
        })
      )
      .subscribe();
  }

  loadNameOfFiles(linkPDF?: string) {
    this.lastPDFLink = linkPDF ? linkPDF : '';

    if (!linkPDF) {
      this.fileLabel = 'Browse file';
      return;
    } else {
      const nameFile = AppHelper.UtileFunctions.getFileNameFromUrl(linkPDF);

      this.fileLabel = nameFile;

      this.uploadTally!._files = [];

      const uploadedFile = new File([new Blob()], nameFile, {
        type: 'application/pdf',
      });
      this.uploadTally!._files.push(uploadedFile);

      this.informG.get('hiddenTextDesign')?.setValue(uploadedFile.size);
      this.getRefresh('hiddenTextDesign');

      this.showCancelButtonUpLoad = true;
    }
  }

  checkInactiveRunAvailable() {
    let check = true;
    (this.rigListDataRun || []).forEach((run) => {
      let condition =
        run.belowRotaryTimestamp &&
        run.runTdTimestamp &&
        run.aboveRotaryTimestamp &&
        run.startMeasuredDepth &&
        run.tdMeasuredDepth;

      if (!condition) {
        check = false;
      }
    });
    return check;
  }
  buildForm() {
    let inactiverRunAvailable = this.checkInactiveRunAvailable();
    this.formRunGroup = this._builder.group({
      inform: this._builder.group({
        [this.fieldNameKeys.wellboreId]: [
          { value: this.wellbore.wellboreId, disabled: true },
        ],
        [this.fieldNameKeys.wellboreName]: [
          { value: this.wellbore.wellboreCurrentName || this.wellbore.wellboreOriginalName, disabled: true },
        ],

        [this.fieldNameKeys.intervalId]: [
          { value: this.interval.intervalId, disabled: true },
        ],
        [this.fieldNameKeys.intervalName]: [
          { value: this.interval.intervalName, disabled: true },
        ],

        [this.fieldNameKeys.runDescription]: [
          '',
          [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator],
        ],
        [this.fieldNameKeys.runNumber]: ['', Validators.required],
        [this.fieldNameKeys.rigBHANumber]: [''],

        [this.fieldNameKeys.type]: ['', Validators.required],

        [this.fieldNameKeys.bitSize]: [
          { value: '', disabled: true },
          Validators.required,
        ],
        [this.fieldNameKeys.reamerSize]: [{ value: '', disabled: true }],
        [this.fieldNameKeys.casingStringSize]: [{ value: '', disabled: true }],
        [this.fieldNameKeys.hiddenTextTally]: [''],
      }),
      timestamp: this._builder.group({
        [this.fieldNameKeys.runTDAboveRotaryTimestamp]: [
          null,
          [
            this.dateOrderValidator(
              this.fieldNameKeys.belowRotaryTimestamp,
              this.fieldNameKeys.runTDTimestamp,
              this.fieldNameKeys.runTDAboveRotaryTimestamp,
              3,
              this.fieldNameKeys
            ),
            inactiverRunAvailable
              ? Validators.nullValidator
              : Validators.required,
          ],
        ],
        [this.fieldNameKeys.runTDTimestamp]: [
          null,
          [
            this.dateOrderValidator(
              this.fieldNameKeys.belowRotaryTimestamp,
              this.fieldNameKeys.runTDTimestamp,
              this.fieldNameKeys.runTDAboveRotaryTimestamp,
              2,
              this.fieldNameKeys
            ),
            inactiverRunAvailable
              ? Validators.nullValidator
              : Validators.required,
          ],
        ],
        [this.fieldNameKeys.belowRotaryTimestamp]: [
          null,
          [
            this.dateOrderValidator(
              this.fieldNameKeys.belowRotaryTimestamp,
              this.fieldNameKeys.runTDTimestamp,
              this.fieldNameKeys.runTDAboveRotaryTimestamp,
              1,
              this.fieldNameKeys
            ),
            inactiverRunAvailable
              ? Validators.nullValidator
              : Validators.required,
          ],
        ],
        [this.fieldNameKeys.startMeasuredDepth]: [
          '',
          [
            inactiverRunAvailable
              ? Validators.nullValidator
              : Validators.required,
          ],
        ],
        [this.fieldNameKeys.TDMeasuredDepth]: [
          '',
          [
            inactiverRunAvailable
              ? Validators.nullValidator
              : Validators.required,
          ],
        ],
      }),
    });
  }

  buildFormEdit(run: any) {
    let isInactiveRun =
      run.belowRotaryTimestamp &&
      run.runTdTimestamp &&
      run.aboveRotaryTimestamp &&
      run.startMeasuredDepth &&
      run.tdMeasuredDepth
        ? true
        : false;

    let intervalIdInformRunEdit = run.intervalId;
    let intervalNameInformRunEdit = this.intervalList.find(
      (interval: any) => interval.intervalId === run.intervalId
    )?.intervalName;

    const wellboreInFormRunEdit = this.rigListDataInterval.find(
      (interval: any) => interval.intervalId === intervalIdInformRunEdit
    );

    let wellboreNameInFormRunEdit = wellboreInFormRunEdit?.currentWellboreName || wellboreInFormRunEdit?.originWellboreName;
    let wellboreIdInFormRunEdit = wellboreInFormRunEdit?.wellboreId;

    if (!wellboreNameInFormRunEdit) {
      wellboreNameInFormRunEdit = this.wellboreNameOverride;
    }

    let isDrillingAssembly =
      this.runTypeList.find((type) => type.type_id === run.type).type ===
      'Drilling'
        ? true
        : false;

    let bitSize = run.bitSize ? this.convertNumber(run.bitSize) : null;
    let reamerSize = run.reamerSize ? this.convertNumber(run.reamerSize) : null;
    let casingSize = run.casingSize ? this.convertNumber(run.casingSize) : null;
    let startMeasuredDepth = run.startMeasuredDepth
      ? Number(
          run.startMeasuredDepth?.toString().replace(/,/g, '') || ''
        ).toLocaleString('en-US', { useGrouping: true })
      : null;
    let tdMeasuredDepth = run.tdMeasuredDepth
      ? Number(
          run.tdMeasuredDepth?.toString().replace(/,/g, '') || ''
        ).toLocaleString('en-US', { useGrouping: true })
      : null;

    this.formRunGroup = this._builder.group({
      inform: this._builder.group({
        [this.fieldNameKeys.runId]: [{ value: run.runId, disabled: true }],
        [this.fieldNameKeys.wellboreId]: [
          { value: wellboreIdInFormRunEdit, disabled: true },
        ],
        [this.fieldNameKeys.wellboreName]: [
          { value: wellboreNameInFormRunEdit, disabled: true },
        ],

        [this.fieldNameKeys.intervalId]: [
          { value: intervalIdInformRunEdit, disabled: true },
        ],
        [this.fieldNameKeys.intervalName]: [
          {
            value: intervalNameInformRunEdit,
            disabled: true,
          },
        ],

        [this.fieldNameKeys.runDescription]: [
          this.run.description,
          [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator],
        ],
        [this.fieldNameKeys.runNumber]: [run.runNo, Validators.required],
        [this.fieldNameKeys.rigBHANumber]: [run.rigBhaNo],

        [this.fieldNameKeys.type]: [
          this.runTypeList.find((type) => type.type_id === run.type),
          Validators.required,
        ],

        [this.fieldNameKeys.bitSize]: [
          { value: bitSize, disabled: !isDrillingAssembly },
          Validators.required,
        ],
        [this.fieldNameKeys.reamerSize]: [
          { value: reamerSize, disabled: !isDrillingAssembly },
        ],
        [this.fieldNameKeys.casingStringSize]: [
          { value: casingSize, disabled: isDrillingAssembly },

          Validators.required,
        ],
        [this.fieldNameKeys.hiddenTextTally]: [''],
      }),
      timestamp: this._builder.group({
        [this.fieldNameKeys.belowRotaryTimestamp]: [
          run.belowRotaryTimestamp ? new Date(run.belowRotaryTimestamp) : null,
          [
            this.dateOrderValidator(
              this.fieldNameKeys.belowRotaryTimestamp,
              this.fieldNameKeys.runTDTimestamp,
              this.fieldNameKeys.runTDAboveRotaryTimestamp,
              1,
              this.fieldNameKeys
            ),
            isInactiveRun ? Validators.required : Validators.nullValidator,
          ],
        ],
        [this.fieldNameKeys.startMeasuredDepth]: [
          startMeasuredDepth,
          [isInactiveRun ? Validators.required : Validators.nullValidator],
        ],

        [this.fieldNameKeys.runTDTimestamp]: [
          run.runTdTimestamp ? new Date(run.runTdTimestamp) : null,
          [
            this.dateOrderValidator(
              this.fieldNameKeys.belowRotaryTimestamp,
              this.fieldNameKeys.runTDTimestamp,
              this.fieldNameKeys.runTDAboveRotaryTimestamp,
              2,
              this.fieldNameKeys
            ),
            isInactiveRun ? Validators.required : Validators.nullValidator,
          ],
        ],
        [this.fieldNameKeys.TDMeasuredDepth]: [
          tdMeasuredDepth,
          [isInactiveRun ? Validators.required : Validators.nullValidator],
        ],

        [this.fieldNameKeys.runTDAboveRotaryTimestamp]: [
          run.aboveRotaryTimestamp ? new Date(run.aboveRotaryTimestamp) : null,
          [
            this.dateOrderValidator(
              this.fieldNameKeys.belowRotaryTimestamp,
              this.fieldNameKeys.runTDTimestamp,
              this.fieldNameKeys.runTDAboveRotaryTimestamp,
              3,
              this.fieldNameKeys
            ),
            isInactiveRun ? Validators.required : Validators.nullValidator,
          ],
        ],
      }),
    });
  }

  get informG(): FormGroup {
    return this.formRunGroup.get('inform') as FormGroup;
  }

  get timestampG(): FormGroup {
    return this.formRunGroup.get('timestamp') as FormGroup;
  }

  submitRun(event: any) {
    this.isLoading = true;

    this.onClearErrorTimestamp();
    let informGroup = this.formRunGroup.get('inform')?.getRawValue();
    let timestamp = this.formRunGroup.get('timestamp')?.getRawValue();
    let payload = {
      wellboreId: informGroup.wellboreId,
      intervalId: informGroup.intervalId,
      runDescription: informGroup.runDescription,
      runNumber: informGroup.runNumber,
      rigBHANumber: informGroup.rigBHANumber,
      type: informGroup.type.type_id,
      bitSize: informGroup?.bitSize || '',
      reamerSize: informGroup?.reamerSize || '',
      casingStringSize: informGroup.casingStringSize,

      belowRotaryTimestamp:
        AppHelper.DateFunctions.formatTime(timestamp.belowRotaryTimestamp) ===
        'Invalid Date'
          ? null
          : AppHelper.DateFunctions.formatTime(timestamp.belowRotaryTimestamp),

      startMeasuredDepth: timestamp?.startMeasuredDepth || '',

      runTDTimestamp:
        AppHelper.DateFunctions.formatTime(timestamp.runTDTimestamp) ===
        'Invalid Date'
          ? null
          : AppHelper.DateFunctions.formatTime(timestamp.runTDTimestamp),
      TDMeasuredDepth: timestamp?.TDMeasuredDepth || '',

      runTDAboveRotaryTimestamp:
        AppHelper.DateFunctions.formatTime(
          timestamp.runTDAboveRotaryTimestamp
        ) === 'Invalid Date'
          ? null
          : AppHelper.DateFunctions.formatTime(
              timestamp.runTDAboveRotaryTimestamp
            ),
      tally: this.lastPDFLink ? true : this.uploadTally!._files[0],
      unit: this.projectUnit || '',
    };

    if (this.formRunGroup.status !== 'INVALID') {
      if (!this.run) {
        this._homeService
          .addRun(payload)
          .pipe(catchError(AppHelper.UtileFunctions.handleError))
          .subscribe({
            next: (response) => {
              this.hide_formRun.emit(false);
              this.reload_formRun.emit();
              this.isLoading = false;
            },
            error: (error) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Add Run',
                content: error?.message || error,
              });
              this.isLoading = false;
            },
            complete: () => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Add Run',
                content: 'Run was created successfully!',
              });
              this.isLoading = false;
            },
          });
      } else {
        // kiểm tra đây là một edit bth hay là edit change status fromChangeStatus

        if (this.fromChangeStatus) {

          this._homeService
            .updateRun(payload, this.run.runId)
            .pipe(
              finalize(() => this.isLoading = false),
              catchError((error) => {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Update Wellbore Status',
                  content: error?.message || error,
                });
                return throwError(() => new Error(error?.message || error));
              }),
              concatMap(() => {
                return this._homeService.updateWellboreStatus(this.returnRigId, this.returnWellboreId)
                .pipe(
                  catchError((error) => {
                    this._notificationService.setMessage({
                      type: AppConstant.MESSAGE_TYPE.WARNING,
                      header: 'Update Wellbore Status',
                      content: error?.message || error,
                    });
                    return throwError(() => new Error(error?.message || error));
                  }),
                )
              }),
              switchMap((res: any) => {
                if (res?.data) {
                  const wellboreActive = res?.data;
                  let payloadFilterLog = AppConstant.DEFAULT_HOME_FILTER;

                  if (wellboreActive && !wellboreActive?.isComplete) {
                    const rigJournalId = wellboreActive?.rigJournalId;
                    const rigName = wellboreActive?.rig?.rigName;
                    
                    payloadFilterLog = this.addFilterRigJournalId(rigJournalId);  
                    this._interventionService.setLogFilter({
                      rigJournalId: rigJournalId,
                      rigName: rigName
                    });
                  } 
                  // If dont have !wellboreActive.isComplete then skip
                  // Or check Restore if needed. Deprecated

                  return forkJoin([
                    of(res),
                    this._interventionService.getInterventionLogsFilter(payloadFilterLog, 0, AppConstant.ITEMS_PER_PAGE),
                  ]);
                } 
                return  forkJoin([
                  of(res),
                  of(null)
                ]);
              })
            ).subscribe({
              next: (res: any) => {
                const [ response, alertLogs ] = res;
                this._notificationService.setMessage({
                  type: response?.message.includes('successfully')
                    ? AppConstant.MESSAGE_TYPE.SUCCESS
                    : AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Update Wellbore Status',
                  content:
                    AppHelper.StringFunctions.capitalizeFirstLetter(
                      response.message
                    ),
                });

                this.hide_formRunEdit.emit(false);
                this.reload_formRun.emit();
                this.request_changeTab.emit(0);

                // Set Intervention Log Filter
                if (alertLogs)
                  this.showDataAlertLogs(alertLogs);
              },
              error: (error) => {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Update Wellbore Status',
                  content: error?.message || error,
                });
              },
            });
        } else {
          this._homeService
            .updateRun(payload, this.run.runId)
            .pipe(catchError(AppHelper.UtileFunctions.handleError))
            .subscribe({
              next: (response) => {
                this.hide_formRunEdit.emit(false);
                this.reload_formRun.emit();
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.SUCCESS,
                  header: 'Update Run',
                  content: 'Run was updated successfully!',
                });
                this.isLoading = false;
              },
              error: (error) => {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: 'Update Run',
                  content: error?.message || error,
                });
                this.isLoading = false;
              },
            });
        }
      }
    } else {
      this.formRunGroup.get('timestamp')?.markAllAsTouched();
      this.onClearErrorTimestamp();
    }
  }

  closeFormRunDialog() {
    if (this.fromChangeStatus) {
      this.request_changeTab.emit(0);
    }
    if (this.formRunGroup.touched || !this.formRunGroup.pristine) {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    } else {
      this.hide_formRun.emit(false);
      this.hide_formRunEdit.emit(false);
    }
  }

  closeDialogForm() {
    this.hide_formRun.emit(false);
    this.hide_formRunEdit.emit(false);
  }

  onSelect_tally(event: any) {
    let errorUpload = this.uploadTally?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });

      this.onClear_tally();
      return;
    }

    this.currentDesignSizeUpload = this.uploadTally!._files[0].size;

    if (this.currentDesignSizeUpload > this.maxFileSizeUpload) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Upload Document',
        content: 'Maximum total upload size of Design is 10 MB.',
      });
      return;
    }

    this.formRunGroup.get('hiddenTextTally')?.setValue('HaveValue');
    this.progressUploadTally!.nativeElement.style.display = 'block';

    if (this.value === 100) {
      this.value = 0;
    }

    this.uploadTally!.showCancelButton = true;

    // pipe letter here
    this.uploadTally!.chooseLabel = AppHelper.StringFunctions.shortenFileName(
      this.uploadTally!._files[0].name,
      12,
      7
    );

    this.fileLabel = this.uploadTally!._files[0].name;

    this.lastPDFLink = '';

    // loading run
    let interval = setInterval(() => {
      this.value =
        this.value +
        Math.floor(AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25);
      if (this.value >= 100) {
        this.value = 100;
        clearInterval(interval);
        this.progressUploadTally!.nativeElement.style.display = 'none';
      }
    }, 1000);
  }
  onClear_tally(e?: any) {
    this.lastPDFLink = '';

    this.fileLabel = 'Browse file';

    this.uploadTally!.chooseLabel = 'Browse file';
    this.progressUploadTally!.nativeElement.style.display = 'none';
    this.uploadTally!.showCancelButton = false;

    this.formRunGroup.get('hiddenTextTally')?.patchValue('');
    this.getRefresh('hiddenTextTally');

    this.currentDesignSizeUpload = 0;
  }

  getRefresh(controlName: string) {
    const control = this.formRunGroup.get(controlName);
    control?.clearValidators();
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  isAscending(arr: number[]): boolean {
    return arr.every(function (x: number, i: number) {
      if (i === 0) {
        return true;
      }
      const prev = arr[i - 1];
      return x >= prev;
    });
  }

  private removeElement(arrayName: number[], arrayElement: number) {
    for (var i = 0; i < arrayName.length; i++) {
      if (arrayName[i] == arrayElement) arrayName.splice(i, 1);
    }
  }

  private dateOrderValidator(
    controlName_1: string,
    controlName_2: string,
    controlName_3: string,
    order: number,
    fieldNameKeys: any
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;
        const currentControl_1 = _formGroup.get(fieldNameKeys[controlName_1]);
        const currentControl_2 = _formGroup.get(fieldNameKeys[controlName_2]);
        const currentControl_3 = _formGroup.get(fieldNameKeys[controlName_3]);

        let dateArray = [
          new Date(
            AppHelper.DateFunctions.formatTime(currentControl_1?.value)
          ).getTime(),
          new Date(
            AppHelper.DateFunctions.formatTime(currentControl_2?.value)
          ).getTime(),
          new Date(
            AppHelper.DateFunctions.formatTime(currentControl_3?.value)
          ).getTime(),
        ];
        let checkDateArray = [...dateArray];
        this.removeElement(checkDateArray, 0);
        let countNull = 0;
        if (currentControl_1 && currentControl_2 && currentControl_3) {
          checkDateArray.forEach((element) => {
            if (!element) {
              countNull++;
            }
          });

          if (countNull > 1) {
            return false;
          }
          if (
            checkDateArray.filter((item) => Number.isNaN(item)).length === 0
          ) {
            if (!this.isAscending(checkDateArray) && order === 1) {
              return { order_1: true };
            }

            if (!this.isAscending(checkDateArray) && order === 2) {
              return { order_2: true };
            }
            if (!this.isAscending(checkDateArray) && order === 3) {
              return { order_3: true };
            }
          }
          if (
            checkDateArray.filter((item) => Number.isNaN(item)).length === 1
          ) {
            let indexNaN = checkDateArray.findIndex((item) =>
              Number.isNaN(item)
            );
            let newDateArrayNotNaN = checkDateArray.filter(
              (item) => !Number.isNaN(item)
            );
            if (indexNaN === 0) {
              if (!this.isAscending(newDateArrayNotNaN) && order === 2) {
                return { order_2: true };
              }
              if (!this.isAscending(newDateArrayNotNaN) && order === 3) {
                return { order_3: true };
              }
            }
            if (indexNaN === 1) {
              if (!this.isAscending(newDateArrayNotNaN) && order === 1) {
                return { order_1: true };
              }
              if (!this.isAscending(newDateArrayNotNaN) && order === 3) {
                return { order_3: true };
              }
            }
            if (indexNaN === 2) {
              if (!this.isAscending(newDateArrayNotNaN) && order === 1) {
                return { order_1: true };
              }
              if (!this.isAscending(newDateArrayNotNaN) && order === 2) {
                return { order_2: true };
              }
            }
          }
        } else {
          return null;
        }
      }

      return null;
    };
  }

  private depthOrderValidator(
    controlName_1: string,
    controlName_2: string,
    order: number,
    fieldNameKeys: any
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;

        const currentControl_1 = _formGroup.get(fieldNameKeys[controlName_1]);
        const currentControl_2 = _formGroup.get(fieldNameKeys[controlName_2]);

        let dateArray = [
          +AppHelper.UtileFunctions.replaceAllCharacter(
            currentControl_1?.value,
            ',',
            ''
          ),
          +AppHelper.UtileFunctions.replaceAllCharacter(
            currentControl_2?.value,
            ',',
            ''
          ),
        ];

        let checkDateArray = [...dateArray];
        // this.removeElement(checkDateArray, 0);

        if (currentControl_1 && currentControl_2) {
          if (!(currentControl_1.value && currentControl_2.value)) {
            return null;
          }

          if (!this.isAscending(checkDateArray) && order === 4) {
            return { order_4: true };
          }

          if (!this.isAscending(checkDateArray) && order === 5) {
            return { order_5: true };
          }

          if (this.isAscending(checkDateArray) && order === 5) {
            // Check the value in ascending order in the correct order, no matter what field is onInput, it clears all errors
            currentControl_1.setErrors(null);
            currentControl_1.updateValueAndValidity();
          }
        }
      }

      return null;
    };
  }
  onSelectDate(
    event: any,
    formControl: any,
    controlNameArray: any,
    fieldNameKeys: any
  ) {
    for (let i = 0; i < controlNameArray.length; i++) {
      const _formGroup = formControl as FormGroup;
      _formGroup
        .get(fieldNameKeys[controlNameArray[i]])
        ?.updateValueAndValidity();
    }
    this.onClearErrorTimestamp();
  }

  private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  updateFieldValue(controlName: string, groupName: string) {
    const control = this.formRunGroup.get(groupName);
    control?.get(controlName)?.updateValueAndValidity();
  }

  removeErrorField(controlName: string, groupName: string) {
    const control = this.formRunGroup.get(groupName);
    control?.get(controlName)?.setErrors(null);
  }

  onClearErrorTimestamp() {
    this.updateFieldValue(this.fieldNameKeys.belowRotaryTimestamp, 'timestamp');
    this.updateFieldValue(
      this.fieldNameKeys.runTDAboveRotaryTimestamp,
      'timestamp'
    );
    this.updateFieldValue(this.fieldNameKeys.runTDTimestamp, 'timestamp');
  }

  onClearErrorDepth() {
    this.removeErrorField(this.fieldNameKeys.startMeasuredDepth, 'inform');
    this.removeErrorField(this.fieldNameKeys.TDMeasuredDepth, 'inform');
  }

  onDestroy(): void {}

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formRunGroup.get('timestamp')?.get(controlName);
    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const errorObj = this.formRunGroup
      .get('timestamp')
      ?.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  convertNumber(number: number | string): string {
    const notNumber = /[^0-9.]/g;
    return Number(number.toString().replace(notNumber, '')).toLocaleString(
      'en-US',
      {
        useGrouping: true,
      }
    );
  }

  showDataAlertLogs(alertResponse: { items: any, total: number  }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    this._interventionService.setLoading(false);
  }

  addFilterRigJournalId(rigJournalId: string) {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    return this._homeService.getPayloadFilter();
  }
}
