<p-table
  [value]="projectListData"
  [columns]="projectListColumn"
  [scrollable]="true"
  [resizableColumns]="true"
  styleClass="p-datatable-striped custom-table"
>
  <ng-template pTemplate="header" let-columns>
    <tr style="height: 40px">
      <th
        *ngFor="let col of columns; last as isLast; let i = index"
        [pSortableColumn]="col.field"
        pResizableColumn
        [ngClass]="isLast ? 'th-last' : 'th-default'"
      >
        <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
        </ng-container>

        <ng-template #showThNotLast>
          <div
            [pTooltip]="col.header"
            tooltipPosition="bottom"
            class="th-content"
          >
            {{ col.header }}
          </div>

          <div class="header-sort-icon">
            <i
              class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
            ></i>

            <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></i>
          </div>
        </ng-template>
        <ng-template #showThLast></ng-template>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr (click)="handleRowClick($event, rowData)">
      <td class="td-default primary-color primary-link">
        <span
          [pTooltip]="rowData.projectName || 'N/A'"
          tooltipPosition="bottom"
          (click)="redirectToRig(rowData.projectId)"
        >
          {{ rowData.projectName || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.company || 'N/A'" tooltipPosition="bottom">
          {{ rowData.company || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.countryName | NA" tooltipPosition="bottom">
          {{ rowData.countryName || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.region || 'N/A'" tooltipPosition="bottom">
          {{ rowData.region || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.block || 'N/A'" tooltipPosition="bottom">
          {{ rowData.block || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.lease || 'N/A'" tooltipPosition="bottom">
          {{ rowData.lease || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.field || 'N/A'" tooltipPosition="bottom">
          {{ rowData.field || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.timezone || 'N/A'" tooltipPosition="bottom">
          {{ rowData.timezone || "N/A" }}
        </span>
      </td>
      <td class="td-default">
        <span [pTooltip]="rowData.curUnit || 'N/A'" tooltipPosition="bottom">
          {{ rowData.curUnit || "N/A" }}
        </span>
      </td>
      <td
        style="min-width: 50px; max-width: 50px"
        (click)="showContextMenu($event, rowData)"
        class="option-cell"
      >
        <div class="more-option-table">
          <svg-icon
            class="header-content__title--feature"
            key="dot3hoz"
            size="md"
          >
          </svg-icon>
        </div>
      </td>
      <app-menu
        #menu
        [optionList]="getMenuItemsForItem$ | async"
        [appendTo]="'body'"
        [styleClass]="'menu-table-item lower-item'"
      ></app-menu>
    </tr>
  </ng-template>
</p-table>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>

<ng-container *ngIf="displayEditProject">
  <app-home-edit-project
    [displayEditProject]="displayEditProject"
    [project]="editProject"
    (hideEditProject)="hideEditProject($event)"
  ></app-home-edit-project>
</ng-container>
