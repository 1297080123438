import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, of } from 'rxjs';
import { AlertFilterOptionsResponse } from '../type';
import { SortBy } from '../interface/sort-by.interface';

export interface AlertFilterOptions {
  timeRange?: number[],
  status?: string[], 
  actionTaken?: string[],
  timeSaved?: number[],
  rig?: string[]
  project?: string;
  well?: string[],
  event?: string[],
  alertType?: string[],
  engineer?: string[],
  remoteCenter?: string[],
  risk?: string[],

  discipline?: string[],
  wellbore?: string[],
  interval?: string[],
  run?: string[],

  pageSize: number,
  pageIndex: number,  
}

@Injectable()
export class AlertService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public itemDeletedSubject = new Subject<string>();
  itemDeleted$ = this.itemDeletedSubject.asObservable();

  triggerItemDeleted(itemId: string) {
    this.itemDeletedSubject.next(itemId);
  }

  public getAlertList(filterOptions?: AlertFilterOptions, sortBy?: SortBy[]): Observable<any[]> {
    const uri = 'intervention/v2';    
    return this.get<any[]>(uri, {
      params: {
        ...(filterOptions || {}),
        pageSize: filterOptions?.pageSize || 10,
        pageIndex: filterOptions?.pageIndex || 1,
        sortBy: (sortBy?.map(item => `${item.field}:${item.order}`) || []).join(',')
      }
    }).pipe(
      map((item: any) => {
        return item.data;
      })
    );
  }

  public getAlertListV2(filterOptions: AlertFilterOptions, rigJournalId: string): Observable<any[]> {
    const uri = `intervention/v2?rigJournalId=${rigJournalId}`;
    
    return this.get<any[]>(uri, {
      params: {
        ...(filterOptions || {}),
        pageSize: filterOptions?.pageSize || 10,
        pageIndex: filterOptions?.pageIndex || 1,
      }
    }).pipe(
      map((item: any) => {
        return item.data;
      })
    );
  }


  public getAlertFilterOptions(): Observable<AlertFilterOptionsResponse> {
    const uri = 'intervention/filter-options';    
    return this.get<any[]>(uri).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  public getAlertFilterOptionsbyId(rigJournalId: string): Observable<any> {
    const uri = `intervention/filter-options?rigJournalId=${rigJournalId}`;    
    return this.get<any[]>(uri).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }


  public getAlertInfoById(rigJournalId: string): Observable<any[]> {
    const uri = `rig-journal/project-list/${rigJournalId}`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        return item;
      })
    );
  }

  public getAlertTableById(rigJournalId: string): Observable<any[]> {
    const uri = `intervention?rigJournalId=${rigJournalId}`;
    return this.get<any[]>(uri).pipe(
      map((item: any) => {
        return item.data;
      })
    );
  }

}
