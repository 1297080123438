import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HelpCenterService } from '../help-center.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() informDisplay: any;
  @Input() visible: boolean = false;
  @Output() onDestroy = new EventEmitter();
  @Output() completed = new EventEmitter();

  checkedConfirm: boolean = false;
  visibleWarningdialog: boolean = false;
  constructor(
    private _helpCenterService: HelpCenterService,
    private _tutorialService: TutorialService,
    private _notificationService: NotificationService,
    private _collapseLayoutService: CollapseLayoutService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    switch (this.informDisplay.type) {
      case 'DELETE_CATEGORY_GUIDE': {
        if (this.informDisplay.itemSelected.chapterList.length) {
          // this.visibleWarningdialog = true;
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Delete Chapter',
            content: 'You cannot delete a chapter when it contains subchapter.',
          });
          this.visible = false;
          return;
        }
        // this._helpCenterService.subjectSetLoadding.next(true);
        this.visible = false;
        const deteleCategory = this._helpCenterService.deleteCategory(
          this.informDisplay.itemSelected.categoryId
        );
        const updateIndexArray = this._helpCenterService.updateIndexCategory({
          indexGuide: this.informDisplay.indexArray,
          indexSubChapter: btoa(
            JSON.stringify(this.informDisplay.indexArraySubChapterMaster)
          ),
        });

        forkJoin([deteleCategory, updateIndexArray]).subscribe({
          next: ([v1, v2]) => {},
          error: ([v1, v2]) => {},
          complete: () => {
            this.completed.emit();
          },
        });
        break;
      }
      case 'DELETE_CHAPTER': {
        this.visible = false;
        // this._helpCenterService.subjectSetLoadding.next(true);
        const updateIndexArray = this._helpCenterService.updateIndexCategory({
          indexSubChapter: btoa(
            JSON.stringify(this.informDisplay.indexArraySubChapterMaster)
          ),
        });
        const deleteChapter = this._helpCenterService.deleteChapter(
          this.informDisplay.itemSelected.chapterId
        );
        forkJoin([updateIndexArray, deleteChapter]).subscribe({
          next: ([v1, v2]) => {},
          error: ([v1, v2]) => {},
          complete: () => {
            this.completed.emit();
          },
        });

        break;
      }
      case 'DELETE_CHANNEL': {
        if (this.informDisplay.itemSelected.videoList.length) {
          // this.visibleWarningdialog = true;
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Delete Channel',
            content: 'You cannot delete a channel when it contains video.',
          });
          this.visible = false;
          return;
        }
        this.visible = false;
        // this._helpCenterService.subjectSetLoadding.next(true);
        const deteleCategory = this._helpCenterService.deleteCategory(
          this.informDisplay.itemSelected.categoryId
        );
        const updateIndexArray = this._helpCenterService.updateIndexChannel({
          indexChannel: this.informDisplay.indexArray,
        });

        forkJoin([deteleCategory, updateIndexArray]).subscribe({
          next: ([v1, v2]) => {},
          error: ([v1, v2]) => {},
          complete: () => {
            this.completed.emit();
          },
        });
        break;
      }
      case 'DELETE_CATEGORY_TUTORIAL': {
        if (this.informDisplay.itemSelected.chapterList.length) {
          // this.visibleWarningdialog = true;
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Delete Module',
            content: 'You cannot delete a Module when it contains topic.',
          });
          this.visible = false;
          return;
        }
        // this._helpCenterService.subjectSetLoadding.next(true);
        this.visible = false;
        const deteleCategory = this._helpCenterService.deleteCategory(
          this.informDisplay.itemSelected.categoryId
        );
        const updateIndexArray = this._helpCenterService.updateIndexCategory({
          indexGuide: this.informDisplay.indexArray,
          indexSubChapter: btoa(
            JSON.stringify(this.informDisplay.indexArraySubChapterMaster)
          ),
        });

        forkJoin([deteleCategory, updateIndexArray]).subscribe({
          next: ([v1, v2]) => {},
          error: ([v1, v2]) => {},
          complete: () => {
            this.completed.emit();
          },
        });
        break;
      }
      case 'DELETE_TOPIC': {
        this.visible = false;
        // this._helpCenterService.subjectSetLoadding.next(true);
        const updateIndexArray = this._helpCenterService.updateIndexCategory({
          indexSubChapter: btoa(
            JSON.stringify(this.informDisplay.indexArraySubChapterMaster)
          ),
        });
        const deleteChapter = this._tutorialService.deleteTopic(
          this.informDisplay.itemSelected.topicId
        );
        forkJoin([updateIndexArray, deleteChapter]).subscribe({
          next: ([v1, v2]) => {
            // Trigger Get New Tutorial Panel
            if(this._collapseLayoutService.getCollapsePanelLayout()) {
              this._collapseLayoutService.triggerResetTutorialPanel();
            }
          },
          error: ([v1, v2]) => {},
          complete: () => {
            this.completed.emit();
          },
        });

        break;
      }
    }
  }
}
