import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownModule } from 'primeng/dropdown';
import { HelpCenterComponent } from './help-center.component';
import { FormsModule } from '@angular/forms';
import { TabviewVideoComponent } from './tabview-video/tabview-video.component';
import { VideoCardComponent } from './video-card/video-card.component';
import { TabviewGuideComponent } from './tabview-guide/tabview-guide.component';
import { AddVideoComponent } from './add-video/add-video.component';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PickListModule } from 'primeng/picklist';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DetailVideoComponent } from './detail-video/detail-video.component';
import { EditVideoComponent } from './edit-video/edit-video.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { TabviewTermsOfUseComponent } from './tabview-terms-of-use/tabview-terms-of-use.component';
import { CommonVideoComponent } from './comment-video/comment-video.component';
import { ShareModule } from 'src/app/shared/shared.module';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { TabviewTutorialComponent } from './tabview-tutorial/tabview-tutorial.component';
import { TabviewReleaseNoteComponent } from './tabview-release-note/tabview-release-note.component';
import { FormReleaseNoteComponent } from './tabview-release-note/form-release-note/form-release-note.component';
@NgModule({
  declarations: [
    HelpCenterComponent,
    TabviewVideoComponent,
    VideoCardComponent,
    TabviewGuideComponent,
    AddVideoComponent,
    ReportIssueComponent,
    DetailVideoComponent,
    EditVideoComponent,
    ConfirmDialogComponent,
    CreateDialogComponent,
    TabviewTermsOfUseComponent,
    CommonVideoComponent,
    TabviewTutorialComponent,
    TabviewReleaseNoteComponent,
    FormReleaseNoteComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    PickListModule,
    ReactiveFormsModule,
    MatIconModule,
    DynamicDialogModule,
    ShareModule,
  ],
  providers: [ConfirmationService, DialogService],
})
export class HelpVideoModule {}
