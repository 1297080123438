<div class="mb-2">
  <app-customizable-button
    [showButton]="true" 
    [label]="'Add Interval'" 
    [style]="'light-sm'"
    [isDisabled]="false"
    [elementId]="'addIntervalButton'"
    [isToolTip]="false"
    [toolTipContent]="''"
    [tooltipPosition]="'bottom'"
    (eventButton)="addInverval()">
  </app-customizable-button>
</div>
<form
  #searchForm="ngForm"
  *ngIf="!!formIntervalItem"
  [formGroup]="formIntervalItem"
>
  <div class="project__filter rig-list__filter filter-wrapper">
    <div class="d-flex align-items-end">
      <div class="rig-list__filter">
        <label for="float-input-invalid" class="label-disabled">Project</label>
        <span class="">
          <input
            type="text"
            pInputText
            placeholder="Project"
            class="search-input custom-p-input"
            maxlength="50"
            name="searchString"
            [formControlName]="fieldNameKeys.project"
          />
        </span>
      </div>

      <div class="rig-list__filter project__filter--dropdown">
        <label for="float-input-invalid"
          >Well<span class="p-error"> *</span></label
        >
        <p-dropdown
          [options]="restoreWellList"
          optionLabel="name"
          [showClear]="true"
          placeholder="Select value"
          class="search-input custom-p-drop"
          [formControlName]="fieldNameKeys.well"
          (onClear)="onClearWell()"
          (onChange)="bindingWell($event)"
          [resetFilterOnHide]="true"
        ></p-dropdown>
      </div>

      <div class="rig-list__filter project__filter--dropdown">
        <label for="float-input-invalid"
          >Wellbore<span class="p-error"> *</span></label
        >
        <!-- optionLabel="name" -->
        <p-dropdown
          [options]="rigListDataWellbore"
          [showClear]="true"
          placeholder="Select value"
          class="search-input custom-p-drop"
          [formControlName]="fieldNameKeys.wellbore"
          (onClear)="onClearWellbore()"
          (onChange)="bindingWellbore()"
          [resetFilterOnHide]="true"
        >
          <ng-template let-wellbore pTemplate="selectedItem">
            <div class="" *ngIf="fieldNameKeys.wellbore">
              <div class="d-flex align-items-center">
                  {{ wellbore.wellboreCurrentName || wellbore.wellboreOriginalName }}
              </div>
            </div>
          </ng-template>
          <ng-template let-wellbore pTemplate="item">
            <div class="d-flex align-items-center">
              {{ wellbore.wellboreCurrentName || wellbore.wellboreOriginalName }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="project__filter-button project__filter-button--dark d-none">
        <p-button label="Filters"></p-button>
      </div>
    </div>
    <div class="rig-list__filter--counter">
      <span>Total: {{ rigListDataInterval.length }}</span>
    </div>
  </div>

  <p-table
    [value]="rigListDataInterval"
    [columns]="rigListIntervalColumn"
    [scrollable]="true"
    [resizableColumns]="true"
    styleClass="p-datatable-striped custom-table"
  >
    <ng-template pTemplate="header" let-columns>
      <tr style="height: 40px">
        <th
          *ngFor="let col of columns; last as isLast; let i = index"
          [pSortableColumn]="col.field"
          pResizableColumn
          [ngClass]="isLast ? 'th-last' : 'th-default'"
        >
          <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
          </ng-container>

          <ng-template #showThNotLast>
            <div
              [pTooltip]="col.header"
              tooltipPosition="bottom"
              class="th-content"
            >
              {{ col.header }}
            </div>
            <div class="header-sort-icon">
              <i
                class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
              ></i>

              <i
                class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
              ></i>
            </div>
          </ng-template>
          <ng-template #showThLast></ng-template>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td class="td-default">
          <span [pTooltip]="rowData.intervalName" tooltipPosition="bottom">
            {{ rowData.intervalName }}
          </span>
        </td>
        <td class="td-default">
          <ng-container
            *ngIf="
              !rowData.openHoleDiameter || rowData.openHoleDiameter === 'null'
            "
          >
            <span [pTooltip]="'N/A'" tooltipPosition="bottom"> N/A </span>
          </ng-container>
          <ng-container
            *ngIf="
              rowData.openHoleDiameter && rowData.openHoleDiameter !== 'null'
            "
          >
            <span
              [pTooltip]="
                rowData.openHoleDiameter
                  | unitConversionPipe
                    : UNIT_SYSTEM
                    : projectData.curUnit
                    : (rowData.unit ? rowData.unit : '')
                    : 'diameter'
                    : 0
                    : 0
                    : 0
                    : 0
              "
              tooltipPosition="bottom"
            >
              {{
                rowData.openHoleDiameter
                  | unitConversionPipe
                    : UNIT_SYSTEM
                    : projectData.curUnit
                    : (rowData.unit ? rowData.unit : "")
                    : "diameter"
                    : 0
                    : 0
                    : 0
                    : 0
              }}
            </span>
          </ng-container>
        </td>
        <td style="min-width: 50px; max-width: 50px">
          <div class="more-option-table">
            <svg-icon
              class="header-content__title--feature"
              key="dot3hoz"
              size="md"
              (click)="showContextMenu($event, rowData)"
            >
            </svg-icon>
          </div>
        </td>
        <app-menu
          #menu
          [optionList]="getMenuItemsForItem$ | async"
          [appendTo]="'body'"
          [styleClass]="'menu-table-item'"
        ></app-menu>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">{{ emptyMsg }}</td>
      </tr>
    </ng-template>
  </p-table>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</form>

<ng-container *ngIf="displayFormInterval">
  <app-home-form-interval
    [projectId]="projectData.id"
    [projectUnit]="projectData.curUnit"
    [interval]="editInterval"
    [rigId]="rigId"
    [well]="formIntervalItem.get('well')?.value"
    [wellbore]="formIntervalItem.get('wellbore')?.value"
    [displayIntervalForm]="displayFormInterval"
    [isViewer]="isViewer"
    (hide_editInterval)="hideEditInterval($event)"
    (reload_editInterval)="reloadEditInterval($event)"
  ></app-home-form-interval>
</ng-container>
