import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, catchError } from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';

@Component({
  selector: 'app-home-form-wellbore',
  templateUrl: './home-form-wellbore.component.html',
  styleUrls: ['./home-form-wellbore.component.scss'],
})
export class HomeFormWellboreComponent extends BaseComponent {
  @Input()
  wellbore: any;

  @Input()
  displayFormWellbore: boolean = false;

  @Input() isViewer: boolean = false;

  @Output()
  hide_formWellbore: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  reload_formWelbore: EventEmitter<any> = new EventEmitter<any>();

  titlePopup = 'Edit Wellbore';

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Variable of Form
  public formFormWellboreGroup!: FormGroup;

  public fieldNameKeys = {
    wellboreId: 'wellboreId',
    wellboreName: 'wellboreName',

    siteId: 'siteId',
    siteName: 'siteName',

    wellId: 'wellId',
    wellName: 'wellName',

    latitude: 'latitude',
    longitude: 'longitude',

    spudDate: 'spudDate',
    UWI: 'UWI',
    GUID: 'GUID',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    maxlength: 'Please enter no more than 100 characters',
    latitudeValidator: 'Latitude : max/min 90.0 to -90.0',
    longitudeValidator: 'Longitude : max/min 180.0 to -180.0',
  };

  private latitudeRegex: string = `^[-]?([1-8]?[0-9](\\.[0-9]{0,16})?|90(\\.[0]{0,16})?)$`;
  private longitudeRegex: string = `^[-]?(180(\\.[0]{0,16})?|((1[0-7][0-9])|([1-9]?[0-9]))(\\.[0-9]{0,16})?)$`;

  menuItems: any = [];
  public LENGTH_DECIMAL_SEPARATOR = 15;

  constructor(
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    if (this.wellbore) {
      this.buildFormEdit(this.wellbore);
      this.titlePopup = `${this.wellbore.wellboreOriginalName}`;
    } else {
      this.buildForm();
    }
  }
  buildForm() {}
  buildFormEdit(wellbore: any) {
    const latitudeValue = wellbore.isOverrideLatitude
      ? wellbore.wellboreSurfaceLatitude ??
        (wellbore.wellboreSurfaceLatitude
          ? wellbore.wellboreSurfaceLatitude
          : null)
      : wellbore.wellboreEDMSurfaceLatitude ??
        (wellbore.wellboreEDMSurfaceLatitude
          ? wellbore.wellboreEDMSurfaceLatitude
          : null);
    const longitudeValue = wellbore.isOverrideLongtitude
      ? wellbore.wellboreSurfaceLongitude ??
        (wellbore.wellboreSurfaceLongitude
          ? wellbore.wellboreSurfaceLongitude
          : null)
      : wellbore.wellboreEDMSurfaceLongitude ??
        (wellbore.wellboreEDMSurfaceLongitude
          ? wellbore.wellboreEDMSurfaceLongitude
          : null);

    this.formFormWellboreGroup = this._builder.group({
      [this.fieldNameKeys.siteId]: [{ value: wellbore.siteId, disabled: true }],
      [this.fieldNameKeys.siteName]: [
        wellbore.siteCurrentName || wellbore.siteOriginalName,
        [
          Validators.required,
          Validators.maxLength(100),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.wellId]: [wellbore.wellId],
      [this.fieldNameKeys.wellName]: [
        wellbore.wellCurrentName || wellbore.wellOriginalName,
        [
          Validators.required,
          Validators.maxLength(100),
          this.noWhitespaceValidator,
        ],
      ],
      [this.fieldNameKeys.wellboreId]: [
        { value: wellbore.wellboreId, disabled: true },
      ],
      [this.fieldNameKeys.wellboreName]: [
        wellbore.wellboreCurrentName || wellbore.wellboreOriginalName,
        [
          Validators.required,
          Validators.maxLength(100),
          this.noWhitespaceValidator,
        ],
      ],

      [this.fieldNameKeys.latitude]: [
        latitudeValue?.toString() ? `${latitudeValue}°` : null,
        [
          this.customCorrectField(
            this.fieldNameKeys,
            this.latitudeRegex,
            'latitude',
            'latitudeValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.longitude]: [
        longitudeValue?.toString() ? `${longitudeValue}°` : null,
        [
          this.customCorrectField(
            this.fieldNameKeys,
            this.longitudeRegex,
            'longitude',
            'longitudeValidator'
          ),
        ],
      ],
      [this.fieldNameKeys.spudDate]: [
        wellbore.isOverrideSpudDate
          ? wellbore.wellboreSpudDate
            ? new Date(wellbore.wellboreSpudDate)
            : null
          : wellbore.wellboreEDMSpudDate
          ? new Date(wellbore.wellboreEDMSpudDate)
          : null,
      ],
      [this.fieldNameKeys.UWI]: [
        wellbore.wellboreCurrentUWI || wellbore.wellboreOriginalUWI,
        Validators.maxLength(100),
      ],
      [this.fieldNameKeys.GUID]: [
        wellbore.wellboreGUID,
        Validators.maxLength(100),
      ],
    });
  }

  private customCorrectField(
    fieldNameKeys: any,
    regex: string,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;
        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        if (!currentControl?.value) {
          return null;
        }

        const reg = new RegExp(regex, 'g');
        let currentValue =
          currentControl?.value.toString().slice(-1) === '°'
            ? currentControl?.value.toString().slice(0, -1)
            : currentControl?.value;

        if (reg.test(currentValue)) {
          return null;
        } else {
          return { [keyValidator]: true };
        }
      }
    };
  }

  closeFormWellboreDialog() {
    if (
      this.formFormWellboreGroup.touched ||
      !this.formFormWellboreGroup.pristine
    ) {
      this._confirmService.setDialog({
        isVisible: true,
        header: 'Discard',
        haveCheckbox: false,
        checkboxLabel: '',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        disableSecondaryButton: false,
        haveBoxSelection: false,
        dataSelection: [],
        haveSecondaryMessage: false,
        secondaryMessage: '',
        haveTertiaryMessage: false,
        tertiaryDialogMessage: '',
        alignCenterButton: false,
        styleDialog: 'dark-dialog',
        isHeaderIcon: false,
        headerIcon: '',
        isHaveTextarea: false,
        textarePlaceHolder: '',
        textareaSize: 6,
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickInConfirmLeaveDialog(event),
        dataDropdownTemplate: [],
        dialogMessageTemplate: '',
        defaultTemplate: {id: 'default', name:'Default', isSelected: true},
        dataDropdownLanguage: [],
        dialogMessageLanguage: '',
        defaultLanguage: {id: 'en', name:'English', isSelected: true},
        isDisableDropdown: false,
      });
    } else {
      this.hide_formWellbore.emit(!this.displayFormWellbore);
    }
  }

  saveWellbore(event: any) {
    if (this.formFormWellboreGroup.status === 'INVALID') {
      return;
    }

    this.formFormWellboreGroup.markAsPristine();
    this.loading.next(true);

    let surfaceLatitude = this.formFormWellboreGroup.get('latitude')?.value;
    let surfaceLongitude = this.formFormWellboreGroup.get('longitude')?.value;
    let spudDate = this.formFormWellboreGroup.get('spudDate')?.value;
    let isOverrideLatitude: boolean = true;
    let isOverrideLongtitude: boolean = true;
    let isOverrideSpudDate: boolean = true;

    surfaceLatitude = surfaceLatitude?.toString().includes('°')
      ? surfaceLatitude.slice(0, -1)
      : surfaceLatitude;

    surfaceLongitude = surfaceLongitude?.toString().includes('°')
      ? surfaceLongitude.slice(0, -1)
      : surfaceLongitude;

    // Compare EDM data
    if (surfaceLatitude === this.wellbore.wellboreEDMSurfaceLatitude) {
      surfaceLatitude = null;
      isOverrideLatitude = false;
    }

    if (surfaceLongitude === this.wellbore.wellboreEDMSurfaceLongitude) {
      surfaceLongitude = null;
      isOverrideLongtitude = false;
    }
    if (
      new Date(spudDate).getTime() ===
      new Date(this.wellbore.wellboreEDMSpudDate).getTime()
    ) {
      spudDate = null;
      isOverrideSpudDate = false;
    }

    const payload = {
      siteName: this.formFormWellboreGroup.get('siteName')?.value,
      wellName: this.formFormWellboreGroup.get('wellName')?.value,
      wellboreName: this.formFormWellboreGroup.get('wellboreName')?.value,
      surfaceLatitude: surfaceLatitude,
      surfaceLongitude: surfaceLongitude,
      spudDate:
        AppHelper.DateFunctions.formatTime(spudDate) !== 'Invalid Date'
          ? AppHelper.DateFunctions.formatTime(spudDate)
          : null,
      isOverrideLatitude,
      isOverrideLongtitude,
      isOverrideSpudDate,
      uwi: this.formFormWellboreGroup.get('UWI')?.value ?? undefined,
      guid: this.formFormWellboreGroup.get('GUID')?.value ?? undefined,
    };

    if (!this.wellbore) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Update Wellbore',
        content: 'Wellbore failed to update',
      });
    } else {
      this._homeService
        .updateWellbore(payload, this.wellbore.wellboreId)
        .pipe(catchError(AppHelper.UtileFunctions.handleError))
        .subscribe({
          next: (response) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Update Wellbore',
              content: 'Wellbore was updated successfully!',
            });
            this.hide_formWellbore.emit(false);
            this.reload_formWelbore.emit([
              this.formFormWellboreGroup.get('wellId')?.value,
            ]);
            this.loading.next(false);
          },
          error: (error) => {
            console.log(error);

            this.loading.next(false);
            // error &&
            //   this._notificationService.setMessage({
            //     type: AppConstant.MESSAGE_TYPE.WARNING,
            //     header: 'Update Wellbore',
            //     content: error?.message || error,
            //   });
          },
        });
    }
  }

  closeDialogForm() {
    this.hide_formWellbore.emit(!this.displayFormWellbore);
  }

  onMenuShow(fomrKey: string, wellbore: any) {
    this.menuItems = [
      {
        label: 'Reset',
        icon: 'c-icons restore-icon',
        disabled: this.isViewer,
        command: () => {
          this.resetValue(fomrKey, wellbore);
        },
      },
    ];
  }

  resetValue(formKey: string, wellbore: any): void {
    switch (formKey) {
      case 'siteName':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.siteOriginalName);
        break;

      case 'wellName':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.wellOriginalName);
        break;

      case 'wellboreName':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.wellboreOriginalName);
        break;

      case 'latitude':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.wellboreEDMSurfaceLatitude);
        break;

      case 'longitude':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.wellboreEDMSurfaceLongitude);
        break;

      case 'spudDate':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(
            wellbore.wellboreEDMSpudDate
              ? new Date(wellbore.wellboreEDMSpudDate)
              : null
          );
        break;

      case 'UWI':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.wellboreOriginalUWI);
        break;

      case 'GUID':
        this.formFormWellboreGroup
          .get(formKey)
          ?.setValue(wellbore.wellboreGUID);
        break;
      default:
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.INFO,
          header: 'Reset',
          content: 'On Developing by Thanh.NguyenCong@Halliburton.com',
        });
        break;
    }
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    formGroupName.controls[controlName].patchValue(control?.value.trim());
  }

  public removeZeroFirst(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    let checkValue = control?.value?.toString();
    if (!checkValue) {
      formGroupName.controls[controlName].setValue(null);
      return;
    }

    try {
      checkValue = this.handleLocateNumber(checkValue);

      if (!checkValue) {
        formGroupName.controls[controlName].setValue(null);
      } else {
        formGroupName.controls[controlName].setValue(checkValue + '°');
      }
    } catch {
      formGroupName.controls[controlName].setValue(null);
    }
  }

  private handleLocateNumber(number: string) {
    let isPositive = true;
    const symbol2Remove = ['°', '-', '.'];
    while (symbol2Remove.includes(number[number.length - 1])) {
      number = number.slice(0, -1);
    }
    // Check number is Positive
    if (number[0] === '-') {
      isPositive = false;
      number = number.slice(1, number.length);
    }
    // add 0 at head
    if (number[0] === '.') number = '0' + number;

    const numberSeparation = number.split('.');
    // Remove number 0 redundancy at head
    if (numberSeparation[0]) {
      while (numberSeparation[0].length > 1 && numberSeparation[0][0] === '0') {
        numberSeparation[0] = numberSeparation[0].slice(
          1,
          numberSeparation[0].length
        );
      }
    }

    // Remove number 0 redundancy at tail
    if (numberSeparation[1]) {
      numberSeparation[1] = numberSeparation[1].slice(
        0,
        this.LENGTH_DECIMAL_SEPARATOR
      );
      while (numberSeparation[1][numberSeparation[1]?.length - 1] === '0') {
        numberSeparation[1] = numberSeparation[1].slice(0, -1);
      }
    }
    number = numberSeparation.join('.');
    while (symbol2Remove.includes(number[number.length - 1])) {
      number = number.slice(0, -1);
    }
    if (number !== '0' && !isPositive) number = '-' + number;
    return number;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control?.value || '').trim().length === 0;
    const moreThanOneCharecter = control?.value?.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  onButtonClickInConfirmLeaveDialog(option: OptionButtonType): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        this.closeDialogForm();
        break;
      case AppConstant.OPTION_BUTTON.NO:
      case AppConstant.OPTION_BUTTON.CANCEL:
      default:
        break;
    }

    this._confirmService.clearDialog();
  }

  onDestroy(): void {}
}
