import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
  providers: [MessageService],
})
export class ReportIssueComponent implements OnInit {
  @ViewChild('evidencesUploader') evidencesUploader!: FileUpload;

  formGroupReportIssue: FormGroup = this.fb.group({
    title: ['', [Validators.required, Validators.maxLength(50)]],
    description: ['', [Validators.required, Validators.maxLength(2000)]],
    evidences: [null],
  });
  isOnDrag: boolean = false;
  files: File[] = [];

  isViewer: boolean = false;
  destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private ref: DynamicDialogRef,
    private _userInfoService: UserInfoService,
  ) { }

  ngOnInit(): void {
    this.preventWhiteSpace('title');
    this.preventWhiteSpace('description');
    this._userInfoService.userSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (user) => {
        if (user) {
          this.isViewer = user.role === AppConstant.ROLES.VIEWER.label;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private buildPayload(): FormData {
    const payload = new FormData();
    payload.append('title', AppHelper.UtileFunctions.encodeBase64(this.formGroupReportIssue.value.title));
    payload.append('description', AppHelper.UtileFunctions.encodeBase64(this.formGroupReportIssue.value.description));
    if (this.formGroupReportIssue.value.evidences)
      this.formGroupReportIssue.value.evidences.forEach((file: File) => {
        const fileName =  AppHelper.UtileFunctions.encodeFileName(file.name)
        payload.append('evidences', file, fileName);
      });
    return payload;
  }

  public onCancel(): void {
    this.ref.close();
  }

  public onSend(): void {
    this.ref.close(this.buildPayload());
  }

  public onUploaderSelect(uploader: string, event: any): void {
    this.validateFile(uploader, event.files);
    if (this.verifyTotalFileSize()) {
      this.files = [];
      this.files.push(...event.currentFiles);
      this.formGroupReportIssue.get(uploader)?.setValue(this.files);
      this.formGroupReportIssue.get(uploader)?.markAsTouched();
    }
  }

  public onUploaderClear(uploader: string): void {
    this.files = [];
    this.formGroupReportIssue.get(uploader)?.setValue(null);
    this.formGroupReportIssue.get(uploader)?.markAsUntouched();
  }

  private preventWhiteSpace(formControl: string): void {
    this.formGroupReportIssue
      .get(formControl)
      ?.valueChanges.subscribe((value) => {
        if (value === ' ')
          this.formGroupReportIssue.get(formControl)?.patchValue('');
          this.formGroupReportIssue.get(formControl)?.markAsPristine();
          this.formGroupReportIssue.get(formControl)?.markAsUntouched();
      });
  }

  public openSelectFileUpload(): void {
    this.evidencesUploader.choose();
  }

  public removeItemUpload(index: number): void {
    this.evidencesUploader._files.splice(index, 1);
    this.files.splice(index, 1);
    this.formGroupReportIssue.get('evidences')?.setValue(this.files);
  }

  public dragTime(event: any): void {
    this.isOnDrag = true;
    this.files = [];
    this.evidencesUploader._files.forEach((file) => this.files.push(file));
    this.formGroupReportIssue.get('evidences')?.setValue(this.files);
  }

  private validateFile(uploader: string, files: FileList): void {
    Array.from(files).forEach((file: File) => {
      // Verify duplicate
      if (this.formGroupReportIssue.get('evidences')?.value) {
        const isDuplicated = this.formGroupReportIssue
          .get('evidences')
          ?.value.some((uploadedFile: File) => {
            return uploadedFile.name === file.name;
          });
        if (isDuplicated) {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: `Duplicated file`,
            content: `Your uploading file is duplicated.`,
          });
          return;
        }
      }

      // Verify file number
      // if (this.evidencesUploader._files.length > 5) {
      //   this.notificationService.setMessage({
      //     type: AppConstant.MESSAGE_TYPE.WARNING,
      //     header: `Exceeded allowed number of files`,
      //     content: `Maximum 5 files are allowed`,
      //   });
      // }

      // Verify file type
      if (
        !(
          uploader == 'evidences' &&
          (file.type == 'image/png' ||
            file.type == 'image/jpeg' ||
            file.type == 'image/jpg' ||
            file.type == 'application/pdf')
        )
      ) {
        let fileType: string;
        if (uploader == 'evidences') fileType = '.png, .jpg, .jpeg, .pdf';
        if (fileType!) {
          this.notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: `Invalid File Type`,
            content: `${file.name}: Invalid file type`,
          });
        }
      }

      // Verify file size
      let maxFileSize: number;
      if (uploader == 'evidences') maxFileSize = 10000000;
      if (file.size > maxFileSize!) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Allowed file size: ${maxFileSize! / 1000000}MB`,
          content: `${file.name}: Invalid file size`,
        });
      }
    });
  }

  private verifyTotalFileSize(): boolean {
    // Verify total file size
    const currentTotalFileSize = this.evidencesUploader._files.reduce(
      (acc, file) => acc + file.size,
      0
    );
    if (currentTotalFileSize > 10000000) {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Allowed total file size: ${10000000 / 1000000}MB`,
        content: `${currentTotalFileSize / 1000000}MB exceeded total allowed file size`,
      });
      return false;
    }
    return true;
  }
}
