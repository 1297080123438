 <p-dialog
  [visible]="visible"
  [modal]="true"
  [style]="{ width: '516px' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [styleClass]="'dialog-customizable ' + styleDialog"
  >
  <!-- maskStyleClass="maskStyleDialog"
  styleClass="styleClassDialog" -->
  <ng-template pTemplate="header">
    <div class="d-flex">
      <div class="me-2 dialog__header--icon" *ngIf="isHeaderIcon">
        <svg-icon [key]="headerIcon" size="xl"></svg-icon>
      </div>
      <div [ngClass]="'dialog__header dialog__header--' + styleDialog">
        {{ header }}
      </div>
    </div>
    <div
      [ngClass]="{ 
        disabled: disableSecondaryButton, 
        styleDialog: styleDialog, 
        'dialog__header-close-icon': true, 
        'dialog__header-close-icon--dark': styleDialog === 'dark-dialog',
        'dialog__header-close-icon--light': styleDialog !== 'dark-dialog',
        'po': true,
        'cursor-pointer': true
      }"
      (click)="onButtonClick('cancel')">
      <i class="pi pi-times"></i>
    </div>
  </ng-template>
  <div class="d-flex">
    <p-checkbox
      *ngIf="haveCheckbox"
      [(ngModel)]="isConfirmCheckbox"
      (onChange)="onToggleCheckbox()"
      [binary]="true"
      inputId="confirm"
      [styleClass]="'dialog__checkbox dialog__checkbox--' + styleDialog"
    ></p-checkbox>
    <label *ngIf="haveCheckbox" [ngClass]="{'active': isConfirmCheckbox, 'm-0': true, 'checkbox-label': true}" for="confirm">
      {{ checkboxLabel}}
    </label>
  </div>
  <div>
    <!-- MAILBOX -->
    <p class="m-0" *ngIf="haveDialogMessage">{{ dialogMessage }}</p>
    <div class="dialog__selector pt-3 item-list-wrapper" *ngIf="haveBoxSelection">
      <div
        [ngClass]="{ 'item-list': true, 'item-center': false }"
      >
        <div
          *ngFor="let item of dataSelection"
          [pTooltip]="item.mailboxName"
          tooltipPosition="bottom"
          [ngClass]="{ 'item-box': true, active: item.isSelected }"
          (click)="toggleClass(item)"
        >
          <div class="item-content">
            {{ item.mailboxName | NameEmail }}
          </div>
        </div>

        <div
          *ngIf="dataSelection?.length == 0"
          pTooltip="N/A"
          tooltipPosition="bottom"
          [ngClass]="{ 'item-box': true, active: true }"
        >
          <div class="item-content">
            {{ 'N/A' }}
          </div>
        </div>
      </div>
    </div>

    <!-- TEMPLATE -->
    <p style="margin-top: 20px;" *ngIf="dialogMessageTemplate">{{ dialogMessageTemplate }}</p>
    <div class="dialog__selector pt-3" *ngIf="haveBoxSelection && dialogMessageTemplate">
      <div
        [ngClass]="{ 'item-list': true }"
      >        
        <p-dropdown
          class="dropdown-wapper"
          [options]="dataDropdownTemplate"
          [(ngModel)]="defaultTemplate"
          optionLabel="name"
          scrollHeight="150px"
          placeholder="Select template"          
          class="custom-p-drop"
          (onChange)="onChangeTemplate($event)"
          [disabled]="isDisableDropdown"
        >
        </p-dropdown>
      </div>
    </div>

    <!-- LANGUAGE -->
    <p style="margin-top: 20px;" *ngIf="dialogMessageLanguage">{{ dialogMessageLanguage }}</p>
    <div class="dialog__selector pt-3" *ngIf="haveBoxSelection && dialogMessageLanguage">
      <div
        [ngClass]="{ 'item-list': true }"
      >        
        <p-dropdown
          class="dropdown-wapper"
          [options]="dataDropdownLanguage"
          [(ngModel)]="defaultLanguage"
          optionLabel="name"
          scrollHeight="150px"
          placeholder="Select language"          
          class="custom-p-drop"
          (onChange)="onChangeLanguage($event)"
          [disabled]="isDisableDropdown"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="pt-3" *ngIf="haveSecondaryMessage">
      <div>
        {{ secondaryMessage }}
      </div>
    </div>
    <div class="pt-3" *ngIf="haveTertiaryMessage">
      {{ tertiaryDialogMessage }}
    </div>
    <div class="pt-3" *ngIf="isHaveTextarea">
      <textarea 
        class="w-100 dialog__textarea" 
        [ngClass]="{ 'error': textareaContent.length > 2000 }"
        [rows]="textareaSize" 
        [placeholder]="textarePlaceHolder"
        [(ngModel)]="textareaContent">
      </textarea>
      <div class="p-1" [ngClass]="{ 'text-error': textareaContent.length > 2000 }">
        {{ textareaContent.length }}/2000
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div [ngClass]="alignCenterButton ? 
      'w-100 justify-content-center d-flex gap-2' 
      : 'justify-content-end d-flex gap-2'">
    
    <app-customizable-button 
      [showButton]="haveSecondaryButton" 
      [label]="secondaryButtonLabel" 
      [style]="'dark'"
      [isDisabled]="false" 
      [elementId]="'cancelButton'" 
      (eventButton)="onButtonClick('no')">
    </app-customizable-button>
    
    <!-- // Style Disable com with style propertirs -->
    <app-customizable-button 
      [showButton]="havePrimaryButton" 
      [label]="primaryButtonLabel" 
      [style]="'light'"
      [isDisabled]="textareaContent 
        ? (textareaContent.trim().length > 2000 || !textareaContent.trim().length) 
        : dataSelection.length <= 0  && haveBoxSelection 
          ? false 
          : ((haveCheckbox && !isConfirmCheckbox) || !isValidPrimaryButton || !!disablePrimaryButton)"
      [elementId]="'confirmButton'" 
      (eventButton)="onButtonClick('yes')">
    </app-customizable-button>
    </div>
  </ng-template>
</p-dialog>
