import { CheckboxModule } from "primeng/checkbox";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from "primeng/fileupload";
import { InputTextModule } from "primeng/inputtext";
import { MenuModule } from "primeng/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressBarModule } from "primeng/progressbar";
import { SidebarModule } from "primeng/sidebar";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { TreeSelectModule } from "primeng/treeselect";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { ChipModule } from "primeng/chip";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TooltipModule } from "primeng/tooltip";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CalendarModule } from "primeng/calendar";
import { SliderModule } from 'primeng/slider';
import { ListboxModule } from 'primeng/listbox';
import { CardModule } from "primeng/card";
import { PickListModule } from "primeng/picklist";
import { EditorModule } from "primeng/editor";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { TabMenuModule } from "primeng/tabmenu";
import { ImageModule } from 'primeng/image';
import { BadgeModule } from 'primeng/badge';
import { SkeletonModule } from 'primeng/skeleton';

export const primeNgModules = [
  ToastModule,
  DialogModule,
  MenuModule,
  SidebarModule,
  DropdownModule,
  InputTextModule,
  ProgressBarModule,
  FileUploadModule,
  CheckboxModule,
  SliderModule,
  TabViewModule,
  TableModule,
  MultiSelectModule,
  TreeSelectModule,
  ButtonModule,
  AutoCompleteModule,
  OverlayPanelModule,
  ChipModule,
  TooltipModule,
  CalendarModule,
  InputTextareaModule,
  BadgeModule,
  SkeletonModule,
  
  ListboxModule,
  CardModule,
  PickListModule,
  EditorModule,
  ConfirmDialogModule,
  MessagesModule,
  MessageModule,
  AvatarModule,
  AvatarGroupModule,
  DynamicDialogModule,
  TabMenuModule,
  ImageModule
];
