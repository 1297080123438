import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { HOME_ADVANCED_FILTER } from '../../../shared/metadata/home-advanced-filter.metadata';
import {
  STATUS_INITIATED_DRAFT,
  STATUS_INITIATED_COMPLETED,
  STATUS_RESOLVED_DRAFT,
  STATUS_RESOLVED_COMPLETED,
  STATUS_RESOLUTION_REQUIRED,
  STATUS_INITIATED_SENT_DRAFT
} from './home-advanced-filter.metata'
import { HomeService } from 'src/app/shared/services/home.service';
import _ from 'lodash';
import { finalize } from 'rxjs';
import { HomeFilterService } from 'src/app/shared/services/home-filter.service';

const ALL_Option = 'All';
const ACTIVE_WELL = 'active';

@Component({
  selector: 'app-home-advanced-filter',
  templateUrl: './home-advanced-filter.component.html',
  styleUrls: ['./home-advanced-filter.component.scss'],
})
export class HomeAdvancedFilterComponent implements OnInit {

  constructor(
    private _homeService: HomeService,
    private _homeFilterService: HomeFilterService,
  ) {}

  METADATA: any = HOME_ADVANCED_FILTER;
  @Input() visible: boolean = true;

  @Input() haveFilter: boolean = false;
  @Input() isShowActive: boolean = false;
  @Output() hideAdvancedFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() chipFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() payloadFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() chipFilterStatus: EventEmitter<any> = new EventEmitter<any>();

  optionRegions: any[] = [];
  optionCountries: any[] = [];
  optionCustomers: any[] = [];
  optionProjects: any[] = [];
  optionRigContractors: any[] = [];
  optionRigs: any[] = [];
  optionStatuses: any[] = [];

  // Global Filter collection
  optionFilter: {
    regions: any
    countries: any
    customers: any
    projects: any
    contractors: any
    rigs: any
    statuses: any
  }

  selectedRegion: any[] = [];
  selectedCountry: any[] = [];
  selectedCustomer: any[] = [];
  selectedProject: any[] = [];
  selectedRigContractor: any[] = [];
  selectedRig: any[] = [];
  selectedStatus: any[] = [];

  // Keep selectedByRigJournalList from search string function
  selectedByRigJournalList: any[] = [];

  selectedProjectActive: boolean = true;
  selectedInterventionLogActive: boolean = false;

  cacheSelectedOptionApplied: any = {};
  cacheOptionsApplied: any = {
    region: [],
    country: [],
    customer: [],
    project: [],
    rigContractor: [],
    rig: [],
  };

  payloadHomeFilter: any;
  isLoading: boolean = false;

  ngOnInit(): void {
    this.loadInitialData();
  }

  loadInitialData() {
    this.selectedRegion = [];
    this.selectedCountry = [];
    this.selectedCustomer = [];
    this.selectedProject = [];
    this.selectedRigContractor = [];
    this.selectedRig = [];
    this.selectedStatus = [];

    // cacheSelectedOptionApplied;
    this.cacheSelectedOptionApplied.region = this.selectedRegion;
    this.cacheSelectedOptionApplied.country = this.selectedCountry;
    this.cacheSelectedOptionApplied.customer = this.selectedCustomer;
    this.cacheSelectedOptionApplied.project = this.selectedProject;
    this.cacheSelectedOptionApplied.rigContractor = this.selectedRigContractor;
    this.cacheSelectedOptionApplied.rig = this.selectedRig;
    // this.cacheSelectedOptionApplied.projectActive = this.selectedProjectActive;
    this.cacheSelectedOptionApplied.InterventionLogActive = this.selectedInterventionLogActive;
    this.cacheSelectedOptionApplied.status = this.selectedStatus;

    this.payloadHomeFilter = this._homeService.getPayloadFilter();
    this.getHomeFilterOption(this.payloadHomeFilter);
  }

   getHomeFilterOption(resetPayload: any) {
    this.isLoading = true;
    this._homeFilterService.getHomeFilterOptions(resetPayload).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((res: any) => {
      const { regions, countries, customers, projects, contractors, rigs, statuses } = res;

      this.optionRegions = regions;
      this.optionCountries = countries;
      this.optionCustomers = customers;
      this.optionRigContractors = contractors;
      this.optionProjects = projects;
      this.optionRigs = rigs;
      this.optionStatuses = statuses;
      this.selectedProjectActive = resetPayload.code === "active";

      this.optionFilter = {
        regions,
        countries,
        customers,
        projects,
        contractors,
        rigs,
        statuses
      };

      // cacheOptionsApplied;
      this.cacheOptionsApplied.region = this.optionRegions;
      this.cacheOptionsApplied.country = this.optionCountries;
      this.cacheOptionsApplied.customer = this.optionCustomers;
      this.cacheOptionsApplied.rigContractor = this.optionRigContractors;
      this.cacheOptionsApplied.project = this.optionProjects;
      this.cacheOptionsApplied.rig = this.optionRigs;
      this.cacheOptionsApplied.status = this.optionStatuses;

      // Cache selected Check Active
      this.cacheSelectedOptionApplied.projectActive = this.selectedProjectActive;
    });
  }


  removeChipAndGetNew(label: string = '', isRemoveCache: boolean) {  
    switch (label) {
      case this.METADATA.REGION.LABEL:
        this.selectedRegion = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.region = this.selectedRegion;
        break;
      case this.METADATA.COUNTRY.LABEL:
        this.selectedCountry = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.country = this.selectedCountry;
        break;
      case this.METADATA.CUSTOMER.LABEL:
        this.selectedCustomer = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.customer = this.selectedCustomer;
        break;
      case this.METADATA.PROJECT.LABEL:
        this.selectedProject = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.project = this.selectedProject;
        break;
      case this.METADATA.RIG_CONTRACTOR.LABEL:
        this.selectedRigContractor = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.rigContractor = this.selectedRigContractor;
        break;
      case this.METADATA.RIG.LABEL:
        this.selectedRig = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.rig = this.selectedRig;
        break;
      case this.METADATA.STATUS.LABEL:
        this.selectedStatus = [];
        if(isRemoveCache) 
          this.cacheSelectedOptionApplied.status = this.selectedStatus;
        break;
      default:
        break;
    }

    this.executeFilter();
  }

  /**
    * Function trigger when panel dropdown have action hide or clear data selected
  */

  onActionDropdown(label: string = '') {
    this.prepairDataDropdownWithdata(label);
  }

  // Function to filter options
  prepairDataDropdownWithdata(label: string = '') {

    let regionsOption: any = [];
    let countriesOption: any = [];
    let customersOption: any = [];
    let projectsOption: any = [];
    let contractorsOption: any = [];
    let rigsOption: any = [];
    let statusesOption: any = [];
    
    let focusRigJournalId = '';
    

    const {
      regions = [],
      countries = [],
      customers = [],
      projects = [],
      contractors = [],
      rigs = [],
      statuses = [],
    } = this.optionFilter || {};

    this.optionRegions = this.cacheOptionsApplied.region;
    this.optionCountries = this.cacheOptionsApplied.country;
    this.optionCustomers = this.cacheOptionsApplied.customer;
    this.optionProjects = this.cacheOptionsApplied.project;
    this.optionRigContractors = this.cacheOptionsApplied.rigContractor;
    this.optionRigs = this.cacheOptionsApplied.rig;
    this.optionStatuses = this.cacheOptionsApplied.status;

    switch (label) {
      case this.METADATA.REGION.LABEL:
        this.selectedRegion = [];
        break;
      case this.METADATA.COUNTRY.LABEL:
        this.selectedCountry = [];
        break;
      case this.METADATA.CUSTOMER.LABEL:
        this.selectedCustomer = [];
        break;
      case this.METADATA.PROJECT.LABEL:
        this.selectedProject = [];
        break;
      case this.METADATA.RIG_CONTRACTOR.LABEL:
        this.selectedRigContractor = [];
        break;
      case this.METADATA.RIG.LABEL:
        this.selectedRig = [];
        break;
      case this.METADATA.STATUS.LABEL:
        this.selectedStatus = [];
        break;
      default:
        break;
    }

    // ==============================================
    // Get List of Search Rig Card
    this.selectedByRigJournalList = this._homeFilterService.getSearchRigCard();

    // Get rigJournalId Focusing
    focusRigJournalId = this._homeFilterService.getFocusRigCard();

    // ==============================================

    
    this.optionFilter?.regions.forEach((region: any) => {
      const isCountryInRegion = this.selectedCountry?.length ? this.selectedCountry.some(country => country.consecutiveIds?.regionId?.includes(region.id)) : true;
      const isCustomerInRegion = this.selectedCustomer?.length ? this.selectedCustomer.some(customer => customer.consecutiveIds?.regionId?.includes(region.id)) : true;
      const isProjectInRegion = this.selectedProject?.length ? this.selectedProject.some(project => project.consecutiveIds?.regionId?.includes(region.id)) : true;
      const isContractorInRegion = this.selectedRigContractor?.length ? this.selectedRigContractor.some(contractor => contractor.consecutiveIds?.regionId?.includes(region.id)) : true;
      const isRigInRegion = this.selectedRig?.length ? this.selectedRig.some(rig => rig.consecutiveIds?.regionId?.includes(region.id)) : true;
      const isStatusInRegion = this.selectedStatus?.length
        ?
        this.selectedStatus.every(status => {
          let conditionItems = status.id.split(' ');
          return conditionItems.some((item: any) => region.consecutiveIds.interventionId?.includes(item));

        })
        : true;

      if (isCountryInRegion && isCustomerInRegion && isProjectInRegion && isContractorInRegion && isRigInRegion && isStatusInRegion) {
        regionsOption.push(region);
      }
    });    

    this.optionFilter?.countries.forEach((country: any) => {
      const isRegionInCountry = this.selectedRegion?.length ? this.selectedRegion.some(region => region.consecutiveIds?.countryId?.includes(country.id)) : true;
      const isCustomerInCountry = this.selectedCustomer?.length ? this.selectedCustomer.some(customer => customer.consecutiveIds?.countryId?.includes(country.id)) : true;
      const isProjectInCountry = this.selectedProject?.length ? this.selectedProject.some(project => project.consecutiveIds?.countryId?.includes(country.id)) : true;
      const isContractorInCountry = this.selectedRigContractor?.length ? this.selectedRigContractor.some(contractor => contractor.consecutiveIds?.countryId?.includes(country.id)) : true;
      const isRigInCountry = this.selectedRig?.length ? this.selectedRig.some(rig => rig.consecutiveIds?.countryId?.includes(country.id)) : true;
      const isStatusInCountry = this.selectedStatus?.length
        ?
        this.selectedStatus.every(status => {
          let conditionItems = status.id.split(' ');
          return conditionItems.some((item: any) => country.consecutiveIds.interventionId?.includes(item));

        })
        : true;

      if (isRegionInCountry && isCustomerInCountry && isProjectInCountry && isContractorInCountry && isRigInCountry && isStatusInCountry) {
        countriesOption.push(country);
      }
    });

    this.optionFilter?.customers.forEach((customer: any) => {
      const isRegionInCustomer = this.selectedRegion?.length ? this.selectedRegion.some(region => region.consecutiveIds?.companyId?.includes(customer.id)) : true;
      const isCountryInCustomer = this.selectedCountry?.length ? this.selectedCountry.some(country => country.consecutiveIds?.companyId?.includes(customer.id)) : true;
      const isProjectInCustomer = this.selectedProject?.length ? this.selectedProject.some(project => project.consecutiveIds?.companyId?.includes(customer.id)) : true;
      const isContractorInCustomer = this.selectedRigContractor?.length ? this.selectedRigContractor.some(contractor => contractor.consecutiveIds?.companyId?.includes(customer.id)) : true;
      const isRigInCustomer = this.selectedRig?.length ? this.selectedRig.some(rig => rig.consecutiveIds?.companyId?.includes(customer.id)) : true;
      const isStatusInCustomer = this.selectedStatus?.length
        ?
        this.selectedStatus.every(status => {
          let conditionItems = status.id.split(' ');
          return conditionItems.some((item: any) => customer.consecutiveIds.interventionId?.includes(item));

        })
        : true;

      if (isRegionInCustomer && isCountryInCustomer && isProjectInCustomer && isContractorInCustomer && isRigInCustomer && isStatusInCustomer) {
        customersOption.push(customer);
      }
    });

    this.optionFilter?.projects.forEach((project: any) => {
      const isRegionInProject = this.selectedRegion?.length ? this.selectedRegion.some(region => region.consecutiveIds?.projectId?.includes(project.id)) : true;
      const isCountryInProject = this.selectedCountry?.length ? this.selectedCountry.some(country => country.consecutiveIds?.projectId?.includes(project.id)) : true;
      const isCustomerInProject = this.selectedCustomer?.length ? this.selectedCustomer.some(customer => customer.consecutiveIds?.projectId?.includes(project.id)) : true;
      const isContractorInProject = this.selectedRigContractor?.length ? this.selectedRigContractor.some(contractor => contractor.consecutiveIds?.projectId?.includes(project.id)) : true;
      const isRigInProject = this.selectedRig?.length ? this.selectedRig.some(rig => rig.consecutiveIds?.projectId?.includes(project.id)) : true;
      const isStatusInProject = this.selectedStatus?.length
        ?
        this.selectedStatus.every(status => {
          let conditionItems = status.id.split(' ');
          return conditionItems.some((item: any) => project.consecutiveIds.interventionId?.includes(item));

        })
        : true;

      if (isRegionInProject && isCountryInProject && isCustomerInProject && isContractorInProject && isRigInProject && isStatusInProject) {
        projectsOption.push(project);
      }
    });

    this.optionFilter?.contractors.forEach((contractor: any) => {
      const isRegionInContractor = this.selectedRegion?.length ? this.selectedRegion.some(region => region.consecutiveIds?.contractorId?.includes(contractor.id)) : true;
      const isCountryInContractor = this.selectedCountry?.length ? this.selectedCountry.some(country => country.consecutiveIds?.contractorId?.includes(contractor.id)) : true;
      const isCustomerInContractor = this.selectedCustomer?.length ? this.selectedCustomer.some(customer => customer.consecutiveIds?.contractorId?.includes(contractor.id)) : true;
      const isProjectInContractor = this.selectedProject?.length ? this.selectedProject.some(project => project.consecutiveIds?.contractorId?.includes(contractor.id)) : true;
      const isRigInContractor = this.selectedRig?.length ? this.selectedRig.some(rig => rig.consecutiveIds?.contractorId?.includes(contractor.id)) : true;
      const isStatusInContractor = this.selectedStatus?.length
        ?
        this.selectedStatus.every(status => {
          let conditionItems = status.id.split(' ');
          return conditionItems.some((item: any) => contractor.consecutiveIds.interventionId?.includes(item));
        })
        : true;

      if (isRegionInContractor && isCountryInContractor && isCustomerInContractor && isProjectInContractor && isRigInContractor && isStatusInContractor) {
        contractorsOption.push(contractor);
      }
    });

    this.optionFilter?.rigs.forEach((rig: any) => {
      const isRegionInRig = this.selectedRegion?.length ? this.selectedRegion.some(region => region.consecutiveIds?.rigId?.includes(rig.id)) : true;
      const isCountryInRig = this.selectedCountry?.length ? this.selectedCountry.some(country => country.consecutiveIds?.rigId?.includes(rig.id)) : true;
      const isCustomerInRig = this.selectedCustomer?.length ? this.selectedCustomer.some(customer => customer.consecutiveIds?.rigId?.includes(rig.id)) : true;
      const isProjectInRig = this.selectedProject?.length ? this.selectedProject.some(project => project.consecutiveIds?.rigId?.includes(rig.id)) : true;
      const isContractorInRig = this.selectedRigContractor?.length ? this.selectedRigContractor.some(contractor => contractor.consecutiveIds?.rigId?.includes(rig.id)) : true;
      const isStatusInRig = this.selectedStatus?.length
        ?
        this.selectedStatus.every(status => {
          let conditionItems = status.id.split(' ');
          return conditionItems.some((item: any) => rig.consecutiveIds.interventionId?.includes(item));
        })
        : true;

      if (isRegionInRig && isCountryInRig && isCustomerInRig && isProjectInRig && isContractorInRig && isStatusInRig) {
        rigsOption.push(rig);
      }
    });

    this.optionFilter?.statuses.forEach((status: any) => {
      const isRegionInStatus = this.selectedRegion?.length
        ?
        this.selectedRegion.some(region => {
          let regionStatus = region.consecutiveIds.interventionId;
          return regionStatus.some((st: any) => {
            let conditionItems = status.id.split(' ');
            return conditionItems.some((i: any) => st.includes(i));
          });
        })
        : true;

      const isCountryInStatus = this.selectedCountry?.length
        ?
        this.selectedCountry.some(country => {
          let countryStatus = country.consecutiveIds.interventionId;
          return countryStatus.some((st: any) => {
            let conditionItems = status.id.split(' ');
            return conditionItems.some((i: any) => st.includes(i));
          });
        })
        : true;

      const isCustomerInStatus = this.selectedCustomer?.length
        ?
        this.selectedCustomer.some(customer => {
          let customerStatus = customer.consecutiveIds.interventionId;
          return customerStatus.some((st: any) => {
            let conditionItems = status.id.split(' ');
            return conditionItems.some((i: any) => st.includes(i));
          });
        })
        : true;

      const isProjectInStatus = this.selectedProject?.length
        ?
        this.selectedProject.some(project => {
          let projectStatus = project.consecutiveIds.interventionId;
          return projectStatus.some((st: any) => {
            let conditionItems = status.id.split(' ');
            return conditionItems.some((i: any) => st.includes(i));
          });
        })
        : true;


      const isContractorInStatus = this.selectedRigContractor?.length
        ?
        this.selectedRigContractor.some(contractor => {
          let contractorStatus = contractor.consecutiveIds.interventionId;
          return contractorStatus.some((st: any) => {
            let conditionItems = status.id.split(' ');
            return conditionItems.some((i: any) => st.includes(i));
          });
        })
        : true;

      const isRigInStatus = this.selectedRig?.length
        ?
        this.selectedRig.some(rig => {
          let rigStatus = rig.consecutiveIds.interventionId;
          return rigStatus.some((st: any) => {
            let conditionItems = status.id.split(' ');
            return conditionItems.some((i: any) => st.includes(i));
          });
        })
        : true;


      if (isRegionInStatus && isCountryInStatus && isCustomerInStatus && isProjectInStatus && isContractorInStatus && isRigInStatus) {
        statusesOption.push(status);
      }
    });
    
    if (this.selectedByRigJournalList.length) {
      regionsOption = regionsOption.filter((region: any) => {
        return this.selectedByRigJournalList.some(item => region.consecutiveIds?.rigJournalId.includes(item));
      })

      countriesOption = countriesOption.filter((country: any) => {
        return this.selectedByRigJournalList.some(item => country.consecutiveIds?.rigJournalId.includes(item));
      })

      customersOption = customersOption.filter((customer: any) => {
        return this.selectedByRigJournalList.some(item => customer.consecutiveIds?.rigJournalId.includes(item));
      })

      projectsOption = projectsOption.filter((project: any) => {
        return this.selectedByRigJournalList.some(item => project.consecutiveIds?.rigJournalId.includes(item));
      })

      contractorsOption = contractorsOption.filter((contractor: any) => {
        return this.selectedByRigJournalList.some(item => contractor.consecutiveIds?.rigJournalId.includes(item));
      })

      rigsOption = rigsOption.filter((rig: any) => {
        return this.selectedByRigJournalList.some(item => rig.consecutiveIds?.rigJournalId.includes(item));
      })

      statusesOption = statusesOption.filter((status: any) => {
        return this.selectedByRigJournalList.some(item => status.rigJournalId.includes(item));
      })
    }
    
    if (focusRigJournalId) {
      regionsOption = regionsOption.filter((region: any) => {
        return region.consecutiveIds?.rigJournalId?.includes(focusRigJournalId)
      })

      countriesOption = countriesOption.filter((country: any) => {
        return country.consecutiveIds?.rigJournalId?.includes(focusRigJournalId)
      })

      customersOption = customersOption.filter((customer: any) => {
        return customer.consecutiveIds?.rigJournalId?.includes(focusRigJournalId)
      })

      projectsOption = projectsOption.filter((project: any) => {
        return project.consecutiveIds?.rigJournalId?.includes(focusRigJournalId)
      })

      contractorsOption = contractorsOption.filter((contractor: any) => {
        return contractor.consecutiveIds?.rigJournalId?.includes(focusRigJournalId)
      })

      rigsOption = rigsOption.filter((rig: any) => {
        return rig.consecutiveIds?.rigJournalId?.includes(focusRigJournalId)
      })

      statusesOption = statusesOption.filter((status: any) => {
        return status.rigJournalId?.includes(focusRigJournalId)
      })

    }

    // Filter statusesOption With Value Valid
    statusesOption = statusesOption.filter((status: any) => {
      return status.rigJournalId.length
    })
    
    this.optionRegions = regionsOption;
    this.optionCountries = countriesOption;
    this.optionCustomers = customersOption;
    this.optionRigContractors = contractorsOption;
    this.optionProjects = projectsOption;
    this.optionRigs = rigsOption;
    this.optionStatuses = statusesOption;

    this.updateSelected();

    if (focusRigJournalId || this.selectedByRigJournalList.length) {
      this.cacheOptions();
    }
  }

  updateSelected() {
    if (this.selectedRegion?.length) {
      this.selectedRegion = this.optionRegions.filter(selected => {
        return this.selectedRegion.some(option => option.id === selected.id);
      });
    }

    if (this.selectedCountry?.length) {
      this.selectedCountry = this.optionCountries.filter(selected => {
        return this.selectedCountry.some(option => option.id === selected.id);
      });
    }

    if (this.selectedCustomer?.length) {
      this.selectedCustomer = this.optionCustomers.filter(selected => {
        return this.selectedCustomer.some(option => option.id === selected.id);
      });
    }

    if (this.selectedRigContractor?.length) {
      this.selectedRigContractor = this.optionRigContractors.filter(selected => {
        return this.selectedRigContractor.some(option => option.id === selected.id);
      });
    }

    if (this.selectedProject?.length) {
      this.selectedProject = this.optionProjects.filter(selected => {
        return this.selectedProject.some(option => option.id === selected.id);
      });
    }

    if (this.selectedRig?.length) {
      this.selectedRig = this.optionRigs.filter(selected => {
        return this.selectedRig.some(option => option.id === selected.id);
      });
    }

    if (this.selectedStatus?.length) {
      this.selectedStatus = this.optionStatuses.filter(selected => {
        return this.selectedStatus.some(option => option.id === selected.id);
      });
    }
  }

  cacheOptions() {
    // cacheOptionsApplied;
    this.cacheOptionsApplied.region = this.optionRegions;
    this.cacheOptionsApplied.country = this.optionCountries;
    this.cacheOptionsApplied.customer = this.optionCustomers;
    this.cacheOptionsApplied.rigContractor = this.optionRigContractors;
    this.cacheOptionsApplied.project = this.optionProjects;
    this.cacheOptionsApplied.rig = this.optionRigs;
    this.cacheOptionsApplied.status = this.optionStatuses;
  }

  resetFilter() {
   const resetPayload = {
      code: "active",
      searchString: "",
    };

    this.isLoading = true;
    this._homeFilterService.getHomeFilterOptions(resetPayload).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((res: any) => {
      const { regions, countries, customers, projects, contractors, rigs, statuses } = res;

      this.optionRegions = regions;
      this.optionCountries = countries;
      this.optionCustomers = customers;
      this.optionRigContractors = contractors;
      this.optionProjects = projects;
      this.optionRigs = rigs;
      this.optionStatuses = statuses;
      this.selectedProjectActive = resetPayload.code === "active";

      this.onActionDropdown();
    })
  }

  /**
   * Function trigger when the panel filter is hided
   * @param event
   */
  onHide() {
    // cache selected
    this.selectedRegion = this.cacheSelectedOptionApplied.region;
    this.selectedCountry = this.cacheSelectedOptionApplied.country;
    this.selectedCustomer = this.cacheSelectedOptionApplied.customer;
    this.selectedProject = this.cacheSelectedOptionApplied.project;
    this.selectedRigContractor = this.cacheSelectedOptionApplied.rigContractor;
    this.selectedRig = this.cacheSelectedOptionApplied.rig;
    this.selectedProjectActive = this.cacheSelectedOptionApplied.projectActive;
    this.selectedInterventionLogActive = this.cacheSelectedOptionApplied.InterventionLogActive;
    this.selectedStatus = this.cacheSelectedOptionApplied.status;
    // cache option
    this.optionRegions = this.cacheOptionsApplied.region;
    this.optionCountries = this.cacheOptionsApplied.country;
    this.optionCustomers = this.cacheOptionsApplied.customer;
    this.optionProjects = this.cacheOptionsApplied.project;
    this.optionRigContractors = this.cacheOptionsApplied.rigContractor;
    this.optionRigs = this.cacheOptionsApplied.rig;
    this.optionStatuses = this.cacheOptionsApplied.status;
  }

  onShow() {
    this.prepairDataDropdownWithdata();
  }

  setSelectedOption(variableName: string, value: any) {
    switch (variableName) {
      case 'region': {
        let findValue = [];
        if (this.optionRegions.length) {
          findValue = this.optionRegions.filter((item: any) => {
            return value.some((v: string) => v === item.id);
          });
        }
        this.selectedRegion = findValue;
        this.cacheSelectedOptionApplied.region = [...this.selectedRegion];
        this.cacheOptionsApplied.region = Object.assign([], this.optionRegions);
        break;
      }
      case 'country': {
        let findValue = [];
        if (this.optionCountries.length) {
          findValue = this.optionCountries.filter((item: any) => {
            return value.some((v: string) => v === item.id);
          });
        }
        this.selectedCountry = findValue;
        this.cacheSelectedOptionApplied.country = [...this.selectedCountry];
        this.cacheOptionsApplied.country = Object.assign(
          [],
          this.optionCountries
        );
        break;
      }
      case 'customer': {
        let findValue = [];
        if (this.optionCustomers.length) {
          findValue = this.optionCustomers.filter((item: any) => {
            return value.some((v: string) => v === item.id);
          });
        }

        this.selectedCustomer = findValue;
        this.cacheSelectedOptionApplied.customer = [...this.selectedCustomer];
        this.cacheOptionsApplied.customer = Object.assign(
          [],
          this.optionCustomers
        );
        break;
      }
      case 'project': {
        let findValue = [];
        if (this.optionProjects.length) {
          findValue = this.optionProjects.filter((item: any) => {
            return value.some((v: string) => v === item.id);
          });
        }

        this.selectedProject = findValue;
        this.cacheSelectedOptionApplied.project = [...this.selectedProject];
        this.cacheOptionsApplied.project = Object.assign(
          [],
          this.optionProjects
        );
        break;
      }
      case 'contractor': {
        let findValue = [];
        if (this.optionRigContractors.length) {
          findValue = this.optionRigContractors.filter((item: any) => {
            return value.some((v: string) => v === item.id);
          });
        }

        this.selectedRigContractor = findValue;
        this.cacheSelectedOptionApplied.rigContractor = [
          ...this.selectedRigContractor,
        ];
        this.cacheOptionsApplied.rigContractor = Object.assign(
          [],
          this.optionRigContractors
        );
        break;
      }
      case 'rig': {
        let findValue = [];
        if (this.optionRigs.length) {
          findValue = this.optionRigs.filter((item: any) => {
            return value.some((v: string) => v === item.id);
          });
        }

        this.selectedRig = findValue;
        this.cacheSelectedOptionApplied.rig = [...this.selectedRig];
        this.cacheOptionsApplied.rig = Object.assign([], this.optionRigs);
        break;
      }
      case 'status': {
        let findValue = [];
        if (value[0]) {
          const queryString = value[0];
          if (queryString.includes(STATUS_INITIATED_DRAFT)) {
            let findItem = this.optionStatuses.find((item: any) => item.name === 'Initiate (Draft)');
            findItem && findValue.push(findItem);
          }

          if (queryString.includes(STATUS_INITIATED_COMPLETED)) {
            let findItem = this.optionStatuses.find((item: any) => item.name === 'Initiate (Completed)');
            findItem && findValue.push(findItem);
          }

          if (queryString.includes(STATUS_RESOLVED_DRAFT)) {
            let findItem = this.optionStatuses.find((item: any) => item.name === 'Resolve (Draft)');
            findItem && findValue.push(findItem);
          }

          if (queryString.includes(STATUS_RESOLVED_COMPLETED)) {
            let findItem = this.optionStatuses.find((item: any) => item.name === 'Resolve (Completed)');
            findItem && findValue.push(findItem);
          }

          if (queryString.includes(STATUS_RESOLUTION_REQUIRED)) {
            let findItem = this.optionStatuses.find((item: any) => item.name === 'Resolution Required');
            findItem && findValue.push(findItem);
          }
        }

        this.selectedStatus = findValue;
        this.cacheSelectedOptionApplied.status = [...this.selectedStatus];
        this.cacheOptionsApplied.status = Object.assign(
          [],
          this.optionStatuses
        );
        break;
      }
      case 'all': {
        this.selectedRegion = value;
        this.selectedCountry = value;
        this.selectedCustomer = value;
        this.selectedProject = value;
        this.selectedRigContractor = value;
        this.selectedRig = value;
        this.selectedProjectActive = true;
        this.selectedInterventionLogActive = false;
        this.selectedStatus = value;

        this.cacheSelectedOptionApplied.region = this.selectedRegion;
        this.cacheSelectedOptionApplied.country = this.selectedCountry;
        this.cacheSelectedOptionApplied.customer = this.selectedCustomer;
        this.cacheSelectedOptionApplied.project = this.selectedProject;
        this.cacheSelectedOptionApplied.rigContractor = this.selectedRigContractor;
        this.cacheSelectedOptionApplied.rig = this.selectedRig;
        this.cacheSelectedOptionApplied.projectActive = this.selectedProjectActive;
        this.cacheSelectedOptionApplied.InterventionLogActive = this.selectedInterventionLogActive;
        this.cacheSelectedOptionApplied.status = this.selectedStatus;
        break;
      }
    }
  }

  executeFilter() {
    // payload display chip filter
    let payloadChip: any = {};
    let payloadChipRegions: any = [];
    let payloadChipCountries: any = [];
    let payloadChipCustomers: any = [];
    let payloadChipProjects: any = [];
    let payloadChipContractor: any = [];
    let payloadChipRig: any = [];
    (this.selectedRegion || []).length > 1
      ? payloadChipRegions.push(`Multiple region`)
      : (this.selectedRegion || []).length == 1
        ? payloadChipRegions.push(this.selectedRegion?.[0].name)
        : '';
    (this.selectedCountry || []).length > 1
      ? payloadChipCountries.push(`Multiple country`)
      : (this.selectedCountry || []).length == 1
        ? payloadChipCountries.push(this.selectedCountry?.[0].id)
        : '';
    (this.selectedCustomer || []).length > 1
      ? payloadChipCustomers.push(`Multiple customer`)
      : (this.selectedCustomer || []).length == 1
        ? payloadChipCustomers.push(this.selectedCustomer?.[0].name)
        : '';
    (this.selectedProject || []).length > 1
      ? payloadChipProjects.push(`Multiple project`)
      : (this.selectedProject || []).length == 1
        ? payloadChipProjects.push(this.selectedProject?.[0].name)
        : '';
    (this.selectedRigContractor || []).length > 1
      ? payloadChipContractor.push(`Multiple contractor`)
      : (this.selectedRigContractor || []).length == 1
        ? payloadChipContractor.push(
          this.selectedRigContractor?.[0].name
        )
        : '';
    (this.selectedRig || []).length > 1
      ? payloadChipRig.push(`Multiple rig`)
      : (this.selectedRig || []).length == 1
        ? payloadChipRig.push(this.selectedRig?.[0].name)
        : '';
    payloadChip = {
      region: payloadChipRegions,
      country: payloadChipCountries,
      customer: payloadChipCustomers,
      project: payloadChipProjects,
      contractor: payloadChipContractor,
      rig: payloadChipRig,
    };
    if (!(this.selectedRegion || []).length) {
      delete payloadChip.region;
    }
    if (!(this.selectedCountry || []).length) {
      delete payloadChip.country;
    }
    if (!(this.selectedCustomer || []).length) {
      delete payloadChip.customer;
    }
    if (!(this.selectedProject || []).length) {
      delete payloadChip.project;
    }
    if (!(this.selectedRigContractor || []).length) {
      delete payloadChip.contractor;
    }
    if (!(this.selectedRig || []).length) {
      delete payloadChip.rig;
    }
    if (!this.selectedStatus) {
      delete payloadChip.status;
    }
    // payload cal API
    let payload: any = {};
    let payloadRegions: any = [];
    let payloadCountries: any = [];
    let payloadCustomers: any = [];
    let payloadProjects: any = [];
    let payloadContractor: any = [];
    let payloadRig: any = [];
    (this.selectedRegion || []).length
      ? this.selectedRegion?.forEach((item: any) => {
        payloadRegions.push(item.id);
      })
      : '';
    (this.selectedCountry || []).length
      ? this.selectedCountry?.forEach((item: any) => {
        payloadCountries.push(item.id);
      })
      : '';
    (this.selectedCustomer || []).length
      ? this.selectedCustomer?.forEach((item: any) => {
        payloadCustomers.push(item.id);
      })
      : '';
    (this.selectedProject || []).length
      ? this.selectedProject?.forEach((item: any) => {
        payloadProjects.push(item.id);
      })
      : '';
    (this.selectedRigContractor || []).length
      ? this.selectedRigContractor?.forEach((item: any) => {
        payloadContractor.push(item.id);
      })
      : '';
    (this.selectedRig || []).length
      ? this.selectedRig?.forEach((item: any) => {
        payloadRig.push(item.id);
      })
      : '';

    payload = {
      region: payloadRegions,
      country: payloadCountries,
      customer: payloadCustomers,
      project: payloadProjects,
      contractor: payloadContractor,
      rig: payloadRig,
    };
    if (!(this.selectedRegion || []).length) {
      delete payload.region;
    }
    if (!(this.selectedCountry || []).length) {
      delete payload.country;
    }
    if (!(this.selectedCustomer || []).length) {
      delete payload.customer;
    }
    if (!(this.selectedProject || []).length) {
      delete payload.project;
    }
    if (!(this.selectedRigContractor || []).length) {
      delete payload.contractor;
    }
    if (!(this.selectedRig || []).length) {
      delete payload.rig;
    }
    if (!this.selectedStatus) {
      delete payload.status;
    }

    if (this.selectedProjectActive) {
      payload.code = ACTIVE_WELL;
    } else {
      payload.code = ALL_Option;
    }
    if (this.selectedInterventionLogActive) {
      payload.codeInterLog = ACTIVE_WELL;
    }

    let valueStatusSelected: any[] = [];
    let chipFilterStatus: any = [];
    if ((this.selectedStatus || []).length) {
      valueStatusSelected[0] = this.selectedStatus
        ?.map((item: any) => {
          if (item.name === 'Initiate (Draft)') {
            chipFilterStatus.push(item.name);
            return [STATUS_INITIATED_DRAFT, STATUS_INITIATED_SENT_DRAFT];

          } else if (item.name === 'Initiate (Completed)') {
            chipFilterStatus.push(item.name);
            return STATUS_INITIATED_COMPLETED

          } else if (item.name === 'Resolve (Draft)') {
            chipFilterStatus.push(item.name);
            return STATUS_RESOLVED_DRAFT;

          } else if (item.name === 'Resolve (Completed)') {
            chipFilterStatus.push(item.name);
            return STATUS_RESOLVED_COMPLETED;

          } else if (item.name === 'Resolution Required') {
            chipFilterStatus.push(item.name);
            return STATUS_RESOLUTION_REQUIRED;
          }
        })
        .join(`,`);
    }

    // Add more status for Project Section Filter
    if ((this.selectedStatus || []).length) {
      payload.status = valueStatusSelected;
    }

    let payloadSend = JSON.parse(JSON.stringify(payload));

    if (chipFilterStatus.length) {
      payloadChip.status = chipFilterStatus.length > 1 ? ['Multiple status'] : chipFilterStatus;
    }
    this.chipFilter.emit(Object.assign({}, payloadChip));
    this.payloadFilter.emit(Object.assign({}, payloadSend));

    // cacheSelectedOptionApplied;
    this.cacheSelectedOptionApplied.region = this.selectedRegion;
    this.cacheSelectedOptionApplied.country = this.selectedCountry;
    this.cacheSelectedOptionApplied.customer = this.selectedCustomer;
    this.cacheSelectedOptionApplied.project = this.selectedProject;
    this.cacheSelectedOptionApplied.rigContractor = this.selectedRigContractor;
    this.cacheSelectedOptionApplied.rig = this.selectedRig;
    this.cacheSelectedOptionApplied.projectActive = this.selectedProjectActive;
    this.cacheSelectedOptionApplied.InterventionLogActive = this.selectedInterventionLogActive;
    this.cacheSelectedOptionApplied.status = this.selectedStatus;

    // cacheOptionsApplied;
    this.cacheOptionsApplied.region = this.optionRegions;
    this.cacheOptionsApplied.country = this.optionCountries;
    this.cacheOptionsApplied.customer = this.optionCustomers;
    this.cacheOptionsApplied.project = this.optionProjects;
    this.cacheOptionsApplied.rigContractor = this.optionRigContractors;
    this.cacheOptionsApplied.rig = this.optionRigs;
    this.cacheOptionsApplied.status = this.optionStatuses;

    this.getHomeFilterOption(this._homeService.getPayloadFilter());
  }

}
