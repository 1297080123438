<div class="intervention">
  <ng-container *ngIf="backPortal">
    <app-module-header
      [backPortal]="true"
      [backPortalLabel]="backPortal"
      [headerTitle]="''"
      [buttonFearture]="false"
      buttonFeartureLabel="Nothing Render Because buttonFearture === false"
      [bottomMargin]="true"
      (onClickBack)="backToAlertManagement()"
    ></app-module-header>
  </ng-container>

  <div class="header-content" [ngClass]="{ 'sm-header': backPortal }">
    <div class="header-content__title">
      <span
        *ngIf="
          (backPortal || showMoreHeader) && rigData?.project?.projectCurrentName
        "
      >
        {{
          rigData.project.projectCurrentName ||
            rigData.project.projectOriginalName
        }}:
        <span >
          <svg-icon
            class="rig custom-svg-icon"
            [ngClass]="{ active: !rigData.isComplete }"
            key="rig"
            size="lg"
          ></svg-icon>
        </span>
      </span>
      <span>{{ rigName }}</span>
    </div>
    <div class="header-content__feature" (click)="showDialogDraft()">
      <svg-icon key="close" size="md"></svg-icon>
    </div>
  </div>

  <div class="intervention-header">
    <div class="intervention-header-wrapper-share">
      <div
        class="intervention-header-status d-flex justify-content-between"
        [ngClass]="alertStatusTag.classColor"
      >
        <div class="d-flex justify-content-between">
          <div>{{ alertStatusTag.name || "INITIATED" }}</div>
          <div>
            <svg-icon
              class="custom-svg-icon"
              [key]="alertStatusTag.icon || 'readEnvelope'"
              size="md"
            ></svg-icon>
          </div>
      </div>
      </div>
      <div
        class="intervention-header-feature d-flex justify-content-end flex-wrap gap-2"
        [ngClass]="isReadOnly ? 'label-disabled' : ''"
      >
        <!-- DISTRIBUTION -->
        <div>
          <span *ngIf="true" class="ms-2">
            <app-customizable-button
              [showButton]="true" 
              [label]="'View Distribution Lists'" 
              [style]="'dark'"
              [isDisabled]="false"
              [elementId]="'viewDistributionLists'"
              [isToolTip]="false"
              [toolTipContent]="''"
              [tooltipPosition]="'bottom'"
              (eventButton)="openDistibution(rigJournalId)">
            </app-customizable-button>
          </span>
          <span *ngIf="false" class="ms-2">
            <app-customizable-button
              [showButton]="true" 
              [label]="'Reload Data'" 
              [style]="'dark'"
              [isDisabled]="false"
              [elementId]="'reloadData'"
              [isToolTip]="false"
              [toolTipContent]="''"
              [tooltipPosition]="'bottom'"
              (eventButton)="loadData()">
            </app-customizable-button>
          </span>
        </div>
        <!-- MAILBOX -->
        <div
          class="dropdown-select"
          *ngIf="currentUser.role === AppConstant.ROLES.ENGINEER.label"
          [pTooltip]="selectedMailbox?.mailboxName || 'Select mailbox'"
          tooltipPosition="bottom"
        >
          <p-dropdown
            [options]="mailboxs"
            [(ngModel)]="selectedMailbox"
            optionLabel="mailboxLocalPart"
            scrollHeight="150px"
            styleClass="dropdown-wapper"
            placeholder="Select mailbox"
            class="custom-p-drop"
          >
          </p-dropdown>
        </div>

        <!-- LANGUAGE -->
        <div
          class="dropdown-select"
          [pTooltip]="selectedLanguage.name || 'Select language'"
          tooltipPosition="bottom"
          >
          <p-dropdown
            [options]="languageList"
            [(ngModel)]="selectedLanguage"
            optionLabel="name"
            scrollHeight="150px"
            styleClass="dropdown-wapper"
            placeholder="Select language"
            class="custom-p-drop"
            (onChange)="onLanguageChange($event)"
            >
          </p-dropdown>
        </div>

        <!-- TEMPLATE -->
        <div
          class="dropdown-select"
          [pTooltip]="selectedTemplate.name || 'Select template'"
          tooltipPosition="bottom"
          >
          <p-dropdown
            [options]="templateList"
            [(ngModel)]="selectedTemplate"
            optionLabel="name"
            scrollHeight="150px"
            styleClass="dropdown-wapper"
            placeholder="Select template"
            class="custom-p-drop"
            [disabled]="isDisableTemplateDropdown"
            >
          </p-dropdown>
        </div>
        <div class="intervention-header-feature-button">
          <p-button
            class="button-left"
            label="Preview"
            (onClick)="onSubmitPreview()"
            [disabled]="isReadOnly || isViewer || !isDataChanged"
          ></p-button>
          <!-- [disabled]="false" -->
          <p-button
            class="button-right"
            type="button"
            icon="pi pi-chevron-down"
            [disabled]="isReadOnly"
            [ngClass]="{ 'label-disabled': isViewer }"
            (onClick)="showContextMenu($event)"
          ></p-button>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [appendTo]="'body'"
            [styleClass]="'inherit'"
          ></app-menu>
        </div>
      </div>
    </div>

    <div class="intervention-header-wrapper">
      <span
        >{{ localTimestamp_parent || localTimestamp_parentEdit }} HRS &nbsp;
        (local time)</span
      >
    </div>
    <div class="intervention-header-wrapper">
      <span
        >{{ rigTimestamp_parent || rigTimestamp_parentEdit }} HRS &nbsp; (rig
        time)</span
      >
    </div>
  </div>

  <div class="intervention-content">
    <div
      class="intervention-content-wrapper"
      [ngClass]="{ 'back-portal': backPortal }"
    >
      <div class="intervention-content__form">
        <p-tabView
          #tabViewForm
          (onChange)="handleChangeTab($event, checkDisableSaveBtn)"
          [activeIndex]="isSelectActiveResolveTab ? 1 : 0"
        >
          <p-tabPanel header="INITIATE" [selected]="!isSelectActiveResolveTab">
            <!-- Content for INITIATE FORM -->
            <app-alert-form-initiate
              *ngIf="filtersLoaded | async"
              #formInitiate
              [rigData]="rigData"
              [interventionDataEdit]="alertDataEdit"
              [alertIdEdit]="alertId"
              [flagColor]="flagColor"
              [readOnly]="isReadOnly"
              [selectedTemplate]="selectedTemplate.id || 'default'"
              [selectedLanguage]="selectedLanguage.id || 'English'"
              [rigInterval]="rigInterval"
              [rigRun]="rigRun"
              [localTimestamps]="localTimestamp_parent"
              [rigTimestamps]="rigTimestamp_parent"
              [devident]="devidentCalc"
              [userTimeZone]="currentUser.timezone"
              [rigTimeZone]="rigTimeZone"
              (onChange)="onChangeFormInitiate($event)"
            ></app-alert-form-initiate>

          </p-tabPanel>
          <p-tabPanel
            header="RESOLVE"
            [disabled]="isHideResolveTab"
            [selected]="isSelectActiveResolveTab"
          >
            <!-- Content for RESOLVE FORM -->
            <app-alert-form-resolve
              *ngIf="filtersLoaded | async"
              #formResolve
              [resolveContactMethod]="resolveContactMethod"
              [flagColor]="flagColor"
              [resolveDataEdit]="resolveDataEdit"
              [alertIdEdit]="alertId"
              [readOnly]="isReadOnly"
              (onChange)="onChangeFormResolve($event)"
            ></app-alert-form-resolve>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <div
      class="intervention-content-wrapper right-panel"
      [ngClass]="{ 'back-portal': backPortal }"
      style="padding-left: 12px"
    >
      <div class="intervention-content__log">
        <p-tabView class="right-tabview">
          <p-tabPanel header="TEAM CHAT" [disabled]="false">
            <app-team-chat
              [alertId]="alertId"
              [alertState]="alertState"
              [currentUser]="currentUser"
              [readOnly]="isReadOnly"
              [isViewer]="isViewer"
              [backPortal]="backPortal"
              [userRelated]="userRelated"
            >
            </app-team-chat>
          </p-tabPanel>
          <p-tabPanel header="AUDIT TRAIL">
            <div class="intervention-header-wrapper" *ngIf="isCreateForm">
              <span>
                Created by:
                <b>
                  {{
                    createdBy
                      ? createdBy.user
                      : alertUserData.userCreatedName ||
                        currentUser.firstName + " " + currentUser.lastName
                  }} </b
                >|
                {{
                  createdBy
                    ? createdBy.timestamps
                    : localTimestamp_parent || localTimestamp_parentCreateAt
                }}
                hrs
              </span>
            </div>
            <div
              class="intervention-content__log log-detail"
              *ngFor="let log of auditTrail"
            >
              <div class="intervention-header-wrapper-full">
                <span>
                  {{ log.action }} by:
                  <b>
                    {{ log.userDisplayName }}
                  </b>
                  | {{ log.timestamps }} hrs{{
                    log.status ? " | " + log.status : ""
                  }}
                </span>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showPDF">
  <app-alert-preview-pdf
    [showPDF]="showPDF"
    (reportPDF)="handleReportPDF($event)"
    (saveEditorPDF)="handleReportPDFEditor($event)"    
    (hidePreviewPdf)="hidePreviewPdf($event)"
    (triggerSaveAlert)="triggerSaveAlert($event)"
    [pdfSrc]="pdfSrc"
    [fileName]="pdfSrcName"
    [previewPDFPayload]="previewPDFPayload"
    [alertId]="alertId"
    [isCreateResolve]="isCreateResolve"
    [isResolvedOnTime]="isResolvedOnTime"
    [disableSendfPdf]="disableSendfPdf"
    [mailboxId]="selectedMailbox?.mailboxId || ''"
    [rigJournalId]="rigJournalId"
    [rigName]="rigData.rig.rigName"

    [currentUser]="currentUser"
    [fileAlertDataEditor]="fileAlertDataEditor"
    [isUseEditor]="isUseEditor"
    (updateFlagUseEditor)="updateFlagUseEditor($event)"
  ></app-alert-preview-pdf>
</ng-container>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
