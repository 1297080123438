import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Socket, connect} from 'socket.io-client'
import { environment } from 'src/environments/environment';


@Injectable()
export class WebSocketService {
    socket: Socket
    constructor() {
        this.socket = connect(environment.apiUrl);
    }
    listen(eventName: string): Observable<any> {
        return new Observable((subcribe) => {
            this.socket.on(eventName, (data: any) => {
                subcribe.next(data)
            })
        })
    }
    unlisten(eventName: string): Observable<any> {
        return new Observable((subcribe) => {
            // temporarily fix the issue with socket.off
            this.socket.removeAllListeners(eventName);
        })
    }

    emit(eventName: string, data: any): any {
        this.socket.emit(eventName, data)
    }
}   
