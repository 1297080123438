<div class="rig-list">
  <div class="rig-list__project-name">
    <div class="rig-list__project-name-title" *ngIf="false">
      {{ project[0].project.projectName | maxLengthString : 50 }}
      <span class="tooltipText title">
        {{ project[0].project.projectName | maxLengthString : 50 }}
      </span>
    </div>
    <div
      class="rig-list__project-name-header"
      *ngIf="project[0].project.projectName"
    >
      <div class="d-flex flex-wrap">
        <div
          class="project-header-item project bold"
          [pTooltip]="project[0].project.projectName"
          tooltipPosition="bottom"
        >
          <div>
            <svg-icon class="custom-svg-icon" key="project" size="md"></svg-icon>
          </div>
          <div>{{ project[0].project.projectName | maxLengthString : 50 }}</div>
        </div>
        <div
          class="project-header-item data-source"
          *ngIf="project[0].policy.dataSource"
          [pTooltip]="project[0].policy.dataSource.displaySourceName"
          tooltipPosition="bottom"
        >
          <div class="data-source">
            {{
              project[0].policy.dataSource.displaySourceName
                | maxLengthString : 30
            }}
          </div>
          <div class="yellow-tag">
            {{ project[0].policy.dataSource.databaseSource }}
          </div>
        </div>
        <div
          class="project-header-item company"
          *ngIf="
            project[0].policy.policyCurrentName ||
            project[0].policy.policyOriginalName
          "
          [pTooltip]="
            project[0].policy.policyCurrentName ||
            project[0].policy.policyOriginalName
          "
          tooltipPosition="bottom"
        >
          <div>
            <svg-icon class="custom-svg-icon" key="singleuser" size="md"></svg-icon>
          </div>
          <div>
            {{
              project[0].policy.policyCurrentName ||
                project[0].policy.policyOriginalName | maxLengthString : 30
            }}
          </div>
        </div>
        <div
          class="project-header-item country"
          *ngIf="
            project[0].project.countryObject?.name || project[0].project.country
          "
          [pTooltip]="
            project[0].project.countryObject?.name || project[0].project.country
          "
          tooltipPosition="bottom"
        >
          <div>
            <i
              class="flag flag-{{
                project[0].project.country.toLowerCase().slice(0, 2)
              }}"
            ></i>
          </div>
          <div>
            {{
              project[0].project.countryObject?.name ||
                project[0].project.country | maxLengthString : 30
            }}
          </div>
          <div *ngIf="project[0].project.region">
            &nbsp;({{ project[0].project.region }})
          </div>
        </div>
        <div
          class="project-header-item field"
          *ngIf="project[0].project.field"
          [pTooltip]="project[0].project.field"
          tooltipPosition="bottom"
        >
          <div>
            <svg-icon class="custom-svg-icon" key="field" size="md"></svg-icon>
          </div>
          <div>{{ project[0].project.field | maxLengthString : 30 }}</div>
        </div>
        <div
          class="project-header-item remote-center"
          *ngIf="remoteCenterString"
          [pTooltip]="remoteCenterString"
          tooltipPosition="bottom"
        >
          <div>
            <svg-icon class="custom-svg-icon" key="office" size="md"></svg-icon>
          </div>
          <div>
            {{ remoteCenterString | maxLengthString : 50 }}
          </div>
        </div>
      </div>
      <div
        class="project-edit-icon"
        *ngIf="isViewer || canEditProject"
        pTooltip="Edit Project"
        tooltipPosition="bottom"
        (click)="openEditProject()"
      >
        <svg-icon class="custom-svg-icon item-option" key="setting" size="md"></svg-icon>
      </div>
    </div>
  </div>
  <div class="rig-tile-wapper" style="width: fit-content">
    <div class="rig-list__project-list">
      <ng-container *ngFor="let rig of project; let i = index">
        <div #body>
          <div class="project-item__header-wrapper">
            <div class="project-item__header-feature">
              <div class="project-item__header-feature-icon cursor-pointer d-flex">
                <div
                  *ngIf="rig.distributionListUrl"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="getMouseLocation($event, rig.rigJournalId)"
                >
                  <svg-icon
                    class="custom-svg-icon phone-icon-setting"
                    key="phone"
                    size="md"
                  ></svg-icon>
                </div>
                <div class="more-option" (click)="showContextMenu($event, rig)">
                  <svg-icon
                    class="custom-svg-icon feature-icon-setting"
                    key="dot3hoz"
                    size="md"
                  ></svg-icon>
                </div>
              </div>
            </div>

            <div class="project-item__link">
              <div
                class="d-flex project-item__link--item"
                [ngClass]="{
                  'label-disabled': !rig.communicateProtocolUrl
                }"
                (click)="
                  readPDFdocument(
                    $event,
                    rig.communicateProtocolUrl || '',
                    'rigcommunicateprotocal',
                    project[i]
                  )
                "
              >
                <svg-icon class="custom-svg-icon" key="protocol" size="md"></svg-icon>
                <div>Protocol</div>
              </div>
              <div
                class="d-flex project-item__link--item"
                [ngClass]="{ 'label-disabled': !rig.dataRoutingUrl }"
                (click)="
                  readPDFdocument(
                    $event,
                    rig.dataRoutingUrl || '',
                    'rigdatarouting',
                    project[i]
                  )
                "
              >
                <svg-icon class="custom-svg-icon" key="routing" size="md"></svg-icon>
                <div>Routing</div>
              </div>
              <div
                class="d-flex project-item__link--item"
                [ngClass]="{
                  'label-disabled': !rig?.interval?.designOfServiceUrl
                }"
                (click)="
                  readPDFdocument(
                    $event,
                    rig?.interval?.designOfServiceUrl || '',
                    'intervaldesignofservice',
                    project[i]
                  )
                "
              >
                <svg-icon class="custom-svg-icon" key="ionesheet" size="md"></svg-icon>
                <div>iOneSheet</div>
              </div>
              <div
                class="d-flex project-item__link--item"
                [ngClass]="{
                  'label-disabled': rig.isComplete
                }"
                (click)="redirectTo(rig.rigJournalId, rig.rig.rigName)"
              >
                <svg-icon class="custom-svg-icon" key="alerts" size="md"></svg-icon>
                <div>Alerts</div>
              </div>
            </div>

            <div class="project-item__final-status project-item__final-status--safe">
              <div class="project-item__btn">
                <div
                  [pTooltip]="rig.rigCondition || ''"
                  tooltipPosition="bottom"
                  [ngClass]="
                    !rig.isComplete &&
                    rig.project.curUnit &&
                    rig.mailboxList.length &&
                    rig.remoteCenterList.length &&
                    rig.distributionListUrl
                      ? ''
                      : 'secondary-disabled'
                  "
                >
                  <p-button
                    [attr.data-cy]="'alert-button'"
                    class="custom-p-button custom-p-button--primary"
                    label="Alert"
                    (onClick)="showIntervene(rig)"
                  ></p-button>
                </div>
              </div>
            </div>

            <div
              class="project-item"
              [ngClass]="
                activeRigCard.rigJournalId === rig.rigJournalId
                  ? ' project-item--active'
                  : ''
              "
              (click)="executeFilter(rig.rigJournalId, rig.rig.rigName)"
            >
              <div class="project-item_header">
                <div class="project-item__header-title d-flex">
                  <div>
                    <svg-icon
                      class="custom-svg-icon"
                      [ngClass]="{ active: !rig.isComplete }"
                      key="rig"
                      size="md"
                    ></svg-icon>
                  </div>
                  <div  class="cursor-pointer rig-name">
                    <span
                      [pTooltip]="rig.rig.rigName || ''"
                      tooltipPosition="bottom"
                    >
                      {{ rig.rig.rigName || "" }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="project-item_well">
                <div class="project-item_well-name">
                  <div>
                    <svg-icon
                      class="custom-svg-icon"
                      [ngClass]="{ active: !rig.isComplete }"
                      key="well"
                      size="md"
                    ></svg-icon>
                  </div>
                  <div class="well-wellbore-name">
                    {{ rig.well.wellName | maxLengthString : 12 }} /
                    {{ rig.wellbore.wellboreName | maxLengthString : 12 }}
                    <span class="tooltipText title">
                      {{ rig.well.wellName }} /
                      {{ rig.wellbore.wellboreName }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="project-item__image__link">
                <ng-container *ngIf="rig.rig.imageUrl">
                  <div class="project-item__image">
                    <img
                      [src]="rig.rig.rigImageBlob | safe : 'url'"
                      alt="Image of Rig"
                      class="project-item__image-display"
                    />
                  </div>
                </ng-container>

                <ng-container *ngIf="!rig.rig.imageUrl">
                  <div class="project-item__image">
                    <img
                      [src]="rig.rig.rigDefaultImage | safe : 'url'"
                      alt="Image of Rig"
                      class="project-item__image-display"
                    />
                  </div>
                </ng-container>
              </div>
              <div class="project-item__active-status-list">
                <ng-container *ngIf="!rig.isComplete">
                  <ng-container
                    *ngFor="let flag of rig.flagStatistic; let i = index"
                  >
                    <div
                      *ngIf="flag.value > 0"
                      class="project-item__active-status d-flex gap-2"
                      [ngClass]="'project-item__active-status--' + flag.key"
                    >
                      <svg-icon class="custom-svg-icon" key="done" size="md"></svg-icon>
                      <div>{{ flag.value }}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="isSkeleton">
        <p-skeleton width="280px" height="286px"></p-skeleton>
      </div>
      <app-menu
        #menu
        [optionList]="getMenuItemsForItem$ | async"
        [styleClass]="'extend-md-width-menu'"
      ></app-menu>
    </div>
  </div>
</div>

<ng-container *ngIf="displayEditProject">
  <app-home-edit-project
    [displayEditProject]="displayEditProject"
    [project]="editProject"
    (hideEditProject)="hideEditProject($event)"
  ></app-home-edit-project>
</ng-container>

<!-- Add Wellbore Dialog Component -->
<ng-container *ngIf="display_menuAddWellbore">
  <app-home-add-wellbore
    [displayAddWellboreDialog]="display_menuAddWellbore"
    [addwellboreLevelRigCard]="dataMenuAddWellboreRigCardLevel"
    (hideAddWellboreDialog)="hideAddWellboreDialog($event)"
    (hideAddWellboreDialogAndReload)="hideAddWellboreDialog($event)"
  ></app-home-add-wellbore>
</ng-container>

<ng-container *ngIf="displayFormRig">
  <app-home-form-rig
    [displayFormRig]="displayFormRig"
    [rigEditId]="rigEditId"
    [rigJournalEditId]="rigJournalEditId"
    [projectName]="projectNameEdit"
    (hideFormRig)="displayFormRig = $event"
    (requestReloadPage)="hideEditRig($event)"
  ></app-home-form-rig>
</ng-container>
