import { Pipe, PipeTransform } from '@angular/core';
import { AppHelper } from '../../utilities/app.helper';

@Pipe({
  name: 'unitConversionPipe',
})
export class MathUnitConversionPipe implements PipeTransform {
  /**
   * Converts the input value from one unit system to another based on the provided parameters.
   *
   * @param value - The input value to be converted.
   * @param unitSystem - The unit system object containing the unit and converter information.
   * @param referenceUnit - The reference unit to convert to.
   * @param currentUnit - The current unit of the input value.
   * @param unitType - The type of unit being converted (e.g., 'diameter', 'depth').
   * @param factorDiameterNumber - The conversion factor for diameter units.
   * @param digitDiameterNumber - The number of digits to round diameter values to.
   * @param factorDepthNumber - The conversion factor for depth units.
   * @param digitDepthNumber - The number of digits to round depth values to.
   * @returns The converted value with the appropriate unit.
   */

  transferData(
    value: any,
    unitSystem: any,
    referenceUnit: string,
    currentUnit: string,
    unitType: string,
    factorDiameterNumber: number,
    digitDiameterNumber: number,
    factorDepthNumber: number,
    digitDepthNumber: number
  ): string {
    let formUnitOld: any;
    let formUnitNew: any;
    let unitConverterDiameter = 0;
    let unitConverterDepth = 0;
    let formUnit: any;
    let digitDiameter = 0;
    let digitDepth = 0;

    if (currentUnit) {
      if (currentUnit && currentUnit !== referenceUnit) {
        formUnitOld = AppHelper.MathFunctions.findUnit(
          currentUnit,
          unitSystem.unit
        );

        formUnitNew = AppHelper.MathFunctions.findUnit(
          referenceUnit,
          unitSystem.unit
        );

        for (const key in unitSystem.converter) {
          let combineConvertersDiameterKey =
            formUnitOld.Diameter + '2' + formUnitNew.Diameter;
          let combineDepthDiameterKey =
            formUnitOld.Depth + '2' + formUnitNew.Depth;

          if (key === combineConvertersDiameterKey.toLowerCase()) {
            unitConverterDiameter = unitSystem.converter[key];
          }

          if (key === combineDepthDiameterKey.toLowerCase()) {
            unitConverterDepth = unitSystem.converter[key];
          }
        }
      }
    }
    formUnit = AppHelper.MathFunctions.findUnit(referenceUnit, unitSystem.unit);
    digitDiameter = AppHelper.MathFunctions.getDigitUnitSystems(
      formUnit.Diameter
    );
    digitDepth = AppHelper.MathFunctions.getDigitUnitSystems(formUnit.Depth);

    factorDiameterNumber = unitConverterDiameter;
    factorDepthNumber = unitConverterDepth;

    digitDiameterNumber = digitDiameter;
    digitDepthNumber = digitDepth;

    switch (unitType) {
      case 'diameter':
        {
          let parsedValue = parseFloat(value?.toString().replace(/,/g, ''));
          let roundedValue = AppHelper.MathFunctions.financial(
            factorDiameterNumber === 0
              ? parsedValue * 1
              : parsedValue * factorDiameterNumber,
            digitDiameterNumber
          );
          return isNaN(roundedValue)
            ? 'N/A'
            : `${roundedValue.toLocaleString('en-US', {
                useGrouping: true,
              })} ${formUnit.Diameter}`;
        }
      case 'depth':
        {
          let parsedValue = parseFloat(value?.toString().replace(/,/g, ''));
          let roundedValue = AppHelper.MathFunctions.financial(
            factorDepthNumber === 0
              ? parsedValue * 1
              : parsedValue * factorDepthNumber,
            digitDepthNumber
          );
          return isNaN(roundedValue)
            ? 'N/A'
            : `${roundedValue.toLocaleString('en-US', {
                useGrouping: true,
              })} ${formUnit.Depth}`;
        }
      default:
        return 'N/A';
    }
  }

  /**
   * Transforms the input value based on the unit conversion parameters.
   *
   * @param value - The input value to be transformed.
   * @param unitSystem - The unit system object containing the unit and converter information.
   * @param referenceUnit - The reference unit to convert to. ==> Unit of Project
   * @param currentUnit - The current unit of the input value. ==> Unit of object (ex: Unit when create Interval, Unit when create Run ....)
   * @param unitType - The type of unit being converted (e.g., 'diameter', 'depth').
   * @param factorDiameterNumber - The conversion factor for diameter units.
   * @param digitDiameterNumber - The number of digits to round diameter values to.
   * @param factorDepthNumber - The conversion factor for depth units.
   * @param digitDepthNumber - The number of digits to round depth values to.
   * @returns The transformed value with the appropriate unit.
   */

  transform(
    value: any,
    unitSystem: any,
    referenceUnit: string,
    currentUnit: string,
    unitType: string,
    factorDiameterNumber: number,
    digitDiameterNumber: number,
    factorDepthNumber: number,
    digitDepthNumber: number
  ): string {
    return this.transferData(
      value,
      unitSystem,
      referenceUnit,
      currentUnit,
      unitType,
      factorDiameterNumber,
      digitDiameterNumber,
      factorDepthNumber,
      digitDepthNumber
    );
  }
}
