<div class="tabview-guide">
  <div class="search-tabview-guide">
    <!-- <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        type="text"
        pInputText
        placeholder="Search by flow, role, etc"
        [(ngModel)]="searchValue"
      />
    </span> -->
  </div>
  <!--  -->
  <!--  -->
  <!--  -->
  <!-- View Admin -->
  <div class="flex flex-row" *ngIf="roleView === 'Admin' || roleView === 'Viewer'; else anotherRole">
    <div class="w-3 navigate-categories-guide">
      <div class="flex flex-row justify-content-between">
        <p class="title-navigation-list">Chapters</p>
        <i
          class="pi pi-plus m-1 icon-add-category hightlight"
          (click)="openCreateCategoryForm()"
        ></i>
      </div>
      <div class="title-opened">
        <div cdkDropListGroup>
          <div class="example-container">
            <div
              cdkDropList
              [cdkDropListData]="categories"
              class="example-list"
              (cdkDropListDropped)="dropCategory($event)"
            >
              <div
                class="example-box"
                *ngFor="let category of categories"
                cdkDrag
                pTooltip="Drag & Drop to change position"
                tooltipPosition="bottom"
                tooltipStyleClass="tooltip-text-align-left"
              >
                <ng-container *ngIf="category.chapterList.length">
                  <div class="flex flex-column w-full">
                    <!-- Category -->
                    <div
                      class="flex flex-row pl-2 pt-2 pb-2 justify-content-between item-chapter-hover w-full chapter-item"
                      (click)="
                        (itemSelected?.id || null) === category?.categoryId
                          ? ''
                          : ShowConfirmSwitchItemAndDisableEditor(
                              category,
                              'CATEGORY'
                            )
                      "
                      [ngClass]="
                        itemSelected?.id === category.categoryId
                          ? 'selected-child'
                          : ''
                      "
                    >
                      <p class="mb-0">
                        {{ category.categoryName }}
                      </p>

                      <i
                        class="pi pi-ellipsis-v display-when-hover hide-child"
                        (click)="
                          $event.stopPropagation();
                          menuCategory.toggle($event);
                          (itemSelected?.id || null) === category?.categoryId
                            ? (categorySelect = category)
                            : ShowConfirmSwitchItemAndDisableEditor(
                                category,
                                'CATEGORY'
                              )
                        "
                      ></i>
                    </div>
                    <!-- Chapter -->
                    <ng-container>
                      <div
                        cdkDropList
                        class="example-list"
                        (cdkDropListDropped)="dropChapter($event)"
                        [cdkDropListData]="category.chapterList"
                        (click)="$event.stopPropagation()"
                      >
                        <div
                          class="example-box pb-0 pt-0"
                          *ngFor="let chapter of category.chapterList"
                          [cdkDragData]="chapter"
                          cdkDrag
                        >
                          <div
                            class="flex flex-row pl-2 pt-2 pb-2 justify-content-between item-chapter-hover w-full chapter-item"
                            [ngClass]="
                              itemSelected?.id === chapter.chapterId
                                ? 'selected-child'
                                : ''
                            "
                            (click)="
                              (itemSelected?.id || null) === chapter?.chapterId
                                ? ''
                                : ShowConfirmSwitchItemAndDisableEditor(
                                    chapter,
                                    'CHAPTER'
                                  )
                            "
                          >
                            <p class="ml-4 mb-0">
                              {{ chapter.chapterName }}
                            </p>
                            <i
                              class="pi pi-ellipsis-v display-when-hover hide-child"
                              (click)="
                                $event.stopPropagation();
                                menuChapter.toggle($event);
                                categorySelect = category;
                                (itemSelected.id || null) === chapter.chapterId
                                  ? ''
                                  : ShowConfirmSwitchItemAndDisableEditor(
                                      chapter,
                                      'CHAPTER'
                                    )
                              "
                            ></i>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="!category.chapterList.length">
                  <div
                    class="pl-2 pt-2 pb-2 flex flex-row justify-content-between item-chapter-hover w-full chapter-item"
                    (click)="
                      (itemSelected.id || null) === category.categoryId
                        ? ''
                        : ShowConfirmSwitchItemAndDisableEditor(
                            category,
                            'CATEGORY'
                          )
                    "
                    [ngClass]="
                      itemSelected.id === category.categoryId
                        ? 'selected-chapter'
                        : ''
                    "
                  >
                    <p class="item-chapter-hover">
                      {{ category.categoryName }}
                    </p>
                    <i
                      class="pi pi-ellipsis-v display-when-hover"
                      (click)="
                        $event.stopPropagation();
                        menuCategory.toggle($event);
                        itemSelected?.id === category?.categoryId
                          ? ''
                          : ShowConfirmSwitchItemAndDisableEditor(
                              category,
                              'CATEGORY'
                            )
                      "
                    ></i>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!-- ADD CATEGORY -->
      <form [formGroup]="createCategoryForm">
        <div
          [hidden]="!createCategoryEnable"
          class="mb-2 flex flex-row gap-1 relative add-new-chapter"
        >
          <input
            #nameCategory
            formControlName="name"
            type="text"
            pInputText
            class="input-text-new-chapter"
            TextInputValidate
            typeCheck="short"
          />
          <div class="absolute button-confirm-add-chapter">
            <i
              class="pi pi-times mr-2 hightlight"
              (click)="cancelAddNewCategory()"
            ></i>
            <i
              class="pi pi-check mr-2 hightlight"
              [ngClass]="{'label-disabled': isViewer}"
              (click)="addNewCategory()"
              type="submit"
            ></i>
          </div>
        </div>
      </form>
      <ng-container *ngIf="createCategoryForm.status === 'INVALID'">
        <small
          class="p-error block"
          *ngIf="
            (createCategoryForm.get('name')?.errors?.required &&
              createCategoryForm.get('name')?.dirty) ||
            (createCategoryForm.get('name')?.errors?.required &&
              createCategoryForm.get('name')?.errors?.blank &&
              createCategoryForm.get('name')?.dirty)
          "
        >
          {{ "Category" + " is required" }}
        </small>
        <small
          class="p-error block"
          *ngIf="
            createCategoryForm.get('name')?.errors?.blank &&
            !createCategoryForm.get('name')?.errors?.required &&
            createCategoryForm.get('name')?.dirty
          "
        >
          {{ "Category" + " is required" }}
        </small>
        <small
          class="p-error block"
          *ngIf="
            !createCategoryForm.get('name')?.errors?.blank &&
            !createCategoryForm.get('name')?.errors?.required &&
            createCategoryForm.get('name')?.errors?.duplicated &&
            createCategoryForm.get('name')?.dirty
          "
        >
          {{ "Category" + " is duplicated" }}
        </small>
        <small
          class="p-error block"
          *ngIf="
            !createCategoryForm.get('name')?.errors?.duplicated &&
            createCategoryForm.get('name')?.errors?.maxlength &&
            createCategoryForm.get('name')?.dirty
          "
        >
          {{ "Value must be no more than " + 50 + " characters" }}
        </small>
      </ng-container>
      <!-- <div>{{ createCategoryForm.get("name")?.errors | json }}</div> -->
    </div>
    <!--  -->
    <!--  -->
    <!--  -->
    <!-- EDITOR ADMIN VIEW -->
    <div class="flex-grow-1 flex-shrink-0 content-guide-editor">
      <div
        class="flex flex-row justify-content-between tittle-icon-editor"
        *ngIf="(categories || []).length"
      >
        <p class="description-editor">Description</p>
        <i
          class="pi pi-pencil icon-editor"
          [ngClass]="{ 'icon-editor-disabled': isViewer }"
          (click)="onShowEditor()"
          *ngIf="!editorEnable"
        ></i>
        <div class="flex flex-row" *ngIf="editorEnable">
          <i
            class="pi pi-times icon-editor mr-2 hightlight"
            (click)="onHideEditor()"
          ></i>
          <i
            class="pi pi-check icon-editor mr-3 hightlight"
            (click)="updateDescriptionItem()"
          ></i>
        </div>
      </div>
      <ng-container>
        <p-editor
          [(ngModel)]="tempEditor"
          [style]="{ width: '100%' }"
          [readonly]="!editorEnable"
          [styleClass]="editorEnable ? '' : 'disable-editor'"
          styleClass="disable-editor"
        >
      </p-editor>
      </ng-container>
      <!-- <div
        [innerHtml]="transform(itemSelected.content || '')"
        *ngIf="!editorEnable"
      ></div> -->
    </div>
  </div>
  <!--  -->
  <!--  -->
  <!--  -->
  <!--  -->
  <!-- view Engineer -->
  <ng-template #anotherRole>
    <div class="flex flex-row">
      <div class="w-3 navigate-categories-guide">
        <div class="flex flex-row justify-content-between">
          <p class="title-navigation-list">Chapters</p>
        </div>
        <div class="title-opened">
          <div cdkDropListGroup>
            <div class="example-container">
              <div
                cdkDropList
                [cdkDropListData]="categories"
                class="example-list"
                (cdkDropListDropped)="dropCategory($event)"
              >
                <div class="example-box" *ngFor="let category of categories">
                  <ng-container *ngIf="category.chapterList.length">
                    <div class="flex flex-column w-full">
                      <div
                        class="flex flex-row justify-content-between item-chapter-hover w-full chapter-item"
                        (click)="selectedCategoryAndLoadDescription(category)"
                        [ngClass]="
                          itemSelected.id === category.categoryId
                            ? 'selected-child'
                            : ''
                        "
                      >
                        <p class="ml-2 mt-2 mb-2">
                          {{ category.categoryName }}
                        </p>
                      </div>
                      <div
                        class="flex flex-row justify-content-between pl-3 item-child-chapter item-chapter-hover chapter-item"
                        *ngFor="let chapter of category.chapterList"
                        [ngClass]="
                          itemSelected.id === chapter.chapterId
                            ? 'selected-child'
                            : ''
                        "
                        (click)="selectedChapterAndLoadDescription(chapter)"
                      >
                        <p class="hide-child mb-0 ml-2 mt-2 mb-2">
                          {{ chapter.chapterName }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!category.chapterList.length">
                    <div
                      class="flex flex-row justify-content-between item-chapter-hover w-full chapter-item"
                      [ngClass]="
                        itemSelected.id === category.categoryId
                          ? 'selected-chapter'
                          : ''
                      "
                      (click)="selectedCategoryAndLoadDescription(category)"
                    >
                      <p class="item-chapter-hover ml-2 mt-2 mb-2">
                        {{ category.categoryName }}
                      </p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->
      <!-- EDITOR - ONLY VIEW -->
      <div class="flex-grow-1 flex-shrink-0 content-guide-editor">
        <div class="flex flex-row justify-content-between">
          <p>Description</p>
        </div>
        <ng-container *ngIf="true">
          <p-editor
            [(ngModel)]="tempEditor"
            [style]="{ width: '100%' }"
            [readonly]="true"
            [styleClass]="editorEnable ? '' : 'disable-editor'"
            styleClass="disable-editor"
          ></p-editor>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <!--  -->
</div>

<!--  -->
<p-menu
  #menuCategory
  [model]="categoryMenu"
  [popup]="true"
  styleClass="menucusCategory"
></p-menu>
<p-menu
  #menuChapter
  [model]="chapterMenu"
  [popup]="true"
  styleClass="menucusChapter"
></p-menu>

<p-toast [breakpoints]="{ top: '40px' }">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-center">
        <div class="flex flex-row gap-2 header-message">
          <fa-icon [icon]="faCircleInfo"></fa-icon>
          <p>{{ message.data.header }}</p>
        </div>
        <p class="detail-message">{{ message.detail }}</p>
        <p class="time-message">
          {{ "Today, " + message.data.hours + " : " + message.data.minutes }}
        </p>
      </div>
    </div>
  </ng-template>
</p-toast>

<!-- popup -->
<div class="warning-dialog-exist-editor">
  <p-dialog
    [header]="'Cancel'"
    [(visible)]="visibleWarningCancelEditItem"
    [modal]="true"
  >
    <div>
      <p class="selected-chapter">
        Do you want to leave without saving information?
      </p>
    </div>
    <ng-template pTemplate="footer">
      <div class="d-flex justify-content-end mt-4 gap-2">
        <p-button
          [attr.data-cy]="'cancel-button'"
          type="button"
          label="Cancel"
          class="custom-p-button custom-p-button--primary"
          (onClick)="visibleWarningCancelEditItem = false"
        ></p-button>
        <p-button
          [attr.data-cy]="'confirm-button'"
          type="button"
          label="Confirm"
          class="custom-p-button custom-p-button--secondary"
          (onClick)="confirmSwitchItemAndDisableEditor(informationSwitchAndExitEditor)"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>
</div>
<template #createContainer></template>
<template #deleteContainer></template>
<app-loading [isLoading]="isLoading"></app-loading>
