<div class="mb-2">
  <app-customizable-button
    [showButton]="true" 
    [label]="'Add Run'" 
    [style]="'light-sm'"
    [isDisabled]="false"
    [elementId]="'addRunButton'"
    [isToolTip]="false"
    [toolTipContent]="''"
    [tooltipPosition]="'bottom'"
    (eventButton)="showPopUpRun()">
  </app-customizable-button>
</div>
<form #searchForm="ngForm" *ngIf="!!formRunItem" [formGroup]="formRunItem">
  <div class="project__filter rig-list__filter filter-wrapper">
    <div class="d-flex align-items-end">
      <div class="rig-list__filter">
        <label for="float-input-invalid" class="label-disabled">Project</label>
        <span class="">
          <input
            type="text"
            pInputText
            placeholder="Project"
            class="search-input custom-p-input"
            maxlength="50"
            name="searchString"
            [formControlName]="fieldNameKeys.project"
          />
        </span>
      </div>

      <div class="rig-list__filter project__filter--dropdown">
        <label for="float-input-invalid"
          >Well<span class="p-error"> *</span></label
        >
        <p-dropdown
          inputId="dropdown"
          [autoDisplayFirst]="true"
          [options]="restoreWellList"
          optionLabel="name"
          placeholder="Well"
          class="search-input custom-p-drop"
          name="filterByActive"
          [formControlName]="fieldNameKeys.well"
          [showClear]="true"
          (onClear)="onClearWellList()"
          (onChange)="bindingWell()"
          [resetFilterOnHide]="true"
        ></p-dropdown>
      </div>

      <div class="rig-list__filter project__filter--dropdown">
        <label for="float-input-invalid"
          >Wellbore<span class="p-error"> *</span></label
        >
        <p-dropdown
          inputId="dropdown"
          [autoDisplayFirst]="true"
          [options]="rigListDataWellbore"
          placeholder="Wellbore"
          class="search-input custom-p-drop"
          name="filterByActive"
          [formControlName]="fieldNameKeys.wellbore"
          [showClear]="true"
          (onChange)="bindingWellbore()"
          (onClear)="onClearWellboreList()"
          [resetFilterOnHide]="true"
        >
          <ng-template let-wellbore pTemplate="selectedItem">
            <div class="" *ngIf="fieldNameKeys.wellbore">
              <div class="d-flex align-items-center">
                  {{ wellbore.wellboreCurrentName || wellbore.wellboreOriginalName }}
              </div>
            </div>
          </ng-template>
          <ng-template let-wellbore pTemplate="item">
            <div class="d-flex align-items-center">
              {{ wellbore.wellboreCurrentName || wellbore.wellboreOriginalName }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="rig-list__filter project__filter--dropdown">
        <label for="float-input-invalid"
          >Interval<span class="p-error"> *</span></label
        >
        <p-dropdown
          inputId="dropdown"
          [autoDisplayFirst]="true"
          [options]="rigListDataInterval"
          placeholder="Interval"
          class="search-input custom-p-drop"
          name="filterByActive"
          [formControlName]="fieldNameKeys.interval"
          [showClear]="true"
          (onChange)="bindingInterval()"
          (onClear)="onClearIntervalList()"
          [resetFilterOnHide]="true"
        >
          <ng-template let-wellbore pTemplate="selectedItem">
            <div class="" *ngIf="fieldNameKeys.interval">
              <div class="d-flex align-items-center">
                  {{ wellbore.intervalName }}
              </div>
            </div>
          </ng-template>
          <ng-template let-wellbore pTemplate="item">
            <div class="d-flex align-items-center">
                {{ wellbore.intervalName }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="project__filter-button project__filter-button--dark d-none">
        <p-button label="Filters"></p-button>
      </div>
    </div>
    <div class="rig-list__filter--counter">
      <span>Total: {{ rigListDataRun.length }}</span>
    </div>
  </div>

  <p-table
    [value]="rigListDataRun"
    [columns]="rigListRunColumn"
    [scrollable]="true"
    [resizableColumns]="true"
    styleClass="p-datatable-striped custom-table"
  >
    <ng-template pTemplate="header" let-columns>
      <tr style="height: 40px">
        <th
          *ngFor="let col of columns; last as isLast; let i = index"
          [pSortableColumn]="col.field"
          pResizableColumn
          [ngClass]="isLast ? 'th-last' : 'th-default'"
        >
          <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
          </ng-container>

          <ng-template #showThNotLast>
            <div
              [pTooltip]="col.header"
              tooltipPosition="bottom"
              class="th-content"
            >
              {{ col.header }}
            </div>
            <div class="header-sort-icon">
              <i
                class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
              ></i>

              <i
                class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
              ></i>
            </div>
          </ng-template>
          <ng-template #showThLast></ng-template>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td class="td-default">
          <span [pTooltip]="rowData.runNo" tooltipPosition="bottom">
            {{ rowData.runNo }}
          </span>
        </td>
        <td class="td-default">
          <span [pTooltip]="rowData.description" tooltipPosition="bottom">
            {{ rowData.description }}
          </span>
        </td>
        <td class="td-default">
          <span
            *ngIf="rowData.startMeasuredDepth"
            [pTooltip]="rowData.startMeasuredDepth"
            tooltipPosition="bottom"
          >
            {{
              rowData.startMeasuredDepth
                | unitConversionPipe
                  : UNIT_SYSTEM
                  : projectData.curUnit
                  : (rowData.unit ? rowData.unit : "")
                  : "depth"
                  : 0
                  : 0
                  : 0
                  : 0
            }}
          </span>
          <span
            *ngIf="!rowData.startMeasuredDepth"
            [pTooltip]="'N/A'"
            tooltipPosition="bottom"
          >
            {{ "N/A" }}
          </span>
        </td>
        <td class="td-default">
          <span
            *ngIf="rowData.tdMeasuredDepth"
            [pTooltip]="rowData.tdMeasuredDepth"
            tooltipPosition="bottom"
          >
            {{
              rowData.tdMeasuredDepth
                | unitConversionPipe
                  : UNIT_SYSTEM
                  : projectData.curUnit
                  : (rowData.unit ? rowData.unit : "")
                  : "depth"
                  : 0
                  : 0
                  : 0
                  : 0
            }}
          </span>
          <span
            *ngIf="!rowData.tdMeasuredDepth"
            [pTooltip]="'N/A'"
            tooltipPosition="bottom"
          >
            {{ "N/A" }}
          </span>
        </td>
        <td class="td-default">
          <span
            [pTooltip]="rowData.belowRotaryTimestamp | format24h : true"
            tooltipPosition="bottom"
          >
            {{ rowData.belowRotaryTimestamp | format24h : true }}
          </span>
        </td>
        <td class="td-default">
          <span
            [pTooltip]="rowData.aboveRotaryTimestamp | format24h : true"
            tooltipPosition="bottom"
          >
            {{ rowData.aboveRotaryTimestamp | format24h : true }}
          </span>
        </td>
        <td class="td-default">
          <span
            [pTooltip]="rowData.runTdTimestamp | format24h : true"
            tooltipPosition="bottom"
          >
            {{ rowData.runTdTimestamp | format24h : true }}
          </span>
        </td>

        <td style="min-width: 50px; max-width: 50px">
          <div class="more-option-table">
            <svg-icon
              class="header-content__title--feature"
              key="dot3hoz"
              size="md"
              (click)="showContextMenu($event, rowData)"
            >
            </svg-icon>
          </div>
        </td>
        <app-menu
          #menu
          [optionList]="getMenuItemsForItem$ | async"
          [appendTo]="'body'"
          [styleClass]="'menu-table-item'"
        ></app-menu>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          {{ emptyMsg }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</form>

<ng-container *ngIf="displayFormRunAdd">
  <app-home-form-run
    [projectId]="projectData.id"
    [projectUnit]="projectData.curUnit"
    [displayFormRun]="displayFormRunAdd"
    [wellbore]="formRunItem.get('wellbore')?.value"
    [interval]="formRunItem.get('interval')?.value"
    (hide_formRun)="hideFormRun($event)"
    (reload_formRun)="reloadData()"
    [rigId]="rigId"
    [fromChangeStatus]="fromChangeStatus"
    [rigListDataRun]="rigListDataRun"
    [isViewer]="isViewer"
    ></app-home-form-run>
</ng-container>

<ng-container *ngIf="displayFormRunEdit">
  <app-home-form-run
    [projectId]="projectData.id"
    [projectUnit]="projectData.curUnit"
    [displayFormRun]="displayFormRunEdit"
    [run]="editRun"
    [wellboreList]="rigListDataWellbore"
    [intervalList]="rigListDataInterval"
    [rigListDataInterval]="rigListDataInterval"
    (hide_formRunEdit)="hideFormRun($event)"
    (reload_formRun)="reloadData()"
    [rigId]="rigId"
    [fromChangeStatus]="fromChangeStatus"
    [returnRigId]="returnRigId"
    [returnWellboreId]="returnWellboreId"
    [isViewer]="isViewer"
    (request_changeTab)="changeTab($event)"
    (onChange)="bindingWellbore()"
    (onClear)="bindingWellbore()"
    [wellboreNameOverride]="wellboreNameOverride"
  ></app-home-form-run>
</ng-container>