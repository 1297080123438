import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLengthString',
})
export class StringMaxLengthPipe implements PipeTransform {
  /**
   * Transforms the input string by truncating it to the specified maximum length.
   * If the string exceeds the maximum length, it will be trimmed and appended with '...' by default.
   *
   * @param value - The input string to be transformed.
   * @param maxLength - The maximum length of the string.
   * @param ellipsis - Optional. The string to append when the input is truncated. Default is '...'.
   * @returns The transformed string, truncated to the specified maximum length.
   */

  transform(value: any, args?: any): string {
    if (value.length > args) {
      return value.trim().slice(0, args) + '...';
    } else {
      return value.trim().slice(0, args);
    }
  }
}
