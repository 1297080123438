import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { RigManagementComponent } from './rig-management/rig-management.component';
import { RigService } from 'src/app/shared/services/rig.service';
import { RigFormComponent } from './rig-form/rig-form.component';
import { PaginatorModule } from 'primeng/paginator';

const declarations = [RigManagementComponent, RigFormComponent];

const imports: any = [
  ShareModule,
  FormsModule,
  PaginatorModule
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [RigManagementComponent, RigFormComponent],
  providers: [RigService],
})
export class RigModule {}
