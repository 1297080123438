<div class="user">
  <app-module-header
    [backPortal]="true"
    backPortalLabel="Back to Team Management"
    headerTitle="User Permissions"
    [buttonFearture]="false"
    buttonFeartureLabel="Nothing Render Because buttonFearture === false"
    [bottomMargin]="true"
    (onClickBack)="backHistory()"
  ></app-module-header>

  <div class="d-flex align-items-center user-info">
    <ng-container *ngIf="user">
      <div
        *ngIf="!user.avatar"
        class="avatar-random"
        [attr.data-label]="getFirstLetter(user?.displayName || 'UU')"
        [appRandomAvatar]="getFirstLetter(user?.displayName || 'UU')"
        avatarSize="medium"
      ></div>

      <ng-container *ngIf="user.avatar">
        <ng-container *ngIf="!avatarImageBlob">
          <div class="avatar">
            <div
              class="content-progress-bar"
              style="height: inherit; display: flex; align-items: center"
            >
              <span class="loader scale-down"></span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="avatarImageBlob">
          <div class="avatar">
            <img [src]="avatarImageBlob | safe : 'url'" alt="Avatar" />
          </div>
        </ng-container>
      </ng-container>

      <div class="info">
        <div class="info-name">
          {{
            user?.firstName
              ? user?.firstName + " " + user?.lastName
              : user?.displayName
          }}
        </div>
      </div>
    </ng-container>
  </div>

  <div class="role-wrapper" *ngIf="!!formEditRoleGroup" [formGroup]="formEditRoleGroup">
    <div class="role-filter">
      <div class="d-flex">
        <div class="">
          <label for="event" class="form-label">Role</label>
        </div>
        <div class="role-dropdown">
          <p-treeSelect
            [options]="roles"
            placeholder="Select Role"
            [formControlName]="fieldNameKeys.role"
            [propagateSelectionDown]="false"
            (onNodeSelect)="onOptionsSelected($event)"
            (onClear)="onClearRole($event)"
          >
            <ng-template pTemplate="value">
              {{ selectedNodeString || "Select Role" }}
            </ng-template>
          </p-treeSelect>
        </div>
      </div>

      <div
        class="role-reset d-flex align-items-center cursor-pointer"
        [ngClass]="isShowResetBtn ? '' : 'd-none'"
        (click)="resetPermissions('Engineer')"
      >
        <div class="custom-svg-icon">
          <svg-icon key="reset" size="md"></svg-icon>
        </div>
        <label for="Reset" class="button-label ms-2">Reset</label>
      </div>
    </div>
    <div
      style="padding-left: 30px"
      *ngIf="isInvalidControl(fieldNameKeys.role)"
    >
      <ng-container *ngFor="let msg of getErrorByField(fieldNameKeys.role)">
        <div class="inner-msg-error">
          <span>{{ msg }}</span>
        </div>
      </ng-container>
    </div>

    <div class="role-table">
      <p-table
        [value]="roleListData"
        [columns]="roleListColumn"
        [scrollable]="true"
        styleClass="p-datatable-striped custom-table"
      >
        <ng-template pTemplate="header" let-columns>
          <tr style="height: 40px">
            <th
              *ngFor="
                let col of columns;
                first as isFirst;
                last as isLast;
                let i = index;
              "
              [pSortableColumn]="col.field"
              [ngClass]="{ 'th-first': isFirst, 'th-last-permission': isLast }"
              [style]=" isFirst ? { 'justify-content': 'space-between', 'padding-left': '12px' } : {}"
            >
              <ng-container *ngIf="!isFirst; then showThNotFirst; else showThNotFirst"></ng-container>

              <ng-template #showThNotFirst>
                <div
                  [pTooltip]="col.header"
                  tooltipPosition="bottom"
                  class="th-content"
                >
                  {{ col.header }}
                </div>
                <div *ngIf="isFirst" class="header-sort-icon">
                  <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>
                  <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></i>
                </div>
              </ng-template>
              <ng-template #showThNotFirst></ng-template>
            </th>
          </tr>
        </ng-template>

        <ng-template
          pTemplate="body"
          let-Permissions
          let-rowIndex="rowIndex"
          formArrayName="Permissions"
        >
          <tr
            style="height: 49px; position: relative"
            #body
            [formGroupName]="rowIndex"
            [ngClass]="rowIndex > 5 ? 'd-none' : ''"
          >
            <td class="ui-resizable-column" style="min-width: 318px">
              {{ Permissions.moduleName }}
            </td>
            <td class="ui-resizable-column text-center">
              <p-checkbox class="custom-p-checkbox" formControlName="view" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center">
              <p-checkbox class="custom-p-checkbox" formControlName="add" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center">
              <p-checkbox class="custom-p-checkbox" formControlName="edit" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center">
              <p-checkbox class="custom-p-checkbox" formControlName="delete" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center d-none">
              <p-checkbox class="custom-p-checkbox" formControlName="assign" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center d-none">
              <p-checkbox class="custom-p-checkbox" formControlName="sendMail" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center d-none">
              <p-checkbox class="custom-p-checkbox" formControlName="markCompleted" [binary]="true"></p-checkbox>
            </td>
            <td class="ui-resizable-column text-center d-none">
              <p-checkbox class="custom-p-checkbox" formControlName="reset" [binary]="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="role-button">
      <app-customizable-button 
        [showButton]="true" 
        [label]="'Cancel'" 
        [style]="'dark'"
        [elementId]="'cancelButton'" 
        (eventButton)="backHistory()">
      </app-customizable-button>
      <app-customizable-button 
        [showButton]="true" 
        [label]="'Save'" 
        [style]="'light'"
        [isDisabled]="!formEditRoleGroup.dirty || isViewer"
        [elementId]="'confirmButton'" 
        (eventButton)="saveRole(formEditRoleGroup, roleData, user)">
      </app-customizable-button>
    </div>
  </div>
</div>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
