import { Unit } from './../../shared/interface/unit.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  Subject,
  catchError,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  Observable,
  from,
  of,
  forkJoin,
  filter,
  BehaviorSubject,
  takeWhile,
  finalize,
} from 'rxjs';

import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertFormInitiateComponent } from './intervention-form-initiate/intervention-form-initiate.component';
import { AlertFormResolveComponent } from './intervention-form-resolve/intervention-form-resolve.component';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { MenuItem } from 'primeng/api';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TabView } from 'primeng/tabview';
import { UserInfoService } from './../../shared/services/user-info.service';
import jsPDF from 'jspdf';
import * as moment from 'moment-timezone';
import {
  ContactMethod,
  DataEdit,
  RigData,
  RigInterval,
  RigRun,
} from 'src/app/shared/interface/rig.interface';
import { RemoteCenter } from 'src/app/shared/models/rig.model';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import {
  AlertTemplate,
  DialogActionALert,
  EvidenceProcessType,
  OptionButtonAndIdType,
  OptionButtonType,
} from 'src/app/shared/type';
import { BackPortalService } from 'src/app/shared/services/back-portal.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PDFDocument, PDFPage } from 'pdf-lib';
import { PassingMailboxService } from 'src/app/shared/services/passingMailbox.service';
import { IConfirmDialog, IDropdownSelection } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { Menu } from 'primeng/menu';
import { ALERT_TYPE, DistributionTemplate, EVIDENCE_TYPE_PROCESS } from 'src/app/shared/enum';
import { LoaderLayOut } from 'src/app/shared/services/loaderLayOut.service';
import { TRANSLATION_SCOPES, TranslationService } from 'src/app/shared/services/translation.service';
import { uniq, cloneDeep, pick, orderBy, last } from 'lodash';
import { replaceWithDict } from 'src/app/shared/utilities/string-utils';
import { dataFields } from 'src/app/shared/components/export-setting/export-setting.data';
import { DistributionService } from 'src/app/shared/services/distribution.service';

// Unit Point (pt)
const A4_SIZE = { width: 595, height: 842 };
interface ImageEvidence {
  fileImage: File;
  text: string;
}

interface ImageEvidenceNew {
  fileImage: File;
  text: string;
}

interface IAuditTrail {
  action: string;
  timestamps: string;
  userDisplayName: string;
  userDiscipline: string;
  status: string;
}
@Component({
  selector: 'app-alert',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.scss'],
})
export class AlertComponent extends BaseComponent {
  isLoading: boolean = false;

  REGIONS = AppConstant.REGIONS;
  COUNTRIES = AppConstant.COUNTRIES;
  ALERT_STATUS: any = AppConstant.ALERT_STATUS;
  // AppConstant to using
  AppConstant = AppConstant;
  // Variable using in template
  rigData!: RigData;
  rigInterval!: RigInterval;
  rigRun!: RigRun;
  rigName: string = 'My Alert';
  rigJournalId = '';
  isEngineer: boolean = false;

  // MAILBOX
  mailboxs: any[] = [];
  selectedMailbox: any = null;
  // TEMPLATE
  templateList: IDropdownSelection[] = JSON.parse(JSON.stringify(AppConstant.ALERT_TEMPLATE));
  selectedTemplate: IDropdownSelection = this.templateList[0];
  isDisableTemplateDropdown: boolean = false;
  // LANGUAGE
  languageList: IDropdownSelection[] = JSON.parse(JSON.stringify(AppConstant.LANGUAGE_TEMPLATE));
  selectedLanguage: IDropdownSelection = this.languageList[0];
  originalFormData: any = {}
  isDisableLanguageDropdown: boolean = false;

  pdfSrc: any;
  formValuePDF: any;
  previewPDFPayload: any;
  alertDataEdit: any;
  checkDisableSaveBtn: any;
  currentUser: {} | any = {
    timezone: '',
  };
  myUnitSystem: any;

  isDataChanged: boolean = false;
  hasChangedInFormInitiate: boolean = false;
  hasChangedInFormResolve: boolean = false;

  // Timestamps
  localTimestamp_parent: any;
  rigTimestamp_parent: any;
  localTimestamp_parentEdit: any;
  localTimestamp_parentUpdateAt: any;
  localTimestamp_parentCreateAt: any;
  localTimestamp_parentSentAt: any;
  localTimestamp_parentSavedAt: any;
  rigTimestamp_parentEdit: any;

  devidentCalc: number = 0;
  remainingTime: number = 0;
  rigTimeZone: string = '';
  // Variable using for option of save, send, preview
  items!: MenuItem[];
  flagColor: any = [];

  //dataReady for load child component
  filtersLoaded: Subject<boolean> = new Subject();

  isHideResolveTab = true;
  dataReady: boolean = false;
  isSelectActiveResolveTab = false;
  // Variable for dialog

  A4_SIZE = A4_SIZE;

  showPDF: boolean = false;
  generatePDF: boolean = false;
  isDisabledButtonSave = false;
  isDisableResolve = false;
  isCreateResolve: boolean = false;
  isResolvedOnTime: boolean = false;
  isFormInitiateGroupChange: boolean = true;
  isCreateForm: boolean = false;

  pdfSrcName = '';
  DEFINED_UNIT: Unit = {
    Diameter: 'in',
    Depth: 'ft',
  };
  alertId = '';

  alertState = AppConstant.ALERT_STATE.I_0_0.key;

  // resolve contact method dropdown
  resolveContactMethod: ContactMethod[] = [];
  auditTrail: IAuditTrail[] = [];
  resolveDataEdit!: DataEdit;

  alertStatusTag = {
    name: '',
    classColor: 'yellow-tag',
    icon: '',
  };

  alertUserData = {
    userCreatedId: '',
    userCreatedName: '',
    userUpdatedId: '',
    userUpdatedName: '',
  };

  fileAlertDataEditor: File | null = null;
  isUseEditor: boolean = false;

  createdBy: any = null;
  savedBy: any = null;
  sentBy: any = null;

  disableSendfPdf: boolean = false;

  rigImageUrl = '';

  @ViewChild('formInitiate') formInitiate!: AlertFormInitiateComponent;
  @ViewChild('formResolve') formResolve!: AlertFormResolveComponent;
  @ViewChild('tabViewForm') tabViewForm!: TabView;

  private triggerFlow$ = new Subject<void>();
  createBuildForm$ = new Subject();
  editBuildForm$ = new Subject();
  resetFlagFlow$ = new Subject<string[]>();

  // Display back portal header form Alert Management
  backPortal: string = '';
  showMoreHeader: boolean = false;

  // ReadOnly Mode
  isReadOnly: boolean = false;

  userRelated: any[] = [];

  // Viewer Mode
  isViewer: boolean = false;

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  translatedData: any = {
    page: 'Page',
    of: 'of'
  }

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _interventionService: InterventionService,
    private _homeService: HomeService,
    private _activedRoute: ActivatedRoute,
    private _router: Router,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _blobService: AzureBlobService,
    private _backPortalService: BackPortalService,
    private _passingMailboxService: PassingMailboxService,
    private _confirmService: ConfirmDialogService,
    private _location: Location,
    private _collapseLayoutService: CollapseLayoutService,
    private _translationService: TranslationService,
    private _loaderLayOut: LoaderLayOut,
    private _distributionService: DistributionService,
    private _cdr: ChangeDetectorRef,
    @Optional() private _ref: DynamicDialogRef,
    @Optional() private _config: DynamicDialogConfig
  ) {
    super();
  }

  deviantTimezone(timeZone1: string, timeZone2: string): number {
    let tz1 = timeZone1.slice(0, 3);
    let tz2 = timeZone2.slice(0, 3);
    return Number(tz1) - Number(tz2);
  }

  timeCalc(localTimeZone: string, rigTimeZone: string) {
    let localTimestamp_currentTimeUser = moment(
      AppHelper.DateFunctions.getTimeUCT(localTimeZone.slice(0, 3))
    ).format('DD-MMM-YYYY, HH:mm');
    let rigTimestamp_currentRig = moment(
      AppHelper.DateFunctions.getTimeUCT(rigTimeZone.slice(0, 3))
    ).format('DD-MMM-YYYY, HH:mm');

    this.localTimestamp_parent = localTimestamp_currentTimeUser;
    this.rigTimestamp_parent = rigTimestamp_currentRig;
    this.devidentCalc = this.deviantTimezone(localTimeZone, rigTimeZone);
  }


  async onInit(): Promise<void> {
    // Default when access Alert Log will close Alert Log
    this._collapseLayoutService.updateCollapseLayout(false);

    // Check display backporal header
    this.backPortal = this._backPortalService.getHeaderDisplayValue();

    if (this._config?.data.isReadOnly) {
      this.isReadOnly = this._config?.data.isReadOnly;
      this.showMoreHeader = this._config?.data.showMoreHeader;
      this.isLoading = true;
    }

    // Pass data of mailbox selection
    const passingData = this._passingMailboxService.getPassingMailbox();
    if (passingData) {
      this.mailboxs = passingData.mailboxs;
      this.selectedMailbox = passingData.selectedMailbox
        ? passingData.selectedMailbox
        : passingData.mailboxs[0];

      this.templateList = JSON.parse(
        JSON.stringify(AppConstant.ALERT_TEMPLATE)
      );
      this.selectedTemplate =
        this.templateList?.find(
          (template: IDropdownSelection) =>
            passingData?.template?.id === template.id
        ) || this.templateList[0];

      this.languageList = JSON.parse(
        JSON.stringify(AppConstant.LANGUAGE_TEMPLATE)
      );
      this.selectedLanguage =
        this.languageList.find(
          (language: IDropdownSelection) =>
            passingData.language.id === language.id
        ) || this.languageList[0];
      this.trackOriginalFormData();

      await this.loadTranslatedData();
    }

    // initialize value of send, save, preview item
    this.triggerFlow$
      .pipe(
        switchMap(() =>
          this._userInfoService.userSubject$.pipe(
            filter((userObject: any) => !!userObject),
            switchMap((user: any) => {
              if (user && user.id && user.role) {
                this.currentUser = user;
                this.isViewer = user.role == AppConstant.ROLES.VIEWER.label;
                return this._activedRoute.paramMap.pipe(
                  map((params) => {
                    const rigJournalId = params.get('id') || this._config?.data.rigJournalIdParam || '';
                    const alertId = params.get('alertId') || this._config?.data.alertIdParam || '';
                    return [rigJournalId, alertId]
                  })
                );
              } else {
                // If user is not valid, return an empty observable
                return [];
              }
            })
          )
        ),
        takeWhile(([rigJournalId]) => rigJournalId !== this.rigJournalId),
        tap(() => {
          this._interventionService.setLoading(true);
        }),
        switchMap((res: any) => {
          const [rigJournalId, alertId] = res;
          const dataLog = this._interventionService.getInterventionLogs();
          const isDiffRigJournalId = dataLog.length ? dataLog?.some((item: any) => item.rigJournalId !== rigJournalId) : true;
          const isSkipGetLog = !isDiffRigJournalId || this.isReadOnly;
          let payloadFilterLog = this.addFilterRigJournalId(rigJournalId);
          this.rigJournalId = rigJournalId;

          if (this.rigJournalId)
            return forkJoin([
              this._homeService.getRigJournalbyId(this.rigJournalId),
              this._interventionService.GetUserListByRigJounalId(this.rigJournalId),
              isSkipGetLog
                ? of(null)
                : this._interventionService.getInterventionLogsFilter(payloadFilterLog, 0, AppConstant.ITEMS_PER_PAGE),
              of(alertId)
            ]);

          return of(null);
        }),
        switchMap((res: any) => {
          if (res) {
            const [rigAPI, userRelated, alertLogsAPI, alertId] = res;

            this.userRelated = userRelated?.data;
            this.selectedMailbox = this.selectedMailbox || null;
            this.flagColor = '';
            this.alertState = AppConstant.ALERT_STATE.I_0_0.key;

            let fileName = '';
            if (rigAPI?.data?.rig.imageUrl)
              fileName = decodeURI(
                AppHelper.StringFunctions.getFileName(rigAPI.data.rig.imageUrl)
              );

            return forkJoin([
              of(rigAPI),
              of(alertId),
              of(alertLogsAPI),
              this._userInfoService.checkPermissionWithRig(
                rigAPI?.message,
                rigAPI?.data
              ),
              fileName ? from(this._blobService.downloadBlob(fileName)) : of('')
            ]);
          }
          return of(null);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res: any) => {
          const [rigAPI, alertId, alertLogsAPI, permission, rigImage] = res;

          if (!permission || !rigAPI) {
            if (this._config?.data.isReadOnly) {
              this._ref.close();
              this._router.navigate(['alerts/alerts-management']);
            } else {
              this._confirmService.setDialog({
                ...this.confirmDialog,
                isVisible: true,
                header: 'Permission Alert',
                haveDialogMessage: true,
                dialogMessage: `Don't have permission. Back to the Home page`,
                havePrimaryButton: true,
                primaryButtonLabel: 'Confirm',
                isValidPrimaryButton: true,
                disablePrimaryButton: false,
                disableSecondaryButton: true,
                buttonEvent: (event: OptionButtonType) =>
                  this.onButtonClickDialog(event, 'Permission'),
              });
            }
            return;
          }

          if (rigImage) {
            this.rigImageUrl = window.URL.createObjectURL(rigImage);
          }

          if (alertLogsAPI) {
            // Set Intervention Log Filter
            // Set Intervention Log Filter - Chip and Focus
            this.showDataAlertLogs(alertLogsAPI);
            this.showFilterAlertLogs(this.rigJournalId, rigAPI.data.rig.rigName, alertId);
          }

          if (alertId) {
            this.isDisableTemplateDropdown = true;
            this.isDisableLanguageDropdown = true;
            this.buildFormAlertEdit(rigAPI, alertId);
          } else {
            this.isCreateForm = true;
            if (!passingData?.language) {
              this.selectedLanguage =
                this.languageList.find(
                  (language: IDropdownSelection) =>
                    rigAPI?.data?.language === language.id
                ) || this.languageList[0];

              this.loadTranslatedData();
            }
            this.buildFormCreateAlert(rigAPI, alertId);
          }

          this._interventionService.setLoading(false);
        },
        error: (err: any) => {
          this._router.navigate(['home']);
          this._collapseLayoutService.updateCollapseLayout(true);
        },
      });

    // Start the flow
    this.triggerFlow$.next();


    // Define Reset Flag Flow
    this.resetFlagFlow$
      .pipe(
        switchMap(([rigJournalId, alertId]) => {
          return forkJoin([
            this._homeService.getRigJournalbyId(rigJournalId),
            this._interventionService.getInterventionById(alertId),
          ]).pipe(
            finalize(() => this.isLoading = false)
          )
        })
      )
      .subscribe({
        next: (res: any) => {
          const [rigAPI, alertAPI] = res;
          ///
          const rigDistribution = rigAPI.distributionList;
          this.flagColor = rigDistribution;
          this.flagColor = this.flagColor.map((flag: any) => {
            if (flag.sheetName !== 'PRIMARY POINTS OF CONTACT')
              return {
                name: AppHelper.StringFunctions.capitalizeFirstLetter(
                  flag.sheetName.toLowerCase()
                ),
                code: flag.sheetName.toLowerCase(),
                purpose: flag.purpose,
              };
          });
          this.flagColor = this.flagColor.filter((flag: any) => {
            return flag !== undefined;
          });

          /////
          const primaryContactData = alertAPI?.primaryContact && alertAPI.primaryContact?.data;
          this.resolveContactMethod = primaryContactData || [];
        },
        error: (err: any) => {
          this._router.navigate(['home']);
          this._collapseLayoutService.updateCollapseLayout(true);
        },
      })

    // Emit Distribution
    this._distributionService.rigcardUpdate$.subscribe((updateRigRow: any) => {
      if (updateRigRow.rigJournalId) {
        this.loadData();
      }
    });
  }

  async buildFormAlertEdit(rigAPIData: any, alertIdConfig: string): Promise<any> {
    this.editBuildForm$
      .pipe(
        takeUntil(this.destroy$),
        tap((nextValue) => {
          this.filtersLoaded.next(false);
        }),
        switchMap(() => {
          return this._activedRoute.paramMap;
        }),
        map((params) => params.get('alertId') || alertIdConfig),
        takeWhile((alertId:any) => alertId !== this.alertId),
        switchMap((alertId: string) => {
          this.alertId = alertId;
          this.isUseEditor = false;
          this.fileAlertDataEditor = null;

          return forkJoin([
            of(rigAPIData),
            this._interventionService.getInterventionById(alertId),
           ]);
        }),
      )
      .subscribe({
        next: async (res: any) => {
          const [rigAPI, alertAPI] = res;

          this.filtersLoaded.next(true);

          if (rigAPI && alertAPI) {
            // Rig Data is already
            this.rigData = rigAPI.data;
            const rigData = rigAPI.data;
            const rigDistribution = rigAPI.distributionList;

            !rigDistribution.length && this.showWarningMissingDisibution();
            this.flagColor = rigDistribution;
            this.flagColor = this.flagColor.map((flag: any) => {
              if (flag.sheetName !== 'PRIMARY POINTS OF CONTACT')
                return {
                  name: AppHelper.StringFunctions.capitalizeFirstLetter(
                    flag.sheetName.toLowerCase()
                  ),
                  code: flag.sheetName.toLowerCase(),
                  purpose: flag.purpose,
                };
            });

            this.flagColor = this.flagColor.filter((flag: any) => {
              return flag !== undefined;
            });

            this.rigName = rigData.rig.rigName || 'Rig Demo';
          }

          // Alert Data is already
          const alertData = alertAPI.data;
          this.alertDataEdit = alertData;
          this.alertState = alertData?.stateCode;
          
          this.isUseEditor = alertData.isUseEditor;
          if (this.isUseEditor && alertData.alertEditorUrl) {
            this.downloadEditor(alertData.alertEditorUrl);
          }

          const primaryContactData = alertAPI.primaryContact && alertAPI.primaryContact.data;
          alertAPI.primaryContact.data
            ? alertAPI.primaryContact.data
            : [];

          // Set contact method in Resolve Tab
          this.resolveContactMethod = primaryContactData;
          this.resolveDataEdit = {
            contactMethod: alertData.contactMethod
              ? JSON.parse(alertData.contactMethod)
              : null,
            isNotificationOfResolutionRequired:
              alertData.isNotificationOfResolutionRequired,
            isRecommendedActionTaken:
              alertData.isRecommendedActionTaken,
            resolution: alertData.resolution,
            sendNotificationOfResolutionTotheSameDistribution:
              alertData.sendNotificationOfResolutionTotheSameDistribution,
            timeSaved: alertData.timeSaved,
            evidences: alertData.evidences,
          };

          // Set contact method in Initiate Tab
          if (alertData) {

            // Intevention Edit Header
            this.localTimestamp_parentEdit =
              AppHelper.DateFunctions.formatDateTime(
                new Date(Number(alertData.localTimestamps)),
                true
              );
            this.localTimestamp_parentUpdateAt =
              AppHelper.DateFunctions.formatDateTime(
                new Date(alertData.updatedAt),
                true
              );
            this.localTimestamp_parentCreateAt =
              AppHelper.DateFunctions.formatDateTime(
                new Date(alertData.createdAt),
                true
              );
            this.rigTimestamp_parentEdit =
              AppHelper.DateFunctions.formatDateTime(
                new Date(Number(alertData.rigTimestamps)),
                true
              );

            // ** AUDIT TRAIL **
            this.auditTrail = alertData.auditTrail.map((item: any) => {
              return {
                action: item.action,
                timestamps: AppHelper.DateFunctions.formatDateTime(
                  new Date(item.timestamps),
                  true
                ),
                userDisplayName: item.userDisplayName,
                userDiscipline: item?.userDiscipline,
                status: item.status,
              };
            });

            this.alertUserData.userCreatedName =
              alertData.createUserName;
            this.alertUserData.userCreatedId =
              alertData.createUserId;

            this.alertUserData.userUpdatedName =
              alertData.updateUserName ??
              alertData.createUserName;
            this.alertUserData.userUpdatedId =
              alertData.updateUserId ?? alertData.createUserId;

            const alertStatusCondition = {
              INITIATE_DRAFT:
                alertData.isRequiredFutherAction &&
                !alertData.hasSent &&
                this.isInitiate(alertData.status),
              INITIATE_NO_FURTHER:
                !alertData.isRequiredFutherAction &&
                alertData.hasSent &&
                this.isInitiate(alertData.status),
              INITIATE_SENT:
                alertData.isRequiredFutherAction &&
                alertData.hasSent &&
                this.isInitiate(alertData.status) &&
                alertData.timeExpire,
              RESOLUTION_DRAFT:
                alertData.isRequiredFutherAction &&
                !alertData.hasSent &&
                !this.isInitiate(alertData.status),
              RESOLUTION_SENT:
                alertData.isRequiredFutherAction &&
                alertData.hasSent &&
                !this.isInitiate(alertData.status),
              ALREADY_HAVE_RESOLUTION:
                alertData.isRequiredFutherAction &&
                alertData.hasSent &&
                alertData.timeExpire,
            };

            // Set Alert Status Tag - Header And Check Mark Completed by tool
            if (alertData.isComplete) {
              this.setStatusTagMaskAsComplete(true, alertData);

              this.isHideResolveTab = true; // need reset data after checking
              this.isSelectActiveResolveTab = false; // need reset data after checking
              this.isCreateResolve = false;
              this.isDisabledButtonSave = false;
            } else {
              this.setStatusTagMaskAsComplete(false, alertData);

              this.isHideResolveTab = true; // need reset data after checking
              this.isSelectActiveResolveTab = false; // need reset data after checking
              this.isCreateResolve = false;
              this.isDisabledButtonSave = false;
              if (alertStatusCondition['INITIATE_DRAFT']) {
                this.isHideResolveTab = true;
                this.isSelectActiveResolveTab = false;
              } else if (alertStatusCondition['INITIATE_NO_FURTHER']) {
                this.isHideResolveTab = true;
                this.isSelectActiveResolveTab = false;
              } else if (alertStatusCondition['RESOLUTION_DRAFT']) {
                this.isHideResolveTab = false;
                this.isCreateResolve = true;
                this.isSelectActiveResolveTab = true;
              } else if (alertStatusCondition['INITIATE_SENT']) {
                this.isHideResolveTab = false;
                this.isCreateResolve = true;
                this.isSelectActiveResolveTab = true;
              } else if (alertStatusCondition['RESOLUTION_SENT']) {
                this.isDisabledButtonSave = true;
                this.isHideResolveTab = false;
                this.isSelectActiveResolveTab = true;
                this.isCreateResolve = true;
              } else if (
                alertStatusCondition['ALREADY_HAVE_RESOLUTION']
              ) {
                this.isHideResolveTab = false;
              }
            }

            // TEMPLATE
            if (alertData?.eventType == ALERT_TYPE.ROADMAP) {
              this.selectedTemplate = this.templateList[1];
              this.A4_SIZE = { width: 842, height: 595 };
            } else {
              this.selectedTemplate = this.templateList[0];
              this.A4_SIZE = { width: 595, height: 842 };
            }

            // MAILBOX            
            this.verifyMailbox(rigAPI, this.alertId);

            // LANGUAGE
            this.selectedLanguage = this.languageList.find((item) => item.id == alertData.language) || this.languageList[0];
            await this.loadTranslatedData();

            // Done data to show tab and form
            this.isLoading = false;

            this.trackOriginalFormData();
          }

          this.checkDisableSaveBtn = {
            status: alertData.status,
            hasSent: alertData.hasSent,
            timeExpire: alertData.timeExpire,
          };
        },
        error: (err: any) => {
          this._router.navigate(['home']);
          this._collapseLayoutService.updateCollapseLayout(true);
        },
        complete: () => {
          this._interventionService.setLoading(false);
        }
      });
    this.editBuildForm$.next(rigAPIData);
  }

  downloadEditor(url: string) {
    const blod = this._blobService.getAlertEditorUrl(
      decodeURIComponent(AppHelper.StringFunctions.getFileName(url)));

    Promise.all([blod]).then((values: Blob[]) => {
      values.forEach((blob: Blob, _: number) => {
        const fullFileName = AppHelper.StringFunctions.getFileName(url).slice(14);
        this.fileAlertDataEditor = new File([blob], fullFileName, { type: 'application/json'})
      })
    }); 
  }

  showContextMenu(event: any) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem());
    this.menu.toggle(event);
  }

  getMenuTableItem(): any {
    const canSaveItem = !this.isViewer;

    if (!this.alertId) {
      return [
        {
          label: 'Save',
          disabled: !canSaveItem || !this.isDataChanged,
          command: () => {
            this.saveAlert();
          },
        },
        {
          label: 'Delete',
          styleClass: 'red-label',
          disabled: true,
        },
      ];
    } else {
      const canDeleteItem =
        (this.currentUser.role === AppConstant.ROLES.ADMIN.label ||
          this.currentUser.role === AppConstant.ROLES.MANAGER.label ||
          (this.currentUser.id === this.alertDataEdit.createUserId &&
            (this.alertDataEdit.stateCode === 'I_1_0' ||
              this.alertDataEdit.stateCode === 'I_0_0'))) &&
        !this.isViewer;

      return [
        {
          label: 'Save',
          disabled: !canSaveItem || !this.isDataChanged,
          command: () => {
            this.saveAlert();
          },
        },
        {
          label: 'Delete',
          styleClass: 'red-label',
          disabled: !canDeleteItem,
          command: () => {
            this.confirmRemove();
          },
        },
      ];
    }
  }

  setStatusTagMaskAsComplete(maskComplete: boolean, alertData: any) {
    if (maskComplete) {
      this.alertStatusTag.name = this.ALERT_STATUS.INITIATE;
      this.alertStatusTag.icon = 'unReadEnvelope';
      this.alertStatusTag.classColor = 'green-tag';
    }
    // Default status
    else {
      this.alertStatusTag.name = this.isInitiate(alertData.status)
        ? this.ALERT_STATUS.INITIATE
        : this.ALERT_STATUS.RESOLVE;

      this.alertStatusTag.icon = ['I_0_1', 'R_1_1', 'I_1_1'].includes(
        alertData.stateCode
      )
        ? 'unReadEnvelope'
        : 'readEnvelope';

      this.alertStatusTag.classColor = this.getColorOfAlertStatus(
        alertData.stateCode,
        alertData.timeExpire
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) { }
  getColorOfAlertStatus(stateCode: string, timeExpire: any) {
    if (['I_0_1', 'R_1_1'].includes(stateCode)) return 'green-tag';
    if (['I_0_0', 'I_1_0'].includes(stateCode)) return 'yellow-tag';
    const isRunOutTime =
      new Date(timeExpire).getTime() - new Date().getTime() <= 0;
    if (['I_1_1'].includes(stateCode))
      return isRunOutTime ? 'red-tag' : 'green-tag';
    if (['R_1_0'].includes(stateCode))
      return isRunOutTime ? 'red-tag' : 'yellow-tag';
    else return '';
  }

  buildFormCreateAlert(rigAPI: any, alertId: string = ''): void {
    this.createBuildForm$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (alertLogs: any) => {

          if (rigAPI) {
            this.rigInterval = rigAPI.interval;
            this.rigRun = rigAPI.run;
            this.rigData = rigAPI.data;
            this.rigJournalId = rigAPI.data.rigJournalId;

            this.flagColor = rigAPI.distributionList;
            this.flagColor = this.flagColor.map((flag: any) => {
              if (flag.sheetName !== 'PRIMARY POINTS OF CONTACT')
                return {
                  name: AppHelper.StringFunctions.capitalizeFirstLetter(
                    flag.sheetName.toLowerCase()
                  ),
                  code: flag.sheetName.toLowerCase(),
                  purpose: flag.purpose,
                };
            });

            this.flagColor = this.flagColor.filter((flag: any) => {
              return flag !== undefined;
            });

            this.rigName = this.rigData.rig.rigName || 'Rig Demo';

            // Set time to show in template
            if (this.rigData.project.timezone.slice(0, 3) === 'UTC') {
              this.rigTimeZone = this.rigData.project.timezone.slice(3);
            } else {
              this.rigTimeZone = this.rigData.project.timezone;
            }

            let currentTimeOfUser = new Date().toString().split(' ');
            let timeZoneNumberOfUsers =
              currentTimeOfUser[5]?.slice(3, 6) +
              ':' +
              currentTimeOfUser[5]?.slice(6, 8) || '-02:00';
            this.timeCalc(timeZoneNumberOfUsers, this.rigTimeZone);

            this.verifyMailbox(rigAPI, alertId);

            // // Setting data for log
            this._interventionService.setLoading(false);

            this._loaderLayOut.setLoadingStatus('InterventionListAPI', true);
            this._loaderLayOut.setLoadingStatus('userInfoAPI', true);

            this.filtersLoaded?.next(true);
          }
        },
        error: (err) => {
          if (err && err === 'There is no project item available!') {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Information',
              content: err,
            });
            this._router.navigate(['home']);
            this._collapseLayoutService.updateCollapseLayout(true);
          }
        },
      });

    this.createBuildForm$.next(null);
  }

  verifyMailbox(rigAPI: any, alertId: string = '') {
    if (rigAPI && !this.selectedMailbox && !this.isReadOnly) {
      // If have alertId but don't have data ==> exit fucntion
      if (alertId && !this.alertDataEdit) return;

      const userMailBox =
        this._userInfoService.userSubject.getValue().mailboxList;
      // Set mailbox dropdown
      this.mailboxs = rigAPI.data.mailboxList.filter((rigMail: any) =>
        userMailBox.some(
          (userMail: any) => rigMail.mailboxId === userMail.mailboxId
        )
      );

      this.mailboxs = this.mailboxs.map((mailbox: any) => {
        let nameEmail = mailbox.mailboxName.split('@').shift();
        return {
          isSelected: false,
          mailboxId: mailbox.mailboxId,
          mailboxName: mailbox.mailboxName,
          mailboxLocalPart: nameEmail ? nameEmail : mailbox.mailboxName,
        };
      });

      if (alertId) {
        this.isDisableLanguageDropdown = true
        this.isDisableTemplateDropdown = true;
      }
      else {
        this.isDisableLanguageDropdown = false
        this.isDisableTemplateDropdown = false;
      }

      if (this.mailboxs.length >= 2 && !this.selectedMailbox && !alertId) {
        this._confirmService.setDialog({
          ...this.confirmDialog,
          isVisible: true,
          header: 'Mailbox Selection',
          haveDialogMessage: true,
          dialogMessage:
            'Select the functional mailbox that will be used to send the alert.',
          havePrimaryButton: true,
          primaryButtonLabel: 'Continue',
          isValidPrimaryButton: false,
          haveBoxSelection: true,
          dataSelection: this.mailboxs,
          dialogMessageLanguage: 'Select the language of the report:',
          dataDropdownTemplate: this.templateList,
          defaultTemplate: this.selectedTemplate,
          dialogMessageTemplate: 'Select the template of the report',
          defaultLanguage: this.selectedLanguage,
          dataDropdownLanguage: this.languageList,
          isDisableDropdown: this.isDisableTemplateDropdown && this.isDisableLanguageDropdown,

          buttonEvent: (event: OptionButtonAndIdType) =>
            this.onButtonClickDialog(event, 'Selection'),
        });
      }
      this.selectedMailbox = this.mailboxs[0];
    }
  }

  // Submit Event
  onSubmitPreview() {
    if (this.isUseEditor && !(this.fileAlertDataEditor)) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Information',
        content: "Alert's Data still loading, wait a second",
      });
      return;
    }
    this.isLoading = true;
    setTimeout(() => {
      this.saveAndPreviewResolution();
    }, 0)
  }

  // Redirect Functional
  redirectTo(endPoint: string) {
    this._router.navigate([`${endPoint}`]);
  }

  error() {
    this._notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.WARNING,
      header: 'Information',
      content: 'Please fill the required information',
    });
  }

  saveResolve() {
    this._notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.SUCCESS,
      header: 'Save Alert',
      content: 'Alert was saved resolve successfully!',
    });
  }

  update() {
    this.tabViewForm.tabs[0]._selected
      ? this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.SUCCESS,
        header: 'Save Alert',
        content: 'Alert was successfully updated.',
      })
      : console.log();
  }

  showDialogDraft() {
    // In Read Only mode
    if (this._config?.data.isReadOnly) {
      this._ref.close();
    }
    // In Backportal case
    else if (this.backPortal) {
      this.redirectTo('/alerts/alerts-management');
    } else {
      if (this.isDataChanged) {
        this._confirmService.setDialog({
          ...this.confirmDialog,
          isVisible: true,
          header: 'Save the Alert',
          haveDialogMessage: true,
          dialogMessage:
            'You want to leave the page without saving, do you want to save it?',
          havePrimaryButton: true,
          primaryButtonLabel: 'Save',
          isValidPrimaryButton: true,
          disablePrimaryButton: false,
          haveSecondaryButton: true,
          secondaryButtonLabel: 'Leave the page',
          buttonEvent: (event: OptionButtonType) =>
            this.onButtonClickDialog(event, 'SaveDraftInitiate'),
        });
      } else {
          this.removeDraft();
      }
    }
  }

  saveAlert(initiate: any = null, resolution: any = null): boolean {
    this.rigJournalId = this.rigJournalId || this.alertDataEdit.rigJournalId;

    if (!initiate) {
      const conditionSave2Tab = this.getConditionSave2TabAlert();
      initiate = this.formInitiate.onSubmit();
      resolution = conditionSave2Tab ? this.formResolve.onSubmit() : null;
    }

    if (
      this.formInitiate.formInitiateGroup.pristine &&
      !this.formInitiate.formInitiateGroup.touched
    ) {
      this.isFormInitiateGroupChange = false;
    }

    if (initiate?.error || resolution?.error) {
      if (initiate?.error) {
        switch (initiate.error.type) {
          case 'INVALID':
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Cannot save',
              content:
                'All required information INITIATE Tab must be filled in.',
            });
            break;
          case 'WAITING_LOAD_DATA':
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Cannot save',
              content:
                'Please wait a few seconds, the evidence files from INITIATE are loading.',
            });
            break;
          case 'MISSING_DISTRIBUTION_DATA':
            this.showWarningMissingDisibution();
            break;
          default:
            break;
        }
      }

      // validate Resolve tab
      if (resolution && resolution?.error) {
        switch (resolution.error.type) {
          case 'INVALID':
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Cannot save',
              content:
                'All required information RESOLVE Tab must be filled in.',
            });
            break;
          case 'WAITING_LOAD_DATA':
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Cannot save',
              content:
                'Please wait a few seconds, the evidence files from RESOLVE are loading.',
            });
            break;
          case 'MISSING_DISTRIBUTION_DATA':
            this.showWarningMissingDisibution();
            break;
          default:
            break;
        }
      }
      this.isLoading = false;
      this.isFormInitiateGroupChange = true;
      return false;
    }

    this.isLoading = true;
    this.handleSaveAlert(initiate, resolution).subscribe(() => {
      this.isLoading = false;
    });
    return true;
  }

  saveAndPreviewResolution() {
    if (
      this.formInitiate.formInitiateGroup.pristine &&
      !this.formInitiate.formInitiateGroup.touched
    ) {
      this.isFormInitiateGroupChange = false;
    }
    const conditionSave2Tab = this.getConditionSave2TabAlert();
    const initiate = this.formInitiate.onSubmit();
    const resolution = conditionSave2Tab ? this.formResolve.onSubmit() : null;

    const isSave = this.saveAlert(initiate, resolution);
    if (!isSave) return;

    this.trackOriginalFormData();
    this.triggerPreviewPDF(
      initiate,
      resolution
    );
  }

  triggerPreviewPDF(
    initiate: any,
    resolution: any,
  ) {
    this.previewPDFPayload = this.makeDataTemplatePDF(
      initiate,
      resolution,
      this.rigImageUrl
    );
    this.showPreview(this.isCreateResolve);
  }

  getConditionSave2TabAlert(): boolean {
    switch (this.alertState) {
      case AppConstant.ALERT_STATE.R_1_0.key:
      case AppConstant.ALERT_STATE.R_1_1.key:
        return true;
      case AppConstant.ALERT_STATE.I_1_1.key:
        const formResolveGroupIsChange = !this.formResolve.formResolveGroup.pristine || this.formResolve.formResolveGroup.touched;
        const selectFurther = this.formInitiate.formInitiateGroup.get('furtherAction')?.value;
        if (selectFurther && selectFurther[0] === 'no')
          return false;
        if (formResolveGroupIsChange) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  /**
   * Make the single page of alert. The Unit use to make PDF is point (pt)
   * @param canvas
   * @param file
   * @returns
   */
  async makeSinglePagePDFEditor(
    template: HTMLCanvasElement,
    pageNumber: number = 1,
    totalPage: number = 1,
    listObj: any[] = [],
    listEvidence: File[] = [],
    format: "portrait" | "landscape" = 'portrait'
  ): Promise<{ arraybuffer: ArrayBuffer}> {
    let arraybuffer = null;
    const doc = new jsPDF(format, 'pt', 'a4');
    const imgWidth = this.A4_SIZE.width;
    const canvasHeight = (template.height * imgWidth) / template.width;

    // use jsPDF to emmbed Canvas
    doc.addImage(template, 'PNG', 0, 0, imgWidth, canvasHeight, '', 'FAST');

    // Draw page number
    if (this.selectedTemplate.id !== ALERT_TYPE.ROADMAP) {
      doc.setFontSize(8);
      doc.setTextColor('#818F99');
  
      doc.text(`${this.translatedData.page} ${pageNumber} ${this.translatedData.of} ${totalPage}`, 280, this.A4_SIZE.height - 10, {
        align: 'center',
        renderingMode: 'fill',
        lineHeightFactor: 14,
      });
    }

    const basePdf = doc.output('arraybuffer');
    // use PDFDocument to emmbed Image     
    const pdfNew = await PDFDocument.load(basePdf);
    const page = pdfNew.getPage(0);  

    for (let i = 0; i < listObj.length; i++) {
      const objData = listObj[i];
      const fileImage = listEvidence.find((f:File)=> f.name == objData?.name);
      if (fileImage) {
        // Get ArrayBuffer image evidence
        const imgBuffer = await this.fileToBase64(fileImage);
        let img = null;

        // Embbed to pdf
        fileImage.type === 'image/png'
          ? (img = await pdfNew.embedPng(imgBuffer))
          : (img = await pdfNew.embedJpg(imgBuffer));

        // DRAW Imgae
        page.drawImage(img, {
          x: objData.left,
          y: this.A4_SIZE.height - objData.top - objData.height * objData.scaleY,
          width: objData.width * objData.scaleX,
          height: objData.height * objData.scaleY,
        });
      }
    }

    arraybuffer = (await pdfNew.save()).buffer;
    return { arraybuffer }
  }

  async makingPDF(
    canvas: any,
    infoHeader: any,
    isCreateResolve: any,
    initiate: any,
    resolution: any,
  ): Promise<ArrayBuffer> {
    let dataBuffer: ArrayBuffer = new ArrayBuffer(0);
    const imageEvidence = await this.getFileEvidence(isCreateResolve, initiate, resolution); 
      this.A4_SIZE = { width: 595, height: 842 };      

      if (imageEvidence.length > 0) {
        let numberPage = { total: imageEvidence.length, page: 1 };
        for (let i = 0; i < imageEvidence.length; i++) {
          const template = numberPage.page == 1 ? canvas : infoHeader;
          const pdfResponse = await this.makeSinglePagePDF(
            template,
            numberPage.page,
            numberPage.total,
            imageEvidence[i]
          );

          if (pdfResponse.isMoveImg) {
            i--;
            numberPage.total += 1;
          }
          numberPage.page += 1;
          // After handle PDF => merge page to Root PDF
          dataBuffer = await this.mergePdf2Pdf(
            dataBuffer,
            pdfResponse.arraybuffer
          );
        }
      } else {
        const data = await this.makeSinglePagePDF(canvas);
        dataBuffer = data.arraybuffer;
      }

    return dataBuffer;
  }

  async makingPDFEditor(
    template: any,
    headerInfo: any,
    listCanvas: any,
    imageEvidences: File[],
    format: "portrait" | "landscape" = 'portrait'
  ) {
    const listPage: any[] = [];
    let dataBuffer: ArrayBuffer = new ArrayBuffer(0);

    for (let i = 0; i < listCanvas.length; i++) {
      const listObject = listCanvas[i].objects.filter((obj: any) => obj?.name !== 'base');
      const page = await this.makeSinglePagePDFEditor(
                    i == 0 ? template : headerInfo,
                    i + 1,
                    listCanvas.length,
                    listObject,
                    imageEvidences,
                    format
                  );
      listPage.push(page);
      dataBuffer = await this.mergePdf2Pdf(
        dataBuffer,
        page.arraybuffer
      );
    }

    return dataBuffer;
  }

  async handleReportPDFEditor({
    template,
    headerInfo = null,
    listCanvas
  }: any): Promise<any> {
    this.isLoading = true;

    // let initiate = null;
    // let resolution = null;
    // const conditionSave2Tab = this.getConditionSave2TabAlert();
    // initiate = this.formInitiate.onSubmit();
    // resolution = conditionSave2Tab == true ? this.formResolve.onSubmit() : null;

    const event = this.formInitiate.formInitiateGroup.get('event')?.value as any;
    const inititateEvidence = this.formInitiate?.uploadedFiles || [];
    const resolutionEvidence = this.formResolve?.uploadedFiles || [];

    const type = event.eventType as any;
    let fileName = `${
      this.rigName
    }_Alert ${AppHelper.StringFunctions.capitalizeFirstLetter(type)}.pdf`;
    let dataBuffer: ArrayBuffer = new ArrayBuffer(0);
    
    const concatArray = (...arrays: any) => [].concat(...arrays.filter(Array.isArray));
    const imageEvidences = concatArray(inititateEvidence, resolutionEvidence)
                            .filter((f: File) => f.type.includes('image'));

    // Handle PDF for Roadmap type
    if (this.selectedTemplate.id === ALERT_TYPE.ROADMAP) {
      fileName = `${ this.rigName}_Alert Roadmap.pdf`;
      dataBuffer = await this.makingPDFEditor(
        template,
        headerInfo,
        listCanvas,
        imageEvidences,
        "landscape"
      );
    } else {
      dataBuffer = await this.makingPDFEditor(
        template,
        headerInfo,
        listCanvas,
        imageEvidences,
        "portrait"
      );
    }

    this.pdfSrcName = fileName;
    this.pdfSrc = new Uint8Array(dataBuffer as ArrayBuffer);
    this.isLoading = false;
  }

  async handleReportPDF([reportPDFPromise, isCreateResolve]: any): Promise<any> {
    const [canvas, infoHeader] = reportPDFPromise;
    let initiate = null;
    let resolution = null;
    const conditionSave2Tab = this.getConditionSave2TabAlert();
    initiate = this.formInitiate.onSubmit();
    resolution = conditionSave2Tab == true ? this.formResolve.onSubmit() : null;

    const type = initiate.event.eventType;
    let fileName = `${
      this.rigName
    }_Alert ${AppHelper.StringFunctions.capitalizeFirstLetter(type)}.pdf`;
    let dataBuffer: ArrayBuffer = new ArrayBuffer(0);
    
    // Handle PDF for Roadmap type
    if (this.selectedTemplate.id === ALERT_TYPE.ROADMAP) {
      fileName = `${ this.rigName}_Alert Roadmap.pdf`;
      this.A4_SIZE = { width: 842, height: 595 };      
      const doc = new jsPDF('l', 'pt', 'a4');
      doc.addImage(
        canvas,
        'PNG',
        0,
        0,
        this.A4_SIZE.width,
        this.A4_SIZE.height,
        '',
        'FAST'
      );
      dataBuffer = doc.output('arraybuffer'); // Generated PDF
    } else {
      dataBuffer = await this.makingPDF(canvas, infoHeader, isCreateResolve, initiate, resolution)
    }

    this.pdfSrcName = fileName;
    this.pdfSrc = new Uint8Array(dataBuffer as ArrayBuffer);
    this.isLoading = false;
  }

  /**
   * Get file PDF and Image evidence from data
   * @param isCreateResolve 
   * @param initiate 
   * @param resolution 
   * @returns 
   */
  private async getFileEvidence(
    isCreateResolve: boolean,
    initiate: any,
    resolution: any)
    : Promise<ImageEvidenceNew[]> {
    let imageEvidence: ImageEvidenceNew[] = [];

    // get File image evidence of Resolve tab
    if (isCreateResolve) {
      for (let file of resolution?.evidence) {
        if (file.type !== 'application/pdf') {
          const fileEvidence: ImageEvidenceNew = {
            fileImage: file,
            text: this._translationService.translate('Resolution Evidence', this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
          };
          imageEvidence.push(fileEvidence);
        }
      }
    }
    // get File pdf evidence of Initiate tab
    for (let file of initiate?.evidence) {
      if (file.type !== 'application/pdf') {
        const fileEvidence: ImageEvidenceNew = {
          fileImage: file,
          text: this._translationService.translate('Original Evidence', this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
        };
        imageEvidence.push(fileEvidence);
      }
    }

    return imageEvidence;
  }

  /**
   * Make the single page of alert. The Unit use to make PDF is point (pt)
   * @param canvas
   * @param file
   * @returns
   */
  async makeSinglePagePDF(
    canvas: HTMLCanvasElement,
    pageNumber: number = 1,
    totalPage: number = 1,
    file?: ImageEvidenceNew | null
  ): Promise<{ arraybuffer: ArrayBuffer; isMoveImg?: boolean }> {
    let arraybuffer = null;
    const doc = new jsPDF('p', 'pt', 'a4');
    const imgWidth = this.A4_SIZE.width;
    const canvasHeight = (canvas.height * imgWidth) / canvas.width;

    // Add Canvas to top.
    doc.addImage(canvas, 'PNG', 0, 0, imgWidth, canvasHeight, '', 'FAST');


    // Draw text for evidence.
    const HEIGHT_TEXT = 30;
    const textPosition = {
      x: 20,
      y: canvasHeight + HEIGHT_TEXT,
    };
    const ratio = 0.8;

    // HEIGHT_TEXT for space of text Ecidence. 20 for text number page
    const spaceLeftOver = {
      width: this.A4_SIZE.width,
      height: this.A4_SIZE.height - canvasHeight - HEIGHT_TEXT - 20,
    };

    // Draw page number
    doc.setFontSize(8);
    doc.setTextColor('#818F99');

    // page is not enought space for image ==> mode the image to next page
    if (spaceLeftOver.height < 200) {
      doc.text(
        `${this.translatedData.page} ${pageNumber} ${this.translatedData.of} ${totalPage + 1}`,
        280,
        this.A4_SIZE.height - 10,
        {
          align: 'center',
          renderingMode: 'fill',
          lineHeightFactor: 14,
        }
      );
      arraybuffer = doc.output('arraybuffer');

      return { arraybuffer, isMoveImg: true };
    }

    // Draw page number
    doc.text(`${this.translatedData.page} ${pageNumber} ${this.translatedData.of} ${totalPage}`, 280, this.A4_SIZE.height - 10, {
      align: 'center',
      renderingMode: 'fill',
      lineHeightFactor: 14,
    });

    if (file) {
      const { fileImage, text } = file;

      // draw text evidence 
      doc.setFontSize(12);
      doc.setFont('Helvetica');
      doc.setTextColor('#000000');
      // doc.text(text, textPosition.x, textPosition.y, {
      //   align: 'left',
      //   renderingMode: 'fill',
      //   lineHeightFactor: 14,
      // });

      const basePdf = doc.output('arraybuffer');
      const pdfNew = await PDFDocument.load(basePdf);
      const page = pdfNew.getPage(0);

      // Get ArrayBuffer image evidence
      // const imgBuffer = await AppHelper.UtileFunctions.fileToArrayBuffer(fileImage);
      const imgBuffer = await this.fileToBase64(fileImage);
      let img = null;

      // Embbed to pdf
      fileImage.type === 'image/png'
        ? (img = await pdfNew.embedPng(imgBuffer))
        : (img = await pdfNew.embedJpg(imgBuffer));
      let imageSize = {
        height: img.height * ratio,
        width: img.width * ratio,
      };

      // Calculating size for Image
      if (img.height >= spaceLeftOver.height || img.width >= spaceLeftOver.width) {
        imageSize = img.scaleToFit(
          spaceLeftOver.width * ratio,
          spaceLeftOver.height * ratio
        );
      } else if (img.height < 50 || img.width < 50) {
        imageSize = img.scaleToFit(
          spaceLeftOver.width * 0.2,
          spaceLeftOver.height * 0.2
        );
      }

      const postionImage = {
        x: (spaceLeftOver.width - imageSize.width) / 2,
        y: (spaceLeftOver.height - imageSize.height + HEIGHT_TEXT) / 2
      };
      // DRAW Imgae
      page.drawImage(img, {
        x: postionImage.x,
        y: postionImage.y,
        width: imageSize.width,
        height: imageSize.height,
      });
      arraybuffer = (await pdfNew.save()).buffer;
      return { arraybuffer };
    } else {
      return { arraybuffer: doc.output('arraybuffer') }
    }
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
   * Function handle merge list PDFs into the PDF is available
   * @param pdfRootBuffer PDF is available (type ArrayBuffer)
   * @param listPDFs list PDFs want to merged (type ArrayBuffer[])
   * @returns pdfRootBuffer after merged
   */
  async mergeListPdf2Pdf(
    pdfRootBuffer: ArrayBuffer,
    listPDFs: File[]
  ): Promise<ArrayBuffer> {
    const rootDocument = await PDFDocument.load(pdfRootBuffer);

    for (let index = 0; index < listPDFs?.length; index++) {
      const filePdf = listPDFs[index];

      try {
        const buffer = await AppHelper.UtileFunctions.fileToArrayBuffer(filePdf);
        const newPDF = await PDFDocument.load(buffer as ArrayBuffer);
        const copiedPages: PDFPage[] = await rootDocument.copyPages(
          newPDF,
          newPDF.getPageIndices()
        );

        copiedPages.forEach((page: PDFPage) => {
          if (page.getWidth() > this.A4_SIZE.width) {
            page.scale(this.A4_SIZE.width / page.getWidth(), 1);
          }

          page.setWidth(this.A4_SIZE.width);
          rootDocument.addPage(page);
        });
      } catch (e) {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file type',
          content: `Having problems with PDF file ${filePdf.name}.`,
        });
        listPDFs.splice(index--, 1);
        this.formInitiate.removeItemUploadWithName(filePdf.name);
      }
    }

    let mergeFile: Uint8Array = await rootDocument.save();
    return mergeFile.buffer;
  }

  async mergePdf2Pdf(
    pdf1: ArrayBuffer,
    pdf2: ArrayBuffer
  ): Promise<ArrayBuffer> {
    if (!pdf1.byteLength) return pdf2;

    const rootDocument = await PDFDocument.load(pdf1);
    const newPDF = await PDFDocument.load(pdf2);
    const copiedPages: PDFPage[] = await rootDocument.copyPages(
      newPDF,
      newPDF.getPageIndices()
    );
    copiedPages.forEach((page: PDFPage) => {
      if (page.getWidth() > this.A4_SIZE.width) {
        page.scale(this.A4_SIZE.width / page.getWidth(), 1);
      }
      page.setWidth(this.A4_SIZE.width);
      rootDocument.addPage(page);
    });

    const mergeFile: Uint8Array = await rootDocument.save();
    return mergeFile.buffer;
  }
  
  updateFlagUseEditor(value: any) {
    this.isUseEditor = value;
  }

  triggerSaveAlert(data: {isSend: boolean, alertEditorData: File | null} ) {   
    const { isSend, alertEditorData } = data;
    const conditionSave2Tab = this.getConditionSave2TabAlert();
    let initiate = null;
    let resolution = null;
    initiate = this.formInitiate.onSubmit();
    resolution = conditionSave2Tab == true ? this.formResolve.onSubmit() : null;

    // get file PDF to save
    const uploadFile = new File([this.pdfSrc], this.pdfSrcName, {
      type: 'application/pdf',
    });
    
    this.fileAlertDataEditor = alertEditorData;
    
    this.isLoading = true;
    const payloadFile = {
      isUseEditor: this.isUseEditor,
      intervention: uploadFile,
      alertEditor: alertEditorData
    };
    this._interventionService.addFilePDFAlert(payloadFile, this.alertId).pipe(
      tap(() => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: 'Save Alert PDF',
          content: 'Save PDF successfully.',
        });
      }),
      map(() => {
        if (!isSend) return of();

        return this.sendPDF(this.alertId,
          !this.isCreateResolve,
          this.isResolvedOnTime,
          this.selectedMailbox?.mailboxId || ''
        )
      })
    ).subscribe({
      next: () => {},
      error: () => {  },
      complete: () => {
        this.isLoading = false;
        this.disableSendfPdf = false;
        this.isDisableTemplateDropdown = true;
        this.isDisableLanguageDropdown = true;
        this.showPDF = false;
      }
    });
  }

  sendPDF(
    alertId: string,
    isInitiate: boolean,
    isResolvedOnTime: boolean,
    mailboxId: string
  ): void {
    if (this.disableSendfPdf) return;
    
    this.isLoading = true;

    let currentTimeOfUser = new Date().toString().split(' ');
    let timeZoneNumberOfUsers =
      currentTimeOfUser[5]?.slice(3, 6) +
        ':' +
        currentTimeOfUser[5]?.slice(6, 8) || '-02:00';

    let sendTime =
      timeZoneNumberOfUsers.slice(0, 1) +
      Number(timeZoneNumberOfUsers.slice(1, 3)) * 360000 +
      Number(timeZoneNumberOfUsers.slice(4, 6)) * 60000;

    const emailTemplateData = {
      regards: this._translationService.translate("Regards,", this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
      event: last(this.translatedData?.data?.event),
      alert: this._translationService.translate("ALERT", this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
      flagsDict: this._translationService.getScopeDict(this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.FLAGS }),
      monthsDict: this._translationService.getScopeDict(this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.MONTHS }),
      resolution: this._translationService.translate("RESOLUTION", this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
      report: this._translationService.translate("REPORT", this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
      alertTypeTemplate: this._translationService.translate("Alert_Type_Template", this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }) || 'Color Alert',
    }

    this._interventionService
      .sendIntervention(
        alertId,
        Number(sendTime) || 0,
        isInitiate,
        isResolvedOnTime,
        mailboxId,
        emailTemplateData
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (response) => {
          console.info(response);
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Send Alert',
            content: 'Alert was successfully sent.',
          });

          // All handlers for back to home keeping Filter
          // this._router.navigate(['/home'], { queryParams: { view: 'card' } })
          // this._collapseLayoutService.updateCollapseLayout(true);
        },
        error: () => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Send Alert',
            content: 'Alert was failed sent.',
          });
        },
      });
  }

  handleSaveAlert(
    initiate: any,
    resolve: any = null,
  ): Observable<any> {
    const payload: any = this.preparePayload(initiate, resolve);
    const payloadFile = {
      evidencesInitiate: initiate.evidence,
      evidencesResolve: resolve?.evidence || [],
    };
  
    this.isLoading = true;
    if (this.alertId) {
      return this._interventionService
        .updateInterventionV2(payload, this.alertId)
        .pipe(
          switchMap((alert: any) => {
            this.alertId = alert.data.interventionId; // add for send mail
            return this._interventionService.addFileEvidenceAlert(
              payloadFile,
              this.alertId,
              this.isCreateResolve
            );
          }),
          tap({
            next: (response: any) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Update Alert',
                content: 'Alert was successfully updated.',
              });
            },
            error: (error) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Update Alert',
                content: error?.message || error || '',
              });
              this.isLoading = false;
            },
          })
        );
    } else {
      return this._interventionService
        .saveIntervention(payload, this.rigJournalId)
        .pipe(
          catchError(AppHelper.UtileFunctions.handleError),
          switchMap((alert: any) => {
            this.alertId = alert.data.interventionId; // add for send mail
            this.alertDataEdit = alert?.data;
            this.isDisableTemplateDropdown = true;
            return this._interventionService.addFileEvidenceAlert(
              payloadFile,
              alert.data.interventionId,
              this.isCreateResolve
            );
          }),
          tap({
            next: (response: any) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Create Alert',
                content: 'Alert was successfully created.',
              });
              this._location.replaceState(
                `/home/rig/${this.rigJournalId}/alert/${this.alertId}`
              );
            },
            error: (error) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Create Alert',
                content: error?.message || error || '',
              });
              this.isLoading = false;
            },
          })
        );
    }
  }

  /**
   * Prepare payload data for API
   * @param initiate 
   * @param resolution 
   * @returns 
   */
  private preparePayload(initiate: any, resolution: any = null) {

    let operationSelected: any;
    if (initiate.selectedOperation[0] === 'A') {
      operationSelected = {
        operation_id: initiate.operation.operation_id,
        data: initiate.operation.data,
        label: initiate.operation.label,
      };
    }

    const selectedEvent = {
      event_id: initiate.event.event_id,
      data: initiate.event.data,
      label: initiate.event.label,
      eventType: initiate.event.eventType,
      hierarchy: initiate.eventString,
    };

    let selectedRisk: any;
    if (initiate.selectedRisk[0] === 'A') {
      selectedRisk = initiate.risk.map((risk: any) => {
        return {
          risk_id: risk.risk_id,
          data: risk.data,
          label: risk.label,
        };
      });
    }

    const isOverrideTime = initiate.localTimestamps
      ? initiate.isOverrideTime
      : false;

    let payload = {
      // initiate
      isOverrideTime: isOverrideTime,
      localTimestamps: Date.parse(
        isOverrideTime
          ? initiate.localTimestamps
          : initiate.autoLocalTimestamp
      ),
      rigTimestamps: Date.parse(
        isOverrideTime
          ? initiate.rigTimestamps
          : initiate.autoRigTimestamp
      ),
      holeDiameter:
        initiate.holeDiameter?.toString().replace(/,/g, '') || '',
      effectiveDiameter: initiate.effectiveDiameter,
      interval:
        initiate.interval.id === 'na' ? null : initiate.interval,
      run: initiate.run.id === 'na' ? null : initiate.run,
      bitMD: initiate.bitMD,
      holeMD: initiate.holeMD,
      isOverrideNumber: initiate.isOverrideRunNumber,
      runNumber: initiate.isOverrideRunNumber
        ? initiate.customRunNumber
        : initiate.runNumber,
      isCustomOperation:
        initiate.selectedOperation[0] === 'A' ? false : true,
      currentOperation:
        initiate.selectedOperation[0] === 'A'
          ? JSON.stringify(operationSelected)
          : initiate.anotherOperation,
      event: selectedEvent,
      eventDescription: initiate.eventDescription,
      flag: initiate.flag.code,
      recommendation: initiate.recommendation,
      isCustomRisk: initiate.selectedRisk[0] === 'A' ? false : true,
      risk:
        initiate.selectedRisk[0] === 'A'
          ? JSON.stringify(selectedRisk)
          : initiate.anotherRisk,
      evidences: null,
      isRequiredRigBasedValidation:
        initiate.validationDetailCheck[0] === 'yes' ? true : false,
      rigBasedValidationDetail: initiate.validationDetail,
      isRequiredFutherAction:
        initiate.furtherAction[0] === 'yes' ? true : false,
      userId: this.currentUser.id,
      unit: this.rigData.project.curUnit,
      eventType: this.selectedTemplate.id === ALERT_TYPE.ROADMAP ? ALERT_TYPE.ROADMAP : initiate.event.eventType,
      language: this.selectedLanguage.id,
      isUseEditor: this.isUseEditor
    };

    if (resolution) {
      const contactMethod: any[] = ([] = resolution.contactMethod.map(
        (contact: any) => {
          if (contact?.contact_id)
            return {
              contact_id: contact.contact_id || contact?.id,
              label: contact.label,
              data: contact.data,
            };
          else {
            return {
              id: contact?.id,
              label: contact.label,
              data: contact.data,
            };
          }
        }
      ));
      payload = {
        ...payload, ...{
          contactMethod: contactMethod || null,
          isRecommendedActionTaken: resolution.recommendedActions[0] || null,
          resolution: resolution.resolution || null,
          timeSaved: resolution.timeSaved || null,
          isNotificationOfResolutionRequired:
            resolution.notificationResolution[0] === 'yes' ? true : false,
          sendNotificationOfResolutionTotheSameDistribution:
            resolution.selectedWhereSendNotification[0] === 'yes_sendNotification'
              ? ''
              : resolution.anotherWhereSendNotification?.code,
        }
      }
    }
    return payload;
  }

  getUserInit() {
    let response = null;
    for (let index = this.auditTrail.length - 1; index >= 0; index--) {
      const item = this.auditTrail[index];
      if (item.action === 'Sent' && item.status === "Initiate (Completed)") {
        response = item;
        break;
      }
    }

    return response;
  }

  makeDataTemplatePDF(
    rawDataInitiate: any,
    rawDataResolution?: any,
    rigImageUrl?: string
  ) {
    let defaultRigImageUrl;
    let infoSendInitiate = null;
    const concat = (...arrays: any) => [].concat(...arrays.filter(Array.isArray));
    const imageEvidences = concat(rawDataInitiate?.evidence, rawDataResolution?.evidence)
                            .filter((f: File) => f.type.includes('image'));

    const imgEvidence: File[] = (rawDataInitiate?.evidence
      ?.filter((f:File) => f.type !== 'application/pdf') || [])
      ?.map((f: File) => {return URL.createObjectURL(f)})
      .slice(0, 2);

    if (rawDataResolution) {
      infoSendInitiate = this.getUserInit();
    }

    if (this.rigData.rig.rigTypeName) {
      const decodeRigType = JSON.parse(atob(this.rigData.rig.rigTypeName));
      defaultRigImageUrl = decodeRigType.rigDefaultImage
        ? `../../../../assets/images/default-rigs/${encodeURIComponent(
          decodeRigType.rigDefaultImage
        )}`
        : `../../../../assets/images/rigs/rig01.png`;
    }
    const region =
      this.REGIONS.find(
        (region) => region.code === this.rigData.project.region
      ) || this.REGIONS[0];

    const country =
      this.COUNTRIES.find(
        (country) => country.code === this.rigData.project.country
      ) || this.COUNTRIES[0];

    const riskString =
      rawDataInitiate.selectedRisk[0] === 'A'
        ? rawDataInitiate.risk.map((el: any) => this._translationService.translate(el.label, this.selectedLanguage?.id, { scope: TRANSLATION_SCOPES.RISKS })).join(', ')
        : rawDataInitiate.anotherRisk;

    let remoteCenter: string = '';
    if (this.rigData.remoteCenterList.length) {
      let array = this.rigData.remoteCenterList;
      for (let index = 0; index < array.length; index++) {
        const element: RemoteCenter = array[index];
        remoteCenter
          ? (remoteCenter += `, ${element.remoteCenterName}`)
          : (remoteCenter += element.remoteCenterName);
      }
    }

    const eventType = AppHelper.UtileFunctions.getTypeAlert(
      rawDataInitiate.event
    );

    let primaryFlag;
    let contactListString;
    let recommendedActions;
    let timeSaved;
    let resolution;

    // Template for Resolve
    if (rawDataResolution) {
      const translationDictForContact = pick(this._translationService.getScopeDict(this.selectedLanguage.id, { scope: TRANSLATION_SCOPES.DATA_FIELDS }), ['Phone', 'Email', 'Chat'])
      let contactListStringWithoutPhone = rawDataResolution.contactMethod
        .filter((contact: any) => !contact?.contact_id && contact.id !== 'phone')
        .map((contact: any) => {
          return contact?.styleClass === "parent_" ? replaceWithDict(contact.label, translationDictForContact) : contact.label;
        })
        .join(', ');

      contactListString = contactListStringWithoutPhone;
      const phoneElements = orderBy(
        rawDataResolution.contactMethod
          ?.filter((contact: any) => contact.contact_id)
          .map((contact: any) => contact.label),
        ['label'],
        ['asc']
      );
      if (phoneElements.length > 0) {
        const strPhone = `${replaceWithDict('Phone', translationDictForContact)} (${phoneElements.join(', ')})`;
        contactListString = [strPhone, contactListStringWithoutPhone].join(', ');
      }

      recommendedActions = '';
      switch (rawDataResolution.recommendedActions[0]) {
        case AppConstant.OPTION_BUTTON.YES:
          recommendedActions = 'Yes';
          break;
        case AppConstant.OPTION_BUTTON.NO:
          recommendedActions = 'No';
          break;
        default:
          recommendedActions = 'N/A';
          break;
      }

      timeSaved = rawDataResolution.timeSaved;
      resolution = rawDataResolution.resolution;
    }

    if (rawDataResolution) {
      primaryFlag =
        rawDataResolution.selectedWhereSendNotification[0] !==
          'yes_sendNotification'
          ? rawDataResolution.anotherWhereSendNotification.code
          : rawDataInitiate.flag.code;
    } else {
      primaryFlag = rawDataInitiate.flag.code;
    }

    const isOverrideTime = rawDataInitiate.localTimestamps
      ? rawDataInitiate.isOverrideTime
      : false;

    const rigTimestamps: string = isOverrideTime
      ? this.alertId
        ? rawDataInitiate.rigTimestamps
        : AppHelper.DateFunctions.formatDateTime(
          new Date(rawDataInitiate.rigTimestamps),
          true,
          this.translatedData?.monthsDict
        )
      : rawDataInitiate.autoRigTimestamp;

    this.DEFINED_UNIT = rawDataInitiate.unit;
    
    let payloadForTemplate: any = {
      rigImageUrl: rigImageUrl ? rigImageUrl : defaultRigImageUrl,
      region: `${region.name}`,
      rig: this.rigData.rig.rigName || 'Rig Demo',
      country: `${country.name} (${country.code})`,
      spudDate:
        this.rigData.wellbore.spudDate || this.rigData.wellbore.EDMspudDate
          ? AppHelper.DateFunctions.formatDateTime(
            new Date(
              this.rigData.wellbore.spudDate ??
              this.rigData.wellbore.EDMspudDate
            ),
            true,
            this.translatedData?.monthsDict
          ).split(',')[0]
          : '',
      operator:
        this.rigData.policy.policyCurrentName ||
        this.rigData.policy.policyOriginalName,
      well:
        this.rigData.well.wellCurrentName || this.rigData.well.wellOriginalName,
      project:
        this.rigData.project.projectCurrentName ||
        this.rigData.project.projectOriginalName,
      wellbore:
        this.rigData.wellbore.wellboreCurrentName ||
        this.rigData.wellbore.wellboreOriginalName,
      block: this.rigData.project.block,
      uwi:
        this.rigData.wellbore.uwiCurrentName ||
        this.rigData.wellbore.uwiOriginalName ||
        '',
      lease: this.rigData.project.lease,
      // interval: this.rigData.interval ? this.rigData.interval.intervalName : '',
      field: this.rigData.project.field,
      remoteCenter,
      contactMethod: contactListString || 'Unknown Contact',
      recommendationFollowed: recommendedActions || 'Unknown Action',
      timeSaved: timeSaved || '0.0',
      resolutionDetail: resolution || 'Unknown Resolution',
      alertEvent: AppHelper.UtileFunctions.replaceAllCharacter(
        rawDataInitiate.eventString,
        '/',
        ' | '
      ),
      alertFlag: primaryFlag,
      currentOperation:
        rawDataInitiate.selectedOperation[0] === 'A'
          ? rawDataInitiate.operation.data
          : rawDataInitiate.anotherOperation,
      holeDiameter: rawDataInitiate.holeDiameter,
      effectiveDiameter: rawDataInitiate.effectiveDiameter,
      runNumber: rawDataInitiate.isOverrideRunNumber
        ? rawDataInitiate.customRunNumber
        : rawDataInitiate.runNumber,
      depth: `${rawDataInitiate.bitMD} ${this.DEFINED_UNIT.Depth} (string) / ${rawDataInitiate.holeMD} ${this.DEFINED_UNIT.Depth} (hole)`,
      risk: riskString,
      eventDescription: rawDataInitiate.eventDescription,
      recommendation: rawDataInitiate.recommendation,
      diameterUnit: this.DEFINED_UNIT.Diameter,
      eventType: eventType,
      interval: rawDataInitiate.interval.name,
      run: rawDataInitiate.run.name,
      evidences: imgEvidence,
      template: this.selectedTemplate.id,
      language: this.selectedLanguage?.id,
      imageEvidences
    };

    if (infoSendInitiate && this.isCreateResolve) {
      payloadForTemplate = {
        ...payloadForTemplate, ...{
          userNameBefore: infoSendInitiate.userDisplayName,
          userDisciplineBefore: `Engineer (${infoSendInitiate.userDiscipline})`,
          rigTimestampsBefore: rigTimestamps,
          userNameSave: this.currentUser.displayName,
          userDisciplineSave:
            this.currentUser.discipline
              ? `${this.currentUser.role} (${this.currentUser.discipline.disciplineName})`
              : this.currentUser.role,
          rigTimestampsSave:
            AppHelper.DateFunctions.formatDateTime(new Date(), true, this.translatedData?.monthsDict),
        }
      }

    } else {
      payloadForTemplate = {
        ...payloadForTemplate, ...{
          userNameBefore: null,
          userDisciplineBefore: null,
          rigTimestampsBefore: null,
          userNameSave: this.currentUser.displayName,
          userDisciplineSave: this.currentUser.discipline
            ? `${this.currentUser.role} (${this.currentUser.discipline.disciplineName})`
            : this.currentUser.role,
          rigTimestampsSave: rigTimestamps,
        }
      }
    }
    payloadForTemplate.rigTimestampsSave = replaceWithDict(payloadForTemplate.rigTimestampsSave, this.translatedData?.monthsDict)
    payloadForTemplate.rigTimestampsBefore = replaceWithDict(payloadForTemplate.rigTimestampsBefore, this.translatedData?.monthsDict)
    payloadForTemplate.spudDate = replaceWithDict(payloadForTemplate.spudDate, this.translatedData?.monthsDict)

    const dataFieldsDict = this._translationService.getScopeDict(this.selectedLanguage.id, { scope: TRANSLATION_SCOPES.DATA_FIELDS })
    payloadForTemplate.userDisciplineSave = replaceWithDict(payloadForTemplate.userDisciplineSave, dataFieldsDict)
    payloadForTemplate.userDisciplineBefore = replaceWithDict(payloadForTemplate.userDisciplineBefore, dataFieldsDict)
    payloadForTemplate.recommendationFollowed = replaceWithDict(payloadForTemplate.recommendationFollowed, dataFieldsDict)
    return payloadForTemplate;
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error?.error?.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return new Error('Something bad happened! Please try again later.');
  }

  confirmRemove() {
    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header: `Are you sure you want to delete this alert from WellCare?`,
      haveCheckbox: true,
      checkboxLabel: 'Yes, I want to delete the alert.',
      haveDialogMessage: false,
      havePrimaryButton: true,
      primaryButtonLabel: 'Delete',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: true,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'Delete'),
    });
  }

  deleteAlert(alertIdEdit: string) {
    this._interventionService.deleteIntervention(alertIdEdit).subscribe({
      next: (response: any) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: 'Delete Alert',
          content: 'Alert was successfully deleted.',
        });
        this.isLoading = false;

        if (this.backPortal) {
          this.backToAlertManagement();
        } else {
          // Need keep Intervention Filter
          this._router.navigate([`/home`]);
          this._collapseLayoutService.updateCollapseLayout(true);
        }
      },
      error: (error) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Delete Alert',
          content: 'There was an error removing the alert: \n' + error,
        });
        this.isLoading = false;
      },
    });
  }

  triggerSaveAndSendResolve() {
    let status = this.formResolve.onSubmit();
    return status ? this.saveResolve() : this.error();
  }

  removeDraft() {
    if (this.backPortal) {
      this.backToAlertManagement();
    } else {
      // IMPORTANT
      // Back Home Page from here MUST keep Filter and Focusing
      // Dont needt to remove RigJournalId
      // Back to Project-List: If have RigJournalId there we will need to query by Rig Card at Finally
      // this.removeFilterRigJournalId();

      this._router.navigate([`${AppConstant.ROOT_PAGE}`]);
      this._collapseLayoutService.updateCollapseLayout(true);
    }
  }

  // Get index of tabview to show dialog close suitable
  handleChangeTab(changeTab: any, checkBtn?: any) {
    this.isCreateResolve = changeTab.index === 1;
    if (changeTab && checkBtn) {
      // INITIATE and SENT and wait for RESOLVE --> Disable button in INITIATE Tab
      if (
        this.isInitiate(checkBtn.status) &&
        checkBtn.hasSent &&
        checkBtn.timeExpire &&
        changeTab.index === 0
      )
        this.isDisabledButtonSave = true;
      else this.isDisabledButtonSave = false;

      // INITIATE and SENT
      if (
        this.isInitiate(checkBtn.status) &&
        checkBtn.hasSent &&
        !checkBtn.timeExpire
      )
        this.isDisabledButtonSave = true;
      // INITIATE and SENT and wait for RESOLVE --> Disable button in INITIATE Tab
      else if (
        this.isInitiate(checkBtn.status) &&
        checkBtn.hasSent &&
        checkBtn.timeExpire &&
        this.tabViewForm.tabs[0]._selected
      )
        this.isDisabledButtonSave = true;
      // RESOLUTION and SENT
      else if (!this.isInitiate(checkBtn.status)) {
        if (this.tabViewForm.tabs[0]._selected)
          this.isDisabledButtonSave = true;
        else if (this.tabViewForm.tabs[1]._selected && checkBtn.hasSent)
          this.isDisabledButtonSave = true;
        else this.isDisabledButtonSave = false;
      }
    }
  }

  // handle Preview
  showPreview(isCreateResolve: boolean) {
    this.isCreateResolve = isCreateResolve;
    this.disableSendfPdf = false;
    this.showPDF = true;
  }

  generatePreview(formValue: any) {
    this.formValuePDF = formValue;
  }

  handleSelected(option: OptionButtonAndIdType) {
    if (this.mailboxs) {
      const isSelected = this.mailboxs.find((mail: any) => mail.isSelected);
      this.selectedMailbox = isSelected;
    }
    
    if (option?.template) {
      this.selectedTemplate = option.template;
      if (this.selectedTemplate.id == ALERT_TYPE.ROADMAP) {
        this.A4_SIZE = { width: 842, height: 595 };
      } else {
        this.A4_SIZE = { width: 595, height: 842 };
      }
    }
  }

  addFilterRigJournalId(rigJournalId: string) {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    return this._homeService.getPayloadFilter();
  }

  showDataAlertLogs(alertResponse: { items: any, total: number }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    this._interventionService.setLoading(false);
    this._loaderLayOut.setLoadingStatus('InterventionListAPI', true);
  }

  showFilterAlertLogs(rigJournalId: string, rigName: string, alertId: string = '') {
    this._interventionService.setInterventionLogsActive(alertId);
    // Set TagName/Filter Log of Recent Alert Logs
    this._interventionService.setLogFilter({
      rigJournalId: rigJournalId,
      rigName: rigName
    });
  }

  onDestroy(): void {
    this._backPortalService.setHeaderDisplayValue('');
  }

  isInitiate(status: string): boolean {
    return (
      this.ALERT_STATUS[status] === this.ALERT_STATUS.INITIATE
    );
  }

  onButtonClickDialog(
    option: OptionButtonType | OptionButtonAndIdType,
    dialogType: DialogActionALert,
    calbackInput?: any
  ): void {
    // Selector Dialog
    if (typeof option === 'object' && 'dataSelected' in option) {
      switch (option.buttonType) {
        case AppConstant.OPTION_BUTTON.YES:
          switch (dialogType) {
            case 'Selection':
              this.handleSelected(option);
              this._confirmService.clearDialog();
          }
          break;
        case AppConstant.OPTION_BUTTON.NO:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
          break;
        default:
          break;
      }
    }
    // Normal Dialog
    else if (typeof option === 'string') {
      switch (option) {
        case AppConstant.OPTION_BUTTON.YES:
          switch (dialogType) {
            case 'Permission':
              if (this.backPortal) {
                this.backToAlertManagement();
              } else {
                // Need keep Intervention Filter
                this._router.navigate([`/home`]);
                this._collapseLayoutService.updateCollapseLayout(true);
              }
              break;
            // case 'SaveAndPreviewAlert':
            //   this.triggerSaveAndPreviewAlert(
            //     initiate,
            //     resolution
            //   );
            //   break;
            // case 'SaveAndPreviewResolution':
            //   this.triggerSaveAndPreviewAlert(
            //     initiate,
            //     resolution
            //   );
            //   break;
            case 'SaveDraftInitiate':
              this.saveAlert();
              break;
            case 'SaveDraftResolve':
              this.saveAlert();
              break;
            case 'Delete':
              this.deleteAlert(this.alertId);
              break;
            default:
              break;
          }
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.NO:
          switch (dialogType) {
            case 'Permission':
              // Dont have Cancel button
              break;
            // case 'SaveAndPreviewAlert':
            //   // Just Close
            //   break;
            // case 'SaveAndPreviewResolution':
            //   // Just Close
            //   break;
            case 'SaveDraftInitiate':
              this.removeDraft();
              break;
            case 'SaveDraftResolve':
              this.removeDraft();
              break;
            case 'Delete':
              // Just Close
              break;
            default:
              break;
          }
          // switch case all in here
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
          break;
        default:
          break;
      }
    }
  }

  backToAlertManagement() {
    // set state to empty string
    this._backPortalService.setHeaderDisplayValue('');
    this._router.navigate([`/alerts/alerts-management`]);
  }

  hidePreviewPdf(status: boolean) {
    this.showPDF = status;
  }

  trackOriginalFormData() {
    this.originalFormData.selectedLanguage = cloneDeep(this.selectedLanguage);
  }

  async loadTranslatedData() {
    try {
      await this._translationService.loadTranslationsAsync([
        TRANSLATION_SCOPES.DATA_FIELDS,
        TRANSLATION_SCOPES.CURRENT_OPERATION,
        TRANSLATION_SCOPES.EVENTS,
        TRANSLATION_SCOPES.RISKS,
        TRANSLATION_SCOPES.FLAGS,
        TRANSLATION_SCOPES.MONTHS
      ], uniq(['en', this.selectedLanguage?.id]))

      this.translatedData = {
        page: this._translationService.translate('Page', this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
        of: this._translationService.translate('of', this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.DATA_FIELDS }),
        monthsDict: this._translationService.getScopeDict(this.selectedLanguage?.id || 'en', { scope: TRANSLATION_SCOPES.MONTHS }),
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  detectFormDataChange() {
    this.isDataChanged = this.hasChangedInFormInitiate || this.hasChangedInFormResolve || this.originalFormData?.selectedLanguage?.id != this.selectedLanguage?.id;
    // Resolve NG100 Error
    this._cdr.detectChanges();
  }

  onChangeFormInitiate(event: any) {
    this.hasChangedInFormInitiate = event?.hasChange;
    this.detectFormDataChange();
  }

  onChangeFormResolve(event: any) {
    this.hasChangedInFormResolve = event?.hasChange;
    this.detectFormDataChange();
  }

  async onLanguageChange(event: any) {
    await this.loadTranslatedData();
    this.detectFormDataChange();
  }

  showWarningMissingDisibution() {
    this._notificationService.setMessage({
      type: AppConstant.MESSAGE_TYPE.WARNING,
      header: 'Information',
      content: 'The flag that was assigned to the alert is no longer available, which could be the result of a modification or the deletion of the distribution lists file.',
    });
  }

  openDistibution(rigJournalId: string) {
    this._homeService.getRigJournalbyId(rigJournalId)
    .pipe(
      map((res: any) => {
        return res?.data;
      })
    )
    .subscribe({
      next: (rigAPI: any) => {
        const data = rigAPI;
        this._distributionService.setDisplay({
          render: {
            isShow: true,
            template: data.distributionListUrl
              ? DistributionTemplate.uploading
              : DistributionTemplate.uploadYet,
            reuploadStatus: false,
          },
        });
    
        this._distributionService.setDisData({
          data: {
            rigId: data.rig.rigId,
            rigJournalId: data.rigJournalId,
            rigOperator:
              data.policy.policyCurrentName ||
              data.policy.policyOriginalName ||
              'Company Name',
            rigProject:
              data.projectCurrentName ||
              data.projectOriginalName ||
              'Project Name',
            rigName: data.rig.rigName || 'Rig Name',
            language: data.language
          },
        });
    
        this._distributionService.setDisURL({
          link: {
            distributionUrl: data.distributionListUrl,
          },
        });
      },
      error: (err: any) => {
        this._router.navigate(['home']);
        this._collapseLayoutService.updateCollapseLayout(true);
      },
    })
  }

  loadData() {
    this.triggerData();
  }

  triggerData() {
    this.isLoading = true;
    // Start the flow
    this.resetFlagFlow$.next([this.rigJournalId, this.alertId]);
  }
}