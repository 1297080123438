import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RigLevelDataToAddWellbore } from 'src/app/shared/models/rig.model';
import {
  DrillingContractors,
  ProjectData,
  RigWellbore,
  RigWellboreColumn,
  WellList,
} from 'src/app/shared/interface/rig.interface';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { Menu } from 'primeng/menu';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BOTH = 'both',
}

@Component({
  selector: 'app-home-rig-item-wellbore',
  templateUrl: './home-rig-item-wellbore.component.html',
  styleUrls: ['./home-rig-item-wellbore.component.scss'],
})
export class HomeRigItemWellboreComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  restoreRigListDataWellbore: RigWellbore[] = [];
  rigListDataWellbore: RigWellbore[] = [];

  @Input()
  restoreWellList: WellList[] = [];
  wellList: WellList[] = [];

  @Input()
  projectData!: ProjectData;

  @Input() companyData!: any;

  @Input() contractorData!: DrillingContractors;

  @Input() isViewer: boolean = false;

  @Input() isEngineer: boolean = false;

  @Output()
  requestChangeTab: EventEmitter<any> = new EventEmitter<any>();

  displayFormWellbore: boolean = false;
  displayFormWellboreEdit: boolean = false;


  rigListWellboreColumn: RigWellboreColumn[] = [
    { field: 'wellboreOriginalName', header: 'Wellbore' },
    { field: 'wellOriginalName', header: 'Well' },
    { field: 'siteOriginalName', header: 'Site' },
    { field: 'wellboreSpudDate', header: 'Spud Date' },
    { field: 'wellboreCurrentUWI', header: 'UWI' },
    { field: 'wellboreGUID', header: 'GUID' },
    { field: 'wellboreIsActive', header: 'Status' },
    { field: 'features', header: 'x' },
  ];

  counterResult: number = 0;

  rigId!: string;
  msgFromApis = 'No result found';

  editWellbore?: RigWellbore;


  rigIdToChangeStatus: string = '';
  wellboreIdToChangeStatus: string = '';
  textConfirmDialog: string = '';

  paramRequestChangeTabEmit: any[] = [];
  wellboreChangeStatus: any;

  public formWellboreItem!: FormGroup;

  public fieldNameKeys = {
    project: 'project',
    well: 'well',
  };

  AppHelper = AppHelper;

  wellboreDelete: any;
  
  displayAddWellboreDialog: boolean = false;

  dataToAddWellbore!: RigLevelDataToAddWellbore;
  
  // Control Button Add Wellbore at Header
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;
  nonPermission: boolean = true;

  constructor(
    private _activedRoute: ActivatedRoute,
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _interventionService: InterventionService,
    private _confirmService: ConfirmDialogService,
    private _userInfoService: UserInfoService
  ) {
    super();
  }
    

  onInit(): void {
    this.canAdd = this._userInfoService.hasPermission(MODULE_NAME.WELLBORE, USER_PERMISSION.ADD);
    this.canEdit = this._userInfoService.hasPermission(MODULE_NAME.WELLBORE, USER_PERMISSION.EDIT);
    this.canDelete = this._userInfoService.hasPermission(MODULE_NAME.WELLBORE, USER_PERMISSION.DELETE);

    // If User Dont Have any permissions
    this.nonPermission = !(this.canEdit || this.canDelete);
    

    this._activedRoute.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params.get('id'))
      )
      .subscribe((id) => {
        this.rigId = id || '';
      });

    if (this.rigListDataWellbore && this.restoreWellList) {
      //update initial wellbore and interval
      this.rigListDataWellbore = [...this.restoreRigListDataWellbore];
      this.wellList = [...this.restoreWellList];
      this.buildForm();
    }
  }

  buildForm() {
    let wellSelect = this.formWellboreItem?.get('well')?.value;
    if (wellSelect)
      wellSelect = this.restoreWellList.find((well: WellList) => well.id === wellSelect.id) as WellList;

    this.formWellboreItem = this._builder.group({
      [this.fieldNameKeys.project]: [
        {
          value: this.projectData.originalName || this.projectData.currentName,
          disabled: true,
        },
        [Validators.required],
      ],
      [this.fieldNameKeys.well]: [wellSelect ? wellSelect : null],
    });
  }

  showContextMenu(event: any, inputData: RigWellbore) {
    if (this.nonPermission) {
      return;
    }
    
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(wellboreRow: RigWellbore): any {
    return [
      {
        label: 'Edit',
        icon: 'c-icons edit-icon',
        visible: this.canEdit,
        command: () => {
          this.displayFormWellboreEdit = true;
          this.editWellbore = this.rigListDataWellbore.find(
            (wellbore: any) => wellbore.wellboreId === wellboreRow.wellboreId
          );
        },
      },
      {
        label: wellboreRow.wellboreIsActive ? 'Set Inactive' : 'Set Active',
        icon: wellboreRow.wellboreIsActive
          ? 'c-icons activeStatus-icon'
          : 'c-icons inactiveStatus-icon',
        visible: this.canEdit,
        disabled: this.isViewer,
        command: () => {
          // Check if any wellbore is active --> dialog override --> just change status
          let haveAnotherWellboreActive = this.restoreRigListDataWellbore.find(
            (wellbore: any) =>
              wellbore.wellboreIsActive &&
              wellbore.wellboreId !== wellboreRow.wellboreId
          );

          if (haveAnotherWellboreActive) {
            this.openConfirmOverrideStatus(
              true,
              wellboreRow.rigId,
              wellboreRow.wellboreId,
              haveAnotherWellboreActive.wellboreCurrentName ||
                haveAnotherWellboreActive.wellboreOriginalName,
              wellboreRow.wellboreCurrentName ||
                wellboreRow.wellboreOriginalName,
              wellboreRow
            );
          } else {
            this.changeWellboreStatus(
              wellboreRow.rigId,
              wellboreRow.wellboreId,
              wellboreRow
            );
          }
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        visible: this.canDelete,
        disabled: this.isViewer,
        command: () => {
          this.wellboreDelete = wellboreRow;

          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this wellbore (${
              wellboreRow.wellboreCurrentName || wellboreRow.wellboreOriginalName}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete it.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  openConfirmOverrideStatus(
    showDialog: boolean,
    rigId: string,
    wellboreId: string,
    wellboreA1: string,
    wellboreA2: string,
    wellbore: any
  ) {
    this.rigIdToChangeStatus = rigId;
    this.wellboreIdToChangeStatus = wellboreId;

    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: showDialog, // true
      header: 'Confirm Change Status',
      haveDialogMessage: true,
      dialogMessage: `Please confirm that you would like to change the status of ${wellboreA1} to Inactive and ${wellboreA2} to Active?`,
      havePrimaryButton: true,
      primaryButtonLabel: 'Confirm',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: true,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        this.onButtonClickDialog(event, 'Override'),
    });

    this.wellboreChangeStatus = wellbore;
  }

  overrideStatus(rigId: string, wellboreId: string, wellbore: any) {
    this.changeWellboreStatus(rigId, wellboreId, wellbore);
  }

  changeWellboreStatus(rigId: string, wellboreId: string, wellboreRow: any) {
    let wellDropDownValue = this.formWellboreItem.get('well')?.value;

    this.isLoading = true;

    this._homeService
      .updateWellboreStatus(rigId, wellboreId)
      .pipe(
        catchError(AppHelper.UtileFunctions.handleError),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.runId) {
            const wellboreName = response.wellboreName;

            this._confirmService.setDialog({
              ...this.confirmDialog,
              isVisible: true,
              header: 'Confirm Change Status',
              haveDialogMessage: true,
              dialogMessage: AppHelper.StringFunctions.capitalizeFirstLetter(response.message),
              havePrimaryButton: true,
              primaryButtonLabel: 'Confirm',
              isValidPrimaryButton: true,
              disablePrimaryButton: false,
              haveSecondaryButton: true,
              secondaryButtonLabel: 'Cancel',
              buttonEvent: (event: OptionButtonType) =>
                this.onButtonClickDialog(event, 'FillRun'),
            });

            this.paramRequestChangeTabEmit = [
              2,
              response.runId,
              rigId,
              wellboreId,
              wellboreName,
            ];
            this.isLoading = false;
          } else {
            // Just notify normal
            this._notificationService.setMessage({
              type: response?.message.includes('successfully')
                ? AppConstant.MESSAGE_TYPE.SUCCESS
                : AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Update Wellbore Status',
              content: AppHelper.StringFunctions.capitalizeFirstLetter(
                response.message
              ),
            });

            this.reloadFormWellbore();
          }
        },
        error: (error) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Update Wellbore Status',
            content: error?.message || error,
          });
          this.isLoading = false;
        },
      });
  }
  
  hideFormWellbore(event: any) {
    this.displayFormWellbore = event;
    this.displayFormWellboreEdit = event;
  }

  bindingWell() {
    const dataSelect = this.formWellboreItem.get('well')?.value;
    const wellSelect = this.restoreWellList.find((well: WellList) => well.id === dataSelect?.id);
    if (wellSelect) {
      const wellId = wellSelect.id;
      this.formWellboreItem.get('well')?.patchValue(wellSelect);

      this.rigListDataWellbore = this.restoreRigListDataWellbore.filter(
        (wellbore: any) => wellbore.wellId === wellId
      );
    } else {
      this.onClear();
    }
  }

  onClear() {
    this.formWellboreItem.get('well')?.patchValue('');
    this.rigListDataWellbore = [...this.restoreRigListDataWellbore];
    this.wellList = this.restoreWellList;
  }

  reloadFormWellbore(arrayWellId?: any, wellboreSelect?: any) {
    this.isLoading = true;

    this._activedRoute.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params.get('id')),
        switchMap((id) => {
          this.rigId = id || '';
          return this._homeService
            .getWellbores(id || '', this.projectData.id)
            .pipe(finalize(() => (this.isLoading = false)));
        }),
        switchMap((dataList: any) => {
          if (dataList) {

            const wellboreActive: RigWellbore | undefined = dataList.data.find((item: RigWellbore) => item.wellboreIsActive )
            let payloadFilterLog = AppConstant.DEFAULT_HOME_FILTER;
            if (wellboreActive) {
              const rigJournalId = wellboreActive.rigJournalId;
              const rigName = wellboreActive.rigName;
              
              payloadFilterLog = this.addFilterRigJournalId(rigJournalId);  
              this._interventionService.setLogFilter({
                rigJournalId: rigJournalId,
                rigName: rigName
              });

            } else {
              const rigJournalId = dataList.data[0].rigJournalId || '';
              const rigName = dataList.data[0].rigName || '';

              payloadFilterLog = this.addFilterRigJournalId(rigJournalId);
              this._interventionService.setLogFilter({
                rigJournalId: rigJournalId,
                rigName: rigName
              });
            }

            return forkJoin([
              of(dataList),
              this._interventionService.getInterventionLogsFilter(payloadFilterLog, 0, AppConstant.ITEMS_PER_PAGE),
            ]);
          }
          return of(null);
        })
      )
      .subscribe((res: any) => {
        const [ dataList, alertLogs ] = res;

        this.rigListDataWellbore = dataList.data;
        this.wellList = AppHelper.UtileFunctions.uniqBy(
          dataList.wellArray,
          (key: any) => key.id
        );

        this.restoreRigListDataWellbore = dataList.data;
        this.restoreWellList = dataList.wellArray;

        this.buildForm();
        this.bindingWell();

        // Set Intervention Log Filter
        this.showDataAlertLogs(alertLogs);
      });
  }

  hideAddWellboreDialog(value: boolean): void {
    this.displayAddWellboreDialog = value;
    let wellDropDownValue = this.formWellboreItem.get('well')?.value;
    wellDropDownValue
      ? this.reloadFormWellbore([wellDropDownValue.id])
      : this.reloadFormWellbore();
  }

  showAddWellboreDialog(): void {
    this.displayAddWellboreDialog = true;

    let defaultDataToAddWellbore = {
      source: {
        sourceId: this.companyData.edmSource,
        sourceName: this.companyData.edmSource,
        displaySourceName: AppHelper.StringFunctions.sourceName(
          this.companyData.edmSource
        ),
      },
      company: {
        policyId: this.companyData.id,
        policyName: this.companyData.originalName,
      },
      project: {
        projectId: this.projectData.id,
        projectName: this.projectData.originalName,
      },
      contractor: this.contractorData,
      assignRig: {
        rigId: this.rigListDataWellbore[0].rigId,
        rigName: this.rigListDataWellbore[0].rigName,
      },
    };
    const selectedWell = this.formWellboreItem.get('well')?.value as WellList;
    if (selectedWell) {
      if (!selectedWell) return;
      this.dataToAddWellbore = {
        ...defaultDataToAddWellbore,
        site: {
          siteId: selectedWell.site.id,
          siteName: selectedWell.site.originalName,
        },
        well: {
          wellId: selectedWell.id,
          wellName: selectedWell.originalName,
        },
      };
    } else {
      this.dataToAddWellbore = { ...defaultDataToAddWellbore };
    }
  }

  onButtonClickDialog(
    option: OptionButtonType,
    dialogType: DialogAction
  ): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Override':
            this.overrideStatus(
              this.rigIdToChangeStatus,
              this.wellboreIdToChangeStatus,
              this.wellboreChangeStatus
            );
            this._confirmService.clearDialog();
            break;
          case 'FillRun':
            this.requestChangeTab.emit(this.paramRequestChangeTabEmit);
            this._confirmService.clearDialog();
            break;
          case 'Delete':
            this.deleteWellbore();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  deleteWellbore() {
    this.isLoading = true;
    const temp = this.rigListDataWellbore.find((item: any) => {
      return item.wellboreId === this.wellboreDelete.wellboreId;
    });

    if (temp && temp.rigJournalId) {
      this._homeService
        .deleteWellbore(temp.rigJournalId)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: (res: any) => {
            this.restoreRigListDataWellbore = this.restoreRigListDataWellbore.filter(
              (wb: RigWellbore) => wb.wellboreId !== temp.wellboreId
            )
            this.bindingWell();
            const wellSelect = this.formWellboreItem.get('well')?.value;
            this.reloadFormWellbore(wellSelect.id);

            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Delete wellbore',
              content: `${
                this.wellboreDelete.wellboreCurrentName ||
                this.wellboreDelete.wellboreOriginalName
              } was deleted successfully!`,
            });
          },
          error: (err: any) => {
            this.isLoading = false;
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Delete wellbore',
              content: err,
            });
          },
          complete: () => {
            this.isLoading = false;
          },
        });
    }
  }
  
  showDataAlertLogs(alertResponse: { items: any, total: number  }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    this._interventionService.setLoading(false);
  }

  addFilterRigJournalId(rigJournalId: string) {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    return this._homeService.getPayloadFilter();
  }

  onDestroy(): void {}
}
