import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { HelpCenterService } from '../help-center.service';
import {
  Observable,
  Subject,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { DetailVideoComponent } from '../detail-video/detail-video.component';
import { DialogService } from 'primeng/dynamicdialog';
import { AddVideoComponent } from '../add-video/add-video.component';
import { EditVideoComponent } from '../edit-video/edit-video.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CreateDialogComponent } from '../create-dialog/create-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tabview-video',
  templateUrl: './tabview-video.component.html',
  styleUrls: ['./tabview-video.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class TabviewVideoComponent implements OnInit {
  @Input() user: any;
  @Input() roleView: any;
  @Output() confirmExcused: any = new EventEmitter<any>();
  @Output() videoCount: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('alertContainer', { read: ViewContainerRef }) container: any;

  isViewer: boolean = false;

  displayAddNewVideo: boolean = false;
  searchValue: any;
  categories: any = [];
  displayDetailVideo: boolean = false;
  displayCheckedConfirm?: boolean;
  dataConfirmDeleteVideo: any  = {
    header: '',
    titleCheckbox: '',
  };
  checkedConfirm?: boolean;
  faCircleInfo = faCircleInfo;
  displayEditVideo: boolean = false;
  itemSelected: any = {};
  selectedCategoryIndex: number = 0;
  presetCategoryId: string = '';
  currentCategory: string = '';
  selectedVideo: any = {
    srcVideo: '',
  };
  sas = environment.sasToken;
  videoToEdit: any = {};
  isLoading: boolean = false;

  //
  categoryMenu: any;
  createCategoryEnable: boolean = false;
  createCategoryForm!: FormGroup;
  @ViewChild('nameCategory') nameCategory!: ElementRef;
  componentRef: ComponentRef<CreateDialogComponent> | undefined;
  @ViewChild('createContainer', { read: ViewContainerRef })
  containerCreate: any;
  deleteComponentRef: ComponentRef<ConfirmDialogComponent> | undefined;
  @ViewChild('deleteContainer', { read: ViewContainerRef })
  deleteContainer: any;
  destroy$ = new Subject<void>();

  userRelated: any[] = [];
  constructor(
    private _notificationService: NotificationService,
    private _helpCenterService: HelpCenterService,
    public _blobService: AzureBlobService,
    public _DomSanitizationService: DomSanitizer,
    public viewContainerRef: ViewContainerRef,
    public dialogService: DialogService,
    private fb: FormBuilder,
    private _userInfoService: UserInfoService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';

    this.isLoading = true;

    this._userInfoService.userSubject$.pipe(
      filter((userObject: any) => !!userObject),
      take(1),
      switchMap((userObject) => forkJoin([
        of(userObject),
        this._userInfoService.getAllUsers()
      ])),
      map(([response, userRelated])=> {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
        }
        this.userRelated = userRelated;
        this.loadInitialData();
      }),
      tap(()=> this.loadVideoFromParam()),
    ).subscribe({
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadVideoFromParam() {
    this._activatedRoute.params.subscribe((params) => {
      const videoId = params?.videoId;
      if (videoId) {
        this._helpCenterService.getVideoDetail(videoId).subscribe({
          next: (resp) => {
            this.presetCategoryId = resp.data?.categoryId;
            this.loadVideo(resp.data);
          },
          error: (error) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.ERROR,
              header: `Video`,
              content: `Video not found!`,
            });
            this._router.navigate(['/help-center']);
          },
        });
      }
    });
  }

  loadInitialData() {
    this.loadPage();
    this.initCategoryForm();
    this.categoryMenu = [
      {
        items: [
          {
            label: 'Rename',
            icon: PrimeIcons.PENCIL,
            command: () => {
              this.renameItem('RENAME_CATEGORY');
            },
          },
          {
            label: 'Delete',
            icon: PrimeIcons.TRASH,
            disabled: this.isViewer,
            command: () => {
              this.deleteItem('DELETE_CHANNEL');
            },
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private loadPage(callback?: Function): void {
    this.isLoading = true;

    this.dataConfirmDeleteVideo = {
      header: '',
      titleCheckbox: '',
    };


    forkJoin([
      this.getCategories(),
    ]).subscribe({
      next: ([categories]: any[]) => {
        // sort Categories
        if (!categories.data.length) {
          return (this.categories = []);
        }
        // check count category
        let categoriesIndex: any = [];
        try {
          categoriesIndex = atob(categories.indexChannelArray).split(',');
        } catch (e) {
          categoriesIndex = [];
          console.log('error : ', e);
        }
        if (categories.data.length === categoriesIndex.length) {
          const tempArray: any[] = [];
          categoriesIndex.forEach((item: any) => {
            const temp = categories.data.find(
              (element: any) => element.categoryId === item
            );
            if (temp) {
              tempArray.push(temp);
            }
          });
          // check count data valid second
          if (tempArray.length === categories.data.length) {
            this.categories = tempArray;
          } else {
            this.categories = categories.data;
          }
        } else {
          this.categories = categories.data;
        }
        // Videos
        const countVideo: number = categories.data.reduce(
          (accumulator: number, currentValue: any) => accumulator + currentValue?.videoList?.length || 0,
          0,
        );
        this.videoCount.emit(countVideo);

        //  Initial Item selected
        this.itemSelected = this.getSelectedCategory();
        // Form create new Channel
        const tempArr: any = [];
        this.categories.forEach((item: any) => {
          tempArr.push(item.categoryName.trim());
        });
        this.createCategoryForm.controls['name'].clearValidators();
        this.createCategoryForm.controls['name'].setValidators([
          Validators.required,
          Validators.maxLength(50),
          this.validatorBlank,
          this.validatorDuplicated(tempArr),
        ]);
        if (callback) {
          callback();
        }
      },
      error: (err) => {
        console.error('something wrong occurred: ' + err);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  private getSelectedCategory() {
    if (!this.categories.length) {
      return { categoryId: '' };
    }

    if (this.presetCategoryId !== '') {
      const idx = this.categories.findIndex((cat: any) => cat.categoryId === this.presetCategoryId);
      if (idx > -1 ) {
        this.selectedCategoryIndex = idx; 
        return this.categories[idx];
      }
    }

    return this.categories[this.selectedCategoryIndex];
  }

  excuseOptionVideo(event: any, video: any) {
    switch (event.type) {
      case 'Delete': {
        const data = {
          header: `Are you sure you want to delete this video (${event.video.title}) from WellCare?`,
          titleCheckbox: 'Yes, I want to delete the video.',
          type: 'Delete',
          video: video,
        };
        this.displayConfirmVideo(data);

        break;
      }
      case 'Edit': {
        this.showEditVideo(video);
        break;
      }
    }
  }

  displayConfirmVideo(data: any) {
    this.displayCheckedConfirm = true;
    this.dataConfirmDeleteVideo = data;
  }

  cancelConfirmVideo() {
    this.displayCheckedConfirm = false;
    this.checkedConfirm = false;
  }

  excuseConfirmVideo(event: any) {
    this.isLoading = true;
    switch (event.type) {
      case 'Delete': {
        this.displayCheckedConfirm = false;
        this.checkedConfirm = false;
        this._helpCenterService
          .deleteVideo(event.video.videoId)
          .pipe(
            finalize(() => this.isLoading = false),
            tap(() => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: `Delete Video`,
                content: `${event.video.title} was deleted successfully!`,
              });
            })
          )
          .subscribe({
            next: () => {
              this.loadPage();
            },
            error: (err) => {
              console.error('something wrong occurred: ' + err);
            },
            complete: () => {},
          });
        break;
      }
    }
  }

  public openVideo(event: any, video: any): void {
    this.loadVideo(video);
  }

  public selectCategory(item: any, index: number): void {
    this.itemSelected = item;
    this.selectedCategoryIndex = index;
  }

  private loadVideo(video: any): void {
    const videoName = decodeURIComponent(
      AppHelper.StringFunctions.getFileName(video.url)
    );
    video.srcVideo = this._blobService.getVideoUrl(videoName);
    this.selectedVideo = video;
    this.createComponent(this.selectedVideo);
  }

  private getCategories(): Observable<any> {
    return this._helpCenterService.getCategories(
      'Y2F0ZWdvcnlfdHlwZT0ndmlkZW8n'
    );
  }

  private createComponent(video: any): void {
    const componentRef = this.container.createComponent(DetailVideoComponent);
    componentRef!.instance.video = video;
    componentRef!.instance.display = true;
    componentRef!.instance.user = this.user;
    componentRef!.instance.userRelated = this.userRelated;    
    componentRef!.instance.onDestroy.subscribe((event: any) => {
      if (event) video.views += 1;
      componentRef.destroy();
    });
  }

  public showAddVideo(): void {
    const ref = this.dialogService.open(AddVideoComponent, {
      header: 'Add Video',
      width: '30.25rem',
      data: {
        categories: this.categories,
        itemSelected: this.itemSelected,
        isViewer: this.isViewer,
      },
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose
      .pipe(
        switchMap((payload: FormData) => {
          if (payload) {
            this.isLoading = true;
            return this._helpCenterService.createVideo(payload);
          }
          return of(null);
        })
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Add Video',
              content: `${res.data.title} was created successfully!`,
            });
            this.loadPage();
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  public showEditVideo(video: any): void {
    const ref = this.dialogService.open(EditVideoComponent, {
      header: 'Edit Video',
      width: '30.25rem',
      data: {
        categories: this.categories,
        itemSelected: this.itemSelected,
        video: video,
        isViewer: this.isViewer
      },
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose
      .pipe(
        switchMap((data: { payload: FormData; videoId: string }) => {
          if (data) {
            this.isLoading = true;
            return this._helpCenterService.editVideo(
              data.payload,
              data.videoId
            );
          }
          return of(null);
        })
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Edit Video',
              content: `${res.data.title} was edited successfully!`,
            });
            this.loadPage();
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  public showVideoDialog(): void {
    const ref = this.dialogService.open(DetailVideoComponent, {
      width: '484px',
      height: '520px',
      dismissableMask: true,
      data: {},
    });

    ref.onClose.subscribe((data: any) => {
      if (data) {
      }
    });
  }

  //
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex) {
      const dataIndex: any = [];
      event.container.data.forEach((item: any) => {
        dataIndex.push(item.categoryId);
      });
      this._helpCenterService
        .updateIndexChannel({ indexChannel: btoa(dataIndex) })
        .subscribe({
          next: (data) => {},
          error: (error) => {
            console.log('error: ', error);
          },
        });
    }
  }

  openCreateCategoryForm() {
    this.createCategoryEnable = true;
    this.createCategoryForm.reset();
    setTimeout(() => {
      this.nameCategory?.nativeElement.focus();
      const domElement = this.nameCategory.nativeElement as HTMLElement;
      domElement.scrollIntoView();
    });
  }
  //
  renameItem(type: string) {
    let informationCreateDialog;
    const tempArr: any = [];
    switch (type) {
      case 'RENAME_CATEGORY': {
        this.categories.forEach((item: any) => {
          tempArr.push(item.categoryName);
        });
        informationCreateDialog = {
          type: 'RENAME_CHANNEL',
          header: 'Rename channel',
          message: 'Channel name',
          valueUpdated: '',
          action: 'rename-channel',
          itemSlected: this.itemSelected,
          arrayNameValidator: tempArr,
        };
        break;
      }

      //
    }
    this.createDynamicPopup(informationCreateDialog);
  }

  createDynamicPopup(informationCreateDialog: any) {
    this.componentRef = this.container.createComponent(CreateDialogComponent);
    this.componentRef!.instance.informationDialog = informationCreateDialog;
    this.componentRef!.instance.visible = true;
    this.componentRef!.instance.isViewer = this.isViewer;
    this.componentRef!.instance.completed.subscribe((message) => {
      this.loadPage();
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.SUCCESS,
        header:`Rename channel`,
        content: message
      });
    });
    this.componentRef!.instance.onDestroy.subscribe((event) => {
      this.componentRef?.destroy();
    });
  }

  addNewCategory() {
    if (this.isViewer) return;
    this.createCategoryForm.controls['name'].markAsDirty();
    if (this.createCategoryForm.status === 'VALID') {
      this.isLoading = true;
      const dataCategory = new FormData();
      dataCategory.append(
        'categoryName',
        AppHelper.UtileFunctions.encodeBase64(
          this.createCategoryForm.controls['name'].value.trim()
          )
      );
      dataCategory.append('categoryType', 'video');
      this._helpCenterService
        .createCategory(dataCategory)
        .pipe(
          switchMap((response) => {
            const dataIndex: any = [];
            this.categories.forEach((item: any) => {
              dataIndex.push(item.categoryId);
            });
            dataIndex.push(response.data.categoryId);
            return this._helpCenterService.updateIndexChannel({
              indexChannel: btoa(dataIndex),
            });
          })
        )
        .subscribe({
          next: (response) => {},
          error: (error) => {
            this.isLoading = false;
            console.log('error = ', error);
          },
          complete: () => {
            this.loadPage();
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: `Create channel`,
              content: `${this.createCategoryForm.controls[
                'name'
              ].value.trim()} was created successfully!`,
            });
            this.createCategoryEnable = false;
            this.createCategoryForm.reset();
          },
        });
    }
  }
  //
  cancelAddNewCategory() {
    this.createCategoryEnable = false;
    this.createCategoryForm.reset();
  }

  initCategoryForm() {
    this.createCategoryForm = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.maxLength(50), this.validatorBlank],
      ],
    });
  }

  validatorBlank(control: AbstractControl) {
    if (control.value?.trim() === '') {
      return { blank: true };
    } else {
      return null;
    }
  }

  validatorDuplicated(arrayNameValidator: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const dupplicated = arrayNameValidator.find(
        (item: any) =>
          item.trim().toLowerCase() === control.value?.trim().toLowerCase()
      );
      return dupplicated === undefined ? null : { duplicated: true };
    };
  }
  //
  // Function delete pop up

  deleteItem(type: string) {
    let informationDeleteDialog;
    switch (type) {
      case 'DELETE_CHANNEL': {
        const tempArr: any = [];
        this.categories.forEach((item: any) => {
          tempArr.push(item.categoryId);
        });
        const filteredArray = tempArr.filter(
          (e: string) => e !== this.itemSelected.categoryId
        );

        informationDeleteDialog = {
          header: `Are you sure you want to delete this channel (${this.itemSelected.categoryName}) from WellCare?`,
          titleCheckbox: 'Yes, I want to delete the channel.',
          type: 'DELETE_CHANNEL',
          itemSelected: this.itemSelected,
          action: 'Delete channel',
          indexArray: btoa(filteredArray),
        };
        break;
      }
    }
    //
    this.createDynamicConfirmPopup(informationDeleteDialog);
  }

  createDynamicConfirmPopup(informationDeleteDialog: any) {
    this.deleteComponentRef = this.deleteContainer.createComponent(
      ConfirmDialogComponent
    );
    this.deleteComponentRef!.instance.informDisplay = informationDeleteDialog;
    this.deleteComponentRef!.instance.visible = true;
    this.deleteComponentRef!.instance.onDestroy.subscribe((event) => {
      this.deleteComponentRef?.destroy();
    });
    this.deleteComponentRef!.instance.completed.subscribe((event) => {
      this.loadPage(() => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: informationDeleteDialog.action,
          content: `${
            informationDeleteDialog.type === 'DELETE_CHAPTER'
              ? `${informationDeleteDialog.itemSelected.chapterName}`
              : `${informationDeleteDialog.itemSelected.categoryName}`
          } was deleted successfully!`,
        });
      });
    });
  }
}