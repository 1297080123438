import { Component, OnInit, ViewChild } from '@angular/core';
import { HelpCenterService } from '../help-center.service';
import { Subject, filter, forkJoin, map, of, switchMap, take, tap } from 'rxjs';
import { PrimeIcons } from 'primeng/api';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog, IMessage } from 'src/app/shared/interface/common';
import { OptionButtonType } from 'src/app/shared/type';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { FileUpload } from 'primeng/fileupload';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';

@Component({
  selector: 'app-tabview-release-note',
  templateUrl: './tabview-release-note.component.html',
  styleUrls: ['./tabview-release-note.component.scss']
})
export class TabviewReleaseNoteComponent implements OnInit {  
  WELLCARE_VERSION = AppConstant.WELLCARE_VERSION;
  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;
  maxSizeContent: number = 10000;
  createReleaseEnable: boolean = false;
  isLoading:boolean = false;
  releaseNotes: any[] = [];

  
  itemSelected: any = null;
  editorEnable: boolean = false;
  isAdmin: boolean = false;

  isRenameRealseNote: boolean = false;
  itemRename: any = null;
  
  tempEditor: string = '';
  categoryMenu: any;

  pdfAttachment: File | null = null;
  pdfAttachmentName: string = '';
  private unsubscribe$: Subject<void> = new Subject<void>();
  
  @ViewChild('menuCategory') menuCategory: any;
  @ViewChild('uploadedFiles') uploadedFiles!: FileUpload;

  constructor(
    private _helpCenterService: HelpCenterService,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService,
    private _blobService: AzureBlobService,
  ) { }

  ngOnInit(): void {
    this._userInfoService.userSubject$.pipe(
      tap(() => this.isLoading = true),
      filter((userObject: any) => !!userObject),
      take(1),
      switchMap((userInfo: any) => {
        if (userInfo) {
          this.isAdmin = userInfo.role === AppConstant.ROLES.ADMIN.label;
        }
        return this._helpCenterService.getCategories(AppConstant.CATEGORY_RELEASE_NOTE_BASE64)
      }),
      map((listCategories: any) => {
        if (listCategories?.data) {
          const totalRelease = listCategories.data.length;
          if (totalRelease > 0) {
            if (!this.isAdmin) {
                this.releaseNotes = listCategories.data.filter((r: any) => r.isPublish);
                this.releaseNotes.forEach((r: any) => this.selectCategory(r));
              } else {
                this.releaseNotes = listCategories.data
                this.selectCategory(this.releaseNotes[totalRelease - 1]);
            }
          }
        }

      })
    ).subscribe({
      next: () => {
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  showContextMenu(event: any, data: any) {
    this.categoryMenu = this.getCategoryMenu(data);
    this.menuCategory.toggle(event);
  }

  getCategoryMenu(data: any) {
    return [
      {
        items: [
          {
            label: 'Rename',
            icon: PrimeIcons.PENCIL,
            disabled: data.isPublish,
            command: (event: any) => {
              this.clickBtnRenameItem(data);
            },
          },
          {
            label: 'Delete',
            icon: PrimeIcons.TRASH,
            disabled: data.isPublish,
            command: (event: any) => {
              this.deleteItemReleaseNote(data);
            },
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onHideEditor() {
    if (this.tempEditor !== this.itemSelected.content) {
      const onButtonDialogDelete = (option: OptionButtonType) => {
        switch (option) {
          case AppConstant.OPTION_BUTTON.YES:
            this.tempEditor = this.itemSelected.content;
            this.editorEnable = false;
            break;
          case AppConstant.OPTION_BUTTON.NO:
          case AppConstant.OPTION_BUTTON.CANCEL:
          default:
            break;
        }
        this._confirmService.clearDialog();
      }
  
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header:  `Are you sure you want to discard all change?`,
        haveCheckbox: true,
        checkboxLabel: 'Yes, I want to discard change.',
        haveDialogMessage: false,
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          onButtonDialogDelete(event),
      });
    } else {
      this.editorEnable = false;
    }
  }

  
  onShowEditor() {
    if (!this.isAdmin) return;
    this.editorEnable = true;
  }

  public validateDescriptionItem() {
    let isValid = true;
    if (!(this.tempEditor.length > 0)) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Create release notes`,
        content: `Please input the content.`,
      });
      isValid = false;
    }
    if (!(this.pdfAttachmentName)) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Create release notes`,
        content: `Please input the attachment.`,
      });
      isValid = false;
    }

    return isValid;
  }

  public updateDescriptionItem() {
    const count = this.countSizeContent();

    if (count > this.maxSizeContent) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Update content release notes`,
        content: `Please input lower than ${this.maxSizeContent} characters!`,
      });
      return;
    }

    this.isLoading = true;
    this.itemSelected.content = this.tempEditor;

    const myBlob = new Blob([this.itemSelected.content || ''], {
      type: 'text/plain',
    });
    const file = new File([myBlob], 'description.txt', {
      type: 'text/plain',
    });
    const dataRelease = new FormData();
    dataRelease.append('description', file);
    dataRelease.append('sizeContent', count.toString());
    if (this.pdfAttachment)
      dataRelease.append('attachmentRelease', this.pdfAttachment);


    this._helpCenterService.updateCategory(this.itemSelected.categoryId, dataRelease).subscribe({
      next: (response: any) => {
        this.itemSelected.attachmentUrl = response.data.attachmentUrl;
        for (let item of this.releaseNotes) {
          if (item.categoryId === response.data.categoryId) {
            Object.assign(item, response.data);
          }
        }
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: `Update content release notes`,
          content: `Release notes ${this.itemSelected.categoryName.trim()} was updated successfully!`,
        });
      },
      error: (error) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Update content release notes`,
          content: `Release notes ${this.itemSelected.categoryName.trim()} was updated unsuccessfully. Please try again!`,
        });
        console.log(error);
      },
      complete: () => {
        this.isLoading = false;
        this.onHideEditor();
      },
    });
  }

  public onSelectCategory(item: any): void {
    if (this.editorEnable) {
      const onButtonDialogEvent = (option: OptionButtonType) => {
        switch (option) {
          case AppConstant.OPTION_BUTTON.YES:
            this.selectCategory(item);
            break;
          case AppConstant.OPTION_BUTTON.NO:
          case AppConstant.OPTION_BUTTON.CANCEL:
          default:
            break;
        }
        this._confirmService.clearDialog();
      }
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header:  `Are you sure you want to switch to Release notes ${item.categoryName}?`,
        haveCheckbox: true,
        checkboxLabel: 'Yes, I want to change.',
        haveDialogMessage: false,
        havePrimaryButton: true,
        primaryButtonLabel: 'Continue',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          onButtonDialogEvent(event),
      });
    } else {
      this.selectCategory(item);
    }
  }

  public selectCategory(item: any): void {
    this.isLoading = true;
    this.editorEnable = false;
    this.isRenameRealseNote = false;
    this.itemRename = null;
    this.pdfAttachment = null;

    this.pdfAttachmentName = item?.attachmentUrl?.split('/')[4]?.slice(14) || '';
    this._helpCenterService.getCategory(item.categoryId)
      .pipe().subscribe({
        next: (response: any) => {
          this.itemSelected = response.data;
          this.itemSelected.content = response?.content || '<p></p>';
          this.tempEditor = response?.content || '<p></p>';

          for (let item of this.releaseNotes) {
            if (item.categoryId === response.data.categoryId) {
              Object.assign(item, response.data);
            }
          }

        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  openAttachment(url:string = '') {
    if (url) {
      this.isLoading = true;
      this._blobService
        .downloadPDF(
          decodeURIComponent(AppHelper.StringFunctions.getFileName(url)),
          'attachmentrelease'
        )
        .then((res: any) => {
          const fullFileName = AppHelper.StringFunctions.getFileName(url).slice(14);
          const fileDownload : File = 
              new File([res], fullFileName, {
                type:'application/pdf',
              })
          const file = window.URL.createObjectURL(fileDownload);
          window.open(file);
          
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Caught error:', error); // The error will be caught here
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading PDF Document',
            content: 'This document is no longer available, please try again',
          });
          this.isLoading = false;
        });
    } else if (this.pdfAttachment) {
      let url = window.URL.createObjectURL(this.pdfAttachment);
      window.open(url);
    }
  }

  private validateVersionNumber(item: any, type: string, root: any[]): boolean {
    let valid = true;
    const path = item.categoryName.split('.');

    for (let version of root) {
      const pathPreVersion = version.categoryName.split('.');
      let i = 0;
      while (i < 3 && valid) {
        if (i != 2 && path[i] < pathPreVersion[i])
          valid = false;

        
        if (i != 2 && path[i] > pathPreVersion[i])
          break;

        if (i == 2 && path[i] <= pathPreVersion[i])
          valid = false;
        i++;
      }

      if (!valid)
        break;
    }

    if (!valid)
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Release Version`,
        content: `The version number must be greater than the previous version number`,
      });

    return valid;
  }

  clickBtnRenameItem(releaseNote: any) {
    this.itemRename = {...releaseNote};
    this.isRenameRealseNote = true;
  }

  openCreateReleaseForm() {
    const inValid = this.releaseNotes.some((release: any) => !release.isPublish)

    if (inValid) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Adding new release notes`,
        content: `Please publish recent release notes before adding new ones!`,
      });
    } else {
      this.createReleaseEnable = true;
    }
  }

  actionFormRelease(event: any) {
    let isValid = true;
    const dataRelease = new FormData();    
    dataRelease.append(
      'categoryName',
      AppHelper.UtileFunctions.encodeBase64(event?.data?.categoryName?.trim())
    );
    dataRelease.append('categoryType', 'release_note');

    switch (event.action) {
      case 'NEW':
        isValid = this.validateVersionNumber(event.data, event.action, this.releaseNotes);
        if (!isValid) return;

        this.isLoading = true;
        this._helpCenterService
          .createCategory(dataRelease)
          .subscribe({
            next: (response: any) => {
              this.releaseNotes.push(response.data)
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: `Create release notes`,
                content: `Release notes ${event.data.categoryName.trim()} was created successfully!`,
              });

              this.onSelectCategory(this.releaseNotes[this.releaseNotes.length - 1]);
            },
            error: (error) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: `Create release notes`,
                content: `Release notes ${event.data.categoryName.trim()} was created unsuccessfully. Please try again!`,
              });
              console.log(error);              
            },
            complete: () => {
              this.isLoading = false;
            },
          });
        break
      case 'EDIT':
        const index = this.releaseNotes.findIndex((r: any) => r.categoryId === event.data.categoryId)
        if (index < 0)
          return false;

        isValid = this.validateVersionNumber(event.data, event.action, this.releaseNotes.slice(0 , index));
        if (!isValid) return        

        this.isLoading = true;
        this._helpCenterService
          .updateCategory(event.data.categoryId, dataRelease)
          .subscribe({
            next: () => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: `Updated release notes`,
                content: `Release notes ${event.data.categoryName.trim()} was updated successfully!`,
              });
              this.releaseNotes.forEach((item: any) => {
                if (item.categoryId === event.data.categoryId) {
                  item.categoryName = event.data.categoryName;
                }
              })

              if (this.itemSelected?.categoryId === event?.data?.categoryId) {
                this.itemSelected = event.data;
              }
            },
            error: (error) => {            
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: `Update release notes`,
                content: `Release notes ${event.data.categoryName.trim()} was updated unsuccessfully. Please try again!`,
              });
              console.log(error);
            },
            complete: () => {
              this.isLoading = false;
            },
          });
        break;
      case 'CANCEL':
      default:
        break;
      }

    this.isLoading = false;
    this.createReleaseEnable = false;
    this.itemRename = null;
    this.isRenameRealseNote = false;
  }

  onTextChange() {
    const control = window.document.querySelector("app-tabview-release-note .ql-editor") as HTMLElement;

    if (control?.childNodes.length > 0) {
      control.childNodes.forEach((child: any) => { 
        this.removeStyle(child);
      })
    }
  }

  removeStyle(node: any) {    
    try {
      const style = node.style as CSSStyleDeclaration;
      if (style?.backgroundColor) style.backgroundColor = "";
      if (style?.color) style.color = "";

      if (node?.childNodes?.length > 0) {
        node.childNodes.forEach((child: any) => {
          this.removeStyle(child);
        })
      }
    } catch (e: any) {
      console.log("error : ", e );
    }
  }

  countSizeContent() {
    const control = window.document.querySelector("app-tabview-release-note .ql-editor") as HTMLElement;
    return control?.textContent?.length || 0;
  }

  onPublishReleaseNote(item: any) {
    if (!item?.sizeContent || item?.sizeContent < 100) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Publish release notes`,
        content: `Size of content must be greater than 100 characters. Please input more!`,
      });
      return;
    }

    const onButtonDialogEvent = (option: OptionButtonType) => {
      switch (option) {
        case AppConstant.OPTION_BUTTON.YES:
          this.publishReleaseNote(item);
          break;
        case AppConstant.OPTION_BUTTON.NO:
        case AppConstant.OPTION_BUTTON.CANCEL:
        default:
          break;
      }
      this._confirmService.clearDialog();
    }

    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header:  `Publish ${this.WELLCARE_VERSION} ${this.itemSelected?.categoryName}?`,
      haveCheckbox: true,
      checkboxLabel: 'Yes, I want to publish the release notes',
      haveDialogMessage: false,
      havePrimaryButton: true,
      primaryButtonLabel: 'Publish',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: true,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        onButtonDialogEvent(event),
    });
  }

  publishReleaseNote(item: any) {
    this._helpCenterService.publishReleaseNote(item.categoryId).pipe(
      tap(() => this.isLoading = true)
    ).subscribe({
      next: () => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: `Publish release notes`,
          content: `Release notes ${item.categoryName.trim()} was published successfully!`,
        });

        this.releaseNotes.forEach((item: any) => {
          if (item.categoryId === item.categoryId) {
            item.isPublish = true;
          }
        })
      },
      error: (error) => {            
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Publish release notes`,
          content: `Release notes ${item.categoryName.trim()} was published unsuccessfully. Please try again!`,
        });
        console.log(error);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  deleteItemReleaseNote(data: any) {
    const onButtonDialogDelete = (option: OptionButtonType) => {
      switch (option) {
        case AppConstant.OPTION_BUTTON.YES:
          this.isLoading = true;
          this._helpCenterService
            .deleteCategory(data.categoryId)
            .pipe(
              tap(() => this.isLoading = false)
            )
            .subscribe({
              next: () => {
                if (data.categoryId === this.itemSelected?.categoryId) {
                  this.tempEditor = ''
                  this.itemSelected = null;
                  this.pdfAttachment = null;
                  this.pdfAttachmentName = '';
                }
                this.releaseNotes = this.releaseNotes.filter((release: any) => release.categoryId !== data.categoryId);
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.SUCCESS,
                  header: `Delete release notes`,
                  content: `Release notes ${data?.categoryName} was deleted successfully!`,
                });
              },
              error: () => {
                this._notificationService.setMessage({
                  type: AppConstant.MESSAGE_TYPE.WARNING,
                  header: `Delete release notes`,
                  content: `Release notes ${data?.categoryName} was deleted unsuccessfully. Please try again!`,
                });
              },
              complete: () => {
                this.isLoading = false;
              }
            });
          break;
        case AppConstant.OPTION_BUTTON.NO:
        case AppConstant.OPTION_BUTTON.CANCEL:
        default:
          break;
      }
      this._confirmService.clearDialog();
    }

    this._confirmService.setDialog({
      ...this.confirmDialog,
      isVisible: true,
      header:  `Are you sure you want to delete this release notes (${this.itemSelected?.categoryName}) from WellCare?`,
      haveCheckbox: true,
      checkboxLabel: 'Yes, I want to delete the release notes.',
      haveDialogMessage: false,
      havePrimaryButton: true,
      primaryButtonLabel: 'Delete',
      isValidPrimaryButton: true,
      disablePrimaryButton: false,
      haveSecondaryButton: true,
      secondaryButtonLabel: 'Cancel',
      buttonEvent: (event: OptionButtonType) =>
        onButtonDialogDelete(event),
    });
  }
  
  public onSelectFile(event: any): void {
    const idValid = this.validateFile(event.files[0])
    if (idValid) {
      this.pdfAttachment = event.files[0];
      this.pdfAttachmentName = this.pdfAttachment?.name || "";
    } else {
      this.onUploaderClear();
    }
  }

  
  public onUploaderClear(): void {
    this.uploadedFiles.clear();
    this.pdfAttachment = null;
  }

  private validateFile(file: File): boolean {
    const MAXIMIN_TOTAL_FILES_SIZE = 10485760; // 10MB = 10485760bytes
    const listErr: IMessage[] = [];

    // Verify file type
    const mimeTypeAllow = [
      'application/pdf',
    ];
    if (!mimeTypeAllow.includes(file.type.toLowerCase())) {
      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Invalid File Type`,
        content:
          'Invalid file type. Allowed file types: .pdf',
      });
    }

    // Verify file name
    let formatSpecialChar = /[!()\[\]{}\\|<>\/?'"]+/;
    if (
      formatSpecialChar.test(
        AppHelper.StringFunctions.extractFileName(file.name)
      )
    ) {
      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Detected: Invalid file name',
        content:
          "A file name can't contain any of the following characters: ! ' \" { } [ ] ( ) \\ / : ? < > | ",
      });
    }

    // Verify file size 0 byte
    if (file.size  == 0) {
      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Invalid file size',
        content: 'Empty file cannot be uploaded.',
      });
    }

    // Verify file size 10000000
    if (file.size > MAXIMIN_TOTAL_FILES_SIZE!) {
      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Invalid file size',
        content: 'Allowed maximum file size: 10MB',
      });
    }

    // Remove notification duplicate
    for (let i = 0; i < listErr.length; i++) {
      let isExist: boolean = false;
      for (let j = 0; j < i; j++) {
        if (listErr[j].content === listErr[i].content) {
          isExist = true;
          listErr.splice(i--, 1);
          break;
        }
      }
      if (!isExist) this._notificationService.setMessage(listErr[i]);
    }

    if (listErr.length > 0) 
      return false;
    return true;
  }
}
