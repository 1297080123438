import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import {
  ProjectColumn,
  ProjectList,
} from '../../../shared/interface/project.interface';
import { EditProject } from '../../../shared/interface/project.interface';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { BehaviorSubject } from 'rxjs';
import { Menu } from 'primeng/menu';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';

@Component({
  selector: 'app-home-project-item-table',
  templateUrl: './home-project-item-table.component.html',
  styleUrls: ['./home-project-item-table.component.scss'],
})
export class HomeProjectItemTableComponent extends BaseComponent {
  @Input()
  projectListData: ProjectList[] = [];

  @Output()
  showRequestReloadHomePage: EventEmitter<string> = new EventEmitter<string>();

  displayEditProject: boolean = false;
  editProject!: EditProject;

  projectListColumn: ProjectColumn[] = [
    { field: 'projectName', header: 'Project' },
    { field: 'company', header: 'Customer' },
    { field: 'country', header: 'Country' },
    { field: 'region', header: 'Region' },
    { field: 'block', header: 'Block' },
    { field: 'lease', header: 'Lease' },
    { field: 'field', header: 'Field' },
    { field: 'timezone', header: 'Time Zone' },
    { field: 'units', header: 'Units' },
    { field: 'features', header: '' },
  ];

  // Variable using for option of add project, edit project, setting feature
  itemFeatures!: MenuItem[];

  // Hide hideItemFeatures When User is Engineer
  hideItemFeatures: boolean = false;

  // Check role for Viewer and Engineer
  isViewer: boolean = false;
  isEngineer: boolean = false;

  //
  isLoading: boolean = false;

  AppHelper = AppHelper;

  @ViewChild('menu') menu: any;

  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  constructor(
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _interventionService: InterventionService
  ) {
    super();
  }
  onInit(): void {
    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
          this.isEngineer = response.role === AppConstant.ROLES.ENGINEER.label;
          this.loadInitialData();
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  onDestroy(): void {}

  loadInitialData() {
    this.projectListData = AppHelper.UtileFunctions.sortArrayBykey(
      this.projectListData,
      'projectName'
    );
  }
  redirectToRig(projectId: string) {
    this._router.navigate([`home/rig-list/${projectId}`]);
  }

  showContextMenu(event: any, inputData: EditProject) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(project: EditProject): any {
    const canEdit = this._userInfoService.hasPermission(MODULE_NAME.PROJECT, USER_PERMISSION.EDIT);
    const canDelete = this._userInfoService.hasPermission(MODULE_NAME.PROJECT, USER_PERMISSION.DELETE);

    return [
      {
        label: 'View',
        icon: 'c-icons eye-icon',
        command: () => {
          this.redirectToRig(project.projectId)
        },
      },
      {
        label: 'Edit',
        visible: canEdit,
        icon: 'c-icons edit-icon',
        command: () => {
          this.displayEditProject = !this.displayEditProject;
          this.editProject = project;
        },
      },
      {
        label: 'Delete',
        visible: canDelete,
        disabled: this.isViewer,
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        command: () => {},
      },
    ];
  }

  hideEditProject(value: boolean) {
    if (value) {
      this.displayEditProject = !value;
      this.showRequestReloadHomePage.emit('Reload Table Page');
    } else {
      this.displayEditProject = value;
    }
  }

  handleRowClick(event: MouseEvent, rowData: any): void {
    const targetElement = event.target as HTMLElement;
    // console.log(targetElement);
    if (targetElement.closest('.option-cell')) {
      // Do Notthing - Handle in HTML
    } else if (targetElement.closest('.primary-link')) {
      this.redirectToRig(rowData.projectId);
    } else {
      this.handleFilterIntervention(rowData);
    }
  }

  // Deprecated 
  handleFilterIntervention(rowData: any) {}
}
