<div class="user">
  <app-module-header
    [backPortal]="false"
    backPortalLabel="Nothing Render Because backPortal === false"
    headerTitle="Team Management"
    [buttonFearture]="true"
    buttonFeartureLabel="Add User"
    [bottomMargin]="true"
    (onClickButton)="this.displayAddUser = true"
  ></app-module-header>

  <form #searchForm="ngForm">
    <div class="user__filter">
      <div class="d-flex">
        <div class="user__filter--input">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              type="text"
              pInputText
              placeholder="Search by name, email"
              class="search-box"
              maxlength="50"
              name="searchString"
              (keyup)="keyupSearch($event)"
              ngModel
            />
          </span>
        </div>

        <div class="project__filter-button project__filter-button--dark">
          <!-- <p-button label="Filters"></p-button> -->
          <app-user-advanced-filter
            #advancedFilter
            [restoreUserListData]="restoreUserListData"
            (chipFilter)="loadChipFilter($event)"
            (filter)="loadPayloadFilter($event)"
          ></app-user-advanced-filter>
        </div>
      </div>
      <div class="user__filter--counter user__filter--text">
        <div>
          <span>Total: {{ counterResult }}</span>
        </div>
      </div>
      <div class="chip-wrapper">
        <ng-container
          class="p-d-flex"
          *ngFor="let item of chipsFilter; index as i"
        >
          <p-chip
            label="{{ item }}"
            styleClass="chip-item"
            removeIcon="pi pi-times"
            [removable]="true"
            (onRemove)="removeItemChip(item, i)"
          ></p-chip>
        </ng-container>
      </div>
      <div>
        <p
          class="cursor-pointer button-highlight user__filter--text"
          (click)="clearFilter()"
        >
          Clear all
        </p>
      </div>
    </div>

    <p-table
      [value]="userListData"
      [columns]="userListColumn"
      [scrollable]="true"
      styleClass="p-datatable-striped custom-table"
      [resizableColumns]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 40px">
          <th
            *ngFor="let col of columns; last as isLast; let i = index"
            [pSortableColumn]="col.field"
            pResizableColumn
            [ngClass]="isLast ? 'th-last' : 'th-default'"
          >
            <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
            </ng-container>

            <ng-template #showThNotLast>
              <div
                [pTooltip]="col.header"
                tooltipPosition="bottom"
                class="th-content"
              >
                {{ col.header }}
              </div>

              <div class="header-sort-icon">
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                ></i>
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                ></i>
              </div>
            </ng-template>
            <ng-template #showThLast></ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr>
          <td
            class="primary-color td-feature pointer"
            (click)="redirectToRollAndPermission(rowData.id)"
          >
            <div class="d-flex w-100">
              <div
                *ngIf="!rowData.avatar"
                class="avatar-random"
                [attr.data-label]="
                  getFirstLetter(rowData.firstName + ' ' + rowData.lastName)
                "
                [appRandomAvatar]="
                  getFirstLetter(rowData.firstName + ' ' + rowData.lastName)
                "
                avatarSize="extra-small"
              ></div>

              <ng-container *ngIf="rowData.avatar">
                <ng-container *ngIf="!rowData.avatarBlob">
                  <div class="avatar">
                    <div
                      class="content-progress-bar"
                      style="
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span class="loader scale-small-list"></span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="rowData.avatarBlob">
                  <div class="avatar">
                    <img
                      [src]="rowData.avatarBlob | safe : 'url'"
                      [alt]="
                        'Avatar Image of' +
                        rowData.firstName +
                        ' ' +
                        rowData.lastName
                      "
                    />
                  </div>
                </ng-container>
              </ng-container>

              <span
                class="ps-2 td-content w-100"
                [pTooltip]="rowData.firstName + ' ' + rowData.lastName"
                tooltipPosition="bottom"
              >
                {{ rowData.firstName + " " + rowData.lastName }}
              </span>
            </div>
          </td>
          <td class="td-feature">
            <span [pTooltip]="rowData.roleDisplay" tooltipPosition="bottom">
              {{ rowData.roleDisplay }}
            </span>
          </td>
          <td class="td-feature">
            <div
              class="td-content"
              style="width: 60px"
              [pTooltip]="rowData.halId || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData.halId || "N/A" }}
            </div>
            <div class="td-icon" (click)="copyText(rowData.halId || 'N/A')">
              <svg-icon key="clipBoard" size="md"></svg-icon>
            </div>
          </td>
          <td class="td-feature">
            <span
              class="td-content"
              [pTooltip]="rowData?.pslDisplay?.name || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData?.pslDisplay?.name || "N/A" }}
            </span>
          </td>
          <td class="td-feature">
            <span
              class="td-content"
              *ngIf="!rowData.country"
              [pTooltip]="'N/A'"
              tooltipPosition="bottom"
            >
              {{ "N/A" }}
            </span>
            <span
              *ngIf="rowData.country"
              [pTooltip]="rowData.countryDisplay?.name"
              tooltipPosition="bottom"
            >
              <!-- + ' ' + '(' + rowData.country + ')' -->
              {{ rowData.countryDisplay?.name }}
            </span>
          </td>
          <td class="td-feature">
            <span
            class="td-content"
            [pTooltip]="rowData.remoteCenterDisplay || 'N/A'"
            tooltipPosition="bottom"
          >
            {{ rowData.remoteCenterDisplay || "N/A" }}
          </span>
          </td>
          <td class="td-feature">
            <span
              [pTooltip]="rowData.timezone || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData.timezone || "N/A" }}
            </span>
          </td>
          <td class="td-feature">
            <div
              class="td-content"
              [pTooltip]="rowData.customMail || rowData.mail || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData.customMail || rowData.mail || "N/A" }}
            </div>
            <div
              class="td-icon"
              (click)="copyText(rowData.customMail || rowData.mail || 'N/A')"
            >
              <svg-icon key="clipBoard" size="md"></svg-icon>
            </div>
          </td>
          <td
            class="td-feature"
            [pTooltip]="replaceCanadaDialCode(rowData.phone) || 'N/A'"
            tooltipPosition="bottom"
          >
            <div class="td-content">
              {{ replaceCanadaDialCode(rowData.phone) || "N/A" }}
            </div>
            <div
              class="td-icon"
              (click)="copyText(replaceCanadaDialCode(rowData.phone) || 'N/A')"
            >
              <svg-icon key="clipBoard" size="md"></svg-icon>
            </div>
          </td>
          <td class="td-feature">
            <span
              class="td-content"
              [pTooltip]="rowData.mailBoxDisplay || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData.mailBoxDisplay || "N/A" }}
            </span>
          </td>
          <td
            style="min-width: 50px; max-width: 50px"
            (click)="showContextMenu($event, rowData)"
          >
            <div class="more-option-table">
              <svg-icon
                class="header-content__title--feature"
                key="dot3hoz"
                size="md"
              >
              </svg-icon>
            </div>
          </td>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [appendTo]="'body'"
            [styleClass]="'menu-table-item lower-item role-overlay'"
          ></app-menu>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">{{ msgFromApis }}</td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</div>

<ng-container *ngIf="displayEditUser">
  <app-user-form-user
    [displayFormUser]="displayEditUser"
    [userEdit]="editUser"
    [userLogin]="currentUser"
    (hideFormUser)="displayEditUser = $event"
    (requestReloadPage)="requestReloadPage($event)"
  ></app-user-form-user>
</ng-container>

<ng-container *ngIf="displayAddUser">
  <app-user-form-user
    [displayFormUser]="displayAddUser"
    (hideFormUser)="displayAddUser = $event"
    (requestReloadPage)="requestReloadPage($event)"
  ></app-user-form-user>
</ng-container>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
