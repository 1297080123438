<div class="terms-of-use">
  <p class="title text-center">
    HALLIBURTON WELLCARE APPLICATION TERMS – INTERNAL USE
  </p>
  <div class="content">
    <p>
      1. Halliburton Energy Services, Inc. (“Halliburton”) along with its
      affiliates, employees, and contractors (collectively “Users”) shall treat
      the WellCare Application (“App”) as Halliburton confidential information.
    </p>
    <p>
      2. Users may access the App for internal use only. Use of customer data or
      content is governed by the terms of the contract with the owner of the
      content.
    </p>
    <p>
      3. THE WELLCARE APPLICATION IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY
      KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
      NO EVENT SHALL HALLIBURTON BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
      LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
      FROM, OUT OF OR IN CONNECTION WITH THE WELL CARE APPLICATION. USERS AGREES
      TO RELEASE, INDEMNIFY, DEFEND, AND HOLD HARMLESS HALLIBURTON FROM AND
      AGAINST ANY AND ALL CLAIMS, LIABILITY AND CAUSES OF ACTION ON ACCOUNT OF
      THIRD-PARTY CLAIMS RELATING TO ACTIVITIES UNDER THESE TERMS.
    </p>
    <p>
      4. EXPORT COMPLIANCE. Users agree that any technology or source code
      exchanged under these terms is subject to the U.S. Export Administration
      Regulations (“EAR”). If the Deemed Export of Controlled technology or
      source code, as defined in the EAR, is needed, both parties shall disclose
      the nationality of personnel that will have access to such Controlled
      technology or source code for the sole purpose of determining if an export
      license is required. If it is determined that an export license is
      required, the applicable party shall procure such license(s) prior to the
      exchange of any Controlled technology or source code. "Deemed Export"
      means the release of technology or source code to a non-U.S. person
      wherein it is “deemed” to be exported to the person’s most recent country
      or countries of nationality. "Controlled" means technology or source code
      that requires an export license to certain non-U.S. countries.
    </p>
    <p>
      5. Access to this website is governed according to the
      <a
        href="https://www.halliburton.com/en/about-us/corporate-governance/terms-and-conditions"
        target="_blank"
      >
        Terms and Conditions (halliburton.com).</a
      >
    </p>
    <p>
      6. All information collected through this website or the App is subject to
      the following:
      <a
        href="https://www.halliburton.com/en/about-us/corporate-governance/privacy-policy"
        target="_blank"
      >
        Privacy Policy (halliburton.com)</a
      >
      . By using the website and App, you consent to all actions taken by us
      with respect to your information subject to our compliance with the
      Privacy Policy.
    </p>
  </div>
</div>
