<div class="filter">
  <p-button
    [attr.data-cy]="'filters-button'"
    class="custom-p-button custom-p-button--secondary"
    [label]="'Filters'"
    (click)="op.toggle($event)"
  >
  </p-button>

  <p-overlayPanel
    #op
    [showCloseIcon]="false"
    (onHide)="onHide($event)"
    styleClass="filter-advanced-mailbox"
  >
    <div class="option-filter">
      <p class="filter-label">{{ METADATA.DISCIPLINE.LABEL }}</p>
      <p-multiSelect
        #disciplineDropdown
        [options]="disciplinesOption"
        [(ngModel)]="selectedDiscipline"
        optionLabel="disciplineName"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        (onChange)="
          selectedOptionChange(
            $event,
            selectedDiscipline,
            METADATA.DISCIPLINE.LABEL
          )
        "
        (onPanelHide)="onPanelHideDisciplineDropdown()"
        (onClear)="selectedOptionClear(METADATA.DISCIPLINE.LABEL)"
        [resetFilterOnHide]="true"
      >
      </p-multiSelect>
    </div>
    <div class="option-filter">
      <p class="filter-label">{{ METADATA.REMOTE_CENTER.LABEL }}</p>
      <p-multiSelect
        #remoteCenterDropdown
        [options]="remoteCentersOption"
        [(ngModel)]="selectedRemoteCenter"
        optionLabel="remoteCenterName"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        (onChange)="
          selectedOptionChange(
            $event,
            selectedRemoteCenter,
            METADATA.REMOTE_CENTER.LABEL
          )
        "
        (onPanelHide)="onPanelHideRemoteCenterDropdown()"
        (onClear)="selectedOptionClear(METADATA.REMOTE_CENTER.LABEL)"
        [resetFilterOnHide]="true"
      >
      </p-multiSelect>
    </div>

    <div class="p-dialog-footer d-flex justify-content-end gap-2 mt-4">
      <div class="btn-cancel">
        <p-button
          [attr.data-cy]="'cancel-button'"
          type="button"
          label="Cancel"
          class="custom-p-button custom-p-button--primary"
          (click)="op.toggle($event)"
        ></p-button>
      </div>
      <div>
        <p-button
          [attr.data-cy]="'apply-button'"
          type="button"
          label="Apply"
          class="custom-p-button custom-p-button--secondary"
          (click)="executeFilter(); op.toggle($event)"
        ></p-button>
      </div>
    </div>
  </p-overlayPanel>
</div>
<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>