<div class="confirm-create-dialog">
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <p-dialog
      [header]="informationDialog.header"
      [(visible)]="visible"
      [modal]="true"
      (onHide)="hidePopup()"
    >
      <div>
        <div class="mb-2">
          <span class="form-label">{{ informationDialog.message + " " }}</span>
          <span class="p-error">*</span>
        </div>
        <input
          formControlName="name"
          type="text"
          pInputText
          class="input-text-new-chapter mb-2 custom-p-input"
          [(ngModel)]="informationDialog.valueUpdated"
          [placeholder]="'Enter ' + informationDialog.message.toLowerCase()"
          TextInputValidate
          typeCheck="short"
        />
        <ng-container *ngIf="form.status === 'INVALID'">
          <small
            class="p-error block"
            *ngIf="
              form.get('name')?.errors?.required && form.get('name')?.dirty
            "
          >
            {{ informationDialog.message + " is required" }}
          </small>
          <small
            class="p-error block"
            *ngIf="
              form.get('name')?.errors?.blank &&
              !form.get('name')?.errors?.required &&
              form.get('name')?.dirty
            "
          >
            {{ informationDialog.message + " is required" }}
          </small>
          <small
            class="p-error block"
            *ngIf="
              !form.get('name')?.errors?.blank &&
              !form.get('name')?.errors?.required &&
              form.get('name')?.errors?.duplicated &&
              form.get('name')?.dirty
            "
          >
            {{ informationDialog.message + " is duplicated" }}
          </small>
          <small
            class="p-error block"
            *ngIf="
              !form.get('name')?.errors?.duplicated &&
              form.get('name')?.errors?.maxlength &&
              form.get('name')?.dirty
            "
          >
            {{ "Value must be no more than " + 50 + " characters" }}
          </small>
        </ng-container>
      </div>
      <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end gap-2">
          <p-button
            [attr.data-cy]="'cancel-button'"
            label="Cancel"
            (onClick)="hidePopup()"
            class="custom-p-button custom-p-button--primary"
          ></p-button>
          <p-button
            type="submit"
            [attr.data-cy]="'save-button'"
            [label]="'Save'"
            class="custom-p-button custom-p-button--secondary"
            [ngClass]="
              form.status === 'VALID' || isViewer ? '' : 'custom-p-button custom-p-button--disable'
            "
            [disabled]="form.status === 'INVALID' || isViewer "
          ></p-button>
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>
