import { BaseComponent } from 'src/app/shared/components/base.component';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  Inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { takeUntil, filter, Subject } from 'rxjs';
import { HomeService } from 'src/app/shared/services/home.service';
import * as XLSX from 'xlsx';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { AzureAdService } from 'src/app/shared/services/azure-ad.service';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  constructor(
    private _homeService: HomeService,
    private fb: FormBuilder,
    private eleRef: ElementRef,
    private renderer2: Renderer2,
    //MSAL
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private azureAdService: AzureAdService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router
  ) {
    super();
  }

  //MSAL
  private readonly _destroy = new Subject<void>();
  isUserLoggedIn: boolean = false;
  AppConstant = AppConstant;
  login_Azure(userName: string) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout_Azure() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.logoutUrl,
    });
  }

  onDestroy(): void {
    //MSAL
    this._destroy.next(undefined);
    this._destroy.complete();
    //MSAL
  }

  loginForm!: FormGroup;

  public fieldNameKeys: any = {
    username: 'username',
  };
  public messageErrors: any = {
    required: 'Please fill in the information.',
    mailValidator: 'Please fill in the correct email syntax.',
    // maxlength: 'Please enter no more than 50 characters.',
    whitespace: 'Please fill in the information without white space.',
  };

  private emailRegex: string = `^(?!\.*?[\._-]{2})([^-_\.]\.*[^-_\.])[a-zA-Z0-9_\.-]{1,}@[A-Za-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$`;
  // private emailRegex: string = `^(?!\\.)(?!.*\\.$)(?!.*\\.\\.)[a-zA-Z0-9.]{3,}@[A-Za-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$`;
  // private emailRegex: string = `^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`;
  // private emailRegex: string = `^[\\w-\\.]+@[A-Za-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$`;
  // private emailRegex: string = `^[\\w-\\.]+@[A-Za-z0-9]{2,}(\.(com))$`;

  onInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {});

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.isUserLoggedIn =
          this.authService.instance.getAllAccounts().length > 0;
        this.azureAdService.isUserLoggedIn.next(this.isUserLoggedIn);

        if (this.isUserLoggedIn && this.router.url === '/login') {
          this.router.navigate(['/home']);
        }
      });

    this.buildForm();
  }

  private buildForm() {
    this.loginForm = this.fb.group({
      username: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.customCorrectField(
            this.fieldNameKeys,
            this.emailRegex,
            'username',
            'mailValidator'
          ),
          // Validators.maxLength(50),
        ],
      ],
    });
  }

  onSubmit(form: FormGroup): void {
    const userName = form.get('username')!.value;
    this.login_Azure(userName);
    // this.showSuccess(this.data);
  }

  // (click)="makeMail('thanh.nguyencong@halliburton.com', 'One', 'Two')"
  makeMailMsOutlook(
    emailAddress: string,
    subjectMail: string,
    bodyContent: string
  ) {
    const outlookUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(
      emailAddress
    )}&subject=${encodeURIComponent(subjectMail)}&body=${encodeURIComponent(
      bodyContent
    )}`;
    // Use Outlook web app if Outlook app is not installed
    window.open(outlookUrl, '_blank');
  }

  private focusElementInvalid() {
    const inputToFocus = document.querySelectorAll('input.ng-invalid');
    for (let i = 0; i < inputToFocus.length; i++) {
      const input = inputToFocus[i] as HTMLElement;
      input.focus();
      return;
    }
  }

  // This function using for custom validators
  private customCorrectField(
    fieldNameKeys: any,
    regex: string,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;
        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        const reg = new RegExp(regex);
        //
        //

        let currentValue = currentControl?.value
          ? currentControl?.value.toString()
          : '';

        //
        if (reg.test(currentValue ? currentValue : '')) {
          return null;
        }
        return { [keyValidator]: true };
      }
      return null;
    };
  }

  // Because when dont have any chareacter another error message of required will show
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    //
    formGroupName.controls[controlName].patchValue(control?.value.trim());
  }
}
