import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HelpCenterService } from '../help-center.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { switchMap } from 'rxjs';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { TutorialService } from 'src/app/shared/services/tutorial.service';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
})
export class CreateDialogComponent implements OnInit {
  form!: FormGroup;
  visible: boolean = false;
  @Input() informationDialog: any;
  @Input() isViewer: boolean = false;
  @Output() onDestroy = new EventEmitter();
  @Output() completed = new EventEmitter();

  constructor(
    private _helpCenterService: HelpCenterService,
    private _tutorialService: TutorialService,
    private _collapseLayoutService: CollapseLayoutService, 
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  hidePopup() {
    this.onDestroy.emit();
  }

  submitForm() {
    switch (this.informationDialog.type) {
      case 'CREATE_CHAPTER': {
        if (this.form.status === 'VALID') {
          // this._helpCenterService.subjectSetLoadding.next(true);
          const id = this.informationDialog.itemSlected.categoryId;

          // (A) CREATE BLOB OBJECT
          const myBlob = new Blob([''], {
            type: 'text/plain',
          });
          const file = new File([myBlob], 'description.txt', {
            type: 'text/plain',
          });
          const dataChapter = new FormData();
          dataChapter.append('categoryId', id);
          dataChapter.append(
            'chapterName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          dataChapter.append('description', file);
          this._helpCenterService
            .createChapter(dataChapter)
            .pipe(
              switchMap((response) => {
                if (!this.informationDialog.indexArraySubChapterMaster[id]) {
                  this.informationDialog.indexArraySubChapterMaster[id] =
                    this.informationDialog.indexArraySubChapterOption;
                }
                this.informationDialog.indexArraySubChapterMaster[id].push(
                  response.data.chapterId
                );
                return this._helpCenterService.updateIndexCategory({
                  indexSubChapter: btoa(
                    JSON.stringify(
                      this.informationDialog.indexArraySubChapterMaster
                    )
                  ),
                });
              })
            )
            .subscribe({
              next: () => {
                this.completed.emit(
                  `${this.form.controls[
                    'name'
                  ].value.trim()} was created successfully!`
                );
              },
              error: () => {},
              complete: () => {},
            });
        }
        break;
      }
      case 'RENAME_CHAPTER': {
        if (this.form.status === 'VALID') {
          // this._helpCenterService.subjectSetLoadding.next(true);
          const id = this.informationDialog.itemSlected.chapterId;
          const dataChapter = new FormData();
          dataChapter.append(
            'chapterName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          this._helpCenterService.updateChapter(id, dataChapter).subscribe({
            next: () => {
              // Trigger Get New Tutorial Panel
              if(this._collapseLayoutService.getCollapsePanelLayout()) {
                this._collapseLayoutService.triggerResetTutorialPanel();
              }
              this.completed.emit(`This subchapter was renamed successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      }
      case 'RENAME_CATEGORY_TUTORIAL': {
        if (this.form.status === 'VALID') {
          const id = this.informationDialog.itemSlected.categoryId;
          const dataCategory = new FormData();
          dataCategory.append(
            'categoryName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          this._helpCenterService.updateCategory(id, dataCategory).subscribe({
            next: () => {
              this.completed.emit(`This module was renamed successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      }
      case 'UPDATE_ICON_CATEGORY_TUTORIAL': {
        if (this.form.status === 'VALID') {
          const id = this.informationDialog.itemSlected.categoryId;
          const dataCategory = new FormData();
          dataCategory.append(
            'iconCode',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          this._helpCenterService.updateCategory(id, dataCategory).subscribe({
            next: () => {
              this.completed.emit(`This module was updated icon successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      }
      case 'RENAME_CATEGORY': {
        if (this.form.status === 'VALID') {
          // this._helpCenterService.subjectSetLoadding.next(true);
          const id = this.informationDialog.itemSlected.categoryId;
          const dataCategory = new FormData();
          dataCategory.append(
            'categoryName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          this._helpCenterService.updateCategory(id, dataCategory).subscribe({
            next: () => {
              this.completed.emit(`This chapter was renamed successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      }
      case 'RENAME_CHANNEL': {
        if (this.form.status === 'VALID') {
          // this._helpCenterService.subjectSetLoadding.next(true);
          const id = this.informationDialog.itemSlected.categoryId;
          const dataChanel = new FormData();
          dataChanel.append(
            'categoryName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );

          this._helpCenterService.updateCategory(id, dataChanel).subscribe({
            next: () => {
              this.completed.emit(`This channel was renamed successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      }
      case 'CREATE_TOPIC': {
        if (this.form.status === 'VALID') {
          // this._helpCenterService.subjectSetLoadding.next(true);
          const id = this.informationDialog.itemSlected.categoryId;

          // (A) CREATE BLOB OBJECT
          const myBlob = new Blob([''], {
            type: 'text/plain',
          });
          const file = new File([myBlob], 'description.txt', {
            type: 'text/plain',
          });
          const dataTopic = new FormData();
          dataTopic.append('categoryId', id);
          dataTopic.append(
            'topicName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          dataTopic.append('description', file);
          this._tutorialService
            .createTopic(dataTopic)
            .pipe(
              switchMap((response) => {
                if (!this.informationDialog.indexArraySubChapterMaster[id]) {
                  this.informationDialog.indexArraySubChapterMaster[id] =
                    this.informationDialog.indexArraySubChapterOption;
                }
                this.informationDialog.indexArraySubChapterMaster[id].push(
                  response.data.chapterId
                );
                return this._helpCenterService.updateIndexCategory({
                  indexSubChapter: btoa(
                    JSON.stringify(
                      this.informationDialog.indexArraySubChapterMaster
                    )
                  ),
                });
              })
            )
            .subscribe({
              next: () => {
                if(this._collapseLayoutService.getCollapsePanelLayout()) {
                  this._collapseLayoutService.triggerResetTutorialPanel();
                }
                this.completed.emit(
                  `${this.form.controls[
                    'name'
                  ].value.trim()} was created successfully!`
                );
              },
              error: () => {},
              complete: () => {},
            });
        }
        break;
      }
      case 'RENAME_TOPIC': {
        if (this.form.status === 'VALID') {
          const id = this.informationDialog.itemSlected.topicId;
          const dataTopic = new FormData();
          dataTopic.append(
            'topicName',
            AppHelper.UtileFunctions.encodeBase64(
              this.form.controls['name'].value.trim()
            )
          );
          this._tutorialService.updateTopic(id, dataTopic).subscribe({
            next: () => {
              // Trigger Get New Tutorial Panel
              if(this._collapseLayoutService.getCollapsePanelLayout()) {
                this._collapseLayoutService.triggerResetTutorialPanel();
              }
              this.completed.emit(`This topic was renamed successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      }
      case 'HIDE_TOPIC': {
        const id = this.informationDialog.itemSlected.topicId;
        const dataTopic = new FormData();
        dataTopic.append('isHide','true');
          this._tutorialService.updateTopic(id, dataTopic).subscribe({
            next: () => {
              // Trigger Get New Tutorial Panel
              if(this._collapseLayoutService.getCollapsePanelLayout()) {
                this._collapseLayoutService.triggerResetTutorialPanel();
              }
              this.completed.emit(`This topic was hided successfully!`);
            },
            error: () => {},
            complete: () => {},
          });
        }
        break;
      case 'SHOW_TOPIC': {
        const id = this.informationDialog.itemSlected.topicId;
        const dataTopic = new FormData();
        dataTopic.append('isHide','false');
        this._tutorialService.updateTopic(id, dataTopic).subscribe({
          next: () => {
            // Trigger Get New Tutorial Panel
            if(this._collapseLayoutService.getCollapsePanelLayout()) {
              this._collapseLayoutService.triggerResetTutorialPanel();
            }
            this.completed.emit(`This topic was showed successfully!`);
          },
          error: () => {},
          complete: () => {},
        });
      }
      break;
      case '': {
        break;
      }
    }
    this.visible = false;
  }

  initForm() {
    this.form = this.fb.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          this.validatorBlank,
          this.validatorDuplicated(
            this.informationDialog.arrayNameValidator
          ).bind(this),
        ]),
      ],
    });
  }

  validatorBlank(control: AbstractControl) {
    if (control.value.trim() === '') {
      return { blank: true };
    } else {
      return null;
    }
  }

  validatorDuplicated(arrayNameValidator: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const dupplicated = arrayNameValidator.find(
        (item: any) =>
          item.trim().toLowerCase() === control.value.trim().toLowerCase()
      );
      return dupplicated !== undefined ? { duplicated: true } : null;
    };
  }
}
