import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntil, tap } from 'rxjs';

import { BaseComponent } from 'src/app/shared/components/base.component';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import {
  ContactMethod,
  DataEdit,
} from 'src/app/shared/interface/rig.interface';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { EVIDENCE_TYPE_PROCESS } from 'src/app/shared/enum';
import { IMessage } from 'src/app/shared/interface/common';
import { PDFDocument } from 'pdf-lib';
import deepCompare from 'src/app/shared/utilities/deep-compare';
import { capitalize } from 'lodash';


const MAXIMUN_NUMBER_FILE_UPLOAD = 5;

@Component({
  selector: 'app-alert-form-resolve',
  templateUrl: './intervention-form-resolve.component.html',
  styleUrls: ['./intervention-form-resolve.component.scss'],
})
export class AlertFormResolveComponent extends BaseComponent {
  //
  // @Input() flagColor: any;
  private _flagColor: any[];
  @Input()
  set flagColor(value: any[]) {
    const resolveData = this.resolveDataEdit;
    this._flagColor = value;

    // Setting isMissingDistri
    if (!this._flagColor?.length) {
      this.isMissingDistribution = true;
    } else {
      this.isMissingDistribution = false;
    }

    // Reassign to get latest value // Swap Edit Alert Case
    this.distributionListofRig = this._flagColor;

    // Setting Color Flag
    let colorSelected;
    let flag = resolveData?.sendNotificationOfResolutionTotheSameDistribution;
    if (flag !== 'yes' && flag !== null) {
      colorSelected = this.flagColor.find((flagItem: any) => {
        this.selectedFlagPurpose =
          flagItem.code ===
            flag
            ? flagItem.purpose
            : '';
        return flagItem.code === flag;
      });
    }

    if (flag !== "no" && flag !== "" && flag !== null && !colorSelected && !this.flagColor.length) {
      colorSelected = {
        code: flag,
        name: capitalize(flag),
        purpose: capitalize(flag)
      }
      this.distributionListofRig = [colorSelected];
    }
    if (flag === null) {
      this.formResolveGroup.get('anotherWhereSendNotification')?.setValue(null);
      this.selectedFlagPurpose = ""
    } else {
      this.formResolveGroup.get('anotherWhereSendNotification')?.setValue(colorSelected);
    }
  }

  get flagColor(): any[] {
    return this._flagColor;
  }

  // For contact methob dropdown
  // @Input() resolveContactMethod!: ContactMethod[];
  private _resolveContactMethod!: ContactMethod[];
  @Input()
  set resolveContactMethod(value: any[]) {
    const drafSelect: any[] = this.formResolveGroup.get('contactMethod')?.value;
    this._resolveContactMethod = value;

    // Setting isMissingDistri
    if (!this._resolveContactMethod?.length) {
      this.contactMethodList = AppConstant.CONTACT_METHOD_WITHOUT_PHONE;
    } else {
      this.contactMethodList = AppConstant.CONTACT_METHOD;
      this.resetPartialSelected(this.contactMethodList);
    }

    /// setting
    if (this.flagColor?.length && this.resolveContactMethod && this.resolveContactMethod?.length) {
      this.contactMethodList[0].children = this.resolveContactMethod.map(
        (contact: any) => {
          return {
            contact_id: contact.ORDER,
            label: `${contact.ORDER} - ${contact.TITLE}`,
            data: contact.PHONE || 'N/A',
          };
        }
      );
    }

    let contactData: any[] = [];
    // Setting Selected Contacts
    if (drafSelect.length) {
      const contactDataObject = [...drafSelect];
      //

      let found,
        index_lv_1 = 0,
        index_lv_2 = 0;
      for (
        let index_ContactData = 0;
        index_ContactData < contactDataObject.length;
        index_ContactData++
      ) {
        if (contactDataObject[index_ContactData].id) {
          let anotherContact = this.contactMethodList.find((contact) => {
            return contact.id === contactDataObject[index_ContactData].id;
          });
          anotherContact && contactData.push(anotherContact);
        } else if (contactDataObject[index_ContactData].contact_id) {
          found = this.contactMethodList[index_lv_1]?.children?.find(
            (item: any, i_Lv2: any) => {
              index_lv_1 = 0;
              index_lv_2 = i_Lv2;
              return (
                item.contact_id ===
                contactDataObject[index_ContactData].contact_id
              );
            }
          );

          if (found) {
            contactData.push(
              this.contactMethodList[index_lv_1].children[index_lv_2]
            );
          }
        }
      }
      this.setContactMethodString(contactData);
    }
    
    this.formResolveGroup.get('contactMethod')?.setValue(contactData);
    // end setting
  }
  get resolveContactMethod(): any[] {
    return this._resolveContactMethod;
  }

  @Input() resolveDataEdit!: DataEdit;

  @Input() alertIdEdit!: string;

  // ReadOnly Mode
  @Input() readOnly: boolean = false;

  // Contact Method
  @ViewChild('treeContactMethod') treeContactMethod: any;

  // viewChild for counter amount upload
  @ViewChild('fileUploader') fileUploader: any;
  // Variable used for upload picture
  uploadedFiles: any[] = [];
  maxFileSizeUpload: number = 10485760;

  @Output() onChange = new EventEmitter<{ originalValues: any, currentValues: any, hasChange: boolean }>();
  originalFormValues: any = {};

  // Title for Upload Component
  UploadTitle = {
    choose: 'Add files',
    upload: 'Paste from Clipboard',
    cancel: 'Clear All',
  };
  isOnDrag = false;
  isLoadingFileEvidence: boolean = false;

  contactMethodList: any[] = AppConstant.CONTACT_METHOD;
  selectedNodeStringContact: string = '';

  // recommendedActions
  recommendedActions: any[] = [];
  selectedRecommendedAction: string[] = [];

  // Resolution
  notificationResolution: any[] = [];
  selectedNotificationResolution: any[] = [];

  // Send Notification if resolution to same distribution
  sendNotificationToDistribution: any[] = [
    { name: 'Yes', key: 'yes_sendNotification' },
    { name: 'DropdownList', key: 'another_distribution' },
  ];

  selectedWhereSendNotification: any[] = [
    this.sendNotificationToDistribution[0].key,
  ];
  distributionListofRig: any[] = [];
  anotherWhereSendNotification: any[] = [];

  // Variable Binding value

  // Message for Error
  public messageErrors: any = {
    required: 'Please fill in the information.',
    maxlength: 'Please enter no more than 2000 characters.',
    whitespace: 'Please fill in the information without white space.',
    twoDigitValidator:
      'Please enter bitMD decimal (2 digits) and no more than 20 characters',
    invalidSpecialField: 'Please enter a value less than or equal to 500.',
    limitLine: 'Please enter no more than 15 lines.',
  };

  // Key of form
  public fieldNameKeys = {
    contactMethod: 'contactMethod',
    recommendedActions: 'recommendedActions',
    resolution: 'resolution',
    notificationResolution: 'notificationResolution',
    timeSaved: 'timeSaved',
    evidence: 'evidence',
    selectedWhereSendNotification: 'selectedWhereSendNotification',
    anotherWhereSendNotification: 'anotherWhereSendNotification',
  };

  // Variable of Form
  public formResolveGroup: FormGroup = this._builder.group({
    [this.fieldNameKeys.contactMethod]: [''],
    [this.fieldNameKeys.recommendedActions]: [''],
    [this.fieldNameKeys.resolution]: [''],
    [this.fieldNameKeys.notificationResolution]: [''],
    [this.fieldNameKeys.timeSaved]: [''],
    [this.fieldNameKeys.evidence]: [''],
    [this.fieldNameKeys.selectedWhereSendNotification]: [''],
    [this.fieldNameKeys.anotherWhereSendNotification]: [''],
  });

  // regex for custom validate
  private twoDigitRegex: string = `^(?=[0-9.]{0,20}$)[0-9]+(?:\.[0-9]{0,2})?$`;
  selectedFlagPurpose: string = '';

  // Variable using in flags
  isMissingDistribution: boolean = false;

  constructor(
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _blobService: AzureBlobService) {
    super();
  }

  onInit(): void {
    this.initData(this.resolveDataEdit);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resolveDataEdit']?.currentValue) {
      this.isLoadingFileEvidence = false;
      this.initData(changes['resolveDataEdit']?.currentValue);
      this.originalFormValues = this.formResolveGroup.getRawValue();
    }
  }

  initData(resolveDataEdit: any) {
    if (this.flagColor && this.contactMethodList[0]?.children) {
      if (this.flagColor.length && this.resolveContactMethod && this.resolveContactMethod.length) {
        this.contactMethodList[0].children = this.resolveContactMethod.map(
          (contact: any) => {
            return {
              contact_id: contact.ORDER,
              label: `${contact.ORDER} - ${contact.TITLE}`,
              data: contact.PHONE || 'N/A',
            };
          }
        );
      }
    }
    this.flagColor = this.flagColor;

    // Initiates value of Contact Method List
    this.recommendedActions = [
      { name: 'Yes', key: 'yes' },
      { name: 'No', key: 'no' },
      { name: 'N/A', key: 'not-applicable' },
    ];

    this.notificationResolution = [
      { name: 'Yes', key: 'yes' },
      { name: 'No', key: 'no' },
    ];

    this.distributionListofRig = this.flagColor;

    if (resolveDataEdit && resolveDataEdit.contactMethod) {
      this.buildFormEditResolve(
        resolveDataEdit,
        this.alertIdEdit,
        this.readOnly
      );
    } else {
      // After fill and create data for form we need build the form
      this.buildForm(this.readOnly);
    }
    // Handle Select "Send notification of resolution to the same distribution"
    this.formResolveGroup
      .get('selectedWhereSendNotification')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          //

          const anotherWhereSendNotification = this.formResolveGroup.get(
            'anotherWhereSendNotification'
          );

          addCtrlValue[0] === 'another_distribution'
            ? anotherWhereSendNotification?.enable()
            : anotherWhereSendNotification?.disable();
        })
      )
      .subscribe();

    this.formResolveGroup.valueChanges.subscribe(() => {
      this.emitChange(this.originalFormValues, this.formResolveGroup.getRawValue())
    })
  }

  emitChange(originalValues: any, currentValues: any) {
    this.onChange.emit({ originalValues, currentValues, hasChange: !deepCompare(originalValues, currentValues) })
  }

  buildForm(isReadOnlyMode: boolean) {
    this.formResolveGroup = this._builder.group({
      [this.fieldNameKeys.contactMethod]: [
        { value: [], disabled: isReadOnlyMode },
        [Validators.required],
      ],
      [this.fieldNameKeys.recommendedActions]: [
        { value: this.selectedRecommendedAction, disabled: isReadOnlyMode },
        Validators.required,
      ],
      [this.fieldNameKeys.resolution]: [
        { value: '', disabled: isReadOnlyMode },
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.lineValidator(15)
        ],
      ],
      [this.fieldNameKeys.timeSaved]: [
        { value: '', disabled: isReadOnlyMode },
        [this.customMaxValue(500)],
      ],
      [this.fieldNameKeys.notificationResolution]: [
        { value: this.selectedNotificationResolution, disabled: isReadOnlyMode },
        Validators.required,
      ],
      [this.fieldNameKeys.evidence]: [
        {
          value: null,
          disabled: isReadOnlyMode,
        },
      ],
      [this.fieldNameKeys.selectedWhereSendNotification]: [
        { value: this.selectedWhereSendNotification, disabled: isReadOnlyMode },
        [Validators.required],
      ],
      [this.fieldNameKeys.anotherWhereSendNotification]: [
        { value: '', disabled: true },
        [Validators.required],
      ],
    });
    this.selectedNodeStringContact = '';
    this.onClearFiles();
  }

  buildFormEditResolve(
    resolveData: any,
    interventionId: string,
    isReadOnlyMode: boolean
  ) {

    // Setting isMissingDistri
    if (!this.flagColor.length) {
      this.isMissingDistribution = true;
      this.resetContactList();
    }

    let contactData: any[] = [];
    // Setting Selected Contacts
    if (resolveData.contactMethod) {
      const contactDataObject = [...resolveData.contactMethod];
      //

      let found,
        index_lv_1 = 0,
        index_lv_2 = 0;
      for (
        let index_ContactData = 0;
        index_ContactData < contactDataObject.length;
        index_ContactData++
      ) {
        if (contactDataObject[index_ContactData].id) {
          let anotherContact = this.contactMethodList.find((contact) => {
            return contact.id === contactDataObject[index_ContactData].id;
          });
          anotherContact && contactData.push(anotherContact);
        } else if (contactDataObject[index_ContactData].contact_id) {
          found = this.contactMethodList[index_lv_1]?.children?.find(
            (item: any, i_Lv2: any) => {
              index_lv_1 = 0;
              index_lv_2 = i_Lv2;
              return (
                item.contact_id ===
                contactDataObject[index_ContactData].contact_id
              );
            }
          );

          if (found) {
            contactData.push(
              this.contactMethodList[index_lv_1].children[index_lv_2]
            );
          }
        }
      }
      this.setContactMethodString(contactData);
    }

    // Setting Color Flag
    let colorSelected;
    let flag = resolveData.sendNotificationOfResolutionTotheSameDistribution;
    if (flag !== 'yes') {
      colorSelected = this.flagColor.find((flagItem: any) => {
        this.selectedFlagPurpose =
          flagItem.code ===
            flag
            ? flagItem.purpose
            : '';
        return flagItem.code === flag;
      });
    }

    if (flag != "no" && flag != "" && !colorSelected && !this.flagColor.length) {
      colorSelected = {
        code: flag,
        name: capitalize(flag),
        purpose: capitalize(flag)
      }
      this.distributionListofRig = [colorSelected];
    }

    this.formResolveGroup = this._builder.group({
      [this.fieldNameKeys.contactMethod]: [
        { value: contactData, disabled: isReadOnlyMode },
        [Validators.required],
      ],
      [this.fieldNameKeys.recommendedActions]: [
        {
          value: [resolveData.isRecommendedActionTaken],
          disabled: isReadOnlyMode,
        },
        Validators.required,
      ],
      [this.fieldNameKeys.resolution]: [
        { value: resolveData.resolution, disabled: isReadOnlyMode },
        [
          Validators.required,
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.lineValidator(15)
        ],
      ],
      [this.fieldNameKeys.timeSaved]: [
        { value: resolveData.timeSaved, disabled: isReadOnlyMode },
        [this.customMaxValue(500)],
      ],
      [this.fieldNameKeys.notificationResolution]: [
        {
          value: resolveData.isNotificationOfResolutionRequired
            ? ['yes']
            : ['no'],
          disabled: isReadOnlyMode,
        },
        Validators.required,
      ],
      [this.fieldNameKeys.selectedWhereSendNotification]: [
        {
          value: resolveData.sendNotificationOfResolutionTotheSameDistribution
            ? ['another_distribution']
            : ['yes_sendNotification'],
          disabled: isReadOnlyMode,
        },

        [Validators.required],
      ],
      [this.fieldNameKeys.evidence]: [
        {
          value: this?.uploadedFiles,
          disabled: isReadOnlyMode,
        },
      ],
      [this.fieldNameKeys.anotherWhereSendNotification]: [
        {
          value: colorSelected,
          disabled:
            isReadOnlyMode ||
              resolveData.sendNotificationOfResolutionTotheSameDistribution ===
              'yes'
              ? true
              : false,
        },
        [Validators.required],
      ],
    });

    if (resolveData?.evidences?.length > 0) {
      const listEvidence = AppHelper.UtileFunctions.filterEvidenceByType(resolveData.evidences, EVIDENCE_TYPE_PROCESS.RESOLVE);

      const promiseAll: Promise<Blob>[] = [];
      for (const evidence of listEvidence) {
        // Evidence have process is initiate or null, will exists
        if (!evidence?.typeProcess || evidence?.typeProcess === EVIDENCE_TYPE_PROCESS.INITIATE) return;

        this.isLoadingFileEvidence = true;
        const blod = this._blobService.downloadImageEvidence(
          decodeURIComponent(
            AppHelper.StringFunctions.getFileName(evidence.evidenceUrl),
          ));
        promiseAll.push(blod);
      }

      Promise.all(promiseAll).then((values: Blob[]) => {
        if (values.length === listEvidence.length) {
          const fileDownload: File[] = [];
          values.forEach((blob: Blob, index: number) => {
            const fullFileName = decodeURIComponent(listEvidence[index].evidenceName)
              || AppHelper.StringFunctions.getFileName(listEvidence[index].evidenceUrl).slice(14);
            fileDownload.push(
              new File([blob], fullFileName, {
                type:
                  AppHelper.StringFunctions.getExtension(fullFileName) !== 'pdf'
                    ? `image/${AppHelper.StringFunctions.getExtension(
                      fullFileName
                    )}`
                    : 'application/pdf',
              })
            );
          })
          this.uploadedFiles = [...fileDownload];
          this.isLoadingFileEvidence = false;
        } else {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Download file have problem',
            content: 'Please refresh page.',
          });
        }
      }).catch(() => {
        this.isLoadingFileEvidence = false;
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Download file have problem',
          content: 'Please input again.',
        });
      });
    }
  }

  setContactMethodString(contactData: any[]) {
    let labelDisplay = '';
    let labelDisplayChild = '';
    let parentArray: any = [];
    let childArray: any = [];
    contactData.forEach((el) => {
      if (el?.styleClass === 'parent_') {
        parentArray.push(el);
      } else {
        childArray.push(el);
      }
    });

    childArray = childArray.sort(function (a: any, b: any) {
      var keyA = a.label,
        keyB = b.label;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    //

    let email = parentArray.find((el: any) => el.label === 'Email');
    let chat = parentArray.find((el: any) => el.label === 'Chat');

    childArray.forEach((item: any) => {
      labelDisplayChild = labelDisplayChild + (item?.contact_id || item?.id) + ', ';
    });
    labelDisplayChild = labelDisplayChild.slice(0, -2);
    labelDisplayChild = `Phone (${labelDisplayChild})`;

    if (childArray.length > 0) {
      labelDisplay = labelDisplayChild + ' | ' + labelDisplay;
    }
    if (email) {
      labelDisplay = labelDisplay + email.label + ' | ';
    }
    if (chat) {
      labelDisplay = labelDisplay + chat.label + ' | ';
    }
    this.selectedNodeStringContact = labelDisplay.slice(0, -2);
  }

  onSubmit() {
    if (this.isLoadingFileEvidence) {
      return { error: { value: true, type: 'WAITING_LOAD_DATA' } };
    }

    if (this.isMissingDistribution) {
      return { error: { value: true, type: 'MISSING_DISTRIBUTION_DATA' } };
    }

    this.formResolveGroup.markAllAsTouched();
    this.formResolveGroup.markAsDirty();
    this.formResolveGroup.updateValueAndValidity();
    const valueFormRaw = this.formResolveGroup.getRawValue();

    valueFormRaw.evidence = [...this.uploadedFiles || []];

    Object.keys(this.formResolveGroup.controls).map((controlName) => {
      const control = this.formResolveGroup.get(controlName);
      control?.markAsDirty();
      control?.markAllAsTouched();
      control?.updateValueAndValidity();
    });

    this.originalFormValues = this.formResolveGroup.getRawValue();
    this.emitChange(this.originalFormValues, this.originalFormValues);
    return this.formResolveGroup.valid
      ? valueFormRaw
      : { error: { value: true, type: 'INVALID' } };
  }

  // common funciona for single choose checkbox
  singleSelectCheckBox(controlName: string, formGroupName: FormGroup) {
    const me = this;
    let getValue = formGroupName.get(controlName)?.value;
    let lastestValue = getValue[getValue.length - 1];

    formGroupName.get(controlName)?.setValue([]);
    formGroupName.get(controlName)?.setValue([lastestValue]);

    if (lastestValue === undefined) {
      formGroupName.get(controlName)?.setValue([]);
    }
  }

  // Prevent '-', '+', 'e' in Input type="number"
  public inputNumber_Clear_e(event: any) {
    let invalidChars = ['-', '+', 'e', 'E'];

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }
  // Message when blur out text area and trim it.
  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    //
    formGroupName.controls[controlName].patchValue(
      AppHelper.StringFunctions.textTrimmingBeauty(control?.value.trim())
    );
  }

  // This function using for check space validators
  // if have more than 1 character and it all space, it will show error message
  // Because when dont have any chareacter another error message of required will show
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  public lineValidator(maxLine: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let value = (control.value || '');

      const arrSplit = value.split('\n');
      if (arrSplit.length <= maxLine)
        return null;

      return { limitLine: true };
    }
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formResolveGroup.get(controlName);

    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const controlValue = this.formResolveGroup.get(controlName)?.value;

    const errorObj = this.formResolveGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }
  public handlePasteTimeSaved(controlName: string, input: any) {
    let control = this.formResolveGroup.get(controlName);

    // get paste data
    let textPaste = input.clipboardData.getData('text/plain');

    // remove AZaz -+eE from paste data
    let finalPaste = textPaste.replace(/[^0-9.]+/g, '');

    // prevent paste to manual setValue to trigger transform Pipe estimateTimeDecimalToHHMM
    input.preventDefault();

    // split with "." in case paste data have 1 or more than 1 "." characters
    let splitNumber = finalPaste.split('.');

    // Take first ".", if final char is first "." then remove it.
    if (splitNumber.length > 1) {
      if (splitNumber[1] === '') {
        control?.setValue(splitNumber[0].slice(0, 10));
        return true;
      } else {
        let result =
          (splitNumber[0] === '' ? '0' : splitNumber[0]) +
          '.' +
          splitNumber[1].slice(0, 2);
        //
        control?.setValue(result);
        return true;
      }
    } else {
      // If dont have '.', we will take first 10 characters (in this case is number)
      control?.setValue(finalPaste.slice(0, 10));
      return true;
    }
  }

  onOptionsSelectedContact() {
    let selectedNodeContact = this.treeContactMethod.value;
    let labelDisplay = '';
    let labelDisplayChild = '';
    let parentArray: any = [];
    let childArray: any = [];

    selectedNodeContact.forEach((el: any) => {
      if (el.styleClass === 'parent_') {
        //
        parentArray.push(el);
      } else {
        childArray.push(el);
      }
    });

    childArray = childArray.sort(function (a: any, b: any) {
      var keyA = a.label,
        keyB = b.label;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    //

    let email = parentArray.find((el: any) => el.label === 'Email');
    let chat = parentArray.find((el: any) => el.label === 'Chat');

    childArray.forEach((item: any) => {
      labelDisplayChild = labelDisplayChild + item.contact_id + ', ';
    });
    labelDisplayChild = labelDisplayChild.slice(0, -2);
    labelDisplayChild = `Phone (${labelDisplayChild})`;

    if (childArray.length > 0) {
      labelDisplay = labelDisplayChild + ' | ' + labelDisplay;
    }
    if (email) {
      labelDisplay = labelDisplay + email.label + ' | ';
    }
    if (chat) {
      labelDisplay = labelDisplay + chat.label + ' | ';
    }
    this.selectedNodeStringContact = labelDisplay.slice(0, -2);

    this.formResolveGroup.get(this.fieldNameKeys.contactMethod)?.markAsTouched();
  }

  onClearEvent(event: any) {
    this.selectedNodeStringContact = '';
    this.formResolveGroup.get(this.fieldNameKeys.contactMethod)?.markAsTouched();
  }

  slectedFlag(event: any) {
    this.selectedFlagPurpose = event?.value?.purpose || '';
  }

  customMaxValue(limit: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedItems = control.value;
      if (selectedItems && selectedItems > limit) {
        return { invalidSpecialField: true };
      }
      return null;
    };
  }

  // Upload Picture and validate when select wrong type upload
  async onSelect(event: any) {
    const filesList: Array<File> = [...event.files];
    const MAXIMIN_TOTAL_FILES_SIZE = 10485760; // 10MB = 10485760bytes
    const listErr: IMessage[] = [];

    // Calculate total file size is uploaded
    let currentTotalFileSize =
      this.uploadedFiles?.reduce(
        (acc: number, file: File) => acc + file.size,
        0
      ) || 0;

    for (let i = 0; i < filesList.length; i++) {
      let file = filesList[i];

      // Verify duplicate
      const isDuplicated = this.uploadedFiles?.some((f: File) => {
        return f.name === file.name && f.size == file.size;
      });
      if (isDuplicated) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Duplicated file',
          content: 'Your uploading file is duplicated.',
        });
        // Remove file dublicate from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file type
      const mimeTypeAllow = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
      ];
      if (!mimeTypeAllow.includes(file.type.toLowerCase())) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid File Type`,
          content:
            'Invalid file type. Allowed file types: .pdf,.jpg, .jpeg, .png',
        });
        // Remove Invalid file type from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file name
      let formatSpecialChar = /[!()\[\]{}\\|<>\/?'"]+/;
      if (
        formatSpecialChar.test(
          AppHelper.StringFunctions.extractFileName(file.name)
        )
      ) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Detected: Invalid file name',
          content:
            "A file name can't contain any of the following characters: ! ' \" { } [ ] ( ) \\ / : ? < > | ",
        });
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 0 byte
      if (file.size == 0) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Empty file cannot be uploaded.',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size 10000000
      if (file.size > MAXIMIN_TOTAL_FILES_SIZE!) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Allowed maximum file size: 10MB',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      if (currentTotalFileSize + file.size > MAXIMIN_TOTAL_FILES_SIZE) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid total file size',
          content:
            'File make exceeded total allowed file size. Should be removed',
        });

        filesList.splice(i--, 1);
        continue;
      } else {
        currentTotalFileSize += file.size;
      }

      if (file.type.includes("image")) {
        try {
          let pdfDoc = await PDFDocument.create();
          const imgBuffer = await AppHelper.UtileFunctions.fileToArrayBuffer(file);

          let img = null;
          file.type === 'image/png'
            ? (img = await pdfDoc.embedPng(imgBuffer))
            : (img = await pdfDoc.embedJpg(imgBuffer));
        } catch {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Invalid file type',
            content: `File ${file.name} cannot be uploaded because of a mismatch between the file type and file name.`,
          });
          filesList.splice(i--, 1);
          continue;
        }
      }
    }

    // Verify file number
    if (
      this.uploadedFiles?.length + filesList?.length >
      MAXIMUN_NUMBER_FILE_UPLOAD
    ) {
      const numberFilesCanAdd =
        MAXIMUN_NUMBER_FILE_UPLOAD - this.uploadedFiles?.length;
      const numberFileRemoved = filesList.length - numberFilesCanAdd;

      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Exceeded allowed number of files`,
        content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
      });
      filesList.splice(numberFilesCanAdd, numberFileRemoved);
    }

    // Remove notification duplicate
    for (let i = 0; i < listErr.length; i++) {
      this._notificationService.setMessage(listErr[i]);
    }

    this.uploadedFiles = [...this.uploadedFiles, ...filesList] || [];
    this.formResolveGroup.get('evidence')?.setValue(this.uploadedFiles);
    this.formResolveGroup?.markAsDirty();
  }

  calculateAllottedSizeFile(): boolean | number {
    let currentTotalFileSizeUpload = 0;

    let counterOverLoad = 0;
    let errorFile;
    this.fileUploader!._files.forEach((file: any) => {
      if (currentTotalFileSizeUpload + file.size < this.maxFileSizeUpload) {
        counterOverLoad++;
        currentTotalFileSizeUpload += file.size;
      } else {
        errorFile = true;
      }
    });
    if (errorFile) return false;
    else return counterOverLoad;
  }

  // error all
  onError($event: any) { }

  async onPasteClipBoardFile(): Promise<void> {
    try {
      const clipboardItems: ClipboardItem[] = await navigator.clipboard.read();
      const blobOutput: Blob = await clipboardItems[0]?.getType('image/png');

      if (blobOutput) {
        const fileName = this.prepareFileName(this.uploadedFiles);

        const file: File = new File([blobOutput], fileName, {
          type: 'image/png',
        });
        if (file.size <= this.maxFileSizeUpload / 2) {
          if (
            this.uploadedFiles?.length >= MAXIMUN_NUMBER_FILE_UPLOAD
          ) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: `Exceeded allowed number of files`,
              content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
            });
          } else {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Clipboard',
              content: 'Pasted screenshot accepted.',
            });
            this.onSelect({ files: [file] });
          };
        } else {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Clipboard',
            content: 'Pasted screenshot is larger than 5MB.',
          });
        }
      }

    } catch (e) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Clipboard',
        content: 'Pasted screenshot is invalid.',
      });
    }
  }

  private prepareFileName(listFileExists: any[]): string {
    let number: number = 1;
    let nameString: string = '';
    let findName: boolean = false;
    while (!findName) {
      nameString = `pasted-screenshot-${number}.png`;
      const result = listFileExists.some((f: any) => f.name === nameString);
      if (result) {
        number++;
      } else {
        findName = !findName;
      }
    }

    return nameString;
  }

  // Remove All Picture
  onClearFiles() {
    if (!this.fileUploader?.el?.nativeElement) return;

    const clipBoardButton = this.fileUploader.el.nativeElement.querySelector(
      'div.p-fileupload-buttonbar > button:nth-child(2)'
    );

    if (clipBoardButton) {
      clipBoardButton.disabled = false;
      clipBoardButton.classList.remove('p-disabled');
    }

    this.fileUploader!._files = [];
    this.uploadedFiles = [];
    this.formResolveGroup.get('evidence')?.setValue([]);
  }

  // function help clear inline errorsMessage in p-upload component
  clearInlineErrorUpload() {
    var nodes = document.getElementsByTagName('p-messages');
    for (var i = 0, len = nodes.length; i != len; ++i) {
      nodes[0].parentNode?.removeChild(nodes[0]);
    }
  }

  // Click text when emtpy upload
  openSelectFileUpload() {
    if (!this.readOnly) {
      const uploader = document.querySelector(
        '.fileupload-resolve'
      ) as HTMLElement;
      const chooseBtn = uploader.querySelector(
        '.p-fileupload-choose'
      ) as HTMLElement;
      chooseBtn.click();
    }
  }

  // drag
  dragTime(e: any) {
    this.isOnDrag = true;
    if (this.isOnDrag) {
      this.uploadedFiles = [...this.fileUploader!._files] || [];
      this.formResolveGroup.get('evidence')?.setValue(this.uploadedFiles);
    }
  }

  // custom remove item picture
  removeItemUpload(index: number) {
    if (!this.readOnly) {
      this.fileUploader!._files.splice(index, 1);
      this.uploadedFiles = [...this.fileUploader!._files] || [];
      this.formResolveGroup.get('evidence')?.setValue(this.uploadedFiles);
    }
  }


  removeItemUploadWithName(name: string) {
    if (!name) return;

    const idx = this.uploadedFiles.findIndex((f: any) => {
      f.name === name;
    });
    idx && this.removeItemUpload(idx);
  }

  resetContactList() {
    this.resolveContactMethod = AppConstant.CONTACT_METHOD_WITHOUT_PHONE;
    this.contactMethodList = AppConstant.CONTACT_METHOD_WITHOUT_PHONE;
  }

  resetPartialSelected(option: any) {
    if (!option) return;

    for (let node of option) {
      node.partialSelected = false;
      if (node.partialSelected && node.children?.length > 0) 
        this.resetPartialSelected(node.children)
    }
  }

  onDestroy(): void { }
}
