<div class="alert-list">
  <app-module-header
    [backPortal]="false"
    backPortalLabel="Nothing Render Because backPortal === false"
    headerTitle="Alerts Management"
    [buttonFearture]="false"
    buttonFeartureLabel="Nothing Render Because buttonFearture === false"
    [bottomMargin]="true"
  ></app-module-header>

  <form #searchForm="ngForm">
    <div class="alert__filter alert-list__filter">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex align-items-center">
          <div class="d-flex">
            <div class="alert__filter-button alert__filter-button--dark">
              <app-alert-advanced-filter
                #advancedFilter
                (chipFilter)="loadChipFilter($event)"
                (filter)="loadPayloadFilter($event)"
                [alertListData]="restoreAlertListData"
              ></app-alert-advanced-filter>
            </div>
          </div>
          <div class="alert-list__filter--counter alert-list__filter--text">
            <span>Total: {{ alertListData.length || 0 }}</span>
          </div>
          <div class="chip-wrapper">
            <ng-container
              class="p-d-flex"
              *ngFor="let item of chipsFilter; index as i"
            >
              <p-chip
                label="{{ item }}"
                styleClass="chip-item"
                removeIcon="pi pi-times"
                [removable]="true"
                (onRemove)="removeItemChip(item, i)"
              ></p-chip>
            </ng-container>
          </div>

          <div class="clear-button">
            <p
              class="cursor-pointer button-highlight alert-list__filter--text"
              (click)="clearFilter()"
            >
              Clear all
            </p>
          </div>
        </div>
        <div class="header-feature-button">
          <p-button class="button-left" label="Export"></p-button>
          <p-button
            class="button-right"
            type="button"
            icon="pi pi-chevron-down"
            [disabled]="false"
            (onClick)="menuHeader.toggle($event)"
          ></p-button>
          <p-menu
            #menuHeader
            [popup]="true"
            [model]="exportListFunc"
            styleClass="sm-width-menu auto-item"
          ></p-menu>
        </div>
      </div>
    </div>

    <p-table
      [value]="alertListData"
      [columns]="alertListColumn"
      [scrollable]="true"
      [resizableColumns]="true"
      styleClass="p-datatable-striped custom-table"
      (sortFunction)="onSort($event)"
      [customSort]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 40px">
          <th
            *ngFor="
              let col of columns;
              last as isLast;
              first as isFirst;
              let i = index
            "
            [pSortableColumn]="col.field"
            pResizableColumn
            [ngClass]="{
              'th-first-left': isFirst,
              'th-document-small': i === 1 || i === 2 || i === 3,
              'th-document-medium': i === 5 || i === 6 || i === 7 || i === 9,
              'th-document-large': i === 14,
              'th-default':
                i !== 0 && i !== 5 && i !== 6 && i !== 7 && i !== 9 && i !== 14 && i !== 1 && i !== 2 && i !== 3
            }"
            alignFrozen="left"
            pFrozenColumn
            [frozen]="isFirst"
          >
            <ng-container *ngIf="!isFirst; then showThNotLast; else showThLast">
            </ng-container>

            <ng-template #showThNotLast>
              <div
                [pTooltip]="col.header"
                tooltipPosition="bottom"
                class="th-content"
              >
                {{ col.header }}
              </div>
              <div class="header-sort-icon">
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                ></i>
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                ></i>
              </div>
            </ng-template>
            <ng-template #showThLast></ng-template>
          </th></tr
      ></ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr
          (click)="
            editIntervention(
              $event,
              rowData.additionalData.rigJournalId,
              rowData.interventionId
            )
          "
        >
          <td
            class="td-option"
            alignFrozen="left"
            pFrozenColumn
            [frozen]="true"
            data-excluded
          >
            <div class="more-option-table" data-excluded>
              <svg-icon
                class="header-content__title--feature"
                key="dot3hoz"
                size="md"
                (click)="showContextMenu($event, rowData)"
              >
              </svg-icon>
            </div>
          </td>
          <td
            class="td-default capitalize"
            [pTooltip]="rowData.eventType"
            tooltipPosition="bottom"
            style="min-width: 140px"
          >
            <span>
              {{ rowData.eventType }}
            </span>
          </td>
          <td
            class="td-default"
            [pTooltip]="rowData.initiated | format24h"
            tooltipPosition="bottom"
            style="min-width: 140px"
          >
            <span>
              {{ rowData.initiated | format24h }}
            </span>
          </td>
          <td 
            class="td-default" 
            style="min-width: 140px"
          >
            <span
              [pTooltip]="rowData?.resolved | format24h"
              tooltipPosition="bottom"
            >
              {{ rowData?.resolved | format24h }}
            </span>
          </td>
          <td
            class="text-overflow-style td-default"
            [ngClass]="rowData.classColor"
            [pTooltip]="rowData.stateMessage"
            tooltipPosition="bottom"
            style="min-width: 140px"
          >
            <div class="d-flex">
              <div>
                {{ rowData.status }}
              </div>
              <div style="margin-top: -2px; padding-left: 8px">
                <svg-icon
                  [key]="
                    ['I_0_1', 'R_1_1', 'I_1_1'].includes(rowData.stateCode)
                      ? 'unReadEnvelope'
                      : 'readEnvelope'
                  "
                  size="sm"
                ></svg-icon>
              </div>
            </div>
          </td>

          <td
            class="td-medium text-overflow-style"
            [ngClass]="rowData.classColor"
            [pTooltip]="rowData.event | eventHierarchyString"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.event | eventHierarchyString }}
            </span>
          </td>

          <td
            class="td-medium"
            [pTooltip]="rowData.depthString"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.depthString }}
            </span>
          </td>

          <td class="td-medium">
            <span>
              {{ rowData.eventDescription }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.isRecommendedActionTaken | NA"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.isRecommendedActionTaken | NA }}
            </span>
          </td>

          <td class="td-medium">
            <span>
              {{ rowData.resolution | NA }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.timeSaved + 'h'"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.timeSaved + "h" }}
            </span>
          </td>

          <td class="yellow-tag td-default" data-excluded>
            <div 
              data-excluded
              [pTooltip]="rowData.highestNomination ? 'Lesson Learned' : ''"
              tooltipPosition="bottom"
              class="d-flex nominate-vote gap-1" 
              *ngIf="rowData.highestNomination" 
              [id]="rowData.interventionId" 
              (click)="toggleDialog(rowData.highestNomination, rowData.interventionId)">
              <div data-excluded>
                <svg-icon data-excluded key="nominate" size="md"></svg-icon> 
              </div>
              <div data-excluded style="line-height: 25px;">
                {{ rowData.highestNomination?.nominateVote}}
              </div>
            </div>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.contactMethod | contactMethodString"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.contactMethod | contactMethodString }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.risk | riskString"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.risk | riskString }}
            </span>
          </td>
          <!-- style="min-width: 220px" -->
          <td
            class="td-large"
            [pTooltip]="rowData.engineer"
            tooltipPosition="bottom"
          >
            <div class="d-flex">
              <div
                *ngIf="
                  (rowData.avatarLink && !rowData.avatarLink.user_avatar_url) ||
                  !rowData.avatarLink
                "
                class="avatar-random"
                [attr.data-label]="getFirstLetter(rowData.engineer)"
                [appRandomAvatar]="getFirstLetter(rowData.engineer)"
              ></div>

              <div
                class="avatar"
                *ngIf="rowData.avatarLink && rowData.avatarLink.user_avatar_url"
              >
                <img
                  [src]="rowData.avatarBlob | safe : 'url'"
                  [alt]="'Avatar Image of' + rowData.engineer"
                />
              </div>
              <div class="color-primary">
                {{ rowData.engineer || "TBD" }}
              </div>
            </div>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.runDisplay"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.runDisplay }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.intervalDisplay | NA"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.intervalDisplay | NA }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.wellboreDisplay"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.wellboreDisplay }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.wellDisplay"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.wellDisplay }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.rigDisplay"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.rigDisplay }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.projectDisplay"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.projectDisplay }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.remoteCenter | NA"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.remoteCenter | NA }}
            </span>
          </td>

          <td
            class="td-default"
            [pTooltip]="rowData.interventionUrl"
            tooltipPosition="bottom"
          >
            <div
              *ngIf="rowData.interventionUrl"
              style="margin-top: -4px"
              (click)="
                readPDFdocument(
                  rowData.interventionUrl,
                  'intervention',
                  rowData
                )
              "
            >
              <svg-icon key="pdf" size="lg"></svg-icon>
            </div>
          </td>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [appendTo]="'body'"
            [styleClass]="'menu-table-item auto-width-menu'"
          ></app-menu>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">{{ msgFromApis }}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      [rows]="pageSize" 
      [totalRecords]="totalRecords" 
      [rowsPerPageOptions]="[10,20,30,{showAll:'All'}]" 
      [first]="(pageIndex - 1) * pageSize"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entities"
      [showCurrentPageReport]="true"
      [templateLeft]="paginatorLeft"
      (onPageChange)="onPageChange($event)">
      <ng-template #paginatorLeft>
        <div class="paginator--totalLeft">Total<span>{{ totalRecords }}</span></div>
      </ng-template>
    </p-paginator>
  </form>
</div>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>

<app-custom-expand-infor></app-custom-expand-infor>