<div class="report-issue" [formGroup]="formGroupReportIssue">
  <p class="description-title">Title<span class="required-asterisk">*</span></p>
  <input
    type="text"
    pInputText
    class="input-title custom-p-input"
    placeholder="Enter title..."
    formControlName="title"
    TextInputValidate
    typeCheck="short"
  />
  <div *ngIf="this.formGroupReportIssue.get('title')?.errors?.maxlength" class="inner-msg-error">
    <span>Please enter no more than 50 characters</span>
  </div>
  <p class="description-title mt-2">
    Description<span class="required-asterisk">*</span>
  </p>
  <textarea
    pInputTextarea
    placeholder="Please provide details here..."
    class="description-content custom-p-input"
    formControlName="description"
    TextInputValidate
    typeCheck="long"
  ></textarea>
  <div *ngIf="this.formGroupReportIssue.get('description')?.errors?.maxlength" class="inner-msg-error">
    <span>Please enter no more than 2000 characters</span>
  </div>
  <div class="upload-section">
    <p class="upload-title">Supporting Evidences</p>
    <div class="upload-input">
      <p>.png, .jpeg, .jpg, .pdf, max total 10MB</p>
      <p-fileUpload
        #evidencesUploader
        name="demo[]"
        accept=".png, .jpeg, .jpg, .pdf"
        [maxFileSize]="10000000"
        [auto]="false"
        [multiple]="true"
        chooseLabel="Add File"
        chooseIcon="pi pi-plus"
        cancelLabel="Clear All"
        [showUploadButton]="false"
        chooseStyleClass="choose-button"
        cancelStyleClass="cancel-button"
        (onClear)="onUploaderClear('evidences')"
        (onSelect)="onUploaderSelect('evidences', $event)"
      >
        <ng-template pTemplate="toolbar"> </ng-template>
        <ng-template let-files pTemplate="content">
          <div class="upload-counter mt-1" *ngIf="files.length">
            {{ files.length }}
            {{ files.length > 1 ? " files" : " file" }} uploaded
          </div>
          <div
            class="empty-upload"
            (click)="openSelectFileUpload()"
            *ngIf="files.length === 0"
          >
            Or drop files here to upload
          </div>
          <div *ngIf="files.length !== 0">
            <p-table
              [value]="files"
              [reorderableColumns]="false"
              (onRowReorder)="dragTime($event)"
              tableStyleClass="custom-table-upload"
            >
              <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
                let-index="rowIndex"
              >
                <tr [pReorderableRow]="index">
                  <td>
                    <div class="p-fileupload-row">
                      <div></div>
                      <div
                        class="p-fileupload-filename"
                        [pReorderableRowHandle]="index"
                      >
                        {{ rowData["name"] }}
                      </div>
                      <div [pReorderableRowHandle]="index">
                        {{ rowData["size"] | formatFileSizePipe : true }}
                      </div>
                      <div>
                        <button
                          type="button"
                          icon="pi pi-times"
                          pbutton=""
                          class="p-element p-button p-component p-button-icon-only"
                          ng-reflect-icon="pi pi-times"
                          (click)="removeItemUpload(index)"
                        >
                          <span
                            class="p-button-icon pi pi-times"
                            aria-hidden="true"
                          ></span
                          ><span aria-hidden="true" class="p-button-label"
                            >&nbsp;</span
                          >
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>

        <ng-template let-file pTemplate="file"></ng-template>
      </p-fileUpload>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-4 gap-2">
    <p-button
      [attr.data-cy]="'cancel-button'"
      label="Cancel"
      class="custom-p-button custom-p-button--primary"
      (onClick)="onCancel()"
    ></p-button>
    <p-button
      [attr.data-cy]="'send-button'"
      label="Send"
      class="custom-p-button custom-p-button--secondary"
      (onClick)="onSend()"
      [ngClass]="
        formGroupReportIssue.status === 'INVALID' ||
        evidencesUploader._files.length > 5
          ? 'custom-p-button custom-p-button--disable'
          : ''
      "
      [disabled]="
        formGroupReportIssue.status === 'INVALID' ||
        evidencesUploader._files.length > 5 ||
        isViewer
      "
    ></p-button>
  </div>
</div>

<p-toast position="top-center"></p-toast>
