<div class="mb-2">
  <app-customizable-button
    *ngIf="canAdd || isViewer"
    [showButton]="true" 
    [label]="'Add Wellbore'" 
    [style]="'light-sm'"
    [isDisabled]="!canAdd && !isViewer"
    [elementId]="'addWellboreButton'"
    [isToolTip]="!canAdd"
    [toolTipContent]="(!canAdd && !isViewer) ? 'User not granted to permission!' : ''"
    [tooltipPosition]="'bottom'"
    (eventButton)="showAddWellboreDialog()">
  </app-customizable-button>
</div>
<form
  #searchForm="ngForm"
  *ngIf="!!formWellboreItem"
  [formGroup]="formWellboreItem"
>
  <div class="project__filter rig-list__filter filter-wrapper">
    <div class="d-flex align-items-end">
      <div class="rig-list__filter">
        <label for="float-input-invalid" class="label-disabled">Project</label>
        <span>
          <input
            type="text"
            pInputText
            placeholder="Project"
            class="search-input custom-p-input"
            maxlength="50"
            name="searchString"
            [formControlName]="fieldNameKeys.project"
          />
        </span>
      </div>

      <div class="rig-list__filter project__filter--dropdown">
        <label for="float-input-invalid">Well</label>
        <p-dropdown
          [options]="wellList"
          optionLabel="name"
          [showClear]="true"
          placeholder="Select value"
          class="search-input custom-p-drop"
          (onChange)="bindingWell()"
          (onClear)="onClear()"
          [formControlName]="fieldNameKeys.well"
          [resetFilterOnHide]="true"
        ></p-dropdown>
      </div>

      <div class="project__filter-button project__filter-button--dark d-none">
        <p-button label="Filters"></p-button>
      </div>
    </div>
    <div class="rig-list__filter--counter">
      <span>Total: {{ rigListDataWellbore.length }}</span>
    </div>
  </div>

  <p-table
    [value]="rigListDataWellbore"
    [columns]="rigListWellboreColumn"
    [scrollable]="true"
    [resizableColumns]="true"
    styleClass="p-datatable-striped custom-table"
  >
    <ng-template pTemplate="header" let-columns>
      <tr style="height: 40px">
        <th
          *ngFor="let col of columns; last as isLast; let i = index"
          [pSortableColumn]="col.field"
          pResizableColumn
          [ngClass]="isLast ? 'th-last' : 'th-default'"
        >
          <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
          </ng-container>

          <ng-template #showThNotLast>
            <div
              [pTooltip]="col.header"
              tooltipPosition="bottom"
              class="th-content"
            >
              {{ col.header }}
            </div>
            <div class="header-sort-icon">
              <i
                class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
              ></i>

              <i
                class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
              ></i>
            </div>
          </ng-template>
          <ng-template #showThLast></ng-template>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td
          class="td-default"
          [pTooltip]="
            rowData.wellboreCurrentName || rowData.wellboreOriginalName || 'N/A'
          "
          tooltipPosition="bottom"
        >
          <span>
            {{
              rowData.wellboreCurrentName ||
                rowData.wellboreOriginalName ||
                "N/A"
            }}
          </span>
        </td>
        <td
          class="td-default"
          [pTooltip]="
            rowData.wellCurrentName || rowData.wellOriginalName || 'N/A'
          "
          tooltipPosition="bottom"
        >
          <span>
            {{ rowData.wellCurrentName || rowData.wellOriginalName || "N/A" }}
          </span>
        </td>
        <td
          class="td-default"
          [pTooltip]="
            rowData.siteCurrentName || rowData.siteOriginalName || 'N/A'
          "
          tooltipPosition="bottom"
        >
          <span>
            {{ rowData.siteCurrentName || rowData.siteOriginalName || "N/A" }}
          </span>
        </td>
        <td
          class="td-default"
          [pTooltip]="
            (rowData.isOverrideSpudDate
              ? rowData.wellboreSpudDate
              : rowData.wellboreEDMSpudDate
            ) | format24h : true
          "
          tooltipPosition="bottom"
        >
          <span>
            {{
              (rowData.isOverrideSpudDate
                ? rowData.wellboreSpudDate
                : rowData.wellboreEDMSpudDate
              ) | format24h : true
            }}
          </span>
        </td>

        <td
          class="td-default"
          [pTooltip]="
            rowData.wellboreCurrentUWI || rowData.wellboreOriginalUWI || 'N/A'
          "
          tooltipPosition="bottom"
        >
          <span>
            {{
              rowData.wellboreCurrentUWI || rowData.wellboreOriginalUWI || "N/A"
            }}
          </span>
        </td>
        <td
          class="td-default"
          [pTooltip]="rowData.wellboreGUID || 'N/A'"
          tooltipPosition="bottom"
        >
          <span>
            {{ rowData.wellboreGUID || "N/A" }}
          </span>
        </td>

        <td
          class="td-default"
          [pTooltip]="
            rowData.wellboreIsActive
              ? (rowData.wellboreCurrentName ||
                  rowData.wellboreOriginalName ||
                  'This Wellbore') + ' is Active'
              : (rowData.wellboreCurrentName ||
                  rowData.wellboreOriginalName ||
                  'This Wellbore') + ' is Inactive'
          "
          tooltipPosition="bottom"
        >
          <span
            [ngClass]="
              rowData.wellboreIsActive ? 'text-active' : 'text-inactive'
            "
          >
            {{ rowData.wellboreIsActive ? "Active" : "Inactive" }}</span
          >
        </td>

        <td style="min-width: 50px; max-width: 50px">
          <div *ngIf="!nonPermission || isViewer" [ngClass]="nonPermission ? 'more-option-table disabled' : 'more-option-table'">
            <svg-icon
              class="header-content__title--feature"
              key="dot3hoz"
              size="md"
              (click)="showContextMenu($event, rowData)"
            >
            </svg-icon>
          </div>
        </td>
        <app-menu
          #menu
          [optionList]="getMenuItemsForItem$ | async"
          [appendTo]="'body'"
          [styleClass]="'menu-table-item'"
        ></app-menu>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">{{ msgFromApis }}</td>
      </tr>
    </ng-template>
  </p-table>
</form>

<ng-container *ngIf="displayFormWellbore">
  <app-home-form-wellbore [isViewer]="isViewer"></app-home-form-wellbore>
</ng-container>

<ng-container *ngIf="displayFormWellboreEdit">
  <app-home-form-wellbore
    [isViewer]="isViewer"
    [wellbore]="editWellbore"
    [displayFormWellbore]="displayFormWellboreEdit"
    (hide_formWellbore)="hideFormWellbore($event)"
    (reload_formWelbore)="reloadFormWellbore($event)"
  ></app-home-form-wellbore>
</ng-container>

<ng-container *ngIf="displayAddWellboreDialog">
  <app-home-add-wellbore
    [displayAddWellboreDialog]="displayAddWellboreDialog"
    [addwellboreLevelRigCard]="dataToAddWellbore"
    (hideAddWellboreDialog)="hideAddWellboreDialog($event)"
    (hideAddWellboreDialogAndReload)="hideAddWellboreDialog($event)"
  ></app-home-add-wellbore>
</ng-container>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
