import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDecimalToHHMM',
})
export class TimeDecimalToHHMMPipe implements PipeTransform {
  /**
   * Converts the input value from seconds to a formatted time string (DH:MM).
   *
   * @param seconds - The input value in seconds to be transformed.
   * @param haveTitle - Indicates whether to include a title in the result.
   * @returns The formatted time string.
   */

  secondsToDhms(seconds: number, haveTitle: boolean): string {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d == 1 ? ' Day, ' : ' Days, ') : '';
    let hDisplay = h > 0 ? h + (h == 1 ? ' Hour, ' : ' Hours, ') : '';
    let mDisplay = m > 0 ? m + (m == 1 ? ' Minute, ' : ' Minutes, ') : '';
    let sDisplay = s > 0 ? s + (s == 1 ? ' Second' : ' Seconds') : '';

    return haveTitle
      ? 'Estimated time:' + dDisplay + hDisplay + mDisplay
      : dDisplay + hDisplay + mDisplay + sDisplay;
  }

  /**
   * Converts the input value from decimal time format to HH:MM format.
   * If `args` is set to 'non-title', the input value is assumed to be in seconds.
   * Otherwise, the input value is assumed to be in decimal time format.
   *
   * @param value - The input value to be transformed.
   * @param args - Additional arguments for the transformation.
   * @returns The transformed time string.
   */

  transform(value: any, args?: any): string {
    let afterConvert;
    if (args === 'non-title') {
      // Input is seconds
      afterConvert = this.secondsToDhms(value * 60 * 60, false);
    } else {
      afterConvert = this.secondsToDhms(value * 60 * 60, true);
    }

    // Get last characters to slice ',' in case convert dont have seconds
    let checkLastChar = afterConvert[afterConvert.length - 2];

    return !!value
      ? checkLastChar === ','
        ? afterConvert.slice(0, -2)
        : afterConvert
      : '';
  }
}
