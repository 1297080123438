import { Alert } from './../../../shared/interface/alert.interface';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import {
  BehaviorSubject,
  Subscription,
  catchError,
  finalize,
  of,
  switchMap,
} from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AlertListColumn } from 'src/app/shared/interface/alert.interface';
import { AlertFilterOptions, AlertService } from 'src/app/shared/services/alert.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AlertAdvancedFilterComponent } from '../alert-advanced-filter/alert-advanced-filter.component';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { BackPortalService } from 'src/app/shared/services/back-portal.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AlertComponent } from '../../intervention/intervention.component';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Menu } from 'primeng/menu';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { OptionButtonAndTextContent, OptionButtonType } from 'src/app/shared/type';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { fontRobotoCustom } from './font';
import { ExpandService } from 'src/app/shared/services/expand.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { isEmpty } from 'lodash';
import { ALERT_TYPE } from 'src/app/shared/enum';
import { SortBy } from 'src/app/shared/interface/sort-by.interface';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-alert-management',
  templateUrl: './alert-management.component.html',
  styleUrls: ['./alert-management.component.scss'],
})
export class AlertManagementComponent extends BaseComponent {
  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  isLoading: boolean = false;
  isAllOptionSelected: boolean = false;
  pageSize: number = 10;  
  pageIndex: number = 1;
  totalRecords: number = 0;
  sortBy: SortBy[] = [];
  isViewer: boolean = false;
  isEngineer: boolean = false;
  currentUser: any;
  exportListFunc!: MenuItem[];

  alertListData: Alert[] = [];
  restoreAlertListData: Alert[] = [];
  cacheAlertListData: Alert[] = [];

  @ViewChild('advancedFilter') advancedFilter:
    | AlertAdvancedFilterComponent
    | undefined;
  @ViewChild('menu') menu: any;

  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  payloadFilter: any;
  // Variables for Filter Chips
  chipsFilter: String[] = [];
  chipFiltersKey: string[] = [];

  alertListColumn: AlertListColumn[] = [
    { field: 'features', header: 'x' },
    { field: 'eventType', header: 'Alert Type' },
    { field: 'initiated', header: 'Initiated' },
    { field: 'resolved', header: 'Resolved' },
    { field: 'status', header: 'Status' },
    { field: 'event', header: 'Event' },
    { field: 'depthConverted', header: 'Depth' },
    { field: 'eventDescription', header: 'Description' },
    { field: 'isRecommendedActionTaken', header: 'Action Taken' },
    { field: 'resolution', header: 'Resolution' },
    { field: 'timeSaved', header: 'Time Saved' },
    { field: 'LL', header: 'LL' },
    { field: 'contactMethod', header: 'Contact Method' },
    { field: 'risk', header: 'Risk' },
    { field: 'engineer', header: 'Engineer' },
    { field: 'runDisplay', header: 'Run' },
    {
      field: 'runNumber',
      header: 'Interval',
    },
    {
      field: 'wellboreDisplay',
      header: 'Wellbore',
    },
    {
      field: 'wellDisplay',
      header: 'Well',
    },
    { field: 'rigDisplay', header: 'Rig' },
    {
      field: 'projectDisplay',
      header: 'Project',
    },
    { field: 'remoteCenter', header: 'Remote Center' },
    { field: 'interventionUrl', header: 'PDF' },
  ];

  AppHelper = AppHelper;

  msgFromApis = 'No result found';

  private socketSubscription: Subscription | undefined;

  constructor(
    private _router: Router,
    private _alertService: AlertService,
    private _blobService: AzureBlobService,
    private _notificationService: NotificationService,
    private _backPortalService: BackPortalService,
    public _viewContainerRef: ViewContainerRef,
    public _dialogService: DialogService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService,
    private _interventionService: InterventionService,
    private _expandService: ExpandService
  ) {
    super();
  }
  onInit() {
    this.isLoading = true;

    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
          this.isEngineer = response.role === AppConstant.ROLES.ENGINEER.label;
          this.currentUser = response;
        }
        this.loadInitialData();
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData(keepFilter: boolean = false) {
    // Socket io Check to remove Delete RealTime from another device
    this.socketSubscription = this._alertService.itemDeleted$.subscribe(
      (deletedItemId) => {
        if (deletedItemId) {
          this.removeItem(deletedItemId);
        }
      }
    );

    this.exportListFunc = [
      {
        items: [
          {
            label: '.xlsx',
            command: () => {
              this.exportExcel();
            },
          },
          {
            label: '.pdf',
            command: () => {
              this.exportPDF();
            },
          },
        ],
      },
    ];

    this.filterALertManagement();
  }
  removeItem(deletedItemId: string) {
    let cloneInterventionLog = JSON.parse(JSON.stringify(this.alertListData));
    cloneInterventionLog.some((intervention: any, index: number) => {
      if (intervention.interventionId === deletedItemId) {
        cloneInterventionLog.splice(index, 1);
      }
    });

    this.alertListData = cloneInterventionLog;
    this.restoreAlertListData = this.alertListData;
  }

  filterALertManagement(): void {
    this.isLoading = true;
    const alterFilterOptions: AlertFilterOptions = {    
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
    };

    !isEmpty(this.payloadFilter?.timeRange) && (alterFilterOptions.timeRange = this.payloadFilter.timeRange);
    !isEmpty(this.payloadFilter?.actionTaken) && (alterFilterOptions.actionTaken = this.payloadFilter.actionTaken);
    !isEmpty(this.payloadFilter?.status) && (alterFilterOptions.status = this.payloadFilter.status);
    !isEmpty(this.payloadFilter?.timeSaved) && (this.payloadFilter.timeSaved?.[1] > 0) && (alterFilterOptions.timeSaved = this.payloadFilter.timeSaved);
    !isEmpty(this.payloadFilter?.project) && (alterFilterOptions.project = this.payloadFilter.project);
    !isEmpty(this.payloadFilter?.well) && (alterFilterOptions.well = this.payloadFilter.well);
    !isEmpty(this.payloadFilter?.rig) && (alterFilterOptions.rig = this.payloadFilter.rig);
    !isEmpty(this.payloadFilter?.engineer) && (alterFilterOptions.engineer = this.payloadFilter.engineer);
    !isEmpty(this.payloadFilter?.remoteCenter) && (alterFilterOptions.remoteCenter = this.payloadFilter.remoteCenter);
    !isEmpty(this.payloadFilter?.event) && (alterFilterOptions.event = this.payloadFilter.event);
    !isEmpty(this.payloadFilter?.eventType) && (alterFilterOptions.alertType = this.payloadFilter.eventType);
    !isEmpty(this.payloadFilter?.risk) && (alterFilterOptions.risk = this.payloadFilter.risk);

    this._alertService.getAlertList(alterFilterOptions, this.sortBy)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (paginatedAlerts: any) => {
          this.totalRecords = paginatedAlerts.totalCount || 0;
          this.alertListData = paginatedAlerts.items;

          this.clarifyData();
          this.restoreAlertListData = this.alertListData;

          if (this.isAllOptionSelected) {
            this.pageSize = this.totalRecords;          
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  clarifyData(): void {
    this.alertListData = this.alertListData.map((item: Alert): any => {
      // Check Mark Completed by tool
      if (item.isComplete) {
        item.status = 'INITIATE';
        item.stateCode = 'I_0_1';
        item.classColor = AppHelper.MathFunctions.getColorOfInterventionStatus(
          item.stateCode,
          item.timeExpire
        );
      }

      // Different thing that is multipli with single alerts detail
      const currentUnit = item.additionalData.project?.curUnit || '';
      // handle avatars in case have engineer and avatars
      let url = '';
      if (
        item.engineer !== null &&
        item.avatarLink &&
        item.avatarLink.user_avatar_url
      ) {
        url = this._blobService.getUserImage(
          decodeURIComponent(
            AppHelper.StringFunctions.getFileName(
              item.avatarLink.user_avatar_url
            )
          )
        );
      }

      return {
        ...item,
        timeSaved: item.timeSaved ? item.timeSaved : 0,
        classColor: AppHelper.MathFunctions.getColorOfInterventionStatus(
          item.stateCode,
          item.timeExpire
        ),
        depthString: item.bitMD
          ? AppHelper.MathFunctions.transferData(
              item.bitMD,
              UNIT_SYSTEM,
              currentUnit,
              item.unit ? item.unit : '',
              'depth',
              0,
              0,
              0,
              0
            ) +
            '(bit) / ' +
            AppHelper.MathFunctions.transferData(
              item.holeMD,
              UNIT_SYSTEM,
              currentUnit,
              item.unit ? item.unit : '',
              'depth',
              0,
              0,
              0,
              0
            ) +
            '(hole)'
          : 'N/A',
        depthConverted: item.bitMD
          ? Number(
              AppHelper.MathFunctions.transferData(
                item.bitMD,
                UNIT_SYSTEM,
                currentUnit,
                item.unit ? item.unit : '',
                'depth',
                0,
                0,
                0,
                0,
                false
              )
            )
          : 'N/A',
        isRecommendedActionTaken:
          item.isRecommendedActionTaken === 'not-applicable' ||
          item.isRecommendedActionTaken === null
            ? 'N/A'
            : AppHelper.StringFunctions.capitalizeFirstLetter(
                item.isRecommendedActionTaken
              ),
        status: item.status === 'INITIATE' ? item.status : 'RESOLVE',
        bitMD: parseInt(item.bitMD),
        stateMessage: AppHelper.MathFunctions.getActionOfInterventionStatus(
          item.stateCode,
          item.timeExpire
        ),
        runDisplay: item.run
          ? `${item.run.description} (${item.run.runNo})`
          : 'N/A',

        intervalDisplay: item?.interval?.intervalName,
        runNumber: item.interval
          ? Number(
              AppHelper.MathFunctions.transferData(
                item.interval.openHoleDiameter,
                UNIT_SYSTEM,
                currentUnit,
                item.unit ? item.unit : '',
                'diameter',
                0,
                0,
                0,
                0,
                false
              )
            )
          : 'N/A',
        wellboreDisplay:
          item.additionalData.wellbore.wellboreCurrentName ||
          item.additionalData.wellbore.wellboreOriginalName,
        wellDisplay:
          item.additionalData.well.wellCurrentName ||
          item.additionalData.well.wellOriginalName,
        rigDisplay: item.additionalData.rig.rigName,
        projectDisplay:
          item.additionalData.project.projectCurrentName ||
          item.additionalData.project.projectOriginalName,
        avatarBlob: url,
        engineer: item.disciplineName ? item.engineer : 'N/A',
      };
    });
  }

  areDates45MinutesApart(date1: Date, date2: Date): boolean {
    // Return true if timeout
    let timeDifference = date2.getTime() - date1.getTime();
    return timeDifference >= 0;
  }

  loadChipFilter(itemFilter: any) {
    let items = Object.assign({}, itemFilter);
    this.chipFiltersKey = Object.keys(items);
    this.chipsFilter = [];

    Object.keys(items).forEach((item: any) => {
      switch (items[item][0]) {
        default:
          this.chipsFilter.push(items[item][0]);
          break;
      }
    });
  }
  loadPayloadFilter(payloadFilter: any) {
    this.payloadFilter = payloadFilter;
    this.pageIndex = 1;
    this.totalRecords = 0;
    if (this.isAllOptionSelected) {
      this.pageSize = 10000000;
    }  

    // Change number date to mid night
    if (this.payloadFilter.timeRange && this.payloadFilter.timeRange[0]) {
      this.payloadFilter.timeRange[0] = AppHelper.DateFunctions.convertToMidnightTimestamp(this.payloadFilter.timeRange[0], false);
    }
    if (this.payloadFilter.timeRange && this.payloadFilter.timeRange[1]) {
      this.payloadFilter.timeRange[1] = AppHelper.DateFunctions.convertToMidnightTimestamp(this.payloadFilter.timeRange[1], true);
    }

    // Check if this.payloadFilter.timeRange have only one date value
    if (this.payloadFilter.timeRange && (this.payloadFilter.timeRange[0] === null || this.payloadFilter.timeRange[1] === null)) {
      const singleDate = this.payloadFilter.timeRange[0] || this.payloadFilter.timeRange[1];
      this.payloadFilter.timeRange[0] = AppHelper.DateFunctions.convertToMidnightTimestamp(singleDate, false);
      this.payloadFilter.timeRange[1] = AppHelper.DateFunctions.convertToMidnightTimestamp(singleDate, true);
    }
    
    this.filterALertManagement();
  }

  removeItemChip(item: any, i: number) {
    let keyItemRemove: any = this.chipFiltersKey[i];

    this.chipsFilter.splice(i, 1);
    this.chipFiltersKey.splice(i, 1);

    this.advancedFilter?.onResetFilter(keyItemRemove);

    this.payloadFilter[keyItemRemove] = [];

    this.loadPayloadFilter(this.payloadFilter);
  }

  clearFilter(): void {
    this.advancedFilter?.onResetFilter('all');

    this.chipsFilter = [];

    this.payloadFilter = {
      actionTaken: [],
      engineer: [],
      event: [],
      project: [],
      remoteCenter: [],
      rig: [],
      risk: [],
      status: [],
      timeRange: [],
      timeSaved: [],
      well: [],
    };

    this.pageIndex = 1;
    this.totalRecords = 0;
    if (this.isAllOptionSelected) {
      this.pageSize = 10000000;
    }   
    this.filterALertManagement();
  }

  readPDFdocument(PDFLink: string, containerName: string, currentRig: any) {
    this.isLoading = true;
    if (PDFLink) {
      this._blobService
        .downloadPDF(
          decodeURIComponent(AppHelper.StringFunctions.getFileName(PDFLink)),
          containerName
        )
        .then((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Caught error:', error); // The error will be caught here
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading PDF Document',
            content: 'This document is no longer available, please try again',
          });
          // disable hyperlink of no longer available document
          switch (containerName) {
            case 'intervention':
              currentRig.interventionUrl = null;
              break;
          }
          this.isLoading = false;
        });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Read PDF Document',
        content: "Can't find the document in the database",
      });
      this.isLoading = false;
    }
  }

  exportPDF() {
    let exportData = JSON.parse(JSON.stringify(this.alertListData));

    exportData = exportData.map((item: any) => ({
      Initiated: item.initiated
        ? AppHelper.DateFunctions.formatDateTime(
            new Date(Number(new Date(item.initiated)))
          )
        : 'N/A',
      Resolved: item.resolved
        ? AppHelper.DateFunctions.formatDateTime(
            new Date(Number(new Date(item.resolved)))
          )
        : 'N/A',
      Status: `${item.currentState}`,
      Event: AppHelper.StringFunctions.formatHierarchyString(item.event),
      Depth: item.depthString,
      Description: item.eventDescription,
      ActionTaken: AppHelper.StringFunctions.formatRecommendedActionTaken(
        item.isRecommendedActionTaken
      ),
      Resolution: item.resolution || 'N/A',
      TimeSave: item.timeSaved ? item.timeSaved + 'h' : 'N/A',
      LL: item?.highestNomination ? true : false,
      ContactMethod: AppHelper.StringFunctions.formatContactMethodString(
        item.contactMethod
      ),
      Risk: AppHelper.StringFunctions.formatRiskString(item.risk),
      Engineer: item.engineer,
      Run: item?.runDisplay || 'N/A',
      Interval: item?.intervalDisplay || 'N/A',
      Wellbore: item.wellboreDisplay,
      Well: item.wellDisplay,
      Rig: item.rigDisplay,
      Project: item.projectDisplay,
      RemoteCenter: item.remoteCenter || 'N/A',
      PDF: item.interventionUrl,
    }));

    let PDF_EXTENSION = '.pdf';

    const doc = new jsPDF('l', 'mm', 'a2');
    doc.addFileToVFS('roboto-custom.ttf', fontRobotoCustom);
    doc.addFont('roboto-custom.ttf', 'roboto-custom', 'normal');

    if (exportData.length) {
      const column: string[] = ['No'];
      const tableData: any[] = [];
      for (let col in exportData[0]) {
        if (col !== 'PDF') {
          column.push(col);
        }
      }

      exportData.forEach((row: any, index: number) => {
        const numberRow = (index + 1).toString();
        const currentRow = [numberRow];
        for (let key in row) {
          if (key !== 'PDF') {
            if (key === 'LL') {
              currentRow.push(row[key] === true ? 'X' : '');
            } else {
              currentRow.push(row[key]);
            }
          }
        }
        tableData.push(currentRow);
      });

      autoTable(doc, {
        head: [column],
        body: tableData,
        didDrawCell: (data) => {},
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 25 },
          2: { cellWidth: 25 },
          3: { cellWidth: 35 },
          4: { cellWidth: 40 },
          5: { cellWidth: 40 },
          6: { cellWidth: 40 },
          7: { cellWidth: 35 },
          8: { cellWidth: 35 },
          9: { cellWidth: 15 },
          10: { cellWidth: 25 },
          11: { cellWidth: 35 },
          12: { cellWidth: 35 },
          13: { cellWidth: 15 },
          14: { cellWidth: 35 },
          15: { cellWidth: 20 },
          16: { cellWidth: 20 },
          17: { cellWidth: 20 },
          18: { cellWidth: 20 },
          19: { cellWidth: 'auto' },
        },
        bodyStyles: {
          font: 'roboto-custom',
        },
      });

      doc.save(
        'Report_Alert_Management' +
          '_export_' +
          new Date().getTime() +
          PDF_EXTENSION
      );
    }
  }

  exportExcel() {
    let exportData = JSON.parse(JSON.stringify(this.alertListData));

    exportData = exportData.map((item: any) => ({
      Initiated: item.initiated
        ? AppHelper.DateFunctions.formatDateTime(
            new Date(Number(new Date(item.initiated)))
          )
        : 'N/A',
      Resolved: item.resolved
        ? AppHelper.DateFunctions.formatDateTime(
            new Date(Number(new Date(item.resolved)))
          )
        : 'N/A',
      Status: `${item.currentState}`,
      Event: AppHelper.StringFunctions.formatHierarchyString(item.event),
      Depth: item.depthString,
      Description: item.eventDescription,
      ActionTaken: AppHelper.StringFunctions.formatRecommendedActionTaken(
        item.isRecommendedActionTaken
      ),
      Resolution: item.resolution || 'N/A',
      TimeSave: item.timeSaved ? item.timeSaved + 'h' : 'N/A',
      LL: item.highestNomination ? 'X' : undefined,
      ContactMethod: AppHelper.StringFunctions.formatContactMethodString(
        item.contactMethod
      ),
      Risk: AppHelper.StringFunctions.formatRiskString(item.risk),
      Engineer: item.engineer,
      Run: item.runDisplay,
      Interval: item.intervalDisplay,
      Wellbore: item.wellboreDisplay,
      Well: item.wellDisplay,
      Rig: item.rigDisplay,
      Project: item.projectDisplay,
      RemoteCenter: item.remoteCenter || 'N/A',
      PDF: item.interventionUrl,
    }));

    let worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'Report Alert Management');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  getFirstLetter(userName: string | undefined): string {
    if (!userName) return 'TBD';
    // Remove content within round brackets
    const nameWithoutBrackets = userName.replace(/ *\([^)]*\) */g, '');

    let arrySplitName = nameWithoutBrackets.trim().split(' ');
    let userNameLength = arrySplitName.length;

    let firstLetter = arrySplitName[0].charAt(0);
    let secondLetter = arrySplitName[userNameLength - 1].charAt(0);

    return (firstLetter + secondLetter).toLocaleUpperCase() || userName;
  }

  showContextMenu(event: any, inputData: Alert) {
    event.preventDefault();
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(alertRow: Alert): any {
    const canReset = alertRow.isComplete || false;

    // Remove Condition for created by themself 
    // || this.currentUser.id === alertRow.createUserId
    const canMarkComplete =
      (this.currentUser.role === AppConstant.ROLES.ADMIN.label ||
        this.currentUser.role === AppConstant.ROLES.MANAGER.label) &&
      (alertRow.stateCode === 'I_1_1' || alertRow.stateCode === 'R_1_0') &&
      !canReset;

    const canNominate = !['I_1_0', 'I_0_0'].includes(alertRow.stateCode);

    /* Case Engineer Can Mark Complete if alert is created by themself */
    // const userOwn = this.currentUser.id === alertRow.createUserId;

    return [
      {
        label: 'Open',
        icon: 'c-icons open-icon',
        command: () => {
          if (alertRow.additionalData.rigJournalId && alertRow.interventionId) {
            this.showIntervention(
              alertRow.additionalData.rigJournalId,
              alertRow.interventionId
            );
          }
        },
      },
      {
        label: 'Nominate for Lesson Learned',
        icon: 'c-icons nominate-icon',
        styleClass: 'yellow-label',
        visible: canNominate,
        disabled: !canNominate,
        command: () => {
          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: 'Nominate for Lesson Learned',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Nominate',
            isValidPrimaryButton: true,
            disablePrimaryButton: true,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            styleDialog: 'dark-dialog',
            isHeaderIcon: true,
            headerIcon: 'nominate',
            isHaveTextarea: true,
            textarePlaceHolder:
              'Provide justification for nominating this Alert...',
            textareaSize: 6,
            buttonEvent: (event: OptionButtonAndTextContent) =>
              this.onButtonClickDialog(event, alertRow),
          });
        },
      },
      {
        label: 'Mark Completed',
        icon: 'c-icons check-icon',
        visible: !this.isEngineer, // || userOwn,
        disabled: !canMarkComplete,
        command: () => {
          this.maskAsCompleteAlert(alertRow.interventionId);
        },
      },
      {
        label: 'Reset',
        icon: 'c-icons restore-icon',
        visible: !this.isEngineer, // || userOwn,
        disabled: !canReset,
        command: () => {
          this.resetAlert(alertRow.interventionId);
        },
      },
      {
        label: 'Edit',
        icon: 'c-icons edit-icon',
        command: () => {
          if (alertRow.additionalData.rigJournalId && alertRow.interventionId) {
            this._backPortalService.setHeaderDisplayValue(
              'Back to Alerts Management'
            );
            this._router.navigate([
              `/home/rig/${alertRow.additionalData.rigJournalId}/alert/${alertRow.interventionId}`,
            ]);
          }
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        visible: !this.isEngineer,
        disabled: this.isViewer,
        command: () => {
          this._confirmService.setDialog({
            isVisible: true,
            header: 'Are you sure you want to delete this alert from WellCare?',
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete this alert.',
            haveDialogMessage: false,
            dialogMessage: '',
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            disableSecondaryButton: false,
            haveBoxSelection: false,
            dataSelection: [],
            haveSecondaryMessage: false,
            secondaryMessage: '',
            haveTertiaryMessage: false,
            tertiaryDialogMessage: '',
            alignCenterButton: false,
            styleDialog: 'dark-dialog',
            isHeaderIcon: false,
            headerIcon: '',
            isHaveTextarea: false,
            textarePlaceHolder: '',
            textareaSize: 6,
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickInConfirmDeleteDialog(
                event,
                alertRow.interventionId
              ),
            dataDropdownTemplate: [],
            dataDropdownLanguage: [],
            isDisableDropdown: false,
            dialogMessageTemplate: '',
            dialogMessageLanguage: '',
            defaultTemplate: {id: 'default', name:'Default', isSelected: true},
            defaultLanguage: {id: 'en', name:'English', isSelected: true},
          });
        },
      },
    ];
  }
  resetAlert(alertId: string) {
    this.isLoading = true;
    this._interventionService
      .resetAlert(alertId)
      .pipe(catchError(AppHelper.UtileFunctions.handleError))
      .subscribe({
        next: ({ data, message }: any) => {
          if (!data.isComplete) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Alert Status',
              content: 'The status of the Alert was reset.', //message,
            });
            this.loadInitialData(true); // keep filter
          }
        },
        error: (error) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: error,
          });
          this.isLoading = false;
        },
      });
  }
  maskAsCompleteAlert(alertId: string) {
    this.isLoading = true;
    this._interventionService
      .markAsCompleteAlert(alertId)
      .pipe(catchError(AppHelper.UtileFunctions.handleError))
      .subscribe({
        next: ({ data, message }: any) => {
          if (data.isComplete) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Alert Status',
              content: 'The Alert was marked as completed.',
            });
            this.loadInitialData(true); // keep filter
          }
        },
        error: (error) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Information',
            content: error,
          });
          this.isLoading = false;
        },
      });
  }

  // Add nominate in Alert Management always create new comment and mark it nominated
  markNominate(alertId: string, nominateContent: string) {
    this.isLoading = true;
  
    this._interventionService
      .addChat(alertId, {
        content: nominateContent.trim(),
      })
      .subscribe({
        next: ({ data, message }: any) => {
          this._interventionService
            .markNominate(data?.chatId)
            .pipe(catchError(AppHelper.UtileFunctions.handleError))
            .subscribe({
              next: ({ data, message }: any) => {
                if (data.nominateAt) {
                  this._notificationService.setMessage({
                    type: AppConstant.MESSAGE_TYPE.SUCCESS,
                    header: 'Nominate Alert',
                    content:
                      'The justification will appear as a comment in the Team chat of the Alert with "Nominate for Lesson Learned" activated.',
                  });
                  this.loadInitialData(true); // keep filter
                }
              },
              error: (error) => {
                // this._notificationService.setMessage({
                //   type: AppConstant.MESSAGE_TYPE.WARNING,
                //   header: 'Information',
                //   content: error,
                // });
                this.isLoading = false;
              },
            });
        },
        error: (error) => {
          // error &&
          //   this._notificationService.setMessage({
          //     type: AppConstant.MESSAGE_TYPE.WARNING,
          //     header: 'Warning: Add Nomination',
          //     content: error?.message || 'Oops! Something went wrong.',
          //   });
          this.isLoading = false;
        },
      });
  }

  checkCondition(condition: string[], data: string) {
    for (const item of condition) {
      if (data.includes(item)) {
        return true;
      }
    }
    return false;
  }

  editIntervention(event: any, rigJournalId: string, interventionId: string) {
    // Check if the clicked element has the 'data-excluded' attribute
    const target = event.target as HTMLElement;

    const isExcludedColumn =
      target.hasAttribute('data-excluded') ||
      target.hasAttribute('viewBox') ||
      target.hasAttribute('d');
    if (isExcludedColumn) {
      // console.log('Excluded column clicked: ');
    } else {
      // Update Default click row
      if (rigJournalId && interventionId) {
        this.showIntervention(rigJournalId, interventionId);
      }
    }
  }

  public getTypeAlert(data: any) {
    // If the new data has eventType, should return eventType
    if (data?.eventType) return data?.eventType;

    // The old data doed not has eventType, should return in event.type
    const event = data?.event;
    return AppHelper.UtileFunctions.getTypeAlert(event);
  }

  private showIntervention(rigJournalId: string, alertId: string): void {
    const ref = this._dialogService.open(AlertComponent, {
      data: {
        isReadOnly: true,
        rigJournalIdParam: rigJournalId,
        alertIdParam: alertId,
        showMoreHeader: true,
      },
      styleClass: 'fullscreen-popup',
      showHeader: false,
    });

    ref.onClose
      .pipe(
        switchMap(() => {
          return of(null);
        })
      )
      .subscribe({
        next: (res: any) => {
          // this.loadPage();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  removeIntervention(interventionId: string) {
    this.isLoading = true;

    this._interventionService.deleteIntervention(interventionId).subscribe({
      next: (res: any) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: 'Delete Alert',
          content: `Alert was successfully deleted.`,
        });
        this.loadInitialData();
      },
      error: (err: any) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Delete Alert',
          content: err,
        });
        this.isLoading = false;
      },
    });
  }

  onButtonClickInConfirmDeleteDialog(
    option: OptionButtonType,
    internvetionId: string
  ): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        this.removeIntervention(internvetionId);
        break;
      case AppConstant.OPTION_BUTTON.NO:
      case AppConstant.OPTION_BUTTON.CANCEL:
      default:
        break;
    }

    this._confirmService.clearDialog();
  }

  toggleDialog(mominatedData: any, locationId: string) {
    const {
      userId,
      userDisplayName,
      userRole,
      timestamps,
      discipline,
      remoteCenter,
      mail
    } = mominatedData.nominateInformation[0];
    const isEngineer = userRole === AppConstant.ROLES.ENGINEER.label
    const nominateUser = `${userDisplayName} (${(isEngineer ? discipline : userRole)})`
    const expandData = {
      isDialogOpen: true,
      headerLabel: 'Nominated by',
      nominateList: [
        {
          header: nominateUser,
          content: `"${mominatedData.content}"`,
          footer: AppHelper.DateFunctions.formatDateTime(
            new Date(timestamps),
            true
          ),
        },
      ],
      triggerElementId: locationId,
      userId: this.currentUser.id
    };
    this._expandService.setExpandData(expandData);
  }

  onButtonClickDialog(option: OptionButtonAndTextContent | OptionButtonType, alert: Alert): void {
    // Selector Dialog
    if (typeof option === 'object' && 'textareaContent' in option) {
      switch (option.buttonType) {
        case AppConstant.OPTION_BUTTON.YES:
          this.markNominate(alert.interventionId, option.textareaContent); // ##Nominate Need Paste content of chat.
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.NO:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
          break;
        default:
          break;
      }
    }
    // Normal Dialog
    else if (typeof option === 'string') {
      switch (option) {
        case AppConstant.OPTION_BUTTON.YES:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.NO:
          this._confirmService.clearDialog();
          break;
        case AppConstant.OPTION_BUTTON.CANCEL:
          this._confirmService.clearDialog();
          break;
        default:
          break;
      }
    }
  }

  onPageChange(event: any) {
    this.pageIndex = event.page + 1;
    this.isAllOptionSelected = ![10, 20, 30].includes(event.rows);
    this.pageSize = event.rows;    
    this.filterALertManagement();
  }

  onSort(event: any) {
    if (!event?.field || event?.field == 'features') return;

    const newSortBy = [];
    newSortBy.push({field: event.field, order: event.order});
    const hasChangeSortCondition = JSON.stringify(this.sortBy) !== JSON.stringify(newSortBy);     
    if (this.isLoading || !hasChangeSortCondition) return;  
    this.sortBy = newSortBy;    
    this.pageIndex = 1;
    this.filterALertManagement();
  }

  onDestroy() {
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
  }
}
