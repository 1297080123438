<div *ngIf="formLoaded | async">
  <div class="alert-detail-list">
    <div class="alert-detail-header d-flex justify-content-between">
      <div class="alert-detail-header__title d-flex gap-2">
        <div class="project-name">
          {{
            alertData.project.projectCurrentName ||
              alertData.project.projectOriginalName ||
              "Project Name"
          }}:
        </div>
        <div class="alert-detail-header__title--rig-icon">
          <svg-icon
            class="custom-svg-icon"
            [ngClass]="{ active: !alertData.isComplete }"
            key="rig"
            size="lg"
          ></svg-icon>
        </div>
        <div>{{ alertData.rig.rigName || "Rig Name" }}</div>
      </div>
      <div class="alert-detail-header__feature d-flex">
        <div
          class="phone-button cursor-pointer"
          *ngIf="alertData.distributionListUrl"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          (click)="getMouseLocation($event, alertData.rigJournalId)"
        >
          <svg-icon class="custom-svg-icon phone-icon-setting" key="phone" size="lg"></svg-icon>
        </div>
        <div
          class="country-item country-item-value"
          *ngIf="alertData.project.country"
        >
          <i
            class="flag flag-{{
              alertData.project.country.toLowerCase().slice(0, 2)
            }}"
          ></i>
        </div>
        <div>
          <div
            class="more-option cursor-pointer"
            (click)="showContextMenu($event, alertData)"
          >
            <svg-icon
              class="custom-svg-icon feature-icon-setting"
              key="dot3hoz"
              size="lg"
            ></svg-icon>
          </div>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [styleClass]="'menu-table-item extend-md-width-menu'"
          ></app-menu>
        </div>
        <div
          class="alert-detail-header__feature--close-btn cursor-pointer"
          (click)="redirectTo('home')"
        >
          <svg-icon class="custom-svg-icon" key="close" size="md"></svg-icon>
        </div>
      </div>
    </div>

    <div class="alert-detail-info">
      <div class="alert-detail">
        <div class="alert-rig-image column">
          <ng-container *ngIf="alertData.rig.imageUrl">
            <ng-container *ngIf="alertData.rig.rigImageBlob">
              <img
                [src]="alertData.rig.rigImageBlob | safe : 'url'"
                alt="Image of Rig"
              />
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!alertData.rig.imageUrl">
            <img
              [src]="
                '../../../../assets/images/default-rigs/' +
                  alertData.rig.rigTypeDecoded.rigDefaultImage | safe : 'url'
              "
              alt="Image of Rig"
            />
          </ng-container>
        </div>
        <div class="alert-rig-detail column d-flex justify-content-between">
          <div class="alert-rig-detail-property">
            <div *ngFor="let property of fieldNameLeft">
              {{ property + ": " }}
            </div>
          </div>
          <div class="alert-rig-detail-value text-right">
            <div class="state--primary" [pTooltip]="alertData.policy.policyCurrentName || alertData.policy.policyOriginalName" tooltipPosition="bottom">
              {{
                alertData.policy.policyCurrentName ||
                  alertData.policy.policyOriginalName
              }}
            </div>
            <div class="state--primary" [pTooltip]="alertData.project.regionInfo | NA" tooltipPosition="bottom">
              {{ alertData.project.regionInfo | NA }}
            </div>
            <div [pTooltip]="alertData.project.countryInfo | NA" tooltipPosition="bottom">
              {{ alertData.project.countryInfo | NA }}
            </div>
            <div [pTooltip]="alertData.project.field | NA" tooltipPosition="bottom">{{ alertData.project.field | NA }}</div>
            <div [pTooltip]="alertData.project.block | NA" tooltipPosition="bottom">{{ alertData.project.block | NA }}</div>
            <div [pTooltip]="alertData.site.siteCurrentName || alertData.site.siteOriginalName | NA" tooltipPosition="bottom">
              {{
                alertData.site.siteCurrentName ||
                  alertData.site.siteOriginalName | NA
              }}
            </div>
            <div [pTooltip]="alertData.project.mapZone | NA" tooltipPosition="bottom">{{ alertData.project.mapZone | NA }}</div>
          </div>
        </div>
        <div class="alert-rig-detail column d-flex justify-content-between">
          <div class="alert-rig-detail-property">
            <div *ngFor="let property of fieldNameRight">
              {{ property + ": " }}
            </div>
          </div>
          <div class="alert-rig-detail-value text-right">
            <div class="state--success" [pTooltip]="alertData.well.wellCurrentName || alertData.well.wellOriginalName" tooltipPosition="bottom">
              {{
                alertData.well.wellCurrentName ||
                  alertData.well.wellOriginalName
              }}
            </div>
            <div [pTooltip]="alertData.wellbore.surfaceLatitude
                        ? alertData.wellbore.surfaceLatitude +
                          '°' +
                          ' / ' +
                          alertData.wellbore.surfaceLongitude +
                          '°'
                        : 'N/A'" tooltipPosition="bottom">
              {{
                alertData.wellbore.surfaceLatitude
                  ? alertData.wellbore.surfaceLatitude +
                    "°" +
                    " / " +
                    alertData.wellbore.surfaceLongitude +
                    "°"
                  : "N/A"
              }}
            </div>
            <div [pTooltip]="alertData.wellbore.guid | NA" tooltipPosition="bottom">
              {{ alertData.wellbore.guid | NA }}
            </div>
            <div class="state--success" [pTooltip]="alertData.wellbore.wellboreCurrentName || alertData.wellbore.wellboreOriginalName | NA" tooltipPosition="bottom">
              {{
                alertData.wellbore.wellboreCurrentName ||
                  alertData.wellbore.wellboreOriginalName | NA
              }}
            </div>
            <div class="state--success" [pTooltip]="alertData.interval?.intervalName | NA" tooltipPosition="bottom">
              {{ alertData.interval?.intervalName | NA }}
            </div>
            <div class="state--success" [pTooltip]="alertData.run?.run_no | NA" tooltipPosition="bottom">{{ alertData.run?.run_no | NA }}</div>
            <div class="state--warning" [pTooltip]="alertData.project.curUnit | NA" tooltipPosition="bottom">
              {{ alertData.project.curUnit | NA }}
            </div>
          </div>
        </div>
        <div class="alert-rig-detail column">
          <div
            class="d-flex gap-2"
            [ngClass]="{
              'feature-active': alertData.communicateProtocolUrl
            }"
            (click)="
              readPDFdocument(
                $event,
                alertData.communicateProtocolUrl || '',
                'rigcommunicateprotocal'
              )
            "
          >
            <div>
              <svg-icon class="custom-svg-icon" key="protocol" size="md"></svg-icon>
            </div>
            <div>Protocol</div>
          </div>

          <div
            class="d-flex gap-2"
            [ngClass]="{
              'feature-active': alertData.dataRoutingUrl
            }"
            (click)="
              readPDFdocument(
                $event,
                alertData.dataRoutingUrl || '',
                'rigdatarouting'
              )
            "
          >
            <div>
              <svg-icon class="custom-svg-icon" key="routing" size="md"></svg-icon>
            </div>
            <div>Routing</div>
          </div>

          <div
            class="d-flex gap-2"
            [ngClass]="{
              'feature-active': alertData?.interval?.designOfServiceUrl
            }"
            (click)="
              readPDFdocument(
                $event,
                alertData?.interval?.designOfServiceUrl || '',
                'intervaldesignofservice'
              )
            "
          >
            <div>
              <svg-icon class="custom-svg-icon" key="ionesheet" size="md"></svg-icon>
            </div>
            <div>iOneSheet</div>
          </div>
        </div>
      </div>

      <div class="alert-flag-statistic d-flex">
        <div class="flag-statistic-group d-flex justify-content-start">
          <ng-container *ngIf="!alertData.isComplete">
            <ng-container
              *ngFor="let flag of alertData.flagStatistic; let i = index"
            >
              <div class="statistic-group d-flex" *ngIf="flag.value > 0">
                <div [class]="'statistic-icon--' + flag.key">
                  <svg-icon class="custom-svg-icon" key="done" size="md"></svg-icon>
                </div>
                <div class="statistic-amount">{{ flag.value }}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div
          class="intervention-button intervention-button--light"
          [ngClass]="
            !alertData.isComplete &&
            alertData.project.curUnit &&
            alertData.mailboxList.length &&
            alertData.distributionListUrl
              ? ''
              : 'p-disabled-dark'
          "
        >
          <p-button label="Alert" (click)="showIntervene(alertData)">
          </p-button>
          <ng-container *ngIf="!alertData.project.curUnit; else var2">
            <span class="tooltipText button">
              The project's attributes must be updated
            </span>
          </ng-container>
          <ng-template #var2>
            <ng-container *ngIf="!alertData.mailboxList.length; else var3">
              <span class="tooltipText button">
                The rig's attributes must be updated
              </span>
            </ng-container>
          </ng-template>
          <ng-template #var3>
            <ng-container *ngIf="!alertData.distributionListUrl; else var4">
              <span class="tooltipText button">
                Distribution lists must be added to the rig tile
              </span>
            </ng-container>
          </ng-template>

          <ng-template #var4>
            <ng-container *ngIf="alertData.isComplete">
              <span class="tooltipText button">
                A wellbore must be set to active
              </span>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="alert-detail-table">
      <div class="alert-detail-table-header d-flex justify-content-between">
        <div>Alerts</div>
        <div>Total: {{ alertListData.length }}</div>
      </div>
      <div class="alert-detail-table-content">
        <p-table
          [value]="alertListData"
          [columns]="alertListColumn"
          [scrollable]="true"
          [resizableColumns]="true"
          styleClass="p-datatable-striped custom-table"
        >
          <ng-template pTemplate="header" let-columns>
            <tr style="height: 40px">
              <th
                *ngFor="let col of columns; last as isLast; let i = index"
                [pSortableColumn]="col.field"
                pResizableColumn
                [ngClass]="{
                  'th-document-small': i === 0 || i === 1,
                  'th-document-medium': i === 3 || i === 4 || i === 5,
                  'th-default': i !== 0 && i !== 1 && i !== 3 && i !== 4 && i !== 5
                }"
              >
                <div
                  [pTooltip]="col.header"
                  tooltipPosition="bottom"
                  class="th-content"
                >
                  {{ col.header }}
                </div>
                <div class="header-sort-icon">
                  <i
                    class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                  ></i>

                  <i
                    class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                  ></i>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
              <td
                class="td-default capitalize"
                [pTooltip]="rowData.eventType"
                tooltipPosition="bottom"
                style="min-width: 140px"
              >
                <span>
                  {{ rowData.eventType }}
                </span>
              </td>
              <td
                class="td-default"
                [pTooltip]="rowData.initiated | format24h"
                tooltipPosition="bottom"
                style="min-width: 140px"
              >
                <span>
                  {{ rowData.initiated | format24h }}
                </span>
              </td>
              <td
                class="td-default text-overflow-style"
                [ngClass]="rowData.classColor"
                [pTooltip]="rowData?.stateMessage"
                tooltipPosition="bottom"
              >
                <div class="d-flex">
                  <div>
                    {{
                      rowData?.status === "INITIATE" ? "INITIATE" : "RESOLVE"
                    }}
                  </div>

                  <div style="margin-left: 8px; margin-top: -2px">
                    <svg-icon
                      class="custom-svg-icon"
                      [key]="
                        ['I_0_1', 'R_1_1', 'I_1_1'].includes(rowData.stateCode)
                          ? 'unReadEnvelope'
                          : 'readEnvelope'
                      "
                      size="sm"
                    ></svg-icon>
                  </div>
                </div>
              </td>
              <td
                class="td-medium text-overflow-style"
                [ngClass]="rowData.classColor"
                [pTooltip]="rowData.event | eventHierarchyString"
                tooltipPosition="bottom"
              >
                <span>
                  {{ rowData.event | eventHierarchyString }}
                </span>
              </td>
              <!--  -->
              <td class="td-medium scrollable-cell">
                <span>
                  {{ rowData.eventDescription }}
                </span>
              </td>
              <td class="td-medium scrollable-cell">
                <span>
                  {{ rowData.resolution | NA }}
                </span>
              </td>
              <td
                class="td-default"
                [pTooltip]="rowData.depthString | NA"
                tooltipPosition="bottom"
              >
                <span>
                  {{ rowData.depthString | NA }}
                </span>
              </td>
              <td
                class="td-default"
                [pTooltip]="rowData.diameterConverted | NA"
                tooltipPosition="bottom"
              >
                <span>
                  {{ rowData.diameterConverted | NA }}
                </span>
              </td>
              <td
                class="td-default"
                [pTooltip]="rowData.intervalString | NA"
                tooltipPosition="bottom"
              >
                <span>
                  {{ rowData.intervalString | NA }}
                </span>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="columns.length">{{ msgFromApis }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- Popup Edit Project -->
<ng-container *ngIf="popupProjectInformation.isDisplay">
  <app-home-edit-project
    [displayEditProject]="popupProjectInformation.isDisplay"
    [project]="popupProjectInformation.project"
    (hideEditProject)="hidePopUpProject($event)"
  ></app-home-edit-project>
</ng-container>

<!-- Popup Edit Rig Tile -->
<ng-container *ngIf="popupRigInformation.isDisplay">
  <app-home-form-rig
    [displayFormRig]="popupRigInformation.isDisplay"
    [rigEditId]="popupRigInformation.rigJournal.rigId"
    [rigJournalEditId]="popupRigInformation.rigJournal.rigJournalId"
    [projectName]="popupRigInformation.rigJournal.projectName"
    (hideFormRig)="popupRigInformation.isDisplay = $event"
    (requestReloadPage)="hidePopUpRig($event)"
  ></app-home-form-rig>
</ng-container>

<!-- Popup Add Wellbore Dialog Component -->
<ng-container *ngIf="popupWellboreInformation.isDisplay">
  <app-home-add-wellbore
    [displayAddWellboreDialog]="popupWellboreInformation.isDisplay"
    [addwellboreLevelRigCard]="
      popupWellboreInformation.dataMenuAddWellboreRigCardLevel
    "
    (hideAddWellboreDialog)="hideAddWellboreDialog($event)"
  ></app-home-add-wellbore>
</ng-container>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
