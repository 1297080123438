<div class="mailbox-list">
  <app-module-header
    [backPortal]="false"
    backPortalLabel="Nothing Render Because backPortal === false"
    headerTitle="Mailbox Management"
    [buttonFearture]="true"
    buttonFeartureLabel="Add Mailbox"
    [bottomMargin]="true"
    (onClickButton)="displayAddFormMailBoxDialog = true"
  ></app-module-header>

  <form #searchForm="ngForm" style="max-width: 1120px">
    <div class="project__filter mailbox-list__filter">
      <div class="d-flex">
        <div class="mailbox-list__filter--input">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              type="text"
              pInputText
              class=""
              placeholder="Search by mailbox"
              class="search-box"
              maxlength="50"
              name="searchString"
              (keyup)="keyupSearch($event)"
              ngModel
            />
          </span>
        </div>

        <div class="project__filter-button project__filter-button--dark">
          <!-- <p-button label="Filters"></p-button> -->
          <app-mailbox-advanced-filter
            #advancedFilter
            (chipFilter)="loadChipFilter($event)"
            (filter)="loadPayloadFilter($event)"
          ></app-mailbox-advanced-filter>
        </div>
      </div>
      <div class="mailbox-list__filter--counter mailbox-list__filter--text">
        <span>Total: {{ mailBoxListData.length || 0 }}</span>
      </div>
      <div class="chip-wrapper">
        <ng-container
          class="p-d-flex"
          *ngFor="let item of chipsFilter; index as i"
        >
          <p-chip
            label="{{ item }}"
            styleClass="chip-item"
            removeIcon="pi pi-times"
            [removable]="true"
            (onRemove)="removeItemChip(item, i)"
          ></p-chip>
        </ng-container>
      </div>
      <div>
        <p
          class="cursor-pointer button-highlight mailbox-list__filter--text"
          (click)="clearFilter()"
        >
          Clear all
        </p>
      </div>
    </div>

    <p-table
      [value]="mailBoxListData"
      [columns]="mailBoxListColumn"
      [scrollable]="true"
      [resizableColumns]="true"
      styleClass="p-datatable-striped custom-table"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 40px">
          <th
            *ngFor="let col of columns; last as isLast; let i = index"
            [pSortableColumn]="col.field"
            pResizableColumn
            [ngClass]="{
              'th-last': isLast,
              'th-document-sort': i === 3,
              'th-default': i !== 3 && !isLast
            }"
          >
            <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
            </ng-container>

            <ng-template #showThNotLast>
              <div
                [pTooltip]="col.header"
                tooltipPosition="bottom"
                class="th-content"
              >
                {{ col.header }}
              </div>
              <div *ngIf="i !== 3 && i !== 4" class="header-sort-icon">
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                ></i>

                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                ></i>
              </div>
            </ng-template>
            <ng-template #showThLast></ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr>
          <td
            class="td-default"
            [pTooltip]="rowData.mailboxName"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.mailboxName }}
            </span>
          </td>
          <td class="td-default">
            <span
              [pTooltip]="rowData?.displayDiscipline"
              tooltipPosition="bottom"
            >
              {{ rowData?.displayDiscipline }}
            </span>
          </td>
          <td
            class="td-default"
            [pTooltip]="rowData.remoteCenter.remoteCenterName"
            tooltipPosition="bottom"
          >
            <span>
              {{ rowData.remoteCenter.remoteCenterName }}
            </span>
          </td>

          <td
            style="min-width: 94px; max-width: 94px"
            [pTooltip]="rowData.isActive ? 'Active' : 'Inactive'"
            tooltipPosition="bottom"
          >
            <div class="text-center">
              <app-single-switch
                [disabled]="isViewer"
                [statusValue]="rowData.isActive"
                (onSwitch)="handleChangeStatus(rowData)"
              ></app-single-switch>
            </div>
          </td>

          <td style="min-width: 50px; max-width: 50px">
            <div class="more-option-table">
              <svg-icon
                class="header-content__title--feature"
                key="dot3hoz"
                size="md"
                (click)="showContextMenu($event, rowData)"
              >
              </svg-icon>
            </div>
          </td>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [appendTo]="'body'"
            [styleClass]="'menu-table-item'"
          ></app-menu>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">{{ msgFromApis }}</td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</div>

<!-- Form Add Rig Dialog Component -->
<ng-container *ngIf="displayAddFormMailBoxDialog">
  <app-mailbox-form
    [displayFormMailBoxDialog]="displayAddFormMailBoxDialog"
    (hideFormMailBoxDialog)="hideFormMailBoxDialog($event)"
    (reloadMailBoxManagement)="reloadMailBoxManagement($event)"
  ></app-mailbox-form>
</ng-container>

<!-- Form Edit Rig Dialog Component -->
<ng-container *ngIf="displayEditFormMailBoxDialog">
  <app-mailbox-form
    [displayFormMailBoxDialog]="displayEditFormMailBoxDialog"
    [mailBoxEdit]="mailBoxEdit"
    (hideFormMailBoxDialog)="hideFormMailBoxDialog($event)"
    (reloadMailBoxManagement)="reloadMailBoxManagement($event)"
  ></app-mailbox-form>
</ng-container>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
