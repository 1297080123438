<div class="filter">
  <p-button
    [attr.data-cy]="'filters-button'"
    [label]="'Filters'"
    class="custom-p-button custom-p-button--secondary"
    (click)="op.toggle($event)"
  >
  </p-button>

  <p-overlayPanel
    #op
    [showCloseIcon]="false"
    (onHide)="onHide($event)"
    styleClass="filter-advanced-alert"
  >
    <div class="filter-advanced-grid parent" (click)="onClickOutside($event)">
      <div>
        <div class="option-filter">
          <div class="d-flex align-items-center">
            <p class="filter-label">{{ METADATA.TIME_RANGE.LABEL }}</p>
            <svg-icon
              [pTooltip]="selectableDate"
              tooltipPosition="right"
              class="icon-info"
              key="contact"
              size="sm"
            ></svg-icon>
          </div>
          <p-calendar
            styleClass="custom-p-calendar time-range-calendar"
            selectionMode="range"
            dateFormat="dd-M-yy"
            rangeSeparator="to"
            placeholder="Enter value..."
            icon="pi pi-calendar"
            [showIcon]="true"
            [showClear]="true"
            [minDate]="minDateValue"
            [maxDate]="maxDateValue"
            [readonlyInput]="true"
            [defaultDate]="defaultDateValue"
            [(ngModel)]="selectedTimeRange"
            [selectOtherMonths]="true"
          ></p-calendar>
          <!-- [numberOfMonths]="2" -->
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.ALERT_TYPE.LABEL }}
            </p>
            <p class="counter">({{ selectedAlertType?.length || 0 }} selected)</p>
          </div>
          <p-multiSelect
            [options]="alertType"
            [(ngModel)]="selectedAlertType"
            optionLabel="name"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            class="custom-p-multiselect"
            [style]="{ width: '100%' }"
            [showClear]="true"
            [showHeader]="false"
            [resetFilterOnHide]="true"
            (onClear)="onClearAlertType($event)"
          >
          </p-multiSelect>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.STATUS.LABEL }}
            </p>
            <p class="counter">({{ selectedStatus?.length || 0 }} selected)</p>
          </div>
          <p-multiSelect
            [options]="statusesOption"
            [(ngModel)]="selectedStatus"
            optionLabel="statusName"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            class="custom-p-multiselect"
            [style]="{ width: '100%' }"
            [showClear]="true"
            [showHeader]="false"
            [resetFilterOnHide]="true"
            (onClear)="onClearStatus($event)"
          >
          </p-multiSelect>
          <!-- selectedItemsLabel="{0} items selected" -->
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.EVENT.LABEL }}
            </p>
            <p class="counter">({{ countSelectedEvent || 0 }} selected)</p>
          </div>
          <p-treeSelect
            #treeEvent
            selectionMode="checkbox"
            [options]="eventsOption"
            [(ngModel)]="selectedEvent"
            [placeholder]="selectedEventString"
            optionLabel="eventName"
            [showClear]="true"
            scrollHeight="182px"
            [propagateSelectionDown]="true"
            class="any-level custom-multiple-level"
            (onNodeSelect)="onEventOptions($event, '|')"
            (onNodeUnselect)="onEventUncheck($event)"
            (onNodeExpand)="onNodeExpand($event)"
            (onClear)="onClearEvent($event)"
          >
            <ng-template pTemplate="value">
              {{ selectedEventString }}
            </ng-template>
          </p-treeSelect>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.ACTION_TAKEN.LABEL }}
            </p>
            <p class="counter">
              ({{ selectedActionTaken?.length || 0 }} selected)
            </p>
          </div>
          <p-multiSelect
            [options]="actionTakensOption"
            [(ngModel)]="selectedActionTaken"
            optionLabel="actionTakenName"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            class="custom-p-multiselect"
            [style]="{ width: '100%' }"
            [showClear]="true"
            [showHeader]="false"
            [resetFilterOnHide]="true"
          >
          </p-multiSelect>
        </div>

        <div class="option-filter">
          <div class="d-flex align-items-center">
            <p class="filter-label">{{ METADATA.TIME_SAVED.LABEL }}</p>
            <svg-icon
              [pTooltip]="selectableTimeSave"
              tooltipPosition="right"
              class="icon-info"
              key="contact"
              size="sm"
            ></svg-icon>
          </div>
          <div class="time-slider-wrapper-box">
            <div class="d-flex justify-content-between">
              <div>
                <div class="filter-label regular">From</div>
                <span class="p-input-icon-right">
                  <i class="unit hr">hr</i>
                  <div class="p-inputgroup">
                    <input
                      pInputText
                      class="custom-p-input"
                      positiveDecimalInput
                      [min]="minTimeSaved"
                      [max]="maxTimeSaved"
                      [ngModel]="selectedTimeSaved[0]"
                      (focusout)="handleTime($event, 0)"
                    />
                  </div>
                </span>
              </div>
              <div>
                <div class="filter-label regular">To</div>
                <span class="p-input-icon-right">
                  <i class="unit hr">hr</i>
                  <div class="p-inputgroup">
                    <input
                      pInputText
                      class="custom-p-input"
                      positiveDecimalInput
                      [min]="minTimeSaved"
                      [max]="maxTimeSaved"
                      [ngModel]="selectedTimeSaved[1]"
                      (focusout)="handleTime($event, 1)"
                    />
                  </div>
                </span>
              </div>
            </div>
            <div
              class="inner-description-text"
              style="margin-top: 2px"
              [ngStyle]="{ display: isWarnField ? 'block' : 'none' }"
            >
              <span> {{ selectableTimeSave }} </span>
            </div>
            <div class="time-slider-panel--content">
              <p-slider
                [(ngModel)]="selectedTimeSaved"
                [range]="true"
                [min]="minTimeSaved"
                [max]="maxTimeSaved"
                [step]="1"
              ></p-slider>
              <div class="time-slider-panel--content-value">
                <span>{{ minTimeSaved || 0 }}</span>
                <span>{{ maxTimeSaved || 0 }}</span>
                <!-- 
                  <span>{{ selectedTimeSaved[0] || 0 }}</span>
                  <span>{{ selectedTimeSaved[1] || 0 }}</span> 
                -->
              </div>
              <!-- <input pInputText [(ngModel)]="selectedTimeSaved" type="text"> -->
            </div>
          </div>
          <div *ngIf="false">
            <div class="time-slider-wrapper" (click)="togglePanel($event)">
              <div [ngClass]="selectedTimeSaved.length ? 'has-value' : ''">
                <span> {{ selectedTimeSaved | showRangeSlider }}</span>
              </div>
              <div>
                <span class="pi pi-chevron-down"></span>
              </div>
            </div>

            <div
              class="time-slider-panel"
              [ngClass]="{ show: showSlider }"
              (click)="$event.stopPropagation()"
            >
              <div class="time-slider-panel--header">
                <span class="label-range">Time Saved Range</span>
                <span
                  (click)="pickAllTimeSaved()"
                  class="clear"
                  [ngClass]="selectedTimeSaved.length ? 'active' : ''"
                  >All</span
                >
              </div>
              <div class="time-slider-panel--content">
                <p-slider
                  [(ngModel)]="selectedTimeSaved"
                  [range]="true"
                  [min]="minTimeSaved"
                  [max]="maxTimeSaved"
                  [step]="1"
                ></p-slider>
                <div class="time-slider-panel--content-value">
                  <span>{{ selectedTimeSaved[0] || 0 }}</span>
                  <span>{{ selectedTimeSaved[1] || 0 }}</span>
                </div>
                <!-- <input pInputText [(ngModel)]="selectedTimeSaved" type="text"> -->
              </div>
            </div>
          </div>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.RISK.LABEL }}
            </p>
            <p class="counter">({{ countSelectedRisk || 0 }} selected)</p>
          </div>
          <p-treeSelect
            #treeRiskEvent
            [options]="risksOption"
            [(ngModel)]="selectedRisk"
            selectionMode="checkbox"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            scrollHeight="182px"
            class="custom-multiple-level"
            [showClear]="true"
            (onClear)="onClearRisk($event)"
            (onNodeSelect)="onSelectRisk()"
            (onNodeUnselect)="onSelectRisk()"
          >
          <ng-template pTemplate="value">
            {{ selectedRiskString }}
          </ng-template>
          </p-treeSelect>
        </div>
      </div>
      <div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.ENGINEER.LABEL }}
            </p>
            <p class="counter">
              ({{ countSelectedEngineer || 0 }} selected)
            </p>
          </div>
          <p-treeSelect
            #treeEngineer
            selectionMode="checkbox"
            [options]="engineersOption"
            [(ngModel)]="selectedEngineer"
            placeholder="All"
            scrollHeight="182px"
            class="custom-multiple-level"
            [showClear]="true"
            (onClear)="onClearEngineer($event)"
            (onNodeExpand)="onNodeExpand($event)"
            (onNodeSelect)="onSelectEngineer()"
            (onNodeUnselect)="onSelectEngineer()"
          >
          <ng-template pTemplate="value">
            {{ selectedEngineerString }}
          </ng-template>
          </p-treeSelect>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.REMOTE_CENTER.LABEL }}
            </p>
            <p class="counter">
              ({{ selectedRemoteCenter?.length || 0 }} selected)
            </p>
          </div>
          <p-multiSelect
            [options]="remoteCentersOption"
            [(ngModel)]="selectedRemoteCenter"
            optionLabel="remoteCenterName"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            class="custom-p-multiselect"
            [style]="{ width: '100%' }"
            [showClear]="true"
            [showHeader]="false"
            [resetFilterOnHide]="true"
          >
          </p-multiSelect>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.RIG.LABEL }}
            </p>
            <p class="counter">({{ selectedRig?.length || 0 }} selected)</p>
          </div>
          <p-multiSelect
            [options]="rigsOption"
            [(ngModel)]="selectedRig"
            optionLabel="rigNameLabel"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            class="custom-p-multiselect"
            [style]="{ width: '100%' }"
            [showClear]="true"
            [showHeader]="false"
            [resetFilterOnHide]="true"
          >
          </p-multiSelect>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.PROJECT.LABEL }}
            </p>
            <p class="counter">({{ selectedProject?.length || 0 }} selected)</p>
          </div>
          <p-multiSelect
            [options]="projectsOption"
            [(ngModel)]="selectedProject"
            optionLabel="projectName"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            class="custom-p-multiselect"
            (onChange)="onOptionsUpdateProject($event)"
            (onClear)="onClearProject($event)"
            [style]="{ width: '100%' }"
            [showClear]="true"
            [showHeader]="false"
            [resetFilterOnHide]="true"
          >
          </p-multiSelect>
        </div>
        <div class="option-filter">
          <div class="filter-label">
            <p>
              {{ METADATA.WELL.LABEL }}
            </p>
            <p class="counter">({{ selectedWell?.length || 0 }} selected)</p>
          </div>
          <!-- <p-multiSelect [options]="wellsOption" [(ngModel)]="selectedWell" optionLabel="wellName"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER" class="custom-p-multiselect" [style]="{ width: '100%' }"
            [showClear]="true" [showHeader]="false" [resetFilterOnHide]="true">
          </p-multiSelect> -->
          <p-treeSelect
            #treeWell
            [options]="wellsOption"
            [(ngModel)]="selectedWell"
            selectionMode="checkbox"
            [placeholder]="METADATA.OPTION.PLACE_HOLDER"
            scrollHeight="182px"
            class="custom-multiple-level"
            [showClear]="true"
            (onNodeSelect)="onOptionsUpdateWell($event)"
          >
          </p-treeSelect>
        </div>
      </div>
    </div>
    <div class="p-dialog-footer d-flex justify-content-end gap-2 mt-4">
      <div class="btn-cancel">
        <p-button
          [attr.data-cy]="'cancel-button'"
          type="button"
          label="Cancel"
          class="custom-p-button custom-p-button--primary"
          (click)="op.toggle($event)"
        ></p-button>
      </div>
      <div>
        <p-button
          [attr.data-cy]="'apply-button'"
          type="button"
          label="Apply"
          class="custom-p-button custom-p-button--secondary"
          (click)="executeFilter(); op.toggle($event)"
        ></p-button>
      </div>
    </div>
  </p-overlayPanel>
</div>
