import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { BaseComponent } from 'src/app/shared/components/base.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import {
  DrillingContractors,
  RigUnit,
} from 'src/app/shared/interface/rig.interface';
import { RigService } from 'src/app/shared/services/rig.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { catchError, finalize, forkJoin, Observable } from 'rxjs';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { MULTI_LEVEL } from 'src/app/shared/metadata/multi-level-dropdown-type.metadata';
import { DialogService } from 'primeng/dynamicdialog';
import { PhotoCropperComponent } from 'src/app/shared/components/photo-cropper/photo-cropper.component';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import _ from 'lodash';

@Component({
  selector: 'app-rig-form',
  templateUrl: './rig-form.component.html',
  styleUrls: ['./rig-form.component.scss'],
})
export class RigFormComponent extends BaseComponent {
  @Input()
  displayFormRigDialog: boolean = false;

  @Output()
  hideFormRigDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  reloadRigManagement: EventEmitter<boolean> = new EventEmitter<boolean>(); // emit both true/false will reload table but true will reload contractor dropdown

  @Input() rigEdit: any;

  formRigTitle: string = 'Add Rig';

  // public formRigGroup!: FormGroup;

  public fieldNameKeys = {
    rigConstractor: 'rigConstractor',
    rigName: 'rigName',
    rigType: 'rigType',
    rigImage: 'rigImage',
    rigSpecs: 'rigSpecs',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    size1: AppConstant.WARN_SIZE_IMAGE_UPLOAD,
    size2: AppConstant.WARN_SIZE_PDF_UPLOAD,
  };

  public rigConstractor: string = '';
  public rigName: string = '';

  private nonSpaceStartEndRegex = `^[^\s]+(\s+[^\s]+)*$`;

  selectedDrillingContractor: string = '';
  selectedRigName: string = '';

  filteredRigName: any[] = [];
  filteredDrillingContractor: DrillingContractors[] = [];

  DrillingContractors: DrillingContractors[] = [];
  RigNames: RigUnit[] = [];

  rigTypes: any[] = [];
  customLabelString = 'Select type';
  // button upload single file
  valueProgress: number = 0;
  @ViewChild('uploadImage') uploadImage?: FileUpload;
  @ViewChild('progressUploadImage') progressUploadImage?: ElementRef;

  @ViewChild('uploadSpecs') uploadSpecs?: FileUpload;
  @ViewChild('progressUploadSpecs') progressUploadSpecs?: ElementRef;

  fileImageLabel: string = 'Browse file';
  fileSpecsLabel: string = 'Browse file';

  //tracking upload to enable ADD button
  trackingUpload = [false, false];

  filtersLoaded?: Promise<boolean>;
  MULTI_LEVEL = MULTI_LEVEL;

  showCancelButtonImage: boolean = false;
  showCancelButtonSpecs: boolean = false;

  lastImageLink: string = '';
  lastSpecsLink: string = '';

  isLoading: boolean = false;
  isViewer: boolean = false;

  formRigGroup: FormGroup = this._builder.group({
    [this.fieldNameKeys.rigName]: [null],
    [this.fieldNameKeys.rigType]: [null],
    [this.fieldNameKeys.rigConstractor]: [null],
    [this.fieldNameKeys.rigImage]: [0],
    [this.fieldNameKeys.rigSpecs]: [0],
  });

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;
  
  constructor(
    private _rigService: RigService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _dialogService: DialogService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  originSources: Observable<any>[] = [];

  onInit(): void {
    this.isLoading = true;

    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
          this.loadInitialData();
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData() {
    this.rigTypes = AppConstant.RIG_TYPES;

    let originSources: Observable<any>[] = [
      this._rigService.getConstractors(),
      this._rigService.getRigs(),
    ];

    forkJoin(originSources).subscribe({
      next: ([contractorList, rigList]) => {
        if (contractorList && rigList) {
          if (contractorList.data) {
            this.DrillingContractors = Object.values(contractorList.data);

            this.DrillingContractors = this.DrillingContractors.filter((el) => {
              return el.contractorName;
            });
          } else {
            this.DrillingContractors = [];
          }

          if (rigList.data) {
            this.RigNames = Object.values(rigList.data);
          } else {
            this.RigNames = [];
          }

          this.filtersLoaded = Promise.resolve(true);

          if (!this.rigEdit) {
            this.buildFormAddRig();
            this.isLoading = false;
          } else {
            if (this.rigEdit) {
              this.formRigTitle = `Edit Rig: ${this.rigEdit.rigName}`;
              this.buildFormEditRig(this.rigEdit);
              this.loadNameOfFiles(this.rigEdit.imageUrl, this.rigEdit.specUrl);

              // markAsPristine to check close when dont active form.
              if (this.rigEdit && this.formRigGroup.pristine === false) {
                this.formRigGroup.markAsPristine();
              }

              this.isLoading = false;
            }
          }
        }
      },
      error: (error: any) => {
        console.error(error);
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Loading Data',
          content:
            'Prepare data is not ready, please try again: ' + error?.message,
        });
      },
    });
  }

  loadNameOfFiles(linkFileImage?: string, linkFileSpecs?: string) {
    this.lastImageLink = linkFileImage ? linkFileImage : '';
    this.lastSpecsLink = linkFileSpecs ? linkFileSpecs : '';

    if (!linkFileImage) {
      this.fileImageLabel = 'Browse file';
    } else {
      const nameFileImage =
        AppHelper.UtileFunctions.getFileNameFromUrl(linkFileImage);

      this.fileImageLabel = nameFileImage;
      this.uploadImage!._files = [];

      const uploadedImageFile = new File(
        [new Blob()],
        nameFileImage,
        {
          type: 'image/jpg',
        }
      );
      this.uploadImage!._files.push(uploadedImageFile);

      this.formRigGroup.get('rigImage')?.setValue(uploadedImageFile.size);
      this.getRefresh('rigImage');

      this.showCancelButtonImage = true;
    }

    if (!linkFileSpecs) {
      this.fileSpecsLabel = 'Browse file';
    } else {
      const nameFileSpec = AppHelper.UtileFunctions.getFileNameFromUrl(linkFileSpecs);

      this.fileSpecsLabel = nameFileSpec;
      this.uploadSpecs!._files = [];

      const uploadedSpecFile = new File([new Blob()], nameFileSpec, {
        type: 'application/pdf',
      });

      this.uploadSpecs!._files.push(uploadedSpecFile);

      this.formRigGroup.get('rigSpecs')?.setValue(uploadedSpecFile.size);
      this.getRefresh('rigSpecs');

      this.showCancelButtonSpecs = true;
    }
  }

  buildFormAddRig() {
    this.formRigGroup = this._builder.group({
      [this.fieldNameKeys.rigName]: [
        this.selectedRigName,
        [Validators.required],
      ],
      [this.fieldNameKeys.rigType]: [null, [Validators.required]],
      [this.fieldNameKeys.rigConstractor]: [
        this.selectedDrillingContractor,
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.nonSpaceStartEndRegex,
            'rigConstractor',
            'whitespace'
          ),
        ],
        ,
      ],
      [this.fieldNameKeys.rigImage]: [
        0,
        [
          this.checkSizeUploadField(
            this.fieldNameKeys,
            AppConstant.MAX_SIZE_IMAGE_UPLOAD,
            'rigImage',
            'size1'
          ),
        ],
      ],
      [this.fieldNameKeys.rigSpecs]: [
        0,
        [
          this.checkSizeUploadField(
            this.fieldNameKeys,
            AppConstant.MAX_SIZE_PDF_UPLOAD,
            'rigSpecs',
            'size2'
          ),
        ],
      ],
    });
  }
  buildFormEditRig(rig: RigUnit) {
    let rigTypeData = this.findTypeById(rig.rigTypeName.rigTypeName);
    this.customLabelString = this.findName(rig.rigTypeName.rigTypeName, '|');

    console.log(rigTypeData);
    
    
    this.formRigGroup = this._builder.group({
      [this.fieldNameKeys.rigName]: [rig, [Validators.required]],
      [this.fieldNameKeys.rigType]: [rigTypeData, [Validators.required]],
      [this.fieldNameKeys.rigConstractor]: [
        {
          contractorId: rig.contractorId,
          contractorName: rig.contractorName,
        },
        [
          Validators.required,
          this.customCorrectField(
            this.fieldNameKeys,
            this.nonSpaceStartEndRegex,
            'rigConstractor',
            'whitespace'
          ),
        ],
        ,
      ],
      [this.fieldNameKeys.rigImage]: [
        0,
        [
          this.checkSizeUploadField(
            this.fieldNameKeys,
            AppConstant.MAX_SIZE_IMAGE_UPLOAD,
            'rigImage',
            'size1'
          ),
        ],
      ],
      [this.fieldNameKeys.rigSpecs]: [
        0,
        [
          this.checkSizeUploadField(
            this.fieldNameKeys,
            AppConstant.MAX_SIZE_PDF_UPLOAD,
            'rigSpecs',
            'size2'
          ),
        ],
      ],
    });
  }

  submitRig() {
    this.isLoading = true;
    
    if (this.formRigGroup.status === 'INVALID') {
      return;
    }

    let valueFormRaw = this.formRigGroup.getRawValue();

    // Check value typing of Contructor. Need Assign New Flag for value Constructor
    if (typeof valueFormRaw.rigConstractor === 'string') {
      valueFormRaw.rigConstractor = this.filteredDrillingContractor[this.filteredDrillingContractor.length - 1]
    }

    let payload = {
      contractorId: valueFormRaw.rigConstractor.contractorId,
      rigType: {
        rigTypeName: valueFormRaw.rigType.type_id,
        rigTypeHierarchy: this.findName(valueFormRaw.rigType.type_id, '|'),
        rigDefaultImage: valueFormRaw.rigType.defaultImage,
      },
      rigName: valueFormRaw.rigName.rigName || valueFormRaw.rigName,
      rigImage: this.lastImageLink
        ? this.lastImageLink
        : this.uploadImage!._files[0],
      rigSpec: this.lastSpecsLink
        ? this.lastSpecsLink
        : this.uploadSpecs!._files[0],
    };

    if (!this.rigEdit) {
      if (!payload.contractorId) {
        this._rigService
          .addContractor(
            valueFormRaw.rigConstractor.contractorName ||
              valueFormRaw.rigConstractor
          )
          .subscribe({
            next: (newContractor) => {
              payload.contractorId = newContractor.contractor.contractorId;
              this._rigService
                .addRig(payload)
                .pipe(
                  finalize(() => {
                    this.isLoading = false;
                  }),
                  catchError(AppHelper.UtileFunctions.handleError)
                )
                .subscribe({
                  next: (response) => {
                    this._notificationService.setMessage({
                      type: AppConstant.MESSAGE_TYPE.SUCCESS,
                      header: 'Add Rig',
                      content: 'Rig was added successfully!',
                    });

                    this.displayFormRigDialog = !this.displayFormRigDialog;
                    this.hideFormRigDialog.emit(this.displayFormRigDialog);
                    this.reloadRigManagement.emit(true);
                  },
                  error: (error) => {
                    console.error(error);
                    this._notificationService.setMessage({
                      type: AppConstant.MESSAGE_TYPE.WARNING,
                      header: 'Add Rig',
                      content: error?.message || error,
                    });
                  },
                });
            },
            error: (error) => {
              console.error(error);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Add Rig',
                content: error?.message || error,
              });
            },
          });
      } else {
        this._rigService
          .addRig(payload)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
            catchError(AppHelper.UtileFunctions.handleError)
          )
          .subscribe({
            next: (response: any) => {
              console.info(response);

              this.displayFormRigDialog = !this.displayFormRigDialog;
              this.hideFormRigDialog.emit(this.displayFormRigDialog);
              this.reloadRigManagement.emit(true);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Add Rig',
                content: 'Rig was added successfully!',
              });
            },
            error: (error: any) => {
              console.error(error);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Add Rig',
                content: error?.message || error,
              });
            },
          });
      }
    } else {
      if (!payload.contractorId) {
        this._rigService
          .editContractor(
            this.rigEdit.contractorId,
            valueFormRaw.rigConstractor.contractorName ||
              valueFormRaw.rigConstractor
          )
          .subscribe({
            next: (updateContractor) => {
              payload.contractorId = updateContractor.contractor.contractorId;
              this._rigService
                .editRig(payload, this.rigEdit.rigId, this.rigEdit)
                .pipe(
                  finalize(() => {
                    this.isLoading = false;
                  }),
                  catchError(AppHelper.UtileFunctions.handleError)
                )
                .subscribe({
                  next: (response) => {
                    this._notificationService.setMessage({
                      type: AppConstant.MESSAGE_TYPE.SUCCESS,
                      header: 'Edit Rig',
                      content: 'Rig was updated successfully!',
                    });

                    this.displayFormRigDialog = !this.displayFormRigDialog;
                    this.hideFormRigDialog.emit(this.displayFormRigDialog);
                    this.reloadRigManagement.emit(true);
                  },
                  error: (error) => {
                    console.error(error);
                    this._notificationService.setMessage({
                      type: AppConstant.MESSAGE_TYPE.WARNING,
                      header: 'Edit Rig',
                      content: error?.message || error,
                    });
                  },
                });
            },
            error: (error) => {
              console.error(error);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Edit Rig',
                content: error?.messagee || error,
              });
            },
          });
      } else {
        this._rigService
          .editRig(payload, this.rigEdit.rigId, this.rigEdit)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
            catchError(AppHelper.UtileFunctions.handleError)
          )
          .subscribe({
            next: (response) => {
              console.info(response);

              this.displayFormRigDialog = !this.displayFormRigDialog;
              this.hideFormRigDialog.emit(this.displayFormRigDialog);
              this.reloadRigManagement.emit(false);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Edit Rig',
                content: 'Rig was updated successfully!',
              });
            },
            error: (error) => {
              console.error(error);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Edit Rig',
                content: error?.message || error,
              });
            },
          });
      }
    }
  }

  findName(type_id: string, separateMark: string): string {
    let name = '';
    let found = false;
    function traverse(node: any, prevName: string) {
      if (found) {
        return;
      }
      let currName = prevName
        ? prevName + ' ' + separateMark + ' ' + node.label
        : node.label;
      if (node.type_id === type_id) {
        name = currName;
        found = true;
        return;
      }
      if (node.children) {
        for (let child of node.children) {
          traverse(child, currName);
        }
      }
    }
    for (let item of AppConstant.RIG_TYPES) {
      traverse(item, '');
    }
    return name;
  }

  findTypeById(typeId: string): any {
    // Search for the type with the specified type_id
    for (const type of AppConstant.RIG_TYPES) {
      const result = this.searchChildren(type, typeId);
      if (result !== null) {
        return result;
      }
    }
    return null;
  }

  searchChildren(type: any, typeId: string): any {
    if (type.type_id === typeId) {
      return type;
    } else if (type.children) {
      for (const child of type.children) {
        const result = this.searchChildren(child, typeId);
        if (result !== null) {
          return result;
        }
      }
    }
    return null;
  }

  removeTheDraft() {
    if (this.formRigGroup.pristine && !this.formRigGroup.touched) {
      this.displayFormRigDialog = !this.displayFormRigDialog;
      this.hideFormRigDialog.emit(this.displayFormRigDialog);
    } else {
      this._confirmService.clearDialog()
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });
    }
  }

  closeDialogForm() {
    this.displayFormRigDialog = !this.displayFormRigDialog;
    this.hideFormRigDialog.emit(this.displayFormRigDialog);
  }

  filterRigName(event: any) {
    let query = event.query;
    const inputArray = _.uniqBy(this.RigNames, 'rigName');
    this.filteredRigName = _.filter(inputArray, (rig: any) =>
      rig.rigName.toLowerCase().includes(query.toLowerCase())
  );

  this.filteredRigName.push({ rigName: query, flag: 'new' });

  }
  clearRigName(event: any) {
    this.formRigGroup.get(this.fieldNameKeys.rigName)?.setValue('');
  }

  filterDrillingContractor(event: any) {
    let filtered: any[] = [];
    let query = event.query.trim();

    for (let i = 0; i < this.DrillingContractors.length; i++) {
      let contractor = this.DrillingContractors[i];
      if (
        contractor.contractorName.toLowerCase().includes(query.toLowerCase())
      ) {
        filtered.push(contractor);
      }
    }
    if (
      (filtered.length > 0 &&
        filtered.find((c: any) => c.contractorName === query.trim())) ||
      query.trim().length === 0
    ) {
    } else {
      filtered.push({ contractorName: query, flag: 'new' });
    }
    this.filteredDrillingContractor = filtered;
  }

  clearDrillingContractor(event: any) {
    this.formRigGroup.get(this.fieldNameKeys.rigConstractor)?.setValue('');
  }

  onSelect_image(file: any) {
    const errorUpload = this.uploadImage?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });

      this.onClear_image();
      return;
    }

    // add value for hidden input to clear message
    this.formRigGroup.get('rigImage')?.setValue(file.size);
    this.getRefresh('rigImage');

    this.uploadImage!._files = [file];

    this.progressUploadImage!.nativeElement.style.display = 'block';

    if (this.valueProgress === 100) {
      this.valueProgress = 0;
      this.trackingUpload[0] = false;
    }
    this.uploadImage!.showCancelButton = true;

    // pipe letter here
    this.uploadImage!.chooseLabel = AppHelper.StringFunctions.shortenFileName(
      file.name,
      12,
      6
    );

    this.fileImageLabel = file.name;

    this.lastImageLink = '';

    let interval = setInterval(() => {
      this.valueProgress =
        this.valueProgress +
        Math.floor(AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25);
      if (this.valueProgress >= 100) {
        this.valueProgress = 100;

        clearInterval(interval);
        this.progressUploadImage!.nativeElement.style.display = 'none';
        this.trackingUpload[0] = true;
      }
    }, 500);
  }

  showCropper(event: any): void {
    const errorUpload = this.uploadImage?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });

      this.onClear_image();
      return;
    }

    if (event.currentFiles[0]) {
      this.uploadImage!.showCancelButton = true;

      // pipe letter here
      this.uploadImage!.chooseLabel = AppHelper.StringFunctions.shortenFileName(
        event.currentFiles[0].name,
        12,
        6
      );

      const ref = this._dialogService.open(PhotoCropperComponent, {
        header: 'Edit Photo',
        width: '30.25rem',
        data: {
          file: event.currentFiles[0],
          isRound: false,
          ratio: 16 / 10,
        },
        baseZIndex: 1013,
        closable: true,
        closeOnEscape: true,
      });

      ref.onClose.subscribe({
        next: (res: any) => {
          if (res) {
            this.onSelect_image(res);
          } else {
            this.uploadImage!._files = [];
            this.uploadImage!.showCancelButton = false;
            this.uploadImage!.chooseLabel = 'Upload file';
            this.uploadImage?.cd.markForCheck();
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  onClear_image(e?: any) {
    this.lastImageLink = '';

    this.fileImageLabel = 'Browse file';

    this.uploadImage!.chooseLabel = 'Browse file';
    this.progressUploadImage!.nativeElement.style.display = 'none';
    this.uploadImage!.showCancelButton = false;

    this.formRigGroup.get('rigImage')?.patchValue(0);
    this.getRefresh('rigImage');
  }
  onSelect_specs(e: any) {
    let errorUpload = this.uploadSpecs?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });
      // handle button cancel when choose invalid type
      this.onClear_specs();
      return;
    }

    // add value for hidden input to clear message
    this.formRigGroup
      .get('rigSpecs')
      ?.setValue(this.uploadSpecs!._files[0].size);
    this.getRefresh('rigSpecs');

    this.progressUploadSpecs!.nativeElement.style.display = 'block';

    if (this.valueProgress === 100) {
      this.valueProgress = 0;
      this.trackingUpload[1] = false;
    }

    this.uploadSpecs!.showCancelButton = true;

    this.uploadSpecs!.chooseLabel = AppHelper.StringFunctions.shortenFileName(
      this.uploadSpecs!._files[0].name,
      12,
      6
    );

    this.fileSpecsLabel = this.uploadSpecs!._files[0].name;

    this.lastSpecsLink = '';

    let interval = setInterval(() => {
      this.valueProgress =
        this.valueProgress +
        Math.floor(AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25);
      if (this.valueProgress >= 100) {
        this.valueProgress = 100;

        // });
        clearInterval(interval);
        this.progressUploadSpecs!.nativeElement.style.display = 'none';
        this.trackingUpload[1] = true;
      }
    }, 1000);
  }
  onClear_specs(e?: any) {
    this.lastSpecsLink = '';

    this.fileSpecsLabel = 'Browse file';

    this.uploadSpecs!.chooseLabel = 'Browse file';
    this.progressUploadSpecs!.nativeElement.style.display = 'none';
    this.uploadSpecs!.showCancelButton = false;

    this.formRigGroup.get('rigSpecs')?.patchValue(0);
    this.getRefresh('rigSpecs');
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  getRefresh(controlName: string) {
    const control = this.formRigGroup.get(controlName);
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  // This function using for custom validators
  private customCorrectField(
    fieldNameKeys: any,
    regex: string,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;

        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        const reg = new RegExp(regex);

        let currentValue = currentControl?.value
          ? currentControl?.value.toString()
          : '';

        if (reg.test(currentValue ? currentValue.trim() : '')) {
          return null;
        }
        return { [keyValidator]: true };
      }
      return null;
    };
  }

  private checkSizeUploadField(
    fieldNameKeys: any,
    maxSizeCap: number,
    controlName: string,
    messageErrorKey: string
  ) {
    return (control: AbstractControl) => {
      if (!!control.parent?.controls) {
        const _formGroup = control.parent as FormGroup;

        const currentControl = _formGroup.get(fieldNameKeys[controlName]);
        const keyValidator = messageErrorKey;

        let currentValue = currentControl?.value;

        if (currentValue <= maxSizeCap) {
          return null;
        }
        return { [keyValidator]: true };
      }
      return null;
    };
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formRigGroup.get(controlName);

    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const controlValue = this.formRigGroup.get(controlName)?.value;

    const errorObj = this.formRigGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }
  onDestroy(): void {}
}
