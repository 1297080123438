import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Well, Wellbore } from 'src/app/shared/interface/interval.interface';
import { catchError, Observable, takeUntil, tap, throwError } from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { FileUpload } from 'primeng/fileupload';
import { HomeService } from 'src/app/shared/services/home.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RigInterval, RigWellbore } from 'src/app/shared/interface/rig.interface';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-home-form-interval',
  templateUrl: './home-form-interval.component.html',
  styleUrls: ['./home-form-interval.component.scss'],
})
export class HomeFormIntervalComponent extends BaseComponent {
  isLoading: boolean = false;

  @Input()
  displayIntervalForm: boolean = false;

  @Output()
  hide_editInterval: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  reload_editInterval: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  rigId: string = '';

  @Input()
  well!: Well;

  @Input()
  wellbore!: RigWellbore;

  @Input()
  interval!: RigInterval | null;

  @Input()
  projectId: string = '';

  @Input()
  projectUnit: string = '';

  @Input() isViewer: boolean = false;

  titlePopup = 'Add Interval';

  formUnitOld: any;
  formUnitNew: any;
  unitSystem: any;
  unitConverterDiameter: number = 0;
  unitConverterDepth: number = 0;
  digitDiameter: number = 0;
  digitDepth: number = 0;

  formUnit: any;

  format_casedHoleId = 0;
  format_lastShoeMeasuredDepth = 0;

  public fieldNameKeys = {
    wellName: 'wellName',
    wellboreName: 'wellboreName',
    wellId: 'wellId',
    wellboreId: 'wellboreId',
    intervalName: 'intervalName',
    operation: 'operation',
    casedHoleId: 'casedHoleId',
    lastShoeMeasuredDepth: 'lastShoeMeasuredDepth',
    openHoleDiameter: 'openHoleDiameter',
    designOfService: 'designOfService',
    check_casedHoleId_lastShoeMeasuredDepth:
      'check_casedHoleId_lastShoeMeasuredDepth',
    check_openHoleDiameter: 'check_openHoleDiameter',
    hiddenTextDesign: 'hiddenTextDesign',
  };

  // Variable of Form
  public formIntervalGroup: FormGroup = this._builder.group({
    [this.fieldNameKeys.wellId]: [null],
    [this.fieldNameKeys.wellName]: [null],
    [this.fieldNameKeys.wellboreId]: [null],
    [this.fieldNameKeys.wellboreName]: [null],
    [this.fieldNameKeys.intervalName]: [null],
    [this.fieldNameKeys.operation]: [null],
    [this.fieldNameKeys.casedHoleId]: [null],
    [this.fieldNameKeys.lastShoeMeasuredDepth]: [null],
    [this.fieldNameKeys.openHoleDiameter]: [null],
    [this.fieldNameKeys.check_openHoleDiameter]: [null],
    [this.fieldNameKeys.check_casedHoleId_lastShoeMeasuredDepth]: [null],
    [this.fieldNameKeys.hiddenTextDesign]: [null],
  });

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    na: 'N/A is not a permitted value.',
    maxlength: 'Please enter no more than 50 characters',
  };

  operations: any[] = [];

  selectedNodeOperation: any;
  selectedNodeOperationString: string = '';

  originSources: Observable<any>[] = [];

  // loading upload file
  value: number = 0;
  @ViewChild('uploadDesign') uploadDesign?: FileUpload;
  @ViewChild('progressUploadDesign') progressUploadDesign?: ElementRef;

  @ViewChild('input_casedHoleId', { read: ElementRef })
  input_casedHoleId?: ElementRef<HTMLElement>;

  // Max File Size Upload
  maxFileSizeUpload: number = 10000000;
  currentDesignSizeUpload: number = 0;

  fileLabel = 'Browse file';

  UNIT_SYSTEM: any = UNIT_SYSTEM;

  showCancelButtonUpLoad: boolean = false;
  lastPDFLink: string = '';

  AppHelper = AppHelper;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _builder: FormBuilder,
    private _homeService: HomeService,
    private _notificationService: NotificationService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    this.buildForm();
    this.isLoading = true;

    this.operations = [
      { name: 'Drilling', code: 'drilling' },
      { name: 'Completion', code: 'completion' },
      { name: 'Intervention', code: 'intervention' },
    ];

    this.formUnit = AppHelper.MathFunctions.findUnit(
      this.projectUnit,
      this.UNIT_SYSTEM.unit
    );

    this.generateForm();
  }

  ngAfterViewInit(): void {
    if (this.interval) {
      this.loadNameOfFiles(this.interval.designOfServiceUrl);
    }
  }

  generateForm() {
    if (this.interval) {
      this.buildFormEdit(this.interval);
      this.titlePopup = `Edit interval: ${this.interval.intervalName}`;

      // markAsPristine to check close when dont active form.
      if (this.interval && this.formIntervalGroup.pristine === false) {
        this.formIntervalGroup.markAsPristine();
      }

      this.isLoading = false;
    } else {
      this.buildForm();
      this.isLoading = false;
    }

    this.formIntervalGroup
      .get('check_casedHoleId_lastShoeMeasuredDepth')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          const casedHoleId = this.formIntervalGroup.get('casedHoleId');
          const lastShoeMeasuredDepth = this.formIntervalGroup.get(
            'lastShoeMeasuredDepth'
          );

          addCtrlValue === false
            ? (casedHoleId?.setValidators(Validators.required),
              lastShoeMeasuredDepth?.setValidators(Validators.required),
              casedHoleId?.enable(),
              lastShoeMeasuredDepth?.enable())
            : (casedHoleId?.setValidators([]),
              lastShoeMeasuredDepth?.setValidators([]),
              casedHoleId?.disable(),
              lastShoeMeasuredDepth?.disable());

          this.formIntervalGroup.get('casedHoleId')?.updateValueAndValidity();
          this.formIntervalGroup
            .get('lastShoeMeasuredDepth')
            ?.updateValueAndValidity();
        })
      )
      .subscribe();

    this.formIntervalGroup
      .get('check_openHoleDiameter')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((addCtrlValue) => {
          const openHoleDiameter =
            this.formIntervalGroup.get('openHoleDiameter');

          addCtrlValue === false
            ? (openHoleDiameter?.setValidators(Validators.required),
              openHoleDiameter?.enable())
            : (openHoleDiameter?.setValidators([]),
              openHoleDiameter?.disable());

          this.formIntervalGroup
            .get('openHoleDiameter')
            ?.updateValueAndValidity();
        })
      )
      .subscribe();
  }

  loadNameOfFiles(linkDesignOfService?: string) {
    this.lastPDFLink = linkDesignOfService ? linkDesignOfService : '';

    if (!linkDesignOfService) {
      this.fileLabel = 'Browse file';
      return;
    } else {
      const nameFile = AppHelper.UtileFunctions.getFileNameFromUrl(linkDesignOfService);

      this.fileLabel = nameFile;
      this.uploadDesign!._files = [];

      const uploadedFile = new File([new Blob()], nameFile, {
        type: 'application/pdf',
      });
      this.uploadDesign!._files.push(uploadedFile);

      this.formIntervalGroup
        .get('hiddenTextDesign')
        ?.setValue(uploadedFile.size);
      this.getRefresh('hiddenTextDesign');

      this.showCancelButtonUpLoad = true;
    }
  }

  closeIntervalPopup() {
    if (this.formIntervalGroup.touched || !this.formIntervalGroup.pristine) {
      this._confirmService.setDialog({
        ...this.confirmDialog,
        isVisible: true,
        header: 'Discard',
        haveDialogMessage: true,
        dialogMessage: 'Do you want to leave without saving information?',
        havePrimaryButton: true,
        primaryButtonLabel: 'Discard',
        isValidPrimaryButton: true,
        disablePrimaryButton: false,
        haveSecondaryButton: true,
        secondaryButtonLabel: 'Cancel',
        buttonEvent: (event: OptionButtonType) =>
          this.onButtonClickDialog(event, 'Draft'),
      });

    } else {
      this.hide_editInterval.emit(!this.displayIntervalForm);
    }
  }

  closeDialogForm() {
    this.hide_editInterval.emit(!this.displayIntervalForm);
  }

  saveInterval(event: any) {
    this.isLoading = true;

    let payload = {
      wellboreId: this.formIntervalGroup.get('wellboreId')?.value,
      rigId: this.rigId,
      intervalName: this.formIntervalGroup.get('intervalName')?.value,
      operation: this.formIntervalGroup.get('operation')?.value.code,
      casedHoleId: this.formIntervalGroup.get('casedHoleId')?.value,
      openHoleDiameter: this.formIntervalGroup.get('openHoleDiameter')?.value,
      lastShoeMeasuredDepth: this.formIntervalGroup.get('lastShoeMeasuredDepth')
        ?.value,
      intervalDesignOfService: this.lastPDFLink
        ? this.lastPDFLink
        : this.uploadDesign!._files[0],
      isNaCaseHoleLastShoe: this.formIntervalGroup.get(
        'check_casedHoleId_lastShoeMeasuredDepth'
      )?.value,
      isNaOpenHole: this.formIntervalGroup.get('check_openHoleDiameter')?.value,
      unit: this.projectUnit || '',
    };

    if (this.formIntervalGroup.status !== 'INVALID') {
      if (!this.interval) {
        this._homeService
          .addInterval(payload)
          .pipe(catchError(AppHelper.UtileFunctions.handleError))
          .subscribe({
            next: (response) => {
              console.info(response);
              this.isLoading = false;
            },
            error: (error) => {
              console.error(error);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Add Interval',
                content: error?.message || error,
              });
              this.isLoading = false;
            },
            complete: () => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Add Interval',
                content: 'Interval was created successfully!',
              });

              this.hide_editInterval.emit(false);
              this.reload_editInterval.emit([
                this.formIntervalGroup.get('wellId')?.value,
                this.formIntervalGroup.get('wellboreId')?.value,
                this.formUnit,
              ]);
              this.buildForm();
            },
          });
      } else {
        this._homeService
          .updateInterval(payload, this.interval.intervalId)
          .subscribe({
            next: (response) => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Edit Interval',
                content: 'Interval was edited successfully!',
              });
              this.isLoading = false;
            },
            error: (error) => {
              console.error(error);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Update Interval',
                content: error?.error?.message || error,
              });
              this.isLoading = false;
            },
            complete: () => {
              this.hide_editInterval.emit(false);
              this.reload_editInterval.emit([
                this.formIntervalGroup.get('wellId')?.value,
                this.formIntervalGroup.get('wellboreId')?.value,
              ]);
              this.buildForm();
            },
          });
      }
    }
  }

  buildForm() {
    this.formIntervalGroup = this._builder.group({
      [this.fieldNameKeys.wellId]: [{ value: this.well.id, disabled: true }],
      [this.fieldNameKeys.wellName]: [
        {
          value: this.well.currentName || this.well.originalName,
          disabled: true,
        },
      ],

      [this.fieldNameKeys.wellboreId]: [
        { value: this.wellbore.wellboreId, disabled: true },
      ],
      [this.fieldNameKeys.wellboreName]: [
        { value: this.wellbore.wellboreCurrentName || this.wellbore.wellboreOriginalName, disabled: true },
      ],
      [this.fieldNameKeys.intervalName]: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
          this.preventNA
        ],
      ],
      [this.fieldNameKeys.operation]: [
        '',
        Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.casedHoleId]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.lastShoeMeasuredDepth]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.openHoleDiameter]: [
        null,
        Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.check_openHoleDiameter]: [false],
      [this.fieldNameKeys.check_casedHoleId_lastShoeMeasuredDepth]: [false],
      [this.fieldNameKeys.hiddenTextDesign]: [''],
    });
  }

  buildFormEdit(interval: any) {
    // filter value edit interval
    let editCasedHoleId =
      interval.casedHoleId === 'null' ? null : interval.casedHoleId;
    let editLastShoeMeasuredDepth =
      interval.lastShoeMeasuredDepth === 'null'
        ? null
        : interval.lastShoeMeasuredDepth;
    let editOpenHoleDiameter =
      interval.openHoleDiameter === 'null' ? null : interval.openHoleDiameter;

    this.formIntervalGroup = this._builder.group({
      [this.fieldNameKeys.wellId]: [{ value: interval.wellId, disabled: true }],
      [this.fieldNameKeys.wellName]: [
        { value: interval.originalWellName, disabled: true },
      ],

      [this.fieldNameKeys.wellboreId]: [
        { value: interval.wellboreId, disabled: true },
      ],
      [this.fieldNameKeys.wellboreName]: [
        { value: interval.originWellboreName, disabled: true },
      ],

      [this.fieldNameKeys.intervalName]: [
        interval.intervalName,
        [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
          this.preventNA
        ],
      ],
      [this.fieldNameKeys.operation]: [
        this.operations.find((ope) => ope.code === interval.operation),
        Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.casedHoleId]: [
        {
          value: editCasedHoleId,
          disabled: this.interval?.isNaCaseHoleLastShoe ? true : false,
        },
        this.interval?.isNaCaseHoleLastShoe
          ? Validators.compose([])
          : Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.lastShoeMeasuredDepth]: [
        {
          value: editLastShoeMeasuredDepth,
          disabled: this.interval?.isNaCaseHoleLastShoe ? true : false,
        },
        this.interval?.isNaCaseHoleLastShoe
          ? Validators.compose([])
          : Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.openHoleDiameter]: [
        {
          value: editOpenHoleDiameter,
          disabled: this.interval?.isNaOpenHole ? true : false,
        },
        this.interval?.isNaOpenHole
          ? Validators.compose([])
          : Validators.compose([Validators.required]),
      ],
      [this.fieldNameKeys.check_casedHoleId_lastShoeMeasuredDepth]: [
        this.interval?.isNaCaseHoleLastShoe,
      ],
      [this.fieldNameKeys.check_openHoleDiameter]: [this.interval?.isNaOpenHole],
      [this.fieldNameKeys.hiddenTextDesign]: [''],
    });
  }

  private preventNA(control: FormControl) {
    const name: string = (control.value || '').trim();
    const isContain: Boolean  = name.toLowerCase() === 'n/a';

    return !isContain ? null : { na: true };
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error?.error?.message);
      return throwError('Something bad happened; please try again later.');
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
          `body was: ${error?.error?.message}`
      );
      return throwError(
        'Something bad happened; please try again later.',
        error.error
      );
    }
  }

  onSelect_design(event: any) {
    let errorUpload = this.uploadDesign?.msgs;
    if (errorUpload?.length !== 0) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: AppHelper.StringFunctions.capitalizeFirstLetter(
          errorUpload![0].detail || 'Error upload'
        ),
        content: AppHelper.UtileFunctions.replaceAllCharacter(
          errorUpload![0].summary || 'Invalid file type x',
          ',',
          '.'
        ),
      });

      this.onClear_image();
      return;
    }

    this.currentDesignSizeUpload = this.uploadDesign!._files[0].size;

    if (this.currentDesignSizeUpload > this.maxFileSizeUpload) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Upload Document',
        content: 'Maximum total upload size of Design is 10 MB.',
      });
      return;
    }

    this.formIntervalGroup.get('hiddenTextDesign')?.setValue('HaveValue');
    this.progressUploadDesign!.nativeElement.style.display = 'block';

    if (this.value === 100) {
      this.value = 0;
    }

    this.uploadDesign!.showCancelButton = true;

    // pipe letter here
    this.uploadDesign!.chooseLabel = AppHelper.StringFunctions.shortenFileName(
      this.uploadDesign!._files[0].name,
      12,
      7
    );

    this.fileLabel = this.uploadDesign!._files[0].name;

    this.lastPDFLink = '';

    // loading run
    let interval = setInterval(() => {
      this.value =
        this.value +
        Math.floor(AppHelper.MathFunctions.getRandomNumber() * (55 - 25) + 25);
      if (this.value >= 100) {
        this.value = 100;
        clearInterval(interval);
        this.progressUploadDesign!.nativeElement.style.display = 'none';
      }
    }, 1000);
  }
  onClear_design(event: any) {
    this.lastPDFLink = '';

    this.fileLabel = 'Browse file';

    this.uploadDesign!.chooseLabel = 'Browse file';
    this.progressUploadDesign!.nativeElement.style.display = 'none';
    this.uploadDesign!.showCancelButton = false;

    this.formIntervalGroup.get('hiddenTextDesign')?.patchValue('');
    this.getRefresh('hiddenTextDesign');

    this.currentDesignSizeUpload = 0;
  }
  onClear_image(e?: any) {
    this.fileLabel = 'Browse file';

    this.uploadDesign!.chooseLabel = 'Browse file';
    this.progressUploadDesign!.nativeElement.style.display = 'none';
    this.uploadDesign!.showCancelButton = false;

    this.formIntervalGroup.get('hiddenTextDesign')?.patchValue('');
    this.getRefresh('hiddenTextDesign');
  }

  getRefresh(controlName: string) {
    const control = this.formIntervalGroup.get(controlName);
    control?.clearValidators();
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  public inputNumber_Clear_e(
    event: any,
    controlName: string,
    limitDigit: number,
    unit: string,
    isCommaSeparated?: boolean
  ) {
    let inputValue = this.formIntervalGroup.get(controlName)?.value;

    let invalidChars: any;

    switch (unit) {
      case 'in':
        invalidChars = ['-', '+', 'e', 'E', '0'];
        break;
      case 'mm':
        invalidChars = ['-', '+', 'e', 'E', '.'];
        break;
      default:
        break;
    }

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }

    if (limitDigit === 0) {
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      } else {
        let inputValue = this.formIntervalGroup.get(controlName)?.value;
      }
    } else {
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      } else {
        let numberString = this.formIntervalGroup.get(controlName)?.value;
        numberString = numberString ? numberString.toString() : numberString;
        if (!numberString) return;

        let splitNumber = numberString.split('.');
        if (splitNumber.length === 1) {
        } else {
          let result = splitNumber[1] ? splitNumber[1] : '0';
          if (result.length === limitDigit) {
            event.preventDefault();
          }
        }
      }
    }

    if (isCommaSeparated) {
      inputValue = AppHelper.UtileFunctions.replaceAllCharacter(
        inputValue,
        ',',
        ''
      );
      inputValue = inputValue
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      this.formIntervalGroup.controls[controlName].setValue(inputValue);
    }
  }

  reset_digit_casedHoleId() {
    this.format_casedHoleId = 0;
  }

  reset_digit_lastShoeMeasuredDepth() {
    this.format_lastShoeMeasuredDepth = 0;
  }

  add_digit(event: any, controlName: string, maxDigit: number) {
    if (controlName === 'lastShoeMeasuredDepth')
      this.format_lastShoeMeasuredDepth = maxDigit;

    if (controlName === 'casedHoleId') this.format_casedHoleId = maxDigit;

    let valueInput = this.formIntervalGroup.controls[controlName].value || 0;
    if (valueInput) {
      this.formIntervalGroup.controls[controlName].setValue(
        Number.parseFloat(valueInput).toFixed(maxDigit)
      );
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const moreThanOneCharecter = control.value.length === 0;
    const isValid = !isWhitespace || moreThanOneCharecter;
    return isValid ? null : { whitespace: true };
  }

  public onBlurMethod(controlName: string, formGroupName: FormGroup) {
    let control = formGroupName.get(controlName);
    formGroupName.controls[controlName].patchValue(control?.value.trim());
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Draft':
            this.closeDialogForm();
            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  onDestroy(): void {}
}
