import { Component, Input, OnInit, ElementRef } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { HelpCenterService } from '../help-center.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Subject, filter, switchMap, take } from 'rxjs';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { CreateDialogComponent } from '../create-dialog/create-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { Menu } from 'primeng/menu';
import { TutorialService } from 'src/app/shared/services/tutorial.service';

@Component({
  selector: 'app-tabview-tutorial',
  templateUrl: './tabview-tutorial.component.html',
  styleUrls: ['./tabview-tutorial.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class TabviewTutorialComponent implements OnInit {
  @Input() roleView: string | undefined;
  isViewer: boolean = false;
  categories: any;
  editorEnable: boolean = false;
  itemSelected: any = {};
  createCategoryEnable: boolean = false;
  categoryMenu: any;
  categorySelect: any;
  chapterSelected: any;
  topicSelected: any;
  optionEnable: boolean = false;
  faCircleInfo = faCircleInfo;
  chapterMenu: any;
  CdkDragDrop: any;
  tempEditor: string = '';
  visibleWarningCancelEditItem: boolean = false;
  informationSwitchAndExitEditor: any;

  @ViewChild('nameCategory') nameCategory!: ElementRef;
  componentRef: ComponentRef<CreateDialogComponent> | undefined;
  @ViewChild('createContainer', { read: ViewContainerRef }) container: any;

  deleteComponentRef: ComponentRef<ConfirmDialogComponent> | undefined;
  @ViewChild('deleteContainer', { read: ViewContainerRef })
  deleteContainer: any;
  createCategoryForm!: FormGroup;
  indexSubChapter: any = {};
  isLoading: boolean = false;
  destroy$ = new Subject<void>();

  @ViewChild('menuChapter') menuChapter: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );
  
  constructor(
    private _tutorialService: TutorialService,
    private _helpCenterService: HelpCenterService,
    private sanitized: DomSanitizer,
    private fb: FormBuilder,
    public viewContainerRef: ViewContainerRef,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _collapseLayoutService: CollapseLayoutService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this._userInfoService.userSubject$.pipe(
      filter((userObject: any) => !!userObject),
      take(1),
    ).subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
        }
        this.loadInitialData();
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData() {
    this.initCategoryForm();
    this.getCategories();
    this.categoryMenu = [
      {
        items: [
          {
            label: 'Add a Tutorial',
            icon: PrimeIcons.PLUS,
            command: (event: any, category: any) => {
              this.createNewTopic();
            },
          },
        ],
      },
    ];
    
  }

  showContextMenu(event: any, inputData: any) {
  this.getMenuItemsForItem$.next(this.getMenuItemsForItem(inputData))
  this.menuChapter.toggle(event);
  }

  // MenuItem[]
  getMenuItemsForItem(topic: any): any{
    return  [
      {
        items: [
          {
            label: 'Rename',
            icon: PrimeIcons.PENCIL,
            command: () => {
              this.renameItem('RENAME_TOPIC');
            },
          },
          {
            label: topic.isHide ? 'Show' : 'Hide',
            icon: topic.isHide ? PrimeIcons.EYE : PrimeIcons.EYE_SLASH,
            command: () => {
              // 
              if (topic.isHide) {
                this.showItem();
              } else {
                this.hideItem();
              }
            },
          },
          {
            label: 'Delete',
            icon: PrimeIcons.TRASH,
            disabled: this.isViewer,
            command: () => {
              this.deleteItem('TOPIC');
            },
          },
        ],
      },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onMoveToSource(event: any) {}

  dropCategory(event: CdkDragDrop<string[]>) {
    this.CdkDragDrop = event;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (event.previousIndex !== event.currentIndex) {
        const dataIndex: any = [];
        event.container.data.forEach((item: any) => {
          dataIndex.push(item.categoryId);
        });

        this._helpCenterService
          .updateIndexCategory({
            indexModule: btoa(dataIndex),
          })
          .subscribe({
            next: (data) => {
              // Trigger Get New Tutorial Panel
              if(this._collapseLayoutService.getCollapsePanelLayout()) {
                this._collapseLayoutService.triggerResetTutorialPanel();
              }
            },
            error: (error) => {
              console.log('error: ', error);
            },
            complete: () => {},
          });
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  dropChapter(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (event.previousIndex !== event.currentIndex) {
        const key = event.container.data[0].categoryId;
        const dataIndex: any = [];
        event.container.data.forEach((item: any) => {
          dataIndex.push(item.topicId);
        });
        this.indexSubChapter[key] = Object.assign([], dataIndex);
        this._helpCenterService
          .updateIndexCategory({
            indexTopic: btoa(JSON.stringify(this.indexSubChapter)),
          })
          .subscribe({
            next: (data) => {
              // Trigger Get New Tutorial Panel
              if(this._collapseLayoutService.getCollapsePanelLayout()) {
                this._collapseLayoutService.triggerResetTutorialPanel();
              }
            },
            error: (error) => {
              console.log('error: ', error);
            },
            complete: () => {},
          });
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  //

  getCategories(callback?: Function) {
    this.isLoading = true;
    this._helpCenterService
      .getCategories(AppConstant.CATEGORY_TUTORIAL_BASE64)
      .subscribe({
        next: (categories: any) => {
          let parseData;
          try {
            parseData = JSON.parse(atob(categories.indexTopicArray));
          } catch (error) {
            parseData = {};
          }
          this.indexSubChapter = parseData;
          //
          if (!categories.data.length) {
            return (this.categories = []);
          }
          let categoriesIndex: any = [];
          try {
            categoriesIndex = atob(categories.indexModuleArray).split(',');
          } catch (e) {
            categoriesIndex = [];
            console.log('error : ', e);
          }
          if (categories.data.length === categoriesIndex.length) {
            const tempArray: any[] = [];
            categoriesIndex.forEach((item: any) => {
              const temp = categories.data.find(
                (element: any) => element.categoryId === item
              );
              if (temp) {
                tempArray.push(temp);
              }
            });
            // check count data valid second
            if (tempArray.length === categories.data.length) {
              this.categories = tempArray;
            } else {
              this.categories = categories.data;
            }
          } else {
            this.categories = categories.data;
          }
          if (callback) {
            callback();
          }
          
          // Handle by role
          this.categories = this.categories.filter((tutorial: any) => tutorial.isHide === false);

          const path = this._tutorialService.getCurrentPathSubject();
          const tutorialList = this._tutorialService.getUserTutorialSubject();
            
          if (path && tutorialList.length) {
            const contains = this.categories.filter((category: any) => {
              return tutorialList.find((path: any) => path.title.toLowerCase() === category.categoryName.toLowerCase());
            });
            this.categories = contains;
          }
        },
        error: () => {
          this.categories = [];
        },
        complete: () => {
          if (
            this.categories.length &&
            Object.keys(this.indexSubChapter).length
          ) {
            this.categories.forEach((category: any) => {
              let tempArrChapter: any = [];

              // Category sort
              if (this.indexSubChapter[category.categoryId]) {
                this.indexSubChapter[category.categoryId].forEach(
                  (element: any) => {
                    const itemTemp: any = category.topicList.find(
                      (elm: any) => elm.topicId === element
                    );
                    if (itemTemp) {
                      tempArrChapter.push(
                        category.topicList.find(
                          (elm: any) => elm.topicId === element
                        )
                      );
                    }
                  }
                );
                // check count
                if (tempArrChapter.length === category.topicList.length) {
                  category.topicList = Object.assign([], tempArrChapter);
                }
              }
            });
          }
          //
          if (this.itemSelected.id === undefined && this.categories.length) {
            this.categorySelect = this.categories[0];
            this._helpCenterService
              .getCategory(this.categories[0].categoryId)
              .subscribe({
                next: (response: any) => {
                  this.itemSelected = response.data;
                  this.itemSelected.content = response.content;
                  this.tempEditor = response.content;
                  this.itemSelected.type = 'CATEGORY';
                  this.itemSelected.name = response.data.categoryName;
                  this.itemSelected.id = response.data.categoryId;
                  this.isLoading = false;
                },
              });
          } else {
            this.isLoading = false;
          }
          const tempArr: any = [];
          this.categories.forEach((item: any) => {
            tempArr.push(item.categoryName.trim());
          });
          this.createCategoryForm.controls['name'].clearValidators();
          this.createCategoryForm.controls['name'].setValidators([
            Validators.required,
            Validators.maxLength(50),
            this.validatorBlank,
            this.validatorDuplicated(tempArr),
          ]);
        },
      });
  }

  // Function Create Category

  openCreateCategoryForm() {
    this.createCategoryEnable = true;
    this.createCategoryForm.reset();
    setTimeout(() => {
      this.nameCategory?.nativeElement.focus();
      const domElement = this.nameCategory.nativeElement as HTMLElement;
      domElement.scrollIntoView();
    });
  }

  addNewCategory() {
    if (this.isViewer) return;
    this.createCategoryForm.controls['name'].markAsDirty();
    if (this.createCategoryForm.status === 'VALID') {
      this.isLoading = true;
      // (A) CREATE BLOB OBJECT
      const myBlob = new Blob([''], {
        type: 'text/plain',
      });
      const file = new File([myBlob], 'description.txt', {
        type: 'text/plain',
      });
      //
      const dataCategory = new FormData();
      dataCategory.append(
        'categoryName',
        AppHelper.UtileFunctions.encodeBase64(
          this.createCategoryForm.controls['name'].value.trim()
        )
      );
      dataCategory.append('description', file);
      dataCategory.append('categoryType', 'tutorial');
      this._helpCenterService
        .createCategory(dataCategory)
        .pipe(
          switchMap((response) => {
            const dataIndex: any = [];
            this.categories.forEach((item: any) => {
              dataIndex.push(item.categoryId);
            });
            dataIndex.push(response.data.categoryId);
            return this._helpCenterService.updateIndexCategory({
              indexGuide: btoa(dataIndex),
            });
          })
        )
        .subscribe({
          next: (response) => {},
          error: (error) => {
            console.log('error = ', error);
          },
          complete: () => {
            this.getCategories(() => {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Create Module',
                content: `${this.createCategoryForm.controls[
                  'name'
                ].value.trim()} was created successfully!`,
              });
            });
            this.createCategoryEnable = false;
          },
        });
    }
  }

  cancelAddNewCategory() {
    this.createCategoryEnable = false;
    this.createCategoryForm.reset();
  }

  initCategoryForm() {
    this.createCategoryForm = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.maxLength(50), this.validatorBlank],
      ],
    });
  }

  validatorBlank(control: AbstractControl) {
    if (control.value?.trim() === '') {
      return { blank: true };
    } else {
      return null;
    }
  }

  validatorDuplicated(arrayNameValidator: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const dupplicated = arrayNameValidator.find(
        (item: any) =>
          item.trim().toLowerCase() === control.value?.trim().toLowerCase()
      );
      return dupplicated === undefined ? null : { duplicated: true };
    };
  }
  // Function Editor Description

  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  onShowEditor() {
    if (this.isViewer) return;
    if (this.categorySelect !== null || this.chapterSelected !== null) {
      this.editorEnable = true;
      this.tempEditor = this.itemSelected.content;
    }
  }
  onHideEditor() {
    this.editorEnable = false;
    this.tempEditor = this.itemSelected.content;
  }

  selectedCategoryAndLoadDescription(category: any) {
    this.isLoading = true;
    this.categorySelect = category;
    this._helpCenterService.getCategory(category.categoryId).subscribe({
      next: (response: any) => {
        this.itemSelected = response.data;
        this.itemSelected.content = response.content;
        this.itemSelected.type = 'CATEGORY';
        this.itemSelected.name = response.data.categoryName;
        this.itemSelected.id = response.data.categoryId;
        this.tempEditor = this.itemSelected.content || '';
      },
      error: () => {},
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  selectedChapterAndLoadDescription(chapter: any) {
    this.isLoading = true;
    this.chapterSelected = chapter;
    this._helpCenterService.getChapter(chapter.chapterId).subscribe({
      next: (response: any) => {
        this.itemSelected = response.data;
        this.itemSelected.content = response.content;
        this.itemSelected.type = 'CHAPTER';
        this.itemSelected.name = response.data.chapterName;
        this.itemSelected.id = response.data.chapterId;
        this.tempEditor = this.itemSelected.content || '';
      },
      error: () => {},
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  selectedTopicAndLoadDescription(topic: any) {
    this.isLoading = true;
    this.topicSelected = topic;
    this._tutorialService.getTopic(topic.topicId).subscribe({
      next: (response: any) => {
        this.itemSelected = response.data;
        this.itemSelected.content = response.content;
        this.itemSelected.type = 'TOPIC';
        this.itemSelected.name = response.data.topicName;
        this.itemSelected.id = response.data.topicId;
        this.tempEditor = this.itemSelected.content || '';
      },
      error: () => {},
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  updateDescriptionItem() {
    this.isLoading = true;
    this.itemSelected.content = this.tempEditor;
    switch (this.itemSelected.type) {
      case 'CATEGORY': {
        // (A) CREATE BLOB OBJECT
        const myBlob = new Blob([this.itemSelected.content || ''], {
          type: 'text/plain',
        });
        const file = new File([myBlob], 'description.txt', {
          type: 'text/plain',
        });
        // (B) FORM DATA
        const data = new FormData();
        data.append('description', file);
        const id = this.itemSelected.id;
        this._helpCenterService.updateCategory(id, data).subscribe({
          next: (data: any) => {},
          error: (error) => {
            console.log('error = ', error);
          },
          complete: () => {
            this.onHideEditor();
            this.getCategories();
          },
        });
        break;
      }
      case 'CHAPTER': {
        // (A) CREATE BLOB OBJECT
        const myBlob = new Blob([this.itemSelected.content || ''], {
          type: 'text/plain',
        });
        const file = new File([myBlob], 'description.txt', {
          type: 'text/plain',
        });
        // (B) FORM DATA
        const data = new FormData();
        data.append(
          'chapterName',
          AppHelper.UtileFunctions.encodeBase64(
            this.chapterSelected.chapterName
          )
        );
        data.append('description', file);
        const id = this.itemSelected.id;
        this._helpCenterService.updateChapter(id, data).subscribe({
          next: (data: any) => {
            // Trigger Get New Tutorial Panel
            if(this._collapseLayoutService.getCollapsePanelLayout()) {
              this._collapseLayoutService.triggerResetTutorialPanel();
            }
          },
          error: (error) => {
            console.log('error = ', error);
          },
          complete: () => {
            this.onHideEditor();
            this.getCategories();
          },
        });
        break;
      }
      case 'TOPIC': {
        // (A) CREATE BLOB OBJECT
        const myBlob = new Blob([this.itemSelected.content || ''], {
          type: 'text/plain',
        });
        const file = new File([myBlob], 'description.txt', {
          type: 'text/plain',
        });
        // (B) FORM DATA
        const data = new FormData();
        data.append(
          'topicName',
          AppHelper.UtileFunctions.encodeBase64(
            this.topicSelected.topicName
          )
        );
        data.append('description', file);
        const id = this.itemSelected.id;
        this._tutorialService.updateTopic(id, data).subscribe({
          next: (data: any) => {
            // Trigger Get New Tutorial Panel
            if(this._collapseLayoutService.getCollapsePanelLayout()) {
              this._collapseLayoutService.triggerResetTutorialPanel();
            }
          },
          error: (error) => {
            console.log('error = ', error);
          },
          complete: () => {
            this.onHideEditor();
            this.getCategories();
          },
        });
        break;
      }
    }
  }

  //
  ShowConfirmSwitchItemAndDisableEditor(item: any, type: string) {
    switch (type) {
      case 'CATEGORY': {
        if (this.editorEnable) {
          this.visibleWarningCancelEditItem = true;
          this.informationSwitchAndExitEditor = {
            type: type,
            item: item,
          };
        } else {
          this.selectedCategoryAndLoadDescription(item);
        }

        break;
      }
      case 'CHAPTER': {
        if (this.editorEnable) {
          this.visibleWarningCancelEditItem = true;
          this.informationSwitchAndExitEditor = {
            type: type,
            item: item,
          };
        } else {
          this.selectedChapterAndLoadDescription(item);
        }
        break;
      }
      case 'TOPIC': {
        if (this.editorEnable) {
          this.visibleWarningCancelEditItem = true;
          this.informationSwitchAndExitEditor = {
            type: type,
            item: item,
          };
        } else {
          this.selectedTopicAndLoadDescription(item);
        }
      }
    }
  }

  confirmSwitchItemAndDisableEditor(informationSwitchAndExitEditor: any) {
    switch (informationSwitchAndExitEditor.type) {
      case 'CATEGORY': {
        this.selectedCategoryAndLoadDescription(
          informationSwitchAndExitEditor.item
        );
        this.editorEnable = false;
        this.visibleWarningCancelEditItem = false;
        break;
      }
      case 'CHAPTER': {
        this.selectedChapterAndLoadDescription(
          informationSwitchAndExitEditor.item
        );
        this.editorEnable = false;
        this.visibleWarningCancelEditItem = false;

        break;
      }
    }
  }

  // Function delete pop up

  deleteItem(type: string) {
    let informationDeleteDialog;

    switch (type) {
      case 'CATEGORY': {
        const tempArr: any = [];
        this.categories.forEach((item: any) => {
          tempArr.push(item.categoryId);
        });
        // remove item in index-category
        const filteredArray = tempArr.filter(
          (e: string) => e !== this.categorySelect.categoryId
        );
        // remove item in index sub-chapter
        const indexSubChapterTemp: any = Object.assign(
          {},
          this.indexSubChapter
        );
        if (indexSubChapterTemp[this.categorySelect.categoryId]) {
          delete indexSubChapterTemp[this.categorySelect.categoryId];
        }
        informationDeleteDialog = {
          header: `Are you sure to delete module  -  ${this.categorySelect.categoryName} ?`,
          titleCheckbox: 'Yes, I want to delete the module.',
          type: 'DELETE_CATEGORY_TUTORIAL',
          itemSelected: this.categorySelect,
          action: 'Delete Module',
          indexArray: btoa(filteredArray),
          indexArraySubChapterMaster: indexSubChapterTemp,
        };
        // IMPORTANT NOTE: category --> chapter
        break;
      }
      case 'CHAPTER': {
        // Create assign sub-chapter-index master
        const indexSubChapterTemp: any = Object.assign(
          {},
          this.indexSubChapter
        );
        // Create sub-chapter index for category selected
        const tempArrSubChapterOption: any = [];
        this.categorySelect.chapterList.forEach((item: any) => {
          tempArrSubChapterOption.push(item.chapterId);
        });
        // check sub-chapterindex item existed ?
        if (!indexSubChapterTemp[this.categorySelect.categoryId]) {
          indexSubChapterTemp[this.categorySelect.categoryId] =
            tempArrSubChapterOption;
        }
        // removed item in subchapter index
        indexSubChapterTemp[this.categorySelect.categoryId] =
          indexSubChapterTemp[this.categorySelect.categoryId].filter(
            (item: any) => item !== this.itemSelected.chapterId
          );

        informationDeleteDialog = {
          header: `Are you sure you want to delete this subchapter (${this.chapterSelected.chapterName}) from WellCare?`,
          titleCheckbox: 'Yes, I want to delete the subchapter.',
          type: 'DELETE_CHAPTER',
          itemSelected: this.chapterSelected,
          action: 'Delete Subchapter',
          indexArraySubChapterMaster: indexSubChapterTemp,
          categoryIdSelected: this.categorySelect.categoryId,
        };
        break;
      }
      case 'TOPIC': {
        // Create assign sub-chapter-index master
        const indexSubChapterTemp: any = Object.assign(
          {},
          this.indexSubChapter
        );
        // Create sub-chapter index for category selected
        const tempArrSubChapterOption: any = [];
        this.categorySelect.chapterList.forEach((item: any) => {
          tempArrSubChapterOption.push(item.chapterId);
        });
        // check sub-chapterindex item existed ?
        if (!indexSubChapterTemp[this.categorySelect.categoryId]) {
          indexSubChapterTemp[this.categorySelect.categoryId] =
            tempArrSubChapterOption;
        }
        // removed item in subchapter index
        indexSubChapterTemp[this.categorySelect.categoryId] =
          indexSubChapterTemp[this.categorySelect.categoryId].filter(
            (item: any) => item !== this.itemSelected.chapterId
          );
          
        informationDeleteDialog = {
          header: `Are you sure you want to delete this topic (${this.topicSelected.topicName}) from WellCare?`,
          titleCheckbox: 'Yes, I want to delete the topic.',
          type: 'DELETE_TOPIC',
          itemSelected: this.topicSelected,
          action: 'Delete Topic',
          indexArraySubChapterMaster: indexSubChapterTemp,
          categoryIdSelected: this.categorySelect.categoryId,
        };
        break;
      }
    }
    //
    this.createDynamicConfirmPopup(informationDeleteDialog);
  }

  createDynamicConfirmPopup(informationDeleteDialog: any) {
    this.deleteComponentRef = this.deleteContainer.createComponent(
      ConfirmDialogComponent
    );
    this.deleteComponentRef!.instance.informDisplay = informationDeleteDialog;
    this.deleteComponentRef!.instance.visible = true;
    this.deleteComponentRef!.instance.onDestroy.subscribe((event) => {
      this.deleteComponentRef?.destroy();
    });
    this.deleteComponentRef!.instance.completed.subscribe((event) => {
      this.categorySelect = null;
      this.chapterSelected = null;
      this.tempEditor = '';
      this.editorEnable = false;
      this.itemSelected = {};
      this.getCategories(() => {

        if (informationDeleteDialog.type === 'DELETE_TOPIC') {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: informationDeleteDialog.action,
            content: `${informationDeleteDialog.itemSelected.topicName} was deleted successfully!`,
          });
        } else {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: informationDeleteDialog.action,
            content: `${
              informationDeleteDialog.type === 'DELETE_CHAPTER'
              ? `${informationDeleteDialog.itemSelected.chapterName}`
              : `${informationDeleteDialog.itemSelected.categoryName}`
            } was deleted successfully!`,
          });
        }
      });
    });
  }

  // Function create pop up

  renameItem(type: string) {
    let informationCreateDialog;
    const tempArr: any = [];
    switch (type) {
      case 'RENAME_CATEGORY_TUTORIAL': {
        this.categories.forEach((item: any) => {
          tempArr.push(item.categoryName);
        });
        informationCreateDialog = {
          type: 'RENAME_CATEGORY_TUTORIAL',
          header: 'Rename Module',
          message: 'Module name',
          valueUpdated: '',
          action: 'rename-tutorial',
          itemSlected: this.categorySelect,
          arrayNameValidator: tempArr,
        };
        break;
      }
      case 'RENAME_TOPIC': {
        this.categorySelect.topicList.forEach((item: any) => {
          tempArr.push(item.topicName);
        });
        informationCreateDialog = {
          type: 'RENAME_TOPIC',
          header: 'Rename Topic',
          message: 'Topic name',
          valueUpdated: '',
          action: 'rename-chapter',
          arrayNameValidator: tempArr,
          itemSlected: this.topicSelected,
        };
        break;
      }
      case 'UPDATE_ICON_CATEGORY_TUTORIAL': {
        this.categories.forEach((item: any) => {
          tempArr.push(item.categoryName);
        });
        informationCreateDialog = {
          type: 'UPDATE_ICON_CATEGORY_TUTORIAL',
          header: 'Update Module Icon',
          message: 'Module Icon',
          valueUpdated: '',
          action: 'update-icon-tutorial',
          itemSlected: this.categorySelect,
          arrayNameValidator: tempArr,
        };
        break;
      }
      //
    }
    this.createDynamicPopup(informationCreateDialog);
  }

  hideItem() {
    let informationCreateDialog = {
      type: 'HIDE_TOPIC',
      header: 'Hide Topic',
      message: 'Are you sure to hide this topic?',
      itemSlected: this.topicSelected,
    };
    const id = informationCreateDialog.itemSlected.topicId;
    const dataTopic = new FormData();
    dataTopic.append('isHide','true');
    this._tutorialService.updateTopic(id, dataTopic).subscribe({
      next: () => {
        // Trigger Get New Tutorial Panel
        if(this._collapseLayoutService.getCollapsePanelLayout()) {
          this._collapseLayoutService.triggerResetTutorialPanel();
        }
        this.getCategories(() => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: informationCreateDialog.header,
            content: `This topic was hidden successfully!`,
          });
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  showItem() {
    let informationCreateDialog = {
      type: 'SHOW_TOPIC',
      header: 'Show Topic',
      message: 'Are you sure to show this topic?',
      itemSlected: this.topicSelected,
    };

    const id = informationCreateDialog.itemSlected.topicId;
    const dataTopic = new FormData();
    dataTopic.append('isHide','false');

    this._tutorialService.updateTopic(id, dataTopic).subscribe({
      next: () => {
        // Trigger Get New Tutorial Panel
        if(this._collapseLayoutService.getCollapsePanelLayout()) {
          this._collapseLayoutService.triggerResetTutorialPanel();
        }
        this.getCategories(() => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: informationCreateDialog.header,
            content: `This topic was shown successfully!`,
          });
        });
      },
      error: () => {},
      complete: () => {},
    })
  }

  createNewTopic() {
    const tempArr: any = [];
    const tempArrOption: any = [];
    this.categorySelect.topicList.forEach((item: any) => {
      tempArr.push(item.topicName);
      tempArrOption.push(item.topicId);
    });
    const informationCreateDialog = {
      type: 'CREATE_TOPIC',
      header: 'Create Topic',
      message: 'Topic name',
      valueUpdated: '',
      arrayNameValidator: tempArr,
      itemSlected: this.categorySelect,
      indexArraySubChapterMaster: this.indexSubChapter,
      indexArraySubChapterOption: tempArrOption,
    };
    this.createDynamicPopup(informationCreateDialog);
  }


  createDynamicPopup(informationCreateDialog: any) {
    this.componentRef = this.container.createComponent(CreateDialogComponent);
    this.componentRef!.instance.informationDialog = informationCreateDialog;
    this.componentRef!.instance.visible = true;
    this.componentRef!.instance.isViewer = this.isViewer;
    this.componentRef!.instance.completed.subscribe((message) => {
      this.getCategories(() => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: informationCreateDialog.header,
          content: message,
        });
      });
    });
    this.componentRef!.instance.onDestroy.subscribe((event) => {
      this.componentRef?.destroy();
    });
  }
}
