import { HomeFormWellboreComponent } from './home-form-wellbore/home-form-wellbore.component';
import { HomeRigItemRunComponent } from './home-rig-item-run/home-rig-item-run.component';
import { HomeRigItemIntervalComponent } from './home-rig-item-interval/home-rig-item-interval.component';
import { HomeRigItemWellboreComponent } from './home-rig-item-wellbore/home-rig-item-wellbore.component';
import { HomeProjectItemTableComponent } from './home-project-item-table/home-project-item-table.component';
import { FormsModule } from '@angular/forms';
import { HomeProjectItemComponent } from './home-project-item/home-project-item.component';
import { HomeProjectListComponent } from './home-project-list/home-project-list.component';
import { HomeRigItemComponent } from './home-rig-item/home-rig-item.component';
import { HomeRigListComponent } from './home-rig-list/home-rig-list.component';
import { HomeService } from '../../shared/services/home.service';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { HomeFormRunComponent } from './home-form-run/home-form-run.component';
import { HomeAdvancedFilterComponent } from './home-advanced-filter/home-advanced-filter.component';
import { UnitInputModule } from 'src/app/shared/directives/unit-input-directive/unit-input.module';
import { HomeFormIntervalComponent } from './home-form-interval/home-form-interval.component';
import { HomeFilterService } from 'src/app/shared/services/home-filter.service';

const declarations = [
  HomeProjectListComponent,
  HomeRigListComponent,
  HomeRigItemComponent,
  HomeProjectItemComponent,
  HomeProjectItemTableComponent,
  HomeRigItemWellboreComponent,
  HomeRigItemIntervalComponent,
  HomeRigItemRunComponent,
  HomeFormIntervalComponent,
  HomeFormRunComponent,
  HomeFormWellboreComponent,
  HomeAdvancedFilterComponent
];

const imports: any = [ShareModule, FormsModule, UnitInputModule];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [
    HomeProjectListComponent,
    HomeRigListComponent,
    HomeRigItemComponent,
  ],
  providers: [HomeService, HomeFilterService],
})
export class HomeModule {}
