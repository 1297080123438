export const MAILBOX_ADVANCED_FILTER = {
  DISCIPLINE: {
    LABEL: 'Discipline',
    OPTION: {},
  },
  REMOTE_CENTER: {
    LABEL: 'Remote Center',
    OPTION: {},
  },
  OPTION: {
    PLACE_HOLDER: 'All',
  },
};
