<div class="add-video" [formGroup]="formGroupAddVideo">
  <div class="category">
    <p class="title-category">
      Category<span class="required-asterisk">*</span>
    </p>
    <p-dropdown
      class="custom-p-drop"
      [options]="categoryOptions"
      optionLabel="name"
      placeholder="Select"
      formControlName="category"
    ></p-dropdown>
  </div>
  <div class="upload-section">
    <p class="upload-title">
      Video<span class="required-asterisk">*</span>
    </p>
    <div class="upload-input">
      <p>Only .mp4 with max size 256MB</p>
      <div class="flex flex-row gap-1">
        <p-fileUpload
          #videoUploader
          mode="basic"
          name="demo[]"
          accept=".mp4"
          [maxFileSize]="256000000"
          [auto]="false"
          chooseLabel="Browse file"
          uploadLabel="pi pi-upload"
          chooseIcon="pi pi-upload"
          chooseStyleClass="button-choose-upload"
          (onClear)="onUploaderClear('video')"
          (onSelect)="onUploaderSelect('video', $event)"
          ></p-fileUpload>
        <i
          class="pi pi-times m-1 icon-clear-file"
          (click)="videoUploader.clear()"
          *ngIf="videoUploader._files.length"
        ></i>
      </div>
    </div>
  </div>
  <div class="upload-section">
    <p class="upload-title">
      Thumbnail<span class="required-asterisk">*</span>
    </p>
    <div class="upload-input">
      <p>Only .png, .jpeg, .jpg with max size 10MB</p>
      <div class="flex flex-row gap-1">
        <p-fileUpload
          #thumbnailUploader
          mode="basic"
          name="demo[]"
          accept=".png, .jpeg, .jpg"
          [maxFileSize]="10000000"
          [auto]="false"
          chooseLabel="Browse file"
          uploadLabel="pi pi-upload"
          chooseIcon="pi pi-upload"
          chooseStyleClass="button-choose-upload"
          (onClear)="onUploaderClear('thumbnail')"
          (onSelect)="onUploaderSelect('thumbnail', $event)"
          ></p-fileUpload>
        <i
          class="pi pi-times m-1 icon-clear-file"
          (click)="thumbnailUploader.clear()"
          *ngIf="thumbnailUploader._files.length"
        ></i>
      </div>
    </div>
  </div>
  <p class="description-title">Title<span class="required-asterisk">*</span></p>
  <input
    type="text"
    pInputText
    class="custom-p-input"
    placeholder="Enter title..."
    formControlName="title"
  />
  <div *ngIf="this.formGroupAddVideo.get('title')?.errors?.maxlength" class="inner-msg-error">
    <span>Please enter no more than 200 characters</span>
  </div>

  <p class="description-title">
    Description<span class="required-asterisk">*</span>
  </p>
  <textarea
    pInputTextarea
    placeholder="Please provide description here..."
    class="description-content custom-p-input"
    formControlName="description"
  ></textarea>
  <div *ngIf="this.formGroupAddVideo.get('description')?.errors?.maxlength" class="inner-msg-error">
    <span>Please enter no more than 2000 characters</span>
  </div>
  
  <div class="d-flex justify-content-end mt-4 gap-2">
    <p-button
      [attr.data-cy]="'cancel-button'"
      type="button"
      label="Cancel"
      class="custom-p-button custom-p-button--primary"
      (onClick)="onCancel()"
    ></p-button>
    <p-button
      [attr.data-cy]="'add-button'"
      type="button"
      label="Add"
      class="custom-p-button custom-p-button--secondary"
      (onClick)="onAddVideo()"
      [ngClass]="
        formGroupAddVideo.status === 'INVALID' || isViewer
          ? 'custom-p-button custom-p-button--disable'
          : ''
      "
      [disabled]="formGroupAddVideo.status === 'INVALID' || isViewer"
    ></p-button>
  </div>
</div>