import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
  providers: [MessageService],
})
export class AddVideoComponent implements OnInit {
  @ViewChild('videoUploader') videoUploader!: FileUpload;
  @ViewChild('thumbnailUploader') thumbnailUploader!: FileUpload;

  isViewer: boolean = false;
  categoryOptions: { name: string; id: string }[] = [];
  formGroupAddVideo: FormGroup = this.fb.group({
    category: ['', [Validators.required]],
    video: [null, [Validators.required]],
    thumbnail: [null, [Validators.required]],
    title: ['', [Validators.required, Validators.maxLength(200)]],
    description: ['', [Validators.required, Validators.maxLength(2000)]],
  });

  constructor(
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    
    if (this.config.data.isViewer) {
      this.isViewer = this.config.data.isViewer;
    }

    this.categoryOptions = this.config.data.categories.map((category: any) => {
      return {
        name: category.categoryName,
        categoryId: category.categoryId
      };
    });

    const selectedCategory = this.categoryOptions.find(
      (category) =>
        (category.name as string) === this.config.data.itemSelected.categoryName
    );

    if (selectedCategory)
      this.formGroupAddVideo.patchValue({
        category: selectedCategory,
      });

    this.preventWhiteSpace('title');
    this.preventWhiteSpace('description');
  }

  private buildPayload(): FormData {
    const payload = new FormData();
    const fileNameVideo = AppHelper.UtileFunctions.encodeFileName(this.formGroupAddVideo.value.video.currentFiles[0].name);
    const fileNameThumbnail = AppHelper.UtileFunctions.encodeFileName(this.formGroupAddVideo.value.thumbnail.currentFiles[0].name);

    payload.append(
      'categoryId',
      this.formGroupAddVideo.value.category.categoryId
    );
    payload.append(
      'video',
      this.formGroupAddVideo.value.video.currentFiles[0],
      fileNameVideo
    );
    payload.append(
      'thumbnail',
      this.formGroupAddVideo.value.thumbnail.currentFiles[0],
      fileNameThumbnail
    );
    payload.append('title', AppHelper.UtileFunctions.encodeBase64(this.formGroupAddVideo.value.title));
    payload.append('description', AppHelper.UtileFunctions.encodeBase64(this.formGroupAddVideo.value.description));
    return payload;
  }

  public onCancel(): void {
    this.ref.close();
  }

  public onAddVideo(): void {
    this.ref.close(this.buildPayload());
  }

  public onUploaderSelect(uploader: string, event: any): void {
    if (this.validateFile(uploader, event.files[0])) {
      this.formGroupAddVideo.get(uploader)?.setValue(event);
      this.formGroupAddVideo.get(uploader)?.markAsTouched();
    } else {
      this.clearUploader(uploader);
    }
  }

  public onUploaderClear(uploader: string): void {
    this.formGroupAddVideo.get(uploader)?.setValue(null);
    this.formGroupAddVideo.get(uploader)?.markAsUntouched();
  }

  private clearUploader(uploader: string): void {
    if (uploader === 'video') this.videoUploader.clear();
    if (uploader === 'thumbnail') this.thumbnailUploader.clear();
  }

  private preventWhiteSpace(formControl: string): void {
    this.formGroupAddVideo.get(formControl)?.valueChanges.subscribe((value) => {
      if (value === ' ')
        this.formGroupAddVideo.get(formControl)?.patchValue('');
    });
  }

  private validateFile(uploader: string, file: File): boolean {
    // Verify file type
    if (
      !(
        (uploader == 'video' && file.type == 'video/mp4') ||
        (uploader == 'thumbnail' &&
          (file.type == 'image/png' ||
            file.type == 'image/jpeg' ||
            file.type == 'image/jpg'))
      )
    ) {
      let fileType: string;
      if (uploader == 'video') fileType = '.mp4';
      if (uploader == 'thumbnail') fileType = '.png, .jpg, .jpeg';
      if (fileType!) {
        this.notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid File Type`,
          content: `${file.name}: Invalid file type`,
        });
      }
      return false;
    }

    // Verify file size
    let maxFileSize: number;
    if (uploader == 'video') maxFileSize = 256000000;
    if (uploader == 'thumbnail') maxFileSize = 10000000;
    if (file.size > maxFileSize!) {
      this.notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: `Allowed file size: ${maxFileSize! / 1000000}MB`,
        content: `${file.name}: Invalid file size`,
      });
      return false;
    }

    // Return true if file is valid
    return true;
  }
}
