import { AppHelper } from 'src/app/shared/utilities/app.helper';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import * as moment from 'moment';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { IComment, IMessage, NominateInformation } from 'src/app/shared/interface/common';
import { InteractionType } from 'src/app/shared/type';
import { Menu } from 'primeng/menu';
import _ from 'lodash';
import { ExpandService } from 'src/app/shared/services/expand.service';
import { Router } from '@angular/router';

const DURATION_EDIT_COMMENT: number = 10 * 60 * 1000; // 10 minutes
const DURATION_PREVENT_SPAM_COMMENT: number = 60 * 60 * 1000; // 60 minutes
const CONTINUOUS_NUMBER_COMMENTS = 5; // 5 comments per time intervalCONTINUOUS_NUMBER_COMMENTS
const MAXIMUN_NUMBER_FILE_UPLOAD = 5;

@Component({
  selector: 'app-team-chat',
  templateUrl: './team-chat.component.html',
  styleUrls: ['./team-chat.component.scss'],
})
export class TeamChatComponent {
  @Input() alertId: string = '';
  
  @Input() alertState: string = '';
  @Input() currentUser: any = {};

  // Variables for ReadOnly Mode
  @Input() readOnly: boolean = false;
  @Input() backPortal: string = '';
  @Input() userRelated: any[] = [];

  // Variables for Viewer Role Mode
  @Input() isViewer: boolean = false;

  @ViewChild('infiniteScroll') infiniteScroll?: ElementRef;
  @ViewChild('content') content!: ElementRef;
  @ViewChild('uploadAttachment') uploadAttachment!: FileUpload;

  MAXIMUN_NUMBER_FILE_UPLOAD = MAXIMUN_NUMBER_FILE_UPLOAD;

  // use to mock-data for test
  page: number = 1;
  listComment: IComment[] = [];

  attachments: any[] = [];
  editAttachments: any[] = [];
  files: any[] = [];
  isUploadings: boolean[] = [];
  isUploadErrors: boolean[] = [];
  isLoadingCommentCompleted: boolean = false;
  itemsPerPage: number = 10;
  avatarMap: Map<string, string> = new Map<string, string>();

  isAllowComment: boolean = true;

  caretFocus: any = {
    indexElementActive: 0,
    index: 0
  };

  isOpenMentionList: boolean = false;
  userRelatedShow: any[] = [];
  stringSearchUser = '';

  formGroupTeamChat: FormGroup = this.fb.group({
    content: [{ value: '' }, [Validators.maxLength(2000)]],
    interventionChatAttachment: [null],
  });
  uploadSubs: any = [];

  isUpdating = false;
  chatId: string = '';
  loadCommentSub: any = null;

  extAllow = ['las', 'xlam', 'ppam'];
  mimeTypeAllow = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroenabled.12',
    'application/vnd.ms-word.template.macroenabled.12',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/vnd.ms-excel.template.macroenabled.12',
    'application/vnd.ms-excel.addin.macroenabled.12',
    'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    'application/vnd.ms-powerpoint.template.macroenabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/tiff',
    'application/pdf',
    'application/zip',
    'application/x-zip-compressed',
    'application/vnd.rar',
    'application/rtf',
    'text/plain',
    'text/xml',
    'text/csv',
  ];

  loadingData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  preventInteract: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loadingCallApi: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  public messageErrors: any = {
    maxlength: 'Please enter no more than 2000 characters',
  };

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {
    if (
      this.infiniteScroll!.nativeElement.scrollHeight <=
      this.infiniteScroll!.nativeElement.offsetHeight +
        Math.abs(this.infiniteScroll!.nativeElement.scrollTop) +
        1
    ) {
      if (!this.loadingData.value) {
        this.loadMore();
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private _io: WebSocketService,
    private _interventionService: InterventionService,
    private _notificationService: NotificationService,
    private _blobService: AzureBlobService,
    private _userInfoService: UserInfoService,
    private _expandService: ExpandService,
    private renderer: Renderer2,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unListenSocket(this.alertId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['alertId'] && changes['alertId'].currentValue) {
      this.setStatusComment(true);

      this.reset();
      if (this.readOnly) {
        this.setStatusComment(false);
      } else {
        this.setStatusComment(true);
      }
      this.page = 1;
      this.listComment = [];
      if (this.loadCommentSub != null) {
        this.loadCommentSub.unsubscribe();
        this.loadCommentSub = null;
      }
      this.loadCommentSub = this._interventionService
        .listChat(
          changes['alertId'].currentValue,
          this.page,
          this.itemsPerPage
        )
        .subscribe({
          next :(data) => {
            this.loadCommentSub = null;
            if (data.data.chats.length < this.itemsPerPage) {
              this.isLoadingCommentCompleted = true;
            }
            this.listComment.push(...data.data.chats);
            this.page = this.page + 1;
            this.loadingData.next(false);
            this.prepareAvatarMap();
          },
          error: (error: any) => {
            this.loadingCallApi.next(false);
          }
        });
      this._io
        .listen(
          `intervention/chat/${changes['alertId'].currentValue}/delete`
        )
        .subscribe((data) => {
          this.listComment.splice(
            this.listComment.findIndex((c: any) => c.chatId === data.data),
            1
          );
          this.loadingCallApi.next(false);
        });
      this._io
        .listen(
          `intervention/chat/${changes['alertId'].currentValue}/edit`
        )
        .subscribe({
          next: (data) => {
            const idx = this.listComment.findIndex(
              (c: any) => c.chatId === data.chatId
            );

            // Keep old comments data, need to change new memory to re-render
            const prevCmt = Object.assign({}, this.listComment[idx]);

            // Update content and updatedAt the comment
            prevCmt.content = data.content;
            prevCmt.attachments = data.attachments;
            prevCmt.updatedAt = data.updatedAt;
            this.listComment[idx] = prevCmt;
            this.loadingCallApi.next(false);
          },
          error: () => {
            this.loadingCallApi.next(false);
          }
      });
      this._io
        .listen(
          `intervention/chat/${changes['alertId'].currentValue}/vote`
        )
        .subscribe({
          next: (data) => {
            const idx = this.listComment.findIndex(
              (c: any) => c.chatId === data.chatId
            );

            this.listComment[idx].interactionSummary.downvote =
              data.interactionSummary.downvote;
            this.listComment[idx].interactionSummary.upvote =
              data.interactionSummary.upvote;

            if (data.idUserAction === this.currentUser.id) {
              if (data.actionType === AppConstant.STATUS_INTERACT.DELETE) {
                delete this.listComment[idx].interactionSummary.interactOfUser;
              } else {
                this.listComment[idx].interactionSummary.interactOfUser =
                  data.interactionSummary.interactOfUser;
              }
            }

            this.preventInteract.next(false);
          },
          error: () => {
            this.loadingCallApi.next(false);
          }});
      this._io
        .listen(`intervention/chat/${changes['alertId'].currentValue}`)
        .subscribe({
          next: (data) => {
          if (!this.avatarMap.get(data.user.id)) {
            if (data.user.avatar) {
              const loadedImage = this._blobService.getUserImage(
                decodeURIComponent(
                  AppHelper.StringFunctions.getFileName(data.user.avatar)
                )
              );
              this.avatarMap.set(data.user.id, `${loadedImage}`);
            }
          }
          this.listComment.unshift(data);
          this.loadingCallApi.next(false);
          },
          error: () => {
            this.loadingCallApi.next(false);
          }}
        );
      this._io
        .listen(
          `intervention/nominate/${changes['alertId'].currentValue}`
        )
        .subscribe({
          next: (data) => {
            const index = _.findIndex(this.listComment, { chatId: data.chatId });
            this.listComment.splice(index, 1,
              {
                ...this.listComment[index],
                nominateInformation: data.nominateInformation,
                nominateAt: data.nominateAt,
                nominateVote: data.nominateVote
              });
            this.preventInteract.next(false);

          },
          error: () => {
            this.loadingCallApi.next(false);
          }
        });
    } else if (!this.alertId && !changes['alertId']?.currentValue) {
      this.resetValueInnerHTML();
      this.setStatusComment(false);
    }
    
    if (
      changes['alertId'] &&
      !changes['alertId'].firstChange &&
      changes['alertId'].previousValue
    ) {
      this.unListenSocket(changes['alertId'].previousValue)
    }
  }

  private unListenSocket(alertId: string) {
    this._io
        .unlisten(
          `intervention/chat/${alertId}`
        )
        .subscribe(() => {});
      this._io
        .unlisten(
          `intervention/chat/${alertId}/delete`
        )
        .subscribe(() => {});
      this._io
        .unlisten(
          `intervention/chat/${alertId}/edit`
        )
        .subscribe(() => {});
      this._io
        .unlisten(
          `intervention/chat/${alertId}/vote`
        )
        .subscribe(() => {});
      this._io
        .unlisten(
          `intervention/nominate/${alertId}`
        )
        .subscribe(() => { });
  }

  public interactComment(chat: IComment, type: InteractionType) {
    const idUserAction = this._userInfoService.userSubject.getValue().id;
    this.preventInteract.next(true);

    if (chat.interactionSummary?.interactOfUser) {
      if (chat.interactionSummary.interactOfUser?.interactionType !== type) {
        if (type === 'upvote') {
          chat.interactionSummary.upvote += 1;
          chat.interactionSummary.downvote -= 1;
          chat.interactionSummary.interactOfUser.interactionType = 'upvote';
        } else {
          chat.interactionSummary.upvote -= 1;
          chat.interactionSummary.downvote += 1;
          chat.interactionSummary.interactOfUser.interactionType = 'downvote';
        }

        this._interventionService
          .editInteractChat(this.alertId, chat.chatId, idUserAction, {
            interactionType: type,
          })
          .subscribe({
            next: () => {},
            error: () => {},
          });
      } else {
        if (type === 'upvote') {
          chat.interactionSummary.upvote -= 1;
        } else {
          chat.interactionSummary.downvote -= 1;
        }
        chat.interactionSummary.interactOfUser = undefined;

        this._interventionService
          .deleteInteractChat(this.alertId, chat.chatId, idUserAction)
          .subscribe({
            next: () => {},
            error: () => {},
          });
      }
    } else {
      if (type === 'upvote') {
        chat.interactionSummary.upvote += 1;
        chat.interactionSummary.interactOfUser = { interactionType: 'upvote' };
      } else {
        chat.interactionSummary.downvote += 1;
        chat.interactionSummary.interactOfUser = {
          interactionType: 'downvote',
        };
      }
      this._interventionService
        .addInteractChat(this.alertId, chat.chatId, idUserAction, {
          interactionType: type,
        })
        .subscribe({
          next: () => {},
          error: () => {},
        });
    }
  }

  public clearEditAttachment(idx: number) {
    this.editAttachments.splice(idx, 1);
  }

  public clearAttachment(idx: number) {
    // Logic for only role Viewer
    if (this.isViewer) {
      this.files.splice(idx, 1);
      this.attachments.splice(idx, 1);
      this.isUploadings.splice(idx, 1);
      this.isUploadErrors.splice(idx, 1);
      return;
    }

    if (this.attachments[idx]) {
      this._interventionService
        .deleteChatAttachment(
          this.alertId,
          this.attachments[idx].attachmentUrl
        )
        .subscribe(() => {});
      this.attachments.splice(idx, 1);
      this.files.splice(idx, 1);
      this.isUploadings.splice(idx, 1);
      this.isUploadErrors.splice(idx, 1);
    } else if (this.uploadSubs[idx]) {
      this.uploadSubs[idx].unsubscribe();
      this.uploadSubs.splice(idx, 1);
      this.files.splice(idx, 1);
      this.isUploadings[idx] = false;
    }
  }

  public onUploaderSelect(uploader: string, event: any): void {
    let filesList = Array.from(event.files as File[]);

    if (
      !this.validateFile(
        uploader,
        filesList,
        this.files.length + this.editAttachments.length
      )
    ) {
      return;
    }

    // Logic for only role Viewer
    if (this.isViewer) {
      this.files = [...this.files, ...filesList];
      this.attachments = [...this.files];
      this.isUploadings = Array.apply(null, Array(this.files.length)).map(
        (_) => false
      );
      this.isUploadErrors = [...this.isUploadings];
      return;
    }

    this.formGroupTeamChat.markAsDirty();

    for (let idx = 0; idx < filesList.length; idx++) {
      const fileIdx = this.files.length;
      let list = new DataTransfer();

      this.files = [...this.files, filesList[idx]];
      this.isUploadings = [...this.isUploadings, true];
      this.isUploadErrors = [...this.isUploadErrors, false];
      list.items.add(filesList[idx]);
      this.formGroupTeamChat.get(uploader)?.setValue(list.files);
      this.formGroupTeamChat.get(uploader)?.markAsTouched();
      let sub = this._interventionService
        .addChatAttachment(this.formGroupTeamChat.value, this.alertId)
        .subscribe(
          (data: any) => {
            this.attachments = [...this.attachments, data.data];
            this.isUploadings[fileIdx] = false;
            this.onUploaderClear(uploader);
          },
          (e) => {
            this.isUploadings[fileIdx] = false;
            this.isUploadErrors[fileIdx] = true;
          }
        );
      this.uploadSubs = [...this.uploadSubs, sub];
    }
  }

  public getFirstLetter(userName: string | undefined): string {
    if (!userName) return 'Unknown User Name';

    const arrySplitName = userName.trim().split(' ');
    const userNameLength = arrySplitName.length;

    const firstLetter = arrySplitName[0].charAt(0);
    const secondLetter = arrySplitName[userNameLength - 1].charAt(0);

    return (firstLetter + secondLetter).toLocaleUpperCase() || userName;
  }

  async prepareAvatarMap() {
    for (let i = 0; i < this.listComment.length; i++) {
      let comment = this.listComment[i];
      if (!this.avatarMap.get(comment.user.id)) {
        if (comment.user.avatar) {
          const loadedImage = this._blobService.getUserImage(
            decodeURIComponent(
              AppHelper.StringFunctions.getFileName(comment.user.avatar)
            )
          );
          this.avatarMap.set(comment.user.id, `${loadedImage}`);
        }
      }
    }
  }

  public getAvatarUrl(comment: any) {
    return this.avatarMap.get(comment.user.id) || '';
  }

  showContextMenu(event: any, inputData: any) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  public getMenuTableItem(data: any): any {
    // Draft cannot nominate
    const canNominate = !['I_1_0', 'I_0_0'].includes(this.alertState); // ##nominate Need investigate
    const isUserNomiated: NominateInformation = data.nominateInformation?.find(
      (item: NominateInformation) => item.userId === this.currentUser?.id
    );
    let items: any = [
      {
        label: isUserNomiated
          ? 'Cancel nomination'
          : 'Nominate for Lesson Learned',
        icon: isUserNomiated ? 'c-icons cancel-icon' : 'c-icons nominate-icon',
        styleClass: 'yellow-label',
        disabled: this.isViewer,
        visible: canNominate && !this.preventInteract.value,
        command: () => {
          this.chatId = data.chatId;
          if(!this.preventInteract.value){
            isUserNomiated
            ? this.cancelNominate(this.chatId)
            : this.markNominate(this.chatId); 
          }
        },
      },
    ];

    if (data.userId === this.currentUser?.id) {
      if (
        moment(data.createdAt).toDate().getTime() >=
        new Date().getTime() - DURATION_EDIT_COMMENT
      ) {
        items.push({
          label: 'Edit',
          styleClass: '',
          icon: 'c-icons edit-icon',
          disabled: this.isViewer,
          command: () => {
            this.isUpdating = true;
            this.chatId = data.chatId;
            // this.formGroupTeamChat.get('content')?.setValue(data.content);
            this.resetValueInnerHTML(data.content);
            if (data.attachments) {
              this.editAttachments = [
                ...data.attachments.map((a: any) => {
                  return {
                    ...a,
                    size: Number(a.size),
                    name: this.decodeFileName(a.name)
                  };
                }),
              ];
            } else {
              this.attachments = [];
              this.editAttachments = [];
            }
          },
        });
      }
      !this.isUpdating &&
        items.push({
          label: 'Delete',
          icon: 'c-icons trash-bin-icon',
          styleClass: 'red-label',
          disabled: this.isViewer,
          command: () => {
            this.onDelete(data.chatId);
          },
        });
    }
    if (items.length == 0) return null;

    if (this.readOnly) {
      return null;
    }
    return [...items];
  }

  public onDownloanAttachment(url: any) {
    window.open(
      this._blobService.getAttachmentUrl(
        decodeURIComponent(AppHelper.StringFunctions.getFileName(url))
      )
    );
    return false;
  }

  public onComment() {
    this.formGroupTeamChat.updateValueAndValidity();

    if (this.isDisabledComment()) {
      return;
    }

    this.removeConsecutiveBr();
    let textComment = this.getValueInnerHTML();
    const listUserIdTagging: string[] = this.getUserTagging() || [];
    textComment = AppHelper.StringFunctions.textTrimmingAdvance(textComment);

    this.resetValueInnerHTML(textComment);
    if (!this.validateComment(textComment) || this.loadingCallApi.getValue())
      return;

    this.formGroupTeamChat.markAsPristine();
    this.loadingCallApi.next(true);

    if (this.isUpdating) {
      this._interventionService
        .editChat(this.alertId, this.chatId, {
          content: textComment,
          listUserIdTagging,
          attachments: [...this.editAttachments, ...this.attachments]
        })
        .subscribe({
          next: () => {},
          error: (error) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Warning: Edit Comment',
              content: error?.message || error || '',
            });
            this.loadingCallApi.next(false);
          },
        });
      this.isUpdating = false;
    } else {
      let numberCommentContinuous: number = 0;

      if (this.listComment?.length >= CONTINUOUS_NUMBER_COMMENTS) {
        for (let idx = 0; idx <= CONTINUOUS_NUMBER_COMMENTS - 1; idx++) {
          if (
            this.listComment[idx].userId ===
            this._userInfoService.userSubject.getValue().id
          ) {
            numberCommentContinuous++;
          } else {
            numberCommentContinuous = 0;
          }
        }

        if (numberCommentContinuous >= CONTINUOUS_NUMBER_COMMENTS) {
          let fivthComment = this.listComment[CONTINUOUS_NUMBER_COMMENTS - 1];
          if (
            moment(fivthComment.createdAt).toDate().getTime() >
            new Date().getTime() - DURATION_PREVENT_SPAM_COMMENT
          ) {
            this.isAllowComment = false;
          }
        }
      }

      if (!this.isAllowComment) {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Comment Intervention',
          content: `Limit reached for consecutive comments. Please wait an hour or for someone to respond before adding new comments`,
        });
        this.loadingCallApi.next(false);
        return;
      }

      this._interventionService
        .addChat(this.alertId, {
          content: textComment.trim(),
          listUserIdTagging,
          attachments: this.attachments
        })
        .subscribe({
          next: () => {},
          error: (error) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Warning: Add Comment',
              content: error?.message || error || 'Cannot send the comment.',
            });
            this.loadingCallApi.next(false);
          },
        });
    }
    this.reset();
  }

  public onUploaderClear(uploader: string): void {
    this.formGroupTeamChat.get(uploader)?.setValue(null);
    this.formGroupTeamChat.get(uploader)?.markAsUntouched();
  }

  public hasAttachmentUploading() {
    return this.isUploadings.some((v: boolean) => v === true);
  }

  public validateComment(data: string) {
    if (data.trim().length === 0) return false;
    return true;
  }

  private onDelete(chatId: string) {
    this.loadingCallApi.next(true);
    this._interventionService
      .deleteChat(this.alertId, chatId)
      .subscribe({
        next: () => {},
        error: (error) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Warning: Delete Comment',
            content: error?.message || error || '',
          });
          this.loadingCallApi.next(false);
        },
      });
  }

  private loadMore(): void {
    if (this.isLoadingCommentCompleted) {
      return;
    }
    this.loadingData.next(true);
    this.loadCommentSub = this._interventionService
      .listChat(this.alertId, this.page, this.itemsPerPage)
      .subscribe((data) => {
        this.loadCommentSub = null;
        if (data.data.chats.length < this.itemsPerPage) {
          this.isLoadingCommentCompleted = true;
        }
        this.listComment.push(...data.data.chats);
        this.page = this.page + 1;
        this.loadingData.next(false);
        this.prepareAvatarMap();
      });
  }

  private reset() {
    this.files = [];
    this.isUploadings = [];
    this.attachments = [];
    this.isUploadErrors = [];
    this.isLoadingCommentCompleted = false;
    this.editAttachments = [];

    this.resetValueInnerHTML('');
  }

  private validateFile(
    uploader: string,
    filesList: Array<File>,
    totalFileAlreadyAvailable: number
  ): boolean {
    const MAXIMIN_TOTAL_FILES_SIZE = 10485760; // 10MB = 10485760bytes
    const listErr: IMessage[] = [];
    // Calculate total file size is uploaded
    let currentTotalFileSize =
      this.files.reduce((acc, file) => acc + file.size, 0) +
      this.editAttachments.reduce(
        (acc: number, file: any) => acc + file.size,
        0
      );

    for (let i = 0; i < filesList.length; i++) {
      let file = filesList[i];
      // Verify duplicate
      if (this.files && this.files.length > 0) {
        const isDuplicated = this.files.some((uploadedFile: File) => {
          return uploadedFile.name === file.name;
        });
        if (isDuplicated) {
          listErr.push({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Duplicated file',
            content: 'Your uploading file is duplicated.',
          });
          // Remove file dublicate from list, decrease i, continue process.
          filesList.splice(i--, 1);
          continue;
        }
      }

      if (
        this.isUpdating &&
        this.editAttachments &&
        this.editAttachments.length > 0
      ) {
        const isDuplicated = this.editAttachments.some((uploadedFile: File) => {
          return uploadedFile.name === file.name;
        });
        if (isDuplicated) {
          listErr.push({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Duplicated file',
            content: 'Your uploading file is duplicated.',
          });
          // Remove file dublicate from list, decrease i, continue process.
          filesList.splice(i--, 1);
          continue;
        }
      }

      // Verify file type
      if (
        !this.mimeTypeAllow.includes(file.type.toLowerCase()) &&
        !this.extAllow.some((ext) => {
          return file.name.toLowerCase().endsWith(`.${ext}`);
        })
      ) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: `Invalid file type`,
          content: 'File type is not allowed',
        });
        // Remove Invalid file type from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      // Verify file size
      if (file.size > MAXIMIN_TOTAL_FILES_SIZE!) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid file size',
          content: 'Allowed file size: 10MB',
        });
        // Remove Invalid file size from list, decrease i, continue process.
        filesList.splice(i--, 1);
        continue;
      }

      if (currentTotalFileSize + file.size > MAXIMIN_TOTAL_FILES_SIZE) {
        listErr.push({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Invalid total file size',
          content:
            'File make exceeded total allowed file size. Should be removed',
        });

        filesList.splice(i--, 1);
        continue;
      } else {
        currentTotalFileSize += file.size;
      }
    }

    // Verify file number
    if (
      totalFileAlreadyAvailable + filesList.length >
      MAXIMUN_NUMBER_FILE_UPLOAD
    ) {
      const numberFilesCanAdd =
        MAXIMUN_NUMBER_FILE_UPLOAD - totalFileAlreadyAvailable;
      const numberFileRemoved = filesList.length - numberFilesCanAdd;

      listErr.push({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Exceeded allowed number of files',
        content: `Maximum ${MAXIMUN_NUMBER_FILE_UPLOAD} files are allowed`,
      });
      filesList.splice(numberFilesCanAdd, numberFileRemoved);
    }

    // Remove notification duplicate
    for (let i = 0; i < listErr.length; i++) {
      let isExist: boolean = false;
      for (let j = 0; j < i; j++) {
        if (listErr[j].content === listErr[i].content) {
          isExist = true;
          listErr.splice(i--, 1);
          break;
        }
      }
      if (!isExist) this._notificationService.setMessage(listErr[i]);
    }

    return filesList.length > 0 ? true : false;
  }

  isDisabledComment(): boolean {
    return (
      Boolean(this.getErrorContent()) ||
      !Boolean(this.getTextContent()) ||
      this.hasAttachmentUploading() ||
      this.isViewer ||
      this.readOnly || 
      !this.alertId ||
      !this.isAllowComment
    );
  }

  decodeFileName(fileName: string) {
    const nameConverted = decodeURIComponent(fileName);
    return nameConverted;
  }

  markNominate(chatId: string) {
    this.loadingCallApi.next(true);
    this.preventInteract.next(true);
    this._interventionService
    .markNominate(chatId)
    .pipe(catchError(AppHelper.UtileFunctions.handleError))
    .subscribe({
      next: ({ data, message }: any) => {
        if (data.nominateAt) {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Nominate Alert',
            content:
              'The justification will appear as a comment in the Team chat of the Alert with "Nominate for Lesson Learned" activated.',
          });
        }
        this.loadingCallApi.next(false);
      },
      error: (error) => {
        this.loadingCallApi.next(false);
      },
    });
    this.reset();
  }

  cancelNominate(chatId: string) {
    this.loadingCallApi.next(true);
    this.preventInteract.next(true);
    this._interventionService
    .cancelNominate(chatId)
    .pipe(catchError(AppHelper.UtileFunctions.handleError))
    .subscribe({
      next: ({ data, message }: any) => {
        if (!data.nominateAt) {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Nominate Alert',
            content:
              'Cancel Nominate successfully!',
          });
        }
        this.loadingCallApi.next(false);
      },
      error: (error) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Information',
          content: error,
        });
        this.loadingCallApi.next(false);
      },
    });
    this.reset();
  }


  toggleDialog(mominatedData: any, locationId: string) {
    let mominatedList = mominatedData.map((information: any) => {
      const {
        userId,
        userDisplayName,
        userRole,
        timestamps,
        discipline,
        remoteCenter,
        mail
      } = information
      const isEngineer = userRole === AppConstant.ROLES.ENGINEER.label
      const nominateUser = `${userDisplayName} (${(isEngineer ? discipline : userRole)})`
      return {
        header: nominateUser,
        content: null,
        footer: AppHelper.DateFunctions.formatDateTime(new Date(timestamps), true),
      }
    })
    const expandData = {
      isDialogOpen: true,
      headerLabel: 'Nominated by',
      nominateList: [...mominatedList],
      triggerElementId: locationId,
      userId: this.currentUser?.id
    };

    this._expandService.setExpandData(expandData);
  }

  //////////////////////////////////
  ///                           ///
  ///    AREA FOR COMMENTBOX    ///
  ///                           ///
  //////////////////////////////////

  toggleSuggestion(val: boolean = false): void {
    this.isOpenMentionList = val || !this.isOpenMentionList;

    if (!this.isOpenMentionList) {
      this.stringSearchUser = '';
    }
  }

  searchIndicate(): void {
    this.userRelatedShow = this.userRelated.filter(
      (user: any) => 
        !this.stringSearchUser || user.displayName.toLowerCase().includes(this.stringSearchUser.toLowerCase())
    );   
  }

  addTag(event: any = null): void {
    const {indexElementActive} = this.caretFocus;
    const isAddNodeTail = indexElementActive + 1 == this.content.nativeElement.childNodes.length;
    const spaceCharacter = '&nbsp;';
    const userName = this.renderer.createText(`@${event.value.displayName}`);
    const newNode = this.renderer.createElement('span');

    this.renderer.setAttribute(newNode, 'id', `${event.value.id}`);
    this.renderer.setAttribute(newNode, 'class', this.currentUser.id === event.value.id ? 'mine' : 'another');

    this.renderer.setAttribute(newNode, 'contenteditable', 'false');
    this.renderer.appendChild(newNode, userName);


    const textNode = this.content.nativeElement.childNodes[indexElementActive].textContent;
    const idxSearchString = textNode.indexOf(`@${this.stringSearchUser}`);
    const textRest = textNode.slice(idxSearchString + this.stringSearchUser.length + 1) + spaceCharacter;
    this.content.nativeElement.childNodes[indexElementActive].textContent = textNode.slice(0, idxSearchString);

    // add tag into contentEditAble
    if (isAddNodeTail) {
      this.renderer.appendChild(this.content.nativeElement, newNode);
    } else {
      this.insertNodeBefor(newNode, indexElementActive + 1);
    }
    let newInnerHTML = this.content.nativeElement.innerHTML;
    const outerHtmlNewNode = newNode.outerHTML;

    const position = newInnerHTML.indexOf(outerHtmlNewNode) + outerHtmlNewNode.length;
    newInnerHTML = [newInnerHTML.slice(0, position), textRest, newInnerHTML.slice(position) || spaceCharacter].join('');

    this.content.nativeElement.innerHTML = newInnerHTML;
    this.toggleSuggestion(false);

    const nodeFocus = this.content.nativeElement.childNodes[indexElementActive + 2] || this.content.nativeElement;
    this.focusNode(nodeFocus);
  }

  private focusNode(node: any): void {
    // Set focus to the editable div
    this.content.nativeElement.focus();
    const range = document.createRange();
    range.setStart(node, 0);
    range.collapse(true);

    // Remove all existing selections
    const selection = window.getSelection();
    selection?.removeAllRanges();

    // Add the new range to the selection
    selection?.addRange(range);
    this.content.nativeElement.focus();
  }


  public onKeyUp(event: any): void {
    this.getElementActive(event);

    if (event.key === "Escape") {
      this.toggleSuggestion(false);
      return;
    }

    if (event.key === "Backspace" && this.isOpenMentionList && !this.stringSearchUser) {
      this.toggleSuggestion(false);
      this.isAllowComment = true;
      return;
    }

    if (event.key === "Backspace" && this.isOpenMentionList) {
      const position = this.stringSearchUser.length - 1;
      this.stringSearchUser = this.stringSearchUser.slice(0, position < 0 ? 0 : position);
      this.searchIndicate();
      this.isAllowComment = true;
      return;
    }

    if (event.key === "Backspace" && this.content.nativeElement.textContent.length < 1) {
      while (this.content.nativeElement.hasChildNodes()) {
        this.content.nativeElement.removeChild(this.content.nativeElement.lastChild);
      }
      this.isAllowComment = true;
      return;
    }
  }

  public keyPress(event: any): void {
    const validChars = /^[A-Za-z0-9+/=]+$/;
    const specialKey = ['Meta', 'Shift', 'Control', 'Alt', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12']
    const math = (keyCode: string) => keyCode === event.key;

    if (specialKey.some(math)) {
      event.preventDefault();
      return;
    }

    if (
      event.keyCode == 13 &&
      event.code === 'Enter' &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      !this.isOpenMentionList &&
      this.alertId
    ) {
      this.onComment();
      event.preventDefault();
      return;
    }
    
    if (event.code === 'Enter' && this.isOpenMentionList) {
      if (this.userRelatedShow[0]) {
        this.addTag({value: this.userRelatedShow[0]});
        this.isAllowComment = true;
      } else {
        this.toggleSuggestion(false);
      }
      event.preventDefault();
      return;
    }

    this.isAllowComment = true;
    if (event.key === 'Enter') {
      return;
    }
    
    if (event.key === "@" && !this.isOpenMentionList) {
      this.toggleSuggestion(true);
      this.searchIndicate();
      return;
    }
    if (event.key === " " && this.isOpenMentionList) {
      this.stringSearchUser = "";
      this.toggleSuggestion(false);
      return;
    }
    
    if (this.isOpenMentionList && validChars.test(event.key)) {
      this.stringSearchUser += event.key
      this.searchIndicate();
      return;
    }
  }

  getElementActive(event: any) {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      this.content.nativeElement.childNodes.forEach((element: any, i: number) => {
        if (element == range.commonAncestorContainer) {
          this.caretFocus = {
            indexElementActive: i,
            index: range.endOffset
          }
        }
      })
    }    
  }

  public getErrorContent(): boolean {
    const result = this.content?.nativeElement?.textContent?.length > 2000 || false;
    return result;
  }

  public getTextContent(): string {
    return this.content?.nativeElement?.textContent || '';
  }

  private resetValueInnerHTML(val: string = '') {
    if (this.content)
      this.content.nativeElement.innerHTML = val;
  }

  private getValueInnerHTML() {
    return this.content?.nativeElement?.innerHTML || '';
  }

  private setStatusComment(value: boolean = true) {
    if (this.content)
      this.content.nativeElement.setAttribute('contenteditable', value.toString());
  }

  private insertNodeBefor(newNode: any, pos: number) {
    this.renderer.insertBefore(
      this.content.nativeElement,
      newNode,  
      this.content.nativeElement.childNodes[pos]
    );
  }

  private removeConsecutiveBr() {
    const div = this.content.nativeElement;
    let prevNode: Node | null = null;

    for (let i = 0; i < div.childNodes.length; i++) {
      const currentNode = div.childNodes[i];
      if (prevNode && prevNode.nodeName === 'BR' && currentNode.nodeName === 'BR') {
        div.removeChild(currentNode);
        i--; // Adjust index since we removed a node
      }
      prevNode = currentNode;
    }
  }

  private removeConsecutiveTextEmpty() {
    const div = this.content.nativeElement;

    for (let i = 0; i < div.childNodes.length; i++) {
      const currentNode = div.childNodes[i];
      if (currentNode.nodeName?.toLowerCase().includes('text') && !currentNode.textContent) {
        div.removeChild(currentNode);
        i--; // Adjust index since we removed a node
      }
    }
  }

  private getUserTagging(): Array<string> {
    let listId: string[] = [];
    
    this.content.nativeElement.childNodes.forEach((child: any) => {
      const math = (id: any) => id === child.id;
      if (child.nodeName.toLowerCase() == 'span' && !listId.some(math)) {
        listId.push(child.id);
      }
    })

    return listId;
  }

  //////////////////////////////////
  ///                           ///
  ///    AREA FOR COMMENTBOX    ///
  ///                           ///
  //////////////////////////////////

}
