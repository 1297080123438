export const USER_ADVANCED_FILTER = {
  REMOTE_CENTER: {
    LABEL: 'Remote Center',
    OPTION: {},
  },
  ROLE: {
    LABEL: 'Role',
    OPTION: {},
  },
  FUNCTIONAL_MAILBOX: {
    LABEL: 'Functional Mailbox',
    OPTION: {},
  },
  PSL: {
    LABEL: 'PSL',
    OPTION: {},
  },
  COUNTRY: {
    LABEL: 'Country',
    OPTION: {},
  },
  OPTION: {
    PLACE_HOLDER: 'All',
  },
};
