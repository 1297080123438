import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, map, switchMap, takeUntil, tap } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {
  ProjectData,
  RigInterval,
  RigRun,
  RigWellbore,
  WellList,
} from 'src/app/shared/interface/rig.interface';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { Menu } from 'primeng/menu';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { MODULE_NAME, USER_PERMISSION } from 'src/app/shared/enum';
import { UNIT_SYSTEM } from 'src/app/shared/utilities/app.helper.data';

@Component({
  selector: 'app-home-rig-item-run',
  templateUrl: './home-rig-item-run.component.html',
  styleUrls: ['./home-rig-item-run.component.scss'],
})
export class HomeRigItemRunComponent extends BaseComponent {
  isLoading: boolean = false;
  
  @Input()
  restoreRigListDataWellbore!: RigWellbore[];
  rigListDataWellbore: any[] = [];

  @Input()
  restoreRigListDataInterval!: RigInterval[];
  rigListDataInterval: any[] = [];

  @Input()  
  restoreRigListDataRun: RigRun[] = [];
  rigListDataRun: RigRun[] = [];

  @Input()
  projectData!: ProjectData;

  @Input()
  restoreWellList!: WellList[];

  @Input() rigId: string = '';

  @Input() isViewer: boolean = false;

  @Input() isEngineer: boolean = false;

  @Output()
  requestChangeTab: EventEmitter<any> = new EventEmitter<any>();

  UNIT_SYSTEM: any = UNIT_SYSTEM;
  
  fromChangeStatus: boolean = false;
  returnRigId: string = '';
  returnWellboreId: string = '';

  // variable setting show/hide popup form Run
  displayFormRunAdd: boolean = false;
  displayFormRunEdit: boolean = false;

  editRun: any;
  emptyMsg: string = '';

  runDelete: any;

  // Variable of Form
  public formRunItem!: FormGroup;

  public fieldNameKeys = {
    project: 'project',
    well: 'well',
    wellbore: 'wellbore',
    interval: 'interval',
  };

  rigListRunColumn: any[] = [
    { field: 'runNo', header: 'Run No.' },
    { field: 'description', header: 'Description' },
    { field: 'startMeasuredDepth', header: 'Depth In' },
    { field: 'tdMeasuredDepth', header: 'Depth Out' },
    { field: 'belowRotaryTimestamp', header: 'Below Rotary' },
    { field: 'aboveRotaryTimestamp', header: 'Above Rotary' },
    { field: 'runTdTimestamp', header: 'TD' },
    { field: 'features', header: 'x' },
  ];
  counterResult: number = 0;

  AppHelper = AppHelper;

  items = [];

  wellboreNameOverride: string = '';
  //
  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _activedRoute: ActivatedRoute,
    private _homeService: HomeService,
    private _builder: FormBuilder,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }

  onInit(): void {
    if (
      this.rigListDataInterval &&
      this.restoreWellList 
    ) {

      this.buildForm();
      this.bindingWellbore();
      this.rigListDataRun = [];

      this.counterResult = this.rigListDataRun.length;
      this.rigListDataRun.length
        ? ''
        : (this.emptyMsg = `There is no Interval selected `);
    }
  }



  buildForm() {
    let wellboreActive_wellboreRecently = this.restoreRigListDataWellbore.find(
      (welbore: any) => {
        return welbore.wellboreIsActive;
      }
    );

    if (wellboreActive_wellboreRecently) {
      this.formRunItem = this._builder.group({
        [this.fieldNameKeys.project]: [
          {
            value:
              this.projectData.currentName || this.projectData.originalName,
            disabled: true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.well]: [
          this.restoreWellList.find(
            (well: any) => well.id === wellboreActive_wellboreRecently?.wellId
          ),
          [Validators.required],
        ],
        [this.fieldNameKeys.wellbore]: [
          wellboreActive_wellboreRecently,
          [Validators.required],
        ],
        [this.fieldNameKeys.interval]: [null, [Validators.required]],
      });
      this.rigListDataInterval = this.restoreRigListDataInterval.filter(
        (interval: any) =>
          interval.wellboreId === wellboreActive_wellboreRecently!.wellboreId
      );
    } else {
      this.formRunItem = this._builder.group({
        [this.fieldNameKeys.project]: [
          {
            value:
              this.projectData.currentName || this.projectData.originalName,
            disabled: true,
          },
          [Validators.required],
        ],
        [this.fieldNameKeys.well]: [null, [Validators.required]],
        [this.fieldNameKeys.wellbore]: [null, [Validators.required]],
        [this.fieldNameKeys.interval]: [null, [Validators.required]],
      });
    }
  }

  showContextMenu(event: any, inputData: RigRun) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(runRow: RigRun): any {
    const canDelete = this._userInfoService.hasPermission(MODULE_NAME.RUN, USER_PERMISSION.DELETE);

    return [
      {
        label: 'Edit',
        icon: 'c-icons edit-icon',
        command: () => {
          this.displayFormRunEdit = true;
          this.editRun = runRow;
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        visible: canDelete,
        disabled: this.isViewer,
        command: () => {
          this.runDelete = runRow;

          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this run (${runRow.runNo}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete it.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  openEditRun(
    runId: string,
    fromChangeStatus: boolean,
    returnRigId: string,
    returnWellboreId: string,
    rigListDataRun: any,
    wellboreName?: string
  ): void {
    this.rigListDataRun = this.rigListDataRun
      ? rigListDataRun
      : this.rigListDataRun;
    this.displayFormRunEdit = true;
    this.editRun = this.rigListDataRun.find((run: any) => run.runId === runId);

    this.fromChangeStatus = fromChangeStatus ? true : false;
    this.returnRigId = returnRigId;
    this.returnWellboreId = returnWellboreId;

    this.wellboreNameOverride = wellboreName || '';
  }

  changeTab(tabIndex: number) {
    this.requestChangeTab.emit(tabIndex);
  }
  showPopUpRun() {
    if (!this.projectData.curUnit) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Add Run',
        content: 'You need to add Unit System for Project',
      });
      return;
    }

    const isAvailableInactiveRun = this.getRunActiveAvailable(
      this.restoreRigListDataRun
    );

    if (isAvailableInactiveRun) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Add Run',
        content:
          `Have existed active run with runNo ${isAvailableInactiveRun.runNo}, please fulfill the old run before starting the new one!`,
      });
    } else {
      if (
        !(
          this.formRunItem.get(this.fieldNameKeys.well)?.value &&
          this.formRunItem.get(this.fieldNameKeys.wellbore)?.value &&
          this.formRunItem.get(this.fieldNameKeys.interval)?.value
        )
      ) {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Add Run',
          content:
            'You need to select the value of well, wellbore and interval before creating a new run',
        });
      } else {
        this.displayFormRunAdd = true;
      }
    }
  }
  hideFormRun(event: any) {
    this.displayFormRunAdd = event;
    this.displayFormRunEdit = event;
  }

  reloadData() {
    this.isLoading = true;
    this._activedRoute.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params.get('id')),
        switchMap((id) => {
          this.rigId = id || '';
          return this._homeService.getRuns(id || '');
        })
      )
      .subscribe((dataList: any) => {
        this.isLoading = false; //<-- Off soon to leak erro

        this.restoreRigListDataRun = dataList.data;
        const selectedInterval = this.formRunItem?.get('interval')?.value;
        if (selectedInterval) {
          this.rigListDataRun = this.restoreRigListDataRun.filter(
            (run) => run.intervalId === selectedInterval.intervalId
          );
        }
      });
  }

  bindingWell(event?: any) {
    if (this.formRunItem.get('well')?.value) {
      let wellId = this.formRunItem.get('well')?.value.id;
      let filterWellbore = this.restoreRigListDataWellbore.filter(
        (wellbore: any) => wellbore.wellId === wellId
      );

      this.rigListDataWellbore = filterWellbore;
      this.rigListDataInterval = [];
      this.onClearWellboreList();
    }
  }

  bindingWellbore() {    
    const selectedWellbore = this.formRunItem.get('wellbore')?.value;    
    if (!selectedWellbore) return;
    
    const wellboreSelect = this.restoreRigListDataWellbore.find(
      (wellbore: RigWellbore) => wellbore.wellboreId === selectedWellbore.wellboreId);

    if (wellboreSelect) {
      const wellboreId = wellboreSelect.wellboreId;
      const wellId = wellboreSelect.wellId;

      // auto select well by wellbore
      const filterWell = this.restoreWellList.find(
        (well: any) => well.id === wellId
      );
      
      this.formRunItem.get('well')?.patchValue(filterWell);
      this.formRunItem.get('wellbore')?.patchValue(wellboreSelect);      

      this.rigListDataWellbore = this.restoreRigListDataWellbore.filter(
        (wellbore: any) => wellbore.wellId === filterWell?.id
      );

      this.rigListDataInterval = this.restoreRigListDataInterval.filter(
        (interval: any) => interval.wellboreId === wellboreId
      );
      this.bindingInterval();
    } else {
      this.onClearWellboreList();
    }
  }

  onClearWellList(event?: any) {
    this.getRefresh('well');
    this.rigListDataWellbore = [];
    this.onClearWellboreList();
  }

  onClearWellboreList(event?: any) {
    this.getRefresh('wellbore');
    this.rigListDataInterval = [];
    this.onClearIntervalList();
  }

  onClearIntervalList(event?: any) {
    this.getRefresh('interval');
    this.rigListDataRun = [];
    this.emptyMsg = `Please choose wellbore and interval value`;
  }

  addRun(runAdded: any) {
    this.counterResult++;
    let newRun = this.restoreRigListDataRun.filter(
      (run) => runAdded.data.id === run.intervalId
    );
    this.rigListDataRun = newRun;
  }

  bindingInterval() {
    const selectedInterval = this.formRunItem?.get('interval')?.value;
    if (!selectedInterval) return;

    const intervalSelect = this.restoreRigListDataInterval.find(
      (interval: RigInterval) => interval.intervalId === selectedInterval.intervalId);

    if (!intervalSelect) {
      this.rigListDataRun = [];
      this.getRefresh('interval');
      this.rigListDataRun = [];
      this.emptyMsg = `Please choose interval value`;
      return;
    }

    this.formRunItem.get('interval')?.setValue(intervalSelect);
    const newRun = this.restoreRigListDataRun.filter(
      (run) => selectedInterval.intervalId === run.intervalId
    );
    this.rigListDataRun = newRun;
    this.rigListDataRun.length
      ? ''
      : (this.emptyMsg = `There is no run created for interval: ${intervalSelect.intervalName}`);     
  }

  /**
   * Refresh form with control name
   * @param controlName 
   */
  getRefresh(controlName: string) {
    const control = this.formRunItem.get(controlName);
    control?.setValue('');
    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();
  }

  getRunActiveAvailable(dataRun: RigRun[]): RigRun | null {
    let runActive: RigRun | null = null;
    (dataRun || []).forEach((run) => {
      if (!Boolean(run.belowRotaryTimestamp &&
        run.runTdTimestamp &&
        run.aboveRotaryTimestamp &&
        run.startMeasuredDepth &&
        run.tdMeasuredDepth)) {
          runActive = {...run};
        }
    });
    return runActive;
  }

  deleteRun(run: any) {
    this.isLoading = true;
    this._homeService
      .deleteRun(run.runId)
      .pipe(
        tap(() => this.isLoading = false),
        catchError(AppHelper.UtileFunctions.handleError)
      )
      .subscribe({
        next: (response: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Delete Run',
            content: `Run number: ${run.runNo} was deleted successfully!`,
          });
          this._confirmService.clearDialog();
          this.reloadData();
        },
        error: (error: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Delete Run',
            content: error?.message || error,
          });
          this.isLoading = false;
        },
      });
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Delete':
            const deleteId = this.runDelete.runId || '';
            if (!deleteId) {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Information',
                content: 'The ID of the Run to be deleted could not be found',
              });
              break;
            }
            this.deleteRun(this.runDelete);

            this._confirmService.clearDialog();
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  onDestroy(): void {}
}
