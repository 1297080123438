import { AppConstant } from 'src/app/shared/utilities/app.constant';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Component, ViewChild } from '@angular/core';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgForm } from '@angular/forms';
import { RigListColumn, Rig } from 'src/app/shared/interface/rig.interface';
import { RigService } from 'src/app/shared/services/rig.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { ConfirmDialogConfig } from 'src/app/shared/models/dialog.model';
import { FileHandle } from 'src/app/shared/directives/drag-drop.directive';
import { MULTI_LEVEL } from 'src/app/shared/metadata/multi-level-dropdown-type.metadata';
import { DialogService } from 'primeng/dynamicdialog';
import { PhotoCropperComponent } from 'src/app/shared/components/photo-cropper/photo-cropper.component';
import { DialogAction, OptionButtonType } from 'src/app/shared/type';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Menu } from 'primeng/menu';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { IConfirmDialog } from 'src/app/shared/interface/common';
import { PaginationData } from 'src/app/shared/interface/pagination-data.interface';

@Component({
  selector: 'app-rig-management',
  templateUrl: './rig-management.component.html',
  styleUrls: ['./rig-management.component.scss'],
})
export class RigManagementComponent extends BaseComponent {
  isLoading: boolean = false;
  isAllOptionSelected: boolean = false;
  pageSize: number = 10;  
  pageIndex: number = 1;
  totalRecords: number = 0;
  projectId: string = '';
  rigListData: Rig[] = [];
  rigListDataTemp: Rig[] = []; // To fix loading issues
  // contractorList: Contractor[] = [];
  contractorList: any[] = [];

  rigTypes: any[] = [];
  customLabelString = 'Select type';
  customLabel = 'Select type';

  rigEdit?: Rig;

  AppHelper = AppHelper;

  rigListColumn: RigListColumn[] = [
    { field: 'rigName', header: 'Rig Name' },
    { field: 'rigTypeName.rigTypeHierarchy', header: 'Rig Type' },
    { field: 'contractorName', header: 'Rig Contractor' },
    { field: 'imageUrl', header: 'Rig Image' },
    { field: 'specUrl', header: 'Rig Specifications' },
    { field: 'features', header: 'x' },
  ];

  msgFromApis = 'No result found';

  // Variable of Form
  @ViewChild('searchRigForm') searchRigForm!: NgForm;
  subjectSearchString = new Subject<string>();

  @ViewChild('menu') menu: any;
  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  rigDelete: any;

  displayAddFormRigDialog: boolean = false;
  displayEditFormRigDialog: boolean = false;

  @ViewChild('contractorDropdown') contractorDropdown?: any;

  previewSearch: string = '';
  previewContractor: string[] = [];
  isCallFilterContractor: boolean = true;

  MULTI_LEVEL = MULTI_LEVEL;

  // Check role for Viewer and Engineer
  isViewer: boolean = false;

  confirmDialog: IConfirmDialog = AppConstant.DEFAULT_DIALOG;

  constructor(
    private _rigService: RigService,
    private _blobService: AzureBlobService,
    private _notificationService: NotificationService,
    private _dialogService: DialogService,
    private _userInfoService: UserInfoService,
    private _confirmService: ConfirmDialogService
  ) {
    super();
  }
  onInit(): void {
    this.isLoading = true;

    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
          this.loadInitialData();
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData() {
    this._rigService.getConstractors().subscribe({
      next: (contractorList: any) => {
        this.rigTypes = AppConstant.RIG_TYPES;
        this.contractorList = AppHelper.UtileFunctions.sortArrayBykey(
          contractorList.data,
          'contractorName'
        );
      },
    });

    this.subjectSearchString.pipe(debounceTime(1000)).subscribe({
      next: (searchTextValue: any) => {
        this.previewSearch = searchTextValue;
        this.pageIndex = 1;
        this.filterRigManagement(0);
      },
    });
  }

  ngAfterViewInit(): void {
    this.filterRigManagement(1000);
  }

  filterRigManagement(timeDebounce: number) {
    let formValue = this.searchRigForm.value;

    if (this.searchRigForm.value) {
      this.isLoading = true;
      if (this.isAllOptionSelected) {
        this.pageSize = 10000000;
      }
      this._rigService
        .getSearchRigByStringEDMV2(
          formValue.searchString,
          formValue?.rigType?.data || '',
          formValue.contractorString,
          timeDebounce,
          this.pageSize,
          this.pageIndex
        )
        .subscribe({
          next: (res: PaginationData<any>) => {
            const items = res.items || [];
            this.totalRecords = res.totalCount || 0;
            if (this.isAllOptionSelected) {
              this.pageSize = this.totalRecords;
            }
            this.rigListDataTemp = [];
            this.rigListData = res.items || [];
            if (items.length === 0) {
              this.rigListDataTemp = items;
              this.isLoading = false;
              return of(null);
            } else {
              items.forEach((element) => {
                if (!element.imageUrl) {
                  this.rigListDataTemp.push(element);
                  if (this.rigListDataTemp.length === items.length) {
                    this.rigListDataTemp =
                      AppHelper.UtileFunctions.sortArrayBykey(
                        this.rigListDataTemp,
                        'rigName'
                      );
                    this.rigListData = [...this.rigListDataTemp];
                  }
                } else {
                  const url = this._blobService.getRigImage(
                    decodeURIComponent(
                      AppHelper.StringFunctions.getFileName(element.imageUrl)
                    )
                  );
                  this.rigListDataTemp.push({
                    ...element,
                    imageBlob: url,
                  });
                  if (this.rigListDataTemp.length === items.length) {
                    this.rigListDataTemp =
                      AppHelper.UtileFunctions.sortArrayBykey(
                        this.rigListDataTemp,
                        'rigName'
                      );
                    this.rigListData = [...this.rigListDataTemp];
                  }
                }
              });
              this.isLoading = false;
            }
          },
          error: (error) => {
            if (error) {
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.WARNING,
                header: 'Loading image',
                content: error?.message || error,
              });
            }

            this.isLoading = false;
          },
        });
    }
  }

  keyupSearch(e: any) {
    let searchTextValue = this.searchRigForm.controls['searchString'].value;
    this.subjectSearchString.next(searchTextValue);
  }

  onPanelHideContractorDropdown() {
    let contractorValue =
      this.searchRigForm.controls['contractorString'].value || [];
    const isMatch = AppHelper.UtileFunctions.arraysMatch(
      this.previewContractor,
      contractorValue
    );

    if (isMatch) {
      this.isCallFilterContractor = false; // Dont need to reload Table because dropdown value dont change
    } else {
      this.isCallFilterContractor = true;
      this.previewContractor = [...contractorValue];
    }

    if (this.isCallFilterContractor) {
      this.pageIndex = 1;
      this.filterRigManagement(0);
    }
  }

  onClearContractorDropdown() {
    if (this.contractorDropdown!.overlayVisible === false) {
      this.searchRigForm.controls['contractorString'].setValue([]);
      this.previewContractor = [];
      this.pageIndex = 1;
      this.filterRigManagement(0);
    }
  }

  showContextMenu(event: any, inputData: Rig) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(rig: Rig): any {
    return [
      {
        label: 'Edit Rig',
        icon: 'c-icons edit-icon',
        command: () => {
          this.rigEdit = rig;
          this.displayEditFormRigDialog = true;
        },
      },
      {
        label: 'Delete',
        icon: 'c-icons trash-bin-icon',
        styleClass: 'red-label',
        disabled: this.isViewer,
        command: () => {
          this.rigDelete = rig;

          this._confirmService.setDialog({
            ...this.confirmDialog,
            isVisible: true,
            header: `Are you sure you want to delete this rig (${rig.rigName}) from WellCare?`,
            haveCheckbox: true,
            checkboxLabel: 'Yes, I want to delete rig.',
            haveDialogMessage: false,
            havePrimaryButton: true,
            primaryButtonLabel: 'Delete',
            isValidPrimaryButton: true,
            disablePrimaryButton: false,
            haveSecondaryButton: true,
            secondaryButtonLabel: 'Cancel',
            buttonEvent: (event: OptionButtonType) =>
              this.onButtonClickDialog(event, 'Delete'),
          });
        },
      },
    ];
  }

  readPDFdocument(PDFLink: string, containerName: string, currentRig: any) {
    if (PDFLink) {
      this.isLoading = true;
      this._blobService
        .downloadPDF(
          decodeURIComponent(AppHelper.StringFunctions.getFileName(PDFLink)),
          containerName
        )
        .then((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Caught error:', error); // The error will be caught here
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading PDF Document',
            content: 'This document is no longer available, please try again',
          });
          // disable hyperlink of no longer available document
          switch (containerName) {
            case 'rigspec':
              currentRig.specUrl = null;
              break;
          }
          this.isLoading = false;
        });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Loading PDF Document',
        content: 'This document is no longer available, please try again',
      });
      this.isLoading = false;
    }
  }

  deleteRig(rig: any) {
    this.isLoading = true;
    this._confirmService.clearDialog();
    this._rigService
      .deleteRig(rig.rigId)
      .pipe(catchError(AppHelper.UtileFunctions.handleError))
      .subscribe({
        next: (response: any) => {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.SUCCESS,
            header: 'Delete Rig',
            content: `${rig.rigName} was deleted successfully!`,
          });
          this.filterRigManagement(0);
        },
        error: (error: any) => {
          console.error(error);
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Delete Rig',
            content: `Your request to delete ${rig.rigName} was denied because the rig is assigned to one or more projects. Please delete the rig from any associated projects before trying again.`,
          });
          this.isLoading = false;
        },
      });
  }

  hideFormRigDialog(value: boolean) {
    this.displayAddFormRigDialog = value;
    this.displayEditFormRigDialog = value;
  }

  reloadRigManagement(reloadDropdownContractor: boolean) {
    if (reloadDropdownContractor) {
      this.isLoading = true;
      this._rigService
        .getConstractors()
        .pipe(takeUntil(this.destroy$))
        .subscribe((contractorList: any) => {
          if (contractorList.data) {
            this.contractorList = contractorList.data;

            this.filterRigManagement(0);
          }
        });
    } else {
      this.filterRigManagement(0);
    }
  }

  clearFilter(): void {
    this.isLoading = true;

    this.searchRigForm.controls['searchString'].setValue('');

    this.searchRigForm.controls['contractorString'].setValue([]);
    this.previewContractor = [];

    this.searchRigForm.controls['rigType'].setValue('');
    this.customLabel = this.customLabelString;
    this.pageIndex = 1;
    this.totalRecords = 0;
    this.subjectSearchString.next('');
    this.filterRigManagement(0);
  }

  filesDropped(files: FileHandle[], uploadType: string, rig: any): void {
    if (this.isViewer) {
      this.isLoading = false;
      // this._notificationService.setMessage({
      //   type: AppConstant.MESSAGE_TYPE.WARNING,
      //   header: 'Upload Document',
      //   content: AppConstant.COMMON_TOASTER.PERMISSION,
      // });
      return;
    }

    if (files.length > 1) {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Upload Document',
        content: 'Please upload only one file',
      });
      return;
    }
    if (files[0]) {
      if (uploadType === 'image') {
        if (!AppHelper.UtileFunctions.isImageFile(files[0].file)) {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Upload Document',
            content: 'Please upload image/* file',
          });
          return;
        } else {
          // --> check size image
          if (files[0].file.size > AppConstant.MAX_SIZE_IMAGE_UPLOAD) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Upload Document',
              content: AppConstant.WARN_SIZE_IMAGE_UPLOAD,
            });
            return;
          } else {
            // Upload PNG
            this.showCropper(files[0].file, rig);
          }
        }
      } else if (uploadType === 'pdf') {
        if (files[0].file.type !== 'application/pdf') {
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Upload Document',
            content: 'Please upload *.pdf file',
          });
          return;
        } else {
          // --> check size PDF
          if (files[0].file.size > AppConstant.MAX_SIZE_PDF_UPLOAD) {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Upload Document',
              content: AppConstant.WARN_SIZE_PDF_UPLOAD,
            });
            return;
          } else {
            // Upload PDF
            this.uploadRigDocument(files[0].file, rig, 'pdf');
          }
        }
      }
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.INFO,
        header: 'Upload Document',
        content: 'Please check upload file',
      });
      return;
    }
  }

  upload(): void {
    //get image upload file obj;
  }

  uploadRigDocument(fileUpload: File, rig: any, uploadType: string) {
    if (uploadType === 'image') {
      this.isLoading = true;
      this._rigService
        .editImageRig(fileUpload, rig.rigId, rig.rigName)
        .pipe(catchError(AppHelper.UtileFunctions.handleError))
        .subscribe({
          next: (response) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Upload Document',
              content: 'Upload Image successfully!',
            });
            this.filterRigManagement(0);
          },
          error: (error) => {
            console.error(error);
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Upload Document',
              content: error?.message || error,
            });
            this.isLoading = false;
          },
        });
    } else if (uploadType === 'pdf') {
      this.isLoading = true;
      this._rigService
        .editSpecsRig(fileUpload, rig.rigId, rig.rigName)
        .pipe(catchError(AppHelper.UtileFunctions.handleError))
        .subscribe({
          next: (response) => {
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.SUCCESS,
              header: 'Upload Document',
              content: 'Upload PDF successfully!',
            });
            this.filterRigManagement(0);
          },
          error: (error) => {
            console.error(error);
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Upload Document',
              content: error?.message || error,
            });
            this.isLoading = false;
          },
        });
    }
  }

  showCropper(file: any, rig: any): void {
    const ref = this._dialogService.open(PhotoCropperComponent, {
      header: 'Edit Photo',
      width: '30.25rem',
      data: {
        file: file,
        ratio: 16 / 10,
        isRound: false,
      },
      baseZIndex: 1013,
      closable: true,
      closeOnEscape: true,
    });

    ref.onClose.subscribe({
      next: (res: any) => {
        if (res) {
          this.uploadRigDocument(res, rig, 'image');
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onOptionsSelected(event: any, seperateMark: string) {
    let finalAncestor = false;
    let labelDisplay = event.node.label;
    let currentObjLevel = event.node;
    while (!finalAncestor) {
      if (
        currentObjLevel.hasOwnProperty('parent') &&
        currentObjLevel.parent !== undefined
      ) {
        //
        currentObjLevel = currentObjLevel.parent;
        labelDisplay = `${currentObjLevel.label} ${seperateMark} ${labelDisplay}`;
      } else {
        finalAncestor = true;
      }
    }
    this.customLabel = labelDisplay;

    this.pageIndex = 1;
    this.filterRigManagement(0);
  }
  onNodeExpand(event: any) {
    // Prevent the arrow from expanding when this is the last child element
    if (event.node.children === undefined) event.node.expanded = false;
  }

  onClear(event: any) {
    this.customLabel = this.customLabelString;
    this.pageIndex = 1;
    this.filterRigManagement(0);
  }

  onButtonClickDialog(option: OptionButtonType, dialogType: DialogAction): void {
    switch (option) {
      case AppConstant.OPTION_BUTTON.YES:
        switch (dialogType) {
          case 'Delete':
            this.deleteRig(this.rigDelete);
            break;
          default:
            this._confirmService.clearDialog();
            break;
        }
        break;
      case AppConstant.OPTION_BUTTON.NO:
        this._confirmService.clearDialog();
        break;
      case AppConstant.OPTION_BUTTON.CANCEL:
        this._confirmService.clearDialog();
        break;
      default:
        break;
    }
  }

  decodeFileName(filename: string): string {
    const nameDecoded = AppHelper.UtileFunctions.getFileNameFromUrl(filename);
    return nameDecoded;
  }

  onPageChange(event: any) {
    this.pageIndex = event.page + 1;
    this.isAllOptionSelected = ![10, 20, 30].includes(event.rows);
    this.pageSize = event.rows;    
    this.filterRigManagement(0);
  }

  onDestroy(): void {}
}
