import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

const declarations = [LoginComponent];

const imports: any = [
  ShareModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [LoginComponent],
  providers: [],
})
export class LoginModule {}
