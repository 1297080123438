import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { MailBoxService } from 'src/app/shared/services/mailbox.service';
import { MailboxManagementComponent } from './mailbox-management/mailbox-management.component';
import { MailboxFormComponent } from './mailbox-form/mailbox-form.component';
import { MailboxAdvancedFilterComponent } from './mailbox-advanced-filter/mailbox-advanced-filter.component';

const declarations = [MailboxManagementComponent, MailboxFormComponent, MailboxAdvancedFilterComponent];

const imports: any = [
  ShareModule,
  FormsModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [MailboxManagementComponent, MailboxFormComponent, MailboxAdvancedFilterComponent],
  providers: [MailBoxService],
})
export class MailBoxModule {}
