import { NgModule, forwardRef } from '@angular/core';
import { UnitInputDirective } from './unit-input.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const declarations = [UnitInputDirective];

const imports: any = [];

@NgModule({
  imports: [...imports],
  declarations: [	...declarations],
  exports: [UnitInputDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitInputDirective),
      multi: true,
    },
  ],
})
export class UnitInputModule {}
