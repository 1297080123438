<div *ngIf="formLoaded | async">
  <div
    class="intervention-content__form--INITIATED container"
    [ngClass]="readOnly ? 'label-readonly' : ''"
    *ngIf="!!formInitiateGroup"
    [formGroup]="formInitiateGroup"
  >
    <div class="row mb-3 d-none">
      <p-button
        icon="pi pi-check"
        (click)="onSubmit()"
        label="Add"
        class="fa__button--primary"
      ></p-button>

      <p-button
        icon="pi pi-check"
        label="Set Time Test"
        class="fa__button--primary"
      ></p-button>
    </div>
    <div class="row mb-2">
      <div class="col-6 ps-0 pe-4">
        <div class="mb-3">
          <label
            for="localTimestamp1"
            class="form-label label-disabled" 
            [ngClass]="{ 'label-disabled': isTimeOverrided }"
          >Local Timestamp</label
          >
          <div class="p-inputgroup">
            <input
              type="text"
              pInputText
              id="localTimestamp1"
              [ngClass]="readOnly || isTimeOverrided ? 'readonly-spec-color' : 'spec-color'"
              [formControlName]="fieldNameKeys.autoLocalTimestamp"
            />
          </div>
        </div>
      </div>
      <div class="col-1 pe-3">
        <div
          class="field-checkbox d-flex align-items-center justify-content-center height-override"
        >
          <p-checkbox
            [formControlName]="fieldNameKeys.isOverrideTime"
            [binary]="true"
            inputId="override1"
            class="custom-p-checkbox"
          ></p-checkbox>
          <label class="form-label override ms-1" for="override1">
            Override
          </label>
        </div>
      </div>

      <div class="col-5 pe-4">
        <div class="mb-3">
          <label
            for="localTimestamp2"
            class="form-label"
            [ngClass]="{ 'label-disabled': !isTimeOverrided }"
            >Local Timestamp</label
          >

          <div class="p-inputgroup">
            <p-calendar
              styleClass="custom-p-calendar"
              id="localTimestamp2"
              (onSelect)="onSetTimeTest($event, devident, true)"
              [showTime]="true"
              [readonlyInput]="true"
              dateFormat="dd-M-yy,"
              placeholder="Enter value..."
              icon="pi pi-calendar"
              [showIcon]="true"
              [showClear]="true"
              [formControlName]="fieldNameKeys.localTimestamps"
              [selectOtherMonths]="true"
            ></p-calendar>
          </div>
          <div class="">
            <div *ngIf="isInvalidControl(fieldNameKeys.localTimestamps)">
              <ng-container
                *ngFor="let msg of getErrorByField(fieldNameKeys.localTimestamps)"
              >
                <div class="inner-msg-error">
                  <span>{{ msg }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6 ps-0 pe-4">
        <div class="mb-3">
          <label
            for="rigTimestamp1"
            class="form-label label-disabled"
            [ngClass]="{ 'label-disabled': isTimeOverrided }"
          >Rig Timestamp</label
          >
          <div class="p-inputgroup">
            <input
              type="text"
              pInputText
              id="rigTimestamp1"
              [ngClass]="readOnly || isTimeOverrided ? 'readonly-spec-color' : 'spec-color'"
              [formControlName]="fieldNameKeys.autoRigTimestamp"
            />
          </div>
        </div>
      </div>
      <div class="col-1 pe-3"></div>

      <div class="col-5 pe-4">
        <div class="mb-3">
          <label
            for="rigTimestamp2"
            class="form-label"
            [ngClass]="{ 'label-disabled': !isTimeOverrided }"
            >Rig Timestamp</label
          >
          <div class="p-inputgroup">
            <p-calendar
              styleClass="custom-p-calendar"
              id="rigTimestamp2"
              (onSelect)="onSetTimeTest($event, devident, false)"
              [showTime]="true"
              [readonlyInput]="true"
              dateFormat="dd-M-yy,"
              placeholder="Enter value..."
              icon="pi pi-calendar"
              [showIcon]="true"
              [showClear]="true"
              [formControlName]="fieldNameKeys.rigTimestamps"
              [selectOtherMonths]="true"
            ></p-calendar>
          </div>          
          <div class="">
            <div *ngIf="isInvalidControl(fieldNameKeys.rigTimestamps)">
              <ng-container
                *ngFor="let msg of getErrorByField(fieldNameKeys.rigTimestamps)"
              >
                <div class="inner-msg-error">
                  <span>{{ msg }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6 ps-0 pe-4">
        <div class="mb-3">
          <label for="interval" class="form-label">
            Interval
            <span class="p-error"> *</span>
          </label>

          <div class="w-100">
            <p-dropdown
              class="custom-p-drop"
              scrollHeight="200px"
              placeholder="Select Interval"
              optionLabel="name"
              [options]="intervalOptions"
              [showClear]="true"
              (onClear)="onClearInterval()"
              (onChange)="onOptionsSelectedInterval($event)"
              [formControlName]="fieldNameKeys.interval"
              [innerMsg]="messageErrors"
            >
              <ng-template let-interval pTemplate="selectedItem">
                <div class="" *ngIf="fieldNameKeys.interval">
                  <div class="d-flex align-items-center">
                      {{ interval.name }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
      
      <div class="col-6 ps-0 pe-4">
        <div class="mb-3">
          <label
            for="run"
            class="form-label" 
            [ngClass]="{ 'label-disabled': isDisableRunOption }"
          >
            Run
            <span class="p-error"> *</span>
          </label>

          <div class="w-100">
            <p-dropdown
              class="custom-p-drop"
              scrollHeight="200px"
              placeholder="Select Run"
              optionLabel="name"
              [options]="runOptions"
              [showClear]="true"
              (onClear)="onClearRun()"
              (onChange)="onOptionsSelectedRun($event)"
              [formControlName]="fieldNameKeys.run"
              [disabled]="isDisableRunOption"
              tooltipPosition="bottom"
              [pTooltip]="isDisableRunOption ? 'Select a valid Interval' : ''"
              [innerMsg]="messageErrors"
            >
              <ng-template let-run pTemplate="selectedItem">
                <div class="" *ngIf="fieldNameKeys.run">
                  <div class="d-flex align-items-center">
                    {{ run.name }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6 ps-0 pe-4">
        <div class="mb-3">
          <label for="bitMD" class="form-label"
            >Bit MD
            <span class="p-error"> *</span>
          </label>

          <div
            class="p-inputgroup p-input-icon-right non-icon unit"
            [ngClass]="
              formInitiateGroup.get('bitMD')?.disabled
                ? 'unit-disabled ' + formUnit.Depth + '-unit'
                : formUnit.Depth + '-unit'
            "
          >
            <input
              type="text"
              id="bitMD"
              placeholder="Enter value..."
              class="inputNumber_Clear_e unit"
              [formControlName]="fieldNameKeys.bitMD"
              pInputText
              NumbersOnly
              [maxLength]="10"
              [maxDecimalDigit]="
                AppHelper.MathFunctions.getDigitUnitSystems(formUnit.Depth)
              "
              appUnitInput
              [unitSystem]="UNIT_SYSTEM"
              [referenceUnit]="projectUnit"
              [currentUnit]="
                interventionDataEdit ? interventionDataEdit.unit : ''
              "
              unitType="depth"
              [factorDepthNumber]="0"
              [digitDepthNumber]="0"
            />
          </div>
          <div class="">
            <div *ngIf="isInvalidControl(fieldNameKeys.bitMD)">
              <ng-container
                *ngFor="let msg of getErrorByField(fieldNameKeys.bitMD)"
              >
                <div class="inner-msg-error">
                  <span>{{ msg }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 pe-4">
        <div class="mb-3">
          <label for="holeMD" class="form-label"
            >Hole MD
            <span class="p-error"> *</span>
          </label>

          <div
            class="p-inputgroup p-input-icon-right non-icon unit"
            [ngClass]="
              formInitiateGroup.get('holeMD')?.disabled
                ? 'unit-disabled ' + formUnit.Depth + '-unit'
                : formUnit.Depth + '-unit'
            "
          >
            <input
              type="text"
              id="holeMD"
              placeholder="Enter value..."
              class="inputNumber_Clear_e unit"
              [formControlName]="fieldNameKeys.holeMD"
              pInputText
              NumbersOnly
              [maxLength]="10"
              [maxDecimalDigit]="
                AppHelper.MathFunctions.getDigitUnitSystems(formUnit.Depth)
              "
              appUnitInput
              [unitSystem]="UNIT_SYSTEM"
              [referenceUnit]="projectUnit"
              [currentUnit]="
                interventionDataEdit ? interventionDataEdit.unit : ''
              "
              unitType="depth"
              [factorDepthNumber]="0"
              [digitDepthNumber]="0"
            />
          </div>
          <div class="">
            <div *ngIf="isInvalidControl(fieldNameKeys.holeMD)">
              <ng-container
                *ngFor="let msg of getErrorByField(fieldNameKeys.holeMD)"
              >
                <div class="inner-msg-error">
                  <span>{{ msg }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0">
        <div class="">
          <label for="" class="form-label"
            >Current Operation (select one)<span class="p-error">
              *</span
            ></label
          >
        </div>
        <div class="d-flex">
          <div class="col-6 ps-0 pe-4 d-flex align-items-center">
            <div
              class="field-checkbox me-2 d-flex align-items-center justify-content-center height-override"
            >
              <p-checkbox
                (onChange)="
                  singleSelectCheckBox(
                    'selectedOperation',
                    formInitiateGroup
                  )
                "
                [value]="operationsWay[0].key"
                name="group_selectedOperation"
                [formControlName]="fieldNameKeys.selectedOperation"
                class="custom-p-checkbox"
              ></p-checkbox>
            </div>
            <div class="w-100" style="max-width: 366px !important">
              <p-treeSelect
                [options]="operations"
                placeholder="Operation"
                class="custom-p-tree"
                scrollHeight="200px"
                [showClear]="true"
                (onClear)="onClearOperation($event)"
                (onNodeExpand)="onNodeExpand($event)"
                (onNodeSelect)="onOptionsSelectedOperation($event)"
                [formControlName]="fieldNameKeys.operation"
                [propagateSelectionDown]="false"
                [propagateSelectionUp]="false"
              >
                <ng-template pTemplate="value">
                  {{ selectedNodeOperationString || "Select Item" }}
                </ng-template>
              </p-treeSelect>
            </div>
          </div>

          <div class="col-6 pe-4 d-flex align-items-center">
            <div
              class="field-checkbox me-2 d-flex align-items-center justify-content-center height-override"
            >
              <p-checkbox
                (onChange)="
                  singleSelectCheckBox(
                    'selectedOperation',
                    formInitiateGroup
                  )
                "
                [value]="operationsWay[1].key"
                name="group_selectedOperation"
                [formControlName]="fieldNameKeys.selectedOperation"
                class="custom-p-checkbox"
              ></p-checkbox>
            </div>
            <div class="w-100">
              <div class="p-inputgroup">
                <input
                  type="text"
                  pInputText
                  id="anotherOperation"
                  placeholder="Enter value..."
                  [formControlName]="fieldNameKeys.anotherOperation"
                  TextInputValidate
                  typeCheck="short"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 d-flex align-items-center ps-0 pe-4">
            <div *ngIf="isInvalidControl(fieldNameKeys.operation)">
              <ng-container
                *ngFor="
                  let msg of getErrorByField(fieldNameKeys.operation);
                  let isFirst = first
                "
              >
                <div
                  *ngIf="isFirst"
                  class="inner-msg-error"
                  style="margin-left: 30px"
                >
                  <span>{{ msg }}</span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center">
            <div *ngIf="isInvalidControl(fieldNameKeys.anotherOperation)">
              <ng-container
                *ngFor="
                  let msg of getErrorByField(fieldNameKeys.anotherOperation)
                "
              >
                <div class="inner-msg-error" style="margin-left: 30px">
                  <span>{{ msg }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="isInvalidControl(fieldNameKeys.selectedOperation)">
          <ng-container
            *ngFor="let msg of getErrorByField(fieldNameKeys.selectedOperation)"
          >
            <div class="inner-msg-error">
              <span>{{ msg }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label for="event" class="form-label"
            >Event<span class="p-error"> *</span></label
          >
        </div>
        <p-treeSelect
          #treeEvent
          [options]="events"
          placeholder="Select Item"
          class="custom-p-tree"
          scrollHeight="200px"
          [showClear]="true"
          (onClear)="onClearEvent($event)"
          (onNodeSelect)="onOptionsSelected($event)"
          (onNodeExpand)="onNodeExpand($event)"
          [formControlName]="fieldNameKeys.event"
          [innerMsg]="messageErrors"
          [propagateSelectionDown]="false"
        >
          <ng-template pTemplate="value">
            {{ selectedNodeString || "Select Item" }}
          </ng-template>
        </p-treeSelect>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label for="detailedEventDescription" class="form-label"
            >Event Description<span class="p-error"> *</span></label
          >
        </div>
        <textarea
          class="w-100"
          [rows]="4"
          pInputTextarea
          [autoResize]="true"
          id="detailedEventDescription"
          placeholder="Enter here event description..."
          [formControlName]="fieldNameKeys.eventDescription"
          (focusout)="onBlurMethod(fieldNameKeys.eventDescription, formInitiateGroup)"
          [innerMsg]="messageErrors"
          class="custom-textarea"
          TextInputValidate
          customPattern="[]"
          [isCustomPattern]="true"
        ></textarea>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label for="flag" class="form-label"
            >Flag<span class="p-error"> *</span></label
          >
        </div>
        <!-- [pTooltip]="selectedFlagPurpose || ''" tooltipPosition="bottom" -->
        <div>
          <p-dropdown
            [options]="flags"
            [formControlName]="fieldNameKeys.flag"
            optionLabel="name"
            [showClear]="true"
            scrollHeight="150px"
            placeholder="Select a Flag"
            class="custom-p-drop"
            [innerMsg]="messageErrors"
            (onChange)="slectedFlag($event)"
            (onClear)="selectedFlagPurpose = ''"
            [resetFilterOnHide]="true"
            [disabled]="isMissingDistribution"
          >
            <ng-template let-flag pTemplate="selectedItem">
              <div class="" *ngIf="fieldNameKeys.flag">
                <div class="gap-3 d-flex align-items-center">
                  <div class="short-label">
                    {{ flag.purpose || flag.name }}
                  </div>
                  <i class="flag" [ngClass]="flag.code"></i>
                </div>
              </div>
            </ng-template>
            <ng-template let-flag pTemplate="item">
              <div class="">
                <div class="gap-3 d-flex align-items-center">
                  <div class="short-label">
                    {{ flag.purpose || flag.name }}
                  </div>
                  <i class="flag" [ngClass]="flag.code"></i>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label for="recommendation" class="form-label"
            >Recommendation<span class="p-error"> *</span></label
          >
        </div>
        <textarea
          class="w-100"
          [rows]="4"
          pInputTextarea
          [autoResize]="true"
          id="recommendation"
          placeholder="Enter here recommendation..."
          [formControlName]="fieldNameKeys.recommendation"
          (focusout)="onBlurMethod(fieldNameKeys.recommendation, formInitiateGroup)"
          [innerMsg]="messageErrors"
          class="custom-textarea"
          TextInputValidate
          customPattern="[]"
          [isCustomPattern]="true"
        ></textarea>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="col-12">
          <label class="form-label">Risk<span class="p-error"> *</span></label>

          <div class="d-flex">
            <div class="col-6 ps-0 pe-4 d-flex align-items-center">
              <div
                class="field-checkbox me-2 d-flex align-items-center justify-content-center height-override"
              >
                <p-checkbox
                  (onChange)="
                    singleSelectCheckBox('selectedRisk', formInitiateGroup)
                  "
                  [value]="risksway[0].key"
                  name="group_selectedRisk"
                  [formControlName]="fieldNameKeys.selectedRisk"
                  class="custom-p-checkbox"
                ></p-checkbox>
              </div>
              <div class="w-100" style="max-width: 366px !important">
                <div class="multiple-risk">
                  <p-treeSelect
                    #treeRiskEvent
                    selectionMode="checkbox"
                    [options]="risks"
                    placeholder="Risk event"
                    class="custom-p-tree"
                    scrollHeight="200px"
                    [showClear]="true"
                    (onClear)="onClearRisk($event)"
                    (onNodeSelect)="onOptionsSelectedRiskEvent($event)"
                    (onNodeUnselect)="onOptionsUnSelectedRiskEvent($event)"
                    [formControlName]="fieldNameKeys.risk"
                    [propagateSelectionDown]="false"
                    [propagateSelectionUp]="false"
                  >
                    <ng-template pTemplate="value">
                      <div>
                        {{
                          (selectedNodeStringRisk === ""
                            ? "Risk Event"
                            : selectedNodeStringRisk
                          ) | checkRiskLength
                        }}
                        <!-- {{ selectedNodeStringRisk }} -->
                      </div>
                    </ng-template>
                  </p-treeSelect>
                </div>
              </div>
            </div>

            <div class="col-6 d-flex align-items-center">
              <div
                class="field-checkbox me-2 d-flex align-items-center justify-content-center height-override"
              >
                <p-checkbox
                  (onChange)="
                    singleSelectCheckBox('selectedRisk', formInitiateGroup)
                  "
                  [value]="risksway[1].key"
                  name="group_selectedRisk"
                  [formControlName]="fieldNameKeys.selectedRisk"
                  class="custom-p-checkbox"
                ></p-checkbox>
              </div>
              <div class="w-100">
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    id="anotherRisk"
                    placeholder="Enter value..."
                    [formControlName]="fieldNameKeys.anotherRisk"
                    TextInputValidate
                    typeCheck="short"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-6 d-flex align-items-center ps-0 pe-4">
              <div *ngIf="isInvalidControl(fieldNameKeys.risk)">
                <ng-container
                  *ngFor="let msg of getErrorByField(fieldNameKeys.risk)"
                >
                  <div class="inner-msg-error" style="margin-left: 23px">
                    <span>{{ msg }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-6 d-flex align-items-center">
              <div *ngIf="isInvalidControl(fieldNameKeys.anotherRisk)">
                <ng-container
                  *ngFor="let msg of getErrorByField(fieldNameKeys.anotherRisk)"
                >
                  <div class="inner-msg-error" style="margin-left: 23px">
                    <span>{{ msg }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="isInvalidControl(fieldNameKeys.selectedRisk)">
            <ng-container
              *ngFor="let msg of getErrorByField(fieldNameKeys.selectedRisk)"
            >
              <div class="inner-msg-error">
                <span>{{ msg }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div
        class="col-12 ps-0 pe-4"
        [ngClass]="
          formInitiateGroup.get('evidence')!.disabled
            ? 'label-disabled'
            : ''
        "
      >
        <div class="">
          <label class="form-label">Evidence</label>
        </div>
        <p-fileUpload
          #fileUploader
          class="fileupload-initiate"
          accept=".pdf,.jpg, .jpeg, .png"
          [disabled]="
            formInitiateGroup.get('evidence')!.disabled ||
            isLoadingFileEvidence
          "
          [showUploadButton]="false"
          [showCancelButton]="false"
          [files]="uploadedFiles"
          (onClear)="onClearFiles($event)"
          (onError)="onError($event)"
          (onSelect)="onSelect($event)"
          (click)="clearInlineErrorUpload()"
          [multiple]="true"
          [chooseLabel]="UploadTitle.choose"
          [customUpload]="true"
        >
          <ng-template pTemplate="toolbar">
            <button
              *ngIf="!formInitiateGroup.get('evidence')!.disabled"
              type="button"
              class="uploadButton"
              pButton [label]="UploadTitle.upload"
              icon="pi pi-copy"
              (click)="onPasteClipBoardFile()"
              [disabled]="isLoadingFileEvidence">
            </button>
            <button 
              *ngIf="!formInitiateGroup.get('evidence')!.disabled"
              type="button"
              class="cancelAllButton"
              pButton
              icon="pi pi-times"
              [disabled]="
                uploadedFiles.length == 0 ||
                isLoadingFileEvidence
              "
              [label]="UploadTitle.cancel"
              (click)="fileUploader.clear()"
            ></button>
          </ng-template>
          <ng-template let-files pTemplate="content">
            <div class="uploadCounter mt-1" *ngIf="files.length > 1">
              {{ files.length }} files uploaded
            </div>
            <div class="uploadCounter mt-1" *ngIf="files.length == 1">
              {{ files.length }} file uploaded
            </div>
            <div
              class="empty-upload"
              (click)="openSelectFileUpload()"
              *ngIf="files.length === 0"
            >
              Or drop files here to upload
            </div>
            <app-loading
              [isLoading]="isLoadingFileEvidence"
              size="40px"
            ></app-loading>
            <div *ngIf="files.length !== 0">
              <p-table
                [value]="files"
                [reorderableColumns]="false"
                (onRowReorder)="dragTime($event)"
                tableStyleClass="custom-table-upload"
              >
                <ng-template
                  pTemplate="body"
                  let-rowData
                  let-columns="columns"
                  let-index="rowIndex"
                >
                  <tr [pReorderableRow]="index">
                    <td>
                      <div class="p-fileupload-row">
                        <div></div>
                        <div
                          class="p-fileupload-filename"
                          [pReorderableRowHandle]="index"
                          [pTooltip]="rowData['name']"
                          tooltipPosition="bottom"
                        >
                          {{ rowData["name"] }}
                        </div>
                        <div [pReorderableRowHandle]="index">
                          {{ rowData["size"] | formatFileSizePipe : true }}
                        </div>
                        <div>
                          <button
                            type="button"
                            icon="pi pi-times"
                            pbutton=""
                            class="p-element p-button p-component p-button-icon-only"
                            ng-reflect-icon="pi pi-times"
                            (click)="removeItemUpload(index)"
                          >
                            <span
                              class="p-button-icon pi pi-times"
                              aria-hidden="true"
                            ></span
                            ><span aria-hidden="true" class="p-button-label"
                              >&nbsp;</span
                            >
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </ng-template>

          <ng-template let-file pTemplate="file"></ng-template>
        </p-fileUpload>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label class="form-label">Rig-based Team Validation Required?</label>
        </div>

        <div class="d-flex">
          <label for="yesRigBased" class="form-label"></label>
          <div
            class="field-checkbox d-flex me-3 justify-content-center height-override"
          >
            <p-checkbox
              (onChange)="
                singleSelectCheckBox(
                  'validationDetailCheck',
                  formInitiateGroup
                )
              "
              [label]="yesNoData[0].name"
              [value]="yesNoData[0].key"
              name="group_validationDetailCheck"
              [formControlName]="fieldNameKeys.validationDetailCheck"
              class="custom-p-checkbox"
            ></p-checkbox>
          </div>

          <label for="noRigBased" class="form-label"></label>
          <div
            class="field-checkbox d-flex justify-content-center height-override"
          >
            <p-checkbox
              (onChange)="
                singleSelectCheckBox(
                  'validationDetailCheck',
                  formInitiateGroup
                )
              "
              [label]="yesNoData[1].name"
              [value]="yesNoData[1].key"
              name="group_validationDetailCheck"
              [formControlName]="fieldNameKeys.validationDetailCheck"
              class="custom-p-checkbox"
            ></p-checkbox>
          </div>
        </div>
        <div *ngIf="isInvalidControl(fieldNameKeys.validationDetailCheck)">
          <ng-container
            *ngFor="
              let msg of getErrorByField(fieldNameKeys.validationDetailCheck)
            "
          >
            <div class="inner-msg-error">
              <span>{{ msg }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label for="validationDetail" class="form-label"
            >Rig-based Team Validation Details
            <span
              *ngIf="
                isDynamicRequired(
                  'validationDetailCheck',
                  formInitiateGroup
                )
              "
              class="p-error"
              >*</span
            >
          </label>
        </div>

        <textarea
          class="w-100"
          [rows]="4"
          pInputTextarea
          [autoResize]="true"
          id="rigBasedDetail"
          placeholder="Enter here validation details..."
          [formControlName]="fieldNameKeys.validationDetail"
          (focusout)="onBlurMethod(fieldNameKeys.validationDetail, formInitiateGroup)"
          [innerMsg]="messageErrors"
          class="custom-textarea"
          TextInputValidate
          customPattern="[]"
          [isCustomPattern]="true"
        ></textarea>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 ps-0 pe-4">
        <div class="">
          <label class="form-label"
            >Further Action Required?<span class="p-error"> *</span></label
          >
        </div>

        <div class="d-flex">
          <label for="yesFurtherAction" class="form-label"></label>
          <div
            class="field-checkbox d-flex me-3 justify-content-center height-override"
          >
            <p-checkbox
              [ngClass]="formInitiateGroup.value ? 'active' : 'inactive'"
              (onChange)="
                singleSelectCheckBox('furtherAction', formInitiateGroup)
              "
              [label]="yesNoData[0].name"
              [value]="yesNoData[0].key"
              name="group_furtherAction"
              [formControlName]="fieldNameKeys.furtherAction"
              class="custom-p-checkbox"
            ></p-checkbox>
          </div>

          <label for="noFurtherAction" class="form-label"></label>
          <div
            class="field-checkbox d-flex justify-content-center height-override"
          >
            <p-checkbox
              [ngClass]="formInitiateGroup.value ? 'active' : 'inactive'"
              (onChange)="
                singleSelectCheckBox('furtherAction', formInitiateGroup)
              "
              [label]="yesNoData[1].name"
              [value]="yesNoData[1].key"
              name="group_furtherAction"
              [formControlName]="fieldNameKeys.furtherAction"
              class="custom-p-checkbox"
            ></p-checkbox>
          </div>
        </div>
        <div *ngIf="isInvalidControl(fieldNameKeys.furtherAction)">
          <ng-container
            *ngFor="let msg of getErrorByField(fieldNameKeys.furtherAction)"
          >
            <div class="inner-msg-error">
              <span>{{ msg }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
