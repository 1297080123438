<div class="help-center">
  <p-card class="card-help-center">
    <div
      class="title-help-center flex flex-row flex-wrap justify-content-between"
    >
      <div class="flex flex-row">
        <p class="context-title-help-center mr-3">
          Help Center
          <i
            class="pi pi-info-circle"
            style="font-size: 0.75rem"
            pTooltip="{{ TOOLTIP_HELP_CENTER }}"
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip-help-center"
          ></i>
        </p>
        <div class="description-help-center-button flex align-items-start">
          <button
            pButton
            type="button"
            label="Report an Issue"
            class="p-button-secondary report-an-issue"
            (click)="showReportIssueDialog()"
          ></button>
        </div>
      </div>
      <div class="icon-close-help-center">
        <button
          pButton
          type="button"
          icon="pi pi-times"
          iconPos="left"
          (click)="closeHelpCenter()"
        ></button>
      </div>
    </div>

    <div class="help-center-tabview">
      <p-tabView
        #tabViewForm
        [activeIndex]="indexTabview"
        (activeIndexChange)="tabviewChangeIndex($event)"
      >
        <p-tabPanel
          [header]="'VIDEOS ' + '(' + countVideos + ')'"
          class="help-video-list"
        >
          <app-tabview-video
            [user]="user"
            [roleView]="roleView"
            (confirmEvent)="confirm('')"
            (videoCount)="updateVideoCount($event)"
          ></app-tabview-video>
        </p-tabPanel>
        <p-tabPanel header="USER GUIDE" class="">
          <app-tabview-guide [roleView]="roleView"></app-tabview-guide>
        </p-tabPanel>
        <p-tabPanel header="TUTORIALS" class="">
          <app-tabview-tutorial [roleView]="roleView"></app-tabview-tutorial>
        </p-tabPanel>
        <p-tabPanel header="RELEASE NOTES" class="">
          <app-tabview-release-note></app-tabview-release-note>
        </p-tabPanel>
        <p-tabPanel header="TERMS OF USE">
          <app-tabview-terms-of-use></app-tabview-terms-of-use>
        </p-tabPanel>
      </p-tabView>
    </div>
  </p-card>
</div>
