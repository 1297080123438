<div
  class="intervention-content__form--RESOLVE container"
  [ngClass]="readOnly ? 'label-readonly' : ''"
  *ngIf="!!formResolveGroup"
  [formGroup]="formResolveGroup"
>
  <div class="row mb-3">
    <div class="col-12 ps-0 pe-4">
      <div class="">
        <label for="contactMethod" class="form-label"
          >Contact Method<span class="p-error"> *</span></label
        >
      </div>
      <p-treeSelect
        #treeContactMethod
        selectionMode="checkbox"
        [options]="contactMethodList"
        placeholder="Select method"
        class="custom-p-tree"
        [ngClass]="{ 'without-parent': isMissingDistribution }"
        scrollHeight="200px"
        [showClear]="true"
        (onClear)="onClearEvent($event)"
        (onNodeSelect)="onOptionsSelectedContact()"
        (onNodeUnselect)="onOptionsSelectedContact()"
        [formControlName]="fieldNameKeys.contactMethod"
        [innerMsg]="messageErrors"
      >
        <ng-template pTemplate="value">
          {{ selectedNodeStringContact || "Select method" }}
        </ng-template>
      </p-treeSelect>
    </div>
  </div>

  <div class="row mb-3" style="padding-left: 1px">
    <div class="col-12 ps-0">
      <div class="">
        <label for="" class="form-label"
          >Was the recommended action taken?<span class="p-error">
            *</span
          ></label
        >
      </div>

      <div class="d-flex align-items-center">
        <div
          *ngFor="let action of recommendedActions; first as isFirst"
          class="d-flex me-3"
          [ngStyle]="
            isFirst ? { 'flex-basis': '55px' } : { 'flex-basis': '50px' }
          "
        >
          <p-checkbox
            (onChange)="
              singleSelectCheckBox('recommendedActions', formResolveGroup)
            "
            [label]="action.name"
            [value]="action.key"
            [formControlName]="fieldNameKeys.recommendedActions"
            class="custom-p-checkbox"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div
      class="col-12 ps-0"
      *ngIf="isInvalidControl(fieldNameKeys.recommendedActions)"
    >
      <ng-container
        *ngFor="let msg of getErrorByField(fieldNameKeys.recommendedActions)"
      >
        <div class="inner-msg-error">
          <span>{{ msg }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row mb-3" style="padding-left: 1px">
    <div class="col-12 ps-0 pe-4">
      <div class="">
        <label for="resolution" class="form-label"
          >Resolution<span class="p-error"> *</span></label
        >
      </div>
      <textarea
        class="w-100"
        [rows]="3"
        pInputTextarea
        class="custom-textarea custom-p-input"
        [autoResize]="true"
        id="resolution"
        placeholder="Enter here recommendation following details..."
        (focusout)="onBlurMethod('resolution', formResolveGroup)"
        [formControlName]="fieldNameKeys.resolution"
        [innerMsg]="messageErrors"
        TextInputValidate
        customPattern="[]"
        [isCustomPattern]="true"
      ></textarea>
    </div>
  </div>

  
  <div class="row mb-3">
    <div
      class="col-12 ps-0 pe-4"
      [ngClass]="
        formResolveGroup.get('evidence')!.disabled
          ? 'label-disabled'
          : ''
      "
    >
      <div class="">
        <label class="form-label">Evidence</label>
      </div>
      <p-fileUpload
        #fileUploader
        class="fileupload-resolve"
        [disabled]="
          formResolveGroup.get('evidence')!.disabled ||
          isLoadingFileEvidence
        "
        [showUploadButton]="false"
        [showCancelButton]="false" 
        [files]="uploadedFiles"
        (onSelect)="onSelect($event)"
        (onError)="onError($event)"
        (onClear)="onClearFiles()"
        (click)="clearInlineErrorUpload()"
        [multiple]="true"
        [customUpload]="true"
        [chooseLabel]="UploadTitle.choose"
        accept=".pdf,.jpg, .jpeg, .png"
      >
        <ng-template pTemplate="toolbar">
          <button
            *ngIf="!formResolveGroup.get('evidence')!.disabled"
            type="button"
            class="uploadButton"
            pButton [label]="UploadTitle.upload"
            icon="pi pi-copy"
            (click)="onPasteClipBoardFile()"
            [disabled]="isLoadingFileEvidence">
          </button>
          <button 
            *ngIf="!formResolveGroup.get('evidence')!.disabled"
            type="button"
            class="cancelAllButton"
            pButton
            icon="pi pi-times"
            [disabled]="
              uploadedFiles.length == 0 ||
              isLoadingFileEvidence
            "
            [label]="UploadTitle.cancel"
            (click)="fileUploader.clear()"
          ></button>
        </ng-template>
        <ng-template let-files pTemplate="content">
          <div class="uploadCounter mt-1" *ngIf="files.length > 1">
            {{ files.length }} files uploaded
          </div>
          <div class="uploadCounter mt-1" *ngIf="files.length == 1">
            {{ files.length }} file uploaded
          </div>
          <div
            class="empty-upload"
            (click)="openSelectFileUpload()"
            *ngIf="files.length === 0"
          >
            Or drop files here to upload
          </div>
          <div
            class="overlay-progressBar"
            *ngIf="isLoadingFileEvidence"
          >
            <div class="content-progress-bar">
              <span class="loader"></span>
              <span class="text-progressbar">Loading ...</span>
            </div>
          </div>
          <div *ngIf="files.length !== 0">
            <p-table
              [value]="files"
              [reorderableColumns]="false"
              (onRowReorder)="dragTime($event)"
              tableStyleClass="custom-table-upload"
            >
              <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
                let-index="rowIndex"
              >
                <tr [pReorderableRow]="index">
                  <td>
                    <div class="p-fileupload-row">
                      <div></div>
                      <div
                        class="p-fileupload-filename"
                        [pReorderableRowHandle]="index"
                        [pTooltip]="rowData['name']"
                        tooltipPosition="bottom"
                      >
                        {{ rowData["name"] }}
                      </div>
                      <div [pReorderableRowHandle]="index">
                        {{ rowData["size"] | formatFileSizePipe : true }}
                      </div>
                      <div>
                        <button
                          type="button"
                          icon="pi pi-times"
                          pbutton=""
                          class="p-element p-button p-component p-button-icon-only"
                          ng-reflect-icon="pi pi-times"
                          (click)="removeItemUpload(index)"
                        >
                          <span
                            class="p-button-icon pi pi-times"
                            aria-hidden="true"
                          ></span
                          ><span aria-hidden="true" class="p-button-label"
                            >&nbsp;</span
                          >
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>

        <ng-template let-file pTemplate="file"></ng-template>
      </p-fileUpload>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 ps-0 pe-4">
      <div class="">
        <label for="timesaved" class="form-label">Time Saved</label>

        <div class="p-inputgroup">
          <input
            type="number"
            pInputText
            id="timesaved"
            class="hideOptional inputNumber_Clear_e custom-p-input"
            placeholder="Enter hours saved..."
            data-decimal="4"
            (keypress)="inputNumber_Clear_e($event)"
            onKeyPress="if(getNumberAfterDecimalPoint(this.value) === 2) return false; if(maxLengthNumber(this.value) === 10) return false;"
            (paste)="handlePasteTimeSaved('timeSaved', $event)"
            onwheel="return false;"
            (keydown.arrowup)="(false)"
            (keydown.arrowdown)="(false)"
            [formControlName]="fieldNameKeys.timeSaved"
            [innerMsg]="messageErrors"
          />
        </div>
        <div class="inner-description-text">
          <span>
            {{ formResolveGroup.get("timeSaved")?.value | timeDecimalToHHMM }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 ps-0">
      <div class="">
        <label for="" class="form-label"
          >Is notification of resolution required?<span class="p-error">
            *</span
          ></label
        >
      </div>

      <div class="d-flex align-items-center">
        <div
          *ngFor="let notification of notificationResolution"
          class="d-flex me-3"
          [ngStyle]="{ 'flex-basis': '50px' }"
        >
          <p-checkbox
            (onChange)="
              singleSelectCheckBox('notificationResolution', formResolveGroup)
            "
            [label]="notification.name"
            [value]="notification.key"
            [formControlName]="fieldNameKeys.notificationResolution"
            class="custom-p-checkbox"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div
      class="col-12 ps-0"
      *ngIf="isInvalidControl(fieldNameKeys.notificationResolution)"
    >
      <ng-container
        *ngFor="
          let msg of getErrorByField(fieldNameKeys.notificationResolution)
        "
      >
        <div class="inner-msg-error">
          <span>{{ msg }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row mb-6">
    <div class="col-12 ps-0">
      <div class="">
        <label for="" class="form-label"
          >Send notification of resolution to the same distribution
          <span class="p-error"> *</span>
        </label>
      </div>

      <div class="col-6 d-flex align-items-center">
        <div class="d-flex me-3" [ngStyle]="{ 'flex-basis': '50px' }">
          <p-checkbox
            (onChange)="
              singleSelectCheckBox(
                'selectedWhereSendNotification',
                formResolveGroup
              )
            "
            [value]="sendNotificationToDistribution[0].key"
            [label]="sendNotificationToDistribution[0].name"
            name="group_selectedWhereSendNotification"
            [formControlName]="fieldNameKeys.selectedWhereSendNotification"
            class="custom-p-checkbox"
          ></p-checkbox>
        </div>
        <div class="d-flex me-3 gap-2" [ngStyle]="{ 'flex-grow': '1' }">
          <p-checkbox
            (onChange)="
              singleSelectCheckBox(
                'selectedWhereSendNotification',
                formResolveGroup
              )
            "
            [value]="sendNotificationToDistribution[1].key"
            name="group_selectedWhereSendNotification"
            [formControlName]="fieldNameKeys.selectedWhereSendNotification"
            class="custom-p-checkbox"
          ></p-checkbox>
          <div class="p-checkbox-label" style="max-width: 356px; width: 356px">
            <p-dropdown
              [options]="distributionListofRig"
              placeholder="Alt Distro"
              optionLabel="name"
              [showClear]="true"
              scrollHeight="150px"
              class="custom-p-drop p-dropdown-distribution"
              [formControlName]="fieldNameKeys.anotherWhereSendNotification"
              (onChange)="slectedFlag($event)"
              (onClear)="selectedFlagPurpose = ''"
              [resetFilterOnHide]="true"
              [disabled]="isMissingDistribution"
            >
              <ng-template let-flag pTemplate="selectedItem">
                <div
                  class="w-100"
                  *ngIf="fieldNameKeys.anotherWhereSendNotification"
                >
                  <div class="gap-3 d-flex align-items-center" style="max-width: 275px">
                    <div class="short-label">
                      {{ flag.purpose || flag.name }}
                    </div>
                    <i class="flag" [ngClass]="flag.code"></i>
                  </div>
                </div>
              </ng-template>
              <ng-template let-flag pTemplate="item">
                <div class="">
                  <div class="gap-3 d-flex align-items-center">
                    <div class="short-label">
                      {{ flag.purpose || flag.name }}
                    </div>
                    <i class="flag" [ngClass]="flag.code"></i>
                  </div>
                </div> </ng-template
            ></p-dropdown>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="d-flex me-3" [ngStyle]="{ 'flex-basis': '50px' }"></div>
        <div
          class="d-flex me-3"
          [ngStyle]="{ 'flex-grow': '1' }"
          style="margin-left: 22px"
        >
          <div
            *ngIf="isInvalidControl(fieldNameKeys.anotherWhereSendNotification)"
          >
            <ng-container
              *ngFor="
                let msg of getErrorByField(
                  fieldNameKeys.anotherWhereSendNotification
                )
              "
            >
              <div class="inner-msg-error">
                <span>{{ msg }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        *ngIf="isInvalidControl(fieldNameKeys.selectedWhereSendNotification)"
      >
        <ng-container
          *ngFor="
            let msg of getErrorByField(
              fieldNameKeys.selectedWhereSendNotification
            )
          "
        >
          <div class="inner-msg-error">
            <span>{{ msg }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- <div (click)="checkValue()">x</div> -->
