<div class="filter">
  <p-button
    [attr.data-cy]="'filters-button'"
    class="custom-p-button custom-p-button--secondary"
    [label]="'Filters'"
    (click)="op.toggle($event)"
  >
  </p-button>

  <p-overlayPanel
    #op
    (onShow)="onShow()"
    (onHide)="restoreSelect()"
    [showCloseIcon]="false"
    styleClass="filter-advanced-user"
  >
    <div class="option-filter">
      <p class="filter-label">{{ METADATA.REMOTE_CENTER.LABEL }}</p>
      <p-multiSelect
        [options]="remoteCentersOption"
        [(ngModel)]="selectedRemoteCenter"
        optionLabel="remoteCenterName"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        (onChange)="
          selectedOptionChange(
            $event,
            selectedRemoteCenter,
            METADATA.REMOTE_CENTER.LABEL
          )
        "
        (onPanelHide)="loadAnotherDataExcept(METADATA.REMOTE_CENTER.LABEL)"
        (onClear)="selectedOptionClear(METADATA.REMOTE_CENTER.LABEL)"
        [resetFilterOnHide]="true"
      >
      </p-multiSelect>
    </div>
    <div class="option-filter">
      <p class="filter-label">{{ METADATA.ROLE.LABEL }}</p>
      <p-treeSelect
        [(ngModel)]="selectedRole"
        [options]="rolesOptionRoot"
        (onNodeSelect)="onOptionsSelected($event)"
        (onNodeUnselect)="onOptionsUnSelected($event)"
        (onHide)="loadAnotherDataExcept(METADATA.ROLE.LABEL)"
        (onClear)="onClearRole()"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        selectionMode="checkbox"
        class="custom-p-tree"
        scrollHeight="200px"
        [showClear]="true"
        [propagateSelectionDown]="true"
      >
        <ng-template pTemplate="value">
          {{ selectedRoleString || "All" }}
        </ng-template>
      </p-treeSelect>
    </div>

    <div class="option-filter">
      <p class="filter-label">{{ METADATA.FUNCTIONAL_MAILBOX.LABEL }}</p>
      <p-multiSelect
        [options]="functionalMailsOption"
        [(ngModel)]="selectedFunctionalMail"
        optionLabel="mailboxName"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        (onChange)="
          selectedOptionChange(
            $event,
            selectedFunctionalMail,
            METADATA.FUNCTIONAL_MAILBOX.LABEL
          )
        "
        (onPanelHide)="loadAnotherDataExcept(METADATA.FUNCTIONAL_MAILBOX.LABEL)"
        (onClear)="selectedOptionClear(METADATA.FUNCTIONAL_MAILBOX.LABEL)"
        [resetFilterOnHide]="true"
      >
      </p-multiSelect>
    </div>

    <div class="option-filter">
      <p class="filter-label">{{ METADATA.PSL.LABEL }}</p>
      <p-multiSelect
        [options]="PSLsOption"
        [(ngModel)]="selectedPSL"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        (onChange)="
          selectedOptionChange($event, selectedPSL, METADATA.PSL.LABEL)
        "
        (onPanelHide)="loadAnotherDataExcept(METADATA.PSL.LABEL)"
        (onClear)="selectedOptionClear(METADATA.PSL.LABEL)"
        [resetFilterOnHide]="true"
      >
      </p-multiSelect>
    </div>

    <div class="option-filter">
      <p class="filter-label">{{ METADATA.COUNTRY.LABEL }}</p>
      <p-multiSelect
        [options]="countriesOption"
        [(ngModel)]="selectedCountry"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        (onChange)="
          selectedOptionChange(
            $event,
            selectedRemoteCenter,
            METADATA.COUNTRY.LABEL
          )
        "
        (onPanelHide)="loadAnotherDataExcept(METADATA.COUNTRY.LABEL)"
        (onClear)="selectedOptionClear(METADATA.COUNTRY.LABEL)"
        [resetFilterOnHide]="true"
      >
      </p-multiSelect>
    </div>

    <div class="p-dialog-footer d-flex justify-content-end gap-2 mt-4">
      <div class="btn-cancel">
        <p-button
          [attr.data-cy]="'cancel-button'"
          type="button"
          label="Cancel"
          class="custom-p-button custom-p-button--primary"
          (click)="op.toggle($event)"
        ></p-button>
      </div>
      <div>
        <p-button
          [attr.data-cy]="'apply-button'"
          type="button"
          label="Apply"
          class="custom-p-button custom-p-button--secondary"
          (click)="executeFilter(); op.toggle($event)"
        ></p-button>
      </div>
    </div>
  </p-overlayPanel>
</div>
