<p-dialog
  [(visible)]="displayIntervalForm"
  [modal]="true"
  [style]="{ width: '480px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0"
>
  <ng-template pTemplate="header">
    <div class="header-dialog">
      <span [pTooltip]="titlePopup || ''" tooltipPosition="bottom">
        {{ titlePopup }}
      </span>
    </div>
    <div
      class="custom-close-icon cursor-pointer"
      (click)="closeIntervalPopup()"
    >
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <!-- [ngClass]="{ 'form-disabled': isViewer }" -->
    <div class="container editForm" [formGroup]="formIntervalGroup">
      <div class="row">
        <div class="col-12 mb-2">
          <div class="add-input-title label-disabled">Well</div>
          <div>
            <div class="add-input p-inputgroup non-icon">
              <input
                type="text"
                pInputText
                class="custom-p-input"
                (focusout)="onBlurMethod('well', formIntervalGroup)"
                [formControlName]="fieldNameKeys.wellName"
                [innerMsg]="messageErrors"
              />
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title label-disabled">Wellbore</div>
          <div>
            <div class="add-input p-inputgroup non-icon">
              <input
                type="text"
                pInputText
                class="custom-p-input"
                (focusout)="onBlurMethod('wellbore', formIntervalGroup)"
                [formControlName]="fieldNameKeys.wellboreName"
                [innerMsg]="messageErrors"
              />
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Interval Name<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right non-icon">
              <i class="icon pi pi-ellipsis-v cursor-pointer"></i>
              <input
                type="text"
                pInputText
                class="custom-p-input"
                placeholder="Enter Interval Name"
                (focusout)="onBlurMethod('intervalName', formIntervalGroup)"
                [formControlName]="fieldNameKeys.intervalName"
                [innerMsg]="messageErrors"
                TextInputValidate
                customPattern="[@#$%^&*{}[\]\\|+<>~`=_]"
                [isCustomPattern]="true"
              />
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">
            Operation<span class="p-error"> *</span>
          </div>
          <div class="w-100 add-input p-inputgroup p-input-icon-right non-icon">
            <p-dropdown
              [options]="operations"
              optionLabel="name"
              [showClear]="true"
              placeholder="Select operation"
              class="custom-p-drop"
              scrollHeight="160px"
              [formControlName]="fieldNameKeys.operation"
              [innerMsg]="messageErrors"
              [resetFilterOnHide]="true"
            >
            </p-dropdown>
            <i class="icon pi pi-ellipsis-v cursor-pointer"></i>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="row">
            <div class="col-5" style="padding-right: 12px">
              <div class="add-input-title">
                Cased Hole ID<span class="p-error"> *</span>
              </div>
              <div>
                <div
                  class="add-input p-inputgroup p-input-icon-right"
                  [ngClass]="formUnit.Diameter + '-unit'"
                >
                  <input
                    pInputText
                    class="custom-p-input"
                    type="text"
                    [formControlName]="fieldNameKeys.casedHoleId"
                    placeholder="Enter Cased Hole ID"
                    formControlName
                    NumbersOnly
                    [maxLength]="10"
                    [maxDecimalDigit]="
                      AppHelper.MathFunctions.getDigitUnitSystems(
                        formUnit.Diameter
                      )
                    "
                    appUnitInput
                    [unitSystem]="UNIT_SYSTEM"
                    [referenceUnit]="projectUnit"
                    [currentUnit]="interval ? interval.unit : ''"
                    unitType="diameter"
                    [factorDiameterNumber]="0"
                    [digitDiameterNumber]="0"
                    [innerMsg]="messageErrors"
                  />
                  <i class="icon pi pi-ellipsis-v cursor-pointer"></i>
                </div>
              </div>
            </div>
            <div class="col-5" style="padding-left: 12px">
              <div class="add-input-title">
                Last Shoe Measured Depth<span class="p-error"> *</span>
              </div>
              <div>
                <div
                  class="add-input p-inputgroup p-input-icon-right"
                  [ngClass]="formUnit.Depth + '-unit'"
                >
                  <input
                    pInputText
                    class="custom-p-input"
                    type="text"
                    [formControlName]="fieldNameKeys.lastShoeMeasuredDepth"
                    placeholder="Enter Last Shoe Measured Depth"
                    formControlName
                    NumbersOnly
                    [maxLength]="10"
                    [maxDecimalDigit]="
                      AppHelper.MathFunctions.getDigitUnitSystems(
                        formUnit.Depth
                      )
                    "
                    appUnitInput
                    [unitSystem]="UNIT_SYSTEM"
                    [referenceUnit]="projectUnit"
                    [currentUnit]="interval ? interval.unit : ''"
                    unitType="depth"
                    [factorDepthNumber]="0"
                    [digitDepthNumber]="0"
                    [innerMsg]="messageErrors"
                  />
                  <i class="icon pi pi-ellipsis-v cursor-pointer"></i>
                </div>
              </div>
            </div>

            <div class="col-2">
              <div class="add-input-title opacity-0">_____</div>
              <div class="d-flex justify-content-end">
                <p-checkbox
                  name="check_casedHoleId_lastShoeMeasuredDepth"
                  [formControlName]="
                    fieldNameKeys.check_casedHoleId_lastShoeMeasuredDepth
                  "
                  [binary]="true"
                  inputId="override1"
                  class="custom-p-checkbox"
                ></p-checkbox>
                <label
                  class="form-label override ms-2 add-input-title"
                  for="override1"
                >
                  N/A
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="row">
            <div class="col-10">
              <div class="add-input-title">
                Open Hole Diameter<span class="p-error"> *</span>
              </div>
              <div>
                <div
                  class="add-input p-inputgroup p-input-icon-right"
                  [ngClass]="formUnit.Diameter + '-unit'"
                >
                  <input
                    pInputText
                    class="custom-p-input"
                    type="text"
                    formControlName
                    NumbersOnly
                    [maxLength]="10"
                    [maxDecimalDigit]="
                      AppHelper.MathFunctions.getDigitUnitSystems(
                        formUnit.Diameter
                      )
                    "
                    placeholder="Enter Open Hole Diameter"
                    [formControlName]="fieldNameKeys.openHoleDiameter"
                    appUnitInput
                    [unitSystem]="UNIT_SYSTEM"
                    [referenceUnit]="projectUnit"
                    [currentUnit]="interval ? interval.unit : ''"
                    unitType="diameter"
                    [factorDiameterNumber]="0"
                    [digitDiameterNumber]="0"
                    [innerMsg]="messageErrors"
                  />
                  <i class="icon pi pi-ellipsis-v cursor-pointer"></i>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="add-input-title opacity-0">_____</div>
              <div class="d-flex justify-content-end">
                <p-checkbox
                  name="check_openHoleDiameter"
                  [formControlName]="fieldNameKeys.check_openHoleDiameter"
                  [binary]="true"
                  inputId="override2"
                  class="custom-p-checkbox"
                ></p-checkbox>
                <label
                  class="form-label override ms-2 add-input-title"
                  for="override2"
                >
                  N/A
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-2">
          <div class="add-input-title">Design of Service (Optional)</div>
          <div>
            <div class="custom-single-upload-btn">
              <div class="col-4 d-flex align-items-center">
                <div class="add-input-des">*.pdf</div>
              </div>
              <div class="col-3 me-1 d-flex align-items-center">
                <div #progressUploadDesign style="display: none; width: 100%">
                  <div style="width: 100%">
                    <p-progressBar
                      [value]="value"
                      [showValue]="false"
                      styleClass="progressUpload"
                    ></p-progressBar>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                [pTooltip]="fileLabel"
                tooltipPosition="bottom"
              >
                <p-fileUpload
                  #uploadDesign
                  name="designFile[]"
                  url="./upload.php"
                  [multiple]="false"
                  accept=".pdf"
                  (onSelect)="onSelect_design($event)"
                  (onClear)="onClear_design($event)"
                  [chooseLabel]="
                    fileLabel === 'Browse file'
                      ? fileLabel
                      : (fileLabel | shortFileName : 6)
                  "
                  chooseIcon="pi pi-upload"
                  cancelLabel=" "
                  cancelStyleClass="cancelStyle"
                  chooseStyleClass="chooseStyle"
                  styleClass="hidePreview"
                  [showUploadButton]="false"
                  [showCancelButton]="showCancelButtonUpLoad"
                ></p-fileUpload>
              </div>
            </div>
          </div>
          <div class="col-12">
            <input
              type="hidden"
              pInputText
              class="custom-p-input"
              name="hiddenTextDesign"
              [formControlName]="fieldNameKeys.hiddenTextDesign"
              [innerMsg]="messageErrors"
            />
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="closeIntervalPopup()"
        class="custom-p-button custom-p-button--primary"
      ></p-button>
      <p-button
        [attr.data-cy]="'save-button'"
        label="Save"
        class="custom-p-button custom-p-button--secondary"
        (onClick)="saveInterval($event)"
        [ngClass]="
          formIntervalGroup.status === 'INVALID' || isViewer
            ? 'custom-p-button custom-p-button--disable'
            : ''
        "
        [disabled]="formIntervalGroup.status === 'INVALID' || isViewer"
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>
