import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format24h',
})
export class DateFormat24hPipe implements PipeTransform {
  /**
   * Transforms the input value into a formatted DD-MMM-YYYY, HH:mm time string.
   * If the value is falsy (null, undefined, empty), it returns 'N/A'.
   * Otherwise, it formats the value as a date and time using AppHelper.DateFunctions.formatDateTime.
   *
   * @param value - The value to be transformed.
   * @returns The formatted DD-MMM-YYYY, HH:mm string or 'N/A'.
   */

  transform(value: string | number | null, hasTime: boolean = false): string {
    if (!value) {
      return 'N/A';
    } else {
      return AppHelper.DateFunctions.formatDateTime(new Date(value), hasTime);
    }
  }
}
