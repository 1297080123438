import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showRangeSlider',
})
export class StringShowRangeSliderPipe implements PipeTransform {
  transform(value: any): any {
    if (!value || !value.length) {
      return 'All';
    } else {
      return `${value[0]} - ${value[1]}`;
    }
  }
}
