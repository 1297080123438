import { UserFormUserComponent } from './user-form-user/user-form-user.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { UserRoleComponent } from './user-role/user-role.component';
import { UserTeamManagementComponent } from './user-team-management/user-team-management.component';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { UserAdvancedFilterComponent } from './user-advanced-filter/user-advanced-filter.component';

const declarations = [
  UserTeamManagementComponent,
  UserRoleComponent,
  UserFormUserComponent,
  UserAdvancedFilterComponent
];

const imports: any = [
  ShareModule,
  FormsModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [
    UserTeamManagementComponent,
    UserRoleComponent,
    UserFormUserComponent,
  ],
  providers: [UserInfoService],
})
export class UserModule {}
