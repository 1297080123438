<div class="login-wrapper">
  <div class="login" *ngIf="!!loginForm" [formGroup]="loginForm">
    <div class="d-flex justify-content-center">
      <svg-icon key="wellCareLogo" size="xxl"></svg-icon>
    </div>
    <div class="login-title">{{ AppConstant.APPLICATION_NAME }}</div>
    <div class="login-form d-none">
      <div class="login-form login_username">
        <label for="login_username" class="form-label">Login</label>
        <div class="p-inputgroup">
          <input
            pInputText
            id="login_username"
            class=""
            placeholder="Enter your login"
            (focusout)="onBlurMethod('username', loginForm)"
            [formControlName]="fieldNameKeys.username"
            [innerMsg]="messageErrors"
          />
        </div>
      </div>
    </div>
    <div class="login-content-button-login custom-p-button custom-p-button--primary">
      <p-button label="Login" (click)="onSubmit(loginForm)"></p-button>
    </div>
    <div class="login-contact">
      <div>For access requests, send an email to</div>
      <div class="primary-color cursor-pointer mail-link" (click)="makeMailMsOutlook('WellCare@halliburton.com', 'Feedback Issue', 'Dear VRO Suppoter')">WellCare@halliburton.com</div>
    </div>
    <pre class="d-none">{{ loginForm.value | json }}</pre>
  </div>
</div>
