<p-dialog
  [(visible)]="displayFormRun"
  [modal]="true"
  [style]="{ width: '32vw' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0"
  [formGroup]="formRunGroup"
>
  <!-- *ngIf="!!formRunGroup" -->
  <ng-template pTemplate="header">
    <div class="header-dialog">
      <span [pTooltip]="titlePopup || ''" tooltipPosition="bottom">
        {{ titlePopup }}
      </span>
    </div>
    <div
      class="custom-close-icon cursor-pointer"
      (click)="closeFormRunDialog()"
    >
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <!-- [ngClass]="{ 'form-disabled': isViewer }" -->
    <div class="container editForm">
      <div class="row">
        <fieldset formGroupName="inform">
          <div class="col-12 mb-2">
            <div class="add-input-title label-disabled">Wellbore</div>
            <div>
              <div class="add-input p-inputgroup non-icon">
                <input
                  type="text"
                  pInputText
                  class="custom-p-input"
                  [formControlName]="fieldNameKeys.wellboreName"
                />
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="add-input-title label-disabled">Interval</div>
            <div>
              <div class="add-input p-inputgroup non-icon">
                <input
                  type="text"
                  pInputText
                  class="custom-p-input"
                  [formControlName]="fieldNameKeys.intervalName"
                />
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="row d-flex justify-content-between">
              <div class="col-5">
                <div class="add-input-title">
                  Description<span class="p-error"> *</span>
                </div>
                <div>
                  <div class="add-input p-inputgroup non-icon">
                    <input
                      type="text"
                      placeholder="Enter a Description for the run"
                      pInputText
                      class="custom-p-input"
                      [formControlName]="fieldNameKeys.runDescription"
                      [innerMsg]="messageErrors"
                      TextInputValidate
                      customPattern="[@#$%^&*{}[\]\\|+<>~`=_]"
                      [isCustomPattern]="true"
                    />
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="add-input-title">
                  Run No.<span class="p-error"> *</span>
                </div>
                <div>
                  <div class="add-input p-inputgroup non-icon">
                    <input
                      pInputText
                      class="custom-p-input"
                      type="text"
                      [formControlName]="fieldNameKeys.runNumber"
                      placeholder="Enter Run Number"
                      formControlName
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="0"
                      [innerMsg]="messageErrors"
                    />
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="add-input-title">Rig BHA No.</div>
                <div>
                  <div class="add-input p-inputgroup non-icon">
                    <input
                      pInputText
                      class="custom-p-input"
                      type="text"
                      [formControlName]="fieldNameKeys.rigBHANumber"
                      placeholder="Enter Rig BHA Number"
                      formControlName
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="0"
                      [innerMsg]="messageErrors"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div
              class="add-input-title"
              [pTooltip]="typeDescription"
              tooltipPosition="right"
              style="cursor: pointer; display: inline"
            >
              Type
              <svg-icon
                key="contact"
                size="sm"
                style="margin-left: 2px"
              ></svg-icon>
              <span class="p-error"> *</span>
            </div>
            <div
              class="w-100 add-input p-inputgroup p-input-icon-right non-icon"
            >
              <p-dropdown
                [options]="runTypeList"
                optionLabel="data"
                [showClear]="true"
                placeholder="Select type"
                class="custom-p-drop"
                scrollHeight="160px"
                [formControlName]="fieldNameKeys.type"
                [innerMsg]="messageErrors"
                [resetFilterOnHide]="true"
              >
              </p-dropdown>
              <i class="icon pi pi-ellipsis-v cursor-pointer"></i>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="row d-flex justify-content-between">
              <div class="col-4" style="padding-right: 12px">
                <div
                  class="add-input-title"
                  [ngClass]="
                    informG.get('bitSize')?.disabled ? 'label-disabled' : ''
                  "
                >
                  Bit Size
                  <span
                    [ngClass]="
                      informG.get('bitSize')!.disabled
                        ? 'label-disabled'
                        : 'p-error'
                    "
                  >
                    *</span
                  >
                </div>
                <div>
                  <div
                    class="add-input p-inputgroup p-input-icon-right non-icon"
                    [ngClass]="
                      informG.get('bitSize')?.disabled
                        ? 'unit-disabled'
                        : formUnit.Diameter + '-unit'
                    "
                  >
                    <input
                      pInputText
                      class="custom-p-input"
                      type="text"
                      placeholder="Enter Bit Size"
                      [formControlName]="fieldNameKeys.bitSize"
                      [innerMsg]="messageErrors"
                      formControlName
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="
                        AppHelper.MathFunctions.getDigitUnitSystems(
                          formUnit.Diameter
                        )
                      "
                      appUnitInput
                      [unitSystem]="UNIT_SYSTEM"
                      [referenceUnit]="projectUnit"
                      [currentUnit]="run ? run.unit : ''"
                      unitType="diameter"
                      [factorDiameterNumber]="0"
                      [digitDiameterNumber]="0"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4" style="padding-left: 6px; padding-right: 6px">
                <div
                  class="add-input-title"
                  [ngClass]="
                    informG.get('reamerSize')?.disabled ? 'label-disabled' : ''
                  "
                >
                  Reamer Size
                </div>
                <div>
                  <div
                    class="add-input p-inputgroup p-input-icon-right non-icon"
                    [ngClass]="
                      informG.get('reamerSize')?.disabled
                        ? 'unit-disabled'
                        : formUnit.Diameter + '-unit'
                    "
                  >
                    <input
                      pInputText
                      class="custom-p-input"
                      type="text"
                      placeholder="Enter Reamer Size"
                      [formControlName]="fieldNameKeys.reamerSize"
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="
                        AppHelper.MathFunctions.getDigitUnitSystems(
                          formUnit.Diameter
                        )
                      "
                      appUnitInput
                      [unitSystem]="UNIT_SYSTEM"
                      [referenceUnit]="projectUnit"
                      [currentUnit]="run ? run.unit : ''"
                      unitType="diameter"
                      [factorDiameterNumber]="0"
                      [digitDiameterNumber]="0"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4" style="padding-left: 12px">
                <div
                  class="add-input-title"
                  [ngClass]="
                    informG.get('casingStringSize')?.disabled
                      ? 'label-disabled'
                      : ''
                  "
                >
                  Casing / String Size
                  <span
                    class="p-error"
                    *ngIf="!informG.get('casingStringSize')!.disabled"
                  >
                    *</span
                  >
                </div>
                <div>
                  <div
                    class="add-input p-inputgroup p-input-icon-right non-icon"
                    [ngClass]="
                      informG.get('casingStringSize')?.disabled
                        ? 'unit-disabled'
                        : formUnit.Diameter + '-unit'
                    "
                  >
                    <input
                      type="text"
                      placeholder="Enter Casing"
                      pInputText
                      class="custom-p-input"
                      [formControlName]="fieldNameKeys.casingStringSize"
                      [innerMsg]="messageErrors"
                      formControlName
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="
                        AppHelper.MathFunctions.getDigitUnitSystems(
                          formUnit.Diameter
                        )
                      "
                      appUnitInput
                      [unitSystem]="UNIT_SYSTEM"
                      [referenceUnit]="projectUnit"
                      [currentUnit]="run ? run.unit : ''"
                      unitType="diameter"
                      [factorDiameterNumber]="0"
                      [digitDiameterNumber]="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset formGroupName="timestamp">
          <div class="col-12 mb-2">
            <div class="row">
              <div class="col-6" style="padding-right: 12px">
                <div class="add-input-title">Below Rotary Timestamp</div>
                <div>
                  <div class="add-input p-inputgroup non-icon">
                    <p-calendar
                      styleClass="custom-p-calendar"
                      (onSelect)="
                        onSelectDate(
                          $event,
                          formRunGroup,
                          [
                            fieldNameKeys.belowRotaryTimestamp,
                            fieldNameKeys.runTDTimestamp,
                            fieldNameKeys.runTDAboveRotaryTimestamp
                          ],
                          fieldNameKeys
                        )
                      "
                      [showTime]="true"
                      [readonlyInput]="true"
                      dateFormat="dd-M-yy,"
                      placeholder="Select Timestamp"
                      icon="pi pi-calendar"
                      [showIcon]="true"
                      [showClear]="true"
                      [formControlName]="fieldNameKeys.belowRotaryTimestamp"
                      [innerMsg]="messageErrors"
                      (onClear)="onClearErrorTimestamp()"
                      [selectOtherMonths]="true"
                    ></p-calendar>
                  </div>
                </div>
              </div>

              <div class="col-6" style="padding-left: 12px">
                <div class="add-input-title">Start Measured Depth</div>
                <div>
                  <div
                    class="add-input p-inputgroup p-input-icon-right non-icon"
                    [ngClass]="formUnit.Depth + '-unit'"
                  >
                    <input
                      pInputText
                      class="custom-p-input"
                      type="text"
                      placeholder="Enter Start Measured Depth"
                      [formControlName]="fieldNameKeys.startMeasuredDepth"
                      formControlName
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="
                        AppHelper.MathFunctions.getDigitUnitSystems(
                          formUnit.Depth
                        )
                      "
                      appUnitInput
                      [unitSystem]="UNIT_SYSTEM"
                      [referenceUnit]="projectUnit"
                      [currentUnit]="run ? run.unit : ''"
                      unitType="depth"
                      [factorDepthNumber]="0"
                      [digitDepthNumber]="0"
                    />
                  </div>
                  <div class="">
                    <div
                      *ngIf="isInvalidControl(fieldNameKeys.startMeasuredDepth)"
                    >
                      <ng-container
                        *ngFor="
                          let msg of getErrorByField(
                            fieldNameKeys.startMeasuredDepth
                          )
                        "
                      >
                        <div class="inner-msg-error">
                          <span>{{ msg }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="row">
              <div class="col-6" style="padding-right: 12px">
                <div class="add-input-title">Run TD Timestamp</div>
                <div>
                  <div class="add-input p-inputgroup non-icon">
                    <p-calendar
                      styleClass="custom-p-calendar"
                      (onSelect)="
                        onSelectDate(
                          $event,
                          formRunGroup,
                          [
                            fieldNameKeys.belowRotaryTimestamp,
                            fieldNameKeys.runTDTimestamp,
                            fieldNameKeys.runTDAboveRotaryTimestamp
                          ],
                          fieldNameKeys
                        )
                      "
                      [showTime]="true"
                      [readonlyInput]="true"
                      dateFormat="dd-M-yy,"
                      placeholder="Select Timestamp"
                      icon="pi pi-calendar"
                      [showIcon]="true"
                      [showClear]="true"
                      [formControlName]="fieldNameKeys.runTDTimestamp"
                      [innerMsg]="messageErrors"
                      (onClear)="onClearErrorTimestamp()"
                      [selectOtherMonths]="true"
                    ></p-calendar>
                  </div>
                </div>
              </div>

              <div class="col-6" style="padding-left: 12px">
                <div class="add-input-title">TD Measured Depth</div>
                <div>
                  <div
                    class="add-input p-inputgroup p-input-icon-right non-icon"
                    [ngClass]="formUnit.Depth + '-unit'"
                  >
                    <input
                      pInputText
                      class="custom-p-input"
                      type="text"
                      placeholder="Enter TD Measured Depth"
                      [formControlName]="fieldNameKeys.TDMeasuredDepth"
                      formControlName
                      NumbersOnly
                      [maxLength]="10"
                      [maxDecimalDigit]="
                        AppHelper.MathFunctions.getDigitUnitSystems(
                          formUnit.Depth
                        )
                      "
                      appUnitInput
                      [unitSystem]="UNIT_SYSTEM"
                      [referenceUnit]="projectUnit"
                      [currentUnit]="run ? run.unit : ''"
                      unitType="depth"
                      [factorDepthNumber]="0"
                      [digitDepthNumber]="0"
                    />
                  </div>
                  <div class="">
                    <div
                      *ngIf="isInvalidControl(fieldNameKeys.TDMeasuredDepth)"
                    >
                      <ng-container
                        *ngFor="
                          let msg of getErrorByField(
                            fieldNameKeys.TDMeasuredDepth
                          )
                        "
                      >
                        <div class="inner-msg-error">
                          <span>{{ msg }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2">
            <div class="add-input-title">Run Above Rotary Timestamp</div>
            <div>
              <div class="add-input p-inputgroup non-icon">
                <p-calendar
                  styleClass="custom-p-calendar"
                  (onSelect)="
                    onSelectDate(
                      $event,
                      formRunGroup,
                      [
                        fieldNameKeys.belowRotaryTimestamp,
                        fieldNameKeys.runTDTimestamp,
                        fieldNameKeys.runTDAboveRotaryTimestamp
                      ],
                      fieldNameKeys
                    )
                  "
                  [showTime]="true"
                  [readonlyInput]="true"
                  dateFormat="dd-M-yy,"
                  placeholder="Select Timestamp"
                  icon="pi pi-calendar"
                  [showIcon]="true"
                  [showClear]="true"
                  [formControlName]="fieldNameKeys.runTDAboveRotaryTimestamp"
                  [innerMsg]="messageErrors"
                  (onClear)="onClearErrorTimestamp()"
                  [selectOtherMonths]="true"
                ></p-calendar>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset formGroupName="inform">
          <div class="col-12 mb-2">
            <div class="add-input-title">Tally (Optional)</div>
            <div>
              <div class="custom-single-upload-btn">
                <div class="col-4 d-flex align-items-center">
                  <div class="add-input-des">*.pdf</div>
                </div>
                <div class="col-3 me-1 d-flex align-items-center">
                  <div #progressUploadTally style="display: none; width: 100%">
                    <div style="width: 100%">
                      <p-progressBar
                        [value]="value"
                        [showValue]="false"
                        styleClass="progressUpload"
                      ></p-progressBar>
                    </div>
                  </div>
                </div>
                <div
                  class="col-5"
                  [pTooltip]="fileLabel"
                  tooltipPosition="bottom"
                >
                  <p-fileUpload
                    #uploadTally
                    name="designFile[]"
                    url="./upload.php"
                    [multiple]="false"
                    accept=".pdf"
                    (onSelect)="onSelect_tally($event)"
                    (onClear)="onClear_tally($event)"
                    [chooseLabel]="
                      fileLabel === 'Browse file'
                        ? fileLabel
                        : (fileLabel | shortFileName : 6)
                    "
                    chooseIcon="pi pi-upload"
                    cancelLabel=" "
                    cancelStyleClass="cancelStyle"
                    chooseStyleClass="chooseStyle"
                    styleClass="hidePreview"
                    [showUploadButton]="false"
                    [showCancelButton]="showCancelButtonUpLoad"
                  ></p-fileUpload>
                  <!-- [disabled]="
                      informG.get('hiddenTextTally')?.disabled || false
                    " -->
                </div>
              </div>
            </div>
            <div class="col-12">
              <input
                type="hidden"
                pInputText
                class="custom-p-input"
                name="hiddenTextDesign"
                [formControlName]="fieldNameKeys.hiddenTextTally"
                [innerMsg]="messageErrors"
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="closeFormRunDialog()"
        class="custom-p-button custom-p-button--primary"
      ></p-button>
      <p-button
        [attr.data-cy]="'save-button'"
        label="Save"
        (onClick)="submitRun($event)"
        class="custom-p-button custom-p-button--secondary"
        [disabled]="
          (informG && informG.status === 'INVALID') ||
          (timestampG && timestampG.status === 'INVALID') ||
          isViewer
        "
        [ngClass]="
          (informG && informG.status === 'INVALID') ||
          (timestampG && timestampG.status === 'INVALID') ||
          isViewer
            ? 'custom-p-button custom-p-button--disable'
            : ''
        "
      ></p-button>
    </div>
  </ng-template>

  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</p-dialog>
