import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertComponent } from './intervention.component';
import { AlertFormInitiateComponent } from './intervention-form-initiate/intervention-form-initiate.component';
import { AlertFormResolveComponent } from './intervention-form-resolve/intervention-form-resolve.component';
import { TeamChatComponent } from './team-chat/team-chat.component';
import { InterventionService } from './../../shared/services/intervention.service';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ShareModule } from 'src/app/shared/shared.module';
import { AlertPreviewPdfComponent } from './intervention-preview-pdf/intervention-preview-pdf.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';
import { UnitInputModule } from 'src/app/shared/directives/unit-input-directive/unit-input.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const declarations = [
  AlertComponent,
  AlertFormInitiateComponent,
  AlertFormResolveComponent,
  AlertPreviewPdfComponent,
  TeamChatComponent,
];

const imports: any = [
  ShareModule,
  FormsModule,
  CommonModule,
  PdfJsViewerModule,
  UnitInputModule,
  MatProgressSpinnerModule,
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  providers: [InterventionService, WebSocketService],
  exports: [AlertPreviewPdfComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class InterventionModule {}
