<div class="wrapper-team-chat" [ngClass]="{ 'back-portal': backPortal }">
  <div class="wrapper-content">
    <div
      #infiniteScroll
      class="d-flex flex-column-reverse container-comment"
      (scroll)="scrollHandler($event)"
    >
      <!-- Spinner show when call API -->
      <div class="content-progress-bar">
        <span class="loader" *ngIf="loadingCallApi | async"></span>
      </div>

      <ng-container *ngIf="listComment && listComment.length > 0">
        <!-- UI for a card comment -->
        <div class="wrapper flex-column" *ngFor="let data of listComment">
          <div class="d-flex header">
            <!-- Avatar and name User -->
            <div class="d-flex w-100 align-items-center">
              <!-- If user don't have avatar, the first letter of the name user is visibled -->
              <div
                *ngIf="!data?.user?.avatar"
                class="avatar-random"
                [attr.data-label]="getFirstLetter(data?.user?.displayName)"
                [appRandomAvatar]="getFirstLetter(data?.user?.displayName)"
                avatarSize="extra-small-secondary"
              ></div>

              <!-- If user have avatar, user's avatar is visibled -->
              <ng-container *ngIf="data?.user?.avatar">
                <ng-container *ngIf="!data?.user?.avatar">
                  <div class="avatar">
                    <div
                      class="content-progress-bar align-items-center"
                      style="height: 32px; width: 32px; display: flex"
                    >
                      <span class="loader scale-small-list"></span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="data?.user?.avatar">
                  <img
                    class="avatar"
                    [src]="getAvatarUrl(data) | safe : 'url'"
                    [alt]="'Avatar Image of' + data?.user?.displayName"
                  />
                </ng-container>
              </ng-container>

              <!-- Username -->
              <span
                class="ps-2 td-content w-100"
                [pTooltip]="data?.user?.displayName"
                tooltipPosition="bottom"
              >
                {{ data?.user?.displayName
                }}{{ data?.user?.role && " (" + data.user.role + ") "
                }}{{ data?.user?.hallId && " (" + data.user.hallId + ") " }}
              </span>
            </div>
            <div class="d-flex flex-rows align-items-center">
              <!-- User's nominate icon -->
              <ng-container *ngIf="data.nominateInformation?.length">
                <div [pTooltip]="'Lesson Learned'"
                      tooltipPosition="bottom"
                      class="d-flex px-2 text-warning cursor-pointer nominate-vote" 
                      *ngIf="data.nominateInformation?.length"
                      [id]="data.chatId" 
                      (click)="toggleDialog(data.nominateInformation, data.chatId)">
                  <div class="custom-svg-icon me-1">
                    <svg-icon key="nominate" size="md"></svg-icon>
                  </div>
                  <div>
                    {{data.nominateInformation.length}}
                  </div>
                </div>
              </ng-container>
              <!-- Datetime of the comment -->
              <span class="nowrap td-content">
                {{ data?.createdAt | date : "dd-MMM-yyyy, HH:mm" }}
              </span>

              <!-- 3dots icon of the comment -->
              <div
                class="more-option-table"
                style="max-width: 50px; padding-left: 10px"
                (click)="showContextMenu($event, data)"
                [ngStyle]="{
                  'pointer-events': getMenuTableItem(data) ? 'auto' : 'none'
                }"
              >
                <svg-icon
                  class="header-content__title--feature"
                  key="dot3hoz"
                  size="md"
                >
                </svg-icon>
              </div>
              <app-menu
                #menu
                [optionList]="getMenuItemsForItem$ | async"
                [styleClass]="'menu-dropdown auto-width-menu'"
              ></app-menu>
            </div>
          </div>

          <!-- Content: text and attachment -->
          <div class="d-flex flex-column">
            <!-- Text -->
            <div
              class="message"
              *ngIf="data.content"
              appExpandableContent
              [userId]="currentUser?.id"
              [content]="data.content"
              [sizeContent]="150"
            ></div>
            <!-- Attachments -->
            <div class="d-flex flex-row flex-wrap">
              <ng-container *ngIf="data.attachments">
                <div *ngFor="let attachment of data.attachments" class="w-50">
                  <a
                    class="d-flex flex-row wrapper-file mr-4"
                    href="javascript:void(0);"
                    (click)="onDownloanAttachment(attachment.attachmentUrl)"
                    target="_blank"
                  >
                    <div class="file">
                      <svg-icon key="file" size="md"></svg-icon>
                    </div>
                    <div class="overflow-hidden text-nowrap text-truncate">
                      {{ decodeFileName(attachment.name) }}
                    </div>
                  </a>
                </div>
              </ng-container>
            </div>
          </div>

          <div
            class="d-flex flex-row flex-wrap justify-content-xxl-start"
            style="margin-top: 5px"
          >
            <div
              class="interact-action mr-4"
              [ngClass]="{
                'disable-interact':
                  (preventInteract | async) || isViewer || readOnly
              }"
            >
              <i
                class="pi cursor-pointer"
                [ngClass]="
                  data?.interactionSummary?.interactOfUser?.interactionType ===
                  'upvote'
                    ? 'pi-thumbs-up-fill'
                    : 'pi-thumbs-up'
                "
                (click)="interactComment(data, 'upvote')"
              ></i>
              <span class="ml-2">{{
                data?.interactionSummary?.upvote || 0
              }}</span>
              <i
                class="pi cursor-pointer ml-4"
                [ngClass]="
                  data?.interactionSummary?.interactOfUser?.interactionType ===
                  'downvote'
                    ? 'pi-thumbs-down-fill'
                    : 'pi-thumbs-down'
                "
                (click)="interactComment(data, 'downvote')"
              ></i>
              <span class="ml-2">{{
                data?.interactionSummary?.downvote || 0
              }}</span>
              <!-- <i class="pi pi-share-alt cursor-pointer"></i> -->
            </div>
          </div>
        </div>

        <!-- Spinner -->
        <div class="content-progress-bar">
          <span class="loader" *ngIf="loadingData | async"></span>
        </div>
      </ng-container>
      <!-- UI for No data -->
      <ng-container *ngIf="listComment && listComment.length <= 0">
        <div class="nodata">
          <svg-icon class="icon-nodata" key="noData" size="lg"></svg-icon>
          <span class="td-content"> No comments yet </span>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Footer -->
  <div
    class="flex-column"
    style="margin-right: 5px"
    [formGroup]="formGroupTeamChat"
  >
    <!-- TextBox comment -->
    <div class="wrapper-textarea">
      <div *ngIf="isOpenMentionList" class="listSuggestion">
        <p-listbox 
          optionLabel="displayName" 
          [options]="userRelatedShow" 
          [style]="{'width':'11rem'}" 
          [listStyle]="{'height': '120px', 'max-height': '120px', 'margin-top': '-121px'}"
          (onClick)="addTag($event)"
        >
          <ng-template let-user pTemplate="item">
            <span>{{ user.displayName }} ({{ user.role}})</span>
          </ng-template>
        </p-listbox>
      </div>

      <div
        pInputTextarea
        #content
        id="content-message"
        class="textArea"
        placeholder="Add a comment..."
        spellcheck="true"
        tabindex="0"
        (keypress)="keyPress($event)"
        (keyup)="onKeyUp($event)"
        contenteditable="true"
      ></div>
      </div>
      <div
        *ngIf="getErrorContent()"
        class="inner-msg-error"
      >
        <span>{{ messageErrors.maxlength }}</span>
      </div>

    <!-- List attachment area -->
    <ng-container *ngIf="isUpdating && editAttachments.length > 0">
      <div
        class="flex-column"
        *ngFor="let file of editAttachments; let idx = index"
      >
        <div class="d-flex flex-row wrapper-file">
          <div class="file">
            <svg-icon key="file" size="md"></svg-icon>
          </div>
          <div class="flex-grow-1 overflow-hidden text-nowrap text-truncate">
            {{ file.name }}
            <span> ({{ file.size / 1024 | number : "1.2-2" }}KB)</span>
          </div>
          <div class="delete">
            <svg-icon
              (click)="clearEditAttachment(idx)"
              key="close"
              size="sm"
              style="cursor: pointer"
            ></svg-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="files.length > 0">
      <div class="flex-column" *ngFor="let file of files; let idx = index">
        <div class="d-flex flex-row wrapper-file">
          <div class="file">
            <mat-spinner *ngIf="isUploadings[idx]" diameter="16"></mat-spinner>
            <svg-icon
              *ngIf="!isUploadings[idx] && !isUploadErrors[idx]"
              key="file"
              size="md"
            ></svg-icon>
            <svg-icon
              class="warning"
              *ngIf="!isUploadings[idx] && isUploadErrors[idx]"
              key="warning"
              size="md"
            ></svg-icon>
          </div>
          <div class="flex-grow-1 overflow-hidden text-nowrap text-truncate">
            {{ file.name }}
            <span> ({{ file.size / 1024 | number : "1.2-2" }}KB)</span>
          </div>
          <div class="delete">
            <svg-icon
              (click)="clearAttachment(idx)"
              key="close"
              size="sm"
              style="cursor: pointer"
            ></svg-icon>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Button Upload and Comment -->
    <div class="d-flex align-items-center mb-3">
      <span class="ps-2 td-content nowrap" style="margin-right: 10px"
        >(max 10MB)</span
      >
      <!-- Btn Upload file -->
      <div class="custom-single-upload-btn">
        <div class="d-flex align-items-center">
          <p-fileUpload
            #uploadAttachment
            name="interventionChatAttachment"
            [multiple]="true"
            chooseIcon="pi pi-upload"
            chooseLabel="Attach"
            cancelLabel=""
            cancelStyleClass="cancelStyle"
            chooseStyleClass="chooseStyle"
            styleClass="hidePreview"
            method="put"
            [auto]="false"
            [showUploadButton]="false"
            [showCancelButton]="false"
            (onClear)="onUploaderClear('interventionChatAttachment')"
            (onSelect)="onUploaderSelect('interventionChatAttachment', $event)"
            [disabled]="
              files.length + editAttachments.length ==
                MAXIMUN_NUMBER_FILE_UPLOAD ||
              !alertId ||
              readOnly
            "
          >
          </p-fileUpload>
        </div>
      </div>

      <!-- Btn Comment -->
      <div class="d-flex w-100 justify-content-xxl-end">
        <button
          pButton
          (click)="onComment()"
          class="p-button-secondary btn-comment"
          [ngClass]="{ 'btn-disable': isDisabledComment() }"
          [label]="isUpdating ? 'Edit' : 'Comment'"
          [disabled]="isDisabledComment() ? true : false"
        ></button>
      </div>
    </div>
  </div>
</div>

<app-custom-expand-infor></app-custom-expand-infor>
