import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  pluck,
  Subject,
  tap,
} from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { WebSocketService } from './web-socket.service';
import { ALERT_TYPE } from '../enum';
import { AlertService } from './alert.service';
import { IHomeFilterPayLoad } from '../interface/common';

const ACTIVE_LOG = 'active';

@Injectable()
export class InterventionService extends BaseService {
  constructor(
    httpClient: HttpClient,
    private _io: WebSocketService,
    private _alertService: AlertService
  ) {
    super(httpClient);

    // Obsser Socket Service
    this.socketCreateLog();
    this.socketUpdateLog();
    this.socketDeleteLog();
    this.socketDeleteWellbore();
  }

  filterInterventionByStatus = new BehaviorSubject<any[]>([]);

  private _refreshRequired = new Subject<void>();

  get RefreshRequired() {
    return this._refreshRequired;
  }

  /* New log for Intervention Log Management */
  private interventionLogs = new BehaviorSubject<any[]>([]);
  public interventionLogs$ = this.interventionLogs.asObservable();

  private interventionLogsTotal = new BehaviorSubject<number>(0);
  public interventionLogsTotal$ = this.interventionLogsTotal.asObservable();

  private interventionLogsFilter = new BehaviorSubject<{ rigJournalId: string, rigName: string }>({
    rigJournalId: '',
    rigName: '',
  });

  public interventionLogsFilter$ = this.interventionLogsFilter.asObservable();

  private interventionLogsLoading = new BehaviorSubject<boolean>(false);
  public interventionLogsLoading$ = this.interventionLogsLoading.asObservable();

  private interventionLogsActive = new BehaviorSubject<string>(''); // Hold InterventionId of Log Actived
  public interventionLogsActive$ = this.interventionLogsActive.asObservable();

  private triggerRigCard = new BehaviorSubject<boolean>(false);

  // payload = {
  //   "region": [ "LA" ],
  //   "country": [ "AW" ],
  //   "customer": [ "8Clli5iv8v" ],
  //   "project": [ "ovpOcaEfE3" ],
  //   "contractor": [ "98a1a70f-dcaa-4a3f-b75c-da794dd617ad" ],
  //   "rig": [ "a5992316-83fc-44aa-b133-bf9343d2f284" ],
  //   "code": "active", <-- control how ti this is active or all or (inactive - derepeated)
  //   "status": [ "(i.status = 'INITIATE' AND i.has_sent = 0)OR(i.status = 'INITIATE' AND i.has_sent = 1 AND i.is_required_futher_action = 0)" ]
  //   "searchString": "hello world"
  // }

  setLoading(nextStatus: boolean) {
    this.interventionLogsLoading.next(nextStatus);
  }
  
  setTriggerRigCard(nextStatus: boolean) {
    this.triggerRigCard.next(nextStatus);
  }
  
  getTriggerRigCard(): boolean {
    return this.triggerRigCard.getValue();
  }

  setLogFilterDefault() {
    this.interventionLogsFilter.next({ rigJournalId: '', rigName: '' });
  }

  setLogFilter(filterObject: { rigJournalId: string, rigName: string }) {
    this.interventionLogsFilter.next(filterObject);
  }

  getLogFilter(): { rigJournalId: string, rigName: string } {
    return this.interventionLogsFilter.getValue();
  }

  setInterventionLogsTotal(total: number) {
    this.interventionLogsTotal.next(total);
  }

  getInterventionLogsTotal() {
    return this.interventionLogsTotal.getValue();
  }

  // ====  //
  loadMoreAlertLog(alertResponse: { items: any, total: number  }) {
    const { items, total } = alertResponse;
    const nextValue: any[] = this.interventionLogs.getValue().concat(items);
    this.setInterventionLogsTotal(total);
    return this.interventionLogs.next(nextValue);
  }

  // ==== //
  public sendIntervention(
    interventionId: string,
    timezone: number,
    isInitiate: boolean,
    isResolvedOnTime: boolean,
    mailboxId: string,
    emailTemplateData: any,
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/send-intervention`;
    return this.post<any, any>(uri, {
      timezone,
      isInitiate,
      isResolvedOnTime,
      mailboxId,
      emailTemplateData
    }).pipe(
      tap((res) => {
        this._io.emit('update-intervention', res);
        this.RefreshRequired.next();
      })
    );
  }

  public getInterventionById(interventionId: string): Observable<any[]> {
    const uri = `intervention/${interventionId}`;
    return this.get<any[]>(uri, {});
  }

  public saveIntervention(payload: any, rigId: string): Observable<any[]> {
    const uri = `intervention/${rigId}/create`;
    AppHelper.UtileFunctions.encode64Payload(payload, [
      'rigBasedValidationDetail',
      'recommendation',
      'eventDescription',
      'risk',
      !payload.isCustomOperation ? 'currentOperation' : '',
    ]);
    return this.post<any, any>(uri, payload).pipe(
      tap((res) => {
        this._io.emit('create-intervention', res);
        this.RefreshRequired.next();
      })
    );
  }

  public listChat(
    interventionId: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    const uri = `intervention/${interventionId}/chat`;
    return this.get<any>(uri, {
      params: {
        page,
        pageSize,
      },
    });
  }

  public addInteractChat(
    interventionId: string,
    chatId: string,
    idUserAction: string,
    payload: any
  ): Observable<any> {
    const uri = `intervention/${interventionId}/interact/${chatId}`;
    return this.post<any, any>(uri, {
      ...payload,
    });
  }

  public editInteractChat(
    interventionId: string,
    chatId: string,
    idUserAction: string,
    payload: any
  ): Observable<any> {
    const uri = `intervention/${interventionId}/interact/${chatId}`;
    return this.put<any, any>(uri, payload);
  }

  public deleteInteractChat(
    interventionId: string,
    chatId: string,
    idUserAction: string
  ): Observable<any> {
    const uri = `intervention/${interventionId}/interact/${chatId}`;
    return this.delete<any>(uri);
  }

  public addChat(interventionId: string, payload: any): Observable<any> {
    const uri = `intervention/${interventionId}/chat`;
    return this.post<any, any>(uri, payload);
  }

  public editChat(
    interventionId: string,
    chatId: string,
    payload: any
  ): Observable<any> {
    const uri = `intervention/${interventionId}/chat/${chatId}`;
    return this.put<any, any>(uri, payload);
  }

  public deleteChat(interventionId: string, chatId: string): Observable<any> {
    const uri = `intervention/${interventionId}/chat/${chatId}`;
    return this.delete<any>(uri);
  }

  public addChatAttachment(
    payloadFile: any,
    interventionId: string
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/chat/attachment`;
    const formData = new FormData();
    if (payloadFile && payloadFile.interventionChatAttachment) {
      for (let interventionChatAttachment of payloadFile.interventionChatAttachment) {
        const fileName = AppHelper.UtileFunctions.encodeFileName(interventionChatAttachment.name);
        formData.append(
          'interventionChatAttachment',
          interventionChatAttachment,
          fileName
        );
      }
    }
    return this.put<any, any>(uri, formData);
  }

  public deleteChatAttachment(
    interventionId: string,
    attachment: string
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/chat/attachment/delete`;
    return this.put<any, any>(uri, {
      attachment,
    });
  }

  public addFileEvidenceAlert(
    payloadFile: any,
    interventionId: string,
    isCreateResolve: boolean
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/add-file`;
    const formData = new FormData();

    // for initiate
    if (payloadFile && payloadFile.evidencesInitiate) {
      for (let evidence of payloadFile.evidencesInitiate) {
        const evidenceName = AppHelper.UtileFunctions.encodeFileName(evidence.name);
        formData.append('evidencesInitiate', evidence, evidenceName);
      }
    }
    // for resolve
    if (payloadFile && payloadFile.evidencesResolve) {
      for (let evidence of payloadFile.evidencesResolve) {
        const evidenceName = AppHelper.UtileFunctions.encodeFileName(evidence.name);
        formData.append('evidencesResolve', evidence, evidenceName);
      }
    }
    formData.append('isCreateResolve', new Boolean(isCreateResolve).toString());

    return this.put<any, any>(uri, formData);
  }

  
  public addFilePDFAlert(
    payloadFile: any,
    interventionId: string,
    
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/add-pdf`;
    const formData = new FormData();

    // for alertEditor
    if (payloadFile && payloadFile.alertEditor) {
      formData.append('alertEditor', payloadFile.alertEditor);
    }
    formData.append('intervention', payloadFile.intervention);
    formData.append('isUseEditor', new Boolean(payloadFile.isUseEditor).toString());

    return this.put<any, any>(uri, formData);
  }

  public deleteIntervention(interventionId: string): Observable<any[]> {
    const uri = `intervention/${interventionId}/delete`;
    return this.delete<any>(uri).pipe(
      tap((res) => {
        this._io.emit('delete-intervention', res);
        this.RefreshRequired.next();
      })
    );
  }

  // public mergeFilePDF(
  //   baseFile: File,
  //   appendFileArray: File[],
  //   isGenerateFromFirstPage: string
  // ): Observable<any[]> {
  //   const uri = `intervention/merge-file`;
  //   const formData = new FormData();

  //   formData.append('intervention', baseFile);
  //   formData.append('isGenerateFromFirstPage', isGenerateFromFirstPage);
  //   if (appendFileArray) {
  //     for (let pdfEvidence of appendFileArray) {
  //       formData.append('evidences', pdfEvidence);
  //     }
  //   }
  //   return this.put<any, any>(uri, formData).pipe();
  // }

  // public updateIntervention(
  //   payload: any,
  //   interventionId: string
  // ): Observable<any[]> {
  //   const uri = `intervention/${interventionId}/edit`;
  //   AppHelper.UtileFunctions.encode64Payload(payload, [
  //     'rigBasedValidationDetail',
  //     'recommendation',
  //     'eventDescription',
  //     !payload.isCustomRisk ? 'risk' : '',
  //     !payload.isCustomOperation ? 'currentOperation' : '',
  //   ]);

  //   return this.put<any, any>(uri, payload).pipe(
  //     tap((res) => {
  //       this._io.emit('update-intervention', res);
  //       this.RefreshRequired.next();
  //     })
  //   );
  // }
  
  public updateInterventionV2(
    payload: any,
    interventionId: string
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/edit/v2`;
    AppHelper.UtileFunctions.encode64Payload(payload, [
      'rigBasedValidationDetail',
      'recommendation',
      'eventDescription',
      'resolution',
      'risk',
      'currentOperation',
    ]);

    return this.put<any, any>(uri, payload).pipe(
      tap((res) => {
        this._io.emit('update-intervention', res);
        this.RefreshRequired.next();
      })
    );
  }

  public saveResolution(
    payload: any,
    interventionId: string
  ): Observable<any[]> {
    const uri = `intervention/${interventionId}/resolve`;
    AppHelper.UtileFunctions.encode64Payload(payload, ['resolution']);

    return this.put<any, any>(uri, payload).pipe(
      tap((res) => {
        this._io.emit('update-intervention', res);
        this.RefreshRequired.next();
      })
    );
  }

  // payload = {
  //   "region": [ "LA" ],
  //   "country": [ "AW" ],
  //   "customer": [ "8Clli5iv8v" ],
  //   "project": [ "ovpOcaEfE3" ],
  //   "contractor": [ "98a1a70f-dcaa-4a3f-b75c-da794dd617ad" ],
  //   "rig": [ "a5992316-83fc-44aa-b133-bf9343d2f284" ],
  //   "code": "active", <-- control how ti this is active or all or (inactive - derepeated)
  //   "status": [ "(i.status = 'INITIATE' AND i.has_sent = 0)OR(i.status = 'INITIATE' AND i.has_sent = 1 AND i.is_required_futher_action = 0)" ]
  //   "searchString": "hello world"
  // }

  public getInterventionLogsFilter(payload: IHomeFilterPayLoad, skipIndex: number, takeIndex: number): Observable<any[]> {
    let filterPayload: any = Object.assign({}, payload);
    if (
      (payload.code && filterPayload.code === 'active') ||
      (filterPayload.code &&
        filterPayload.code === 'All' &&
        filterPayload.status)
    )
      payload.isActive = true;

    const codeInterLog = filterPayload.codeInterLog;
    delete filterPayload.code;
    delete filterPayload.status;
    delete filterPayload.searchString;
    delete filterPayload.codeInterLog;

    let uri = `intervention/log/list/v2`;
    return this.get<any[]>(uri, {
      params: {
        ...(payload || {}),
        skip: skipIndex,
        take: takeIndex,
      },
    })
      .pipe(
        map((list: any) => {
          if (
            payload.hasOwnProperty('status') &&
            list.data &&
            (status || []).length
          ) {
            this.filterInterventionByStatus.next(list.data);
          } else {
            this.filterInterventionByStatus.next([]);
          }
          if (!(codeInterLog === ACTIVE_LOG)) {
            return list;
          } else {
            let exprireLogs: any = [];
            list.data.map((item: any) => {
              if (
                new Date(item.timeExpire).getTime() - new Date().getTime() <
                  0 &&
                item.timeExpire &&
                item.status !== 'RESOLUTION'
              ) {
                exprireLogs.push(item);
              }
              list.data = exprireLogs;
            });
            return list;
          }
        }),
        map((item: any) => {
          return item.data;
        })
      )
      .pipe(catchError(() => of([])));
  }

  resetLogFilter() {
    this.setLogFilterDefault();
    this.setInterventionLogsActive('');
  }

  mapSingleElement() {
    return map(({ items }) => {
      if (items && items.length === 1) {
        return items[0];
      } else {
        return [];
      }
    })
  }

  socketCreateLog(): void {
    this._io.listen('create-intervention')
      .pipe(this.mapSingleElement())
      .subscribe((newInterventionLog) => {
        if (newInterventionLog.eventType === ALERT_TYPE.REPORT) return;

        const currentLogs = this.interventionLogs.getValue();
        const currentFilter = this.getLogFilter();

        if (currentLogs.length === 0) {
          this.interventionLogs.next([newInterventionLog]);
          return;
        }

        if (currentFilter.rigJournalId === '') {
          // Without Conditional --> Adding Top
          let nextValue = [].concat(
            newInterventionLog,
            ...this.interventionLogs.getValue()
          );
          this.interventionLogs.next(nextValue);
          this.setInterventionLogsTotal(this.getInterventionLogsTotal() + 1);
        } else {
          // With Conditional --> Check should show or not
          if (newInterventionLog.rigJournalId === currentFilter.rigJournalId) {
            let nextValue = [].concat(
              newInterventionLog,
              ...this.interventionLogs.getValue()
            );
            this.interventionLogs.next(nextValue);
            this.setInterventionLogsTotal(this.getInterventionLogsTotal() + 1);
          }
        }
      });
  }

  socketUpdateLog() {
    this._io.listen('update-intervention')
      .pipe(this.mapSingleElement())
      .subscribe((alertUpdate) => {
        if (alertUpdate.eventType === ALERT_TYPE.REPORT) return;

        let currentLogs = this.interventionLogs.getValue();
        const currentFilter = this.getLogFilter();

        if (currentLogs.length) {
          const isExist = currentLogs.some((alert: any, index: number) => {
            if (alert.interventionId === alertUpdate.interventionId) {
              currentLogs.splice(index, 1);
              currentLogs.unshift(alertUpdate);
              return true;
            }
          });

          // Cover for case: alert have Report eventType is change Intervention eventType
          if (!isExist && ((alertUpdate.rigJournalId === currentFilter.rigJournalId && currentFilter.rigJournalId !== '') || currentFilter.rigJournalId === '')) {
            currentLogs.unshift(alertUpdate);
          }
          currentLogs = currentLogs.filter((alert: any) => alert.eventType !== ALERT_TYPE.REPORT);
          this.interventionLogs.next(currentLogs);
        } 
      });
  }

  socketDeleteLog() {
    this._io.listen('delete-intervention').subscribe((deletedIntervention) => {
      if (deletedIntervention && deletedIntervention.interventionId) {
        this._alertService.triggerItemDeleted(
          deletedIntervention.interventionId
        );

        const currentLogs = this.interventionLogs.getValue();

        if (currentLogs.length) {
          currentLogs.some((alert: any, index: number) => {
            if (alert.interventionId === deletedIntervention.interventionId) {
              currentLogs.splice(index, 1);
              this.interventionLogs.next(currentLogs);
              this.setInterventionLogsTotal(this.getInterventionLogsTotal() - 1);
              return true;
            }
          });
        }
      }
    });
  }

  socketDeleteWellbore() {
    this._io.listen('delete-wellbore').subscribe((rigJournalId) => {
      if (this.interventionLogs.getValue().length) {
        let cloneInterventionLog = [...this.interventionLogs.getValue()];

        cloneInterventionLog = cloneInterventionLog.filter((log) => {
          return log.rigJournalId !== rigJournalId;
        });

        this.interventionLogs.next(cloneInterventionLog);
        this.setInterventionLogsTotal(this.getInterventionLogsTotal() - cloneInterventionLog.length);
      }
    });
  }

  setInterventionLogsActive(id: string) {
    this.interventionLogsActive.next(id);
  }

  getInterventionLogsActive() {
    return this.interventionLogsActive.getValue();
  }

  setInterventionLogs(logList: any) {
    this.interventionLogs.next(logList);
  }

  getInterventionLogs() {
    return this.interventionLogs.getValue();
  }

  updateAlertComplete(alertId: string, data: object): Observable<string> {
    const uri = `intervention/${alertId}/complete`;
    return this.patch<any, any>(uri, data).pipe(
      tap((res) => {
        this._io.emit('update-intervention', res);
        this.RefreshRequired.next();
      })
    );
  }

  markAsCompleteAlert(alertId: string): Observable<string> {
    return this.updateAlertComplete(alertId, { isComplete: true });
  }

  resetAlert(alertId: string): Observable<any> {
    return this.updateAlertComplete(alertId, { isComplete: false });
  }

  nominateChat(
    chatId: string,
    payload: object
  ): Observable<any> {
    const uri = `intervention/nominate/${chatId}`;
    return this.put<any, any>(uri, payload);
  }

  markNominate(chatId: string): Observable<string> {
    return this.nominateChat(chatId, { isNominate: true });
  }

  cancelNominate(chatId: string): Observable<any> {
    return this.nominateChat(chatId, { isNominate: false });
  }
  
  public GetUserListByRigJounalId(id: string): Observable<any[]> {
    const uri = `user/rigjournal/${id}`;
    return this.get<any[]>(uri);
  }
}
