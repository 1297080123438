export const ALERT_ADVANCED_FILTER = {
  STATUS: {
    LABEL: 'Status',
    OPTION: {},
  },
  TIME_RANGE: {
    LABEL: 'Time Range',
    OPTION: {},
  },
  EVENT: {
    LABEL: 'Event',
    OPTION: {},
  },
  ACTION_TAKEN: {
    LABEL: 'Action Taken',
    OPTION: {},
  },
  TIME_SAVED: {
    LABEL: 'Time Saved',
    OPTION: {},
  },
  RISK: {
    LABEL: 'Risk',
    OPTION: {},
  },
  ENGINEER: {
    LABEL: 'Engineer',
    OPTION: {},
  },
  REMOTE_CENTER: {
    LABEL: 'Remote Center',
    OPTION: {},
  },
  RIG: {
    LABEL: 'Rig',
    OPTION: {},
  },
  PROJECT: {
    LABEL: 'Project',
    OPTION: {},
  },
  WELL: {
    LABEL: 'Well',
    OPTION: {},
  },
  OPTION: {
    PLACE_HOLDER: 'All',
  },
  ALERT_TYPE: {
    LABEL: 'Alert Type',
    OPTION: {},
  }
};

