import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { USER_ADVANCED_FILTER } from 'src/app/shared/metadata/user-advanced-filter.metadata';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Component({
  selector: 'app-user-advanced-filter',
  templateUrl: './user-advanced-filter.component.html',
  styleUrls: ['./user-advanced-filter.component.scss'],
})
export class UserAdvancedFilterComponent extends BaseComponent {
  METADATA: any = USER_ADVANCED_FILTER;
  isInit: boolean = false;

  remoteCentersOption: any = [];
  rolesOption: any = [];
  rolesOptionRoot: any = [];
  functionalMailsOption: any = [];
  PSLsOption: any = [];
  countriesOption: any = [];

  selectedRemoteCenter: any[] = [];
  selectedRole: any[] = [];
  selectedFunctionalMail: any[] = [];
  selectedPSL: any[] = [];
  selectedCountry: any[] = [];
  selectedRoleString: string = USER_ADVANCED_FILTER.OPTION.PLACE_HOLDER;

  cacheSlectedOptionApplied: any = {
    remoteCenter: [],
    role: [],
    roleString: USER_ADVANCED_FILTER.OPTION.PLACE_HOLDER,
    mailbox: [],
    psl: [],
    country: [],
  };

  userListData: any[] = [];
  @Input() restoreUserListData: any[] = [];

  @Output()
    chipFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
    filter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _userInfoService: UserInfoService) {
    super();
  }

  onInit(): void { }

  ngOnChanges(changes: SimpleChanges) { 
    if (changes['restoreUserListData'] && changes['restoreUserListData'].currentValue) {
      this.isInit = false
      this.loadInitialData(changes['restoreUserListData'].currentValue);
      this.storeSelect();
    }
  }

  loadInitialData(userData: any[]): void {
    // Only get data which have been used in user table
    if (userData?.length > 0) {
      const users = userData.map((item: any) => {
        if (!item.remoteCenter) {
          item.remoteCenter = {
            remoteCenterId: null,
            remoteCenterName: 'N/A',
          };
        }
        if (!item.mailboxList.length) {
          item.mailboxList = [
            {
              mailboxId: null,
              mailboxName: 'N/A',
            },
          ];
        }
        return item;
      });

      this.userListData = [...users];
      this.loadInitialDataForDropdown(users, '');
    } else {
      this.remoteCentersOption = [];
      this.rolesOption = [];
      this.functionalMailsOption = [];
      this.PSLsOption = [];
      this.countriesOption = [];
      this.storeSelect();
    }
  }
  loadInitialDataForDropdown(users: any[], exceptField: string = '') {
    // Clear the initial data dropdown
    let rolesOption: any[] = [];

    // Reset value patch 1
    switch (exceptField) {
      case this.METADATA.REMOTE_CENTER.LABEL:
        this.rolesOption = [];
        this.functionalMailsOption = [];
        this.PSLsOption = [];
        this.countriesOption = [];
        break;
      case this.METADATA.ROLE.LABEL:
        this.remoteCentersOption = [];
        this.functionalMailsOption = [];
        this.PSLsOption = [];
        this.countriesOption = [];
        break;
      case this.METADATA.FUNCTIONAL_MAILBOX.LABEL:
        this.rolesOption = [];
        this.remoteCentersOption = [];
        this.PSLsOption = [];
        this.countriesOption = [];
        break;
      case this.METADATA.PSL.LABEL:
        this.rolesOption = [];
        this.remoteCentersOption = [];
        this.functionalMailsOption = [];
        this.countriesOption = [];
        break;
      case this.METADATA.COUNTRY.LABEL:
        this.rolesOption = [];
        this.remoteCentersOption = [];
        this.functionalMailsOption = [];
        this.PSLsOption = [];
        break;
      default:
        this.rolesOption = [];
        this.functionalMailsOption = [];
        this.remoteCentersOption = [];
        this.PSLsOption = [];
        this.countriesOption = [];
        break;
    }

    users.forEach((user: any) => {
      if (user?.remoteCenterList) {
        user?.remoteCenterList?.forEach((remoteCenter: any) => {
          AppHelper.UtileFunctions.addToArrIfNotExist(
            this.remoteCentersOption,
            remoteCenter,
            'remoteCenterId'
          );
        })
      }
      if (user.mailboxList.length) {
        user.mailboxList.forEach((mailbox: any) => {
          AppHelper.UtileFunctions.addToArrIfNotExist(
            this.functionalMailsOption,
            mailbox,
            'mailboxId'
          );
        });
      }
      if (user.discipline) {
        AppHelper.UtileFunctions.addToArrIfNotExist(
          rolesOption,
          user.discipline,
          'disciplineId'
        );
      }
    });

    if (exceptField != this.METADATA.REMOTE_CENTER.LABEL) {
      this.remoteCentersOption = this.prepareDataRemoteCenter(
        this.remoteCentersOption,
        'remoteCenterId',
        'remoteCenterName',
        users,
      );
    }
    
    if (exceptField != this.METADATA.ROLE.LABEL) {
      this.rolesOption = this.prepareDataRole(
        rolesOption,
        this.rolesOption,
        users
      );

      if (!this.isInit) {
        this.isInit = true;
        this.rolesOptionRoot = JSON.parse(JSON.stringify(this.rolesOption));
      }
    }
    
    if (exceptField != this.METADATA.FUNCTIONAL_MAILBOX.LABEL) {
      this.functionalMailsOption = this.prepareData(
        this.functionalMailsOption,
        'mailboxId',
        'mailboxName',
        users,
        'mailboxList',
        'remoteCenterId'
      );
    }
    
    if (exceptField != this.METADATA.PSL.LABEL) {
      AppConstant.HALLIBURTON_PSLs.forEach((t) => {
        let u = users.find((u: any) => u.psl === t.code);
        if (u) {
          this.PSLsOption.push({
            name: t.name,
            code: t.code,
          });
        }
      });
    }
    
    if (exceptField != this.METADATA.COUNTRY.LABEL) {
        // countries
        AppConstant.COUNTRIES.forEach((t) => {
          let u = users.find((u: any) => u.country === t.code);
          if (u) {
            this.countriesOption.push(t);
          }
        });
    }

    this.calculateDataDropDownIfOnly();
    this.calculateVisibleItemRoleDropdown();
  }

  calculateVisibleItemRoleDropdown() {
    const arrRolesVisible: any[] = [];

    this.treeToArray(arrRolesVisible, this.rolesOption);
    this.removeSelectedNotExistsInList(arrRolesVisible);
    this.handleShowHide(arrRolesVisible, this.rolesOptionRoot);

    this.setSelectedRoleString();
  }

  /**
   * This is a recursive function to Handle show/hide for element in optionRoot based on data arrRolesVisible
   * @param arrRolesVisible
   * @param optionRoot
   * @returns
   */
  handleShowHide(arrRolesVisible: any[], optionRoot: any[]) {
    let numberElementHide = 0;
    optionRoot?.forEach((item: any) => {
      if (item?.children && item?.children?.length > 0) {
        const quantityChildrenChange = this.handleShowHide(
          arrRolesVisible,
          item.children
        );

        // All chilren is hide ==> node parent must be hide
        if (quantityChildrenChange == item.children.length) {
          item.styleClass = 'lowestChild hide';
        } else {
          item.styleClass = 'lowestChild';
        }
      } else {
        // find the node element in root is exists in array visible
        const result = arrRolesVisible.some(
          (role: any) => role.label === item.label
        );

        // node is exists ==> show , not is exists ==> hide with styleClass hide
        if (result) {
          item.styleClass = 'lowestChild';
        } else {
          item.styleClass = 'lowestChild hide';
          numberElementHide = numberElementHide + 1;
        }
      }
    });
    return numberElementHide;
  }

  removeSelectedNotExistsInList(arrRoles: any[]) {
    // Remove the selectedRole isn't exists in RoleOption
    const listPosDelete: number[] = [];

    // Find the element's position in selectedRole isn't exists in RoleOption
    this.selectedRole?.forEach((item: any, index: number) => {
      const result = arrRoles.some((role: any) => role.label === item.label);
      if (!result) {
        listPosDelete.push(index);
      }
    });

    // Delete element
    if (listPosDelete?.length > 0)
      for (let i = listPosDelete.length - 1; i >= 0; i--) {
        this.selectedRole.splice(listPosDelete[i], 1);
      }
  }

  /**
   * Convert the tree to array;
   * @param arr destination array
   * @param tree source tree
   */
  treeToArray(arr: any[], tree: any[]) {
    tree.forEach((item: any) => {
      if (item?.children?.length > 0) {
        this.treeToArray(arr, item.children);
      } else {
        arr.push(item);
      }
    });
  }

  calculateDataDropDownIfOnly() {
    let checkQuantityCondition = this.checkQuantityCondition();
    if (checkQuantityCondition.isOnlyCondition) {
      switch (checkQuantityCondition.name) {
        case this.METADATA.REMOTE_CENTER.LABEL:
          this.remoteCentersOption = [];
          this.restoreUserListData.forEach((user: any) => {
            if (user?.remoteCenterList && user.remoteCenterList.length > 0) {
              user.remoteCenterList.forEach((remoteCenter: any) => {
                AppHelper.UtileFunctions.addToArrIfNotExist(
                  this.remoteCentersOption,
                  remoteCenter,
                  'remoteCenterId'
                );
              })
            }
          });
          this.remoteCentersOption = this.prepareDataRemoteCenter(
            this.remoteCentersOption,
            'remoteCenterId',
            'remoteCenterName',
            this.restoreUserListData,
          );
          break;
        case this.METADATA.ROLE.LABEL:
          let rolesOption: any[] = [];
          this.rolesOption = [];
          this.restoreUserListData.forEach((user: any) => {
            if (user.discipline) {
              AppHelper.UtileFunctions.addToArrIfNotExist(
                rolesOption,
                user.discipline,
                'disciplineId'
              );
            }
          });

          this.rolesOption = this.prepareDataRole(
            rolesOption,
            this.rolesOption,
            this.restoreUserListData
          );
          break;
        case this.METADATA.FUNCTIONAL_MAILBOX.LABEL:
          this.functionalMailsOption = [];
          this.restoreUserListData.forEach((user: any) => {
            if (user.mailboxList.length) {
              user.mailboxList.forEach((mailbox: any) => {
                AppHelper.UtileFunctions.addToArrIfNotExist(
                  this.functionalMailsOption,
                  mailbox,
                  'mailboxId'
                );
              });
            }
          });
          this.functionalMailsOption = this.prepareData(
            this.functionalMailsOption,
            'mailboxId',
            'mailboxName',
            this.restoreUserListData,
            'mailboxList',
            'remoteCenterId'
          );
          break;
        case this.METADATA.PSL.LABEL:
          this.PSLsOption = [];
          AppConstant.HALLIBURTON_PSLs.forEach((t) => {
            let u = this.restoreUserListData.find((u: any) => u.psl === t.code);
            if (u) {
              this.PSLsOption.push({
                name: t.name,
                code: t.code,
              });
            }
          });
          break;
        case this.METADATA.COUNTRY.LABEL:
          this.countriesOption = [];
          AppConstant.COUNTRIES.forEach((t) => {
            let u = this.restoreUserListData.find(
              (u: any) => u.country === t.code
            );
            if (u) {
              this.countriesOption.push(t);
            }
          });
          break;
        default:
          break;
      }
    }
  }

  executeFilter() {
    let payloadChip: any = {};
    let payloadChipRemoteCenters: any = [];
    let payloadChipRoles: any = [];
    let payloadChipMailBoxs: any = [];
    let payloadChipPSLs: any = [];
    let payloadChipCountries: any = [];

    (this.selectedRemoteCenter || []).length > 1
      ? payloadChipRemoteCenters.push(`Multiple remote center`)
      : (this.selectedRemoteCenter || []).length == 1
      ? payloadChipRemoteCenters.push(
          this.selectedRemoteCenter[0].remoteCenterName
        )
      : '';

    (this.selectedRole || []).length > 1
      ? payloadChipRoles.push(`Multiple role`)
      : (this.selectedRole || []).length == 1
      ? payloadChipRoles.push(this.selectedRole[0].label)
      : '';

    (this.selectedFunctionalMail || []).length > 1
      ? payloadChipMailBoxs.push(`Multiple mailbox`)
      : (this.selectedFunctionalMail || []).length == 1
      ? payloadChipMailBoxs.push(this.selectedFunctionalMail[0].mailboxName)
      : '';

    (this.selectedPSL || []).length > 1
      ? payloadChipPSLs.push(`Multiple PSL`)
      : (this.selectedPSL || []).length == 1
      ? payloadChipPSLs.push(this.selectedPSL[0].name)
      : '';

    (this.selectedCountry || []).length > 1
      ? payloadChipCountries.push(`Multiple country`)
      : (this.selectedCountry || []).length == 1
      ? payloadChipCountries.push(this.selectedCountry[0].code)
      : '';

    payloadChip = {
      remoteCenter: payloadChipRemoteCenters,
      role: payloadChipRoles,
      mailbox: payloadChipMailBoxs,
      psl: payloadChipPSLs,
      country: payloadChipCountries,
    };
    if (!(this.selectedRemoteCenter || []).length) {
      delete payloadChip.remoteCenter;
    }
    if (!(this.selectedRole || []).length) {
      delete payloadChip.role;
    }
    if (!(this.selectedFunctionalMail || []).length) {
      delete payloadChip.mailbox;
    }
    if (!(this.selectedPSL || []).length) {
      delete payloadChip.psl;
    }
    if (!(this.selectedCountry || []).length) {
      delete payloadChip.country;
    }

    // payload cal API
    let payload: any = {};
    let payloadRemoteCenter: any = [];
    let payloadRoles: any = [];
    let payloadMailBoxs: any = [];
    let payloadPSLs: any = [];
    let payloadCountries: any = [];

    (this.selectedRemoteCenter || []).length
      ? this.selectedRemoteCenter.forEach((item: any) => {
          payloadRemoteCenter.push(item.remoteCenterId);
        })
      : '';
    (this.selectedRole || []).length
      ? this.selectedRole.forEach((item: any) => {
          payloadRoles.push(item.data);
        })
      : '';
    (this.selectedFunctionalMail || []).length
      ? this.selectedFunctionalMail.forEach((item: any) => {
          payloadMailBoxs.push(item.mailboxId);
        })
      : '';
    (this.selectedPSL || []).length
      ? this.selectedPSL.forEach((item: any) => {
          payloadPSLs.push(item.code);
        })
      : '';
    (this.selectedCountry || []).length
      ? this.selectedCountry.forEach((item: any) => {
          payloadCountries.push(item.code);
        })
      : '';

    payload = {
      remoteCenter: payloadRemoteCenter,
      role: payloadRoles,
      mailbox: payloadMailBoxs,
      psl: payloadPSLs,
      country: payloadCountries,
    };

    this.chipFilter.emit(Object.assign({}, payloadChip));
    this.filter.emit(Object.assign({}, payload));
    this.storeSelect();
  }

  prepareData(
    data: any,
    key1: string,
    key2: string,
    users?: any,
    checkKeyNa?: string,
    key3?: string
  ): any[] {
    if (data.length) {
      let resultData = data.map((element: any) => {
        if (key3)
          return {
            [key1]: element[key1],
            [key2]: element[key2],
            [key3]: element[key3],
          };
        else {
          return {
            [key1]: element[key1],
            [key2]: element[key2],
          };
        }
      });

      let resultArray = AppHelper.UtileFunctions.sortArrayExceptNABykey(
        resultData,
        key2
      );
      let haveValueNA: boolean = false;
      if (users && checkKeyNa) {
        haveValueNA = AppHelper.UtileFunctions.isArrayHaveNABykey(
          users,
          checkKeyNa
        );
      }

      if (key3 && haveValueNA) {
        resultArray.push({
          [key1]: null,
          [key2]: 'N/A',
          [key3]: null,
        });
      }

      if (!key3 && haveValueNA) {
        resultArray.push({
          [key1]: null,
          [key2]: 'N/A',
        });
      }
      return resultArray;
    } else {
      return [];
    }
  }

  prepareDataRemoteCenter(
    data: any,
    key1: string,
    key2: string,
    users?: any,
  ): any[] {
    let resultArray = [];

    if (data.length) {
      let resultData = data.map((element: any) => {
        return {
          [key1]: element[key1],
          [key2]: element[key2],
        };
      });

      resultArray = AppHelper.UtileFunctions.sortArrayExceptNABykey(
        resultData,
        key2
      );
    }

    const haveValueNA: boolean = !!users?.some((user: any) => user?.remoteCenterList?.length == 0);
    if (haveValueNA) 
      resultArray.push({
        [key1]: null,
        [key2]: 'N/A',
      });
    return resultArray;
  }

  prepareDataRole(discipline: any, roles: TreeNode[], userData?: any) {
    if (discipline.length) {
      roles.push({
        label: 'Engineer',
        data: 'Engineer',
        children: [],
      });
      roles[0].children = [];
      discipline.forEach((discipline: any) => {
        this.rolesOption[0].children.push({
          label: discipline.disciplineName,
          data: discipline.disciplineId,
          styleClass: 'lowestChild',
          leaf: false,
          parent: 'Engineer',
        });
      });
    }

    if (
      userData.some((user: any) => user.role === AppConstant.ROLES.VIEWER.label)
    ) {
      roles.push({
        label: 'Viewer',
        data: 'Viewer',
        styleClass: 'lowestChild',
        leaf: false,
      });
    }

    if (
      userData.some(
        (user: any) => user.role === AppConstant.ROLES.MANAGER.label
      )
    ) {
      roles.push({
        label: 'Manager',
        data: 'Manager',
        styleClass: 'lowestChild',
        leaf: false,
      });
    }

    if (
      userData.some((user: any) => user.role === AppConstant.ROLES.ADMIN.label)
    ) {
      roles.push({
        label: 'Admin',
        data: 'Admin',
        styleClass: 'lowestChild',
        leaf: false,
      });
    }

    return roles;
  }

  storeSelect() {
    this.cacheSlectedOptionApplied.remoteCenter = this.selectedRemoteCenter;
    this.cacheSlectedOptionApplied.role = this.selectedRole;
    this.cacheSlectedOptionApplied.roleString = this.selectedRoleString;

    this.cacheSlectedOptionApplied.mailbox = this.selectedFunctionalMail;
    this.cacheSlectedOptionApplied.psl = this.selectedPSL;
    this.cacheSlectedOptionApplied.country = this.selectedCountry;
  }

  restoreSelect() {
    this.selectedRemoteCenter = this.cacheSlectedOptionApplied.remoteCenter;
    this.selectedRole = this.cacheSlectedOptionApplied.role;
    this.selectedRoleString = this.cacheSlectedOptionApplied.roleString;

    this.selectedFunctionalMail = this.cacheSlectedOptionApplied.mailbox;
    this.selectedPSL = this.cacheSlectedOptionApplied.psl;
    this.selectedCountry = this.cacheSlectedOptionApplied.country;

    if (!this.selectedRoleString || ["", "All"].includes(this.selectedRoleString))
      this.resetPartialSelected(this.rolesOptionRoot);
  }

  onShow() {
    this.restoreSelect();
    this.loadAnotherDataExcept('');
  }

  payloadFilter: any = {
    remoteCenter: [],
    role: [],
    mailbox: [],
    psl: [],
    country: [],
  };

  loadAnotherDataExcept(exceptField: string = '') {
    this.preparePayload(exceptField);
    this.loadPayloadFilter(this.payloadFilter);
    this.loadInitialDataForDropdown(
      this.userListData,
      exceptField
    );
  }

  preparePayload(exceptField: string = '') {
    if (!exceptField || exceptField == this.METADATA.REMOTE_CENTER.LABEL) {
      this.payloadFilter.remoteCenter = [];
      this.selectedRemoteCenter?.forEach((remote: any) => {
        if (
          (remote && remote.remoteCenterId) ||
          remote.remoteCenterName === 'N/A'
        ) {
          this.payloadFilter.remoteCenter.push(remote.remoteCenterId);
        }
      });
    }

    if (!exceptField || exceptField == this.METADATA.ROLE.LABEL) {
      this.payloadFilter.role = [];
      this.selectedRole?.forEach((role: any) => {
        if (role && role.data) {
          this.payloadFilter.role.push(role.data);
        }
      });    
    }

    if (!exceptField || exceptField == this.METADATA.FUNCTIONAL_MAILBOX.LABEL) {
      this.payloadFilter.mailbox = [];
      this.selectedFunctionalMail?.forEach((mailbox: any) => {
        if ((mailbox && mailbox.mailboxId) || mailbox.mailboxName === 'N/A') {
          this.payloadFilter.mailbox.push(mailbox.mailboxId);
        }
      });
    
    }

    if (!exceptField || exceptField == this.METADATA.PSL.LABEL) {
      this.payloadFilter.psl = [];
      this.selectedPSL?.forEach((psl: any) => {
        if (psl && psl.code) {
          this.payloadFilter.psl.push(psl.code);
        }
      });    
    }

    if (!exceptField || exceptField == this.METADATA.COUNTRY.LABEL) {
      this.payloadFilter.country = [];
      this.selectedCountry?.forEach((country: any) => {
        if (country && country.code) {
          this.payloadFilter.country.push(country.code);
        }
      });
    }
  }

  loadPayloadFilter(payloadFilter: any) {
    this.payloadFilter = payloadFilter;
    this.userListData = JSON.parse(JSON.stringify(this.restoreUserListData));

    if (this.payloadFilter) {
      this.userListData = this.filterPayloadData(
        this.userListData,
        this.payloadFilter
      );
    }
  }

  checkQuantityCondition(): any {
    let count = 0;
    let name = null;

    if (this.selectedRemoteCenter && this.selectedRemoteCenter?.length > 0) {
      name = this.METADATA.REMOTE_CENTER.LABEL;
      count += 1;
    }

    if (this.selectedRole && this.selectedRole?.length > 0) {
      name = this.METADATA.ROLE.LABEL;
      count += 1;
    }

    if (
      this.selectedFunctionalMail &&
      this.selectedFunctionalMail?.length > 0
    ) {
      name = this.METADATA.FUNCTIONAL_MAILBOX.LABEL;
      count += 1;
    }

    if (this.selectedPSL && this.selectedPSL?.length > 0) {
      name = this.METADATA.PSL.LABEL;
      count += 1;
    }

    if (this.selectedCountry && this.selectedCountry?.length > 0) {
      name = this.METADATA.COUNTRY.LABEL;
      count += 1;
    }

    if (count == 1) return { isOnlyCondition: true, name };
    else return { isOnlyCondition: false, name: null };
  }

  filterPayloadData(data: any[], condition: any) {
    return data.filter((item) => {
      const flagRemoteCenter = !!item?.remoteCenterList?.some((remoteCenter: any) => {
        if (condition.remoteCenter.includes(remoteCenter?.remoteCenterId))
          return true;
      });
      
      const includeNA = (condition.remoteCenter.includes(null));
      let userNotHaveRemoteCenter = (includeNA && item?.remoteCenterList?.length == 0) || false;

      return (
        (!condition.remoteCenter?.length || flagRemoteCenter || userNotHaveRemoteCenter) &&
        (!condition.role?.length ||
          condition.role.includes(item.role) ||
          condition.role.includes(item?.discipline?.disciplineId)) &&
        (!condition.mailbox?.length ||
          item.mailboxList.some((mail: any) =>
            condition.mailbox.includes(mail.mailboxId)
          )) &&
        (!condition.psl?.length || condition.psl.includes(item.psl)) &&
        (!condition.country?.length || condition.country.includes(item.country))
      );
    });
  }

  onResetFilter(type: string) {
    switch (type) {
      case 'all':
        this.selectedRemoteCenter = [];
        this.selectedRole = [];
        this.selectedFunctionalMail = [];
        this.selectedPSL = [];
        this.selectedCountry = [];       
        this.onClearRole();

        this.cacheSlectedOptionApplied = {
          remoteCenter: null,
          role: null,
          roleString: null,
          mailbox: null,
          psl: null,
          country: null,
        };

        this.payloadFilter = {
          remoteCenter: [],
          role: [],
          roleString: [],
          mailbox: [],
          psl: [],
          country: [],
        };
        break;
      case 'remoteCenter':
        this.selectedRemoteCenter = [];
        this.cacheSlectedOptionApplied.remoteCenter = [];
        break;
      case 'role':
        this.selectedRole = [];
        this.cacheSlectedOptionApplied.role = [];
        this.cacheSlectedOptionApplied.roleString = this.METADATA.OPTION.PLACE_HOLDER;
        this.onClearRole();
        break;
      case 'mailbox':
        this.selectedFunctionalMail = [];
        this.cacheSlectedOptionApplied.mailbox = [];
        break;
      case 'psl':
        this.selectedPSL = [];
        this.cacheSlectedOptionApplied.psl = [];
        break;
      case 'country':
        this.selectedCountry = [];
        this.cacheSlectedOptionApplied.country = [];
        break;
      default:
        break;
    }
    this.loadInitialData(this.restoreUserListData);      
  }

  selectedOptionChange(event: any, selectedItem: any, typeItem: any) {}

  selectedOptionClear(typeItem: any) {
    switch (typeItem) {
      case this.METADATA.REMOTE_CENTER.LABEL:
        this.selectedRemoteCenter = [];
        break;
      case this.METADATA.ROLE.LABEL:
        this.selectedRole = [];
        break;
      case this.METADATA.FUNCTIONAL_MAILBOX.LABEL:
        this.selectedFunctionalMail = [];
        break;
      case this.METADATA.PSL.LABEL:
        this.selectedPSL = [];
        break;
      case this.METADATA.COUNTRY.LABEL:
        this.selectedCountry = [];
        break;
      default:
        break;
    }
    this.loadAnotherDataExcept();
  }

  onOptionsSelected(event: any) {
    this.setSelectedRoleString();
  }

  onOptionsUnSelected(event: any) {
    this.setSelectedRoleString();
  }

  setSelectedRoleString() {
    if (!this.selectedRole) {
      this.selectedRoleString = this.METADATA.OPTION.PLACE_HOLDER;
      return;
    }

    let selectedRoleArray: any[] = [];
    this.selectedRole = this.selectedRole?.filter((select: any) => !select?.styleClass?.includes("hide"))
    this.selectedRole.forEach((role: any) => {
      if (role?.children === undefined) selectedRoleArray.push(role.label);
    });
    this.selectedRoleString = selectedRoleArray.join(', ');
  }

  onClearRole() {
    this.resetPartialSelected(this.rolesOptionRoot);
    this.setSelectedRoleString();
  }

  resetPartialSelected(option: any): void {
    if (!option) {
      return;
    }

    for (let node of option) {
      node.partialSelected = false;
      if (node.children && node.children?.length > 0)
        this.resetPartialSelected(node.children);
    }
  }
  onDestroy(): void {}
}
