<p-dialog
  [(visible)]="displayFormWellbore"
  [modal]="true"
  [style]="{ width: '480px' }"
  [draggable]="false"
  [resizable]="false"
  [closeOnEscape]="false"
  maskStyleClass="maskStyleDialog"
  styleClass="style-class-dialog"
  [closable]="false"
  [baseZIndex]="0"
>
  <ng-template pTemplate="header">
    <div class="header-dialog">
      <span [pTooltip]="titlePopup || ''" tooltipPosition="bottom">
        Edit wellbore: {{ titlePopup }}
      </span>
    </div>
    <div
      class="custom-close-icon cursor-pointer"
      (click)="closeFormWellboreDialog()"
    >
      <i class="pi pi-times"></i>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <!-- [ngClass]="{ 'form-disabled': isViewer }" -->
    <div
      class="container edit-form"
      *ngIf="!!formFormWellboreGroup"
      [formGroup]="formFormWellboreGroup"
    >
      <div class="row">
        <div class="col-12 px-0">
          <div class="add-input-title">
            Site
            <span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                class="custom-p-input p-input-left-icon"
                placeholder="Enter Site Name"
                (focusout)="onBlurMethod('siteName', formFormWellboreGroup)"
                [formControlName]="fieldNameKeys.siteName"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_1.toggle($event)"
              ></i>
              <p-menu
                #menuItems_1
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.siteName, wellbore)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <div class="add-input-title">Well<span class="p-error"> *</span></div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                class="custom-p-input p-input-left-icon"
                placeholder="Enter Well Name"
                (focusout)="onBlurMethod('wellName', formFormWellboreGroup)"
                [formControlName]="fieldNameKeys.wellName"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_2.toggle($event)"
              ></i>
              <p-menu
                #menuItems_2
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.wellName, wellbore)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <div class="add-input-title">
            Wellbore<span class="p-error"> *</span>
          </div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                class="custom-p-input p-input-left-icon"
                placeholder="Enter Wellbore Name"
                (focusout)="onBlurMethod('wellboreName', formFormWellboreGroup)"
                [formControlName]="fieldNameKeys.wellboreName"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_3.toggle($event)"
              ></i>
              <p-menu
                #menuItems_3
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.wellboreName, wellbore)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <div class="row">
            <div class="col-6" style="padding-right: 12px">
              <div class="add-input-title">Surface Latitude</div>
              <div>
                <div class="add-input p-inputgroup p-input-icon-right">
                  <input
                    type="text"
                    pInputText
                    class="custom-p-input p-input-left-icon"
                    placeholder="Enter Latitude"
                    NumberDegree
                    [paste]="false"
                    [allowDecimals]="true"
                    [allowSign]="true"
                    [lengthDecimalSeparator]="LENGTH_DECIMAL_SEPARATOR"
                    suffixCharacter="°"
                    (focusout)="
                      removeZeroFirst('latitude', formFormWellboreGroup)
                    "
                    [formControlName]="fieldNameKeys.latitude"
                    [innerMsg]="messageErrors"
                  />
                  <i
                    class="icon pi pi-ellipsis-v cursor-pointer"
                    (click)="menuItems_4.toggle($event)"
                  ></i>
                  <p-menu
                    #menuItems_4
                    [popup]="true"
                    [model]="menuItems"
                    (onShow)="onMenuShow(fieldNameKeys.latitude, wellbore)"
                    class="menu-table-item"
                  ></p-menu>
                </div>
              </div>
            </div>
            <div class="col-6" style="padding-left: 12px">
              <div class="add-input-title">Surface Longitude</div>
              <div>
                <div class="add-input p-inputgroup p-input-icon-right">
                  <input
                    type="text"
                    pInputText
                    class="custom-p-input p-input-left-icon"
                    placeholder="Enter Longitude"
                    NumberDegree
                    [paste]="false"
                    [allowDecimals]="true"
                    [allowSign]="true"
                    [lengthDecimalSeparator]="LENGTH_DECIMAL_SEPARATOR"
                    suffixCharacter="°"
                    (focusout)="
                      removeZeroFirst('longitude', formFormWellboreGroup)
                    "
                    [formControlName]="fieldNameKeys.longitude"
                    [innerMsg]="messageErrors"
                  />
                  <i
                    class="icon pi pi-ellipsis-v cursor-pointer"
                    (click)="menuItems_5.toggle($event)"
                  ></i>
                  <p-menu
                    #menuItems_5
                    [popup]="true"
                    [model]="menuItems"
                    (onShow)="onMenuShow(fieldNameKeys.longitude, wellbore)"
                    class="menu-table-item"
                  ></p-menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <div class="add-input-title">Spud Date</div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <p-calendar
                styleClass="custom-p-calendar secondary-icon"
                [showTime]="true"
                [readonlyInput]="true"
                dateFormat="dd-M-yy,"
                placeholder="Select Date"
                icon="pi pi-calendar"
                [showIcon]="true"
                [showClear]="true"
                [formControlName]="fieldNameKeys.spudDate"
                [innerMsg]="messageErrors"
                [selectOtherMonths]="true"
              ></p-calendar>
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_6.toggle($event)"
              ></i>
              <p-menu
                #menuItems_6
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.spudDate, wellbore)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <div class="add-input-title">UWI</div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                class="custom-p-input p-input-left-icon"
                placeholder="Enter UWI"
                blockSpecialCharacter
                [formControlName]="fieldNameKeys.UWI"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_7.toggle($event)"
              ></i>
              <p-menu
                #menuItems_7
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.UWI, wellbore)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>

        <div class="col-12 px-0">
          <div class="add-input-title">GUID</div>
          <div>
            <div class="add-input p-inputgroup p-input-icon-right">
              <input
                type="text"
                pInputText
                class="custom-p-input p-input-left-icon"
                placeholder="Enter GUID"
                blockSpecialCharacter
                [formControlName]="fieldNameKeys.GUID"
                [innerMsg]="messageErrors"
                TextInputValidate
                typeCheck="short"
              />
              <i
                class="icon pi pi-ellipsis-v cursor-pointer"
                (click)="menuItems_8.toggle($event)"
              ></i>
              <p-menu
                #menuItems_8
                [popup]="true"
                [model]="menuItems"
                (onShow)="onMenuShow(fieldNameKeys.GUID, wellbore)"
                class="menu-table-item"
              ></p-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end gap-2">
      <p-button
        [attr.data-cy]="'cancel-button'"
        label="Cancel"
        (onClick)="closeFormWellboreDialog()"
        class="custom-p-button custom-p-button--primary"
      ></p-button>
      <p-button
        [attr.data-cy]="'save-button'"
        label="Save"
        (onClick)="saveWellbore($event)"
        class="custom-p-button custom-p-button--secondary"
        [ngClass]="
          formFormWellboreGroup.status === 'INVALID' || isViewer
          ? 'custom-p-button custom-p-button--disable'
          : ''
          "
        [disabled]="formFormWellboreGroup.status === 'INVALID' || isViewer"
      ></p-button>
    </div>
  </ng-template>

  <app-loading [isLoading]="(loading | async) || false"></app-loading>
</p-dialog>
