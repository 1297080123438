import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { NgForm } from '@angular/forms';
import { Rig, RigListColumn } from 'src/app/shared/interface/rig.interface';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { DistributionService } from 'src/app/shared/services/distribution.service';
import { DistributionTemplate } from 'src/app/shared/enum';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { Menu } from 'primeng/menu';
import { DialogService } from 'primeng/dynamicdialog';
import { ExportSettingComponent } from 'src/app/shared/components/export-setting/export-setting.component';

@Component({
  selector: 'app-home-rig-list',
  templateUrl: './home-rig-list.component.html',
  styleUrls: ['./home-rig-list.component.scss'],
})
export class HomeRigListComponent extends BaseComponent {
  isLoading: boolean = false;

  projectId: string = '';
  rigListData: Rig[] = [];
  rigListColumn: RigListColumn[] = [
    { field: 'rigName', header: 'Rig Name' },
    { field: 'projectName', header: 'Project' },
    { field: 'statistics', header: 'Statistics' },
    { field: 'details', header: 'Details' },
    { field: 'displayRemoteCenter', header: 'Remote Center' },
    { field: 'displayMailbox', header: 'Functional Mailboxes' },
    { field: 'features', header: 'x' },
  ];

  counterResult: number = 0;

  // Variable of Form
  @ViewChild('searchRigForm') searchRigForm!: NgForm;
  @ViewChild('menu') menu: any;

  getMenuItemsForItem$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(
    []
  );

  AppHelper = AppHelper;

  displayFormRig: boolean = false;
  rigEditId: string = '';
  rigJournalEditId: string = '';
  projectNameEdit: string = '';

  sidebarImportDistribution: any = {
    isDisplay: false,
    rigName: '',
    rigProject: '',
    rigOperator: '',
    uploadStatus: '',
    rigJournalId: '',
    rigId: '',
    distributionUrl: '',
    reuploadStatus: '',
  };

  constructor(
    private _homeService: HomeService,
    private _activedRoute: ActivatedRoute,
    private _router: Router,
    private _blobService: AzureBlobService,
    private _notificationService: NotificationService,
    private _userInfoService: UserInfoService,
    private _interventionService: InterventionService,
    private _distributionService: DistributionService,
    private _dialogService: DialogService
  ) {
    super();
  }
  onInit(): void {
    this.isLoading = true;

    this._activedRoute.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map((params) => params.get('id'))
      )
      .subscribe((id) => {
        this.isLoading = false;

        this.projectId = id || '';
      });
  }

  ngAfterViewInit(): void {
    const formValue = this.searchRigForm.valueChanges;

    formValue!
      .pipe(
        tap(() => (this.isLoading = true)),
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((input) =>
          this._homeService.getSearchRigByStringEDM(
            this.projectId,
            input.searchString
          )
        )
      )
      .subscribe((res: any) => {
        this.rigListData = res;
        this.rigListData = this.rigListData.map((rig: any) => {
          let displayRemoteCenter = rig.remoteCenterList
            .map((remote: any) => remote.remoteCenterName)
            .join(', ');

          let displayMailbox = rig.mailboxList
            .map((mailbox: any) => mailbox.mailboxName)
            .join(', ');

          return {
            ...rig,
            displayRemoteCenter: displayRemoteCenter,
            displayMailbox: displayMailbox,
          };
        });

        this.rigListData.forEach((element: Rig) => {
          element.flagStatistic = AppHelper.UtileFunctions.sortPrimaryFlag(
            element.interventionFlag
          ).slice(0, 4);

          // Wait re Model from Backend
          // element.isComplete = true;
          // element.rigJournalId = 'random';
        });

        this.counterResult = this.rigListData.length;

        this.isLoading = false;

        this._userInfoService
          .checkPermissionWithRig(res?.message, this.rigListData[0])
          .subscribe((res) => {
            if (!res) {
              this._router.navigate(['/home']);
            }
          });
      });

    // Uppdate of table
    this._distributionService.rigcardUpdate$.subscribe((updateRigRow: any) => {
      if (updateRigRow.rigJournalId) {
        this.rigListData = this.rigListData.map((rigRow: any) => {
          if (rigRow.rigJournalId === updateRigRow.rigJournalId) {
            return {
              ...rigRow,
              distributionListUrl: updateRigRow.distributionListUrl,
            };
          } else {
            return rigRow;
          }
        });

        if (updateRigRow.updateType === 'RigTile') {
          this.rigListData = this.rigListData.map((rigCard: any) => {
            if (rigCard.rigJournalId === updateRigRow.rigJournalId) {
              return {
                ...rigCard,
                distributionListUrl: updateRigRow.rigFile.distributionListUrl,
                communicateProtocolUrl:
                  updateRigRow.rigFile.communicateProtocolUrl,
                dataRoutingUrl: updateRigRow.rigFile.dataRoutingUrl,
              };
            } else {
              return rigCard;
            }
          });
        }
      }
    });
  }

  showContextMenu(event: any, inputData: Rig) {
    this.getMenuItemsForItem$.next(this.getMenuTableItem(inputData));
    this.menu.toggle(event);
  }

  getMenuTableItem(rig: any): any {
    const isAdmin =
      this._userInfoService.userSubject.getValue().role ===
      AppConstant.ROLES.ADMIN.label;
    const isViewer =
      this._userInfoService.userSubject.getValue().role ===
      AppConstant.ROLES.VIEWER.label;

    if (rig && rig.rigId && rig.projectId) {
      return [
        {
          label: 'Distribution Lists',
          icon: 'c-icons file-icon',
          command: () => {
            this._distributionService.setDisplay({
              render: {
                isShow: true,
                template: rig.distributionListUrl
                  ? DistributionTemplate.uploading
                  : DistributionTemplate.uploadYet,
                reuploadStatus: false,
              },
            });

            this._distributionService.setDisData({
              data: {
                rigId: rig.rigId,
                rigJournalId: rig.rigJournalId,
                rigOperator:
                  rig.policy.policyCurrentName ||
                  rig.policy.policyOriginalName ||
                  'Company Name',
                rigProject:
                  rig.projectCurrentName ||
                  rig.projectOriginalName ||
                  'Project Name',
                rigName: rig.rigName || 'Rig Name',
                language: rig.language
              },
            });

            this._distributionService.setDisURL({
              link: {
                distributionUrl: rig.distributionListUrl,
              },
            });
          },
        },
        {
          label: 'Edit Rig Tile',
          visible: isAdmin || isViewer,
          icon: 'c-icons edit-icon',
          command: () => {
            this.displayFormRig = true;
            this.rigEditId = rig.rigId || '';
            this.rigJournalEditId = rig.rigJournalId || '';
            this.projectNameEdit = rig.projectName || '';
          },
        },
        {
          label: 'Wellbores',
          icon: 'c-icons wellbore-icon',
          command: () => {
            this._router.navigate(
              [`home/rig-journal/${rig.rigId}/project/${rig.projectId}`],
              {
                queryParams: { tab: 'wellbore' },
              }
            );
          },
        },
        {
          label: 'Intervals',
          icon: 'c-icons interval-icon',
          command: () => {
            this._router.navigate(
              [`home/rig-journal/${rig.rigId}/project/${rig.projectId}`],
              {
                queryParams: { tab: 'interval' },
              }
            );
          },
        },
        {
          label: 'Runs',
          icon: 'c-icons run-icon',
          command: () => {
            this._router.navigate(
              [`home/rig-journal/${rig.rigId}/project/${rig.projectId}`],
              {
                queryParams: { tab: 'run' },
              }
            );
          },
        },
        {
          label: 'Export',
          icon: 'c-icons export-icon',
          command: () => {
            this.openExportDialog(rig);
          },
        },
      ];
    } else {
      return [];
    }
  }

  // Reload after Add/Edit User
  requestReloadPage(event: any) {
    this.displayFormRig = !event;
    this.clearFilterRigList();
  }
  // Function help clear all filter to default --> soon become a common func
  clearFilterRigList(): void {
    this.searchRigForm.controls['searchString'].setValue('');
    this.isLoading = false;
  }

  backToProjectList() {
    this._router.navigate(['/home'], { queryParams: { view: 'card' } });
  }

  redirectToRigDetail(rigId: string, projectId: string) {
    this._router.navigate([`home/rig-journal/${rigId}/project/${projectId}`], {
      queryParams: { tab: 'wellbore' },
    });
  }

  executeFilter(rigJournalId: string, rigName: string) {
    // [IMPORTANT] - purpose to loading more Alerts
    this._interventionService.setTriggerRigCard(true);

    this._interventionService.setInterventionLogs([]);
    this._interventionService.setInterventionLogsTotal(0);

    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    // Call API get Alert Log with payload filter
    this.filterAlertLog(payloadFilterLog, rigName);
  }

  filterAlertLog(filterPayload: any, rigName: string) {
    of(null).pipe(
      tap(() => {
        // Turn on Loading Alert Log
        this._interventionService.setLoading(true);

        // Set Filter Chip Logs
        // HightLight Rig Card - CSS 
        if (filterPayload?.rigJournalId && rigName) {
          // Set TagName/Filter Log of Recent Alert Logs
          this._interventionService.setLogFilter({
            rigJournalId: filterPayload?.rigJournalId,
            rigName: rigName
          });
        } else {
          this._interventionService.setLogFilterDefault();
        }
      }),
      switchMap(() => {
        return this._interventionService.getInterventionLogsFilter(filterPayload, 0, AppConstant.ITEMS_PER_PAGE).pipe(
          finalize(() => this._interventionService.setLoading(false))
        )
      })
    ).subscribe((alert: any) => {
        this.showDataAlertLogs(alert);
    });
  }

  showDataAlertLogs(alertResponse: { items: any, total: number  }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    setTimeout(() => {
      this._interventionService.setTriggerRigCard(false);
    }, 300);
  }

  readPDFdocument(PDFLink: string, containerName: string, currentRigRow: any) {
    if (PDFLink) {
      this.isLoading = true;
      this._blobService
        .downloadPDF(
          decodeURIComponent(AppHelper.StringFunctions.getFileName(PDFLink)),
          containerName
        )
        .then((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Caught error:', error); // The error will be caught here
          this._notificationService.setMessage({
            type: AppConstant.MESSAGE_TYPE.WARNING,
            header: 'Loading PDF Document',
            content: 'This document is no longer available, please try again',
          });
          // disable hyperlink of no longer available document
          switch (containerName) {
            case 'rigcommunicateprotocal':
              currentRigRow.communicateProtocalUrl = null;
              break;
            case 'rigdatarouting':
              currentRigRow.dataRoutingUrl = null;
              break;
            case 'intervaldesignofservice':
              currentRigRow.interval.designOfServiceUrl = null;
              break;
          }
          this.isLoading = false;
        });
    } else {
      this._notificationService.setMessage({
        type: AppConstant.MESSAGE_TYPE.WARNING,
        header: 'Loading PDF Document',
        content: 'This document is no longer available, please try again',
      });
      this.isLoading = false;
    }
  }

  // ============ FUNCTION HANDLE DISTRIBUTION LIST PANEL ============
  hideSideBar(isShow: boolean) {
    this.sidebarImportDistribution.isDisplay = isShow;
  }
  switchTemplate(value: string) {
    if (value === 'uploadYet-reload') {
      this.sidebarImportDistribution.reuploadStatus =
        AppHelper.MathFunctions.getRandomNumber();
      this.sidebarImportDistribution.distributionUrl = '';
    } else {
      this.sidebarImportDistribution.reuploadStatus = false;
      this.sidebarImportDistribution.uploadStatus = value;
    }
  }
  requestReloadHomePage(value: string) {
    this.requestReloadPage(true);
  }
  // =================================================================

  redirectTo(rigJournalId: string) {
    if (rigJournalId)
      this._router.navigate([`/home/alert-rig/${rigJournalId}`]);
  }

  openExportDialog({rigName, rigJournalId}: any): void {
    const ref = this._dialogService.open(ExportSettingComponent, {
      header: `Export Alerts: ${rigName}`,
      data: {
        rigJournalId: rigJournalId
      },
      styleClass: 'style-class-dialog-custom'
    });

    ref.onClose
      .subscribe({
        next: (res: any) => {},
        error: (error) => {
          console.log(error);
        },
      });
  }

  onDestroy(): void {}
}
