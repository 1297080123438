import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Input } from '@angular/core';
import * as moment from 'moment';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
  providers: [],
})
export class VideoCardComponent implements OnInit {
  @Input() video: any;
  @Input() isAdmin: boolean = false;
  @Input() isViewer: boolean = false;
  @Output() confirmEvent: any = new EventEmitter<any>();
  @Output() videoClicked: any = new EventEmitter<any>();

  items: any;
  playIcon: any = faPlay;
  isMenuShown: boolean = false;

  constructor(private _blobService: AzureBlobService, private _notificationService: NotificationService) {}

  ngOnInit(): void {
    this.items = [
      {
        items: [
          {
            label: 'Get Video ID',
            icon: 'pi pi-copy',
            command: () => {
              navigator.clipboard.writeText(this.video.videoId);
              this._notificationService.setMessage({
                type: AppConstant.MESSAGE_TYPE.SUCCESS,
                header: 'Copy clipboard!',
                content: `Copied: Video ID successfully!`,
              });
            },
          },
          {
            label: 'Edit',
            icon: 'pi pi-pencil',
            command: () => {
              this.confirmEvent.emit({ type: 'Edit' });
            },
          },
          {
            label: 'Delete',
            icon: 'pi pi-trash',
            disabled: this.isViewer,
            command: () => {
              this.confirmEvent.emit({
                type: 'Delete',
                video: this.video,
              });
            },
          },
        ],
      },
    ];
    const d = new Date(Date.parse(this.video.createdAt));
    this.video.createdAtFormat = moment(d, 'YYYYMMDD').fromNow();
    this.video.thumbnail
      ? this.getThumbnail(this.video.thumbnail.split('/')[4])
      : '';
  }

  public openVideo(): void {
    this.videoClicked.emit();
  }

  private getThumbnail(imageName: string): void {
    this.video.imageUrl = this._blobService.getVideoThumbnailUrl(
      decodeURIComponent(imageName)
    );
  }

  public onMenuShow(): void {
    this.isMenuShown = true;
  }

  public onMenuHide(): void {
    this.isMenuShown = false;
  }
}
