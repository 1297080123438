<div class="filter-wapper">
  <p-button
    [attr.data-cy]="'filters-button'"
    [label]="'Filters'"
    class="custom-p-button custom-p-button--secondary"
    (click)="op.toggle($event)"
  ></p-button>

  <p-overlayPanel
    #op
    [showCloseIcon]="false"
    (onHide)="onHide()"
    (onShow)="onShow()"
    styleClass="filter-advanced-home"
  >
    <div class="option-filter">
      <div class="label-with-button">
        <p class="filter-label mb-1">{{ METADATA.REGION.LABEL }}</p>
        <div class="button" (click)="resetFilter()" >
          <label class="custom-svg-icon-down">
            <svg-icon key="reset" size="md"></svg-icon>
          </label>
          <label for="Reset" class="button-label ms-2">Reset</label>
        </div>
      </div>
      <p-multiSelect
        [options]="optionRegions"
        [(ngModel)]="selectedRegion"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.REGION.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <p class="filter-label mb-1">{{ METADATA.COUNTRY.LABEL }}</p>
      <p-multiSelect
        [options]="optionCountries"
        [(ngModel)]="selectedCountry"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.COUNTRY.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <p class="filter-label mb-1">{{ METADATA.CUSTOMER.LABEL }}</p>
      <p-multiSelect
        [options]="optionCustomers"
        [(ngModel)]="selectedCustomer"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.CUSTOMER.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <p class="filter-label mb-1">{{ METADATA.PROJECT.LABEL }}</p>
      <p-multiSelect
        [options]="optionProjects"
        [(ngModel)]="selectedProject"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.PROJECT.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <p class="filter-label mb-1">{{ METADATA.RIG_CONTRACTOR.LABEL }}</p>
      <p-multiSelect
        [options]="optionRigContractors"
        [(ngModel)]="selectedRigContractor"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.RIG_CONTRACTOR.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <p class="filter-label mb-1">{{ METADATA.RIG.LABEL }}</p>
      <p-multiSelect
        [options]="optionRigs"
        [(ngModel)]="selectedRig"
        optionLabel="name"
        [placeholder]="METADATA.OPTION.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.RIG.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <p class="filter-label mb-1">{{ METADATA.STATUS.LABEL }}</p>
      <p-multiSelect
        [options]="optionStatuses"
        [(ngModel)]="selectedStatus"
        optionLabel="name"
        [placeholder]="METADATA.STATUS.PLACE_HOLDER"
        class="custom-p-multiselect"
        [style]="{ width: '100%' }"
        [showClear]="true"
        [showHeader]="false"
        [resetFilterOnHide]="true"
        (onClear)="onActionDropdown(METADATA.STATUS.LABEL)"
        (onPanelHide)="onActionDropdown()"
        >
      </p-multiSelect>
    </div>
    <div class="mt-3">
      <div>
        <p-checkbox
          class="custom-p-checkbox"
          [binary]="true"
          inputId="binary_only_active"
          [(ngModel)]="selectedProjectActive"
        ></p-checkbox>
        <label for="binary_only_active" class="filter-label ms-2">{{
          METADATA.ACTIVE_PROJECT.LABEL
        }}</label>
      </div>
      <div *ngIf="false">
        <p-checkbox
          class="custom-p-checkbox"
          [binary]="true"
          inputId="binary_require_attention"
          [(ngModel)]="selectedInterventionLogActive"
        ></p-checkbox>
        <label for="binary_require_attention" class="filter-label mb-1">{{
          METADATA.REQUIRE_ATTENTION.LABEL
        }}</label>
      </div>
    </div>
    <div class="p-dialog-footer d-flex justify-content-end gap-2 mt-4">
      <div class="btn-cancel">
        <p-button
          [attr.data-cy]="'cancel-button'"
          type="button"
          label="Cancel"
          class="custom-p-button custom-p-button--primary"
          (click)="op.toggle($event)"
        ></p-button>
      </div>
      <div>
        <p-button
          [attr.data-cy]="'apply-button'"
          type="button"
          label="Apply"
          class="custom-p-button custom-p-button--secondary"
          (click)="executeFilter(); op.toggle($event)"
        ></p-button>
      </div>
    </div>
    <app-loading [isLoading]="isLoading"></app-loading>
  </p-overlayPanel>
</div>
