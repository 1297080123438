import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  catchError,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Permission } from 'src/app/shared/models/permission.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PermissionGroup } from 'src/app/shared/models/permissionGroup.model';
import { AzureBlobService } from 'src/app/shared/services/azure-blob.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
})
export class UserRoleComponent extends BaseComponent {
  constructor(
    private _activedRoute: ActivatedRoute,
    private _location: Location,
    private _userInfo: UserInfoService,
    private _builder: FormBuilder,
    private _userInfoService: UserInfoService,
    private _notificationService: NotificationService,
    private _blobService: AzureBlobService
  ) {
    super();
  }

  isLoading: boolean = false;

  public fieldNameKeys = {
    role: 'role',
    Permissions: 'Permissions',
    rigRole: 'rigRole',
    wellboreRole: 'wellboreRole',
    intervalRole: 'intervalRole',
    runRole: 'runRole',
    alertRole: 'alertRole',
  };

  public messageErrors: any = {
    required: 'Please fill in the information.',
    whitespace: 'Please fill in the information without white space.',
    maxlength: 'Please enter no more than 50 characters',
  };

  // Variable of Form
  public formEditRoleGroup!: FormGroup;

  user: any;

  roles: any[] = [];

  currentUserRole_Lv1 = AppConstant.ROLES.ENGINEER.label;
  currentUserRole_Lv2 = 'LIVE';

  selectedNode: any;
  selectedNodeString: string = '';

  roleListData: Permission[] = [];

  roleListColumn: any[] = [
    { field: 'moduleName', header: 'Object' },
    { field: 'view', header: 'View' },
    { field: 'add', header: 'Add' },
    { field: 'edit', header: 'Edit' },
    { field: 'delete', header: 'Delete' },
    // { field: 'assign', header: 'Assign' },
    // { field: 'sendMail', header: 'Send Mail' },
    // { field: 'markCompleted', header: 'Mark Completed' },
    // { field: 'reset', header: 'Reset' },
    // { field: 'assign', header: 'Assign' },
  ];

  defaultOrderRole = [
    'Project',
    'Rig Tile',
    'Wellbore',
    'Interval',
    'Run',
    'Alert',
    'Main Page',
    'Alert Management',
    'Team Management',
    'Rig Management',
    'Mailbox Management',
    'Help Center',
  ];

  defaultDisplayRole = [
    'Project',
    'Rig Tile',
    'Wellbore',
    'Interval',
    'Run',
    'Alert',
  ];

  roleData: any;
  isShowResetBtn: boolean = false;

  avatarImageBlob: string = '';

  ENGINEER_DEFAULT_PERMISSION!: PermissionGroup;
  USER_DEFAULT_PERMISSION!: PermissionGroup;


  isViewer: boolean = false;

  onInit(): void {
    this.isLoading = true;

    this._userInfoService.userSubject$.subscribe({
      next: (response) => {
        if (response) {
          this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
        }
        this.loadInitialData();
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }

  loadInitialData() {
    // Trigger reload new avatar form another screen.
    this._userInfoService.reloadUserPermission$.subscribe(
      (isReload: boolean) => {
        if (isReload) {
          this.reloadAvatar();
        }
      }
    );
    this.roles = AppConstant.VRO_ROLES;
    this.loadData();
  }

  reloadAvatar() {
    this.loadData();
  }

  buildForm(indexRole: any, roleListData: Permission[]) {
    this.formEditRoleGroup = this._builder.group({
      [this.fieldNameKeys.role]: [
        { value: indexRole, disabled: true },
        Validators.required,
      ],
      Permissions: this._builder.array(this.initRows(roleListData, indexRole)),
    });

    if (
      indexRole.data === AppConstant.ROLES.ADMIN.label ||
      indexRole.data === AppConstant.ROLES.MANAGER.label ||
      indexRole.data === AppConstant.ROLES.VIEWER.label ||
      this.isViewer
    ) {
      let formPermissions = this.formEditRoleGroup.get(
        this.fieldNameKeys.Permissions
      );
      formPermissions?.disable();
    }

    this.formEditRoleGroup.markAsPristine();
    this.formEditRoleGroup.markAsUntouched();
  }

  initRows(roleListData: Permission[], role: any) {
    let result: any[] = [];

    if (
      role.label === AppConstant.ROLES.ADMIN.label ||
      role.label === AppConstant.ROLES.MANAGER.label ||
      role.label === AppConstant.ROLES.VIEWER.label
    ) {
      for (let i = 0; i < roleListData.length; i++) {
        result.push(
          this._builder.group({
            permissionId: roleListData[i].permissionId,
            moduleName: [roleListData[i].moduleName],
            view: { value: roleListData[i].view || false, disabled: true },
            add: { value: roleListData[i].add || false, disabled: true },
            edit: { value: roleListData[i].edit || false, disabled: true },
            delete: { value: roleListData[i].delete || false, disabled: true },
            assign: { value: roleListData[i].assign || false, disabled: true },
            sendMail: { value: roleListData[i].sendMail, disabled: true },
            markCompleted: { value: roleListData[i].markComplete, disabled: true },
            reset: { value: roleListData[i].reset, disabled: true },
          })
        );
      }
    } else {
      for (let i = 0; i < roleListData.length; i++) {
        if (roleListData[i].moduleName === 'Project') {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: { value: roleListData[i].add, disabled: true },
              edit: roleListData[i].edit,
              delete: { value: roleListData[i].delete, disabled: true },
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        } else if (roleListData[i].moduleName === 'Rig Tile') {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: { value: roleListData[i].add, disabled: true },
              edit: roleListData[i].edit,
              delete: { value: roleListData[i].delete, disabled: true },
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        } else if (roleListData[i].moduleName === 'Wellbore') {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: roleListData[i].add,
              edit: roleListData[i].edit,
              delete: roleListData[i].delete,
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        } else if (roleListData[i].moduleName === 'Interval') {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: { value: roleListData[i].add, disabled: true },
              edit: { value: roleListData[i].edit, disabled: true },
              delete: roleListData[i].delete,
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        } else if (roleListData[i].moduleName === 'Run') {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: { value: roleListData[i].add, disabled: true },
              edit: { value: roleListData[i].edit, disabled: true },
              delete: roleListData[i].delete,
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        } else if (roleListData[i].moduleName === 'Alert') {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: { value: roleListData[i].add, disabled: true },
              edit: { value: roleListData[i].edit, disabled: true },
              delete: { value: roleListData[i].delete, disabled: true },
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        } else {
          result.push(
            this._builder.group({
              permissionId: roleListData[i].permissionId,
              moduleName: [roleListData[i].moduleName],
              view: { value: roleListData[i].view, disabled: true },
              add: { value: roleListData[i].add, disabled: true },
              edit: { value: roleListData[i].edit, disabled: true },
              delete: { value: roleListData[i].delete, disabled: true },
              assign: { value: roleListData[i].assign, disabled: true },
              sendMail: { value: roleListData[i].sendMail, disabled: true },
              markCompleted: { value: roleListData[i].markComplete, disabled: true },
              reset: { value: roleListData[i].reset, disabled: true },
            })
          );
        }
      }
    }
    return result;
  }

  resetPermissions(role: string) {
    this.formEditRoleGroup.markAsDirty();

    switch (role) {
      case AppConstant.ROLES.ENGINEER.label:
        let formPermissions = this.formEditRoleGroup.get(
          this.fieldNameKeys.Permissions
        );
        formPermissions?.patchValue(
          this.USER_DEFAULT_PERMISSION.groupPermission
        );

        this.saveRole(this.formEditRoleGroup, this.roleData, this.user);

        break;
      default:
        break;
    }
  }
  getControls() {
    return (this.formEditRoleGroup.get('Permissions') as FormArray).controls;
  }

  onOptionsSelected(event: any) {
    this.selectedNode = event.node;
    //

    if (event.node.parent === undefined) {
      this.selectedNodeString = `${event.node.label}`;
    } else {
      //
      this.selectedNodeString = `${event.node.parent.label} (${event.node.label})`;
    }
    this.formEditRoleGroup.get('role')?.setValue(this.selectedNode);
  }

  onClearRole(event: any) {
    this.selectedNode = undefined;
    this.selectedNodeString = '';
  }

  // this function using for normal errors messages
  public isInvalidControl(controlName: string): boolean {
    const control = this.formEditRoleGroup.get(controlName);

    //

    if (!control) {
      return false;
    }
    return control.invalid && (control.touched || control.dirty);
  }

  public getErrorByField(controlName: string): string[] {
    const controlValue = this.formEditRoleGroup.get(controlName)?.value;

    const errorObj = this.formEditRoleGroup.get(controlName)?.errors;
    if (!errorObj) {
      return [];
    }
    const errorKeys = Object.keys(errorObj || {});
    if (errorKeys.length === 0) {
      return [];
    }

    const listMsg = errorKeys.reduce((res: string[], key: string) => {
      const msg = this.messageErrors[key];
      res.push(msg);
      return res;
    }, []);

    // get message
    return listMsg;
  }

  backHistory() {
    this._location.back();
  }

  getFirstLetter(userName: string | undefined): string {
    if (!userName) return 'Unknown User Name';

    let arrySplitName = userName.trim().split(' ');
    let userNameLength = arrySplitName.length;

    let firstLetter = arrySplitName[0].charAt(0);
    let secondLetter = arrySplitName[userNameLength - 1].charAt(0);

    return (firstLetter + secondLetter).toLocaleUpperCase() || userName;
  }

  saveRole(formValue: any, originRole: any, user: any) {
    this.isLoading = true;

    let sources: Observable<any>[] = [];
    sources.push(
      this._userInfoService.updatePermission(user.id, {
        groupPermission: formValue.value.Permissions,
      })
    );
    forkJoin(sources).subscribe({
      next: (_) => {
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.SUCCESS,
          header: 'Update Permission',
          content: 'Permission was updated successfully!',
        });

        this.loadData();
      },
      error: (error: any) => {
        console.error(error);
        this._notificationService.setMessage({
          type: AppConstant.MESSAGE_TYPE.WARNING,
          header: 'Info: Update Permissions',
          content: error?.message,
        });
        this.isLoading = false;
      },
    });
  }

  loadData() {
    this.isLoading = true;

    this._activedRoute.paramMap
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError(AppHelper.UtileFunctions.handleError),
        map((params) => params.get('userId')),
        switchMap((id: any) => {
          let originSources: Observable<any>[] = [
            this._userInfoService.getDiscipline(),
            this._userInfo.getUserInfoById(id),
          ];
          return forkJoin(originSources);
        }),
        switchMap(([discipline, user]: any): any => {
          if (discipline.data && user.data) {
            // Setting data for Role Selected
            this.displayDropdown(discipline.data, user.data);
            // Setting data for User
            this.displayMarix(user.data);
            // Setting Avatar
            this.displayAvatar(user.data)
          } 
          return this._userInfoService.getDefaultPermission(user.data.role)
        })
      )
      .subscribe({
        next: (defaultPer: any) => {
          this.USER_DEFAULT_PERMISSION = {
            groupPermission: AppHelper.UtileFunctions.sortRoleByOrder(
              this.defaultOrderRole,
              defaultPer.data,
              'moduleName'
            ),
          };
          this.buildForm(this.roleData, this.roleListData);
          this.isLoading = false;
        },
        error: (error: any) => {
          console.error(error);
          if(error === 'There are no user available!') 
            this._location.back()
          else{
            this._notificationService.setMessage({
              type: AppConstant.MESSAGE_TYPE.WARNING,
              header: 'Info: Update Permissions',
              content: error?.message,
            });
            this.isLoading = false;
          }
        },
      });
  }

  displayMarix(data: any) {
    this.user = data;
    this.roleListData = this.user.permissionList;
    this.roleListData = AppHelper.UtileFunctions.sortRoleByOrder(
      this.defaultOrderRole,
      this.roleListData,
      'moduleName'
    );
    // this.roleListData = this.roleListData.filter(permissionItem => {
    //   return this.defaultDisplayRole.includes(permissionItem.moduleName)
    //   }
    // );
  }

  displayDropdown(discipline: any, user: any) {
    if (discipline.length && user.role) {
      this.roles[0].children = [];
      discipline.forEach((disciplineItem: any) => {
        this.roles[0].children.push({
          label: disciplineItem.disciplineName,
          data: disciplineItem.disciplineId,
          styleClass: 'lowestChild',
          leaf: false,
          parent: 'Engineer',
        });
      });

      if (user) {
        // Need set label of dropdown multi level and Item chooose - Role (ENGINEER role)
        if (user.role === AppConstant.ROLES.ADMIN.label) {
          this.roleData = AppConstant.VRO_ROLES[2];
          this.selectedNodeString = AppConstant.ROLES.ADMIN.label;

          this.isShowResetBtn = false;
        } else if (user.role === AppConstant.ROLES.MANAGER.label) {
          this.roleData = AppConstant.VRO_ROLES[1];
          this.selectedNodeString = AppConstant.ROLES.MANAGER.label;

          this.isShowResetBtn = false;
        } else if (user.role === AppConstant.ROLES.VIEWER.label) {
          this.roleData = AppConstant.VRO_ROLES[3];
          this.selectedNodeString = AppConstant.ROLES.VIEWER.label;

          this.isShowResetBtn = false;
        } else {
          this.ENGINEER_DEFAULT_PERMISSION = {
            groupPermission: user.permissionList,
          };
          let dataDisciplineId = user.discipline.disciplineId;
          let found,
            index_lv_1 = 0,
            index_lv_2 = 0;
          found = AppConstant.VRO_ROLES[0]!.children!.find(
            (item: any, i_Lv2: number) => {
              if (item.data === dataDisciplineId) {
                index_lv_2 = i_Lv2;
                return item.data === dataDisciplineId;
              }
            }
          );
          if (found) {
            this.roleData =
              AppConstant.VRO_ROLES[0]!.children![index_lv_2];
            this.selectedNodeString = `Engineer (${this.roleData.label})`;
          }

          this.isShowResetBtn = !this.isViewer;
        }
      }
    } else {
      this.roles[0].styleClass = 'lowestChild';
      this.roles[0].leaf = false;
    }
  }

  displayAvatar(data: any): Observable<any> {
    if (!data.avatar) {
      return of([]);
    } else {
      this.avatarImageBlob = this._blobService.getUserImage(
        decodeURIComponent(
          AppHelper.StringFunctions.getFileName(data.avatar)
        )
      );
      return of([]);
    }
  }

  onDestroy(): void {}
}
