<div class="rig-list" style="position: relative">
  <app-module-header
    [backPortal]="true"
    backPortalLabel="Back to Projects List"
    headerTitle="Rigs List"
    [buttonFearture]="false"
    buttonFeartureLabel="Nothing Render Because buttonFearture === false"
    [bottomMargin]="true"
    (onClickBack)="backToProjectList()"
  ></app-module-header>

  <form #searchRigForm="ngForm">
    <div class="project__filter rig-list__filter">
      <div class="d-flex">
        <div class="rig-list__filter--input">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              type="text"
              pInputText
              placeholder="Search by Rig Name"
              class="search-box"
              maxlength="50"
              name="searchString"
              ngModel
            />
          </span>
        </div>
      </div>
      <div class="rig-list__filter--counter">
        <span>Total: {{ counterResult }}</span>
      </div>
    </div>

    <p-table
      [value]="rigListData"
      [columns]="rigListColumn"
      [scrollable]="true"
      [resizableColumns]="true"
      styleClass="p-datatable-striped custom-table"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 40px">
          <th
            *ngFor="let col of columns; last as isLast; let i = index"
            [pSortableColumn]="col.field"
            pResizableColumn
            [ngClass]="isLast ? 'th-last' : 'th-default'"
          >
            <ng-container *ngIf="!isLast; then showThNotLast; else showThLast">
            </ng-container>

            <ng-template #showThNotLast>
              <div
                [pTooltip]="col.header"
                tooltipPosition="bottom"
                class="th-content"
              >
                {{ col.header }}
              </div>
              <div class="header-sort-icon">
                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                ></i>

                <i
                  class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                ></i>
              </div>
            </ng-template>
            <ng-template #showThLast></ng-template>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr (click)="executeFilter(rowData.rigJournalId, rowData.rigName)">
          <td class="td-default primary-color">
            <span
              [pTooltip]="rowData.rigName"
              tooltipPosition="bottom"
              (click)="redirectToRigDetail(rowData.rigId, rowData.projectId)"
            >
              {{ rowData.rigName }}
            </span>
          </td>
          <td class="td-default">
            <span [pTooltip]="rowData.projectName" tooltipPosition="bottom">
              {{ rowData.projectName }}
            </span>
          </td>
          <td class="td-default">
            <div class="d-flex" *ngIf="rowData.flagStatistic[0].value !== 0">
              <ng-container
                *ngFor="let flag of rowData.flagStatistic; let i = index"
              >
                <div
                  *ngIf="flag.value > 0"
                  [ngClass]="'rig-list-flag flag--' + flag.key"
                  class="d-flex"
                >
                  <div class="custom-svg-icon-down">
                    <svg-icon key="done" size="md"></svg-icon>
                  </div>
                  <div>{{ flag.value }}</div>
                </div>
              </ng-container>
            </div>

            <div class="d-flex" *ngIf="rowData.flagStatistic[0].value === 0">
              <span></span>
              <!-- <span>N/A</span> -->
            </div>
          </td>
          <td class="td-default" style="cursor: default">
            <div class="d-flex">
              <div
                *ngIf="false"
                class="document-item label-disabled"
                [pTooltip]="'More Information'"
                tooltipPosition="bottom"
              >
                <svg-icon key="contact" size="md"></svg-icon>
              </div>
              <div
                [ngClass]="{
                  'label-disabled': !rowData.communicateProtocalUrl
                }"
                class="document-item"
                (click)="
                  readPDFdocument(
                    rowData.communicateProtocalUrl,
                    'rigcommunicateprotocal',
                    rowData
                  )
                "
                [pTooltip]="'Communicate Protocal'"
                tooltipPosition="bottom"
              >
                <svg-icon key="protocol" size="md"></svg-icon>
              </div>
              <div
                [ngClass]="{ 'label-disabled': !rowData.dataRoutingUrl }"
                class="document-item"
                (click)="
                  readPDFdocument(
                    rowData.dataRoutingUrl,
                    'rigdatarouting',
                    rowData
                  )
                "
                [pTooltip]="'Data Routing'"
                tooltipPosition="bottom"
              >
                <svg-icon key="routing" size="md"></svg-icon>
              </div>
              <div
                [ngClass]="{
                  'label-disabled': !rowData?.interval?.designOfServiceUrl
                }"
                class="document-item"
                (click)="
                  readPDFdocument(
                    rowData.interval.designOfServiceUrl,
                    'intervaldesignofservice',
                    rowData
                  )
                "
                [pTooltip]="'iOneSheet'"
                tooltipPosition="bottom"
              >
                <svg-icon key="ionesheet" size="md"></svg-icon>
              </div>
              <div
                class="document-item"
                [pTooltip]="'Alert Details'"
                tooltipPosition="bottom"
                [ngClass]="{
                  'label-disabled': rowData.isComplete !== false
                }"
                (click)="redirectTo(rowData.rigJournalId)"
              >
                <svg-icon key="alerts" size="md"></svg-icon>
              </div>
            </div>
          </td>
          <td class="td-default">
            <span
              [pTooltip]="rowData.displayRemoteCenter || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData.displayRemoteCenter || "N/A" }}
            </span>
          </td>
          <td class="td-default">
            <span
              [pTooltip]="rowData.displayMailbox || 'N/A'"
              tooltipPosition="bottom"
            >
              {{ rowData.displayMailbox || "N/A" }}
            </span>
          </td>
          <td style="min-width: 50px; max-width: 50px">
            <div class="more-option-table">
              <svg-icon
                class="header-content__title--feature"
                key="dot3hoz"
                size="md"
                (click)="showContextMenu($event, rowData)"
              >
              </svg-icon>
            </div>
          </td>
          <app-menu
            #menu
            [optionList]="getMenuItemsForItem$ | async"
            [appendTo]="'body'"
            [styleClass]="'menu-table-item md-width-menu'"
          ></app-menu>
        </tr>
      </ng-template>
    </p-table>
  </form>
  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</div>
<ng-container *ngIf="displayFormRig">
  <app-home-form-rig
    [displayFormRig]="displayFormRig"
    [rigEditId]="rigEditId"
    [rigJournalEditId]="rigJournalEditId"
    [projectName]="projectNameEdit"
    (hideFormRig)="displayFormRig = $event"
    (requestReloadPage)="requestReloadPage($event)"
  ></app-home-form-rig>
</ng-container>
