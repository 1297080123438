<div class="rig-list" style="position: relative">
  <app-module-header
    [backPortal]="true"
    backPortalLabel="Back to My Rigs"
    [headerTitle]="rigName"
    [buttonFearture]="false"
    buttonFeartureLabel="Nothing Render Because buttonFearture === false"
    [bottomMargin]="false"
    (onClickBack)="backToRig(projectData.id)"
  ></app-module-header>

  <div>
    <div class="tabview-content">
      <div class="tabview-content-wrapper">
        <div class="tabview-content__form">
          <p-tabView
            #tabViewForm
            (onChange)="handleChangeTab($event)"
            [(activeIndex)]="tabIndex"
          >
            <p-tabPanel header="WELLBORES">
              <app-home-rig-item-wellbore
                #tabWellbore
                *ngIf="dataWellboreReady"
                [restoreWellList]="wellList"
                [restoreRigListDataWellbore]="rigListDataWellbore"
                [projectData]="projectData"
                [companyData]="companyData"
                [contractorData]="contractorData"
                [isViewer]="isViewer"
                [isEngineer]="isEngineer"
                (requestChangeTab)="requestChangeTab($event)"
              ></app-home-rig-item-wellbore>
            </p-tabPanel>
            <p-tabPanel header="INTERVALS">
              <app-home-rig-item-interval
                #tabInterval
                *ngIf="dataIntervalReady"
                [rigId]="rigId"
                [restoreWellList]="wellList"
                [restoreRigListDataWellbore]="rigListDataWellbore"
                [restoreRigListDataInterval]="rigListDataInterval"
                [projectData]="projectData"
                [isViewer]="isViewer"
                [isEngineer]="isEngineer"
                ></app-home-rig-item-interval>
                <!-- (update_intervalDropdown)="rigListDataInterval = $event" -->
            </p-tabPanel>
            <p-tabPanel header="RUNS">
              <app-home-rig-item-run
                *ngIf="dataRunReady"
                #tabRun
                [rigId]="rigId"
                [restoreWellList]="wellList"
                [restoreRigListDataWellbore]="rigListDataWellbore"
                [restoreRigListDataInterval]="rigListDataInterval"
                [restoreRigListDataRun]="rigListDataRun"
                [projectData]="projectData"
                [isViewer]="isViewer"
                [isEngineer]="isEngineer"
                (requestChangeTab)="requestChangeTabByTabIndexAndReload($event)"
              ></app-home-rig-item-run>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </div>
  </div>
  <!-- Loading -->
  <app-loading [isLoading]="isLoading"></app-loading>
</div>
