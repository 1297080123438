import { AlertDetailComponent } from './pages/alert/alert-detail/alert-detail.component';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateGuard } from './shared/guards/CanActivateGuard';
import { ErrorComponent } from './pages/error/error.component';
import { HomeProjectListComponent } from './pages/home/home-project-list/home-project-list.component';
import { HomeRigItemComponent } from './pages/home/home-rig-item/home-rig-item.component';
import { HomeRigListComponent } from './pages/home/home-rig-list/home-rig-list.component';
import { AlertComponent } from './pages/intervention/intervention.component';
import { LoginComponent } from './pages/login/login.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { UserRoleComponent } from './pages/user/user-role/user-role.component';
import { UserTeamManagementComponent } from './pages/user/user-team-management/user-team-management.component';
import { AuthGuard } from './shared/guards/AuthGuard';
import { RigManagementComponent } from './pages/rig/rig-management/rig-management.component';
import { HelpCenterComponent } from './pages/help-center/help-center.component';
import { MailboxManagementComponent } from './pages/mailbox/mailbox-management/mailbox-management.component';
import { AlertManagementComponent } from './pages/alert/alert-management/alert-management.component';
import { TestComponent } from './pages/test/test.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'WellCare - Login',
  },
  // {
  //   path: 'test',
  //   component: TestComponent,
  //   title: 'WellCare - Testing',
  // },
  {
    path: 'home',
    component: HomeProjectListComponent,
    title: 'WellCare - Home Page',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  {
    path: 'home/rig/:id',
    component: AlertComponent,
    title: 'WellCare - Alert',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  {
    path: 'home/rig/:id/alert/:alertId',
    component: AlertComponent,
    title: 'WellCare - Edit Alert',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  {
    path: 'home/rig-list/:id',
    component: HomeRigListComponent,
    title: 'WellCare - Rig List',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  // {
  //   path: 'home/rig-journal/:id',
  //   component: HomeRigItemComponent,
  //   title: 'WellCare - Rig Item',
  //   canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  // },
  {
    path: 'home/rig-journal/:id/project/:projectId',
    component: HomeRigItemComponent,
    title: 'WellCare - Rig Item',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  {
    path: 'rig/rig-management',
    component: RigManagementComponent,
    title: 'WellCare - Rig Management',
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard], // <================ Must have MSAL code
  },
  {
    path: 'mailbox/mailbox-management',
    component: MailboxManagementComponent,
    title: 'WellCare - Mailbox Management',
    canActivate: [CanActivateGuard, MsalGuard, AuthGuard], // <================ Must have MSAL code
  },
  {
    path: 'user',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'team-management' },
      {
        path: 'team-management',
        component: UserTeamManagementComponent,
        title: 'WellCare - Team Management',
        canActivate: [CanActivateGuard, MsalGuard, AuthGuard],
      },
      {
        path: 'team-management/:userId',
        component: UserRoleComponent,
        title: 'WellCare - User Permissions',
        canActivate: [CanActivateGuard, MsalGuard, AuthGuard],
      },
    ],
  },
  {
    path: 'help-center',
    component: HelpCenterComponent,
    title: 'WellCare - Help Center',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  {
    path: 'help-center/video/:videoId',
    component: HelpCenterComponent,
    title: 'WellCare - Help Center',
    canActivate: [CanActivateGuard, MsalGuard], // <================ Must have MSAL code
  },
  {
    path: 'alerts/alerts-management',
    component: AlertManagementComponent,
    title: 'WellCare - Alert Management',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: 'home/alert-rig/:rigJournalId',
    component: AlertDetailComponent,
    title: 'WellCare - Alert Detail',
    canActivate: [CanActivateGuard, MsalGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: ErrorComponent,
    title: 'WellCare - Not Found',
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
