<form [formGroup]="createReleaseForm">
    <div
      class="mb-2 flex flex-row gap-1 relative add-new-release"
    >
      <span class="static-text">
        {{ WELLCARE_VERSION }}
      </span>
      <input
        #newRelease
        formControlName="version"
        type="text"
        pInputText
        maxlength="20"
        class="input-text-new-chapter"
        TextInputValidate
        typeCheck="short"
        (input)="onKeyDown($event)"
      />
      <div class="absolute button-confirm-add-chapter">
        <i
          class="pi pi-times mr-2 hightlight-hover"
          (click)="cancelSaveRelease()"
        ></i>
        <i
          class="pi pi-check mr-2 hightlight-hover"
          [ngClass]="{'label-disabled': !isAdmin}"
          (click)="saveRelease()"
          type="submit"
        ></i>
      </div>
    </div>
    <div *ngIf="isInvalidControl('version')">
      <ng-container
        *ngFor="
          let msg of getErrorByField('version')
        "
      >
        <div class="inner-msg-error">
          <span>{{ msg }}</span>
        </div>
      </ng-container>
    </div>
    <div>

    </div>
  </form>