import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ShareModule } from '../../shared/shared.module';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertAdvancedFilterComponent } from './alert-advanced-filter/alert-advanced-filter.component';
import { AlertManagementComponent } from './alert-management/alert-management.component';
import { AlertDetailComponent } from './alert-detail/alert-detail.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { PaginatorModule } from 'primeng/paginator';

const declarations = [
  AlertManagementComponent,
  AlertAdvancedFilterComponent,
  AlertDetailComponent,
];

const imports: any = [
  ShareModule,
  FormsModule,
  PaginatorModule
];

@NgModule({
  imports: [...imports],
  declarations: [...declarations],
  exports: [
    AlertManagementComponent,
    AlertAdvancedFilterComponent,
    AlertDetailComponent,
  ],
  providers: [AlertService, HomeService],
})
export class AlertModule {}
