import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactMethodString',
})
export class StringContactMethodPipe implements PipeTransform {
  /**
   * Transforms the input value into a formatted string representing contact methods.
   * If the input value is empty or undefined, it returns 'N/A'.
   * If the input value can be parsed as JSON, it processes the data to extract and format the contact methods.
   * The contact methods are categorized into parent (Mail and Chat) and child (Phone) groups.
   * The child group is sorted by label in ascending order.
   * The transformed string is constructed based on the categorized and sorted contact methods.
   *
   * Example:
   * [{"contact_id":"C2","label":"C2 - Well Engineer","data":"+1 111-111-111"}]
   * Expected Return ==> Phone (C2)
   *
   * @param value - The input value to be transformed.
   * @returns The transformed string representing contact methods.
   */

  transform(value: any): any {
    if (!value) {
      return 'N/A';
    } else {
      const data = JSON.parse(value);
      if (data) {
        let labelDisplay = '';
        let labelDisplayChild = '';
        let parentArray: any = [];
        let childArray: any = [];
        data.forEach((el: any) => {
          if (el.id) {
            // seperate with element is group Parent (Mail and Chat) or Group Child (Phone)
            parentArray.push(el);
          } else {
            childArray.push(el);
          }
        });

        childArray = childArray.sort(function (a: any, b: any) {
          var keyA = a.label,
            keyB = b.label;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        //

        let email = parentArray.find((el: any) => el.label === 'Email');
        let chat = parentArray.find((el: any) => el.label === 'Chat');

        childArray.forEach((item: any) => {
          labelDisplayChild = labelDisplayChild + item.contact_id + ', ';
        });
        labelDisplayChild = labelDisplayChild.slice(0, -2);
        labelDisplayChild = `Phone (${labelDisplayChild})`;

        if (childArray.length > 0) {
          labelDisplay = labelDisplayChild + ' | ' + labelDisplay;
        }
        if (email) {
          labelDisplay = labelDisplay + email.label + ' | ';
        }
        if (chat) {
          labelDisplay = labelDisplay + chat.label + ' | ';
        }
        return labelDisplay.slice(0, -2);
      } else {
        return 'N/A';
      }
    }
  }
}
