import { Component, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  delay,
  finalize,
  forkJoin,
  mergeMap,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { HomeService } from 'src/app/shared/services/home.service';
import { Location } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { HomeAdvancedFilterComponent } from '../home-advanced-filter/home-advanced-filter.component';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { LoaderLayOut } from 'src/app/shared/services/loaderLayOut.service';
import { DistributionService } from 'src/app/shared/services/distribution.service';
import { CollapseLayoutService } from 'src/app/shared/services/collapseLayout.service';
import { HOME_ADVANCED_FILTER } from 'src/app/shared/metadata/home-advanced-filter.metadata';
import { HomeFilterService } from 'src/app/shared/services/home-filter.service';

@Component({
  selector: 'app-home-project-list',
  templateUrl: './home-project-list.component.html',
  styleUrls: ['./home-project-list.component.scss'],
})
export class HomeProjectListComponent extends BaseComponent {
  isLoading: boolean = false;
  userRole: string = '';

  showMode: 'card' | 'table' = 'card';

  // Variable for Counting number of data we get
  counterResult: number = 0;

  activeRigCard: { rigJournalId: string, rigName: string } = {
    rigJournalId: '',
    rigName: ''
  };

  // Variable of Form
  @ViewChild('advancedFilter') advancedFilter:
    | HomeAdvancedFilterComponent
    | undefined;
  @ViewChild('searchInput') searchInput: any;

  // Variable take data return of Search Functional
  searchResults$: any[] = [];
  searchResultsTable$: any[] = [];

  alertResults$: any[] = [];

  isShowActive: boolean = true;

  msgFromApis: string = '';

  // Variable using for option of add project, add rig, add well and setting feature
  itemFeatures!: MenuItem[];

  // Hide hideItemFeatures When User is Engineer
  hideItemFeatures: boolean = true;

  // Variable for dialog option of add project, add rig, add well and setting feature
  showAddWellbore: boolean = false;

  AppHelper = AppHelper;
  chipsFilter: string[] = [];
  chipFiltersKey: string[] = [];

  payloadFilter: any = { code: 'active', searchString: '' };

  isSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  // <-- auto false and never turn on

  debounceTimer: any;

  private subscriptions: Subscription[] = [];

  METADATA: any = HOME_ADVANCED_FILTER;

  constructor(
    private _homeService: HomeService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _userInfoService: UserInfoService,
    private _interventionService: InterventionService,
    private _loaderLayOut: LoaderLayOut,
    private _homeFilterService: HomeFilterService
  ) {
    super();
  }

  onInit(): void {
    this._route.queryParams.subscribe(params => {
      this.showMode = params.view ? params.view : 'card';
    });

    // Check and get rigCardId for query if click focus is enabled.
    const rigCardIdQuery = this.removeFilterRigJournalId();

    // Get query Collection if exist and remove rigJournalId if have.
    this.payloadFilter = this._homeService.getPayloadFilter();

    let onInitCall = true;
    this.applyFilter(this.payloadFilter, onInitCall, rigCardIdQuery);
  }

  onDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSearchChange(event: any) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      const searchString = event.target.value;
      this.payloadFilter.searchString = searchString.trim();
      this._homeService.setPayloadFilter(this.payloadFilter);
      this.applyFilter(this._homeService.getPayloadFilter());
    }, 700);
  }

  groupByKeyChild(array: any, key: string, child: string) {
    let arr = array.reduce((hash: any, obj: any) => {
      if (obj[key][child] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key][child]]: (hash[obj[key][child]] || []).concat(obj),
      });
    }, {});
    return Object.entries(arr);
  }

  clearAllFilter(): void {
    // Default Search String
    this.searchInput.nativeElement.value = '';

    let temp = Object.assign([], this.chipFiltersKey);
    temp.map((item: any, _index: number) => {
      this.chipsFilter.splice(this.chipsFilter.indexOf(item), 1);
      this.chipFiltersKey.splice(_index, 1);
      delete this.payloadFilter[this.chipFiltersKey[_index]];
    });

    // Update Store Filter Chips
    this.storeValueFilterChip([], []);

    // Default Filter Object
    this.payloadFilter = { code: 'active', searchString: '' };
    this._homeService.setPayloadFilter(this.payloadFilter);
    this.advancedFilter?.loadInitialData();

    // Reload Log Filter
    this._interventionService.resetLogFilter();

    // Fetch latest Data
    this.applyFilter(this._homeService.getPayloadFilter());

    // Remove Focusing/Active Rig Card
    this.setActiveRigCardDefault();

    // Clear rigJournalId Focusing
    this._homeFilterService.setFocusRigCard('');

    // Clear List of Search Rig Card
    this._homeFilterService.setSearchRigCard([]);
  }

  setActiveRigCardDefault(): void {
    this.activeRigCard = {
      rigJournalId: '',
      rigName: ''
    }
  }

  storeValueFilterChip(chipsFilter: string[], chipFiltersKey: string[]) {
    this._homeService.setchipsFilter(chipsFilter);
    this._homeService.setchipFiltersKey(chipFiltersKey);
  }

  rigCardMode(mode: 'card' | 'table') {
    this.showMode = mode;
    this._location.replaceState('home?view=card');
    this.setCounterOfItem(this.searchResults$, this.showMode);
  }

  tableMode(mode: 'card' | 'table') {
    this.showMode = mode;
    this._location.replaceState('home?view=table');
    this.setCounterOfItem(this.searchResults$, this.showMode);

    this.setActiveRigCardDefault();

    this._interventionService.setLogFilterDefault();
    this._interventionService.setInterventionLogsActive('');
  }

  setCounterOfItem(arrayItem: any[], showMode: string): void {
    if (showMode === 'card') {
      this.counterResult = arrayItem.length;
      let countItemCard = 0;
      arrayItem.forEach(el => {
        countItemCard = countItemCard + el[1].length;
      });
      this.counterResult = countItemCard;
    } else {
      this.counterResult = arrayItem.length;
    }
  }

  hideAddWellboreDialog(value: boolean) {
    this.showAddWellbore = value;
  }

  hideAddWellboreDialogAndReload(value: boolean) {
    this.showAddWellbore = value;

    if (!this.payloadFilter) {
      this.payloadFilter = {
        code: 'All',
      };
    }

    this._homeService
      .getProjectsFilter(this.payloadFilter, false)
      .subscribe(data => {
        this.searchResults$ = this.groupByKeyChild(
          data,
          'project',
          'projectId'
        );
        this.searchResults$ =
          AppHelper.UtileFunctions.sortByKeyNestObjectCaseGroup(
            this.searchResults$,
            'project',
            'projectName'
          );

        this.searchResults$.forEach(element => {
          element[1].forEach((item: any) => {
            item.project.countryObject = AppConstant.COUNTRIES.find(country => {
              return country.code === item.project?.country?.toUpperCase();
            });
          });
        });

        this.mappingDataProjectTable();
        // // Update Counter
        this.setCounterOfItem(this.searchResults$, this.showMode);
        this.msgFromApis =
          this.searchResults$.length === 0 ? 'No result found' : '';
      });
  }

  showRequestReloadHomePage(_value: string) {
    this.clearAllFilter();
  }

  showLoadingTheme(loadingStatus: boolean) {
    this.isLoading = loadingStatus;
  }

  loadChipFilter(itemFilter: any) {
    let items = Object.assign({}, itemFilter);
    this.chipFiltersKey = Object.keys(items);
    this.chipsFilter = [];
    Object.keys(items).forEach((item: any) => {
      this.chipsFilter.push(items[item][0]);
    });

    // Update Store Filter Chips
    this.storeValueFilterChip(this.chipsFilter, this.chipFiltersKey);
  }

  removeItemChip(item: any, i: number) {
    delete this.payloadFilter[this.chipFiltersKey[i]];
    this._homeService.setPayloadFilter(this.payloadFilter);
    this.applyFilter(this._homeService.getPayloadFilter());

    // this.chipsFilter.splice(i, 1);
    // this.chipFiltersKey.splice(i, 1);

    // Update Store Filter Chips
    this.storeValueFilterChip(this.chipsFilter, this.chipFiltersKey);

    // Remove Selected
    let inputOptions = 'NAME_OF_DROPDOWN_WILL_RESET'
    switch (this.chipFiltersKey[i]) {
      case 'region': {
        inputOptions = this.METADATA.REGION.LABEL;
        break;
      }
      case 'country': {
        inputOptions = this.METADATA.COUNTRY.LABEL;
        break;
      }
      case 'customer': {
        inputOptions = this.METADATA.CUSTOMER.LABEL;
        break;
      }
      case 'project': {
        inputOptions = this.METADATA.PROJECT.LABEL;
        break;
      }
      case 'contractor': {
        inputOptions = this.METADATA.RIG_CONTRACTOR.LABEL;
        break;
      }
      case 'rig': {
        inputOptions = this.METADATA.RIG.LABEL;
        break;
      }
      case 'status': {
        inputOptions = this.METADATA.STATUS.LABEL;
        break;
      }
      case 'all': {
        break;
      }
    }
    const isRemoveCache: boolean = true; // Remove
    this.advancedFilter?.removeChipAndGetNew(inputOptions, isRemoveCache);
  }

  loadPayloadFilter(event: any) {
    // Get current Focus Rig // Behavior Items 299017
    const rigCardIdQuery = this.getFilterRigJournalId();

    this.payloadFilter = Object.assign({}, event);
    this.payloadFilter = {
      ...this.payloadFilter,
      searchString: this.searchInput.nativeElement.value.trim(),
    };

    this._homeService.setPayloadFilter(this.payloadFilter);
    this.applyFilter(this._homeService.getPayloadFilter(), false, rigCardIdQuery);
  }

  mappingDataProjectTable() {
    this.searchResultsTable$ = this.searchResults$.map(item => {
      return {
        projectId: item[1][0].project.projectId || '',
        projectName: item[1][0].project.projectName || '',
        projectOriginalName: item[1][0].project.projectOriginalName || '',
        projectCurrentName: item[1][0].project.projectCurrentName || '',
        company:
          item[1][0].policy.policyCurrentName ||
          item[1][0].policy.policyOriginalName,
        policyCurrentName: item[1][0].policy.projectCurrentName || '',
        policyOriginalName: item[1][0].policy.policyOriginalName || '',
        country: item[1][0].project.country || '',
        countryName: item[1][0].project.countryObject?.name || '',
        region: item[1][0].project.region || '',
        block: item[1][0].project.block || '',
        lease: item[1][0].project.lease || '',
        field: item[1][0].project.field || '',
        timezone: item[1][0].project.timezone || '',
        curUnit: item[1][0].project.curUnit || '',
      };
    });
  }

  applyFilter(filterPayload: any, onInitCall?: boolean, rigCardIdQuery?: string): void {
    of(null)
      .pipe(
        tap(() => {
          this.isLoading = true;
          this._interventionService.setLoading(true);
          // this.isSkeleton$.next(true); <--temporary comment

          this._loaderLayOut.setLoadingStatus('ProjectListAPI', false);
          this._loaderLayOut.setLoadingStatus('InterventionListAPI', false);
        }),
        switchMap(() => {
          return forkJoin([
            this._homeService.getProjectsFilter(filterPayload, false),
            this._interventionService.getInterventionLogsFilter(
              filterPayload,
              0,
              AppConstant.ITEMS_PER_PAGE
            ),
          ]).pipe(
            finalize(() => {
              // ==
              this._loaderLayOut.setLoadingStatus('ProjectListAPI', true);
              this._loaderLayOut.setLoadingStatus('InterventionListAPI', true);

              this._interventionService.setLoading(false);
              this.isLoading = false;
            }),
            mergeMap(([project, alert]: any) => {
              this.showDataAlertLogs(alert);
              // this.showDataRigCard(project);

              return of(project)
                .pipe(
                  delay(1), // <-- simulate get API to trigger refresh Filter
                  finalize(() => {
                    // this.isSkeleton$.next(false);  <--temporary comment
                  })
                );
            })
          );
        })
      )
      .subscribe((fullCallProject: any) => {
        this.showDataRigCard(fullCallProject, onInitCall, rigCardIdQuery);
        this.showSettingMenu();
        this.showSearchString(filterPayload, onInitCall);
        this.setCheckBoxFilter(filterPayload);

        this.passingResultSearch(fullCallProject, filterPayload);
      });
  }

  passingResultSearch(fullCallProject: any, filterPayload: any) {
    // Store List of Search Rig Card
    if (filterPayload.searchString) {
      this._homeFilterService.setSearchRigCard(fullCallProject.map((item: any) => item.rigJournalId))
      this.advancedFilter?.prepairDataDropdownWithdata()
    } else {
      this._homeFilterService.setSearchRigCard([]);
      this.advancedFilter?.prepairDataDropdownWithdata()
    }
  }

  setCheckBoxFilter(filterPayload: any) {
    // Check and store Checkbox Both Active/Inactive
    this.isShowActive = (this.payloadFilter?.code === 'active');
  }

  showSearchString(filterPayload: any, onInitCall: boolean | undefined) {
    if (filterPayload && filterPayload?.searchString && onInitCall && this.searchInput) {
      this.searchInput.nativeElement.value = filterPayload.searchString;
    }
  }

  showFilterChip() {
    this.chipsFilter = this._homeService.getchipsFilter();
    this.chipFiltersKey = this._homeService.getchipFiltersKey();

    for (let i = 0; i < this.chipFiltersKey.length; i++) {
      this.advancedFilter?.setSelectedOption(this.chipFiltersKey[i], this.payloadFilter[this.chipFiltersKey[i]]);
    }
  }

  showSettingMenu() {
    const user = this._userInfoService.getUserInfoSubject();

    if (user && user.id && user.role) {
      this.userRole = user.role;
      if (
        user.role === AppConstant.ROLES.ADMIN.label ||
        user.role === AppConstant.ROLES.VIEWER.label
      ) {
        this.hideItemFeatures = false;
        this.itemFeatures = [
          {
            items: [
              {
                label: 'Add Wellbore',
                icon: 'c-icons wellbore-icon',
                command: () => {
                  this.showAddWellbore = true;
                },
              },
            ],
          },
        ];
      } else {
        this.hideItemFeatures = true;
      }
    }
  }

  showDataAlertLogs(alertResponse: { items: any; total: number }) {
    const { items, total } = alertResponse;
    // Show Data for Alert Logs
    // =================================================================
    this.alertResults$ = items;
    // Handle Filter Log
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
  }

  showDataRigCard(projectList: any, onInitCall?: boolean, rigCardIdQuery?: string) {
    const project = projectList;
    // Show Data for Project
    // =================================================================
    this.searchResults$ = this.groupByKeyChild(project, 'project', 'projectId');
    this.searchResults$ = AppHelper.UtileFunctions.sortByKeyNestObjectCaseGroup(
      this.searchResults$,
      'project',
      'projectName'
    );

    this.searchResults$.forEach(element => {
      element[1].forEach((item: any) => {
        item.project.countryObject = AppConstant.COUNTRIES.find(country => {
          return country.code === item.project?.country?.toUpperCase();
        });
      });
    });

    this.mappingDataProjectTable();

    this.searchResults$.forEach((project: any) => {
      project[1] = this.AppHelper.UtileFunctions.filterAndMultiply(project[1]);
    });

    // Update Counter
    this.setCounterOfItem(this.searchResults$, this.showMode);
    this.msgFromApis =
      this.searchResults$.length === 0 ? 'No result found' : '';
    // =================================================================

    if (onInitCall) {
      // Show Filter Chip if Exists
      this.showFilterChip();
    }

    if (rigCardIdQuery && projectList.length) {
      const rigCard = projectList.find((item: any) => item.rigJournalId === rigCardIdQuery);
      this.activeRigCard.rigJournalId = rigCard?.rigJournalId;
      this.activeRigCard.rigName = rigCard?.rig?.rigName

      if (this.activeRigCard.rigJournalId && this.activeRigCard.rigName) {
        // Query by Rig Card

        this._interventionService.setInterventionLogs([]);
        this._interventionService.setInterventionLogsTotal(0);

        let payloadFilterLog = this.addFilterRigJournalId(this.activeRigCard.rigJournalId);

        // Set TagName/Filter Log of Recent Alert Logs
        this._interventionService.setLogFilter({
          rigJournalId: this.activeRigCard.rigJournalId,
          rigName: this.activeRigCard.rigName
        });

        // Call API get Alert Log with payload filter
        this.filterAlertLog(payloadFilterLog);
      }
    }
  }

  addFilterRigJournalId(rigJournalId: string) {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    return this._homeService.getPayloadFilter();
  }

  removeFilterRigJournalId(): string {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    const id = payloadFilterLog?.rigJournalId && payloadFilterLog?.rigJournalId[0];

    delete payloadFilterLog.rigJournalId;
    this._homeService.setPayloadFilter(payloadFilterLog);

    return id || '';
  }

  getFilterRigJournalId(): string {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    const id = payloadFilterLog?.rigJournalId && payloadFilterLog?.rigJournalId[0];
    return id || '';
  }

  filterAlertLog(filterPayload: any) {
    of(null).pipe(
      tap(() => {
        // Turn on Loading Alert Log
        this._interventionService.setLoading(true);
      }),
      switchMap(() => {
        return this._interventionService.getInterventionLogsFilter(filterPayload, 0, AppConstant.ITEMS_PER_PAGE).pipe(
          finalize(() => this._interventionService.setLoading(false))
        )
      })
    ).subscribe((alert: any) => {
      this.showDataAlertLogsFromRigCard(alert);
    });
  }

  showDataAlertLogsFromRigCard(alertResponse: { items: any, total: number }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    setTimeout(() => {
      this._interventionService.setTriggerRigCard(false);
    }, 300);
  }

}
