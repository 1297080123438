import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable, catchError, forkJoin, of, finalize, tap, switchMap } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MAILBOX_ADVANCED_FILTER } from 'src/app/shared/metadata/mailbox-advanced-filter.metadata';
import { MailBoxService } from 'src/app/shared/services/mailbox.service';
import { AppHelper } from 'src/app/shared/utilities/app.helper';

@Component({
  selector: 'app-mailbox-advanced-filter',
  templateUrl: './mailbox-advanced-filter.component.html',
  styleUrls: ['./mailbox-advanced-filter.component.scss'],
})
export class MailboxAdvancedFilterComponent extends BaseComponent {
  @Output() chipFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();

  disciplinesOption: any = [];
  remoteCentersOption: any = [];

  restoreDisciplinesOption: any = [];
  restoreRemoteCentersOption: any = [];

  selectedDiscipline: any;
  selectedRemoteCenter: any;

  cacheSlectedOptionApplied: any = {};

  METADATA: any;
  isLoading: boolean = false;

  constructor(private _mailBoxService: MailBoxService) {
    super();
  }

  onInit(): void {
    this.loadInitialData();
  }

  loadInitialData() {
    this.METADATA = MAILBOX_ADVANCED_FILTER;

    const disciplines = this._mailBoxService
      .getDiscipline()
      .pipe(catchError((err) => of([])));

    const remoteCenters = this._mailBoxService
      .getRemoveCenter()
      .pipe(catchError((err) => of([])));

    const mailBoxFilterSource: Observable<any>[] = [disciplines, remoteCenters];

    const loadInitialData$ = of(null)
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() => forkJoin(mailBoxFilterSource)),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: ([disciplines, remoteCenters]) => {
          this.disciplinesOption = disciplines.filter((discipline: any) => {
            return discipline.mailboxList.length > 0; // Exist in mailbox list;
          });

          this.remoteCentersOption = remoteCenters.filter((mailbox: any) => {
            return mailbox.mailboxList.length > 0; // Exist in mailbox list;
          });

          this.disciplinesOption = AppHelper.UtileFunctions.sortArrayBykey(
            this.disciplinesOption,
            'disciplineName'
          );
          this.remoteCentersOption = AppHelper.UtileFunctions.sortArrayBykey(
            this.remoteCentersOption,
            'remoteCenterName'
          );

          this.restoreDisciplinesOption = [...this.disciplinesOption];
          this.restoreRemoteCentersOption = [...this.remoteCentersOption];
        },
      });
  }

  executeFilter() {
    let payloadChip: any = {};
    let payloadChipDisciplines: any = [];
    let payloadChipRemoteCenters: any = [];

    (this.selectedDiscipline || []).length > 1
      ? payloadChipDisciplines.push(`Multiple discipline`)
      : (this.selectedDiscipline || []).length == 1
      ? payloadChipDisciplines.push(this.selectedDiscipline[0].disciplineName)
      : '';

    (this.selectedRemoteCenter || []).length > 1
      ? payloadChipRemoteCenters.push(`Multiple remote center`)
      : (this.selectedRemoteCenter || []).length == 1
      ? payloadChipRemoteCenters.push(
          this.selectedRemoteCenter[0].remoteCenterName
        )
      : '';

    payloadChip = {
      discipline: payloadChipDisciplines,
      remoteCenter: payloadChipRemoteCenters,
    };

    if (!(this.selectedDiscipline || []).length) {
      delete payloadChip.discipline;
    }
    if (!(this.selectedRemoteCenter || []).length) {
      delete payloadChip.remoteCenter;
    }

    // payload cal API
    let payload: any = {};
    let payloadDiscipline: any = [];
    let payloadRemoteCenter: any = [];

    (this.selectedDiscipline || []).length
      ? this.selectedDiscipline.forEach((item: any) => {
          payloadDiscipline.push(item.disciplineId);
        })
      : '';
    (this.selectedRemoteCenter || []).length
      ? this.selectedRemoteCenter.forEach((item: any) => {
          payloadRemoteCenter.push(item.remoteCenterId);
        })
      : '';

    payload = {
      discipline: payloadDiscipline,
      remoteCenter: payloadRemoteCenter,
    };

    this.chipFilter.emit(Object.assign({}, payloadChip));
    this.filter.emit(Object.assign({}, payload));

    this.cacheSlectedOptionApplied.discipline = this.selectedDiscipline;
    this.cacheSlectedOptionApplied.remoteCenter = this.selectedRemoteCenter;
  }

  onHide(event: any) {
    this.selectedDiscipline = this.cacheSlectedOptionApplied.discipline;
    this.selectedRemoteCenter = this.cacheSlectedOptionApplied.remoteCenter;
  }

  onResetFilter(type: string) {
    switch (type) {
      case 'all':
        this.selectedDiscipline = null;
        this.selectedRemoteCenter = null;

        this.cacheSlectedOptionApplied = {
          discipline: null,
          remoteCenter: null,
        };
        this.loadInitialData();
        break;
      case 'discipline':
        this.selectedDiscipline = null;
        this.cacheSlectedOptionApplied.discipline = null;
        this.loadDataDiscipline();
        break;

      case 'remoteCenter':
        this.selectedRemoteCenter = null;
        this.cacheSlectedOptionApplied.remoteCenter = null;
        this.loadDataRemoteCenter();
        break;

      default:
        break;
    }
  }

  onResetInitial() {
    this.loadInitialData();
  }

  selectedOptionChange(event: any, selectedItem: any, typeItem: any) {
    switch (typeItem) {
      case this.METADATA.REMOTE_CENTER.LABEL:
        break;
      default:
        break;
    }
  }

  selectedOptionClear(typeItem: any) {
    switch (typeItem) {
      case this.METADATA.DISCIPLINE.LABEL: {
        if (this.disciplineDropdown!.overlayVisible === false) {
          this.selectedDiscipline = [];
          this.previewDiscipline = [];
          this.loadDataRemoteCenter();
        }
        break;
      }
      case this.METADATA.REMOTE_CENTER.LABEL: {
        if (this.remoteCenterDropdown!.overlayVisible === false) {
          this.selectedRemoteCenter = [];
          this.previewRemoteCenter = [];
          this.loadDataDiscipline();
        }
        break;
      }
      default:
        break;
    }
  }

  previewDiscipline: any[] = [];
  isCallDataRemoteCenter: boolean = false;

  previewRemoteCenter: any[] = [];
  isCallDataDiscipline: boolean = false;

  @ViewChild('disciplineDropdown') disciplineDropdown?: any;
  @ViewChild('remoteCenterDropdown') remoteCenterDropdown?: any;

  onPanelHideRemoteCenterDropdown() {
    const remoteCenterData = this.selectedRemoteCenter || [];

    const isMatch = AppHelper.UtileFunctions.arraysMatch(
      this.previewRemoteCenter || [],
      remoteCenterData || []
    );

    if (isMatch) {
      this.isCallDataDiscipline = false; // Dont need to reload Table because dropdown value dont change
    } else {
      this.isCallDataDiscipline = true;
      this.previewRemoteCenter = [...remoteCenterData];
    }

    if (this.isCallDataDiscipline) {
      this.loadDataDiscipline();
    }
  }

  onPanelHideDisciplineDropdown() {
    const disciplineData = this.selectedDiscipline || [];

    const isMatch = AppHelper.UtileFunctions.arraysMatch(
      this.previewDiscipline || [],
      disciplineData || []
    );

    if (isMatch) {
      this.isCallDataRemoteCenter = false; // Dont need to reload Table because dropdown value dont change
    } else {
      this.isCallDataRemoteCenter = true;
      this.previewDiscipline = [...disciplineData];
    }

    if (this.isCallDataRemoteCenter) {
      this.loadDataRemoteCenter();
    }
  }

  loadDataDiscipline() {
    let remoteCenterData = this.selectedRemoteCenter || [];
    let newDataDisciplineOption = [...this.restoreDisciplinesOption];

    if (remoteCenterData.length !== 0) {
      this.disciplinesOption = this.restoreDisciplinesOption.filter(
        (item: any) => {
          return remoteCenterData.some((input: any) => {
            return item.remoteCenterList.some(
              (remote: any) => remote.remoteCenterId === input.remoteCenterId
            );
          });
        }
      );
    } else {
      this.disciplinesOption = this.restoreDisciplinesOption;
    }
  }
  loadDataRemoteCenter() {
    let disciplineData = this.selectedDiscipline || [];
    let newDataRemoteCentersOption = [...this.restoreRemoteCentersOption];

    if (disciplineData.length !== 0) {
      this.remoteCentersOption = this.restoreRemoteCentersOption.filter(
        (item: any) => {
          return disciplineData.some((input: any) => {
            return item.disciplineList.some(
              (discipline: any) =>
                discipline.disciplineId === input.disciplineId
            );
          });
        }
      );
    } else {
      this.remoteCentersOption = this.restoreRemoteCentersOption;
    }
  }

  onDestroy(): void {}
}
