import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Observable, forkJoin, map } from 'rxjs';

export enum TRANSLATION_SCOPES {
  DATA_FIELDS = 'data-fields',
  EVENTS = 'events',
  RISKS = 'risks',
  CURRENT_OPERATION = 'current-operation',
  FLAGS = 'flags',
  MONTHS = 'months',
}

export type TranslationOptions = {
  separator?: string;
  scope?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private cachedTranslations: { [key: string]: { [lang: string]: any } } = {};
  constructor(private http: HttpClient) { }

  loadTranslations(scopes: string[], languages: string[]): Observable<any> {
    const requests: Observable<any>[] = [];
    for (const scope of scopes) {
      if (!this.cachedTranslations[scope]) {
        this.cachedTranslations[scope] = {};
      }

      for (const lang of languages) {
        if (!this.cachedTranslations[scope][lang]) {
          try {
            const request = this.http.get(`assets/data/i18n/${scope}.${lang}.json`).pipe(
              map((translation: any) => {
                this.cachedTranslations[scope][lang] = translation;
              })
            )            
            requests.push(request);
          } catch (error) {
            console.error(`Error loading translation for scope: ${scope}, language: ${lang}`, error);
          }
        }
      }
    }

    return forkJoin(requests);
  }

  async loadTranslationsAsync(scopes: string[], languages: string[]): Promise<any> {
    return this.loadTranslations(scopes, languages).toPromise();
  }

  getCachedTranslations() {
    return this.cachedTranslations;
  }

  translate(text: string, language: string, opts?: TranslationOptions) {
    if (opts?.scope) {      
      let res = get(this.cachedTranslations, [opts.scope, language, text])      
      if (res) return res;

      // console.log(`not found '${text}' - language: ${language} - scope: ${opts?.scope}`)
      res = get(this.cachedTranslations, [opts.scope, 'en', text])
      if (res) return res;
    }
    return text;
  }

  getScopeDict(language: string, opts?: TranslationOptions) {
    if (!opts?.scope) return {};
    return get(this.cachedTranslations, [opts.scope, language])      
  }

}
