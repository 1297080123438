import { TabView } from 'primeng/tabview';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, map, switchMap, takeUntil, finalize, tap, of } from 'rxjs';

import { AppHelper } from 'src/app/shared/utilities/app.helper';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Component, ViewChild } from '@angular/core';
import { HomeService } from 'src/app/shared/services/home.service';
import { HomeRigItemRunComponent } from '../home-rig-item-run/home-rig-item-run.component';
import {
  DrillingContractors,
  ProjectData,
  RigInterval,
  RigRun,
  RigWellbore,
  WellboreList,
  WellList,
} from 'src/app/shared/interface/rig.interface';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { InterventionService } from 'src/app/shared/services/intervention.service';
import { HomeRigItemWellboreComponent } from '../home-rig-item-wellbore/home-rig-item-wellbore.component';
import { HomeRigItemIntervalComponent } from '../home-rig-item-interval/home-rig-item-interval.component';
@Component({
  selector: 'app-home-rig-item',
  templateUrl: './home-rig-item.component.html',
  styleUrls: ['./home-rig-item.component.scss'],
})
export class HomeRigItemComponent extends BaseComponent {
  isLoading: boolean = false;

  rigName: string = 'Rig Name';
  rigId: string = '';
  counterResult: number = 0;

  tabView = ['wellbore', 'interval', 'run'];
  tab = 'wellbore';
  tabIndex = 0;

  // for wellbore tab
  rigListDataWellbore!: RigWellbore[];
  rigListDataInterval!: RigInterval[];
  rigListDataRun!: RigRun[];

  projectData!: ProjectData;
  companyData: any;
  contractorData!: DrillingContractors;
  wellList!: WellList[];
  wellboreList!: WellboreList[];

  // flag loading done tab
  dataWellboreReady: boolean = false;
  dataIntervalReady: boolean = false;
  dataRunReady: boolean = false;

  @ViewChild('tabWellbore') tabWellbore!: HomeRigItemWellboreComponent;
  @ViewChild('tabInterval') tabInterval!: HomeRigItemIntervalComponent;
  @ViewChild('tabRun') tabRun!: HomeRigItemRunComponent;
  @ViewChild('tabViewForm') tabViewForm!: TabView;

  isViewer: boolean = false;
  isEngineer: boolean = false;

  constructor(
    private _homeService: HomeService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _userInfoService: UserInfoService,
    private _interventionService: InterventionService
  ) {
    super();
  }

  onInit(): void {
    this.isLoading = true;

    this._userInfoService.userSubject$
      .pipe(tap(() => this._interventionService.setLoading(true)))
      .subscribe({
        next: (response) => {
          if (response) {
            this.isViewer = response.role === AppConstant.ROLES.VIEWER.label;
            this.isEngineer =
              response.role === AppConstant.ROLES.ENGINEER.label;
            this.loadInitialData();
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.error(error);
        },
      });
  }

  loadInitialData() {
    this._route.queryParams.subscribe((params) => {
      this.tab = params.tab ? params.tab : 'wellbore';
    });

    const index = this.tabView.findIndex((element) => element === this.tab);
    this.tabIndex = index === -1 ? 0 : index;

    this._route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        map((params) => {
          return { id: params.get('id'), projectId: params.get('projectId') };
        }),
        switchMap(({ id, projectId }) => {
          this.rigId = id || '';
          return forkJoin([
            this._homeService.getWellbores(id || '', projectId || ''),
            this._homeService.getIntervals(id || ''),
            this._homeService.getRuns(id || '')
          ]);
        }),
        switchMap((res: any) => {
          if (res) {
            const [wellbores, intervals, runs] = res;

            if (wellbores.message === 'User not granted to permission!') {
              this._router.navigate(['/home']);
            }
            //
            if (wellbores.message === 'There is no item available!') {
              this._router.navigate(['/home']);
            }

            if (wellbores.data && wellbores.data.length) {
              // Show header rigName
              this.rigName = wellbores.data[0].rigName;

              // Need to check and show internvetion log of rigcard have wellbore actively
              const activeWellbore = wellbores.data.find(
                (welbore: any) => welbore.wellboreIsActive
              );

              let payloadFilterLog = AppConstant.DEFAULT_HOME_FILTER;
              if (activeWellbore) {
                const rigJournalId = activeWellbore.rigJournalId;
                const rigName = activeWellbore.rigName;

                payloadFilterLog = this.addFilterRigJournalId(rigJournalId);    
                this._interventionService.setLogFilter({
                  rigJournalId: rigJournalId,
                  rigName: rigName
                });

              } else {
                const rigJournalId = wellbores.data[0].rigJournalId
                const rigName = wellbores.data[0].rigName

                payloadFilterLog = this.addFilterRigJournalId(rigJournalId);
                this._interventionService.setLogFilter({
                  rigJournalId: rigJournalId,
                  rigName: rigName
                });
              }

              return forkJoin([
                of(wellbores),
                of(intervals),
                of(runs),
                this._interventionService.getInterventionLogsFilter(payloadFilterLog, 0, AppConstant.ITEMS_PER_PAGE),
              ]);
            } else {
              return of([]);
            }
          }
          return of(null);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        const [wellbores, intervals, runs, alertLogs] = res;

        if (wellbores.data && wellbores.data.length) {
          this.rigListDataWellbore = wellbores.data;
          this.projectData = wellbores.project;
          this.companyData = wellbores.policy;
          this.contractorData = wellbores.contractor;
          if ((wellbores.wellArray || []).length) {
            this.wellList = AppHelper.UtileFunctions.uniqBy(
              wellbores.wellArray,
              (key: any) => key.id
            );
            this.wellList = this.wellList.map((well) => {
              well.name = well.currentName ?? well.originalName;
              return well;
            });
          }
          this.dataWellboreReady = true;
        }
        if (intervals.data) {
          this.rigListDataInterval = intervals.data;
          this.dataIntervalReady = true;
        }
        if (runs.data) {
          this.rigListDataRun = runs.data ? Object.assign([], runs.data) : [];
          this.dataRunReady = true;
        }

        // Set Intervention Log Filter
        this.showDataAlertLogs(alertLogs);
        this.isLoading = false;
      });
  }

  showDataAlertLogs(alertResponse: { items: any, total: number  }) {
    const { items, total } = alertResponse;
    // Set Alert Logs
    this._interventionService.setInterventionLogs(items);
    this._interventionService.setInterventionLogsTotal(total);
    this._interventionService.setLoading(false);
  }

  backToRig(projectId: string) {
    this._router.navigate([`home/rig-list/${projectId}`]);
  }

  // Get index of tabview to show dialog close suitable
  handleChangeTab(tabEvent: any) {
    // Turn on Parent Loading
    // this.isLoading = true;

    // Checking which tab is click to load data only this tab
    /*
      0: Wellbore Tab
      1: Interval Tab
      2: Run Tab
    */

    this.getDataSettingPage().pipe(
      takeUntil(this.destroy$),
      switchMap((res: any) => {
        if (res) {
          const [wellbores, intervals, runs] = res;

          if (wellbores.message === 'User not granted to permission!') {
            this._router.navigate(['/home']);
          }
          //
          if (wellbores.message === 'There is no item available!') {
            this._router.navigate(['/home']);
          }
          
          if (wellbores.data) {
            this.rigName = wellbores.data[0].rigName;
    
            // Need to check and show internvetion log of rigcard have wellbore actively
            const activeWellbore = wellbores.data.find(
              (welbore: any) => welbore.wellboreIsActive
            );

            let payloadFilterLog = AppConstant.DEFAULT_HOME_FILTER;
            if (activeWellbore) {
              const rigJournalId = activeWellbore.rigJournalId;
              payloadFilterLog = this.addFilterRigJournalId(rigJournalId);    
            } else {
              const rigJournalId = wellbores.data[0].rigJournalId
              payloadFilterLog = this.addFilterRigJournalId(rigJournalId);
            }

            return forkJoin([
              of(wellbores),
              of(intervals),
              of(runs),
              of(null),
            ]);
            // this._interventionService.getInterventionLogsFilter(payloadFilterLog, 0, AppConstant.ITEMS_PER_PAGE),
          } else {
            return of([]);
          }
        }
        else {
          return of(null);
        }
      })
    )
    .subscribe((res: any) => {
      const [wellbores, intervals, runs, alertLogs] = res;

      if (wellbores.data) {
        this.rigListDataWellbore = wellbores.data;
        this.projectData = wellbores.project;
        this.companyData = wellbores.policy;
        this.contractorData = wellbores.contractor;
        if ((wellbores.wellArray || []).length) {
          this.wellList = AppHelper.UtileFunctions.uniqBy(
            wellbores.wellArray,
            (key: any) => key.id
          );
        }
        this.dataWellboreReady = true;

        if (intervals.data) {
          this.rigListDataInterval = intervals.data;
          this.dataIntervalReady = true;
        } else {
          this.rigListDataInterval = [];
          this.rigListDataRun = [];
        }

        if (runs.data) {
          this.rigListDataRun = runs.data;
          this.dataRunReady = true;
        } else {
          this.rigListDataRun = [];
        }
      } else {
        this.rigListDataRun = [];
        this.rigListDataInterval = [];
        this.rigListDataWellbore = [];
      }

      // Set Intervention Log Filter
      // this.showDataAlertLogs(alertLogs);

      let tab = 'wellbore';
      switch (tabEvent.index) {
        case 0:
          tab = 'wellbore';
          this.tabWellbore.restoreWellList = [...this.wellList];
          this.tabWellbore.restoreRigListDataWellbore = [
            ...this.rigListDataWellbore,
          ];
          this.tabWellbore.bindingWell();
          break;
        case 1:
          tab = 'interval';
          this.tabInterval.restoreWellList = [...this.wellList];
          this.tabInterval.restoreRigListDataWellbore = [
            ...this.rigListDataWellbore,
          ];
          this.tabInterval.restoreRigListDataInterval = [
            ...this.rigListDataInterval,
          ];
          this.tabInterval.bindingWellbore();
          break;
        case 2:
          tab = 'run';
          this.tabRun.restoreWellList = [...this.wellList];
          this.tabRun.restoreRigListDataWellbore = [
            ...this.rigListDataWellbore,
          ];
          this.tabRun.restoreRigListDataInterval = [
            ...this.rigListDataInterval,
          ];
          this.tabRun.restoreRigListDataRun = [...this.rigListDataRun];
          this.tabRun.bindingWellbore();
          break;
        default:
          this.isLoading = false;
          break;
      }

      this._router.navigate([], {
        relativeTo: this._route,
        queryParams: { tab: tab },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    })
  }

  getDataSettingPage() {
    return this._route.paramMap.pipe(
      takeUntil(this.destroy$),
      map((params) => {
        return { id: params.get('id'), projectId: params.get('projectId') };
      }),
      tap(() => (this.isLoading = true)),
      switchMap(({ id, projectId }) => {
        this.rigId = id || '';

        return forkJoin([
          this._homeService.getWellbores(id || '', projectId || ''),
          this._homeService.getIntervals(id || ''),
          this._homeService.getRuns(id || ''),
        ]).pipe(finalize(() => (this.isLoading = false)));
      })
    );
  }

  requestChangeTab(arrayRunInfo: any[]) {
    this.tabIndex = arrayRunInfo[0];

    setTimeout(() => {
      if (this.tabViewForm.tabs[2]._selected) {
        //
        this.tabRun.openEditRun(
          arrayRunInfo[1],
          true,
          arrayRunInfo[2], //return rigID
          arrayRunInfo[3], // return wellboreID,
          this.rigListDataRun,
          arrayRunInfo[4] // return wellboreName
        );
      }
      this.isLoading = false;
    }, 100);
  }

  requestChangeTabByTabIndexAndReload(tabindex: number) {
    this.isLoading = true;

    this.tabIndex = tabindex;

    this.dataWellboreReady = false;
    this.dataIntervalReady = false;
    this.dataRunReady = false;

    this.rigListDataWellbore = [];
    this.rigListDataInterval = [];
    this.rigListDataRun = [];

    this.wellList = [];

    this._route.paramMap
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroy$),
        map((params) => {
          return { id: params.get('id'), projectId: params.get('projectId') };
        }),
        switchMap(({ id, projectId }) => {
          this.rigId = id || '';
          return forkJoin(
            this._homeService.getWellbores(id || '', projectId || ''),
            this._homeService.getIntervals(id || ''),
            this._homeService.getRuns(id || '')
          );
        })
      )
      .subscribe((resultData) => {
        if (resultData[0].data) {
          this.rigListDataWellbore = resultData[0].data;
          this.projectData = resultData[0].project;

          if ((resultData[0].wellArray || []).length) {
            this.wellList = AppHelper.UtileFunctions.uniqBy(
              resultData[0].wellArray,
              (key: any) => key.id
            );
          }
          this.dataWellboreReady = true;
        }
        if (resultData[1].data) {
          this.rigListDataInterval = resultData[1].data;
          this.dataIntervalReady = true;
        }
        if (resultData[2].data) {
          this.rigListDataRun = Object.assign([], resultData[2].data);

          this.dataRunReady = true;
        }

        this.isLoading = false;
      });
  }

  addFilterRigJournalId(rigJournalId: string) {
    let payloadFilterLog = this._homeService.getPayloadFilter();
    payloadFilterLog = {
      ...payloadFilterLog,
      rigJournalId: [rigJournalId]
    };
    this._homeService.setPayloadFilter(payloadFilterLog);

    return this._homeService.getPayloadFilter();
  }

  onDestroy(): void {}
}
