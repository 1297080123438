export const HOME_ADVANCED_FILTER = {
  REGION: {
    LABEL: 'Region',
    OPTION: {},
  },
  COUNTRY: {
    LABEL: 'Country',
    OPTION: {},
  },
  CUSTOMER: {
    LABEL: 'Customer',
    OPTION: {},
  },
  PROJECT: {
    LABEL: 'Project',
    OPTION: {},
  },
  RIG_CONTRACTOR: {
    LABEL: 'Rig Contractor',
    OPTION: {},
  },
  RIG: {
    LABEL: 'Rig',
    OPTION: {},
  },
  STATUS: {
    LABEL: 'Status',
    OPTION: {},
    PLACE_HOLDER: 'Select Status',
  },
  ACTIVE_PROJECT: {
    LABEL: 'Show only active rigs',
  },
  REQUIRE_ATTENTION: {
    LABEL: 'Show only Interventions that require attention',
  },
  OPTION: {
    PLACE_HOLDER: 'All',
  },
};
